import React from 'react'
import Radio from 'shared/forms/Radio'
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded'
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded'
import type { FormObject } from 'types'
import { presence } from 'shared/forms/validations'
import type { FormFields } from './NewPermanentConversation'

type Props = {
  fields: FormObject<FormFields>['fields']
}

export default function Decision({ fields }: Props) {
  const formFields = fields('raw', 'decision', [presence()])

  return (
    <div className="mt-8">
      <Radio<FormFields['decision']>
        title="Decisão Efetivação / Desligamento"
        center
        fullWidth
        afterChange={formFields.onChange}
        options={[
          {
            label: 'Efetivado/a',
            value: 'permanent',
            color: 'bg-green/50',
            icon: <ThumbUpRoundedIcon htmlColor="#969696" />,
          },
          {
            label: 'Desligado/a',
            value: 'fired',
            color: 'bg-red/50',
            icon: <ThumbDownRoundedIcon htmlColor="#969696" />,
          },
        ]}
      />
    </div>
  )
}
