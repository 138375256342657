import Chip from '@material-ui/core/Chip'

type Props = {
  size?: 'small' | 'medium'
  variant?: 'default' | 'outlined'
  label: string
}

export default function InactiveChip({
  size = 'small',
  variant = 'outlined',
  label,
}: Props) {
  return (
    <Chip
      className="!text-sm"
      size={size}
      label={label.toUpperCase()}
      variant={variant}
    />
  )
}
