import { Skeleton } from '@material-ui/lab'
import { Fetch } from 'croods'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import StarRounded from '@material-ui/icons/StarRounded'
import StarHalfRounded from '@material-ui/icons/StarHalfRounded'
import StarOutlineRounded from '@material-ui/icons/StarOutlineRounded'
import { getDenomination } from 'shared/helpers'
import CounterChip from '../counter-chip'
import Table from '../table'
import ceil from 'lodash/fp/ceil'
import floor from 'lodash/fp/floor'
import isEmpty from 'lodash/isEmpty'
import { useNavigate } from 'react-router-dom'
import { chartPath } from 'vibe/insights/chart-helpers'
import Avatar from 'shared/ui/Avatar'

import type { InsightComponentProps } from 'vibe/insights/chart-helpers'
import type { UserAvatar } from 'types'

type TeamTableRow = {
  circleId: number
  circleName: string
  circleType: string
  evaluationAverageScore: number
  highContribution: number
  mediumContribution: number
  lowContribution: number
}

function TeamDevelopmentSupportTable({ list }: { list: TeamTableRow[] }) {
  const navigate = useNavigate()

  return (
    <Table title="Ciclo atual em detalhes">
      <Table.Head>
        <Table.Header
          tooltip="São contabilizados apenas os círculos que permaneceram ativos desde o início do ciclo. Círculos que foram criados durante o ciclo ou estão inativos não serão levados em consideração."
          className="w-[180px]"
        >
          Círculo
        </Table.Header>
        <Table.Header
          className="w-[300px]"
          tooltip="Autoavaliações dos liderados"
        >
          Autoavaliação
        </Table.Header>
        <Table.Header
          className="w-[300px]"
          tooltip='O resultado da Contribuição dos liderados é calculado com a combinação dos resultados de "Entrega" e "Trabalho em Equipe" informadas na Conversa de Carreira.'
        >
          Contribuição
        </Table.Header>
      </Table.Head>
      <Table.Body>
        {list.map((item: TeamTableRow, index: number) => {
          const evaluation = item.evaluationAverageScore || 0
          const filledStars = floor(evaluation)
          const halfStars = ceil(evaluation - filledStars)
          const emptyStars = 5 - filledStars - halfStars
          const stars = (total: number) => Array.from(Array(total).keys())

          return (
            <Table.Row key={index} index={index}>
              <Table.Cell className="overflow-hidden text-ellipsis whitespace-nowrap">
                <button
                  type="button"
                  onClick={() => navigate(`/insights/${item.circleId}`)}
                  className="cursor-pointer text-blue-400 hover:underline"
                >
                  {item.circleName} [{getDenomination(item.circleType)}]
                </button>
              </Table.Cell>
              <Table.Cell>
                {evaluation ? (
                  <div className="flex items-center space-x-2 text-base font-bold text-blue">
                    <div>
                      {stars(filledStars).map((index) => (
                        <StarRounded key={index} />
                      ))}
                      {stars(halfStars).map((index) => (
                        <StarHalfRounded key={index} />
                      ))}
                      {stars(emptyStars).map((index) => (
                        <StarOutlineRounded key={index} />
                      ))}
                    </div>
                    <div>{evaluation}/5</div>
                  </div>
                ) : (
                  'Nenhum colaborador ou autoavaliação neste ciclo'
                )}
              </Table.Cell>
              <Table.Cell className="flex min-w-max">
                <CounterChip
                  color="bg-yellow"
                  count={item.lowContribution}
                  text="Baixa"
                />
                <CounterChip
                  color="bg-green"
                  count={item.mediumContribution}
                  text="Adequada"
                />
                <CounterChip
                  color="bg-green-900"
                  count={item.highContribution}
                  text="Superior"
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

type UserTableRow = {
  avatar: UserAvatar
  userId: number
  userName: string
  userRole: string
  selfEvaluation: number
  contributionLevel: KeyContributionLevel
}

type KeyContributionLevel = 'high' | 'medium' | 'low'
type DescriptionContributionLevel = {
  label: string
  bgColor: string
}

const contributionLevel: Record<
  KeyContributionLevel,
  DescriptionContributionLevel
> = {
  low: {
    label: 'Baixa',
    bgColor: 'bg-yellow',
  },
  medium: {
    label: 'Adequada',
    bgColor: 'bg-green-dark',
  },
  high: {
    label: 'Superior',
    bgColor: 'bg-green-darkest',
  },
}

function UserDevelopmentSupportTable({ list }: { list: UserTableRow[] }) {
  return (
    <Table title="Ciclo atual em detalhes">
      <Table.Head>
        <Table.Header
          tooltip="São contabilizados apenas os colaboradores que respondem a este círculo desde o início do ciclo. Colaboradores que entraram ou saíram durante o ciclo, ou estão inativos, não serão levados em consideração."
          className="w-[180px]"
        >
          Colaboradores
        </Table.Header>
        <Table.Header className="w-[300px]" tooltip="Autoavaliação do liderado">
          Autoavaliação
        </Table.Header>
        <Table.Header
          className="w-[300px]"
          tooltip="Contribuições do colaborador para o círculo"
        >
          Contribuições para o Circulo
        </Table.Header>
      </Table.Head>
      <Table.Body>
        {list.map((item: UserTableRow, index: number) => {
          const evaluation = item.selfEvaluation || 0
          const filledStars = floor(evaluation)
          const halfStars = ceil(evaluation - filledStars)
          const emptyStars = 5 - filledStars - halfStars
          const stars = (total: number) => Array.from(Array(total).keys())

          return (
            <Table.Row key={index} index={index}>
              <Table.Cell className="overflow-hidden text-ellipsis whitespace-nowrap">
                <div className="flex w-max items-center break-words">
                  <div className="mr-4">
                    <Avatar user={item.avatar} />
                  </div>
                  <div className="flex max-w-[200px] flex-col justify-center">
                    <h5 className="subtitle2 whitespace-nowrap">
                      {item.userName}
                    </h5>
                    <p className="body2 whitespace-nowrap text-black/50">
                      {item.userRole}
                    </p>
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="flex items-center space-x-2 text-base font-bold text-blue">
                  <div>
                    {stars(filledStars).map((index) => (
                      <StarRounded key={index} />
                    ))}
                    {stars(halfStars).map((index) => (
                      <StarHalfRounded key={index} />
                    ))}
                    {stars(emptyStars).map((index) => (
                      <StarOutlineRounded key={index} />
                    ))}
                  </div>
                  <div>{evaluation}/5</div>
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="flex min-w-max">
                  <CounterChip
                    color={contributionLevel[item.contributionLevel]?.bgColor}
                    text={contributionLevel[item.contributionLevel]?.label}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default function TeamDevelopmentSupport({
  query,
  stateId,
}: InsightComponentProps) {
  return (
    <Fetch
      name="charts"
      customPath={chartPath('team_development_support', query)}
      stateId={stateId}
      query={query}
      renderLoading={() => <Skeleton />}
      render={(_, [{ list, listError }]) => {
        if (listError)
          return <FeedbackMessage message={listError} type="warning" />

        if (isEmpty(list)) return null

        if (!('overview' in query) && !query?.inner_circle)
          return <UserDevelopmentSupportTable list={list} />

        return <TeamDevelopmentSupportTable list={list} />
      }}
    />
  )
}
