import { NavLink } from 'react-router-dom'
import map from 'lodash/map'

import { cx, renderIf } from 'shared/helpers'
import Table from 'shared/ui/table'
import Icon from 'shared/ui/icon'

import { formatNumberOfDays } from 'portal/vacationsHelpers'
import { endDate } from 'portal/vacationsHelpers'

import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import type { VacationEvent } from 'portal/myVacations/types'

const Vacations = ({ vacations }: { vacations: VacationEvent[] }) => {
  const tableHeaders = [
    'Status',
    'Período de férias',
    'Dias de férias',
    'Dias de abono',
    'Antecipação 13º',
    'Criado por',
    '',
  ]

  const classNameTd = 'py-1 md:py-5'

  const VacationStatus = ({
    vacationStatus,
  }: {
    vacationStatus: VacationEvent['status']
  }) =>
    vacationStatus === 'confirmed' ? (
      <div className="flex items-center gap-1">
        <Icon icon="check-circle" size="md" className="text-green" />
        Confirmado
      </div>
    ) : (
      <div className="flex items-center gap-1">
        <Icon icon="clock" size="md" className="text-blue" />
        Solicitado
      </div>
    )

  return (
    <div className="mt-6 h-fit overflow-hidden rounded-lg shadow">
      <Table id="my-vacations-table">
        <Table.Thead>
          {tableHeaders.map((header, index) => (
            <Table.Th
              key={`my-vacations-table-header-${index}`}
              className={cx(
                index === 0 && '!w-[7rem] !min-w-[7rem]',
                index === 1 && '!w-[12rem] !min-w-[12rem]',
                index === 6 && '!w-[10rem] !min-w-[10rem]',
              )}
            >
              {header.toUpperCase()}
            </Table.Th>
          ))}
        </Table.Thead>

        <Table.Tbody>
          {map(vacations, (vacation: VacationEvent) => {
            const showVacationValues =
              vacation.status === 'confirmed' ||
              !Boolean(vacation.solicitationId)

            const initialStartDate = showVacationValues
              ? parseISO(vacation.startDate)
              : parseISO(vacation.solicitation?.prefixedValues.startDate!)

            const initialEndDate = showVacationValues
              ? parseISO(vacation.endDate)
              : endDate(
                  initialStartDate,
                  vacation.solicitation?.prefixedValues.vacationDays!,
                )

            const initialVacationDays = formatNumberOfDays(
              showVacationValues
                ? vacation.vacationDays
                : vacation.solicitation?.prefixedValues.vacationDays!,
            )

            const initialVacationDaysSold = formatNumberOfDays(
              showVacationValues
                ? vacation.vacationDaysSold
                : vacation.solicitation?.prefixedValues.vacationDaysSold!,
            )

            const initialAdvanceThirteenth = showVacationValues
              ? vacation.advanceThirteenth
              : vacation.solicitation?.prefixedValues.advanceThirteenth!

            return (
              <Table.Tr key={`my-vacations-table-line-${vacation.id}`}>
                <Table.Td data-title={tableHeaders[0]} className={classNameTd}>
                  <VacationStatus vacationStatus={vacation.status} />
                </Table.Td>

                <Table.Td data-title={tableHeaders[1]} className={classNameTd}>
                  <div className="flex h-6 w-fit items-center rounded-2xl bg-gray/50 px-2 text-gray-darkest">
                    {`${format(initialStartDate, 'dd/MM/yyyy')} a ${format(
                      initialEndDate,
                      'dd/MM/yyyy',
                    )}`}
                  </div>
                </Table.Td>

                <Table.Td data-title={tableHeaders[2]} className={classNameTd}>
                  {initialVacationDays}
                </Table.Td>

                <Table.Td
                  data-title={tableHeaders[3]}
                  className={cx(classNameTd, 'block md:table-cell')}
                >
                  {initialVacationDaysSold}
                </Table.Td>

                <Table.Td
                  data-title={tableHeaders[4]}
                  className={cx(classNameTd, 'overflow-hidden text-ellipsis')}
                >
                  {initialAdvanceThirteenth ? 'Sim' : 'Não'}
                </Table.Td>

                <Table.Td
                  data-title={tableHeaders[5]}
                  className={cx(classNameTd, 'overflow-hidden text-ellipsis')}
                >
                  {vacation.createdBy ? vacation.createdBy : 'Integrador'}
                </Table.Td>

                <Table.Td data-title={tableHeaders[6]} className={classNameTd}>
                  {renderIf(
                    Boolean(vacation.solicitationId),
                    <NavLink
                      to={`/portal/solicitacoes-enviadas/em-meu-nome/${vacation.solicitationId}`}
                      type="button"
                      className="flex h-7 w-32 items-center gap-2 rounded border border-blue px-2 py-1 text-blue outline-none hover:bg-blue-50"
                    >
                      <Icon icon="visibility" size="sm" />
                      <span className="block text-base font-semibold text-blue lg:block">
                        SOLICITAÇÃO
                      </span>
                    </NavLink>,
                  )}
                </Table.Td>
              </Table.Tr>
            )
          })}
        </Table.Tbody>
      </Table>
    </div>
  )
}

export default Vacations
