import { Link } from 'react-router-dom'
import { useCurrentUser } from 'croods-auth'
import { isAdmin, isLeader, cx } from 'shared/helpers'
import StatusChip from 'vibe/circle/status-chip'
import InfoTooptip from 'shared/ui/Tooltip/InfoTooltip'

export default function Header({
  name,
  hasEvaluateButton,
  hasEditButton,
  teamId,
  team,
  variant,
  denomination,
  status,
  TooltipContent,
}: any) {
  const [{ currentUser }] = useCurrentUser()
  const admin = isAdmin(currentUser)
  const leader = isLeader(currentUser, teamId)
  const owner = team?.ownerId === currentUser?.id && team?.status === 'draft'
  const showEditButton = (admin || leader || owner) && hasEditButton
  const showButtons = hasEvaluateButton || showEditButton

  return (
    <div
      className={cx(
        'flex flex-col items-start justify-between bg-black/5 p-4 sm:flex-row sm:items-center',
        variant?.includes('primary') && 'linear-gradient',
      )}
    >
      <div className="flex gap-4 text-gray-darkest">
        {variant?.includes('primary') ? (
          <h4>
            {name} [{denomination}]
          </h4>
        ) : (
          <h5>
            {name} [{denomination}]
          </h5>
        )}
        {TooltipContent && (
          <InfoTooptip htmlColor={'#FFFFFF'} fontSize={'medium'}>
            <TooltipContent />
          </InfoTooptip>
        )}
        {status === 'inactive' && <StatusChip label="círculo inativo" />}
        {status === 'draft' && <StatusChip label="rascunho" />}
      </div>

      {showButtons && (
        <div className="flex gap-2 mt-2 md:mt-0">
          {hasEvaluateButton && (
            <Link
              key={`/performance/avaliacao-de-performance/${teamId}`}
              to={`/performance/avaliacao-de-performance/${teamId}`}
              style={{ textDecoration: 'none' }}
            >
              <button className="bt border-2 text-white hover:bg-[#6a97ce]/5">
                Avaliar performance
              </button>
            </Link>
          )}
          {showEditButton && (
            <Link
              key={`${teamId}`}
              to="editar-circulo"
              style={{ textDecoration: 'none' }}
            >
              <button className="bt border-2 text-white hover:bg-[#6a97ce]/5">
                Editar
              </button>
            </Link>
          )}
        </div>
      )}
    </div>
  )
}
