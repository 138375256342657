import React from 'react'
import { Fetch } from 'croods'
import { useParams } from 'react-router-dom'
import Section from 'shared/ui/section'
import Skeleton from 'shared/ui/skeleton'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import Documents from 'shared/ui/Documents'
import Form from './Form'

const Solicitation = () => {
  const { solicitationId } = useParams()
  return (
    <Section title="Abrir solicitação">
      <Fetch
        name="openSolicitation"
        path={`/workflow/solicitation_templates/${solicitationId}`}
        stateId={solicitationId}
        render={(template) => (
          <div className="card w-full overflow-visible">
            <div className="mt-4 flex w-full flex-col">
              <div className="mx-4 mb-4">
                <h4 title={template?.name}>{template?.name}</h4>
              </div>
              <div className="mx-4 mb-4">
                <p
                  className="font-normal leading-6 text-gray-darkest"
                  title={template?.description}
                >
                  {template?.description}
                </p>
              </div>
              <Documents
                documents={template?.documentsUrls}
                title="Documentos para download"
              />
              <Form
                inputs={template?.inputsAttributes}
                solicitationTemplateId={Number(solicitationId)}
              />
            </div>
          </div>
        )}
        renderLoading={() => <Skeleton height="20rem" />}
        renderError={(error) =>
          error === 'not allowed to show? this SolicitationTemplate' ? (
            <FeedbackMessage
              message="Você não tem permissão para abrir este modelo de solicitação"
              serverMessage=""
            />
          ) : (
            <FeedbackMessage
              serverMessage={error}
              type="warning"
              message="Solicitações não encontradas"
            />
          )
        }
      />
    </Section>
  )
}

export default Solicitation
