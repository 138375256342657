import * as React from 'react'
import PathProfile from '../../shared/path-profile'
import ConversationRoles from '../../conversation-roles'
import type {
  CareerConversation,
  IndividualOutputValues,
  TeamworkValues,
} from 'talks/types'
import { colors } from 'theme'
import Radio from 'shared/forms/Radio'
import Icon from 'shared/ui/icon'

type Props = {
  conversation: CareerConversation
  handleSave: (value: { [index: string]: string | number | null }) => void
  setIsValidated: React.Dispatch<React.SetStateAction<boolean>>
  setConversation: (value: CareerConversation) => void
}

export default function SelfEvaluation({
  conversation,
  handleSave,
  setIsValidated,
  setConversation,
}: Props) {
  const { individualOutput, teamwork, pathProfile } = conversation

  React.useEffect(() => {
    setIsValidated(
      ![
        individualOutput,
        teamwork,
        pathProfile,
        conversation?.organizationRole?.id,
      ].some((i) => !i),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualOutput, teamwork, pathProfile, conversation])

  return (
    <div className="flex flex-col gap-20 mb-10">
      <ConversationRoles
        conversation={conversation}
        handleSave={handleSave as (value: { [index: string]: any }) => void}
        setConversation={setConversation}
        setIsValidated={setIsValidated}
      />
      <Radio<IndividualOutputValues>
        title="Entrega"
        previousValue={individualOutput}
        afterChange={(newValue: IndividualOutputValues) => {
          handleSave({ individualOutput: newValue })
        }}
        options={[
          {
            label: 'Dependência',
            value: 'dependency',
            color: colors.yellowInput,
            description:
              '<div>Realiza entregas mais <b>simples, dependendo dos colegas</b> para realizar entregas mais relevantes.</div>',
            icon: <Icon icon="vertical-align-bottom" />,
          },
          {
            label: 'Autonomia',
            value: 'autonomy',
            color: colors.greenInput,
            description:
              '<div>Realiza entregas relevantes para o time de forma <b>autônoma</b>.</div>',
            icon: <Icon icon="drag-handle" />,
          },
          {
            label: 'Diferenciada',
            value: 'differentiated',
            color: colors.greenDarkestInput,
            description:
              '<div>Realiza entregas <b>diferenciadas</b>, acima da média de mercado e que poderiam ser apresentadas em <b>cases</b>.</div>',
            icon: <Icon icon="vertical-align-top" />,
          },
        ]}
      />
      <Radio<TeamworkValues>
        title="Trabalho em Equipe"
        previousValue={teamwork}
        afterChange={(newValue: TeamworkValues) => {
          handleSave({ teamwork: newValue })
        }}
        options={[
          {
            label: 'Individual',
            value: 'individual',
            color: colors.yellowInput,
            description:
              '<div><b>Trabalha mais individualmente</b> do que coletivamente.</div>',
            icon: <Icon icon="vertical-align-bottom" />,
          },
          {
            label: 'Membro',
            value: 'member',
            color: colors.greenInput,
            description:
              '<div><b>Trabalha em equipe</b> para que todos atinjam os objetivos coletivamente.</div>',
            icon: <Icon icon="drag-handle" />,
          },
          {
            label: 'Referência',
            value: 'reference',
            color: colors.greenDarkestInput,
            description:
              '<div>É uma <b>referência pessoal</b> e profissional para os demais membros do time.</div>',
            icon: <Icon icon="vertical-align-top" />,
          },
        ]}
      />
      <PathProfile
        title="Marque o Perfil de Trajetória desejado"
        pathProfile={pathProfile}
        handleSave={handleSave}
      />
    </div>
  )
}
