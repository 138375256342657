import type { Option } from 'shared/forms/Radio'
import Radio from 'shared/forms/Radio'
import { useAdminText } from 'shared/utils/hooks'
import getIcon from 'shared/ui/get-icon'

import { colors } from 'theme'
import type { PathProfileValues } from 'talks/types'

type Props = {
  pathProfile?: PathProfileValues
  handleSave: (value: { [index: string]: string | null }) => void
  title?: string
}

export default function PathProfile({ pathProfile, handleSave, title }: Props) {
  const {
    careerConversationsGrowthPathGradualLabel,
    careerConversationsGrowthPathFastLabel,
  } = useAdminText()

  const options: Option<PathProfileValues>[] = [
    {
      label: 'Cuidado',
      value: 'care',
      icon: getIcon('care'),
      description: careerConversationsGrowthPathGradualLabel,
      color: colors.purpleInput,
    },
    {
      label: 'Desafio',
      value: 'challenge',
      icon: getIcon('auto-graph'),
      description: careerConversationsGrowthPathFastLabel,
      color: colors.blueInput,
    },
  ]

  const handleChange = (newValue: PathProfileValues) => {
    handleSave({ pathProfile: newValue })
  }

  return (
    <Radio<PathProfileValues>
      afterChange={handleChange}
      options={options}
      title={title}
      previousValue={pathProfile}
    />
  )
}
