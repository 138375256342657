import { useFormState } from 'react-use-form-state'

import {
  getFieldProps,
  isValidForm,
  isFulfilled,
  isPartiallyFilled,
} from 'shared/forms/validations'

type Required<T> = { requiredFields?: Array<keyof T> }

type InputElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement

// Even though we're accepting a number as a default value for numeric inputs
// (e.g. type=number and type=range), the value stored in state for those
// inputs will be a string
type StateValues<T> = {
  readonly [A in keyof T]: T[A] extends number ? string : T[A]
}

interface FormOptions<T> {
  onChange?(
    event: React.ChangeEvent<InputElement>,
    stateValues: StateValues<T>,
    nextStateValues: StateValues<T>,
  ): void
  onBlur?(event: React.FocusEvent<InputElement>): void
  onClear?(): void
  onReset?(): void
  onTouched?(event: React.FocusEvent<InputElement>): void
  validateOnBlur?: boolean
  withIds?: boolean | ((name: string, value?: string) => string)
}

export const useForm = <T>(
  initialValues: Partial<T>,
  { requiredFields, ...options }: Required<T> & FormOptions<T> = {},
) => {
  const [formState, form] = useFormState<T>(initialValues, options)
  const fields = getFieldProps<T>(form, formState)
  const hasErrors =
    !isFulfilled<T>(formState, { requiredFields }) || !isValidForm<T>(formState)
  const hasPartialValues =
    !isPartiallyFilled<T>(formState, { requiredFields }) ||
    !isValidForm<T>(formState)

  return { formState, fields, hasErrors, hasPartialValues }
}
