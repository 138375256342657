import React from 'react'
import type { FormProps } from 'shared/formsv2/form'

import type { Props } from './Wrapper'

type ContextType = Pick<Props, 'onClose' | 'loading'> & {
  disabled: boolean
  handleConfirm: (() => Promise<void>) | FormProps['onSubmit']
  isForm: boolean
}
const initialValues = {
  loading: false,
  disabled: false,
  onClose: () => {},
  handleConfirm: async () => {},
}
const ModalContext = React.createContext<ContextType>(
  initialValues as ContextType,
)
ModalContext.displayName = 'ModalContext'

export default ModalContext
