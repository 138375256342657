import { cx } from 'shared/helpers'

const Item = ({ className, name, icon, onClick }: any) => (
  <div
    role="presentation"
    className={cx(
      'card cursor-pointer transition-shadow hover:shadow-lg',
      className,
    )}
    onClick={onClick}
  >
    <div
      className="group flex items-center gap-2 bg-gray-100 text-gray-500 transition-all hover:bg-blue hover:bg-opacity-10 hover:text-blue-dark"
      title={name}
    >
      <div className="border-r bg-gray-300 p-3 text-current group-hover:border-blue-dark group-hover:bg-blue group-hover:bg-opacity-70 ">
        {icon}
      </div>
      <div className="grow overflow-hidden p-3">
        <h5 className="overflow-hidden text-ellipsis whitespace-nowrap text-xs font-light uppercase text-current">
          {name}
        </h5>
      </div>
    </div>
  </div>
)

export default Item
