import CircularProgress from '@material-ui/core/CircularProgress'
import { useCombobox } from 'downshift'
import identity from 'lodash/identity'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { cx, matchSearch } from 'shared/helpers'
import Chip from 'shared/ui/chip'
import type { InputProps } from './input'
import InputHint from './input-hint'
import Label from './label'

function parseValue<T extends any = Record<string, unknown>>(value: string) {
  if (!value) return []
  try {
    return JSON.parse(value) as T[]
  } catch (err) {
    return []
  }
}

type Props<T extends unknown = unknown> = Omit<
  InputProps,
  'onChange' | 'defaultValue'
> & {
  defaultValue?: string
  emptyText?: string
  loadingText?: string
  options: T[]
  multiple?: boolean
  loading?: boolean
  onChange?: (value: string) => void
  displayValue?: (item?: T | null) => string
  filter?: (item: T, val: string) => boolean
}
function AutocompleteInput<T extends unknown = unknown>({
  name,
  id = name,
  hint,
  label,
  required,
  className,
  emptyText = 'Nenhum dado encontrado',
  loadingText = 'Carregando...',
  multiple = false,
  options,
  loading,
  onChange,
  filter,
  defaultValue = '',
  displayValue = identity,
  ...props
}: Props<T>) {
  let { formState, register, setValue, getValues } = useFormContext()
  let hasErrors = Boolean(formState.errors?.[name]?.message)
  const [items, setItems] = React.useState<T[]>([])
  const [initialized, setInitialized] = React.useState(false)
  const [selectedItems, setSelectedItems] = React.useState<T[]>(
    parseValue(defaultValue),
  )
  const downshift = useCombobox({
    items,
    itemToString: displayValue,
    onSelectedItemChange: ({ selectedItem }) => {
      selectedItem && updateValue(selectedItem)
    },
    onInputValueChange: ({ inputValue = '' }) => {
      onChange?.(inputValue)
      setItems(
        options.filter(
          (item) =>
            filter?.(item, inputValue) ?? defaultFilter(item, inputValue),
        ),
      )
    },
  })

  React.useEffect(() => {
    if (!initialized) setInitialized(true)
    setValue(name, JSON.stringify(selectedItems), {
      shouldValidate: initialized,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems, name])

  function defaultFilter(item: T, value: string) {
    return matchSearch(value, [displayValue(item)])
  }

  function updateValue(selectedItem: T) {
    setSelectedItems(
      multiple ? [...selectedItems, selectedItem] : [selectedItem],
    )
    downshift.reset()
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Backspace' && downshift.inputValue === '') {
      setSelectedItems(selectedItems.slice(0, -1))
    }
  }

  function handleDelete(index: number) {
    setSelectedItems([
      ...selectedItems.slice(0, index),
      ...selectedItems.slice(index + 1),
    ])
  }

  let hintId = `input-${id}-hint`
  return (
    <div {...downshift.getComboboxProps()} className="relative flex flex-col">
      {label && (
        <Label
          {...downshift.getLabelProps()}
          aria-required={required ? 'true' : 'false'}
        >
          {label}
        </Label>
      )}
      <input
        data-testid="hidden-input-autocomplete"
        type="hidden"
        value={String(getValues(name))}
        {...register(name)}
      />
      <div className="relative flex w-full flex-col justify-center">
        <div className="input flex flex-wrap items-start !p-0 focus-within:ring-1">
          {Boolean(selectedItems.length) && (
            <div role="list" className="m-2 mb-0 flex flex-wrap gap-1">
              {selectedItems.map((item, idx) => (
                <Chip
                  key={`item-chip-${idx}`}
                  role="listitem"
                  onDelete={() => handleDelete(idx)}
                >
                  {displayValue(item)}
                </Chip>
              ))}
            </div>
          )}
          <input
            type="text"
            className={cx(
              'input grow border-none !ring-0',
              hasErrors && '!border-red-dark !text-red-dark',
              className,
            )}
            {...props}
            {...downshift.getInputProps({ onKeyDown })}
            name={`${name}-ui-representation`}
            id={id}
            aria-invalid={hasErrors ? 'true' : 'false'}
            aria-describedby={hasErrors ? hintId : undefined}
          />
        </div>
        {loading && (
          <CircularProgress
            role="progressbar"
            size={20}
            className="absolute right-2"
          />
        )}
        <ul
          {...downshift.getMenuProps()}
          className={cx(
            'absolute top-full z-10 w-max min-w-[200px] translate-y-1 bg-white py-2 text-sm shadow-xl',
            downshift.isOpen || 'hidden',
          )}
        >
          {downshift.isOpen &&
            items.map((item, index) => (
              <li
                className={cx(
                  'block cursor-pointer px-4 py-1.5 hover:bg-blue-light/30 hover:font-bold',
                  downshift.highlightedIndex === index &&
                    'bg-blue-light/30 font-bold',
                )}
                key={`${displayValue(item)}-${index}`}
                {...downshift.getItemProps({ item, index })}
              >
                {displayValue(item)}
              </li>
            ))}
          {Boolean(downshift.isOpen && !items.length) && (
            <li className="block px-4 py-1.5">
              {loading ? loadingText : emptyText}
            </li>
          )}
        </ul>
      </div>
      <InputHint id={hintId} isError={hasErrors}>
        {formState.errors?.[name]?.message ?? hint}
      </InputHint>
    </div>
  )
}

export type { Props as AutocompleteInputProps }
export { parseValue }
export default AutocompleteInput
