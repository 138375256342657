import React from 'react'
import { Link } from 'react-router-dom'

import { cx } from 'shared/helpers'

type Props = {
  path: string
  icon: string
  title: string
  active?: boolean
}

const MenuItem = ({ path, icon, title, active = false }: Props) => (
  <Link
    to={path}
    className={cx(
      active && 'bg-white bg-opacity-20',
      'group relative flex h-[40px] w-[40px] items-center justify-center rounded-xl hover:bg-blue-darkest',
    )}
  >
    <div className="absolute left-[3.25rem] z-[200] hidden w-max flex-col rounded-xl bg-white py-2 px-4 shadow md:group-hover:block">
      <span className="text-xs font-semibold text-blue">{title}</span>
    </div>
    <img
      src={`/modules-icons/${icon}${active ? '-active' : ''}.svg`}
      alt={title}
      className="relative z-[2] w-12 hover:z-[199]"
    />
  </Link>
)

export default MenuItem
