import map from 'lodash/map'

import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Modal from 'shared/ui/Modal'
import { cx } from 'shared/helpers'

import Item from './Item'

const CustomTooltip = ({
  message,
  children,
}: {
  message: string
  children: JSX.Element
}) => {
  if (!message) return children

  return (
    <Tooltip title={message} aria-label={message}>
      <div>{children}</div>
    </Tooltip>
  )
}

type Props = {
  title: string
  questionsTypes: any
  setType: Function
  handleCloseAndStatus: React.MouseEventHandler<HTMLButtonElement>
  list: Array<any>
  siblingsQuestionsList?: Array<any>
}

const SelectableQuestions = ({
  title,
  questionsTypes,
  setType,
  handleCloseAndStatus,
  list,
  siblingsQuestionsList,
}: Props) => {
  const getUniqueState = (type: string) => {
    const filteredList = list.filter(
      (question: any) => question.inputType === type,
    )

    const filteredSiblingsList =
      siblingsQuestionsList &&
      siblingsQuestionsList.filter(
        (question: any) => question.inputType === type,
      )

    return (
      questionsTypes[type].unique &&
      (filteredList.length > 0 ||
        (filteredSiblingsList && filteredSiblingsList?.length > 0))
    )
  }

  return (
    <>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <div className="grid min-w-max md:grid-cols-3 md:grid-rows-3 justify-items-stretch gap-4 md:gap-8">
          {map(questionsTypes, (item) => {
            const isUnique = getUniqueState(item.type)
            return (
              <CustomTooltip
                key={item.title}
                message={
                  isUnique &&
                  'É permitido apenas adicionar uma pergunta deste tipo'
                }
              >
                <Item
                  name={item.title}
                  icon={item.icon()}
                  onClick={() => setType(item.type)}
                  className={cx(
                    isUnique &&
                      'pointer-events-none !cursor-not-allowed opacity-50',
                  )}
                />
              </CustomTooltip>
            )
          })}
        </div>
      </Modal.Content>
      <Modal.Actions hideCancel>
        <Button
          className="order-first"
          size="small"
          onClick={handleCloseAndStatus}
        >
          Voltar
        </Button>
      </Modal.Actions>
    </>
  )
}

export default SelectableQuestions
