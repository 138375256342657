import * as React from 'react'
import startCase from 'lodash/startCase'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

const DateInput = ({
  disabled,
  name,
  error,
  helper = ' ',
  label = startCase(name),
  labelClassName = '',
  onChange,
  onBlur,
  minDate,
  maxDate,
  ...props
}: any) => {
  const helperText = error || helper

  React.useEffect(() => {
    props.value ? onChange(props.value) : onChange(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <label htmlFor={name} className={labelClassName}>
        {label}
      </label>
      <KeyboardDatePicker
        inputVariant="outlined"
        clearable
        autoOk
        pattern="^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)(19|20)\d{2}$"
        format="DD/MM/YYYY"
        name={name}
        id={name}
        placeholder="DD/MM/AAAA"
        error={!!error}
        helperText={helperText}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        className="max-w-[20rem] !my-2"
        inputProps={{ className: '!py-4' }}
        InputLabelProps={{ shrink: false, className: '!hidden' }}
        InputAdornmentProps={{ className: '!-mr-4' }}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DateInput
