import * as React from 'react'

const MyFeedback = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24pt" height="24pt" viewBox="0 0 24 24" version="1.1" {...props}>
    <g id="surface1">
      <path
        stroke='none'
        fillRule='nonzero'
        fill='currentColor'
        d="M 13 3 C 10.613281 3 8.324219 3.949219 6.636719 5.636719 C 4.949219 7.324219 4 9.613281 4 12 L 1 12 L 4.890625 15.890625 L 4.960938 16.03125 L 9 12 L 6 12 C 6 8.128906 9.128906 5 13 5 C 16.871094 5 20 8.128906 20 12 C 20 15.871094 16.871094 19 13 19 C 11.070312 19 9.320312 18.210938 8.058594 16.941406 L 6.640625 18.359375 C 7.472656 19.199219 8.464844 19.863281 9.554688 20.316406 C 10.648438 20.769531 11.816406 21 13 21 C 15.386719 21 17.675781 20.050781 19.363281 18.363281 C 21.050781 16.675781 22 14.386719 22 12 C 22 9.613281 21.050781 7.324219 19.363281 5.636719 C 17.675781 3.949219 15.386719 3 13 3 Z M 13 3 "
      />
      <path
        stroke='none'
        fillRule='nonzero'
        fill='currentColor'
        d="M 13.898438 13.890625 L 11.898438 13.890625 L 11.898438 15.890625 L 13.898438 15.890625 Z M 13.898438 13.890625 "
      />
      <path
        stroke='none'
        fillRule='nonzero'
        fill='currentColor'
        d="M 13.898438 7.890625 L 11.898438 7.890625 L 11.898438 12.890625 L 13.898438 12.890625 Z M 13.898438 7.890625 "
      />
    </g>
  </svg>
)

export { MyFeedback }
