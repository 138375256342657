import { cx } from 'shared/helpers'
import { colors } from 'theme'
import type { Box } from '.'
import useRating from './useRating'

const BACKGROUNDS = {
  low: 'bg-red/10',
  medium: 'bg-yellow/10',
  high: 'bg-green/10',
  veryHigh: 'bg-green/20',
}

const GOAL_TYPE_COLORS = {
  unknown: colors.red,
  conservative: colors.yellow,
  realistic: colors.green,
  stretch: colors.greenDarkest,
}

const GOAL_TYPE_LABELS = {
  unknown: 'Metas sem referência',
  conservative: 'Metas conservadoras',
  realistic: 'Metas realistas',
  stretch: 'Metas superiores',
}

const REACHED_GOALS_COLORS = {
  none: colors.red,
  some: colors.yellow,
  all: colors.green,
}

const REACHED_GOALS_LABELS = {
  none: 'Não atingimos os objetivos',
  some: 'Atingimos alguns objetivos',
  all: 'Atingimos os objetivos',
}

type Props = Pick<Box, 'goalType' | 'reachedGoals'>

export default function Label({ goalType, reachedGoals }: Props) {
  const rating = useRating(goalType, reachedGoals)
  const background = BACKGROUNDS[rating]
  return (
    <div
      className={cx(
        'h-full overflow-hidden rounded-2xl sm:h-auto sm:rounded-none',
        background,
      )}
    >
      <div className="flex flex-col items-center justify-center h-full py-1 font-bold text-center text-gray-dark sm:h-auto sm:flex-row sm:py-2">
        <div style={{ color: REACHED_GOALS_COLORS[reachedGoals] }}>
          {REACHED_GOALS_LABELS[reachedGoals]}
        </div>
        <div>&nbsp;+&nbsp;</div>
        <div style={{ color: GOAL_TYPE_COLORS[goalType] }}>
          {GOAL_TYPE_LABELS[goalType]}
        </div>
      </div>
    </div>
  )
}
