import { useEffect, useState } from 'react'
import { useCroods } from 'croods'
import AutocompleteWithSearch from 'shared/forms/AutocompleteWithSearch'
import { getFullName, renderIf } from 'shared/helpers'
import { conversationTypes } from 'shared/utils/conversationLookup'
import { useNavigate } from 'react-router-dom'

import type { Option } from 'shared/forms/AutocompleteWithSearch/Autocomplete'
import type { LegacyRouteProps } from 'legacy-route'
import type { User } from 'types'
import type { ID } from 'croods'

export default function NewConversation({ pageTitle }: LegacyRouteProps) {
  const [userId, setUserId] = useState<number | string | null>(null)
  const [selectedTeamId, setSelectedTeamId] = useState<ID | undefined>(
    undefined,
  )
  const [conversation, setConversation] = useState<string>('')
  const [conversations, setConversations] = useState<
    {
      label: string
      value: string
    }[]
  >([])
  const navigate = useNavigate()

  const [{ fetchingInfo, info: team }, {}] = useCroods({
    name: 'teamInfo',
    id: selectedTeamId,
    path: '/team',
    fetchOnMount: true,
  })

  useEffect(() => {
    let enabledConversationsList: String[] = []

    if (team?.abilities) {
      team.abilities.oneOnOneConversation &&
        enabledConversationsList.push('one_on_one', 'onboarding')

      team.abilities.behaviorConversation &&
        enabledConversationsList.push('behavior')

      team.abilities.careerConversation &&
        enabledConversationsList.push('career')
    }

    setConversations(
      Object.keys(conversationTypes)
        .filter((key) => enabledConversationsList.includes(key))
        .map((key) => {
          const conv = conversationTypes[key as keyof typeof conversationTypes]
          return {
            value: conv.path,
            label: conv.name,
          }
        }),
    )
  }, [team])

  const handleClick = () => {
    const url = `/conversas/nova-conversa/${conversation}/${userId}`
    navigate(url)
  }

  const handleSelectConversation = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setConversation(e.target.value)
  }

  const handleSelectUserId = (
    props: string | Option | (string | Option)[] | null,
  ) => {
    if (typeof props === 'object' && !Array.isArray(props) && props !== null) {
      setUserId(props.value)
      setSelectedTeamId(props.teamId)
    }
  }

  const parseOptions = (options: User[]) => {
    const result = options.map((option) => ({
      label: getFullName(option),
      value: option.teamMemberId,
      teamId: option.teamId,
    }))
    return result
  }

  return (
    <div className="flex flex-col gap-6">
      <h3>{pageTitle}</h3>

      <div className="card flex flex-col gap-6 p-6">
        <AutocompleteWithSearch<User>
          croodsConfig={{
            name: 'usersILead',
            path: '/conversations/conversations_with_led',
            fetchOnMount: true,
          }}
          parseOptions={parseOptions}
          inputProps={{
            name: 'users',
            label: 'Com quem você deseja conversar?',
          }}
          autocompleteProps={{
            multiple: false,
            onChange: handleSelectUserId,
            onBlur: () => {},
          }}
        />

        {fetchingInfo ? (
          <div className="h-[4.25rem] w-full animate-pulse rounded bg-gray-200" />
        ) : (
          <>
            {renderIf(
              Boolean(selectedTeamId),
              <>
                {conversations.length > 0 ? (
                  <label htmlFor="conversations" className="h5">
                    Escolha o tipo de conversa
                    <div className="relative mt-1">
                      <select
                        name="conversations"
                        id="conversations"
                        onChange={handleSelectConversation}
                        value={conversation}
                        className="block w-full appearance-none rounded border border-blue px-2 py-4 text-xs"
                      >
                        <option value="" hidden disabled className="hidden ">
                          Selecionar
                        </option>
                        {conversations.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute bottom-0 right-1 flex h-full items-center">
                        <svg
                          className="h-6 w-6 fill-current text-lg text-blue"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M7 10l5 5 5-5z" />
                        </svg>
                      </div>
                    </div>
                  </label>
                ) : (
                  <p className="mt-1">
                    O círculo a que essa pessoa responde não habilita nenhum
                    tipo de conversa.
                  </p>
                )}
              </>,
            )}
          </>
        )}

        <div className="bg-blue-lightest p-6 flex -m-6 mt-0 rounded-b-lg justify-end">
          <button
            className="bt bt-contained"
            disabled={!userId || !conversation}
            onClick={handleClick}
          >
            Iniciar
          </button>
        </div>
      </div>
    </div>
  )
}
