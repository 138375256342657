import { authStorageKey } from 'domain/auth'
import {
  camelToSnake,
  makeService,
  mergeHeaders,
  snakeToCamel,
  typedResponse,
} from 'make-service'
import type { Schema, SearchParams } from 'make-service'
import { onErrorDefaultTo } from 'shared/helpers'
import getOrganizationSlug from 'shared/organization/getOrganizationSlug'
import { z } from 'zod'

const authHeadersSchema = z.object({
  accessToken: z.string(),
  client: z.string(),
  expiry: z.string(),
  tokenType: z.string(),
  uid: z.string(),
})

function authHeaders() {
  const rawCredentials = JSON.parse(
    localStorage.getItem(authStorageKey()) || 'null',
  )
  const result = authHeadersSchema.safeParse(rawCredentials)

  if (!result.success) return {}

  const credentials = result.data

  if (!credentials) return {}

  return {
    'Access-Token': credentials.accessToken,
    Client: credentials.client,
    Expiry: credentials.expiry,
    'Token-Type': credentials.tokenType,
    Uid: credentials.uid,
  }
}

function saveHeaders(response: Response) {
  const credentials = {
    accessToken: response.headers.get('access-token'),
    client: response.headers.get('client'),
    expiry: response.headers.get('expiry'),
    tokenType: response.headers.get('token-type'),
    uid: response.headers.get('uid'),
  }

  if (credentials.accessToken) {
    localStorage.setItem(authStorageKey(), JSON.stringify(credentials))
  }
}

function queryToSnake(query: SearchParams) {
  if (Array.isArray(query)) return query
  if (query instanceof URLSearchParams) return query
  if (typeof query === 'string') return query

  return camelToSnake(query)
}

function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  )
}

class FetchError extends Error {
  url: Response['url']
  status: Response['status']
  data: unknown

  constructor(data: unknown, response: Response) {
    const message = isErrorWithMessage(data)
      ? data.message
      : response.statusText
    super(message || 'Erro inesperado')
    this.name = 'FetchError'
    this.url = response.url
    this.status = response.status
    this.data = data
  }
}

const api = makeService(
  (window as any)?.env?.REACT_APP_API_URL ?? process.env.REACT_APP_API_URL,
  {
    headers: () =>
      mergeHeaders(authHeaders(), {
        Organization: getOrganizationSlug() || '',
      }),
    requestTransformer: (request) => ({
      ...request,
      query: queryToSnake(request.query),
      body: camelToSnake(request.body),
    }),
    responseTransformer: async (response) => {
      saveHeaders(response)

      if (!response.ok) {
        const body = await response.text()
        const data = await onErrorDefaultTo(
          () => snakeToCamel(JSON.parse(body) as unknown),
          { message: body },
        )

        throw new FetchError(data, response)
      }

      return typedResponse(response, {
        getJson:
          (response) =>
          async <T = unknown>(schema?: Schema<T>): Promise<T> => {
            const json = snakeToCamel(await response.json())
            return schema ? schema.parse(json) : (json as unknown as T)
          },
      })
    },
  },
)

export { api, FetchError }
