import { Bar } from 'recharts'
import { colors } from 'theme'
import Chart from 'vibe/insights/chart'

type Data = {
  veryHighScoreCount: number
  highScoreCount: number
  mediumScoreCount: number
  lowScoreCount: number
  averageScore: number
  averageOrganizationScore: number
}

type Props = { data: Data[] }

const getColorIndex = (score: number) => {
  if (score <= 54) return 'red'
  if (score <= 69) return 'yellow'
  if (score <= 84) return 'green'
  return 'greenDarkest'
}

const getColor = (score: number) => colors[getColorIndex(score)]

const getBackgroundColor = (score: number) =>
  colors[`${getColorIndex(score)}Input`]

export default function TeamEvaluationsChart({ data }: Props) {
  return (
    <Chart data={data}>
      <Chart.Header tooltip="Avaliação de performance dos círculos">
        Avaliação de performance dos círculos
      </Chart.Header>
      <div className="mt-2 mb-6 ml-[16%] flex w-[80%] sm:ml-[12%] sm:w-[83%] md:ml-[10%] md:w-[85%] lg:ml-[8%] lg:w-[87%]">
        {data.map(({ averageScore }, index) => {
          const color = getColor(averageScore)
          const backgroundColor = getBackgroundColor(averageScore)

          return (
            <div key={index} className="flex flex-1 justify-center">
              <div
                className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 font-bold"
                style={{ color, backgroundColor }}
              >
                {averageScore}%
              </div>
            </div>
          )
        })}
      </div>
      <Chart.Bars
        tooltip={({
          lowScoreCount,
          mediumScoreCount,
          highScoreCount,
          veryHighScoreCount,
          averageScore,
          averageOrganizationScore,
        }) => {
          const circleColor = getColor(averageScore)
          const organizationColor = getColor(averageOrganizationScore)

          return (
            <>
              <div className="py-2">
                Performance média dos círculos:{' '}
                <span style={{ color: circleColor }}>{averageScore}%</span>
              </div>
              <div style={{ color: colors.greenDarkest }}>
                Excelente: {veryHighScoreCount}
              </div>
              <div style={{ color: colors.green }}>Bom: {highScoreCount}</div>
              <div style={{ color: colors.yellow }}>
                Regular: {mediumScoreCount}
              </div>
              <div style={{ color: colors.red }}>Ruim: {lowScoreCount}</div>
              <div className="pt-2">
                Performance média da organização:{' '}
                <span style={{ color: organizationColor }}>
                  {averageOrganizationScore}%
                </span>
              </div>
            </>
          )
        }}
      >
        <>
          <Bar
            dataKey="lowScoreCount"
            stackId="a"
            shape={Chart.RedStackShape}
          />
          <Bar
            dataKey="mediumScoreCount"
            stackId="a"
            shape={Chart.YellowStackShape}
          />
          <Bar
            dataKey="highScoreCount"
            stackId="a"
            shape={Chart.GreenStackShape}
          />
          <Bar
            dataKey="veryHighScoreCount"
            stackId="a"
            shape={Chart.DarkGreenStackShape}
          />
        </>
      </Chart.Bars>
    </Chart>
  )
}
