import React from 'react'
import Grid from '@material-ui/core/Grid'

import get from 'lodash/get'

import Step from 'shared/ui/form-step'

import StepSummary from './StepSummary'
import summarize from './summarize'
import Approvals from './Approvals'

const Summary = ({
  handleSubmit,
  submitting = false,
  error,
  values,
  visible,
  handleNavigate,
  nextButtonLabel = 'Publicar',
  activeStep,
}: any) => {
  const summarized = summarize(values)

  const onSubmit: React.FormEventHandler = (e) => {
    e && e.preventDefault && e.preventDefault()
    handleSubmit && handleSubmit()
  }

  return (
    <Step
      handleSubmit={onSubmit}
      error={error}
      submitting={submitting}
      visible={visible}
      backButtonLabel="Voltar"
      nextButtonLabel={nextButtonLabel}
      handleNavigate={handleNavigate(activeStep - 1)}
    >
      <Grid container direction="column">
        <Grid item>
          <StepSummary startOpen questions={summarized.opening}>
            Abertura
          </StepSummary>
        </Grid>
        <Grid item>
          <StepSummary
            questions={get(summarized, 'approval.approvalsAttributes.value')}
            render={(levels: any) => <Approvals levels={levels} />}
          >
            Aprovação
          </StepSummary>
        </Grid>
        <Grid item>
          <StepSummary questions={summarized.performer}>Execução</StepSummary>
        </Grid>
      </Grid>
    </Step>
  )
}

export default Summary
