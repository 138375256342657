import Modal from 'shared/ui/Modal'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Period from 'shared/ui/Period'
import { useFormContext } from 'react-hook-form'
import { updateEvaluationWindow } from 'settings/cycle/helpers'
import type { Cycle } from '../types'

type Props = {
  currentCycle: Cycle
  isFirstEvaluationCycle?: boolean
}
function DialogStageTwo({ currentCycle, isFirstEvaluationCycle }: Props) {
  const { watch } = useFormContext()
  const newCycle = updateEvaluationWindow(
    currentCycle,
    watch('selectCycle'),
    isFirstEvaluationCycle,
  )
  return (
    <>
      <Modal.Header>Confirmar alteração de ciclo</Modal.Header>
      <Modal.Content>
        <h5>Veja a mudança de datas com o novo ciclo escolhido</h5>
        <div className="flex flex-col items-start gap-4 divide-y divide-gray-200">
          <div className="flex w-full flex-col items-start">
            <h6 className="mt-4 mb-2 text-sm">Periodicidade de avaliação</h6>
            <div className="grid grid-cols-3 place-items-center items-center gap-2">
              <div className="flex flex-col items-start">
                <p className="px-2 text-xs text-gray-dark">Era assim</p>
                <Period
                  {...({
                    show: true,
                    durationInMonths: currentCycle.durationInMonths,
                    hollow: true,
                  } as any)} // TODO: Implement Period component in TS
                />
              </div>
              <ArrowForwardIcon color="disabled" />
              <div className="flex flex-col items-start">
                <p className="px-2 text-xs text-gray-dark">Será assim</p>
                <Period
                  {...({
                    show: true,
                    durationInMonths: newCycle.durationInMonths,
                  } as any)}
                />
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col items-start">
            <h6 className="mt-4 mb-2 text-sm">Ciclo vigente</h6>
            <div className="grid grid-cols-3 place-items-center items-center gap-2">
              <div className="flex flex-col items-start">
                <p className="px-2 text-xs text-gray-dark">Era assim</p>
                <Period
                  {...({
                    show: true,
                    startsAt: currentCycle.startsAt,
                    finishesAt: currentCycle.finishesAt,
                    hollow: true,
                  } as any)}
                />
              </div>
              <ArrowForwardIcon color="disabled" />
              <div className="flex flex-col items-start">
                <p className="px-2 text-xs text-gray-dark">Será assim</p>
                <Period
                  {...({
                    show: true,
                    startsAt: newCycle.startsAt,
                    finishesAt: newCycle.finishesAt,
                  } as any)}
                />
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col items-start">
            <h6 className="mt-4 mb-2 text-sm">
              Período para realizar as próximas avaliações
            </h6>
            <div className="grid grid-cols-3 place-items-center items-center gap-2">
              <div className="flex flex-col items-start">
                <p className="px-2 text-xs text-gray-dark">Era assim</p>
                <Period
                  {...({
                    show: true,
                    startsAt: currentCycle.evaluationWindowOpensAt,
                    finishesAt: currentCycle.evaluationWindowClosesAt,
                    longFormat: true,
                    hollow: true,
                  } as any)}
                />
              </div>
              <ArrowForwardIcon color="disabled" />
              <div className="flex flex-col items-start">
                <p className="px-2 text-xs text-gray-dark">Será assim</p>
                <Period
                  {...({
                    show: true,
                    startsAt: newCycle.evaluationWindowOpensAt,
                    finishesAt: newCycle.evaluationWindowClosesAt,
                    longFormat: true,
                  } as any)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>
    </>
  )
}

export default DialogStageTwo
