import React, { Suspense, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Loading from 'shared/ui/Loading'
import type { LegacyRoute as LegacyRouteTypes } from 'types'
import useOrganization from 'shared/organization/useOrganization'
import SetCurrentCycle from './shared/organization/set-current-cycle'
import { LegacyAuth } from 'legacy-auth'

type LegacyRouteProps = Omit<LegacyRouteTypes, 'breadcrumbs'> & {
  requireAuthorization?: boolean
  path: string
  default?: boolean
}

const keepHistoryURL = (location: any, authRedirect: string | undefined) => {
  const { pathname, search, hash } = location ?? {}
  const endpoint = [pathname, search, hash].join('') ?? '/'
  const path = authRedirect || `/login?redirect_to=${endpoint}`

  return path
}

const LegacyRoute = ({
  component,
  requireAuthorization = true,
  unauthorize,
  authRedirect,
  ...props
}: LegacyRouteProps) => {
  const location = useLocation()
  const hasCycle = useOrganization()?.hasCycle
  const navigate = useNavigate()
  const [shouldRedirect, setShouldRedirect] = React.useState<Boolean>(false)

  React.useEffect(() => {
    if (shouldRedirect) {
      setShouldRedirect(false)
      navigate(keepHistoryURL(location, authRedirect))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRedirect])

  const Rendered = useMemo(
    () => () => {
      if (component) {
        if (requireAuthorization) {
          const Component = hasCycle ? component : SetCurrentCycle

          return (
            <LegacyAuth
              {...props}
              authorizing={<Loading />}
              unauthorize={unauthorize}
              unauthorized={() => setShouldRedirect(true)}
              Component={Component}
            />
          )
        }

        const Component = component
        return <Component {...props} />
      }

      return null
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [component, hasCycle, requireAuthorization, unauthorize],
  )

  return (
    <Suspense fallback={<Loading />}>
      <Rendered />
    </Suspense>
  )
}

export type { LegacyRouteProps }
export { keepHistoryURL, LegacyRoute }
