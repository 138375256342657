import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import { formatDate } from 'shared/helpers'
import CustomTooltip from '../CustomTooltip'
import { typeToIcon, typeToName } from 'shared/utils/conversationLookup'
import { useTemplate } from 'shared/utils/useTemplate'
import { useLocation, useNavigate } from 'react-router-dom'
import type { TeamMemberType } from 'types'
import type { UpwardEvaluation } from 'talks/types'

const EVALUATION_MAP = {
  radical_candor: 'Franqueza com Empatia',
  obnoxious_aggression: 'Franqueza com Indiferença',
  ruinous_empathy: 'Medo de Falar a Verdade',
  manipulative_insincerity: 'Mentira com Indiferença',
} as const

const UpwardEvaluationListItem = ({
  conversation,
}: {
  conversation: UpwardEvaluation
}) => {
  const template = useTemplate('evaluation')
  const { search } = useLocation()
  const navigate = useNavigate()
  const type = (
    search ? search.split('?type=')[1] : 'members'
  ) as TeamMemberType

  const {
    id: conversationId,
    teamLeaderId,
    conversationType,
    date,
    evaluation,
    comment,
  } = conversation

  const tooltipText = get(template, camelCase(`info_${evaluation}`))

  const canEdit = type === 'leaders'

  return (
    <div id={conversationId} className="relative scroll-pt-20">
      <div className="card">
        <header className="flex items-center justify-between bg-gray-lightest px-8 py-2">
          <div className="flex items-center gap-2">
            {typeToIcon(conversationType)}
            <h4 className="text-base text-gray-darkest text-opacity-80 first-letter:uppercase">
              {typeToName(conversationType)}
            </h4>
          </div>
          {canEdit && (
            <button
              className="rounded px-3 py-2 text-lg font-bold uppercase tracking-wide text-blue transition hover:bg-gray-light"
              onClick={() =>
                navigate(
                  `/conversas/lider/${teamLeaderId}/avaliacao-ascendente/${conversationId}/editar`,
                )
              }
            >
              Editar
            </button>
          )}
        </header>
        <div className="my-6 flex flex-col gap-4 px-8 py-2">
          <div>
            <h5>Data</h5>
            <p className="text-gray-500">{formatDate(date)}</p>
          </div>
          <div>
            <h5>Avaliação do liderado</h5>
            <p className="flex text-gray-500">
              {get(EVALUATION_MAP, evaluation)}{' '}
              <CustomTooltip
                text={tooltipText}
                className="!h-4 !text-gray"
                blackText={true}
              />
            </p>
          </div>
          <div>
            <h5>
              {type === 'leaders' ? 'Mensagem enviada' : 'Mensagem recebida'}
            </h5>
            <div
              className="text-gray-500"
              dangerouslySetInnerHTML={{ __html: comment }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpwardEvaluationListItem
