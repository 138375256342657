import * as React from 'react'
import _uniqueId from 'lodash/uniqueId'

type Props = {
  label: string | JSX.Element
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
}

const CheckInput: React.FC<Props> = ({ onChange, checked, label }) => {
  const [id] = React.useState(_uniqueId('checkbox-'))

  return (
    <div className="flex items-center">
      <input
        id={id}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        className="mx-[9px] h-[18px] w-[18px] rounded-sm border-2 border-gray-dark text-base text-blue hover:cursor-pointer focus:outline-0 focus:ring-0 focus:ring-offset-0"
      />
      <label htmlFor={id} className="text-base hover:cursor-pointer">
        {label}
      </label>
    </div>
  )
}

export default CheckInput
