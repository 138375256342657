import React, { useState, useEffect } from 'react'

import { email as emailValidation } from 'shared/forms/validations'
import TagsInput from 'shared/forms/TagsInput'

const isValidEmail = (email: string) =>
  Boolean(email && !emailValidation()(email))

const InviteInput = ({ setValue, setError, hasCollaborator }: any) => {
  const [emailChips, setEmailChips] = useState<any>([])
  const [inputValue, setInputValue] = useState<any>('')
  const emails = isValidEmail(inputValue)
    ? [...emailChips, inputValue]
    : emailChips

  useEffect(() => {
    if (emails.length) {
      setValue(emails)
      setError('')
    }
    if (!inputValue.length ?? isValidEmail(inputValue)) {
      setError('')
    } else {
      setError('error')
    }
    if (!emails.length && !hasCollaborator) {
      setError('error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailChips, inputValue, hasCollaborator])

  return (
    <TagsInput
      onTextChange={setInputValue}
      collection={emailChips}
      setCollection={setEmailChips}
      validate={isValidEmail}
      label="Convidar novos colaboradores por e-mail"
      name="emails"
      placeholder="nome@empresa.com"
      helperText="Adicione vários separando por vírgula"
    />
  )
}

export default InviteInput
