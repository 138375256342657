import * as React from 'react'
import { Fetch } from 'croods'
import Loading from 'shared/ui/Loading'
import getOrganizationSlug from './getOrganizationSlug'

import type { Organization } from 'types'

const OrganizationContext = React.createContext<Organization>(undefined!)

const OrganizationProvider = ({ children }: any) => {
  const slug = getOrganizationSlug()
  return (
    <Fetch
      id={slug}
      name="organization"
      renderLoading={() => (
        <div className="flex h-screen w-screen items-center justify-center">
          <Loading />
        </div>
      )}
      render={(organization) => (
        <OrganizationContext.Provider value={organization}>
          {children}
        </OrganizationContext.Provider>
      )}
    />
  )
}

const OrganizationConsumer = OrganizationContext.Consumer

export { OrganizationConsumer, OrganizationProvider, OrganizationContext }
