import { cx } from 'shared/helpers'

const Tbody = ({
  children,
  className,
  ...props
}: JSX.IntrinsicElements['tbody']) => (
  <tbody {...props} className={cx('block md:table-row-group', className)}>
    {children}
  </tbody>
)

export default Tbody
