import ReactDOM from 'react-dom'
import './index.css'
import { App } from './app'
import i18next from 'i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import pt from 'zod-i18n-map/locales/pt/zod.json'

i18next.init({
  lng: 'pt',
  resources: {
    pt: {
      zod: {
        ...pt,
        errors: {
          ...pt.errors,
          too_small: {
            ...pt.errors.too_small,
            string: {
              ...pt.errors.too_small.string,
              inclusive: 'Não pode ficar em branco',
            },
          },
          too_big: {
            ...pt.errors.too_big,
            string: {
              ...pt.errors.too_big.string,
              inclusive: 'Pode conter no máximo {{maximum}} caracter(es)',
            },
          },
        },
      },
    },
  },
})

z.setErrorMap(zodI18nMap)

ReactDOM.render(<App />, document.getElementById('root'))
