import React from 'react'
import Modal from 'shared/ui/Modal'
import HomeIcon from '@material-ui/icons/Home'
import TextLink from 'shared/ui/text-link'
import { useNavigate } from 'react-router-dom'

export default function ConversationEditDialog() {
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()

  return (
    <>
      <TextLink onClick={() => setOpen(true)} className="mr-2 text-base">
        Cancelar
      </TextLink>
      <Modal.Wrapper
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        onConfirm={async () => navigate('/')}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Modal.Header>Cancelar edição</Modal.Header>
        <Modal.Content>
          <p>
            Você deseja cancelar as edições feitas nessa conversa e ir para a
            Home?
          </p>
        </Modal.Content>
        <Modal.Actions cancelLabel="Voltar">
          <Modal.Confirm>
            <span className="flex gap-1">
              <HomeIcon className="-translate-y-px" /> Cancelar
              <span className="hidden md:inline">&nbsp;e ir para Home</span>
            </span>
          </Modal.Confirm>
        </Modal.Actions>
      </Modal.Wrapper>
    </>
  )
}
