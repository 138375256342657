import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Fetch, useCroods } from 'croods'
import type { Moment } from 'moment'
import moment from 'moment'

import ConversationPaper from 'talks/conversations/ConversationPaper'
import BaseForm from 'talks/conversations/Form'
import { useTemplate } from 'shared/utils/useTemplate'
import { useForm } from 'shared/utils/useForm'

import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { typeToIcon } from 'shared/utils/conversationLookup'
import type { FormTemplate } from 'types'
import { useNavigate, useParams } from 'react-router-dom'
import Icon from 'shared/ui/icon'
import { renderIf } from 'shared/helpers'
import RemoveUploadIcon from 'shared/ui/icon/RemoveUploadIcon'
import { useFileInput } from '../use-file-input'

const Edit = () => {
  const template = useTemplate('one_on_one')
  const { conversationId } = useParams()

  return (
    <ConversationPaper
      title="EDITAR CONVERSA"
      style={{ marginTop: '40px', padding: 0 }}
    >
      <Grid container direction="column">
        <header className="flex flex-col text-gray-darkest text-opacity-80">
          <div className="flex items-center gap-4">
            {typeToIcon('one_on_one')}
            <h4 className="text-gray-darkest first-letter:uppercase">1:1</h4>
          </div>
          <p className="mt-2 text-base italic text-gray-dark">
            Ouvir | Clarificar | Remover obstáculos
          </p>
          <p className="mt-4 text-base text-gray-dark">
            São conversas rápidas (de 15 a 30 minutos) mensais com cada pessoa
            do time com o objetivo de ouvir a pessoa, clarificar ideias, remover
            obstáculos e fazer o trabalho fluir.
          </p>
        </header>
        <Fetch
          name="OneOnOne"
          customPath={`/one_on_one_conversations/${conversationId}`}
          cache
          render={(conversationsList) => (
            <Form template={template} conversation={conversationsList} />
          )}
        />
      </Grid>
    </ConversationPaper>
  )
}

type FormProps = {
  conversation: any
  template: FormTemplate
}

type OneOnOneFormValues = {
  date: Moment
  comment: string
}

type FormFile = {
  name: string
  type: string
  content?: string
  url: string | null
  id: string | null
}

const Form = ({ conversation, template }: FormProps) => {
  const { formState, fields, hasErrors } = useForm<OneOnOneFormValues>(
    {
      ...conversation,
      date: moment(conversation.date),
    },
    {
      requiredFields: ['date', 'comment'],
    },
  )

  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const [{ saving, saveError }, { save, resetState }] = useCroods({
    name: 'oneOnOneConversations',
    id: conversation.id,
    path: `/one_on_one_conversations`,
    afterSuccess: () => {
      snackbar({ message: 'Atualizado com sucesso!', type: 'success' })
      navigate(`/`)
    },
  })

  const { files, addFile, removeFile } = useFileInput(conversation.attachments)

  // This is only used to clear the Croods saveError state
  React.useEffect(() => resetState, []) //eslint-disable-line

  return (
    <BaseForm
      onSubmit={handleSubmit({ save, values: { ...formState.values, files } })}
      template={template}
      fields={fields}
      saving={saving}
      saveError={saveError}
      disabled={hasErrors}
      isEditMode
    >
      <details open={Boolean(files?.length > 0)}>
        <summary className="hover:cursor-pointer select-none flex items-center">
          <p className="mb-1 mt-[6px] mr-1">Adicionar anexos</p>
          <Icon
            icon="arrow-down"
            size="sm"
            className="summary-arrow inline transition-transform"
          />
        </summary>
        <div className="flex relative items-center rounded justify-center outline-blue outline-dashed outline-1 h-24">
          <input
            type="file"
            multiple={true}
            className="absolute w-[100%] h-[100%] opacity-0 cursor-pointer"
            onChange={addFile}
            onClick={(event) => (event.currentTarget.value = '')}
            title="Adicionar anexo"
          />
          <button className="bt bt-outlined" type="button">
            <Icon icon="attach-file" size="sm" className="inline" />
            &nbsp; Adicionar Anexo
          </button>
        </div>
        {renderIf(
          files.length > 0,
          <ul className="flex flex-col mt-4 p-2 gap-2">
            {files.map(({ name, content, url, type }, i) => {
              const preview =
                type.split('/')[0] === 'image' ? (
                  <img className="w-8 h-8" src={(content || url)!} alt={name} />
                ) : type === 'application/pdf' ? (
                  <Icon
                    icon="upload-pdf"
                    className="inline w-4 h-4 fill-gray-dark"
                  />
                ) : (
                  <Icon
                    icon="upload-document"
                    className="inline w-4 h-4 fill-gray-dark"
                  />
                )

              const description = url ? (
                <a href={url} rel="noreferrer" target="_blank">
                  {name}
                </a>
              ) : (
                name
              )

              return (
                <li className="flex justify-start items-center gap-2" key={i}>
                  <div className="w-8">{preview}</div>
                  <div className="mr-auto">{description}</div>
                  <button
                    className="hover:bg-gray-100 rounded-full p-3"
                    type="button"
                    onClick={() => removeFile(i)}
                  >
                    <RemoveUploadIcon />
                  </button>
                </li>
              )
            })}
          </ul>,
        )}
        <p className="text-gray-dark mt-1 text-sm">
          Adicione arquivos que ficarão salvos no histórico da conversa.
        </p>
      </details>
    </BaseForm>
  )
}

type SaveFn = (params: {
  method: 'put'
}) => (params: { date: string; comment: string }) => void

export const handleSubmit =
  ({
    save,
    values: { comment, date, files },
  }: {
    save: SaveFn
    values: OneOnOneFormValues & { files?: FormFile[] }
  }) =>
  (event: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault && event.preventDefault()

    const convertedValues = {
      comment,
      files,
      date: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }

    save({ method: 'put' })(convertedValues)
  }

export default Edit
