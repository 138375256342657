import { useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import flatten from 'lodash/flatten'
import compact from 'lodash/compact'
import { useForm } from 'shared/utils/useForm'
import AutocompleteWithSearch from 'shared/forms/AutocompleteWithSearch'
import AddQuestions from '../../addQuestions'
import validateQuestions from 'settings/solicitationTemplate/new/parsers/validateQuestions'
import InfoTooltip from 'shared/ui/Tooltip/InfoTooltip'

const ApproverTooltip = () => (
  <div className="relative">
    <div className="absolute z-50 ml-[85px]">
      <InfoTooltip fontSize="small">
        <div className="flex flex-col gap-3 p-2 text-left text-gray-darker">
          <div className="mb-2">
            <h6 className="font-bold text-gray-darkest">APROVADORES</h6>
            <p>Escolha quem pode aprovar essa solicitação.</p>
          </div>

          <div>
            <p className="font-bold">Nome de Colaborador</p>
            <p className="font-light">Colaborador específico.</p>
          </div>

          <div>
            <p className="font-bold">Nome de Círculo</p>
            <p className="font-light">
              Membros do Círculo específico, exceto seu líder.
            </p>
          </div>

          <div>
            <p className="font-bold">&quot;Líder do solicitante&quot;</p>
            <p className="font-light">
              Líder do círculo ao qual o solicitante responde.
            </p>
          </div>
        </div>
      </InfoTooltip>
    </div>
  </div>
)

type Props = {
  title: string
  filledValues: any
  onChange: Function
  allApprovalAttributes: Array<any>
}

const ApproverFields = ({
  title,
  filledValues,
  onChange,
  allApprovalAttributes,
}: Props) => {
  const initialState = {
    id: filledValues?.id,
    approversAttributes: filledValues?.approversAttributes || [],
    inputsAttributes: filledValues?.inputsAttributes || [],
  }

  const { formState, fields } = useForm(initialState, {
    onChange: (_event, _currentState, newState) => {
      onChange(newState)
    },
    requiredFields: ['approversAttributes'],
  })

  const allInputsAttributes = compact(
    flatten(
      allApprovalAttributes.map(
        (approvalAttributes) => approvalAttributes.inputsAttributes,
      ),
    ),
  )

  useEffect(() => {
    if (filledValues) {
      for (const [attribute, value] of Object.entries(filledValues)) {
        formState.setField(attribute as keyof typeof initialState, value)
      }
    }
  }, [formState, filledValues])

  const autocompleteFields = fields(
    'raw',
    'approversAttributes',
    [(value) => (isEmpty(value) ? 'Campo obrigatório' : undefined)],
    {
      touchOnChange: true,
      compare: isEqual,
    },
  )

  return (
    <div className="mb-4">
      {title && (
        <h2 className="text-xl mb-2">
          {title}
        </h2>
      )}
      <div>
        <ApproverTooltip />

        <AutocompleteWithSearch
          croodsConfig={{
            name: 'search_approver',
            path: '/search/approver',
          }}
          autocompleteProps={{
            ...autocompleteFields,
            disabledOptions: (option: {
              label: string
              value: string | number
              kind?: any
            }) =>
              (autocompleteFields.value as unknown as any[]).some(
                (anotherOption) =>
                  option.kind === anotherOption.kind &&
                  option.value === anotherOption.value,
              ),
            getOptionSelected: (option, anotherOption) =>
              option.kind === anotherOption.kind &&
              option.value === anotherOption.value,
          }}
          inputProps={{
            value: '',
            label: 'Aprovadores',
            name: 'Aprovadores',
            placeholder:
              'Ex. João da Silva, Financeiro, Líder do solicitante, etc.',
          }}
          defaultValue={initialState.approversAttributes}
        />
        <p className="form-helper-text mb-6">
          Selecione colaboradores ou círculos específicos utilizando seus nomes. Para aprovadores genéricos, utilize o papel &quot;Líder do solicitante&quot;.
        </p>
      </div>

      <div>
        <AddQuestions
          // @ts-expect-error
          {...fields('raw', 'inputsAttributes', [validateQuestions()], {
            touchOnChange: true,
            compare: isEqual,
          })}
          step="approver"
          siblingsQuestionsList={allInputsAttributes}
        />
      </div>
    </div>
  )
}

export default ApproverFields
