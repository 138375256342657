import React from 'react'
import Typography from '@material-ui/core/Typography'

const Text = ({ value, ...props }: any) => (
  <Typography variant="body1" color="textSecondary" {...props}>
    {value}
  </Typography>
)

export default Text
