import React from 'react'
import { Disclosure } from '@headlessui/react'

type Props = {
  children: React.ReactNode
  section?: string
  title: string
  chips?: React.ReactNode
}

export default function Section({ children, section, title, chips }: Props) {
  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <>
          <Disclosure.Button as="div" className="mt-10">
            {section && (
              <p className="text-sm font-bold !leading-none text-gray-dark">
                / {section}
              </p>
            )}
            <div className="flex justify-between">
              <div className="flex items-center justify-start gap-5 mb-5">
                <h4 className="italic text-gray-darkest ">{title}</h4>
                {chips}
              </div>
              <div className="flex items-center justify-center rounded-full test h-7 w-7">
                <svg
                  aria-hidden="true"
                  className=""
                  data-reactid="266"
                  fill="none"
                  height="24"
                  stroke="#606F7B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline
                    points={`${!open ? '6 9 12 15 18 9' : '18 15 12 9 6 15'}`}
                  />
                </svg>
              </div>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
