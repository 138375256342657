import { Evaluator } from '@seasonedsoftware/ui'
import StarRounded from '@material-ui/icons/StarRounded'
import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded'
import { cx } from 'shared/helpers'

type Props = {
  id: number
  developmentCommitment?: number
  handleSave?: (number: number) => void
  isCompleted?: boolean
  isPaused?: boolean
}

type ChangeProps = {
  [index: string]: number
}

export default function DevelopmentCommitment({
  id,
  developmentCommitment,
  handleSave,
  isCompleted,
  isPaused,
}: Props) {
  const handleChange = (data: ChangeProps) => {
    const value = data[`developmentCommitment-${id}`]
    if (handleSave) {
      handleSave(value)
    }
  }

  return (
    <div className="p-4">
      <p className="mb-2 text-base font-bold leading-4">
        Comprometimento com o autodesenvolvimento
      </p>
      <div
        className={cx(
          'inline-block rounded-lg border p-2',
          isCompleted
            ? 'green-stars'
            : isPaused
              ? 'gray-stars'
              : 'blue-stars focus-within:border-blue',
        )}
      >
        <Evaluator
          subjects={[`developmentCommitment-${id}`]}
          onChange={handleChange}
          value={developmentCommitment}
          emptyIcon={<StarOutlineRoundedIcon />}
          icon={<StarRounded />}
          readOnly={isCompleted || isPaused}
        />
      </div>
    </div>
  )
}
