import Icon from 'shared/ui/icon'
import BaseSvgIcon from 'shared/ui/icon/BaseSvgIcon'

import ConversationIconOutline from 'shared/ui/icon/ConversationIconOutline'
import PortalIcon from 'shared/ui/icon/PortalIcon'

import type { IconProps } from 'shared/ui/icon'
import PerformanceIconOutline from 'shared/ui/icon/PerformanceIconOutline'
import CareIconOutline from 'shared/ui/icon/CareIconOutline'
import AutoGraphIconOutline from 'shared/ui/icon/AutoGraphIconOutline'
import { MyFeedback } from 'shared/ui/icon/my-feedback'
import { Diamond } from 'shared/ui/icon/diamond'
import { AddToCircle } from 'shared/ui/icon/add-to-circle'

function getIcon(icon: string, props?: {}) {
  const iconMap: Record<string, IconProps['icon']> = {
    home: 'home-outline',
    lamp: 'emoji-objects-outline',
    mainTeam: 'supervised-user-circle',
    people: 'supervised-user-circle-outline',
    'baloon-plus': 'add-comment-outline',
    next: 'chat-outline',
    person: 'person-outline',
    'person-plus': 'person-add-outline',
    chart: 'insert-chart-outlined',
    dashboard: 'speed-outline',
    history: 'history-outline',
    trophy: 'emoji-events-outline',
    plus: 'add-circle-outline',
    bell: 'notifications-outline',
    folder: 'folder-open-outline',
    assignmentindicon: 'assignment-ind-outline',
    cached: 'cached',
    description: 'description',
    calendar: 'calendar-today-outline',
    inbox: 'move-to-inbox-outline',
    'all-inclusive': 'all-inclusive',
    'alarm-add': 'alarm-add',
    'file-text': 'file-text',
    fingerprint: 'fingerprint',
    vacations: 'vacations',
    travel: 'travel',
    invitation: 'invitation',
    'thumbs-up': 'thumbs-up',
    'thumbs-down': 'thumbs-down',
    'rate-review': 'rate-review',
    assignment: 'assignment-outline',
    delete: 'delete',
    edit: 'edit',
    'delete-forever-outline': 'delete-forever-outline',
  }

  const svgIconMap: any = {
    conversation: ConversationIconOutline,
    portal: PortalIcon,
    performance: PerformanceIconOutline,
    care: CareIconOutline,
    'auto-graph': AutoGraphIconOutline,
    'my-feedback': MyFeedback,
    diamond: Diamond,
    'add-to-circle': AddToCircle,
  }

  const iconName = iconMap[icon]
  const IconSvg = svgIconMap[icon]

  if (IconSvg) {
    return <BaseSvgIcon Component={IconSvg} {...props} />
  }

  return iconName ? <Icon icon={iconName} {...props} /> : <></>
}

export default getIcon
