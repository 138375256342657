import { useState } from 'react'
import {
  SearchParamsForm,
  SearchParamsLink,
  list,
} from '@remaster-run/react-router'
import { getUserEnv } from 'domain/auth'
import { List, Form } from 'themes/main'
import Icon from 'shared/ui/icon'
import {
  Outlet,
  type LoaderFunctionArgs,
  useLocation,
  useNavigate,
  useNavigation,
  useLoaderData,
  type ShouldRevalidateFunction,
} from 'react-router-dom'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import type { RouteHandle } from 'domain/routes'

import {
  fetchFilteredSolicitations,
  solicitationFiltersFormSchema,
} from 'domain/solicitations'
import { cx, renderIf } from 'shared/helpers'
import type { UnpackData } from 'domain-functions'
import { inputFromSearch } from 'domain-functions'
import concat from 'lodash/concat'
import uniq from 'lodash/uniq'
import AutocompleteWithSearch from 'shared/forms/AutocompleteWithSearch'
import type { Option } from 'shared/forms/AutocompleteWithSearch/Autocomplete'
import { DotsInterval } from 'shared/ui/icon/dots-interval'

export const shouldRevalidate: ShouldRevalidateFunction = ({
  defaultShouldRevalidate,
  currentUrl,
  nextUrl,
}) => {
  if (!defaultShouldRevalidate) return false

  if (currentUrl.pathname !== '/configuracoes/gestao-de-solicitacoes') {
    return true
  }

  if (nextUrl.pathname !== '/configuracoes/gestao-de-solicitacoes') {
    return true
  }

  const currentSearch = new URLSearchParams(currentUrl.search)
  currentSearch.delete('selected[]')

  const nextSearch = new URLSearchParams(nextUrl.search)
  nextSearch.delete('selected[]')

  return currentSearch.toString() !== nextSearch.toString()
}

export const handle: RouteHandle = {
  title: 'Gestão de Solicitações',
  category: 'settings',
  breadcrumbs: ['home', 'solicitationsManagement'],
}

export async function loader({ request, params }: LoaderFunctionArgs) {
  const environment = await getUserEnv(request)
  return list(fetchFilteredSolicitations)({ request, params, environment })
}

function setSelectedFilters(
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: Function,
  statusList: String[] | undefined,
  status: String,
) {
  if (event.target.checked) {
    setValue('status', uniq(concat(statusList || [], status)))
  } else {
    setValue(
      'status',
      statusList && uniq(statusList.filter((s) => s !== status)),
    )
  }
}

export function Component() {
  const navigate = useNavigate()
  const { state } = useNavigation()
  const { search } = useLocation()
  const filterValues = inputFromSearch(new URLSearchParams(search))

  const selectedValues = Array.isArray(filterValues.selected)
    ? (filterValues.selected as string[])
    : []

  const hasSelectedItems = selectedValues.length > 0
  const [filterBoxOpen, setFilterBoxOpen] = useState<boolean>(false)
  const isLoading = state === 'loading'
  const enableCancelSelected = hasSelectedItems && !isLoading

  const { templateOptions, items } = useLoaderData() as UnpackData<
    typeof fetchFilteredSolicitations
  >

  const selectableItems = items.filter(({ canCancel }) => canCancel)

  const allSelected =
    selectableItems.length > 0 &&
    selectableItems.filter(({ id }) => selectedValues.includes(String(id)))
      .length === selectableItems.length

  return (
    <>
      <div className="flex flex-col mb-6">
        <h3>Gestão de Solicitações</h3>
        <Form
          method="get"
          schema={solicitationFiltersFormSchema}
          values={{ status: [], ...filterValues }}
          labels={{
            createdFrom: 'Abertura a partir de',
            createdUntil: 'Abertura até',
            solicitationTemplateId: 'Modelo de Solicitação',
            userId: 'Solicitante',
            status: 'Situação',
            solicitationIdFilter: 'Nº da Solicitação',
            order: 'Ordenar por',
          }}
          options={{
            order: [
              { name: 'Mais antigas', value: 'asc' },
              { name: 'Mais recentes', value: 'desc' },
            ],
            solicitationTemplateId: templateOptions,
          }}
          emptyOptionLabel="Todos"
        >
          {({ Field, reset, submit, watch, setValue }) => {
            const status = watch('status')
            const userId = watch('userId')
            const createdFrom = watch('createdFrom')
            const createdUntil = watch('createdUntil')

            const handleSelectUserId = (
              props: string | Option | (string | Option)[] | null,
            ) => {
              if (
                typeof props === 'object' &&
                !Array.isArray(props) &&
                props !== null
              ) {
                setValue('userId', String(props.value))
              } else {
                if (props === null) {
                  setValue('userId', '')
                }
              }
            }

            return (
              <>
                <div className="rounded-lg shadow-md mt-6 overflow-hidden">
                  <a
                    rel="noopener noreferrer"
                    className="cursor-pointer"
                    data-testid="filter-expandable-panel-button"
                    onClick={() => setFilterBoxOpen(!filterBoxOpen)}
                  >
                    <div
                      className={cx(
                        'flex justify-between h-14 items-center px-6',
                        filterBoxOpen && 'bg-blue-lightest',
                      )}
                    >
                      <h2 className="flex text-lg text-gray-darkest">
                        Filtros
                      </h2>
                      <Icon
                        className="text-gray-dark"
                        icon={filterBoxOpen ? 'arrow-up' : 'arrow-down'}
                        title={filterBoxOpen ? 'recolher' : 'expandir'}
                      />
                    </div>
                  </a>
                  {renderIf(
                    filterBoxOpen,
                    <div className="grid gap-y-5 p-6">
                      <section className="flex flex-col md:flex-row gap-4">
                        <section className="flex justify-between gap-2">
                          <Field
                            name="createdFrom"
                            type="date"
                            className="min-w-[8.5rem] w-5/12"
                          >
                            {({ Label, Input, Errors }) => (
                              <>
                                <Label />
                                <Input />
                                <button
                                  type="button"
                                  onClick={() => setValue('createdFrom', '')}
                                  className="text-blue font-bold self-start"
                                  hidden={!Boolean(createdFrom)}
                                >
                                  Limpar
                                </button>
                                <Errors />
                              </>
                            )}
                          </Field>
                          <DotsInterval className="text-blue mt-12 w-2/12 min-w-[1rem] hidden sm:block" />
                          <Field
                            name="createdUntil"
                            type="date"
                            className="min-w-[8.5rem] w-5/12"
                          >
                            {({ Label, Input, Errors }) => (
                              <>
                                <Label />
                                <Input />
                                <button
                                  type="button"
                                  onClick={() => setValue('createdUntil', '')}
                                  className="text-blue font-bold self-start"
                                  hidden={!Boolean(createdUntil)}
                                >
                                  Limpar
                                </button>
                                <Errors />
                              </>
                            )}
                          </Field>
                        </section>
                        <section className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full">
                          <Field name="solicitationTemplateId" />
                          <input type="hidden" name="userId" value={userId} />
                          <AutocompleteWithSearch<{
                            id: number
                            alias: string
                          }>
                            parseOptions={(users) =>
                              users.map((user) => ({
                                label: user.alias,
                                value: user.id,
                              }))
                            }
                            defaultValue={null}
                            croodsConfig={{
                              name: 'applicants',
                              path: '/supervisor/solicitations/applicants',
                            }}
                            autocompleteProps={{
                              value: String(userId),
                              noOptionsText: 'Nenhum solicitante encontrado',
                              multiple: false,
                              onChange: handleSelectUserId,
                              getOptionSelected: (option, selectedOption) =>
                                option.value === selectedOption.value,
                            }}
                            inputProps={{
                              label: 'Solicitante',
                              InputProps: {
                                inputLabelProps: {
                                  style: {
                                    color: '#2D2D2C',
                                    height: '1.125rem',
                                    marginBottom: '0.6rem',
                                    marginTop: '-0.1rem',
                                  },
                                },
                                style: {
                                  height: '2.725rem',
                                },
                              },
                            }}
                            debounceTime={300}
                          />
                        </section>
                      </section>
                      <section className="flex flex-col-reverse md:flex-row md:flex-wrap gap-5 justify-between">
                        <div className="flex flex-col gap-2">
                          <label className="font-bold text-lg text-gray-darkest">
                            Situação
                          </label>
                          <div className="flex flex-col md:flex-row md:flex-wrap gap-4 md:gap-10 lg:h-10 md:items-end ml-2 md:ml-0">
                            <div className="flex gap-2 items-center">
                              <input
                                id="statusPendingApproval"
                                type="checkbox"
                                className="border-blue text-blue focus:ring-blue border rounded h-5 w-5"
                                checked={status?.includes('pending-approval')}
                                onChange={(event) => {
                                  setSelectedFilters(
                                    event,
                                    setValue,
                                    status,
                                    'pending-approval',
                                  )
                                }}
                              />
                              <label
                                htmlFor="statusPendingApproval"
                                className={cx(
                                  'text-lg font-semibold',
                                  status?.includes('pending-approval')
                                    ? 'text-blue'
                                    : 'text-gray-darker',
                                )}
                              >
                                Aprovação Pendente
                              </label>
                            </div>
                            <div className="flex gap-2 items-center">
                              <input
                                id="statusPendingExecution"
                                type="checkbox"
                                className="border-blue text-blue focus:ring-blue border rounded h-5 w-5"
                                checked={status?.includes('pending-execution')}
                                onChange={(event) => {
                                  setSelectedFilters(
                                    event,
                                    setValue,
                                    status,
                                    'pending-execution',
                                  )
                                }}
                              />
                              <label
                                htmlFor="statusPendingExecution"
                                className={cx(
                                  'text-lg font-semibold',
                                  status?.includes('pending-execution')
                                    ? 'text-blue'
                                    : 'text-gray-darker',
                                )}
                              >
                                Execução Pendente
                              </label>
                            </div>
                            <div className="flex gap-2 items-center">
                              <input
                                id="statusCompleted"
                                type="checkbox"
                                className="border-blue text-blue focus:ring-blue border rounded h-5 w-5"
                                checked={status?.includes('completed')}
                                onChange={(event) => {
                                  setSelectedFilters(
                                    event,
                                    setValue,
                                    status,
                                    'completed',
                                  )
                                }}
                              />
                              <label
                                htmlFor="statusCompleted"
                                className={cx(
                                  'text-lg font-semibold',
                                  status?.includes('completed')
                                    ? 'text-blue'
                                    : 'text-gray-darker',
                                )}
                              >
                                Executada
                              </label>
                            </div>
                            <div className="flex gap-2 items-center">
                              <input
                                id="statusRejected"
                                type="checkbox"
                                className="border-blue text-blue focus:ring-blue border rounded h-5 w-5"
                                checked={status?.includes('rejected')}
                                onChange={(event) => {
                                  setSelectedFilters(
                                    event,
                                    setValue,
                                    status,
                                    'rejected',
                                  )
                                }}
                              />
                              <label
                                htmlFor="statusRejected"
                                className={cx(
                                  'text-lg font-semibold',
                                  status?.includes('rejected')
                                    ? 'text-blue'
                                    : 'text-gray-darker',
                                )}
                              >
                                Reprovada
                              </label>
                            </div>
                            <div className="flex gap-2 items-center">
                              <input
                                id="statusCanceled"
                                type="checkbox"
                                className="border-blue text-blue focus:ring-blue border rounded h-5 w-5"
                                checked={status?.includes('canceled')}
                                onChange={(event) => {
                                  setSelectedFilters(
                                    event,
                                    setValue,
                                    status,
                                    'canceled',
                                  )
                                }}
                              />
                              <label
                                htmlFor="statusCanceled"
                                className={cx(
                                  'text-lg font-semibold',
                                  status?.includes('canceled')
                                    ? 'text-blue'
                                    : 'text-gray-darker',
                                )}
                              >
                                Cancelada
                              </label>
                            </div>
                          </div>
                          {status &&
                            status.map((stat) => (
                              <input
                                type="hidden"
                                name="status[]"
                                value={stat}
                                key={stat}
                              />
                            ))}
                        </div>
                        <Field
                          name="solicitationIdFilter"
                          className="w-36"
                          type="number"
                        />
                      </section>
                      <section className="flex md:gap-6 md:mt-6 mt-1 md:justify-end justify-between">
                        <button
                          type="button"
                          className="bt bt-outlined"
                          onClick={() => {
                            reset({
                              createdFrom: '',
                              createdUntil: '',
                              solicitationTemplateId: '',
                              userId: '',
                              solicitationIdFilter: '',
                            })
                            navigate('.')
                          }}
                        >
                          Limpar filtros
                        </button>
                        <button
                          type="submit"
                          className="bt bt-blue"
                          disabled={isLoading}
                        >
                          Aplicar filtros
                        </button>
                      </section>
                    </div>,
                  )}
                </div>
                <div className="flex flex-col flex-wrap md:w-40 w-full self-end gap-2 md:gap-1">
                  <Field name="order" onChange={() => submit()} />
                </div>
              </>
            )
          }}
        </Form>
      </div>
      <div className="relative md:-mt-[3.8rem] mb-7 md:w-fit w-full flex justify-center flex-col gap-4">
        {enableCancelSelected ? (
          <SearchParamsLink
            preserve="all"
            preventScrollReset
            to="cancelar-solicitacoes"
            className="bt bt-blue flex items-center justify-center w-full"
          >
            Cancelar selecionadas
          </SearchParamsLink>
        ) : (
          <button className="bt bt-blue w-full" disabled>
            Cancelar selecionadas
          </button>
        )}
        {selectableItems.length > 0 && (
          <div className="md:hidden">
            {allSelected ? (
              <SearchParamsForm
                preserve="all"
                customValues={{ 'selected[]': null }}
              >
                <button className="bt bt-outlined w-full">
                  Desselecionar todas
                </button>
              </SearchParamsForm>
            ) : (
              <SearchParamsForm
                preserve="all"
                customValues={{ 'selected[]': null }}
              >
                {selectableItems.map(({ id }) => (
                  <input key={id} type="hidden" name="selected[]" value={id} />
                ))}
                <button className="bt bt-outlined w-full">
                  Selecionar todas
                </button>
              </SearchParamsForm>
            )}
          </div>
        )}
      </div>
      <List<typeof loader>
        preserveSearchParams="all"
        customSearchValues={{ 'selected[]': null }}
        actions={[]}
        canSelectItem={(item) => item.canCancel}
        itemActions={['info', 'select']}
        titleField="id"
        titleLabel="Nº"
        hiddenFields={['canCancel']}
        labels={{
          status: 'Situação',
          solicitationTemplateName: 'Modelo de solicitação',
          createdAt: 'Abertura',
          requesterFullName: 'Solicitante',
        }}
        renderTableActions={({ item }) => (
          <div className="flex justify-end">
            <SearchParamsLink
              to={String(item.id)}
              preventScrollReset={true}
              preserve="all"
              className="flex items-center gap-2 px-2 py-1 text-blue-400 border border-blue-400 rounded-md outline-none hover:bg-blue-50 no-underline"
            >
              <Icon icon="visibility" size="sm" />
              <span className="md:hidden">SOLICITAÇÃO</span>
            </SearchParamsLink>
          </div>
        )}
      >
        {({ data, Table, Pagination }) => {
          if (!data) return null

          const { items } = data

          if (items.length === 0)
            return (
              <FeedbackMessage
                message="Não foram encontradas solicitações"
                type="warning"
                serverMessage=""
              />
            )

          return (
            <>
              <Table>
                {({ Head, Body }) => (
                  <>
                    <colgroup>
                      <col width="1" />
                      <col width="1" />
                      <col width="3" />
                      <col width="3" />
                      <col width="4" />
                      <col width="4" />
                      <col width="1" />
                    </colgroup>
                    <Head />
                    <Body />
                  </>
                )}
              </Table>
              <Pagination />
            </>
          )
        }}
      </List>
      <Outlet />
    </>
  )
}
