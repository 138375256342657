import Modal from 'shared/ui/Modal'
import AiIcon from 'shared/ui/icon/AiIcon'
import { AILoadingAnimation } from './ai-loading-animation'

type LoadingDialogProps = {
  open: boolean
  onClose: () => void
}
const LoadingDialog = ({ open, onClose }: LoadingDialogProps) => (
  <Modal.Wrapper open={open} onClose={onClose}>
    <Modal.Header>
      <h5 className="flex items-center">
        <AiIcon fill="#2D2D2C" className="inline pr-2" />
        AI trabalhando. Pode levar em torno de 45 segundos.
      </h5>
    </Modal.Header>
    <Modal.Content>
      <div className="flex w-[600px] flex-col items-center self-center">
        <AILoadingAnimation />
      </div>
    </Modal.Content>
    <Modal.Actions cancelLabel="CANCELAR" />
  </Modal.Wrapper>
)

export { LoadingDialog }
