import { Fragment } from 'react'

import compact from 'lodash/compact'

import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import FeedbackMessage from 'shared/ui/FeedbackMessage'
import Documents from 'shared/ui/Documents'
import type { ParsedSolicitation } from 'portal/helpers'

import SolicitationDetails from 'portal/clocking-panel/solicitation/SolicitationDetails'
import { joinInputsHour } from 'portal/clocking-panel/helpers'
import { formatDateUnits } from 'shared/helpers'
import { VacationActions } from './VacationActions'
import { Actions } from './Actions'

type Props = Pick<
  ParsedSolicitation,
  | 'description'
  | 'id'
  | 'automationIdentifier'
  | 'fixedFieldsAnswers'
  | 'prefixedValues'
  | 'fullName'
  | 'vacationStartDate'
  | 'status'
>

const SolicitationDescription = ({
  description: { questions, hasUnfilledRequiredFields },
  automationIdentifier,
  fixedFieldsAnswers,
  prefixedValues,
  id,
  fullName,
  vacationStartDate,
  status,
}: Props) => {
  if (
    questions.length === 0 &&
    automationIdentifier !== 'clocking' &&
    automationIdentifier !== 'vacation'
  )
    return (
      <FeedbackMessage type="warning" message="Não há perguntas cadastradas" />
    )

  return (
    <div className="mx-4 my-8 flex flex-col">
      {(
        automationIdentifier === 'clocking' ?

        <ClockingDescription
          fixedFieldsAnswers={fixedFieldsAnswers}
          id={id}
          prefixedValues={prefixedValues}
        /> :

        <DefaultDescription questions={questions} />
      )}

      {(
        automationIdentifier === 'vacation' ?

        <VacationActions
          fullName={fullName}
          vacationStartDate={vacationStartDate!}
        /> :

        <>
          <hr className="colors-gray my-8" />
          <Actions
            id={id}
            hasUnfilledRequiredFields={hasUnfilledRequiredFields}
            status={status}
          />
        </>
      )}
    </div>
  )
}

type DefaultDescriptionProps = {
  questions: Props['description']['questions']
}

const DefaultDescription = ({ questions = [] }: DefaultDescriptionProps) => (
  <ul>
    {questions.map(({ id: questionId, inputType: type, question, answer }) => {
      return (
        <Fragment key={questionId}>
          <li className="mb-2">
            <h6 className="text-sm font-bold text-gray-dark">{question}</h6>
          </li>
          {type === 'upload' ? (
            <Documents
              documents={(
                answer as { fileName: string; fileUrl: string }[]
              ).map((document) => ({
                name: document.fileName,
                url: document.fileUrl,
              }))}
            />
          ) : (
            <li className="mb-4 ml-4">
              <p className="whitespace-pre-wrap text-sm text-gray">{answer}</p>
            </li>
          )}
        </Fragment>
      )
    })}
  </ul>
)

type ClockingDescriptionProps = Pick<
  ParsedSolicitation,
  'fixedFieldsAnswers' | 'prefixedValues'
> & { id: number }

const ClockingDescription = ({
  fixedFieldsAnswers,
  id,
  prefixedValues,
}: ClockingDescriptionProps) => {
  const date = formatDateUnits(prefixedValues?.masterDate)
  const timePerformed = compact(prefixedValues.moments)
    .map((moment) => format(parseISO(String(moment).replace('Z', '')), 'HH:mm'))
    .join(' | ')
  const addedTimes = joinInputsHour(
    fixedFieldsAnswers.timeInputs as unknown as string[],
  )

  return (
    <SolicitationDetails
      date={date}
      id={id}
      addedTimes={addedTimes || 'Nenhum horário adicionado'}
      timePerformed={timePerformed}
      reason={fixedFieldsAnswers.reasonText}
      observation={fixedFieldsAnswers.observation}
      estimatedTime={prefixedValues.defaultMoments}
    />
  )
}

export default SolicitationDescription
