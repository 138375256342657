import type { ChangeEvent, Dispatch, SetStateAction } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import CreateRoundedIcon from '@material-ui/icons/CreateRounded'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { cx, renderIf } from 'shared/helpers'

type CommonProps = {
  value: string
  name: string
  setValue: Dispatch<SetStateAction<string>>
  placeholder?: string
  className?: string
}

type TextAreaProps = {
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onKeyPress?: (el: React.KeyboardEvent<HTMLTextAreaElement>) => void
  autoFocus?: boolean
  highlighted?: boolean
  maxLength?: number
  label?: string
} & CommonProps

export function TextAreaPencil({
  value,
  setValue,
  name,
  label,
  onChange,
  onBlur,
  onKeyPress,
  placeholder,
  className,
  autoFocus = false,
  highlighted,
  maxLength,
}: TextAreaProps) {
  const [editing, setEditing] = useState(autoFocus)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const sendCursorToEnd = (currentValue: string = value) => {
    if (textAreaRef.current) {
      const cursorPosition = currentValue.length
      textAreaRef.current.setSelectionRange(cursorPosition, cursorPosition)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
    if (onChange) onChange(e)
  }

  const onClick = () => {
    setEditing(true)
  }

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setEditing(false)
    if (onBlur) onBlur(e)
  }

  const handleKeyPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      setEditing(false)
      if (onKeyPress) onKeyPress(e)
    }
  }

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus()
      sendCursorToEnd()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing])

  if (editing) {
    return (
      <div className="my-4 flex w-full flex-col gap-2">
        {renderIf(
          Boolean(label),
          <label className="label text-base">{label}</label>,
        )}
        <TextareaAutosize
          maxLength={maxLength}
          data-testid={name}
          name={name}
          ref={textAreaRef}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          className={cx(
            className,
            'rounded border border-gray-500 outline-none focus-within:border-2 focus-within:border-blue-light focus:ring-0',
          )}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onKeyPress={handleKeyPressEnter}
        />
      </div>
    )
  }

  return (
    <Button
      onClick={onClick}
      value={value}
      placeholder={placeholder}
      className={className}
      highlighted={highlighted}
    />
  )
}

type ButtonProps = {
  onClick: () => void
  value: CommonProps['value']
  placeholder?: CommonProps['placeholder']
  className?: string
  highlighted?: boolean
}

const Button = ({
  onClick,
  value,
  placeholder,
  className,
  highlighted,
}: ButtonProps) => (
  <button
    data-testid="editable-field"
    type="button"
    className={cx(
      className,
      `${highlighted ? 'text-blue-500' : ''}`,
      'inline cursor-pointer text-left text-base',
    )}
    onClick={onClick}
  >
    {value || placeholder || ''}
    <span className="ml-2">
      <CreateRoundedIcon className="!text-lg text-blue-500" />
    </span>
  </button>
)
