import { useState } from 'react'
import flow from 'lodash/flow'
import delay from 'lodash/delay'

import Modal from 'shared/ui/Modal'
import SelectableQuestions from './SelectableQuestions'
import RenderedQuestion from './RenderedQuestion'
import questionsTypes from '../questionTypes'

type Props = {
  open: boolean
  handleClose: () => void
  list: Array<any>
  handleSubmit: (state: any) => void
  editingIndex: number | null
  step: any
  questionType: string
  siblingsQuestionsList?: Array<any>
}

const QuestionTypeSelector = ({
  open,
  handleClose,
  list,
  handleSubmit,
  editingIndex,
  step,
  questionType,
  siblingsQuestionsList,
}: Props) => {
  const [type, setType] = useState(questionType)
  const resetStatus = () => delay(() => setType('selector'), 150)
  const handleCloseAndStatus = flow(handleClose, resetStatus)

  if (type === 'selector') {
    return (
      <Modal.Wrapper open={open} onClose={handleCloseAndStatus}>
        <SelectableQuestions
          title="Escolha o tipo de pergunta"
          questionsTypes={questionsTypes}
          setType={setType}
          handleCloseAndStatus={handleCloseAndStatus}
          list={list}
          siblingsQuestionsList={siblingsQuestionsList}
        />
      </Modal.Wrapper>
    )
  }

  const question = questionsTypes[type as keyof typeof questionsTypes].question(
    {
      handleClose,
      list,
      handleSubmit,
      editingIndex,
      step,
      resetStatus,
      inputType: type,
    },
  )
  return (
    <Modal.Wrapper open={open} onClose={handleCloseAndStatus}>
      <RenderedQuestion
        title={questionsTypes[type as keyof typeof questionsTypes].title}
        icon={questionsTypes[type as keyof typeof questionsTypes].icon()}
      >
        {question}
      </RenderedQuestion>
    </Modal.Wrapper>
  )
}

export default QuestionTypeSelector
