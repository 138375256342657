import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import moment from 'moment'
import * as z from 'zod'

export function getWarning(
  parsedValue: Date,
  masterDate: string,
): string | undefined {
  const parsedDateTimeStr = parsedValue.toISOString().slice(0, 10)

  if (parsedDateTimeStr !== masterDate) {
    return `Atenção: você adicionou um horário que será marcado como no dia ${parsedValue.toLocaleDateString(
      'pt-br',
    )}`
  }
}

export function getDateFromTimeInput(
  timeInput: string,
  masterDate: string,
): Date | undefined {
  const array = timeInput.split(':')

  if (array.filter((i) => i).length === 2) {
    const [hour, minute] = array
    const [masterYear, masterMonth, masterDay] = masterDate
      .split('-')
      .map(Number)

    let newDate = new Date(masterYear, masterMonth - 1, masterDay)
    newDate.setHours(Number(hour), Number(minute))
    const userTimezoneOffset = newDate.getTimezoneOffset() * 60000

    const response = new Date(newDate.getTime() - userTimezoneOffset)
    return response
  }
}

export function getDefaultTimeInputs(
  defaultTimeInputsValue: Array<string> = [],
) {
  const filterTimes = defaultTimeInputsValue.filter((i) => i)

  if (!filterTimes.length) {
    return [
      {
        value: '',
        parsedValue: undefined,
        disabled: false,
        focus: true,
      },
    ]
  }

  return filterTimes.map((timeInput) => {
    const value = format(parseISO(timeInput.replace('Z', '')), 'HH:mm')
    const formattedTime = format(
      parseISO(timeInput.replace('Z', '')),
      'yyyy-MM-dd',
    )
    const parsedValue = getDateFromTimeInput(value, formattedTime)

    return {
      value,
      parsedValue,
      disabled: true,
      focus: false,
    }
  })
}

export function getInputParsedValue(
  previousFieldParsedValue: Date | undefined,
  value: string,
  masterDate: string,
): Date | undefined {
  const baseDate = previousFieldParsedValue
    ? moment(previousFieldParsedValue).format('YYYY-MM-DD')
    : masterDate

  const currentFieldParsedValue = getDateFromTimeInput(value, baseDate)

  if (!currentFieldParsedValue) return undefined

  if (previousFieldParsedValue) {
    if (previousFieldParsedValue > currentFieldParsedValue) {
      const nextDay = new Date(currentFieldParsedValue)
      nextDay.setDate(previousFieldParsedValue.getDate() + 1)
      nextDay.setHours(currentFieldParsedValue.getHours())
      return nextDay
    }
  }

  return currentFieldParsedValue
}

export const sliceLocaleString = (str: string | Date = '') =>
  moment(str).utc().format('DD/MM/YYYY, HH:mm')

const MAX_HOURS = 23
const MAX_MINUTES = 59
const MIN_HOUR_AND_MINUTE = 0

export const validationSchema = z.object({
  observation: z.string().optional(),
  reason: z
    .string({ required_error: 'Campo obrigatório' })
    .nonempty({ message: 'Campo obrigatório' })
    .transform((val = '') => JSON.parse(val)),
  timeInputs: z
    .array(
      z.object({
        value: z
          .string()
          .nonempty({ message: 'Por favor, preencha o campo' })
          .superRefine((val, ctx) => {
            const [hour, minute] = val.split(':')?.map((item) => Number(item))

            if (
              !isFinite(hour) ||
              !isFinite(minute) ||
              hour > MAX_HOURS ||
              minute > MAX_MINUTES ||
              hour < MIN_HOUR_AND_MINUTE ||
              minute < MIN_HOUR_AND_MINUTE
            ) {
              return ctx.addIssue({
                message: 'Horário inválido',
                code: 'custom',
              })
            }
          }),
        disabled: z.boolean(),
        focus: z.boolean(),
        parsedValue: z.date().optional(),
      }),
    )
    .superRefine((val, ctx) => {
      const newVal = val.filter((item) => !item.disabled)
      if (newVal.length === 0) {
        return ctx.addIssue({
          message: 'Por favor, adicione pelo menos um novo horário',
          code: 'custom',
        })
      }

      const takenHours: Record<string, boolean> = {}

      val.forEach((item, index) => {
        if (!item.parsedValue) return

        const key = sliceLocaleString(item.parsedValue)

        if (key in takenHours)
          return ctx.addIssue({
            message: 'Horário duplicado',
            code: 'custom',
            path: [index, 'value'],
          })

        takenHours[sliceLocaleString(item.parsedValue)] = true
      })
    })
    .superRefine((val = [], ctx) => {
      if (val.length > 1) {
        val.forEach((item, index) => {
          if (index > 0 && index < val.length - 1) {
            const prevItem = sliceLocaleString(val[index - 1]?.parsedValue)
            const nextItem = sliceLocaleString(val[index + 1]?.parsedValue)
            const currentItem = sliceLocaleString(item?.parsedValue)

            if (currentItem < prevItem || currentItem > nextItem) {
              if (item.disabled) return
              return ctx.addIssue({
                message: 'Tem algo errado! Este horário é inválido.',
                code: 'custom',
                path: [index, 'value'],
              })
            }
          } else if (index === 0) {
            const nextItem = sliceLocaleString(val[index + 1]?.parsedValue)
            const currentItem = sliceLocaleString(item?.parsedValue)

            if (currentItem > nextItem) {
              return ctx.addIssue({
                message: 'Tem algo errado! Este horário é inválido.',
                code: 'custom',
                path: [index, 'value'],
              })
            }
          }
        })
      }
    })
    .transform((val) =>
      val
        .filter((item) => !item.disabled)
        .map((item) => {
          if (item && item.parsedValue) return item.parsedValue.toISOString()
          return
        }),
    ),
})
