import type { Box, Team } from '.'
import Table from '../table'
import { getDenomination } from 'shared/helpers'
import TeamButton from './team-button'
import Indicator from './indicator'
import useEvaluationColors from 'shared/utils/useEvaluationColors'
import { getColor } from 'performance/evaluations/teamEvaluation/teamEvaluationNotice/PercentageScoreCard'

const GOALS_SCORES = [
  'Sem referência',
  'Conservadoras',
  'Realistas',
  'Superiores',
]

const RESULTS_SCORES = [
  'Não atingimos os objetivos',
  'Atingimos alguns objetivos',
  'Atingimos os objetivos',
]

const MATURITY_SCORES = ['Estrutura', 'Confiança', 'Acordo', 'Performance']

type Props = {
  teams: Box['teams']
}

const TeamEvaluationCard = ({
  type,
  score,
  children,
}: {
  type: string
  score: number
  children: React.ReactNode
}) => {
  const { backgroundColors, scoreColors } = useEvaluationColors([type, score])

  return (
    <Indicator
      className="flex h-full items-center justify-center"
      style={{
        backgroundColor: backgroundColors,
        color: scoreColors,
      }}
    >
      {children}
    </Indicator>
  )
}

export const DetailsButton = ({
  team,
  whiteVariant = false,
}: {
  team: Team
  whiteVariant?: boolean
}) => {
  const {
    goalsScore,
    resultsScore,
    maturityScore,
    overallScorePercentage: overallScore,
  } = team.evaluation

  const performanceScore = getColor(overallScore)

  return (
    <TeamButton
      team={team}
      whiteVariant={whiteVariant}
      indicators={
        <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
          <div className="flex basis-full space-x-2 md:flex-col md:space-y-2 md:space-x-0">
            <h4 className="text-center text-xs text-gray-400">
              Ambição das metas
            </h4>
            <TeamEvaluationCard type="growthPath" score={goalsScore}>
              {GOALS_SCORES[goalsScore - 1]}
            </TeamEvaluationCard>
          </div>
          <div className="flex basis-full space-x-2 md:flex-col md:space-y-2 md:space-x-0">
            <h4 className="text-center text-xs text-gray-400">Resultados</h4>
            <TeamEvaluationCard type="teamResultsScore" score={resultsScore}>
              {RESULTS_SCORES[resultsScore - 1]}
            </TeamEvaluationCard>
          </div>
          <div className="flex basis-full space-x-2 md:flex-col md:space-y-2 md:space-x-0">
            <h4 className="text-center text-xs text-gray-400">Maturidade</h4>
            <TeamEvaluationCard type="maturityScore" score={maturityScore}>
              {MATURITY_SCORES[maturityScore - 1]}
            </TeamEvaluationCard>
          </div>
          <div className="flex basis-full space-x-2 md:flex-col md:space-y-2 md:space-x-0">
            <h4 className="text-center text-xs text-gray-400">Performance</h4>
            <TeamEvaluationCard type="teamPercentage" score={performanceScore}>
              {overallScore}%
            </TeamEvaluationCard>
          </div>
        </div>
      }
    />
  )
}

export default function TeamsTable({ teams }: Props) {
  return (
    <>
      <Table>
        <Table.Head>
          <Table.Header className="w-[120px]">Círculo</Table.Header>
          <Table.Header className="w-[100px]">Líder</Table.Header>
          <Table.Header className="w-[100px]">Ambição das metas</Table.Header>
          <Table.Header className="w-[140px]">Resultados</Table.Header>
          <Table.Header className="w-[90px]">Maturidade</Table.Header>
          <Table.Header className="w-[70px]">Performance</Table.Header>
          <Table.Header className="w-[100px]" />
        </Table.Head>
        <Table.Body>
          {teams.map((team, index) => {
            const user = team.leaders[0]?.user
            const {
              goalsScore,
              resultsScore,
              maturityScore,
              overallScorePercentage: overallScore,
            } = team.evaluation

            const performanceScore = getColor(overallScore)

            return (
              <Table.Row key={team.id} index={index}>
                <Table.Cell className="overflow-hidden text-ellipsis whitespace-nowrap">
                  {team.name} [{getDenomination(team.denomination)}]
                </Table.Cell>
                <Table.Cell className="overflow-hidden text-ellipsis whitespace-nowrap">
                  {user.firstName} {user.lastName}
                </Table.Cell>
                <Table.Cell>
                  <TeamEvaluationCard type="growthPath" score={goalsScore}>
                    {GOALS_SCORES[goalsScore - 1]}
                  </TeamEvaluationCard>
                </Table.Cell>
                <Table.Cell>
                  <TeamEvaluationCard
                    type="teamResultsScore"
                    score={resultsScore}
                  >
                    {RESULTS_SCORES[resultsScore - 1]}
                  </TeamEvaluationCard>
                </Table.Cell>
                <Table.Cell>
                  <TeamEvaluationCard
                    type="maturityScore"
                    score={maturityScore}
                  >
                    {MATURITY_SCORES[maturityScore - 1]}
                  </TeamEvaluationCard>
                </Table.Cell>
                <Table.Cell>
                  <TeamEvaluationCard
                    type="teamPercentage"
                    score={performanceScore}
                  >
                    {overallScore}%
                  </TeamEvaluationCard>
                </Table.Cell>
                <Table.Cell>
                  <DetailsButton team={team} />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}
