import InfoTooptip from 'shared/ui/Tooltip/InfoTooltip'
import {
  Entrega,
  TrabalhoEmEquipe,
  FitCultural,
  Potencial,
  Impacto,
  Remuneracao,
} from './Table/TooltipContent'

export default function TeamTableHeader() {
  return (
    <div className="subtitle2 flex h-10 min-w-[868px] items-center rounded-t-lg bg-gray-lightest text-left">
      <div className="mr-3 w-full min-w-[180px] p-2">
        <div className="flex items-center justify-start">
          <div className="mr-1">Colaboradores</div>
        </div>
      </div>
      <div className="mx-1 w-full min-w-[90px] p-2 text-left">
        <div className="flex items-center justify-start">
          <div className="mr-1">Entrega</div>
          <InfoTooptip>
            <Entrega />
          </InfoTooptip>
        </div>
      </div>
      <div className="mx-1 w-full min-w-[90px] p-2 text-left">
        <div className="flex items-center justify-start">
          <div className="mr-1">Trabalho em Equipe</div>
          <InfoTooptip>
            <TrabalhoEmEquipe />
          </InfoTooptip>
        </div>
      </div>
      <div className="mx-1 w-full min-w-[90px] p-2 text-left">
        <div className="flex items-center justify-start">
          <span className="mr-1">Potencial</span>
          <InfoTooptip>
            <Potencial />
          </InfoTooptip>
        </div>
      </div>
      <div className="mx-1 w-full min-w-[90px] p-2 text-left">
        <div className="flex items-center justify-start">
          <span className="mr-1">Fit Cultural</span>
          <InfoTooptip>
            <FitCultural />
          </InfoTooptip>
        </div>
      </div>
      <div className="mx-1 min-w-[60px] p-2 text-left">Mérito</div>
      <div className="mx-1 w-full min-w-[90px] p-3 text-left">
        <div className="flex items-center justify-start">
          <div className="mr-1">Remuneração</div>
          <InfoTooptip>
            <Remuneracao />
          </InfoTooptip>
        </div>
      </div>
      <div className="mx-1 w-full min-w-[90px] p-2 text-left">
        <div className="flex items-center justify-start">
          <div className="mr-1">Impacto da Perda</div>
          <InfoTooptip>
            <Impacto />
          </InfoTooptip>
        </div>
      </div>
      <div className="ml-1 mr-3 min-w-[60px] p-2 text-left">Risco de Perda</div>
    </div>
  )
}
