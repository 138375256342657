import type { EventResizeDoneArg } from '@fullcalendar/interaction'
import type {
  CalendarApi,
  EventApi,
  EventDropArg,
  FormatterInput,
  ViewApi,
} from '@fullcalendar/react'
import type {
  ActionOptions,
  Info,
  QueryStringObj,
  ReqBody,
  SaveOptions,
} from 'croods'
import { differenceInDays, format, parseISO } from 'date-fns'
import ActionBar from './ActionBar'
import Collaborator from './Collaborator'
import { getScrollTime } from 'portal/vacationsHelpers'

import type { ActionProps } from '../context/reducer'
import type { CollaboratorType } from '../types'

const calendarConstProps = {
  resourceGroupField: 'circle',
  resourceAreaHeaderClassNames: 'capitalize text-gray-darkest text-lg',
  resourceAreaWidth: '335px',
  resourceGroupLabelClassNames:
    'capitalize text-sm text-gray-darkest !bg-white',
  slotLaneClassNames: '!border-transparent',
  eventResourceEditable: false,
  resourceOrder: 'resourcePriority',
  snapDuration: '24:00:00',
  slotLabelClassNames: '!border-x-transparent uppercase !text-gray-dark',
  resourceGroupLaneClassNames: '!bg-white !border-t-transparent',
  resourceAreaHeaderContent: 'Círculos',
  eventBorderColor: 'transparent',
  eventColor: 'transparent',
  headerToolbar: {
    left: 'myTodayButton,myPrev',
    center: 'title',
    right: 'myNext',
  },
  slotLabelFormat: [{ month: 'long' }, { day: '2-digit' }] as FormatterInput[],
  editable: true,
  selectMirror: true,
  dayMaxEvents: true,
  aspectRatio: 1.5,
  nowIndicator: true,
  nowIndicatorClassNames: '!bg-blue !border-0 !bg-opacity-50 w-1 !ml-3',
  dayHeaders: true,
  initialView: 'resourceTimelineYear',
  height: 'auto',
}

const viewDidMount = (
  view: ViewApi,
  setCalendarApi: React.Dispatch<React.SetStateAction<CalendarApi | undefined>>,
) => {
  if (setCalendarApi) setCalendarApi(view.calendar)
}

const customButtons = (calendarApi: CalendarApi | undefined) => {
  return {
    myPrev: {
      text: '<',
      click: async () => {
        if (calendarApi) {
          await calendarApi.prev()
          calendarApi.scrollToTime(getScrollTime(calendarApi.view.title))
        }
      },
    },
    myNext: {
      text: '>',
      click: async () => {
        if (calendarApi) {
          await calendarApi?.next()
          calendarApi?.scrollToTime(getScrollTime(calendarApi.view.title))
        }
      },
    },
    myTodayButton: {
      text: 'Hoje',
      click: () => {
        if (calendarApi) {
          calendarApi?.today()

          calendarApi?.scrollToTime(getScrollTime(calendarApi?.view.title))
        }
      },
    },
  }
}

function revertNotDraftEvent(arg: EventDropArg | EventResizeDoneArg): boolean {
  if (arg.event._def.extendedProps.status !== 'draft') {
    arg.revert()
    return true
  }

  return false
}

const eventDrop = async (
  arg: EventDropArg,
  save: <Vacation>(
    a?: SaveOptions | undefined,
  ) => (b?: ReqBody | undefined) => Promise<Info<Vacation>>,
  dispatch: (value: ActionProps) => void,
) => {
  if (revertNotDraftEvent(arg)) return

  const startDate = format(arg.event.start!, 'yyyy-MM-dd')
  const objToSave = {
    advanceThirteenth: arg.event._def.extendedProps.advanceThirteenth,
    vacationDaysSold: arg.event._def.extendedProps.vacationDaysSold,
    vacationDays: arg.event._def.extendedProps.vacationDays,
    id: arg.event._def.publicId,
    startDate,
  }

  save({
    method: 'PUT',
    id: objToSave.id,
    afterSuccess: ({ data }) => {
      dispatch({
        type: 'UPDATE_EVENT_FROM_API',
        payload: {
          event: data,
        },
      })
    },
  })(objToSave)

  dispatch({
    type: 'UPDATE_EVENT',
    payload: {
      event: {
        ...arg.event._def.extendedProps,
        ...objToSave,
      },
    },
  })
}

const eventResize = async (
  arg: EventResizeDoneArg,
  save: <Vacation>(
    a?: SaveOptions | undefined,
  ) => (b?: ReqBody | undefined) => Promise<Info<Vacation>>,
  dispatch: (value: ActionProps) => void,
) => {
  if (revertNotDraftEvent(arg)) return

  const { event } = arg
  const startDate = format(event.start!, 'yyyy-MM-dd')
  const endDate = format(event.end!, 'yyyy-MM-dd')
  const vacationDays =
    differenceInDays(parseISO(endDate), parseISO(startDate)) + 1

  const objToSave = {
    advanceThirteenth: event._def.extendedProps.advanceThirteenth,
    vacationDaysSold: event._def.extendedProps.vacationDaysSold,
    id: event._def.publicId,
    vacationDays,
    startDate,
  }

  save({
    method: 'PUT',
    id: objToSave.id,
    afterSuccess: ({ data }) => {
      dispatch({
        type: 'UPDATE_EVENT_FROM_API',
        payload: {
          event: data,
        },
      })
    },
  })(objToSave)

  dispatch({
    type: 'UPDATE_EVENT',
    payload: {
      event: {
        ...arg.event._def.extendedProps,
        ...objToSave,
      },
    },
  })
}

const resourceLabelContent = (
  resource: any,
  fetch: <Vacation>(
    a?: ActionOptions | undefined,
  ) => (b?: QueryStringObj | undefined) => Promise<Info<Vacation>>,
  calendarApi: CalendarApi | undefined,
) => (
  <Collaborator
    key={resource.id}
    data={
      {
        ...resource.extendedProps,
        id: resource.id,
        vacationDaysSold: '0',
        vacationDays: '',
        vacationId: null,
      } as CollaboratorType
    }
    reload={fetch()}
    calendarApi={calendarApi}
  />
)

const eventContent = (
  event: EventApi,
  calendarApi: CalendarApi | undefined,
  selectedEventIdToConfirm: string,
  setSelectedEventIdToConfirm: React.Dispatch<React.SetStateAction<string>>,
  fetch: <Vacation>(
    a?: ActionOptions | undefined,
  ) => (b?: QueryStringObj | undefined) => Promise<Info<Vacation>>,
) => (
  <div className="flex items-start ">
    <div className="flex w-full justify-start justify-items-stretch">
      <ActionBar
        event={event}
        calendarApi={calendarApi}
        selectedEventIdToConfirm={selectedEventIdToConfirm}
        onSelectedEventIdToConfirm={setSelectedEventIdToConfirm}
        reload={(afterReload) => fetch({ afterResponse: afterReload })()}
      />
    </div>
  </div>
)

export {
  viewDidMount,
  customButtons,
  eventDrop,
  eventResize,
  resourceLabelContent,
  eventContent,
  calendarConstProps,
}
