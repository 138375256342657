import React from 'react'
import type { BoxContent } from '../../types'
import labelColors from './labelColors'

type Props = { name: BoxContent['name']; withBg?: boolean }

const Label = ({ name, withBg }: Props) => {
  const { meritColor, merit, riskColor, risk, bgColor } = labelColors[name]
  return (
    <div className={withBg ? bgColor : ''}>
      <div className="flex items-center justify-center py-1 sm:py-2">
        <p className="text-sm font-bold">
          <span style={{ color: meritColor }}>{merit}</span>
          <span>&nbsp;+&nbsp;</span>
          <span style={{ color: riskColor }}>{risk}</span>
        </p>
      </div>
    </div>
  )
}

export default Label
