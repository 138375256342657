import React from 'react'
import { useCroods } from 'croods'
import moment from 'moment'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'

import BaseForm from 'talks/conversations/Form'
import ConversationPaper from 'talks/conversations/ConversationPaper'
import BaseConversationListItem from 'talks/conversations/BaseConversationListItem'
import { useTemplate } from 'shared/utils/useTemplate'
import { useForm } from 'shared/utils/useForm'

import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { typeToIcon } from 'shared/utils/conversationLookup'
import type { FormTemplate } from 'types'
import { FollowUpDate } from './FollowUpDate'
import { useNavigate, useParams } from 'react-router-dom'
import { useTrackAnalytics } from 'domain/analytics'
import Icon from 'shared/ui/icon'
import { renderIf } from 'shared/helpers'
import RemoveUploadIcon from 'shared/ui/icon/RemoveUploadIcon'
import { useFileInput } from '../use-file-input'

const New = () => {
  const { teamMemberId } = useParams()
  const template = useTemplate('behavior')

  const [{ list }] = useCroods({
    name: 'behaviorConversations',
    customPath: `/team_members/${teamMemberId}/behavior_conversations`,
    fetchOnMount: true,
  })

  return (
    <>
      <ConversationPaper className="mt-10 mb-4">
        <header className="flex flex-col text-gray-darkest text-opacity-80">
          <div className="flex items-center gap-4">
            {typeToIcon('behavior')}
            <h4 className="text-gray-darkest first-letter:uppercase">
              Comportamento
            </h4>
          </div>
          <p className="mt-2 text-base italic text-gray-dark">
            Fato | Comportamento | Impacto | Sugestão
          </p>
          <p className="mt-4 text-base text-gray-dark">
            Conversas sem frequência pré-definida para falar sobre um problema
            de comportamento, as situações específicas onde o comportamento
            ocorreu, seu impacto e sugerir mudanças de forma amorosa.
          </p>
        </header>
        <Form teamMemberId={teamMemberId!} template={template} />
      </ConversationPaper>
      <div className="flex flex-col gap-4">
        {list.map((conversation: any, index: number) => (
          <BaseConversationListItem
            key={index + conversation.id}
            conversation={conversation}
            teamMemberId={teamMemberId}
          />
        ))}
      </div>
    </>
  )
}

const Form = ({
  teamMemberId,
  template,
}: {
  teamMemberId: string
  template: FormTemplate
}) => {
  const { formState, fields, hasErrors } = useForm(
    {
      date: moment(),
      comment: '',
    },
    {
      requiredFields: ['date', 'comment'],
    },
  )

  const { snackbar } = useSnackbar()
  const navigate = useNavigate()
  const track = useTrackAnalytics()

  const [{ saving, saveError }, { save, resetState }] = useCroods({
    name: 'behaviorConversations',
    customPath: `/team_members/${teamMemberId}/behavior_conversations`,
    afterSuccess: () => {
      track('Conversation', { Type: 'behavior' })
      snackbar({ message: 'Conversa criada com sucesso!', type: 'success' })
      navigate(`/`)
    },
  })

  const { files, addFile, removeFile } = useFileInput()

  // This is only used to clear the Croods saveError state
  React.useEffect(() => resetState, []) //eslint-disable-line

  return (
    <BaseForm
      onSubmit={handleSubmit({ save, values: { ...formState.values, files } })}
      template={template}
      fields={fields}
      saving={saving}
      saveError={saveError}
      disabled={hasErrors}
    >
      <details className="mb-8 mt-2">
        <summary className="hover:cursor-pointer select-none flex items-center">
          <p className="mb-1 mt-[6px] mr-1">Adicionar anexos</p>
          <Icon
            icon="arrow-down"
            size="sm"
            className="summary-arrow inline transition-transform"
          />
        </summary>
        <div className="flex relative items-center rounded justify-center outline-blue outline-dashed outline-1 h-24">
          <input
            type="file"
            multiple={true}
            className="absolute w-[100%] h-[100%] opacity-0 cursor-pointer"
            onChange={addFile}
            onClick={(event) => (event.currentTarget.value = '')}
            title="Adicionar anexo"
          />
          <button className="bt bt-outlined" type="button">
            <Icon icon="attach-file" size="sm" className="inline" />
            &nbsp; Adicionar Anexo
          </button>
        </div>
        {renderIf(
          files.length > 0,
          <ul className="flex flex-col mt-4 p-2 gap-2">
            {files.map(({ name, content, type }, i) => {
              const preview =
                type.split('/')[0] === 'image' ? (
                  <img className="w-8 h-8" src={content} alt={name} />
                ) : type === 'application/pdf' ? (
                  <Icon
                    icon="upload-pdf"
                    className="inline w-4 h-4 fill-gray-dark"
                  />
                ) : (
                  <Icon
                    icon="upload-document"
                    className="inline w-4 h-4 fill-gray-dark"
                  />
                )
              return (
                <li className="flex justify-start items-center gap-2" key={i}>
                  <div className="w-8">{preview}</div>
                  <p className="mr-auto">{name}</p>
                  <button
                    className="hover:bg-gray-100 rounded-full p-3"
                    type="button"
                    onClick={() => removeFile(i)}
                  >
                    <RemoveUploadIcon />
                  </button>
                </li>
              )
            })}
          </ul>,
        )}
        <p className="text-gray-dark mt-1 text-sm">
          Adicione arquivos que ficarão salvos no histórico da conversa.
        </p>
      </details>
      <FollowUpDate fields={fields} />
    </BaseForm>
  )
}

export const handleSubmit =
  ({ save, values }: { save: any; values: any }) =>
  (event: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault && event.preventDefault()

    const convertedValues = {
      ...values,
      date: moment(values.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      followUpDate:
        values.followUpDate &&
        moment(values.followUpDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }

    save()(omitBy(convertedValues, isEmpty))
  }

export default New
