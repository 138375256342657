import * as React from 'react'
import Section from './section'
import ConversationFrequency from './charts/conversation-frequency'
import UpwardEvaluation from './charts/upward-evaluation'
import SelfEvaluation from './charts/self-evaluation'
import ContributionLevel from './charts/contribution-level'
import TeamPerformance from './tables/team-performance'
import TeamDevelopmentSupport from './tables/team-development-support'
import TeamEvaluations from './charts/team-evaluations'
import TotalRetention from './charts/total-retention'
import TalentRetention from './charts/talent-retention'
import NineBox from 'performance/evaluations/membersEvaluation/nineBox'
import { Fetch } from 'croods'
import TwelveBox from './twelve-box'
import { Header } from './header'
import Switcher from 'shared/ui/Switcher'
import { useCurrentUser } from 'croods-auth'
import { useNavigate, useSearchParams } from 'react-router-dom'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import { leadersOnly } from 'shared/accessPolicies'
import type { User } from 'types'
import { chartPath } from './chart-helpers'
import useOrganization from 'shared/organization/useOrganization'
import { ConversationPanel } from './conversation-panel'

const MY_CIRCLES = 'MEUS CÍRCULOS'
const OVERVIEW = 'VISÃO TOTAL'

const Insights = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const organization = useOrganization()
  const [{ currentUser }] = useCurrentUser() as [{ currentUser: User }]

  const availableFilters = [
    ...(leadersOnly(currentUser) ? [MY_CIRCLES] : []),
    ...(currentUser.dataAnalyst ? [OVERVIEW] : []),
  ]

  const userHasCircles = leadersOnly(currentUser)
  const overview = searchParams.get('overview')

  const defaultActive = userHasCircles ? MY_CIRCLES : OVERVIEW

  const active =
    overview === 'true'
      ? OVERVIEW
      : overview === 'false'
      ? MY_CIRCLES
      : defaultActive

  const query = React.useMemo(
    () => (active == OVERVIEW ? { overview: true } : { overview: false }),
    [active],
  )
  const filterOptions = availableFilters.map((f) => ({
    title: f,
  }))

  const handleSwitcherClick = (activeState: string) => {
    navigate(`?overview=${(activeState === OVERVIEW).toString()}`)
  }

  const [showConversationFrequency, setShowConversationFrequency] =
    React.useState<boolean>(false)
  const [showUpwardEvaluation, setShowUpwardEvaluation] =
    React.useState<boolean>(false)
  const [showTeamDevelopmentSupport, setShowTeamDevelopmentSupport] =
    React.useState<boolean>(false)
  const [showConversationPanel, setShowConversationPanel] =
    React.useState<boolean>(false)

  React.useEffect(() => {
    if (organization) {
      setShowUpwardEvaluation(organization?.permitUpwardEvaluation)
      setShowConversationFrequency(
        organization.permitOneOnOneConversation ||
          organization.permitCareerConversation,
      )
      setShowConversationPanel(
        organization.permitOneOnOneConversation ||
          organization.permitCareerConversation,
      )
      setShowTeamDevelopmentSupport(organization.permitCareerConversation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  if (availableFilters.length === 0) {
    return (
      <FeedbackMessage
        message="Você não possui acesso à esses Insights"
        type="warning"
        serverMessage=""
      />
    )
  }

  return (
    <div>
      <div className="flex justify-between">
        <h3>Insights</h3>
        <div className="flex">
          {availableFilters.length === 2 && (
            <Switcher
              active={active}
              setActive={handleSwitcherClick}
              options={filterOptions}
            />
          )}
        </div>
      </div>

      <Header query={query} />

      {showConversationPanel && (
        <ConversationPanel
          query={query}
          stateId={
            query?.overview
              ? 'currentCycleConversations-overview'
              : 'currentCycleConversations'
          }
        ></ConversationPanel>
      )}

      {(showConversationFrequency || showUpwardEvaluation) && (
        <Section
          section="Experiência de Desenvolvimento Pessoal e Profissional"
          title="Estamos proporcionando uma boa experiência de desenvolvimento pessoal e profissional?"
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {showConversationFrequency && (
              <ConversationFrequency
                query={query}
                stateId={
                  query?.overview
                    ? 'conversationFrequency-overview'
                    : 'conversationFrequency'
                }
              />
            )}
            {showUpwardEvaluation && (
              <UpwardEvaluation
                query={query}
                stateId={
                  query?.overview
                    ? 'upwardEvaluation-overview'
                    : 'upwardEvaluation'
                }
              />
            )}
          </div>
          <div className="mt-5">
            <TeamPerformance
              query={query}
              stateId={
                query?.overview ? 'teamPerformance-overview' : 'teamPerformance'
              }
              showUpwardEvaluation={showUpwardEvaluation}
              showConversationFrequency={showConversationFrequency}
            />
          </div>
        </Section>
      )}
      {showTeamDevelopmentSupport && (
        <Section
          section="Desenvolvimento dos Liderados"
          title="Estou conseguindo apoiar os meus liderados em busca do autodesenvolvimento deles?"
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <SelfEvaluation
              query={query}
              stateId={
                query?.overview ? 'selfEvaluation-overview' : 'selfEvaluation'
              }
            />
            <ContributionLevel
              query={query}
              stateId={
                query?.overview
                  ? 'contributionLevel-overview'
                  : 'contributionLevel'
              }
            />
          </div>
          <div className="mt-5">
            <TeamDevelopmentSupport
              query={query}
              stateId={
                query?.overview
                  ? 'teamDevelopmentSupport-overview'
                  : 'teamDevelopmentSupport'
              }
            />
          </div>
        </Section>
      )}
      {organization.permitTeamsEvaluations && (
        <Section
          section="Performance"
          title="Como os meus círculos estão performando?"
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <TeamEvaluations
              query={query}
              stateId={
                query?.overview ? 'teamEvaluations-overview' : 'teamEvaluations'
              }
            />
          </div>
          <div className="mt-10">
            <TwelveBox
              query={query}
              stateId={query?.overview ? 'twelveBox-overview' : 'twelveBox'}
            />
          </div>
        </Section>
      )}
      {organization.permitTalentsEvaluations && (
        <Section
          section="Reconhecimento e Retenção"
          title="Quem são os talentos dos meus círculos e eles estão sendo adequadamente reconhecidos e retidos?"
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <TotalRetention
              query={query}
              stateId={
                query?.overview ? 'totalRetention-overview' : 'totalRetention'
              }
            />
            <TalentRetention
              query={query}
              stateId={
                query?.overview ? 'talentRetention-overview' : 'talentRetention'
              }
            />
          </div>

          <div className="mt-10">
            <Fetch
              name="memberEvaluationsNineBox"
              path={chartPath('recursive_nine_box', query)}
              stateId={
                query?.overview
                  ? 'memberEvaluationsNineBox-overview'
                  : 'memberEvaluationsNineBox'
              }
              query={query}
              render={({
                boxes,
                organizationCycleStartsAt,
                organizationCycleFinishesAt,
              }) => {
                if (!boxes) return null

                return (
                  <NineBox
                    boxes={boxes}
                    cycle={{
                      organizationCycleStartsAt,
                      organizationCycleFinishesAt,
                    }}
                  />
                )
              }}
            />
          </div>
        </Section>
      )}
    </div>
  )
}

export default Insights
