import Avatar from '@material-ui/core/Avatar'

export default function FaceGroup({ faces, remaining }: any) {
  return (
    <div className="relative flex pl-6">
      {faces?.map(({ key, name, image }: any, index: number) => (
        <div key={key} className="relative rounded-full">
          <Avatar
            alt={name}
            src={image}
            className="-ml-5 border-2 border-white"
            style={{
              zIndex: 5 - index,
            }}
          />
          <div
            className="absolute top-px left-[-19px] h-[42px] w-[42px] bg-white"
            style={{
              zIndex: 5 - index,
              opacity: `${index * 20}%`,
            }}
          />
        </div>
      ))}
      {Boolean(remaining) && (
        <h6 className="subtitle1 ml-2 flex items-center whitespace-nowrap leading-tight text-black/40">
          +{remaining}
          <br />
          colaboradores
        </h6>
      )}
    </div>
  )
}
