import { Fetch } from 'croods'
import Skeleton from 'shared/ui/skeleton'
import Table from 'shared/ui/table'

import type { Role } from 'performance/evaluations/membersEvaluation/types'
import map from 'lodash/map'
import Dropdown from 'shared/ui/dropdown'
import DropdownMenu from 'shared/ui/dropdown/dropdown-menu'
import { cx } from 'shared/helpers'

type Props = {
  setDialogType: React.Dispatch<
    React.SetStateAction<'create' | 'edit' | 'delete' | null>
  >
  setRoleId: React.Dispatch<React.SetStateAction<number>>
  setRoleName: React.Dispatch<React.SetStateAction<string>>
  select: (
    collection: Role[],
    fn: (param: any) => string | string[],
  ) => object[]
}

const List = ({ setDialogType, setRoleId, setRoleName, select }: Props) => {
  return (
    <Fetch
      name="organizationRoles"
      cache
      render={(list: Role[]) => {
        const rows = select(list, (item) => item?.name)

        return (
          <Table id="organization-roles-table">
            <Table.Thead className="bg-gray-200">
              <Table.Th>Nome do papel</Table.Th>
              <Table.Th></Table.Th>
            </Table.Thead>
            <Table.Tbody>
              {map(rows, ({ id, name }: Role, index: number) => (
                <Table.Tr
                  key={id}
                  className={cx(index % 2 !== 0 && 'bg-gray-100')}
                >
                  <Table.Td data-title="Nome do papel">{name}</Table.Td>
                  <Table.Td className="text-right">
                    <Dropdown>
                      <DropdownMenu
                        onClick={() => {
                          setRoleId(+id)
                          setRoleName(name)
                          setDialogType('edit')
                        }}
                      >
                        EDITAR
                      </DropdownMenu>
                      <DropdownMenu
                        onClick={() => {
                          setRoleId(+id)
                          setRoleName(name)
                          setDialogType('delete')
                        }}
                      >
                        EXCLUIR
                      </DropdownMenu>
                    </Dropdown>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        )
      }}
      renderLoading={() => <Skeleton height="400px" />}
    />
  )
}

export default List
