import { useCroods } from 'croods'
import ConversationPaper from 'talks/conversations/ConversationPaper'
import type { OnboardingConversation } from 'talks/types'
import { schema } from 'talks/conversations/onboarding/schema'
import ConversationForm from 'talks/conversations/onboarding/conversation-form'
import { useParams } from 'react-router-dom'

const EditOnboardingConversation = () => {
  const { teamMemberId, conversationId } = useParams()
  let [{ info: member }] = useCroods({
    name: 'teamMembers',
    id: teamMemberId,
    fetchOnMount: true,
  })
  let [{ info: conversation }] = useCroods<OnboardingConversation>({
    name: 'onboardingConversations',
    id: conversationId,
    cache: true,
    fetchOnMount: true,
  })
  let convoCroods = useCroods({ name: 'onboardingConversations' })

  let convoSave = convoCroods[1].save({ id: conversation?.id })

  if (!conversation) return null

  return (
    <ConversationPaper title="EDITAR CONVERSA" className="mt-10 mb-4">
      <ConversationForm
        convoSave={convoSave}
        schema={schema}
        user={member?.user}
        conversation={conversation}
        error={Boolean(convoCroods[0].saveError)}
      />
    </ConversationPaper>
  )
}

export default EditOnboardingConversation
