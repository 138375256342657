import React from 'react'
import Row from './TableRow'

type Row = { id: number; parentId: number }

type Props = {
  rows: Row[]
  setList: (list: Row[]) => void
}

const CustomTable = ({ rows, setList }: Props) => {
  return (
    <div className="card">
      <table className="w-full text-sm text-gray-dark">
        <thead className="bg-gray-50 text-xs font-bold">
          <tr className="text-left">
            <th className="p-3 md:p-6">MODELO DE SOLICITAÇÃO</th>
            <th className="p-3 md:p-6">SITUAÇÃO</th>
            <th className="p-3 md:p-6"></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <Row
              key={row.id}
              solicitationTemplate={row}
              parentSolicitationTemplate={rows.find(
                (parent) => parent.id === row.parentId,
              )}
              childSolicitationTemplate={rows.find(
                (child) => child.parentId === row.id,
              )}
              updateRows={(fn: any) => setList(rows.map(fn))}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CustomTable
