import React, { useEffect } from 'react'
import { useCroods } from 'croods'
import indexOf from 'lodash/indexOf'
import { useNavigate } from 'react-router-dom'

import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import parseValues from './parseValues'

export const CIRCLE_STEP = 'circle'
export const RELATIONS_STEP = 'relations'
export const COLLABORATORS_STEP = 'collaborators'

export const STEPS = [CIRCLE_STEP, RELATIONS_STEP, COLLABORATORS_STEP]

const lastStepAfterSuccess =
  ({ navigate, snackbar, circleToEdit, circleId }: any) =>
  () => {
    const succesType = circleToEdit ? 'atualizado' : 'criado'
    snackbar({ message: `Círculo ${succesType} com sucesso!`, type: 'success' })
    navigate(`/circulos/${circleId}`)
  }

const defaultAfterSuccess =
  ({ dispatch, values }: any) =>
  ({ data: { id } }: any) => {
    dispatch({ type: 'next', values })
    dispatch({ type: 'set_id', value: id })
  }

type State = {
  id?: string
  step: string
  values: object
}

type Action = {
  type: string
  value?: string
  values?: object
  stepIndex: number
}

const reducer: React.Reducer<State, Action> = (state, action) => {
  if (action.type === 'next') {
    const nextStep = STEPS[indexOf(STEPS, state.step) + 1]
    const values = { ...state.values, ...action.values }
    const isLastStep = indexOf(STEPS, state.step) === STEPS.length - 1

    return {
      ...state,
      step: isLastStep ? state.step : nextStep,
      values,
    }
  }

  if (action.type === 'navigate') {
    const nextStep = STEPS[action.stepIndex]
    return { ...state, step: nextStep }
  }

  if (action.type === 'set_id') {
    return { ...state, id: action.value }
  }

  return state
}

const useFormSteps = (circleToEdit = undefined) => {
  const initialState: State = {
    step: CIRCLE_STEP,
    values: {},
    id: circleToEdit,
  }
  const { snackbar } = useSnackbar()

  const navigate = useNavigate()

  const [{  saving, saveError }, { save, resetState }] =
    useCroods({
      name: 'team',
    })

  useEffect(() => {
    return resetState
    // eslint-disable-next-line
  }, [])

  const [{ values, step, id }, dispatch] = React.useReducer<typeof reducer>(
    reducer,
    initialState,
  )

  const handleNext = (formValues: object) => {
    const isLastStep = indexOf(STEPS, step) === STEPS.length - 1
    const valuesToSubmit = isLastStep
      ? { ...values, ...formValues, status: 'active' }
      : { ...values, ...formValues }

    const saveParams = {
      id,
      afterSuccess: isLastStep
        ? lastStepAfterSuccess({
            snackbar,
            navigate,
            circleToEdit,
            circleId: id,
          })
        : defaultAfterSuccess({
            dispatch,
            values: valuesToSubmit,
          }),
    }
    save(saveParams)(parseValues(valuesToSubmit, step))
  }

  const handleNavigate = (stepIndex: number) => () => {
    dispatch({ type: 'navigate', stepIndex })
  }

  return {
    values,
    step,
    id,
    saving,
    saveError,
    handleNext,
    handleNavigate,
  }
}

export default useFormSteps
