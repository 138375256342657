import React from 'react'
import Chip from '@material-ui/core/Chip'
import map from 'lodash/map'

const Chips = ({ value, ...props }: any) =>
  typeof value === 'string' ? (
    <Chip label={value} {...props} />
  ) : (
    <div className="flex gap-2 flex-wrap">
      {map(value, (item) => (
        <Chip key={item} label={item || '[Sem nome]'} {...props} />
      ))}
    </div>
  )

export default Chips
