import { useState } from 'react'
import { useCroods } from 'croods'
import { Link, useNavigate } from 'react-router-dom'

import AutocompleteWithSearch from 'shared/forms/AutocompleteWithSearch'
import { email as emailValidation } from 'shared/forms/validations'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import TagsInput from 'shared/forms/TagsInput'
import Icon from 'shared/ui/icon'

import type { Option } from 'shared/forms/AutocompleteWithSearch/Autocomplete'
import type { LegacyRouteProps } from 'legacy-route'

type Circle = {
  teamId: number
  label: string
}

function Invitation({ pageTitle }: LegacyRouteProps) {
  const [teamId, setTeamId] = useState<number | string | null>(null)

  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const [{}, { save }] = useCroods({
    name: 'addPeople',
    customPath: `/team/${teamId}/add_people`,
    fetchOnMount: false,
  })

  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event && event.preventDefault && event.preventDefault()
    save({
      method: 'POST',
      afterSuccess: () => {
        navigate(`/circulos/${teamId}`)
        snackbar({
          message: 'Colaboradores convidados com sucesso',
          type: 'success',
        })
      },
      afterFailure: (e) => {
        if (e.response?.data.message) {
          snackbar({
            message: e.response.data.message,
            type: 'error',
          })
        } else {
          snackbar({
            message: 'houve um erro inesperado, tente novamente',
            type: 'error',
          })
        }
      },
    })({ members_to_invite: emails })
  }

  const handleSelectTeamId = (
    props: string | Option | (string | Option)[] | null,
  ) => {
    if (typeof props === 'object' && !Array.isArray(props) && props !== null) {
      setTeamId(props.value)
    }
  }

  const parseOptions = (options: Circle[]) => {
    const result = options.map((option) => ({
      label: option.label,
      value: option.teamId,
    }))

    return result
  }

  const isValidEmail = (email: string) =>
    Boolean(email && !emailValidation()(email))

  const [emailChips, setEmailChips] = useState<any>([])
  const [inputValue, setInputValue] = useState<any>('')
  const emails = isValidEmail(inputValue)
    ? [...emailChips, inputValue]
    : emailChips

  return (
    <form>
      <h3 className="mb-4">{pageTitle}</h3>

      <div className="card flex flex-col gap-1 rounded-b-none p-6">
        <AutocompleteWithSearch<Circle>
          croodsConfig={{
            name: 'teamsToInvite',
            path: '/team/to_invite',
            fetchOnMount: true,
          }}
          parseOptions={parseOptions}
          inputProps={{
            name: 'teams',
            label:
              'Escolha um círculo em que deseja adicionar o(s) colaborador(es)',
            placeholder: 'Busque pelo nome do círculo',
          }}
          autocompleteProps={{
            multiple: false,
            onChange: handleSelectTeamId,
            onBlur: () => {},
          }}
        />

        <div className="mt-2 mb-4 md:mb-0 flex justify-end">
          <Link
            to="/circulos/criar-novo-circulo"
            className="text-base text-blue"
          >
            Criar novo círculo
          </Link>
        </div>

        <TagsInput
          onTextChange={setInputValue}
          collection={emailChips}
          setCollection={setEmailChips}
          validate={isValidEmail}
          label="Convidar novos colaboradores por e-mail"
          name="emails"
          placeholder="nome@empresa.com"
          helperText="Adicione vários separando por vírgula"
        />
      </div>

      <div className="flex justify-end rounded-b-lg bg-blue-lightest p-6">
        <button
          className="bt bt-contained flex items-center justify-center gap-2 self-center sm:w-44 px-2 md:px-0"
          disabled={!teamId || emails.length === 0}
          onClick={handleSubmit}
          type="submit"
        >
          <span>ENVIAR CONVITE</span>
          <Icon icon="arrow-forward" size="md" />
        </button>
      </div>
    </form>
  )
}

export default Invitation
