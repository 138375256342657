import capitalize from 'lodash/capitalize'
import toLower from 'lodash/toLower'
import map from 'lodash/map'
import { getUserIdentifier, getDenomination } from 'shared/helpers'

export const parseName = (name: string) => {
  if (name && name.length) {
    return `${name.charAt(0).toUpperCase()}${name.slice(1)}`
  }
  return name
}

export const parseLeader = (target: any) => {
  if (target instanceof Array && target.length > 0) {
    const items = target[0].inputName
    if (items.length === 0) return []
    return items[0].value ? [items[0].value] : []
  }
  return []
}

export const parseParent = (target: any) => {
  if (target instanceof Array && target.length > 0) {
    const items = target[0].inputName
    if (items.length === 0) return null
    return items[0].value ?? null
  }
  return null
}

export const parseInvitations = (target: any) => {
  if (verifyArrayUsers(target)) {
    return target?.reduce(
      (acc: any, { inputName }) => [
        ...acc,
        ...inputName
          .map((userOrEmail) =>
            userOrEmail instanceof Object ? null : userOrEmail,
          )
          .filter(Boolean),
      ],
      [],
    )
  }
  return []
}

export const parseMembers = (target: any) => {
  if (verifyArrayUsers(target)) {
    return target.reduce(
      (acc: any, { inputName }) => [
        ...acc,
        ...inputName
          .map((userOrEmail) =>
            userOrEmail instanceof Object ? userOrEmail.value : null,
          )
          .filter(Boolean),
      ],
      [],
    )
  }
  return []
}

export const parseChilds = (target: any) => {
  if (verifyArrayUsers(target)) {
    return target?.reduce(
      (acc: any, { inputName }: any) => [
        ...acc,
        ...inputName.map((item: any) => item.value),
      ],
      [],
    )
  }
  return []
}

export const parseFetchedCollaborators = (
  fetchedCollaborators: any[] = [],
  filledValues: any = [],
) => {
  const filledCollaboratorsToAdd = verifyArrayUsers(filledValues?.membersToAdd)
    ? filledValues.membersToAdd[0].inputName
    : []
  const parsedFetchedCollaborators = fetchedCollaborators.length
    ? fetchedCollaborators.map(({ id, user }: any) => ({
        value: user.id,
        label: getUserIdentifier({ user }),
        role: user.role,
        email: user.email,
        avatar: user.avatar,
        mainTeam: user.mainTeam,
        invitedByEmail: user.invitedByEmail,
        memberId: id,
        hasCredential: user.hasCredential,
        recentlyAdded: user.recentlyAdded,
      }))
    : []
  if (parsedFetchedCollaborators.length || filledCollaboratorsToAdd.length) {
    return [
      {
        step: 'relations',
        inputName: [
          ...parsedFetchedCollaborators,
          ...filledCollaboratorsToAdd,
        ].reduce((acc, item) => {
          if (acc.every((i: any) => i.value !== item.value))
            return [...acc, item]
          return acc
        }, []),
      },
    ]
  }
  return []
}

export const invalidCollaborators = (
  formsValues: any,
  serverStateValues: any,
  formMembers: any[] = [],
) => {
  const takenLeader = formsValues?.leadersToAdd?.length
    ? formsValues?.leadersToAdd
    : serverStateValues?.leadersToAdd ?? []

  if (formMembers.length === 0) return takenLeader ?? []

  return [...takenLeader, ...formMembers]
}

export const parseItemValues = (value: any) => {
  if (value.length === 0) return []
  const [temporary] = [...value]
  // @ts-expect-error
  let { inputName: target } = { ...temporary }
  target = target || temporary
  if (Array.isArray(target))
    return target.sort((memberA, memberB) =>
      memberA.label > memberB.label ? 1 : -1,
    )
  return [target].sort((memberA, memberB) =>
    memberA.label > memberB.label ? 1 : -1,
  )
}

function verifyArrayUsers(target: any): target is Array<{
  inputName: Array<{ value: string; email: string | null } | string>
}> {
  return target?.length && target.some(({ inputName }: any) => inputName.length)
}

export const autoCompleteParser = (type: any) => {
  if (type === 'user') {
    return (users: any) => {
      return map(
        users,
        ({
          id,
          avatar = '',
          mainTeam = '',
          email = '',
          role = '',
          ...user
        }) => ({
          value: id,
          label: getUserIdentifier({ user }) ?? email,
          role,
          email,
          avatar,
          mainTeam,
          hasCredential: user.hasCredential,
        }),
      )
    }
  }
  return (teams: any) =>
    map(teams, ({ id = '', name = '', leaders = [], denomination = '' }) => {
      if (leaders.length) {
        // @ts-expect-error
        const { user, teamName } = { ...leaders[0] }

        return {
          value: id,
          label: `${name}${
            denomination
              ? ' [' + capitalize(getDenomination(denomination)) + ']'
              : ''
          }`,
          email: user.email,
          avatar: user.avatar,
          leaderedTeamName: teamName,
          leaderName: getUserIdentifier({ user }),
          mainTeam: user.mainTeam,
          userId: user.id,
        }
      }

      return {
        value: id,
        label: `${name}${
          denomination
            ? ' [' + capitalize(getDenomination(denomination)) + ']'
            : ''
        }`,
        email: '',
        avatar: '',
        leaderedTeamName: '',
        leaderName: 'Círculo sem líder',
        mainTeam: '',
        userId: '',
      }
    })
}

export const setNameAndDenomination = ({ name, denomination }: any) => {
  if (name && denomination) {
    return `${capitalize(name)} [${toLower(getDenomination(denomination))}]`
  }
  return 'este círculo'
}

export const parseDisabledOptions = ({
  createdTeamId,
  parent,
  childs,
}: any) => {
  const options = parseChilds([...parent, ...childs])
  if (createdTeamId) return [createdTeamId, ...options]
  return [...options]
}
