import * as React from 'react'
import type { PageProps } from 'types'
import { OrganizationPerformanceSettings } from './organization'
import { TeamsPerformanceSettings } from './teams'

import useOrganization from 'shared/organization/useOrganization'

const Performance: PageProps = () => {
  const organization = useOrganization()

  return (
    <>
      <OrganizationPerformanceSettings organization={organization} />
      <TeamsPerformanceSettings organization={organization} className="mt-12" />
    </>
  )
}

export default Performance
