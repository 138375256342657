import MomentUtils from '@date-io/moment'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import type { Moment } from 'moment'
import React from 'react'
import useOrganization from 'shared/organization/useOrganization'
import type { FormState } from '../types'

type Props = {
  evaluationDate: Moment
  handleChange: (title: keyof FormState, value: string | Moment) => void
}

export default function Date({ evaluationDate, handleChange }: Props) {
  const organization = useOrganization()

  const onChange = (datepickerDate: Moment | null) => {
    if (datepickerDate) {
      handleChange('evaluatedAt', datepickerDate.format('YYYY-MM-DD'))
    }
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        value={evaluationDate}
        inputVariant="outlined"
        fullWidth
        format="DD/MM/YYYY"
        name="evaluationDate"
        id="evaluationDate"
        placeholder="DD/MM/AAAA"
        minDate={organization.evaluationWindowOpensAt}
        maxDate={organization.evaluationWindowClosesAt}
        InputLabelProps={{ shrink: false }}
        invalidDateMessage="Formato de data inválido"
        maxDateMessage="O valor não pode ser posterior à data máxima"
        minDateMessage="O valor não pode ser inferior à data mínima"
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  )
}
