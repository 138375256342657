import React from 'react'
import { useNavigate } from 'react-router-dom'
import Avatar from 'shared/ui/Avatar'
import { getUserIdentifier } from 'shared/helpers'
import type { User } from 'types'

type Props = {
  user: User
  hasUpwardEvaluation: boolean
  leaderId: number
}

export default function Header({ user, hasUpwardEvaluation, leaderId }: Props) {
  const { role } = user
  const navigate = useNavigate()

  const upwardEvaluationUrl = `/conversas/lider/${leaderId}/avaliacao-ascendente/novo`

  return (
    <div className="flex justify-between px-4 py-3">
      <div className="flex items-center">
        <Avatar user={user} />
        <div className="ml-2">
          <h6 className="font-bold">{getUserIdentifier({ user })}</h6>
          <p className="text-xs text-gray">{role}</p>
        </div>
      </div>
      {hasUpwardEvaluation && (
        <button
          className="bt bt-blue px-3"
          onClick={() => navigate(upwardEvaluationUrl)}
        >
          Avaliar Líder
        </button>
      )}
    </div>
  )
}
