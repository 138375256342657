import * as React from 'react'
import { Fetch, useCroods } from 'croods'
import { Skeleton } from '@material-ui/lab'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import type { CareerConversation } from 'talks/types'

import FirstConversation from './FirstConversation'
import SecondConversation from './SecondConversation'
import { useParams } from 'react-router-dom'
import { useRestoreScrollOnChange } from 'shared/utils/hooks'

const Career = () => {
  const { teamMemberId } = useParams()

  const [
    { fetchingInfo: loading, infoError: error, info },
    { fetch, resetState },
  ] = useCroods({
    id: teamMemberId,
    name: 'careerConversations',
    customPath: `/team_members/:id/career_conversations/user_conversations/`,
    cache: false,
  })

  React.useEffect(() => {
    fetch()()
    return () => resetState()
  }, [teamMemberId]) // eslint-disable-line

  useRestoreScrollOnChange(teamMemberId)

  if (error)
    return (
      <FeedbackMessage
        message="Algo deu errado, tente novamente"
        serverMessage=""
      />
    )

  if (info === null || loading) return <Skeleton data-testid="Skeleton" />

  return (
    <Fetch<CareerConversation>
      cache={false}
      id={teamMemberId}
      name="careerConversationsLatest"
      customPath={`/team_members/${teamMemberId}/career_conversations/latest_unpublished`}
      render={(conversation, [, { setInfo: setConversation }]) => {
        if (info?.firstConversation) {
          return (
            <FirstConversation
              conversation={conversation}
              setConversation={setConversation}
              teamMemberId={teamMemberId!}
            />
          )
        }

        return (
          <SecondConversation
            careerConversation={conversation}
            setConversation={setConversation}
            teamMemberId={teamMemberId!}
          />
        )
      }}
      renderLoading={() => <Skeleton data-testid="Skeleton" />}
      renderError={() => (
        <FeedbackMessage
          message="Algo deu errado, tente novamente"
          serverMessage=""
        />
      )}
    />
  )
}

export default Career
