import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { cx } from 'shared/helpers'
import Icon from 'shared/ui/icon'

type Document = {
  id: number
  fileName: string
  fileUrl: string
}

type DocumentsProp = {
  value: Document[]
  onChange: (value: Document[]) => void
}

type DocumentsLinkProps = {
  document: Document
  index: number
  lineThrough: boolean
}

const Documents = ({ value, onChange }: DocumentsProp) => {
  if (!value || !value.length) {
    return null
  }

  const handleDelete = (target: Document) => {
    onChange(value.filter((document) => document !== target))
  }

  return (
    <Grid item xs={12}>
      <Typography variant="h5" gutterBottom>
        Documentos adicionados na criação do modelo
      </Typography>

      <div className="flex flex-col gap-4 pl-2">
        {value.map((document, index) => (
          <div
            key={index.toString()}
            className="flex items-center justify-center"
          >
            <Icon
              onClick={() => handleDelete(document)}
              className="mr-2 cursor-pointer text-blue"
              data-testid="svg"
              icon="delete"
            />
            <Document lineThrough={false} index={index} document={document} />
          </div>
        ))}
      </div>
    </Grid>
  )
}

const Document = ({ document, index, lineThrough }: DocumentsLinkProps) => (
  <Grid
    container
    className={cx(
      'm-0 flex items-center',
      lineThrough && 'line-through opacity-50',
    )}
  >
    <Grid item>
      <Typography color="primary">
        <Link
          href={document.fileUrl}
          target="_blank"
          className="flex items-center break-all font-bold uppercase no-underline"
        >
          {document.fileName || `Documento em anexo - ${index + 1}`}
        </Link>
      </Typography>
    </Grid>
  </Grid>
)
export default Documents
