import * as z from 'zod'

import LeaderSearch from 'portal/openSolicitation/new/leader-search'
import { parseValue } from 'shared/formsv2/autocomplete-input'
import CheckboxGroup from 'shared/formsv2/checkbox-group'
import { parseFiles } from 'shared/formsv2/upload-input'
import UploadInput from 'shared/formsv2/upload-input'
import NumberInput from 'shared/formsv2/number-input'
import RadioGroup from 'shared/formsv2/radio-group'
import DateInput from 'shared/formsv2/date-input'
import TextArea from 'shared/formsv2/textarea'
import Input from 'shared/formsv2/input'
import {
  DATE_REGEX,
  NUMBER_COMMA_REGEX,
  NUMBER_PERIOD_REGEX,
  NUMBER_INTEGER_REGEX,
} from 'shared/formsv2/regex'

import type { DynamicInput } from 'portal/openSolicitation/new/types'

const NUMBER_REGEX = {
  comma: NUMBER_COMMA_REGEX,
  period: NUMBER_PERIOD_REGEX,
  integer: NUMBER_INTEGER_REGEX,
} as const

function getTextSchema(input: DynamicInput) {
  let schema = z.string()
  if ('maxCharacter' in input && input.maxCharacter)
    schema = schema.max(input.maxCharacter)
  if (input.required) schema = schema.nonempty({ message: 'Campo obrigatório' })
  return schema
}

function getDateSchema(input: DynamicInput) {
  return getTextSchema(input).regex(DATE_REGEX, {
    message: 'Formato de data é inválido',
  })
}

function getNumberSchema(input: DynamicInput) {
  let schema = getTextSchema(input)
  if ('maskType' in input)
    schema = schema.regex(NUMBER_REGEX[input.maskType], {
      message: 'Número é inválido',
    })
  return schema
}

function getLedSearchSchema(input: DynamicInput) {
  let userSchema = z.object({
    id: z.number(),
  })

  let schema = z.preprocess(
    (val) => parseValue(val as string),
    input.required
      ? z.array(userSchema).nonempty({
          message: 'Campo obrigatório',
        })
      : z.array(userSchema),
  )

  return schema.transform((val) => val.map(({ id }) => String(id))[0] ?? '')
}

function getUploadSchema(input: DynamicInput) {
  return getTextSchema(input).transform((val) => {
    const files = parseFiles(val)
    return files.map(({ name, parsed }) => ({
      file_name: name,
      file_blob: parsed,
    }))
  })
}

function getOptionListSchema(input: DynamicInput) {
  if ('optionType' in input && input.optionType === 'multiple') {
    const schema = z.array(z.string())
    return z.preprocess(
      (val) => val || [],
      input.required
        ? schema.nonempty({ message: 'Campo obrigatório' })
        : schema,
    )
  }
  return z.preprocess((val) => val ?? '', getTextSchema(input))
}

function buildSchema(inputs: Array<DynamicInput>) {
  return z.object(
    inputs.reduce((result, input) => {
      switch (input.inputType) {
        case 'upload':
          result[input.id] = getUploadSchema(input)
          break
        case 'option_list':
          result[input.id] = getOptionListSchema(input)
          break
        case 'date_input':
          result[input.id] = getDateSchema(input)
          break
        case 'number_input':
          result[input.id] = getNumberSchema(input)
          break
        case 'led_search':
          result[input.id] = getLedSearchSchema(input)
          break
        default:
          result[input.id] = getTextSchema(input)
          break
      }
      return result
    }, {} as z.ZodRawShape),
  )
}

type Props = {
  inputs: Array<DynamicInput>
}
function DynamicInputs({ inputs }: Props) {
  return (
    <>
      {inputs.map((input) => {
        switch (input.inputType) {
          case 'long_text':
            return (
              <div key={input.id} className="mb-5 flex flex-col">
                <TextArea
                  name={String(input.id)} // TODO: backend should send a name
                  defaultValue={input.inputValue ?? ''}
                  required={input.required}
                  label={input.inputName}
                  rows={4}
                />
              </div>
            )

          case 'date_input':
            return (
              <div key={input.id} className="mb-5 flex flex-col">
                <DateInput
                  name={String(input.id)} // TODO: backend should send a name
                  defaultValue={input.inputValue ?? ''}
                  required={input.required}
                  label={input.inputName}
                />
              </div>
            )

          case 'hour_input':
            return (
              <div key={input.id} className="mb-5 flex flex-col">
                <Input
                  name={String(input.id)} // TODO: backend should send a name
                  type="time"
                  defaultValue={input.inputValue ?? ''}
                  required={input.required}
                  label={input.inputName}
                />
              </div>
            )

          case 'number_input':
            if (input.maskType === 'integer') {
              return (
                <div key={input.id} className="mb-5 flex flex-col">
                  <Input
                    name={String(input.id)} // TODO: backend should send a name
                    defaultValue={input.inputValue ?? ''}
                    mask="integer"
                    required={input.required}
                    label={input.inputName}
                  />
                </div>
              )
            }
            return (
              <div key={input.id} className="mb-5 flex flex-col">
                <NumberInput
                  name={String(input.id)} // TODO: backend should send a name
                  thousandSeparator={input.maskType === 'comma' ? ',' : '.'}
                  decimalSeparator={input.maskType === 'comma' ? '.' : ','}
                  defaultValue={input.inputValue ?? ''}
                  required={input.required}
                  label={input.inputName}
                  autoComplete="off"
                />
              </div>
            )

          case 'upload':
            return (
              <div
                key={input.id}
                className="override-dropzone mb-5 flex flex-col"
              >
                <UploadInput
                  name={String(input.id)} // TODO: backend should send a name
                  defaultValue={input.inputValue ?? []}
                  required={input.required}
                  label={input.inputName}
                />
              </div>
            )

          case 'option_list':
            return (
              <div key={input.id} className="mb-5 flex flex-col">
                {input.optionType === 'multiple' ? (
                  <CheckboxGroup
                    name={String(input.id)} // TODO: backend should send a name
                    defaultValue={input.inputValue ?? []}
                    label={input.inputName}
                    required={input.required}
                    options={input.optionList}
                  />
                ) : (
                  <RadioGroup
                    name={String(input.id)} // TODO: backend should send a name
                    defaultValue={input.inputValue ?? ''}
                    label={input.inputName}
                    required={input.required}
                    options={input.optionList}
                  />
                )}
              </div>
            )

          case 'led_search':
            return (
              <div key={input.id} className="mb-5 flex flex-col">
                <LeaderSearch
                  name={String(input.id)} // TODO: backend should send a name
                  defaultValue={input.inputValue ?? ''}
                  required={input.required}
                  label={input.inputName}
                />
              </div>
            )

          default:
            return (
              <div key={input.id} className="mb-5 flex flex-col">
                <Input
                  name={String(input.id)} // TODO: backend should send a name
                  defaultValue={input.inputValue ?? ''}
                  required={input.required}
                  label={input.inputName}
                />
              </div>
            )
        }
      })}
    </>
  )
}

export { buildSchema }
export default DynamicInputs
