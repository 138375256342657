import * as React from 'react'
import Button from '@material-ui/core/Button'
import { useCroods } from 'croods'

import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import type { PageProps } from 'types'
import { cx } from 'shared/helpers'
import Icon from 'shared/ui/icon'
import { ConfirmationModal } from './confirmation-modal'

const API: PageProps = ({ pageTitle }) => {
  const [showModal, setShowModal] = React.useState(false)
  const [{ info, saving }, { save, resetState }] = useCroods({
    name: 'api',
    path: 'workflow/api_key',
  })
  const { snackbar } = useSnackbar()

  const apiKey = info?.apiKey || ''
  const handleGenerateApiKey = async () => await save({ method: 'POST' })()
  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(apiKey)
    snackbar({ message: 'Chave copiada com sucesso', type: 'success' })
  }

  React.useEffect(() => {
    return () => resetState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <h3 className="mb-4">{pageTitle}</h3>
      <div className="card">
        <div className="my-4 flex flex-col justify-center gap-4 p-6 sm:flex-row">
          <Button
            title="Gerar chave de api"
            color="primary"
            variant="contained"
            size="small"
            onClick={() => setShowModal(true)}
            disabled={saving}
          >
            GERAR CHAVE DE API
          </Button>
          <div className="flex flex-1 flex-col gap-3">
            <div className="flex gap-4">
              <div className="relative flex w-full">
                <input
                  type="text"
                  className={cx(
                    'h-9 w-full overflow-hidden text-ellipsis rounded border-blue pr-8 text-lg font-semibold text-gray-darkest placeholder:text-gray disabled:border-gray-lightest',
                    saving && 'text-gray',
                  )}
                  readOnly
                  disabled={!apiKey}
                  placeholder="A sua chave será mostrada aqui."
                  value={
                    saving
                      ? 'Aguarde, estamos gerando a sua chave de API'
                      : apiKey
                  }
                />
                {apiKey && (
                  <Icon
                    title="file_copy-icon"
                    icon="file-copy-outline"
                    className="absolute right-1 top-1.5 text-blue"
                  />
                )}
              </div>
              <Button
                title="Copiar chave de api"
                color="primary"
                variant="contained"
                size="small"
                onClick={handleCopyApiKey}
                disabled={!apiKey}
              >
                COPIAR
              </Button>
            </div>

            {apiKey && (
              <p className="flex flex-row gap-2 text-yellow">
                <Icon icon="file-copy-outline" />
                Atenção! Tenha certeza que copiou este código, pois ele
                aparecerá somente uma vez para você.
              </p>
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        show={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        handleSave={() => {
          setShowModal(false)
          handleGenerateApiKey()
        }}
      >
        <div className="flex flex-row text-base gap-2">
          <Icon icon="error-outline" className="text-yellow shrink-0" />
          <p>
            Esteja ciente que a geração dessa nova chave resultará na revogação
            da chave existente e essa ação é irreversível. Caso uma integração
            esteja em vigor, essa ação pode interromper temporariamente a
            integração até que a nova chave seja atualizada na outra ponta
            integrada.
          </p>
        </div>
      </ConfirmationModal>
    </>
  )
}

export default API
