import Documents from 'shared/ui/Documents'

export type File =
  | {
      fileName: string
      fileUrl: string
      name?: string
      url?: string
    }
  | {
      fileName?: string
      fileUrl?: string
      name: string
      url: string
    }

const parseFiles = (documents: File[], isModelFile = false) =>
  documents.map(
    (file: File, index) =>
      ({
        name: isModelFile
          ? `${`documento anexo ${index + 1}`}`
          : file.fileName || file.name,
        url: file.fileUrl || file.url,
      } as { name: string; url: string }),
  )

type Props = {
  documents: File[]
  className?: string
  isModelFile?: boolean
}

const DocumentsList = ({ documents, className, isModelFile }: Props) => {
  const parsedFiles = parseFiles(documents, isModelFile)

  return (
    <Documents
      documents={parsedFiles}
      className={className}
      emptyText="O documento não foi anexado"
    />
  )
}

export default DocumentsList
