import Chip from '@material-ui/core/Chip'
import {
  formatDateShorter,
  formatStartDateCycle,
  formatEndDateCycle,
} from 'shared/helpers'
import useOrganization from 'shared/organization/useOrganization'
import { colors } from 'theme'

export const periodDictionary = [
  null,
  'Mensais',
  'Bimestrais',
  'Trimestrais',
  'Quadrimestrais',
  'Quinquimestrais',
  'Semestrais',
]

type CycleProps = {
  start: string
  finish: string
  title?: string
  direction: React.CSSProperties['flexDirection']
  evaluatedAt?: string | null
  longFormat?: boolean
  status?: boolean | string
  organization?: boolean
  durationInMonths?: string | null
  hollow?: boolean
  show?: boolean
}

const Cycle = ({
  start,
  finish,
  title,
  direction,
  evaluatedAt,
  longFormat = false,
  status = true,
  organization = false,
  durationInMonths,
  hollow = false,
  show = false,
}: CycleProps) => {
  let formattedDate: string | null = ''

  if (durationInMonths) {
    formattedDate = periodDictionary[Number(durationInMonths)]
  } else if (evaluatedAt) {
    formattedDate = formatStartDateCycle(evaluatedAt)
  } else if (start && (organization || longFormat)) {
    formattedDate = `${formatStartDateCycle(start)}
    ${finish && ` a ${formatEndDateCycle(finish)}`}`
  } else {
    formattedDate = `${formatDateShorter(start)}${
      finish && ` a ${formatDateShorter(finish)}`
    }`
  }

  return status !== 'closed' || show ? (
    <div
      style={{ flexDirection: direction }}
      className="flex items-center gap-2"
    >
      {title ? <p className="font-bold text-gray-dark">{title}</p> : null}
      <Chip
        size="small"
        label={<span className="body2">{formattedDate}</span>}
        style={
          hollow
            ? {
                color: colors.black40,
                backgroundColor: colors.white,
                border: `1px solid ${colors.grey54}`,
              }
            : {
                color: colors.black54,
                backgroundColor: colors.grey54,
              }
        }
      />
    </div>
  ) : null
}

type Props = {
  startsAt?: string
  finishesAt?: string
  title?: string
  evaluatedAt?: string | null
  longFormat?: boolean
  durationInMonths?: string | null
  direction?: React.CSSProperties['flexDirection']
  hollow?: boolean
  show?: boolean
}

const Period = ({
  startsAt,
  finishesAt,
  evaluatedAt,
  longFormat = false,
  title,
  durationInMonths,
  direction = 'row',
  hollow = false,
  show = false,
}: Props) => {
  const {
    evaluationWindowOpensAt: windowOpen,
    evaluationWindowClosesAt: windowClose,
    evaluationWindowStatus: windowStatus,
  } = useOrganization()

  return (
    <Cycle
      title={title}
      start={startsAt || windowOpen}
      finish={finishesAt || windowClose}
      evaluatedAt={evaluatedAt}
      direction={direction}
      status={windowStatus}
      organization={!startsAt && !finishesAt}
      longFormat={longFormat}
      durationInMonths={durationInMonths}
      hollow={hollow}
      show={show}
    />
  )
}

export default Period
