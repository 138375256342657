import React from 'react'
import map from 'lodash/map'

import Icon from 'shared/ui/icon'

const Files = ({ value: files }: any) => (
  <div className="flex gap-4">
    {map(files, (file, ind) => (
      <div key={`${file.id}${ind}`}>
        <a
          download
          href={file}
          className="flex items-center justify-center rounded-2xl bg-gray-200 p-2 no-underline"
          title={file.filename}
        >
          <Icon icon="insert-drive-file" className="text-gray-500" />
        </a>
      </div>
    ))}
  </div>
)
export default Files
