import { match } from 'ts-pattern'
import type { IndividualOutputValues, TeamworkValues } from 'talks/types'

type ContributionScore = 0 | 4 | 8
const calculateContribution = (
  individualOutput: IndividualOutputValues | undefined,
  teamwork: TeamworkValues | undefined,
) =>
  match({
    individualOutput,
    teamwork,
  })
    .with(
      {
        individualOutput: 'differentiated',
        teamwork: 'reference',
      },
      (): ContributionScore => 8,
    )
    .with(
      {
        individualOutput: 'differentiated',
        teamwork: 'member',
      },
      (): ContributionScore => 4,
    )
    .with(
      {
        individualOutput: 'autonomy',
        teamwork: 'reference',
      },
      (): ContributionScore => 4,
    )
    .with(
      {
        individualOutput: 'autonomy',
        teamwork: 'member',
      },
      (): ContributionScore => 4,
    )
    .otherwise((): ContributionScore => 0)

export type { ContributionScore }
export { calculateContribution }
