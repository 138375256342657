import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Input from 'shared/forms/Input'
import Select from 'shared/forms/Select'
import { maxLength, presence } from 'shared/forms/validations'
import { useForm } from 'shared/utils/useForm'
import Step from 'shared/ui/form-step'
import ExitConfirmationModal from 'shared/ui/ExitConfirmationModal'
import { getDenominationOptions } from 'shared/helpers'

import { parseLeader } from '../parseTeamsAndUsers'
import AddLeader from './add-leader'

const verifyBeforeExit =
  ({ handleNavigate, formState, setOpenConfirm, navigate }: any) =>
  () => {
    if (!Object.values(formState.values).some((item: any) => item.length))
      return navigate(handleNavigate)
    return setOpenConfirm(true)
  }

type Props = {
  handleSubmit: (formValues: object) => void
  submitting?: boolean
  error: string | null
  visible: boolean
  filledValues: any
  handleNavigate: Function | string
}

const Form = ({
  handleSubmit,
  submitting = false,
  error,
  visible,
  filledValues,
  handleNavigate,
}: Props) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const navigate = useNavigate()
  const { formState, fields, hasErrors } = useForm(
    {
      name: filledValues?.name || '',
      leadersToAdd: filledValues?.leadersToAdd || [],
      denomination: filledValues?.denomination.value || '',
      goalDescription: filledValues?.goalDescription || '',
    },
    {
      requiredFields: ['name', 'denomination', 'leadersToAdd'],
    },
  )

  const onSubmit: React.FormEventHandler = (e) => {
    e && e.preventDefault && e.preventDefault()
    handleSubmit && handleSubmit(formState.values)
  }
  return (
    <Step
      handleSubmit={onSubmit}
      error={error}
      disableButton={hasErrors}
      submitting={submitting}
      backButtonLabel="Sair"
      visible={visible}
      handleNavigate={verifyBeforeExit({
        handleNavigate,
        formState,
        openConfirm,
        setOpenConfirm,
        navigate,
      })}
    >
      <section className="flex flex-col">
        <Input
          title="Nome do círculo"
          {...fields('text', 'name', [presence()])}
          label="Nome do círculo"
          placeholder="Ex.: Marketing"
          autoComplete="off"
        />
      </section>
      <section className="flex flex-col">
        <Input
          title="O que este círculo faz?"
          {...fields('text', 'goalDescription', [maxLength(120)], {
            touchOnChange: true,
          })}
          label="O que este círculo faz?"
          placeholder="Ex.: Desenvolve soluções funcionais para atender às necessidades de clientes e usuários."
          autoComplete="off"
        />
      </section>
      <section className="flex flex-col">
        <Select
          {...fields('select', 'denomination', [], {
            touchOnChange: true,
          })}
          variant="outlined"
          label="Qual é a denominação deste círculo?"
          placeholder="Ex.: Área ou Time"
          name="denomination"
          options={getDenominationOptions()}
          defaultValue=""
        />
      </section>
      <section className="flex flex-col">
        <AddLeader
          {...fields(
            'raw',
            'leadersToAdd',
            [
              (value: any) =>
                value[0]?.inputName.length ? undefined : 'é obrigatório',
            ],
            {
              touchOnChange: true,
            },
          )}
          path="/users/browse_with_credential"
          step="circle"
          title="Quem é o líder deste círculo?"
          subtitle="Ao publicar este Círculo, somente o líder e admins poderão editá-lo."
          placeholder="Busque pelos nomes do colaborador."
          isAvatar
          showRole
          label=""
          selectedOptions={parseLeader(formState.values.leadersToAdd)}
          isCircleLeader
          debounceTime={600}
        />
      </section>
      <ExitConfirmationModal
        title="Deseja realmente sair?"
        exitWarning={
          filledValues?.id
            ? 'A edição será interrompida!'
            : 'O cadastro será interrompido!'
        }
        {...{ handleNavigate, setOpenConfirm, openConfirm }}
      />
    </Step>
  )
}

export default Form
