import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

type Props = {
  isOpen: boolean
  toggle: () => void
}

const MobileMenuButton = ({ isOpen, toggle }: Props) => (
  <IconButton
    color="primary"
    size="small"
    onClick={toggle}
    className="t-2 l-2 absolute z-[110] md:!hidden"
    aria-controls="menu"
    aria-expanded={isOpen}
    aria-label={isOpen ? 'Fechar menu' : 'Abrir menu'}
  >
    <MenuIcon />
  </IconButton>
)

export default MobileMenuButton
