import React from 'react'

import LastCycle from './Thumbs/LastCycle'
import CurrentCycle from './Thumbs/CurrentCycle'
import NextCycle from './Thumbs/NextCycle'
import { THUMB_NAMES } from '.'
import LongTerm from './Thumbs/LongTerm'

import type { SliderProps } from '../../types'

export default function Mark(props: SliderProps['props']) {
  const { 'data-label': label } = props

  switch (label) {
    case THUMB_NAMES.last:
      return <LastCycle key={label} props={props} isMark />
    case THUMB_NAMES.now:
      return <CurrentCycle key={label} props={props} isMark />
    case THUMB_NAMES.next:
      return <NextCycle key={label} props={props} isMark />
    case THUMB_NAMES.longterm:
      return <LongTerm key={label} props={props} isMark />
    default:
      return null
  }
}
