import type { ChangeEvent, FocusEvent } from 'react'
import type { Option, AutocompleteProps } from './Autocomplete'

export const getOptionLabel =
  (options: Option[]) => (option: string | number | Option) => {
    if (Array.isArray(option) && option.length === 0) return ''

    if (typeof option === 'string') {
      return option
    }
    if (typeof option === 'number') {
      const initialOption = options.find((opt) => opt.value === option)
      return initialOption ? initialOption.label : ''
    }
    return option.label
  }

export const getHandleChange =
  (onChange: AutocompleteProps['onChange']) =>
  (
    _: ChangeEvent<{}>,
    newInputValue: string | Option | (string | Option)[] | null,
  ) =>
    onChange(newInputValue)

export const getHandleBlur =
  (onBlur: AutocompleteProps['onBlur'], options: Option[]) =>
  (e: FocusEvent<HTMLInputElement>) => {
    const selectedOption = options.find((opt) => opt.label === e.target.value)
    if (selectedOption) {
      onBlur && onBlur(selectedOption)
    }
  }
