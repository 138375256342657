import React, { useState } from 'react'
import get from 'lodash/get'

import Step from 'shared/ui/form-step'

import StepSummary from '../../../new/steps/summary/StepSummary'
import summarize from '../../../new/steps/summary/summarize'
import Approvals from '../../../new/steps/summary/Approvals'
import ExitConfirmationModal from 'shared/ui/ExitConfirmationModal'

const verifyBeforeExit =
  ({
    setOpenConfirm,
  }: {
    setOpenConfirm: React.Dispatch<React.SetStateAction<boolean>>
  }) =>
    async () => {
      setOpenConfirm(true)
    }

type Props = {
  handleSubmit: Function,
  submitting: boolean,
  error: string | null,
  values: any,
  visible: boolean,
  handleNavigate: Function | string,
  nextButtonLabel: string,
}

const Review = ({
  handleSubmit,
  submitting = false,
  error,
  values,
  visible,
  handleNavigate,
  nextButtonLabel = 'Avançar',
}: Props) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const summarized = summarize(values)

  const onSubmit: React.FormEventHandler = async (e) => {
    e && e.preventDefault && e.preventDefault()
    handleSubmit && handleSubmit()
  }

  return (
    <Step
      handleSubmit={onSubmit}
      error={error}
      submitting={submitting}
      visible={visible}
      backButtonLabel="Sair"
      nextButtonLabel={nextButtonLabel}
      handleNavigate={verifyBeforeExit({
        setOpenConfirm,
      })}
    >
      <div className="flex flex-col gap-5">
        <div>
          <StepSummary startOpen questions={summarized.opening}>
            Abertura
          </StepSummary>
        </div>
        <div>
          <StepSummary
            questions={get(summarized, 'approval.approvalsAttributes.value')}
            render={(levels: any) => <Approvals levels={levels} />}
          >
            Aprovação
          </StepSummary>
        </div>
        <div>
          <StepSummary questions={summarized.performer}>Execução</StepSummary>
        </div>
      </div>
      <ExitConfirmationModal
        title="Deseja realmente sair?"
        exitWarning={'A edição será interrompida!'}
        {...{ handleNavigate, setOpenConfirm, openConfirm }}
      />
    </Step>
  )
}

export default Review
