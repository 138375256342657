const commonStyles = 'text-base flex justify-center items-center text-white'
const commonDashed = 'border-dashed border w-px relative left-[13px]'

export const styles = {
  default: `cursor-move ${commonStyles} bg-blue-500 `,
  disabled: `pointer-events-none ${commonStyles} bg-gray-300 `,
  displayOnly: `pointer-events-none ${commonStyles} bg-purple `,
  completed: `pointer-events-none ${commonStyles} bg-green `,
  round: 'rounded-full h-8 w-8 leading-8',
  square: 'rounded rotate-45 h-7 w-7 leading-6',
  dashedDefault: `${commonDashed} border-blue`,
  dashedDisabled: `pointer-events-none ${commonDashed} border-gray-light`,
  dashedCompleted: `pointer-events-none ${commonDashed} border-green-light`,
}

type Props = {
  isPaused?: boolean
  displayOnly?: boolean
  isCompleted?: boolean
  isMark?: boolean
}

const getStyle = ({ isPaused, displayOnly, isCompleted, isMark }: Props) => {
  if (isPaused) return styles.disabled
  if (isCompleted) return styles.completed
  if (isMark) return styles.disabled
  if (displayOnly) return styles.displayOnly
  return styles.default
}

export const getDashed = ({
  isPaused,
  displayOnly,
  isCompleted,
  isMark,
}: Props) => {
  if (isCompleted) return styles.dashedCompleted
  if (isPaused || displayOnly || isMark) return styles.dashedDisabled
  return styles.dashedDefault
}

export default getStyle
