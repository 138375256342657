import React from 'react'
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab/'
import { makeStyles } from '@material-ui/core/styles'
import AutocompleteInput from './AutocompleteInput'
import { getHandleBlur, getHandleChange, getOptionLabel } from './getters'

const useStyles = makeStyles(() => ({
  main: {
    margin: 0,
    '& .MuiAutocomplete-inputRoot': {
      padding: '.25rem !important',
    },
  },
}))

export type Option = {
  label: string
  value: string | number
  teamId?: number
}

export type AutocompleteProps = {
  value?: any[] | string
  onChange: (input: string | Option | (string | Option)[] | null) => void
  getOptionSelected?: (option: any, value: any) => boolean
  onBlur?: (option: Option) => void
  multiple?: boolean
  disabledOptions?: (option: Option) => boolean
  loadingText?: string
  noOptionsText?: string
  defaultValue?: Option[]
  key?: string
}

interface Props extends AutocompleteProps {
  inputProps: Record<string, any>
  loading: boolean
  options: Option[]
  query: string
  setQuery: React.Dispatch<React.SetStateAction<string>>
  defaultValue?: any
  disablePortal?: boolean
}

export default function Autocomplete({
  onChange,
  onBlur,
  multiple = true,
  disabledOptions = () => false,
  loadingText = 'Carregando...',
  noOptionsText = 'Nenhum dado encontrado',
  loading,
  options,
  query,
  setQuery,
  inputProps,
  defaultValue,
  getOptionSelected,
  disablePortal = false,
}: Props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  return (
    <div className={classes.main}>
      <MuiAutocomplete
        disablePortal={disablePortal}
        loadingText={loadingText}
        noOptionsText={noOptionsText}
        multiple={multiple}
        loading={loading}
        onChange={getHandleChange(onChange)}
        onBlur={getHandleBlur(onBlur, options)}
        getOptionLabel={getOptionLabel(options)}
        options={options}
        inputValue={query}
        getOptionDisabled={disabledOptions}
        getOptionSelected={getOptionSelected}
        onInputChange={(_, newInputValue) => {
          setQuery(newInputValue)
          newInputValue.length ? setOpen(true) : setOpen(false)
        }}
        onClose={() => setOpen(false)}
        open={open}
        defaultValue={defaultValue}
        renderInput={(params) => (
          <AutocompleteInput
            params={params}
            loading={loading}
            {...inputProps}
          />
        )}
      />
    </div>
  )
}
