import { format, parseISO } from 'date-fns'
import type { EventInput } from '@fullcalendar/react'

import { endDate } from 'portal/vacationsHelpers'

import type { Vacation, VacationEvent } from '../types'

type EventsProps = Array<EventInput>

export type ActionProps =
  | {
      payload: {
        initialState: Vacation[]
      }
      type: 'INITIAL_STATE'
    }
  | {
      payload: {
        event: EventInput
        shouldDelete?: boolean
      }
      type: 'UPDATE_EVENT'
    }
  | {
      payload: {
        event: Partial<EventInput>
      }
      type: 'UPDATE_EVENT_FROM_API'
    }

const makeUpdateEvents =
  (events: EventsProps) => (eventToAddOrUpdate: Partial<EventInput>) => {
    const index = events.findIndex(
      (e) => e.id?.toString() === eventToAddOrUpdate.id?.toString(),
    )
    return index === -1
      ? [...events, eventToAddOrUpdate]
      : [
          ...events.slice(0, index),
          {
            ...events[index],
            ...eventToAddOrUpdate,
          },
          ...events.slice(index + 1),
        ]
  }

export const reducer = (state: EventsProps, action: ActionProps) => {
  const updateEvents = makeUpdateEvents(state)

  switch (action.type) {
    case 'UPDATE_EVENT_FROM_API': {
      return updateEvents(action.payload.event)
    }

    case 'UPDATE_EVENT': {
      const newEvents = state
      const event = action.payload.event
      const shouldDelete = action.payload.shouldDelete || false
      const index = newEvents.findIndex(
        (e) => e.id?.toString() === event.id?.toString(),
      )

      const newVacationInfo = {
        editable: event.status === 'draft',
        edited: event.edited,
        start: event.startDate,
        end: endDate(parseISO(event.startDate), event.vacationDays),
        startDate: event.startDate,
        vacationDays: event.vacationDays,
        vacationDaysSold: event.vacationDaysSold,
        advanceThirteenth: event.advanceThirteenth,
        status: event.status,
        confirmationError: event.confirmationError,
        extendedProps: {
          advanceThirteenth: event.advanceThirteenth,
          confirmationError: event.confirmationError,
          endDate:
            event.startDate && event.vacationDays
              ? format(
                  endDate(parseISO(event.startDate), event.vacationDays),
                  'yyyy-MM-dd',
                )
              : null,
          id: event.id,
          resourceId: event.resourceId,
          solicitation: event.solicitation,
          startDate: event.startDate,
          status: event.status,
          title: event.title,
          vacationDays: event.vacationDays,
          vacationDaysSold: event.vacationDaysSold,
        },
      }

      const newEventArray: EventInput[] = shouldDelete
        ? [...newEvents.slice(0, index), ...newEvents.slice(index + 1)]
        : updateEvents({ ...event, ...newVacationInfo })

      return newEventArray
    }

    case 'INITIAL_STATE': {
      const dbEvents = action.payload.initialState?.reduce(
        (prev: EventInput[], curr: Vacation) => {
          const parsedEvents = curr.events.map(eventToFullCalendar)
          return [...prev, ...parsedEvents]
        },
        [],
      )!

      return dbEvents
    }

    default:
      throw new Error(
        `The type that was triggered into dispatch function,  is not supported`,
      )
  }
}

function eventToFullCalendar(event: VacationEvent): EventInput {
  return {
    resourceId: String(event.resourceId),
    id: String(event.id),
    title: event.title,
    start: event.startDate,
    end: endDate(parseISO(event.startDate), event.vacationDays),
    extendedProps: event,
    editable: event.status === 'draft',
  }
}
