import RadioGroup from '@material-ui/core/RadioGroup'
import React, { useState } from 'react'
import camelCase from 'lodash/camelCase'
import { cx } from 'shared/helpers'
import RadioCard from './RadioCard'

export type Option<T> = {
  label: string
  value: T
  description?: string
  color: string
  icon?: React.ReactNode
}

type Props<T> = {
  options: Option<T>[]
  previousValue?: T
  afterChange?: (newValue: T) => void
  className?: string
  title?: string
  center?: boolean
  fullWidth?: boolean
}

export default function Radio<T>({
  title,
  options,
  center,
  fullWidth,
  previousValue,
  afterChange,
  className,
}: Props<T>) {
  const [value, setValue] = useState(previousValue || null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value as unknown as T
    // @ts-expect-error
    setValue(newValue)
    if (afterChange) {
      afterChange(newValue)
    }
  }

  return (
    <div>
      <h4 className="mb-4">{title}</h4>
      <RadioGroup
        className={cx(
          'items-center !flex-col md:items-stretch md:!flex-row gap-8 md:justify-start text-2xl',
          className,
        )}
        aria-label={title}
        name={camelCase(title)}
        value={value}
        onChange={handleChange}
      >
        {options.map(
          ({
            label: optionLabel,
            value: optionValue,
            description,
            color,
            icon,
          }) => (
            <RadioCard<T>
              description={description}
              color={color}
              key={optionLabel}
              value={optionValue}
              label={optionLabel}
              icon={icon}
              center={center}
              fullWidth={fullWidth}
            />
          ),
        )}
      </RadioGroup>
    </div>
  )
}
