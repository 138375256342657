import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { cx } from 'shared/helpers'
import InputHint from './input-hint'

type Props = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'name' | 'type'
> & {
  name: string
  label?: string
  hint?: string
  options?: string[]
}
function RadioGroup({
  name,
  id = name,
  options,
  hint,
  label,
  required,
  defaultValue,
  className,
  ...props
}: Props) {
  let { register, formState, setValue } = useFormContext()

  let hasErrors = Boolean(formState.errors?.[name]?.message)

  React.useEffect(() => {
    setValue(name, defaultValue)
  }, [defaultValue, name, setValue])

  let hintId = `input-${id}-hint`
  return (
    <fieldset role="radiogroup" className="flex flex-col items-start gap-2">
      {label && <p className="label">{label + (required ? ' *' : '')}</p>}
      {options?.map((option, index) => {
        return (
          <label
            key={`${name}-${option}-${index}`}
            className="group inline-flex cursor-pointer items-center gap-1 text-sm"
          >
            <input
              className={cx(
                'input mr-2 h-6 w-6 cursor-pointer rounded-full group-focus:ring-blue-dark',
                hasErrors && '!border-red-dark !text-red-dark',
                className,
              )}
              type="radio"
              {...props}
              {...register(name)}
              value={option}
              id={id}
              aria-describedby={hasErrors ? hintId : undefined}
            />
            {option}
          </label>
        )
      })}
      <InputHint id={hintId} isError={hasErrors}>
        {formState.errors?.[name]?.message ?? hint}
      </InputHint>
    </fieldset>
  )
}

export default RadioGroup
