import * as React from 'react'
import type { Organization } from 'types'

import Table from 'shared/ui/table'
import Icon from 'shared/ui/icon'
import Modal from 'shared/ui/Modal'
import CheckInput from 'shared/forms/CheckInput'
import { useCroods } from 'croods'

type PermitTypes = 'talents_evaluations' | 'teams_evaluations'

type PropsConfirmationModal = {
  show: boolean
  onClose: () => void
  handleSave: () => void
  children: React.ReactNode
}
const ConfirmationModal = ({
  show,
  onClose,
  handleSave,
  children,
}: PropsConfirmationModal) => {
  return (
    <Modal.Wrapper open={show} onClose={onClose}>
      <Modal.Header>
        <div className="flex items-start">
          <h5 className="mx-2">Confirmar alteração</h5>
        </div>
      </Modal.Header>
      <Modal.Content className="mx-2 gap-6 md:min-w-[600px]">
        {children}
      </Modal.Content>
      <Modal.Actions cancelLabel="FECHAR">
        <Modal.Confirm color="primary" onClick={handleSave}>
          SALVAR E CONFIRMAR
        </Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

type PropsIconButton = {
  type: PermitTypes
  checked: boolean
}
const IconButton = ({ type, checked }: PropsIconButton) => {
  const [showModal, setShowModal] = React.useState(false)
  const [checkedAllCircles, setCheckedAllCircles] = React.useState(false)

  const [, { save, setInfo: setOrganization }] = useCroods({
    name: 'organization',
    id: type,
  })

  const PERMIT_CONTENT = {
    talents_evaluations: {
      description: 'permitir Avaliação de Talentos',
      alertMessage:
        'Todos os Círculos da organização terão Avaliação de Talentos habilitadas',
    },
    teams_evaluations: {
      description: 'permitir Avaliação de Performance',
      alertMessage:
        'Todos os Círculos da organização terão Avaliação de Performance habilitadas',
    },
  }

  const REVOKE_CONTENT = {
    talents_evaluations: {
      description: 'não permitir Avaliação de Talentos',
      alertMessage:
        'Todos os Círculos da organização terão Avaliação de Talentos desabilitadas',
    },
    teams_evaluations: {
      description: 'não permitir Avaliação de Performance',
      alertMessage:
        'Todos os Círculos da organização terão Avaliação de Performance desabilitadas',
    },
  }

  const dataContent = checked ? REVOKE_CONTENT[type] : PERMIT_CONTENT[type]

  return (
    <>
      <div className="group flex justify-center p-5">
        <div className="block cursor-pointer group-hover:hidden">
          {checked ? (
            <Icon icon="check-circle" className="text-green-300" />
          ) : (
            <Icon icon="block" />
          )}
        </div>
        <div className="hidden cursor-pointer group-hover:block">
          {checked ? (
            <button
              className="text-base font-semibold text-blue"
              onClick={() => {
                setShowModal(true)
              }}
            >
              NÃO PERMITIR
            </button>
          ) : (
            <button
              className="text-base font-semibold text-blue"
              onClick={() => {
                setShowModal(true)
              }}
            >
              PERMITIR
            </button>
          )}
        </div>
      </div>
      <ConfirmationModal
        show={showModal}
        onClose={() => {
          setCheckedAllCircles(false)
          setShowModal(false)
        }}
        handleSave={() => {
          save({
            customPath: `organization/${
              checked
                ? 'revoke'
                : checkedAllCircles
                ? 'permit_with_teams'
                : 'permit'
            }/${type}`,
            afterSuccess: ({ data: organization }) => {
              setOrganization(organization)
              setShowModal(false)
            },
          })()
        }}
      >
        <h5 className="font-normal">
          Você deseja{' '}
          <span className="font-bold">{dataContent.description}</span> na
          Organização?
        </h5>
        {!checked && (
          <CheckInput
            onChange={() => setCheckedAllCircles(!checkedAllCircles)}
            checked={checkedAllCircles}
            label="Habilitar em todos os Círculos"
          />
        )}
        {(checked || checkedAllCircles) && (
          <div className="flex items-center gap-2 rounded-2xl bg-orange-50 px-[10px] py-1">
            <Icon icon="error-outline" className="text-[#F0B858]" />
            <span className="text-base font-bold text-[#604A23]">
              {dataContent.alertMessage}
            </span>
          </div>
        )}
      </ConfirmationModal>
    </>
  )
}

const OrganizationPerformanceSettings = ({
  organization,
}: {
  organization: Organization
}) => {
  const classNameTd =
    'flex items-center justify-between text-left md:table-cell before:block before:font-bold before:content-[attr(data-title)] md:before:content-none'

  return (
    <>
      <h3 className="mb-6">Performance da Organização</h3>
      <div className="card">
        <div className="max-w-full">
          <Table id="collaborator-table">
            <colgroup>
              <col span={0} width="60%" />
            </colgroup>
            <Table.Thead>
              <Table.Th>Nome</Table.Th>
              <Table.Th className="text-center">Avaliar Performance</Table.Th>
              <Table.Th className="text-center">Avaliar Talentos</Table.Th>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr className="!py-6">
                <Table.Td data-title="Nome" className={classNameTd}>
                  {organization.name}
                </Table.Td>
                <Table.Td
                  data-title="Avaliar Performance"
                  className={classNameTd}
                >
                  <IconButton
                    type="teams_evaluations"
                    checked={organization.permitTeamsEvaluations}
                  />
                </Table.Td>
                <Table.Td data-title="Avaliar Talentos" className={classNameTd}>
                  <IconButton
                    type="talents_evaluations"
                    checked={organization.permitTalentsEvaluations}
                  />
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </div>
      </div>
    </>
  )
}

export { OrganizationPerformanceSettings }
