import * as React from 'react'

const RemoveUploadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="fill-[#5F91CC] h-6 w-6"
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z"></path>
  </svg>
)

export default RemoveUploadIcon
