type Payload = { payload?: any }[]

type Props = {
  payload?: Payload
  children?: (values: Record<string, any>) => JSX.Element
}

export default function Tooltip({ payload, children }: Props) {
  const values: Record<string, any> = payload ? payload[0]?.payload || {} : {}
  const dateText = values?.dateText

  return (
    <div className="flex flex-col space-y-1 rounded-lg bg-white p-4 font-extrabold opacity-90 shadow">
      {dateText && <div className="text-black/70">{dateText}</div>}
      {children && children(values)}
    </div>
  )
}
