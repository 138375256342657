import * as React from 'react'
import { useCroods } from 'croods'
import * as z from 'zod'
import { useCurrentUser } from 'croods-auth'
import type { SubmitHandler } from 'react-hook-form'
import Input from 'shared/formsv2/input'
import DateInput from 'shared/formsv2/date-input'
import SubmitButton from 'shared/formsv2/submit-button'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ControlledForm from 'shared/formsv2/form'
import ImageUploadInput, { parseFile } from 'shared/formsv2/image-upload-input'
import isEmpty from 'lodash/isEmpty'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { useEffect } from 'react'
import { notFutureDateSchema } from 'shared/schema-helpers'
import type { SnackbarSettings } from 'shared/ui/Snackbar/types'
import { renderIf } from 'shared/helpers'

const schema = z.object({
  invitationToken: z.string().nonempty(),
  password: z
    .string()
    .nonempty({ message: 'Digite uma senha' })
    .min(8, { message: 'Sua senha precisa ter no mínimo 8 caracteres.' }),
  passwordConfirmation: z
    .string()
    .nonempty({ message: 'Digite uma senha' })
    .min(8, { message: 'Sua senha precisa ter no mínimo 8 caracteres.' }),
  firstName: z.string().nonempty({ message: 'Não pode ficar em branco' }),
  lastName: z.string().nonempty({ message: 'Não pode ficar em branco' }),
  role: z.string(),
  admissionDate: notFutureDateSchema,
  image: z.string().transform((val) => {
    const file = parseFile(val)
    return file?.parsed ?? ''
  }),
})

const passwordStepSchema = schema
  .pick({ password: true, passwordConfirmation: true })
  .refine((data) => data.password === data.passwordConfirmation, {
    message:
      'Sua senha está diferente da primeira. Por favor, checar novamente.',
    path: ['passwordConfirmation'],
  })

const finalStepSchema = schema.pick({
  firstName: true,
  lastName: true,
  role: true,
  admissionDate: true,
  image: true,
})

type FormValues = z.infer<typeof schema>
type InvitationFormProps = {
  acceptInvitation: (data: Record<string, unknown>) => Promise<unknown>
}

function TwoStepInvite({ acceptInvitation }: InvitationFormProps) {
  const [step, setStep] = React.useState<'passwordStep' | 'finalStep'>(
    'passwordStep',
  )
  const handlePasswordStepSubmit: SubmitHandler<FormValues> = async (data) => {
    setPassword(data.password)
    setStep('finalStep')
  }
  const [password, setPassword] = React.useState<string>('')

  const handleFinalFormSubmit: SubmitHandler<FormValues> = async (data) => {
    return acceptInvitation({ ...data, password })
  }

  return (
    <div className="rounded-xl bg-white shadow">
      <div className="p-8 pb-0">
        <h3>Criação de perfil</h3>
      </div>

      {step === 'passwordStep' && (
        <ControlledForm
          onSubmit={handlePasswordStepSubmit}
          schema={passwordStepSchema}
          className="mt-4 flex flex-col gap-6"
        >
          <div className="flex flex-col px-8">
            <Input required type="password" name="password" label="Senha" />
          </div>

          <div className="flex flex-col px-8">
            <Input
              required
              type="password"
              name="passwordConfirmation"
              label="Confirmar senha"
            />
          </div>

          <section className="flex items-center justify-end rounded-b-xl bg-blue-50 p-8 text-center">
            <SubmitButton className="self-center rounded border border-blue p-2 text-sm font-bold uppercase text-blue hover:bg-white">
              Próximo
              <ArrowForwardIcon className="ml-2" fontSize="small" />
            </SubmitButton>
          </section>
        </ControlledForm>
      )}

      {step === 'finalStep' && !isEmpty(password) && (
        <ControlledForm
          onSubmit={handleFinalFormSubmit}
          schema={finalStepSchema}
          className="mt-4 flex flex-col gap-6"
        >
          <div className="flex flex-col items-center">
            <ImageUploadInput
              label="Avatar"
              id="avatar"
              name="image"
              hint="(opcional)"
              className="overflow-hidden rounded-full"
            />
          </div>

          <div className="flex flex-col px-8">
            <Input required type="firstName" name="firstName" label="Nome" />
          </div>

          <div className="flex flex-col px-8">
            <Input required type="lastName" name="lastName" label="Sobrenome" />
          </div>

          <div className="flex flex-col px-8">
            <Input type="role" name="role" label="Cargo" />
          </div>

          <div className="flex flex-col px-8">
            <DateInput
              name="admissionDate"
              label="Data de admissão"
              aria-label="Data de admissão"
            />
          </div>

          <section className="flex items-center justify-between rounded-b-xl bg-blue-50 p-8 text-center">
            <button
              type="button"
              className="cursor-pointer self-center border-blue p-2 text-base !font-bold uppercase text-blue hover:bg-white"
              onClick={() => setStep('passwordStep')}
            >
              Voltar
            </button>
            <SubmitButton
              className="self-center rounded border border-blue p-2 text-sm font-bold uppercase text-blue hover:bg-white"
              submitting="Criando..."
            >
              Criar perfil
              <ArrowForwardIcon className="ml-2" fontSize="small" />
            </SubmitButton>
          </section>
        </ControlledForm>
      )}
    </div>
  )
}

function OneStepInvite({ acceptInvitation }: InvitationFormProps) {
  const oneStepSchema = schema
    .pick({ image: true, password: true, passwordConfirmation: true })
    .refine((data) => data.password === data.passwordConfirmation, {
      message:
        'Sua senha está diferente da primeira. Por favor, checar novamente.',
      path: ['passwordConfirmation'],
    })

  return (
    <div className="rounded-xl bg-white shadow">
      <div className="p-8 pb-0">
        <h3>Criação de perfil</h3>
      </div>
      <ControlledForm
        onSubmit={async (data) => {
          await acceptInvitation(data)
        }}
        schema={oneStepSchema}
        className="mt-4 flex flex-col gap-6"
      >
        <div className="flex flex-col items-center">
          <ImageUploadInput
            label="Avatar"
            id="avatar"
            name="image"
            hint="(opcional)"
            className="overflow-hidden rounded-full"
          />
        </div>

        <div className="flex flex-col px-8">
          <Input required type="password" name="password" label="Senha" />
        </div>

        <div className="flex flex-col px-8">
          <Input
            required
            type="password"
            name="passwordConfirmation"
            label="Confirmar senha"
          />
        </div>

        <section className="flex items-center justify-end rounded-b-xl bg-blue-50 p-8 text-center">
          <SubmitButton
            className="self-center rounded border border-blue p-2 text-sm font-bold uppercase text-blue hover:bg-white"
            submitting="Criando..."
          >
            Criar perfil
            <ArrowForwardIcon className="ml-2" fontSize="small" />
          </SubmitButton>
        </section>
      </ControlledForm>
    </div>
  )
}

function AcceptInvite() {
  const navigate = useNavigate()
  const [{ currentUser }, setCurrentUser] = useCurrentUser()
  const { snackbar } = useSnackbar()
  const [message, setMessage] = React.useState<SnackbarSettings>({
    message:
      'Voce já esta logado em uma conta. Por favor, deslogue da conta atual para acessar seu novo convite.',
    type: 'error',
  })
  const loggedIn = Boolean(currentUser?.id)

  useEffect(() => {
    if (!loggedIn) return

    navigate('/')
    snackbar(message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  const params = useParams()

  const invitationToken = params.token
  const [{ info }] = useCroods<{ imported: boolean }>({
    name: 'invitedUser',
    id: invitationToken,
    customPath: `/users/${invitationToken}/invitation`,
    fetchOnMount: true,
  })

  const [, { save }] = useCroods<FormValues>({
    name: 'invitation',
    customPath: '/auth/invitation',
    afterSuccess: async (response) => {
      setMessage({
        message: 'Conta criada com sucesso. Bem vindo ao Vibe!',
        type: 'success',
      })
      setCurrentUser(response.data)
    },
    after4xx: () => {
      navigate('/login')
      snackbar({
        message:
          'Convite inválido e/ou expirado. Tente novamente ou solicite um novo convite',
        type: 'error',
      })
    },
  })

  const FormComponent = info?.imported ? OneStepInvite : TwoStepInvite
  return renderIf(
    info !== null,
    <div className="my-4 mx-1 sm:mx-10 sm:my-10 md:my-14">
      <section className="mx-auto w-[95%] max-w-[512px]">
        <FormComponent
          acceptInvitation={async (data) =>
            save({ method: 'PUT' })({ ...data, invitationToken })
          }
        />
      </section>
    </div>,
  )
}

export { passwordStepSchema, finalStepSchema }
export default AcceptInvite
