import React from 'react'
import StepIcon from '@material-ui/core/StepIcon'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { withStyles, alpha } from '@material-ui/core/styles'
import { colors } from 'theme'

const DarkPrimaryStepIcon = withStyles((theme) => ({
  root: {
    color: alpha(colors.blue, 0.08),
  },
  text: {
    fill: colors.textDisabled,
  },
  active: {
    color: `${theme.palette.primary.dark} !important`,
  },
}))(StepIcon)

type Props = {
  steps: string[]
  activeStep: number
  handleNavigate: (index: number) => React.MouseEventHandler<HTMLDivElement>
}

export default function HorizontalLabelPositionBelowStepper({
  steps,
  activeStep,
  handleNavigate,
}: Props) {
  return (
    <div className="w-full">
      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const clickableProps =
            index < activeStep
              ? {
                  onClick: handleNavigate(index),
                  style: { cursor: 'pointer' },
                }
              : {}

          return (
            <Step key={label} {...clickableProps}>
              <StepLabel StepIconComponent={DarkPrimaryStepIcon}>
                {steps.length > 4 &&
                index !== activeStep &&
                index !== steps.length - 1 ? (
                  <>
                    <span className="stepper-label inline-block md:hidden">
                      ...
                    </span>
                    <span className="stepper-label hidden md:inline-block">
                      {label}
                    </span>
                  </>
                ) : (
                  <span className="stepper-label">{label}</span>
                )}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
}
