import React from 'react'

import Input from 'shared/forms/Input'
import get from 'lodash/get'

const CustomInput = ({ error, ...props }: any) => (
  <Input {...props} error={translateError(error)} />
)

const errorTranslations = {
  'Is required': 'É obrigatório',
  'Invalid email': 'Email inválido',
  'Must be equal to password': 'Deve ser igual a senha',
  'Minimum 8 characters': 'Mínimo 8 caracteres',
  'Password fields must be equal': 'Deve ser igual a senha',
}

export const translateError = (error: any) =>
  get(errorTranslations, error, error)

export default CustomInput
