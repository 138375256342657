import React from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import Icon from 'shared/ui/icon'
import { cx } from 'shared/helpers'

type Props = {
  children: React.ReactNode
  folderName: string
}

export default function FolderSection({ children, folderName }: Props) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            as="div"
            className={cx(
              'hover:cursor-pointer',
              'group',
              !open ? 'stroke-gray-dark ' : 'stroke-blue',
            )}
          >
            <div className="mt-6 flex justify-start justify-items-center hover:bg-blue-lightest hover:text-blue">
              <div className="test flex h-7 w-7 rounded-full group-hover:stroke-blue">
                <svg
                  aria-hidden="true"
                  className={cx(
                    'transition duration-300',
                    open ? 'rotate-90' : '',
                  )}
                  data-reactid="266"
                  fill="none"
                  height="24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline points={'9 6 15 12 9 18'} />
                </svg>
              </div>
              <Icon icon={'folder-outlined'} className="text-gray-dark" />
              <div className="flex flex-col justify-center">
                <h5 className={cx('ml-1', 'text-gray-darkest')}>
                  {folderName}
                </h5>
              </div>
            </div>
          </Disclosure.Button>
          <Transition
            enter="transition duration-200 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-200 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel>
              <div className="pl-7">{children}</div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}
