import React from 'react'
import Grid from '@material-ui/core/Grid'
import Evaluation from 'performance/evaluations/teamEvaluation/EvaluationCard'
import Period from 'shared/ui/Period'
import type { FormState } from '../types'
import useSummaryData from './useSummaryData'

const removeTags = (text: string) => text && text.replace(/<[^>]+>/g, '')

type SecondaryProps = {
  title?: string
  description?: string
}

const SecondaryFields = ({ title, description }: SecondaryProps) => {
  if (!description) {
    return null
  }
  const descriptionWithoutTags = removeTags(description)

  return descriptionWithoutTags ? (
    <div>
      {title && <p className="text-black-500 text-base font-bold">{title}</p>}
      <p
        className="text-black-800 mt-2"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  ) : null
}

type Props = {
  formState: FormState
}

const Content = ({ formState }: Props) => {
  const { evaluatedAt, fields, optionalFields } = useSummaryData({
    formState,
  })

  const [day, month, year] = evaluatedAt.split('/')
  const isoDate = `${year}-${month}-${day}`

  return (
    <Grid container direction="column" className="gap-8">
      {formState?.cycleStartedAt && formState?.cycleFinishedAt && (
        <Period
          {...({
            title: 'Ciclo',
            startsAt: formState.cycleStartedAt,
            finishesAt: formState.cycleFinishedAt,
          } as any)}
        />
      )}
      <div className="mb-2 grid grid-cols-3 gap-6">
        {fields.map((item) => (
          <Evaluation
            key={item.title}
            title={item.title}
            type={item.type}
            description={item.description}
            score={item.score || 0}
          />
        ))}
      </div>
      <div className="flex flex-col gap-6">
        {optionalFields.map((item) => (
          <SecondaryFields
            key={item.title}
            title={item.title}
            description={item?.description || ''}
          />
        ))}
        <Period {...({ title: 'Data', evaluatedAt: isoDate } as any)} />
      </div>
    </Grid>
  )
}

export default Content
