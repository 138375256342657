import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as ReTooltip,
} from 'recharts'
import type { ResponsiveContainerProps } from 'recharts'

import { cx } from 'shared/helpers'
import { colors } from 'theme'
import Tick from './tick'
import Tooltip from './tooltip'

type BarsProps = {
  data?: any[]
  tooltip: (values: Record<string, any>) => JSX.Element
} & ResponsiveContainerProps

export default function Bars({
  data,
  className,
  children,
  tooltip,
  ...props
}: BarsProps) {
  return (
    <div style={{ transform: 'translateX(-8.5%)' }}>
      <ResponsiveContainer
        height={200}
        width="100%"
        className={cx('px-5', className)}
        {...props}
      >
        <BarChart data={data} barSize={24}>
          <CartesianGrid
            strokeDasharray="12"
            stroke={colors.divider}
            vertical={false}
          />
          <XAxis
            dataKey="dateLabel"
            tick={<Tick posX={0} posY={0} />}
            stroke={colors.textHint}
          />
          <YAxis
            type="number"
            allowDecimals={false}
            tick={<Tick posX={-5} posY={-7} />}
            stroke={colors.textHint}
            domain={[0, 'dataMax']}
          />
          <ReTooltip
            content={<Tooltip>{tooltip}</Tooltip>}
            cursor={{ fill: 'rgba(0, 0, 0, 0.03)' }}
          />
          {children}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
