import React, { memo } from 'react'
import { Skeleton } from '@material-ui/lab'
import { useLocation } from 'react-router-dom'
import { Fetch } from 'croods'
import get from 'lodash/get'
import { getFullName } from 'shared/helpers'
import type { Breadcrumb, TeamMemberType, User } from 'types'
import Item from './item'

type PathVar = {
  path: string
  getPath: string
}

const pathVariables: {
  [index: string]: PathVar
} = {
  ':teamId': {
    path: 'team',
    getPath: 'label',
  },
  ':userId': {
    path: 'users',
    getPath: 'firstName',
  },
  ':solicitationId': {
    path: 'workflow/solicitation_templates',
    getPath: 'name',
  },
  ':teamMemberId': {
    path: 'team_members',
    getPath: 'user.firstName',
  },
  ':teamLeaderId': {
    path: 'team_leaders',
    getPath: 'user.firstName',
  },
  ':name': {
    path: ':name',
    getPath: '',
  },
}

const getCorrectPath = (path: string, url: string, type: TeamMemberType) => {
  const urlSections = url.split('/')
  const pathSections = path.split('/')
  const queryString = type === 'leaders' ? '?type=leaders' : ''

  const newPath = pathSections.map((section, idx) => {
    if (section.includes(':')) {
      return urlSections[idx]
    }
    return section
  })

  return newPath.join('/') + queryString
}

const getFetchVariable = (path: string, url: string, type: TeamMemberType) => {
  const pathSections = path.split('/')
  const urlSections = url.split('/')

  const variableIndex = pathSections.findIndex((section) =>
    section.includes(':'),
  )
  const isLast = variableIndex + 1 === pathSections.length
  const param = isLast ? pathSections[variableIndex] : ''
  const id = isLast ? urlSections[variableIndex] : ''
  const leaderType = param === ':teamMemberId' && type === 'leaders'
  const variable = leaderType ? ':teamLeaderId' : param

  return [variable, id]
}

const getTitle = ({ getPath }: PathVar, info: any): string => {
  if (getPath === 'firstName') {
    return getFullName(info as User)
  }

  if (getPath === 'user.firstName') {
    return getFullName(info.user as User)
  }

  return get(info, getPath)
}

type Props = {
  routeInfo: Breadcrumb
  last?: boolean
}

function BreadcrumbItem({ routeInfo, last }: Props) {
  const { pathname: url, search } = useLocation()
  const { title, icon, module, path } = routeInfo
  const type: TeamMemberType = (
    search ? search.split('?type=')[1] : 'members'
  ) as TeamMemberType

  const correctPath = getCorrectPath(path, url, type)
  const [fetchVariable, fetchId] = getFetchVariable(path, url, type)
  const fetchInfo = pathVariables[fetchVariable]

  const breadcrumbIcon = module ? `/modules-icons/${icon}-breadcrumb.svg` : icon

  if (fetchVariable && fetchInfo && fetchId && last) {
    return (
      <Fetch
        name={fetchInfo.path}
        id={fetchId}
        render={(info) => {
          const fetchTitle = getTitle(fetchInfo, info)

          return (
            <Item
              key={fetchTitle}
              title={fetchTitle}
              last={last}
              to={correctPath}
              isModule={module}
              icon={breadcrumbIcon}
            />
          )
        }}
        renderError={() => <Skeleton className="!h-12 !min-h-0 !w-20" />}
        renderLoading={() => <Skeleton className="!h-12 !min-h-0 !w-20" />}
      />
    )
  }

  return (
    <Item
      key={title}
      title={title}
      last={last}
      to={correctPath}
      isModule={module}
      icon={breadcrumbIcon}
    />
  )
}

export default memo(BreadcrumbItem)
