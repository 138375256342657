import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import isEmpty from 'lodash/isEmpty'

import FieldValue from './fieldValue'

const ApprovalLevel = ({ approvers, inputsAttributes, index }: any) => {
  const emptyApprovers = isEmpty(approvers)
  const emptyInputs = isEmpty(inputsAttributes)

  if (emptyApprovers && emptyInputs) return null

  return (
    <>
      <Grid item style={{ marginBottom: 20 }}>
        <Typography variant="h3">Nível {index + 1}</Typography>
      </Grid>
      {emptyApprovers || (
        <Grid item style={{ marginBottom: 20 }}>
          <FieldValue label="Aprovadores" value={approvers} variant="chips" />
        </Grid>
      )}
      {emptyInputs || (
        <Grid item style={{ marginBottom: 20 }}>
          <FieldValue
            label="Perguntas"
            value={inputsAttributes}
            variant="questions"
          />
        </Grid>
      )}
    </>
  )
}

export default ApprovalLevel
