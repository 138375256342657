import * as React from 'react'

import { cx } from 'shared/helpers'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'theme'
import { useIsMobile } from 'shared/utils/hooks'

import type { Box } from '.'
import Label from './label'
import Tag from './tag'
import useRating from './useRating'
import Avatars from './avatars'

const useStyles = makeStyles((theme) => ({
  box: {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-16px',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 2,
      margin: 0,
      borderRadius: 'inherit',
      border: `3px solid ${colors.blue}`,
      opacity: 0,
      transition: 'opacity .15s ease',
    },
    '&:hover::before': { opacity: 1, cursor: 'pointer' },
  },
  selected: {
    '&::before': {
      opacity: 1,
    },
  },
  avatarFormatter: {
    flexWrap: 'wrap',
    padding: theme.spacing(3, 3, 2),
    height: '128px',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& > div': {
      marginLeft: '5px!important',
      marginRight: '5px',
    },
  },
}))

type Props = Box & {
  totalTeams: number
  active: boolean
  onSelect: () => void
  onUnselect: () => void
}

const COLORS = {
  low: colors.red,
  medium: colors.yellow,
  high: colors.green,
  veryHigh: colors.greenDarkest,
}

export default function Card({
  teams,
  totalTeams,
  goalType,
  reachedGoals,
  active,
  onSelect,
  onUnselect,
}: Props) {
  const isMobile = useIsMobile()

  const classes = useStyles()
  const rating = useRating(goalType, reachedGoals)
  const color = COLORS[rating]

  const handleClick = React.useCallback(() => {
    if (active) {
      onUnselect()
    } else {
      onSelect()
    }
  }, [active, onSelect, onUnselect])

  return (
    <div
      className={cx(
        'card relative h-[90px] w-full select-none !overflow-visible rounded-2xl border-t-[16px] border-white sm:h-[210px] sm:w-[300px]',
        classes.box,
        active && classes.selected,
      )}
      style={{ borderTopColor: color }}
      onClick={handleClick}
    >
      <Label goalType={goalType} reachedGoals={reachedGoals} />
      {!isMobile ? <Avatars teams={teams} /> : null}
      <Tag count={teams.length} total={totalTeams} />
    </div>
  )
}
