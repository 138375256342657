import { CroodsProvider } from 'croods'
import { authHeaders, saveHeaders } from 'croods-auth'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { StylesProvider } from '@material-ui/core/styles'
import partialRight from 'lodash/partialRight'

import { OrganizationProvider } from 'shared/organization/OrganizationContext'

import Loading from 'shared/ui/Loading'
import theme from 'theme'
import getOrganizationSlug from 'shared/organization/getOrganizationSlug'
import snakeCase from 'lodash/snakeCase'
import SnackbarProvider from 'shared/ui/Snackbar/SnackbarProvider'
import { useRedirectWhenUnauthenticated } from 'shared/utils/hooks'
import type { ReactNode } from 'react'
import { authStorageKey } from 'domain/auth'

const headersOptions = {
  storageKey: authStorageKey(),
}

const headers = () => ({
  ...authHeaders(headersOptions),
  Organization: getOrganizationSlug(),
})

const getBaseURL = () =>
  (window as any)?.env?.REACT_APP_API_URL ?? process.env.REACT_APP_API_URL

const UiProviders = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={theme}>
    <SnackbarProvider>
      <StylesProvider>
        <CssBaseline />
        {children}
      </StylesProvider>
    </SnackbarProvider>
  </ThemeProvider>
)

const Providers = ({
  children,
  renderOrganizationProvider,
}: {
  children: ReactNode
  renderOrganizationProvider: boolean
}) => {
  const redirectWhenUnauthenticated = useRedirectWhenUnauthenticated()
  return (
    <CroodsProvider
      handleResponseHeaders={partialRight(saveHeaders, headersOptions)}
      headers={headers}
      baseUrl={getBaseURL()}
      renderLoading={Loading}
      requestTimeout={30000}
      urlParser={snakeCase}
      renderError={(error: any) => (
        <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
      )}
      after4xx={redirectWhenUnauthenticated()}
    >
      {renderOrganizationProvider ? (
        <OrganizationProvider>
          <UiProviders>{children}</UiProviders>
        </OrganizationProvider>
      ) : (
        <UiProviders>{children}</UiProviders>
      )}
    </CroodsProvider>
  )
}

export default Providers
