import { useEffect } from 'react'
import { useCroods } from 'croods'
import TeamCard from 'shared/ui/teamMembersCard/Card'
import Pagination from 'shared/ui/pagination/Pagination'
import useCroodsHeaders from 'shared/utils/useCroodsHeader'
import { usePagination, useRestoreScrollOnChange } from 'shared/utils/hooks'

import Skeleton from 'shared/ui/skeleton'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import { Link } from 'react-router-dom'
import { getDenomination, mountQueryString } from 'shared/helpers'

const CircleTable = ({ rows }: { rows: any[] }) => {
  return (
    <div className="flex flex-col gap-4">
      {rows.map(
        (
          { id, name, leaders = [], members = [], status, denomination },
          index,
        ) => (
          <div key={`myteam-${id}-${index}`}>
            <Link to={`/circulos/${id}`}>
              <TeamCard
                name={name}
                leaders={leaders}
                members={members}
                teamId={id}
                status={status}
                denomination={getDenomination(denomination)}
              />
            </Link>
          </div>
        ),
      )}
    </div>
  )
}

const CirclesList = ({ searchTerm, userStateId }: any) => {
  const { headers, parseResponse } = useCroodsHeaders()

  const { currentPage, setCurrentPage, totalPages } = usePagination(
    Number(headers['per-page']),
    Number(headers['total']),
  )
  useRestoreScrollOnChange(currentPage)

  const path = `/team${mountQueryString({
    page: currentPage,
    user_id: userStateId,
    search: searchTerm,
  })}`

  const [{ list, fetchingList }, { fetch }] = useCroods({
    name: 'teams',
    path,
    stateId: userStateId?.toString(),
    cache: false,
    fetchOnMount: false,
    parseResponse,
  })

  useEffect(() => {
    fetch()()
  }, [currentPage, searchTerm, userStateId]) // eslint-disable-line

  useEffect(() => {
    setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, userStateId])

  return !fetchingList ? (
    headers['per-page'] ? (
      totalPages > 0 ? (
        <>
          <CircleTable rows={list} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageClick={setCurrentPage}
          />
        </>
      ) : (
        <FeedbackMessage
          message="A consulta não retornou resultados"
          type="warning"
          serverMessage=""
        />
      )
    ) : (
      <FeedbackMessage message="Erro no servidor" serverMessage="" />
    )
  ) : (
    <Skeleton height="400px" />
  )
}

export default CirclesList
