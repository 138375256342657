import React from 'react'
import { colors } from 'theme'

type Payload = {
  value: React.ReactNode
}

type Props = {
  posX?: number
  posY?: number
  x?: number
  y?: number
  payload?: Payload
}

function BaseTick({ value }: Payload) {
  const position = { dx: 0, dy: 10, textAnchor: 'middle' }
  const normalFormat = {
    fill: colors.textHint,
    fontWeight: 'normal',
    fontSize: '10px',
    letterSpacing: '0.2px',
  }

  return (
    <text {...position} {...normalFormat}>
      {value}
    </text>
  )
}

export default function CustomTick({
  posX = 0,
  posY = 0,
  x = 0,
  y = 0,
  payload,
}: Props) {
  return (
    <g transform={`translate(${x + posX},${y + posY})`} height="200%">
      <BaseTick value={payload?.value} />
    </g>
  )
}
