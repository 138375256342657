import * as React from 'react'
import { useFormState } from 'react-use-form-state'
import { useCroods } from 'croods'
import isEmpty from 'lodash/isEmpty'
import OnOff from 'shared/ui/on-off'
import DateInput from 'shared/forms/DateInput'
import { date, presence } from 'shared/forms/validations'
import Modal from 'shared/ui/Modal'
import { periodDictionary } from 'shared/ui/Period'
import type { Moment } from 'moment'
import moment from 'moment'

type FormState = {
  startsAt: Moment | null
  durationInMonths: number
  permitClocking: boolean
  permitDocuments: boolean
  permitSolicitations: boolean
  permitVacations: boolean
  permitOneOnOneConversation: boolean
  permitCareerConversation: boolean
  permitBehaviorConversation: boolean
  permitUpwardEvaluation: boolean
  permitFeedback: boolean
  permitTalentsEvaluations: boolean
  permitTeamsEvaluations: boolean
}

export default function SetCurrentCycle() {
  const [formState, { raw, select }] = useFormState<FormState>({
    startsAt: null,
    durationInMonths: 3,
    permitClocking: false,
    permitDocuments: false,
    permitSolicitations: false,
    permitVacations: false,
    permitOneOnOneConversation: false,
    permitCareerConversation: false,
    permitBehaviorConversation: false,
    permitUpwardEvaluation: false,
    permitFeedback: false,
    permitTalentsEvaluations: false,
    permitTeamsEvaluations: false,
  })
  const [{ saving, saveError }, { save }] = useCroods({
    name: 'newCycle',
    path: '/supervisor/organization_cycles',
    afterSuccess: () => {
      window.location.reload()
    },
  })

  const options = [
    { value: 3, label: periodDictionary[3] },
    { value: 4, label: periodDictionary[4] },
    { value: 6, label: periodDictionary[6] },
  ]

  const handleConfirm = async () => {
    if (
      formState.values.startsAt &&
      Boolean(formState.values.durationInMonths)
    ) {
      const startsAt = moment(formState.values.startsAt, 'DD/MM/YYYY').format(
        'YYYY-MM-DD',
      )

      save()({
        startsAt,
        durationInMonths: Number(formState.values.durationInMonths),
        permitClocking: formState.values.permitClocking,
        permitDocuments: formState.values.permitDocuments,
        permitSolicitations: formState.values.permitSolicitations,
        permitVacations: formState.values.permitVacations,
        permitOneOnOneConversation: formState.values.permitOneOnOneConversation,
        permitCareerConversation: formState.values.permitCareerConversation,
        permitBehaviorConversation: formState.values.permitBehaviorConversation,
        permitUpwardEvaluation: formState.values.permitUpwardEvaluation,
        permitFeedback: formState.values.permitFeedback,
        permitTalentsEvaluations: formState.values.permitTalentsEvaluations,
        permitTeamsEvaluations: formState.values.permitTeamsEvaluations,
      })
    }
  }

  const firstDayOfCurrentMonth = moment()?.startOf('month')

  const { startsAt, durationInMonths } = formState.values

  const handlePermitClocking = (event: { permitClocking: boolean }) => {
    formState.setField('permitClocking', event.permitClocking)
    event.permitClocking && formState.setField('permitSolicitations', true)
  }

  const handlePermitSolicitations = (event: {
    permitSolicitations: boolean
  }) => {
    formState.setField('permitSolicitations', event.permitSolicitations)
    !event.permitSolicitations && formState.setField('permitClocking', false)
    !event.permitSolicitations && formState.setField('permitVacations', false)
  }

  const handlePermitVacations = (event: { permitVacations: boolean }) => {
    formState.setField('permitVacations', event.permitVacations)
    event.permitVacations && formState.setField('permitSolicitations', true)
  }

  return (
    <Modal.Wrapper
      onConfirm={handleConfirm}
      onClose={() => {}}
      enabled={
        Boolean(startsAt) &&
        Boolean(durationInMonths) &&
        isEmpty(
          Object.entries(formState.errors).filter(([, error]) => {
            error
          }),
        ) &&
        !saving
      }
      loading={saving}
    >
      <Modal.Header>
        <Modal.Title>Configurações iniciais da organização</Modal.Title>
      </Modal.Header>

      <div className="h-fit max-h-[70vh] overflow-y-auto rounded-lg">
        <Modal.Content className="gap-7 px-12 pt-6 pb-8 text-base text-gray-darkest">
          <div>
            <h6 className="mb-2 text-[1rem] font-bold">Ciclos</h6>

            <p>
              Olá, administrador. Você precisa definir o mês de início e duração
              dos Ciclos da sua organização.
            </p>
            <p className="mb-2">
              Essa definição é obrigatória, mas poderá ser alterada no futuro.
            </p>

            <DateInput
              label="Mês de início do Ciclo"
              labelClassName="font-bold"
              id="startsAt"
              className="!mt-1 w-full"
              format="MM/YYYY"
              placeholder="Escolha a data"
              minDate={firstDayOfCurrentMonth.toDate()}
              maxDate={undefined}
              disabled={false}
              error={formState.errors.startsAt}
              views={['month', 'year']}
              {...raw({
                name: 'startsAt',
                validate: (value: string | Moment | null) => {
                  const errorDate = date()(value as Moment)
                  const errorPresence = presence('Campo obrigatório')(
                    value as string,
                  )
                  if (moment(value).isBefore(firstDayOfCurrentMonth)) {
                    return 'Data inválida'
                  }
                  return errorPresence || errorDate
                },
                onChange: (value: Moment | null) =>
                  value ? value.startOf('month') : null,
              })}
            />

            <label htmlFor="durationInMonths" className="font-bold">
              Duração dos Ciclos
              <div className="relative">
                <select
                  className="mt-1 block w-full appearance-none rounded border border-blue bg-white px-2 py-4 text-base"
                  {...select('durationInMonths')}
                >
                  {options.map((option) => (
                    <option
                      key={`cycle-duration-${option.value}`}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute bottom-0 right-2 flex h-full items-center">
                  <svg
                    className="h-6 w-6 fill-current text-lg text-blue"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M7 10l5 5 5-5z" />
                  </svg>
                </div>
              </div>
            </label>
          </div>

          <div>
            <h6 className="mb-2 text-[1rem] font-bold">Conversas</h6>

            <p className="mb-3">
              Quais Conversas serão habilitados na sua Organização? Essa
              definição poderá ser alterada depois.
            </p>

            <div className="flex flex-col items-center rounded border-blue/70">
              <OnOff
                {...raw({
                  name: 'permitOneOnOneConversation',
                  validate: () => true,
                })}
                label="Fazer Conversas de Onboarding, Efetivação e 1:1"
                type="permitOneOnOneConversation"
                handleChange={() => {}}
              />

              <OnOff
                {...raw({
                  name: 'permitCareerConversation',
                  validate: () => true,
                })}
                label="Fazer Conversas de Carreira"
                type="permitCareerConversation"
                handleChange={() => {}}
              />

              <OnOff
                {...raw({
                  name: 'permitBehaviorConversation',
                  validate: () => true,
                })}
                label="Fazer Conversas de Comportamento"
                type="permitBehaviorConversation"
                handleChange={() => {}}
              />

              <OnOff
                {...raw({
                  name: 'permitUpwardEvaluation',
                  validate: () => true,
                })}
                label="Fazer Avaliação Ascendente"
                type="permitUpwardEvaluation"
                handleChange={() => {}}
              />

              <OnOff
                {...raw({
                  name: 'permitFeedback',
                  validate: () => true,
                })}
                label="Fazer Feedbacks"
                type="permitFeedback"
                handleChange={() => {}}
              />
            </div>
          </div>

          <div>
            <h6 className="mb-2 text-[1rem] font-bold">Performance</h6>

            <p className="mb-3">
              Quais Avaliações serão habilitadas na sua Organização? Essa
              definição poderá ser alterada depois.
            </p>

            <div className="flex flex-col items-center rounded border-blue/70">
              <OnOff
                {...raw({
                  name: 'permitTeamsEvaluations',
                  validate: () => true,
                })}
                label="Fazer Avaliação de Performance"
                type="permitTeamsEvaluations"
                handleChange={() => {}}
              />

              <OnOff
                {...raw({
                  name: 'permitTalentsEvaluations',
                  validate: () => true,
                })}
                label="Fazer Avaliação Talentos"
                type="permitTalentsEvaluations"
                handleChange={() => {}}
              />
            </div>
          </div>

          <div>
            <h6 className="mb-2 text-[1rem] font-bold">Portal</h6>

            <p className="mb-3">
              Quais recursos serão habilitados na sua Organização? Essa
              definição poderá ser alterada depois.
            </p>

            <div className="flex flex-col items-center rounded border-blue/70">
              <OnOff
                {...raw({
                  name: 'permitSolicitations',
                  validate: () => true,
                })}
                label="Solicitações"
                type="permitSolicitations"
                handleChange={handlePermitSolicitations}
              />

              <OnOff
                {...raw({
                  name: 'permitClocking',
                  validate: () => true,
                })}
                label="Acerto de Ponto"
                type="permitClocking"
                handleChange={handlePermitClocking}
              />

              <OnOff
                {...raw({
                  name: 'permitDocuments',
                  validate: () => true,
                })}
                label="Documentos"
                type="permitDocuments"
                handleChange={() => {}}
              />

              <OnOff
                {...raw({
                  name: 'permitVacations',
                  validate: () => true,
                })}
                label="Férias"
                type="permitVacations"
                handleChange={handlePermitVacations}
              />
            </div>
          </div>

          {saveError && <p>{saveError}</p>}
        </Modal.Content>

        <Modal.Actions hideCancel className={'!justify-end'}>
          <Modal.Confirm color="primary">Confirmar</Modal.Confirm>
        </Modal.Actions>
      </div>
    </Modal.Wrapper>
  )
}
