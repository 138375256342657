import Icon from 'shared/ui/icon'

import GenericInput from './questionTypeSelector/questions/GenericInput'
import NumberInput from './questionTypeSelector/questions/NumberInput'
import LedSearchInput from './questionTypeSelector/questions/LedSearchInput'
import OptionsListInput from './questionTypeSelector/questions/OptionsListInput'

const questionsTypes = {
  short_text: {
    title: 'Texto curto',
    icon: (props = {}) => (
      <Icon data-testid="icon" {...props} icon="drag-handle" />
    ),
    question: (props = {}) => <GenericInput data-testid="icon" {...props} />,
    type: 'short_text',
    unique: false,
  },
  long_text: {
    title: 'Texto longo',
    icon: (props = {}) => (
      <Icon data-testid="icon" {...props} icon="format-align-left" />
    ),
    question: (props = {}) => <GenericInput data-testid="icon" {...props} />,
    type: 'long_text',
    unique: false,
  },
  number_input: {
    title: 'Números',
    icon: (props = {}) => <Icon data-testid="icon" {...props} icon="looks-3" />,
    question: (props = {}) => <NumberInput data-testid="icon" {...props} />,
    type: 'number_input',
    unique: false,
  },
  date_input: {
    title: 'Data',
    icon: (props = {}) => (
      <Icon data-testid="icon" {...props} icon="calendar-today" />
    ),
    question: (props = {}) => <GenericInput data-testid="icon" {...props} />,
    type: 'date_input',
    unique: false,
  },
  hour_input: {
    title: 'Hora',
    icon: (props = {}) => (
      <Icon data-testid="icon" {...props} icon="schedule" />
    ),
    question: (props = {}) => <GenericInput data-testid="icon" {...props} />,
    type: 'hour_input',
    unique: false,
  },
  led_search: {
    title: 'Consultar liderados',
    icon: (props = {}) => <Icon data-testid="icon" {...props} icon="search" />,
    question: (props = {}) => <LedSearchInput data-testid="icon" {...props} />,
    type: 'led_search',
    unique: true,
  },
  option_list: {
    title: 'Lista de opções',
    icon: (props = {}) => (
      <Icon data-testid="icon" {...props} icon="check-box-outline" />
    ),
    radioBoxIcon: (props = {}) => (
      <Icon data-testid="radioBoxIcon" {...props} icon="radio-button-checked" />
    ),
    question: (props = {}) => (
      <OptionsListInput data-testid="icon" {...props} />
    ),
    type: 'option_list',
    unique: false,
  },
  upload: {
    title: 'Anexo',
    icon: (props = {}) => (
      <Icon data-testid="icon" {...props} icon="create-new-folder-outline" />
    ),
    question: (props = {}) => <GenericInput data-testid="icon" {...props} />,
    type: 'upload',
    unique: false,
  },
}

export default questionsTypes
