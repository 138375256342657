import { Bar } from 'recharts'
import { colors } from 'theme'
import Chart from 'vibe/insights/chart'

type Data = {
  positiveEvaluation: number
  negativeEvaluation: number
  missingEvaluation: number
}

type Props = { data: Data[] }

export default function ConversationFrequencyChart({ data }: Props) {
  return (
    <Chart data={data}>
      <Chart.Header tooltip="Avaliação ascendente dos liderados">
        Avaliação ascendente dos liderados
      </Chart.Header>
      <Chart.Bars
        tooltip={({
          negativeEvaluation,
          positiveEvaluation,
          missingEvaluation,
        }) => (
          <>
            <div style={{ color: colors.green }}>
              Avaliações positivas: {positiveEvaluation}
            </div>
            <div style={{ color: colors.yellow }}>
              Oportunidades de melhoria: {negativeEvaluation}
            </div>
            <div style={{ color: colors.red }}>
              Sem avaliação: {missingEvaluation}
            </div>
          </>
        )}
      >
        <>
          <Bar
            dataKey="missingEvaluation"
            stackId="a"
            shape={Chart.RedStackShape}
          />
          <Bar
            dataKey="negativeEvaluation"
            stackId="a"
            shape={Chart.YellowStackShape}
          />
          <Bar
            dataKey="positiveEvaluation"
            stackId="a"
            shape={Chart.GreenStackShape}
          />
        </>
      </Chart.Bars>
    </Chart>
  )
}
