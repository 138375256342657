import React, { useState } from 'react'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { cx } from 'shared/helpers'

type Props = {
  handleSave?: (value: { [index: string]: string }) => void
  handleChange?: (value: { [index: string]: string }) => void
  name: string
  text?: string
  disabled?: boolean
  label: string
  placeholder?: string
}

export default function TextInput({
  handleSave,
  handleChange: onChange,
  placeholder = '',
  name,
  label,
  text,
  disabled,
}: Props) {
  const [value, setValue] = useState(text || '')

  const handleBlur = () => {
    if (handleSave) {
      handleSave({ [name]: value })
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange({ [name]: e.target.value })

    setValue(e.target.value)
  }

  return (
    <div
      className={cx(
        'flex grow flex-col p-4 text-base',
        disabled
          ? 'pointer-events-none text-gray-dark'
          : 'text-gray-darkest focus-within:border-blue-light',
      )}
    >
      <p className="mb-2 text-base font-bold leading-4">{label}</p>
      <TextareaAutosize
        data-testid={name}
        disabled={disabled}
        name={name}
        onBlur={handleBlur}
        className={cx(
          'w-full resize-none rounded border p-4 focus-within:border-blue-light focus:outline-none',
          disabled
            ? 'pointer-events-none bg-gray-lightest text-gray-dark'
            : 'text-gray-darkest focus-within:border-blue-light',
        )}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  )
}
