import { useState } from 'react'
import Button from 'shared/forms/SubmitButton'
import Icon from 'shared/ui/icon'
import RejectModal from './RejectModal'

const RejectButton = ({ id }: { id: number }) => {
  const [modal, setModal] = useState(false)

  return (
    <>
      <Button
        className="w-full flex-1 sm:w-auto"
        title="reprovar"
        variant="outlined"
        kind="danger"
        type="button"
        onClick={() => setModal(true)}
      >
        <Icon icon="thumbs-down" className="fill-transparent text-blue" />
      </Button>
      <RejectModal open={modal} id={id} onClose={() => setModal(false)} />
    </>
  )
}

export default RejectButton
