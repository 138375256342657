import React from 'react'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useNavigate } from 'react-router-dom'
import type { Team } from 'types'

const useClasses = makeStyles(() => ({
  chipWrapper: {
    display: 'flex',
    '& > h6': {
      flexShrink: 0,
      marginRight: '2rem',
      lineHeight: '1.5rem',
    },
    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '0.6rem',
    },
  },
}))

type Props = {
  list: Team[] | string[]
  label: string
  type: 'roles' | 'teams'
  leader?: boolean
  url?: string
}

export default function TeamChip({ list, url, label, leader, type }: Props) {
  const classes = useClasses()
  const navigate = useNavigate()

  return (
    <div className={classes.chipWrapper}>
      <Typography variant="subtitle1" color="textSecondary">
        {label}
      </Typography>
      <div>
        {type === 'roles'
          ? (list as string[]).map((role: string, index: number) => (
              <Chip
                label={role}
                key={`${role}=${index}`}
                variant="outlined"
                size="small"
              />
            ))
          : (list as Team[]).map(({ teamId, teamName }: Team) => (
              <Chip
                label={teamName}
                key={teamId}
                onClick={() => navigate(`${url}${teamId}`)}
                icon={leader ? <StarsRoundedIcon /> : undefined}
                variant={leader ? 'default' : 'outlined'}
                size="small"
              />
            ))}
      </div>
    </div>
  )
}
