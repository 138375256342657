import React from 'react'
import Button from '@material-ui/core/Button'
import { Fetch } from 'croods'
import { Link } from 'react-router-dom'
import Skeleton from 'shared/ui/skeleton'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import HeaderControls from 'shared/ui/HeaderControls'
import Icon from 'shared/ui/icon'
import { useSearch } from 'shared/utils/hooks'
import Table from './Table'

type Row = { id: number; parentId: number }

const List = () => {
  const { setSearch, select } = useSearch<Row>()

  return (
    <div>
      <h3>Gerenciar Modelos de Solicitação</h3>
      <HeaderControls
        searchLabel="Busca rápida por modelos de solicitações"
        setSearch={setSearch}
      >
        <Button
          component={Link}
          className="mb-2 h-auto"
          to="novo"
          startIcon={<Icon icon="add-circle-outline" className="h-5 w-5" />}
          variant="contained"
          color="primary"
          size="small"
        >
          Criar Modelo
        </Button>
      </HeaderControls>

      <Fetch<Row[]>
        name="solicitationTemplates"
        path="workflow/solicitation_templates/management"
        render={(list, [, { setList }]) => (
          <Table rows={select(list, ({ name }) => name)} setList={setList} />
        )}
        renderLoading={() => <Skeleton height="503px" />}
        renderError={(error) => (
          <FeedbackMessage
            serverMessage={error}
            message="Solicitações não encontradas"
          />
        )}
        renderEmpty={() => (
          <FeedbackMessage
            serverMessage="Crie um modelo"
            type="warning"
            message="Não existem solicitações"
          />
        )}
      />
    </div>
  )
}

export default List
