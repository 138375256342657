import * as React from 'react'
import type { IconType } from 'react-icons/lib'
import * as Icons from 'react-icons/md'
import { FaSpinner, FaRegFileAlt } from 'react-icons/fa'
import { FiThumbsDown, FiThumbsUp } from 'react-icons/fi'

import { cx } from 'shared/helpers'

type IconProps = Omit<IconType, 'size'> & {
  icon: keyof typeof ICON_MAPPING
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full'
  title?: string
  className?: string
  style?: JSX.IntrinsicElements['svg']['style']
  onClick?: React.MouseEventHandler
}

const ICON_MAPPING = {
  'upload-document': Icons.MdInsertDriveFile,
  'upload-pdf': Icons.MdPictureAsPdf,
  upload: Icons.MdAddAPhoto,
  event: Icons.MdEvent,
  close: Icons.MdClose,
  'attach-file': Icons.MdAttachFile,
  'arrow-down': Icons.MdKeyboardArrowDown,
  'arrow-up': Icons.MdKeyboardArrowUp,
  help: Icons.MdHelp,
  'format-line-spacing': Icons.MdFormatLineSpacing,
  'more-horizontal': Icons.MdMoreHoriz,
  'add-circle-outline': Icons.MdAddCircleOutline,
  'drag-handle': Icons.MdDragHandle,
  'format-align-left': Icons.MdFormatAlignLeft,
  'calendar-today': Icons.MdCalendarToday,
  schedule: Icons.MdSchedule,
  search: Icons.MdSearch,
  'radio-button-checked': Icons.MdRadioButtonChecked,
  'looks-3': Icons.MdLooks3,
  'check-box-outline': Icons.MdOutlineCheckBox,
  'create-new-folder-outline': Icons.MdOutlineCreateNewFolder,
  folder: Icons.MdFolder,
  'folder-outlined': Icons.MdOutlineFolderOpen,
  delete: Icons.MdDelete,
  'delete-forever-outline': Icons.MdOutlineDeleteForever,
  'file-download-outline': Icons.MdOutlineFileDownload,
  undo: Icons.MdUndo,
  'expand-more': Icons.MdExpandMore,
  'insert-drive-file': Icons.MdInsertDriveFile,
  'check-circle': Icons.MdCheckCircle,
  'check-box': Icons.MdCheckBox,
  clear: Icons.MdClear,
  'warning-outline': Icons.MdOutlineWarning,
  edit: Icons.MdEdit,
  'group-work': Icons.MdGroupWork,
  adjust: Icons.MdAdjust,
  'arrow-forward': Icons.MdArrowForward,
  history: Icons.MdHistory,
  visibility: Icons.MdOutlineVisibility,
  clock: Icons.MdOutlineWatchLater,
  'check-circle-outline': Icons.MdCheckCircleOutline,
  mood: Icons.MdMood,
  'all-inclusive': Icons.MdOutlineAllInclusive,
  'file-copy-outline': Icons.MdOutlineFileCopy,
  'home-outline': Icons.MdOutlineHome,
  'emoji-events-outline': Icons.MdOutlineEmojiEvents,
  'emoji-objects-outline': Icons.MdOutlineEmojiObjects,
  'supervised-user-circle-outline': Icons.MdOutlineSupervisedUserCircle,
  'supervised-user-circle': Icons.MdSupervisedUserCircle,
  'add-comment-outline': Icons.MdOutlineAddComment,
  'calendar-today-outline': Icons.MdOutlineCalendarToday,
  'chat-outline': Icons.MdOutlineChat,
  'person-outline': Icons.MdPersonOutline,
  'person-add-outline': Icons.MdOutlinePersonAdd,
  'history-outline': Icons.MdOutlineHistory,
  'insert-chart-outlined': Icons.MdInsertChartOutlined,
  'speed-outline': Icons.MdOutlineSpeed,
  'notifications-outline': Icons.MdOutlineNotifications,
  'folder-open-outline': Icons.MdOutlineFolderOpen,
  'assignment-ind-outline': Icons.MdOutlineAssignmentInd,
  cached: Icons.MdCached,
  description: Icons.MdDescription,
  'move-to-inbox-outline': Icons.MdOutlineMoveToInbox,
  'alarm-add': Icons.MdAlarmAdd,
  check: Icons.MdCheck,
  info: Icons.MdInfoOutline,
  spinner: FaSpinner,
  'check-outline': Icons.MdOutlineCheck,
  'play-list-add': Icons.MdPlaylistAdd,
  'mood-bad': Icons.MdMoodBad,
  'add-circle': Icons.MdAddCircle,
  cancel: Icons.MdCancel,
  error: Icons.MdError,
  'error-outline': Icons.MdErrorOutline,
  'file-text': FaRegFileAlt,
  fingerprint: Icons.MdFingerprint,
  mail: Icons.MdOutlineEmail,
  'info-fill': Icons.MdInfo,
  vacations: Icons.MdOutlineBeachAccess,
  'star-rate': Icons.MdStarRate,
  'star-border': Icons.MdStarBorder,
  travel: Icons.MdOutlineCardTravel,
  block: Icons.MdBlock,
  'vertical-align-top': Icons.MdVerticalAlignTop,
  'vertical-align-bottom': Icons.MdVerticalAlignBottom,
  invitation: Icons.MdPersonAddAlt,
  'thumbs-up': FiThumbsUp,
  'thumbs-down': FiThumbsDown,
  'rate-review': Icons.MdOutlineRateReview,
  'assignment-outline': Icons.MdOutlineAssignment,
}

const Icon = ({ icon, size = 'md', className, ...props }: IconProps) => {
  const iconClasses = cx(
    'fill-current',
    size === 'xs' && 'w-3 h-3',
    size === 'sm' && 'w-4 h-4',
    size === 'md' && 'w-6 h-6',
    size === 'lg' && 'w-8 h-8',
    size === 'xl' && 'w-16 h-16',
    size === 'full' && 'w-full h-full',
    className,
  )

  const IconComponent = ICON_MAPPING[icon]

  return <IconComponent aria-label={icon} {...props} className={iconClasses} />
}

export default Icon
export type { IconProps }
