import * as React from 'react'
import { useCroods } from 'croods'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import isEmpty from 'lodash/isEmpty'

type Params = {
  path: string
  callbackSuccess: (data: any) => void
  timeout?: number
}

function useDataRefreshOnInterval({
  path,
  callbackSuccess,
  timeout = 3000,
}: Params) {
  const interval = React.useRef<ReturnType<typeof setInterval> | null>(null)
  const [gptSessionId, setGptSessionId] = React.useState<number | null>(null)
  const { snackbar } = useSnackbar()

  const errorSnackbar = () =>
    snackbar({
      message:
        'Ocorreu um erro na comunicação com a AI. Tente novamente mais tarde',
      type: 'error',
    })

  const resetStates = () => {
    setGptSessionId(null)
    if (interval.current) clearInterval(interval.current)
  }

  const [, { fetch }] = useCroods({
    name: 'gptSession',
    path,
    afterSuccess: ({ data }) => {
      if (data?.completed) {
        if (isEmpty(data?.apiErrors)) {
          const content = JSON.parse(data.content)
          callbackSuccess(content ?? [])
          resetStates()
        } else {
          resetStates()
          errorSnackbar()
        }
      }
    },
    afterFailure: () => {
      resetStates()
      errorSnackbar()
    },
  })

  React.useEffect(() => {
    if (gptSessionId) {
      interval.current = setInterval(() => {
        fetch({ id: gptSessionId })()
      }, timeout)
    } else {
      resetStates()
    }
    return () => {
      if (interval.current) return clearInterval(interval.current)
    }
  }, [gptSessionId]) // eslint-disable-line

  return { gptSessionId, setGptSessionId }
}

export { useDataRefreshOnInterval }
