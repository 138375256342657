import * as React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import { masks } from 'shared/formsv2/masks'
import { getInputParsedValue } from './helpers'
import Icon from 'shared/ui/icon'
import { cx } from 'shared/helpers'

type TimeInputProps = JSX.IntrinsicElements['input'] & {
  error?: string | undefined
  previousFieldParsedValue: Date | undefined
  warning: string | undefined
  masterDate: string
  onRemove: () => void
  onUpdate: (value: {
    value: string
    focus: boolean
    parsedValue?: Date | undefined
  }) => void
}

const TimeInput = React.forwardRef<any, TimeInputProps>(
  (
    {
      error,
      warning,
      onUpdate,
      onChange,
      onRemove,
      disabled,
      masterDate,
      previousFieldParsedValue,
      ...props
    },
    ref,
  ) => {
    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      ev.target.value = masks['time'](ev.target.value)
      const currentParsedValue = getInputParsedValue(
        previousFieldParsedValue,
        ev.target.value,
        masterDate,
      )

      onUpdate({
        value: ev.target.value,
        parsedValue: currentParsedValue,
        focus: Boolean(ev.target.focus),
      })

      onChange && onChange(ev)
    }

    return (
      <div className="flex items-center">
        {error && (
          <Tooltip title={error}>
            <div ref={ref}>
              <Icon aria-label={error} icon="error" className="text-red" />
            </div>
          </Tooltip>
        )}

        {!error && warning && (
          <Tooltip title={warning}>
            <div ref={ref}>
              <Icon aria-label={warning} icon="error" className="text-yellow" />
            </div>
          </Tooltip>
        )}

        <input
          {...props}
          ref={ref}
          placeholder="hh:mm"
          onChange={handleChange}
          disabled={disabled}
          className={cx(
            'input h-9 w-[5.125rem] px-2 text-lg placeholder:text-lg disabled:border-gray-300 disabled:bg-gray-lightest disabled:text-gray-dark',
            error && '!border-2 !border-red-dark !text-red-dark',
            disabled && '!w-[59px]',
          )}
        />
        {!disabled && (
          <Icon
            title="remover campo de horário"
            icon="cancel"
            className="absolute right-9 m-0 h-5 w-5 cursor-pointer p-0 text-gray"
            onClick={onRemove}
          />
        )}
      </div>
    )
  },
)

export default TimeInput
