import { Fetch } from 'croods'
import Boxes from './boxes'
import type { InsightComponentProps } from 'vibe/insights/chart-helpers'
import { chartPath } from 'vibe/insights/chart-helpers'

type Leader = {
  user: {
    firstName: string
    lastName: string
  }
}

export type Team = {
  id: number
  name: string
  denomination: string
  leaders: Leader[]
  evaluation: {
    goalsScore: number
    resultsScore: number
    maturityScore: number
    overallScorePercentage: number
    evaluatedAt: string
    keepDoing: string
    needsToImprove: string
    notes: string
  }
}

export type Box = {
  name: string
  description: string
  goalType: 'unknown' | 'conservative' | 'realistic' | 'stretch'
  reachedGoals: 'none' | 'some' | 'all'
  teams: Team[]
}

export default function TwelveBox({ query, stateId }: InsightComponentProps) {
  return (
    <Fetch
      name="twelveBox"
      path={chartPath('twelve_box', query)}
      stateId={stateId}
      query={query}
      render={({ boxes, startsAt, finishesAt, totalTeams }) => {
        if (!boxes) return null

        return (
          <Boxes
            boxes={boxes}
            totalTeams={totalTeams}
            startsAt={startsAt}
            finishesAt={finishesAt}
          />
        )
      }}
    />
  )
}
