import React from 'react'
import { cx } from 'shared/helpers'
import Label from 'shared/formsv2/label'
import InputHint from 'shared/formsv2/input-hint'

type SelectProps = JSX.IntrinsicElements['select'] & {
  loading?: boolean
  error?: string | undefined
  options: { id: string; description: string }[]
}

const Select = React.forwardRef<any, SelectProps>(
  ({ loading, options, error, ...props }, ref) => (
    <section aria-label="reasons-inputs" className="flex flex-col">
      <Label htmlFor="reasons" aria-required>
        Motivo
      </Label>
      <select
        ref={ref}
        id="reasons"
        className={cx(
          'input',
          error && '!border-2 !border-red-dark !text-red-dark',
        )}
        disabled={loading}
        placeholder="Selecione o motivo"
        {...props}
      >
        {!loading && (
          <>
            <option value="">Selecione o motivo</option>
            {options.map((opt) => (
              <option key={opt.id} value={JSON.stringify(opt)}>
                {opt.description}
              </option>
            ))}
          </>
        )}
        {loading && <option value="">Carregando...</option>}
      </select>
      <InputHint isError id={error} className="mt-1">
        {error}
      </InputHint>
    </section>
  ),
)

export default Select
