import { useNavigate } from 'react-router-dom'
import Avatar from '../Avatar'

type Props = {
  id?: string
  badge?: string
  image?: string
  name: string
  occupation: string
  avatarSize?: number
  hasCredential: boolean
  recentlyAdded: boolean
}

const MemberInfo = ({
  id,
  badge,
  image,
  name,
  occupation,
  avatarSize,
  hasCredential,
  recentlyAdded,
}: Props) => {
  const navigate = useNavigate()

  return (
    <div
      className="flex w-max cursor-pointer items-center break-words"
      onClick={() => navigate(`/colaboradores/${id}`)}
      role="button"
    >
      <div className="mr-4">
        <Avatar
          user={{
            firstName: name,
            avatar: image,
            email: '',
            hasCredential,
            recentlyAdded,
          }}
          size={avatarSize}
        />
      </div>

      <div className="flex max-w-[200px] flex-col justify-center">
        <h5 className="subtitle2 text-base truncate">{name}</h5>
        <p className="body2 text-base text-black/50 truncate">
          {occupation} {badge && <span>[{badge}]</span>}
        </p>
      </div>
    </div>
  )
}

export default MemberInfo
