import React from 'react'

type Props = {
  children: React.ReactNode
  title: React.ReactNode
  action?: React.ReactNode
}
const Section = ({ title, action, children }: Props) => (
  <section className="flex grow flex-col p-0">
    {title && (
      <div className="mb-4 flex w-full items-center justify-between text-left">
        <div className="flex gap-4">
          <h3 className="flex items-baseline gap-2 first-letter:uppercase">
            {title}
          </h3>
        </div>
        {action}
      </div>
    )}
    {children}
  </section>
)

export default Section
