import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { cx } from 'shared/helpers'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  submitting?: React.ReactNode
}
function SubmitButton({ children, submitting = children, ...props }: Props) {
  const { formState } = useFormContext()
  return (
    <button
      disabled={formState.isSubmitting}
      type="submit"
      {...props}
      className={cx('disabled:opacity-40', props.className)}
    >
      {formState.isSubmitting ? submitting : children}
    </button>
  )
}

export default SubmitButton
