import get from 'lodash/get'
import map from 'lodash/map'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'theme'

const useStyles = makeStyles((theme) => ({
  body: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.blue,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.blueDark,
    },
    '& label': {
      fontFamily: 'Nunito',
      fontSize: theme.typography?.h5?.fontSize,
      fontWeight: theme.typography?.h5?.fontWeight,
      lineHeight: theme.typography?.h5?.lineHeight,
      position: 'relative',
      transition: 'none',
      transform: 'translate(0, 0) scale(1) !important',
      padding: 0,
    },
    '& input': {
      padding: '12px',
      backgroundPosition: '99% center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto',
    },
    '& div:focus': {
      backgroundColor: 'transparent',
    },
    '& div:hover': {
      borderColos: colors.blueDark,
    },
    '& fieldset': {
      top: '-4px !important',
    },
  },
}))

const SimpleSelect = ({
  label,
  name,
  helper,
  options,
  error,
  multiple = false,
  onChange,
  variant,
  fullWidth = true,
  ...props
}: any) => {
  const reStyle = useStyles()

  const handleChange: React.ChangeEventHandler = multiple
    ? (e) =>
        onChange({
          target: {
            options: map(get(e, 'target.value'), (value) => ({
              value,
              selected: true,
            })),
          },
        })
    : onChange

  return (
    <FormControl
      variant={variant}
      error={!!error}
      className={`${reStyle.body} min-w-[120px]`}
      fullWidth={fullWidth}
    >
      {label && (
        <InputLabel className="mb-2" htmlFor={`${name}-helper`}>
          {label}
        </InputLabel>
      )}
      <Select
        {...props}
        multiple={multiple}
        onChange={handleChange}
        title="Lista de opções"
        MenuProps={{
          MenuListProps: {
            style: { padding: 0 },
          },
        }}
      >
        {map(options, ({ label: opLabel, value: opValue }, index) => (
          <MenuItem
            key={index}
            value={opValue}
            style={{ boxSizing: 'border-box' }}
          >
            {opLabel}
          </MenuItem>
        ))}
      </Select>
      {(error || helper) && <FormHelperText>{error || helper}</FormHelperText>}
    </FormControl>
  )
}

export default SimpleSelect
