import React from 'react'
import Actions from 'shared/ui/form-step/actions'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import Select from './Select'
import { getDefaultTimeInputs, validationSchema } from './helpers'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { useCroods } from 'croods'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import type { FormProps, ReasonsProps } from './types'
import { zodResolver } from '@hookform/resolvers/zod'
import TimeInputSection from './TimeInputSection'

export type Props = {
  defaultTimeInputsValue?: Array<string>
  id: string
  masterDate: string
  clockId: number
}

const Form = ({ defaultTimeInputsValue, id, masterDate, clockId }: Props) => {
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()
  const [{ list: reasons, fetchingList: loading }] = useCroods<ReasonsProps>({
    name: 'clockingSolicitationForm',
    path: '/workflow/adjustment-reasons',
    fetchOnMount: true,
    cache: true,
  })
  const [{ saveError }, { save }] = useCroods({
    name: 'clockingSolicitationForm',
    stateId: id,
    customPath: '/workflow/masters/:id/create_solicitation',
    afterSuccess: () => {
      snackbar({ message: 'Solicitação criada com sucesso.', type: 'success' })
      navigate(`/portal/acerto-do-ponto/${clockId}/acertos`)
    },
  })
  const {
    control,
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      timeInputs: getDefaultTimeInputs(defaultTimeInputsValue),
    },
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const onSubmit = React.useCallback(
    (data: Record<string, any>) => {
      const { timeInputs, reason, observation } = data

      return save({ id, method: 'PUT' })({
        timeInputs,
        observation,
        reasonId: String(reason.id),
        reasonText: reason.description,
      })
    },
    [save, id],
  )

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      <TimeInputSection
        control={control}
        errors={errors}
        masterDate={masterDate}
        trigger={trigger}
        register={register}
      />
      <Select
        loading={loading}
        options={reasons}
        error={errors.reason?.message}
        {...register('reason')}
      />
      <div className="flex flex-col">
        <input
          aria-label="observacao"
          {...register('observation')}
          placeholder="observação"
          className="input"
        />
      </div>
      {saveError && (
        <div>
          <FeedbackMessage
            message="Houve um erro ao processar a solicitação"
            serverMessage={saveError}
          />
        </div>
      )}
      <Actions backButtonLabel="Voltar" nextButtonLabel="Enviar" />
    </form>
  )
}

export default Form
