import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export default function canBePublished(solicitationTemplate: any) {
  const missingName = !get(solicitationTemplate, 'name')
  const missingApplicants = isEmpty(
    get(solicitationTemplate, 'applicantsAttributes'),
  )
  const missingPerformers = isEmpty(
    get(solicitationTemplate, 'performersAttributes'),
  )

  const missingInformation = []
  missingName && missingInformation.push('Nome')
  missingApplicants && missingInformation.push('Solicitantes')
  missingPerformers && missingInformation.push('Executores')

  if (isEmpty(missingInformation)) return undefined

  return `Para publicar é preciso preencher: ${missingInformation
    .join(', ')
    .replace(/(.*),\s/, '$1 e ')}`
}
