import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'

export default function RadioButtonsGroup({
  options,
  label,
  setValue,
  defaultValue,
  value,
  style = {},
  error,
  helper = ' ',
  groupStyle = {
    direction: 'row',
    justifyContent: 'space-between',
  },
}: any) {
  const classes = useStyles()

  const helperText = error || helper

  return (
    <FormControl component="fieldset" style={style} error={!!error}>
      <FormLabel component="legend" className="!text-base !text-gray-darkest">
        {label}
      </FormLabel>
      <RadioGroup
        aria-label={label}
        name={label}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange(setValue)}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          ...groupStyle,
        }}
      >
        {options.map((option: any) => (
          <FormControlLabel
            key={option.value}
            {...option}
            classes={{
              root: classes.root,
              label: classes.label,
            }}
            control={
              <Radio
                className="text-gray-darkest"
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
          />
        ))}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    marginRight: 0,
  },
  label: {
    width: '100%',
    marginRight: 0,
  },
  radio: {
    '&$checked': {
      color: '#4B8DF8',
    },
  },
  checked: {
    '&$checked': {
      color: '#5F91CC',
    },
  },
}))

export const handleChange: (
  set: (value: any) => void,
) => React.ChangeEventHandler<{ value: any }> =
  (set) =>
  ({ target: { value } }) =>
    set(value)
