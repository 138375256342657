import ModalContext from './Context'
import type { FormProps } from 'shared/formsv2/form'
import Form from 'shared/formsv2/form'
import type { SubmitHandler } from 'react-hook-form'
import OverlayWrapper from './OverlayWrapper'
import { cx } from 'shared/helpers'

export type Props = Omit<FormProps, 'onSubmit'> & {
  enabled?: boolean
  loading?: boolean
  onClose: () => void
  onConfirm?: FormProps['onSubmit']
  preventClose?: boolean
  open?: boolean
}
const FormWrapper = ({
  onClose,
  children,
  loading = false,
  enabled = true,
  open = true,
  onConfirm,
  preventClose = false,
  className,
  ...props
}: Props) => {
  const disabled = loading || !enabled
  const handleConfirm = async (
    data: SubmitHandler<any>['arguments'][0],
    event?: React.FormEvent<HTMLFormElement>,
  ) => {
    if (enabled) {
      if (onConfirm) {
        await onConfirm(data, event)
      }
      preventClose || onClose()
    }
    return true
  }

  const value = { loading, onClose, disabled, handleConfirm, isForm: true }
  return (
    <ModalContext.Provider value={value}>
      <OverlayWrapper show={open} onClose={onClose}>
        <Form
          {...props}
          onSubmit={handleConfirm}
          className={cx(
            className,
            'relative mx-auto w-full min-w-[400px] rounded-lg bg-white shadow-lg md:w-max md:max-w-5xl',
          )}
        >
          {children}
        </Form>
      </OverlayWrapper>
    </ModalContext.Provider>
  )
}

export default FormWrapper
