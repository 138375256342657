import * as React from 'react'
import { cx } from 'shared/helpers'
import Icon from 'shared/ui/icon'

type Props = {
  children: React.ReactNode
  type?: 'success' | 'error'
}

const EmptyMessageFeedback = ({ children, type = 'success' }: Props) => {
  const isSuccess = type === 'success'
  return (
    <section
      role="status"
      title="feedback"
      className="flex h-80 flex-col items-center justify-center gap-4 bg-white p-4"
    >
      <Icon
        title="icone de feedback"
        className={cx(isSuccess ? 'text-green-500' : 'text-red-500')}
        icon={isSuccess ? 'mood' : 'mood-bad'}
      />

      <span
        role="alert"
        className="text-center text-lg font-bold text-gray-700"
      >
        {children}
      </span>
    </section>
  )
}

export default EmptyMessageFeedback
