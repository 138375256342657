import type { StepProps as StepperStepProps } from 'shared/ui/stepper/Steps'
import React from 'react'
import { cx } from 'shared/helpers'

type StepProps = {
  marker: string
  children: React.ReactNode
}

export default function Step({
  marker,
  children,
  active,
  passed,
}: StepProps & StepperStepProps) {
  return (
    <div
      className={cx(
        'flex flex-col items-center justify-start text-center text-base',
        active ? 'font-bold' : 'text-gray-light',
        passed && 'font-bold',
      )}
    >
      <div
        className={cx(
          'flex h-8 w-8 items-center justify-center rounded-full',
          active ? 'bg-purple text-white' : 'bg-gray-50',
          passed && 'bg-purple-light text-white',
        )}
      >
        {marker}
      </div>
      <div className="stepper-label mt-4">{children}</div>
    </div>
  )
}

export type { StepProps }
