import * as React from 'react'
import { getFullName } from 'shared/helpers'
import { useNavigate } from 'react-router-dom'
import Avatar from 'shared/ui/Avatar'

import Table from 'shared/ui/table'
import type { ActionCaller } from 'settings/users/index'
import type { User } from 'types'

import NotInterestedIcon from '@material-ui/icons/NotInterested'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { cx } from 'shared/helpers'
import CircleChips from './circle-chips'
import Dropdown from 'shared/ui/dropdown'
import { TeamInvitationModal } from 'vibe/employee'
import InviteModal from 'vibe/invite-modal'

import type { Action } from 'settings/users/index'
import type { Actions } from 'croods'

type IProps = { check: boolean; active: boolean }
const Icon: React.FC<IProps> = ({ check, active }) => {
  const IconComponent = check ? CheckCircleIcon : NotInterestedIcon
  return (
    <IconComponent
      data-testid={check ? 'check-icon' : 'uncheck-icon'}
      className={cx(
        '!text-2xl',
        check && active && 'text-green-light',
        !check && active && 'text-red-dark',
      )}
    />
  )
}

const classNameTd =
  'flex items-center justify-between text-left md:table-cell py-2 md:py-3 before:block before:font-bold before:content-[attr(data-title)] md:before:content-none'

type Props = {
  actOnUser: ActionCaller
  rows: User[]
  fetch: Actions<User>['fetch']
  disableInvite: boolean
}
const CollaboratorTable: React.FC<Props> = ({
  actOnUser,
  rows,
  fetch,
  disableInvite,
}) => {
  const navigate = useNavigate()

  const [showTeamInvitation, setShowTeamInvitation] =
    React.useState<boolean>(false)

  const [currentEmployee, setCurrentEmployee] = React.useState<User>()

  const [showInviteToVibe, setShowInviteToVibe] = React.useState<boolean>(false)

  return (
    <div className="max-w-full">
      <Table id="collaborator-table">
        <colgroup>
          <col span={0} width="35%" />
        </colgroup>
        <Table.Thead>
          <Table.Th>Nome</Table.Th>
          <Table.Th>Cargo</Table.Th>
          <Table.Th className="w-44 text-center">Círculos</Table.Th>
          <Table.Th className="w-[6rem] text-center">Admin</Table.Th>
          <Table.Th className="w-[6rem] text-center">Visão total</Table.Th>
          <Table.Th className="w-14"></Table.Th>
        </Table.Thead>
        <Table.Tbody>
          {rows.map((user) => {
            const { active, dataAnalyst, admin, teamMembers, teamLeaders } =
              user
            const makeAction = (action: Action) => () =>
              actOnUser({ user, action })
            const isTeamMember = teamMembers ? teamMembers?.length > 0 : false

            const canBeInvited = Boolean(!user.hasCredential && user.email)

            return (
              <Table.Tr
                key={user.id}
                className="cursor-pointer !py-6 transition even:bg-gray-50 hover:bg-gray-lightest"
                onClick={() => navigate(`/colaboradores/${user.id}`)}
              >
                <Table.Td data-title="Nome" className={classNameTd}>
                  <div className="flex flex-row-reverse items-center gap-3 overflow-hidden md:flex-row md:items-start md:justify-start">
                    <Avatar user={user} size={30} />
                    <p className="ml-4 flex-1 self-center overflow-hidden text-ellipsis whitespace-nowrap text-left text-base font-semibold">
                      {getFullName(user)}
                    </p>
                  </div>
                </Table.Td>
                <Table.Td data-title="Cargo" className={classNameTd}>
                  <p
                    className="overflow-hidden text-ellipsis text-base font-semibold"
                    title={user.role}
                  >
                    {user.role}
                  </p>
                </Table.Td>
                <Table.Td
                  className={cx(classNameTd, 'text-center')}
                  data-title="Círculos"
                >
                  {isTeamMember ? (
                    <CircleChips
                      teamMembers={teamMembers}
                      teamLeaders={teamLeaders}
                    />
                  ) : (
                    <button
                      className="min-w-fit justify-center whitespace-nowrap rounded-2xl border border-blue p-1 px-3 text-center text-blue disabled:border-0 disabled:bg-gray-light disabled:text-gray-dark"
                      type="button"
                      disabled={disableInvite}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                      ) => {
                        event.stopPropagation()
                        setCurrentEmployee(user)
                        setShowTeamInvitation(true)
                      }}
                    >
                      Adicionar a um círculo
                    </button>
                  )}
                </Table.Td>

                {canBeInvited || !Boolean(user.email) ? (
                  <Table.Td
                    className={cx(classNameTd, 'text-center')}
                    colSpan={2}
                    data-title="Vibe"
                  >
                    <button
                      className="min-w-fit justify-center whitespace-nowrap rounded-2xl bg-blue p-1 px-3 text-center text-white disabled:bg-gray-light disabled:text-gray-dark"
                      type="button"
                      disabled={disableInvite || !Boolean(user.email)}
                      title={Boolean(user.email) ? '' : 'Sem e-mail cadastrado'}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                      ) => {
                        event.stopPropagation()
                        setCurrentEmployee(user)
                        setShowInviteToVibe(true)
                      }}
                    >
                      Convidar para o Vibe
                    </button>
                  </Table.Td>
                ) : (
                  <>
                    <Table.Td
                      className={cx(classNameTd, 'text-center')}
                      data-title="Admin"
                    >
                      <Icon active={active} check={admin} />
                    </Table.Td>
                    <Table.Td
                      className={cx(classNameTd, 'text-center')}
                      data-title="Visão total"
                    >
                      <Icon active={active} check={dataAnalyst} />
                    </Table.Td>
                  </>
                )}
                <Table.Td
                  className={cx(classNameTd, 'text-center')}
                  data-title=""
                >
                  <Dropdown>
                    {active ? (
                      <>
                        <Dropdown.Menu
                          disabled={!user.hasCredential}
                          onClick={makeAction('EDIT')}
                        >
                          Editar
                        </Dropdown.Menu>
                        <Dropdown.Menu
                          title={
                            user.teamLeaders?.length
                              ? 'Líderes não podem ser inativados.'
                              : 'Inativar usuário'
                          }
                          disabled={!!user.teamLeaders?.length}
                          onClick={makeAction('DEACTIVATE')}
                        >
                          Inativar
                        </Dropdown.Menu>
                      </>
                    ) : (
                      <Dropdown.Menu onClick={makeAction('REACTIVATE')}>
                        Reativar
                      </Dropdown.Menu>
                    )}
                    <Dropdown.Menu
                      disabled={
                        Boolean(user.invitationAcceptedAt) ||
                        !user.hasCredential ||
                        !user.active
                      }
                      onClick={makeAction('RESENDINVITE')}
                    >
                      Reenviar convite
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Td>
              </Table.Tr>
            )
          })}
        </Table.Tbody>
      </Table>

      {currentEmployee && (
        <TeamInvitationModal
          open={showTeamInvitation}
          employee={currentEmployee}
          onClose={() => setShowTeamInvitation(false)}
          afterSuccess={() => {
            fetch()()
          }}
        />
      )}

      {currentEmployee && showInviteToVibe && (
        <InviteModal
          onClose={() => setShowInviteToVibe(false)}
          employee={currentEmployee}
          setShowInvite={setShowInviteToVibe}
          afterSuccess={() => {
            fetch()()
          }}
        />
      )}
    </div>
  )
}

export default CollaboratorTable
