import * as z from 'zod'
import { DATE_REGEX, ISO_DATE_REGEX } from './formsv2/regex'

const notFutureDateSchema = z
  .string()
  .nonempty({ message: 'Campo obrigatório' })
  .regex(DATE_REGEX, {
    message: 'Data inválida. Verifique se sua data está correta.',
  })
  .transform(dateStringBrToISO)
  .refine((admissionDate) => {
    const today = new Date()
    return new Date(admissionDate) <= today
  }, 'Não pode ser uma data no futuro.')

function dateStringBrToISO(val: string) {
  const result = z.string().nonempty().regex(DATE_REGEX).safeParse(val)
  if (result.success) {
    const parsed = result.data
    const [day, month, year] = parsed.split('/')
    return `${year}-${month}-${day}`
  } else {
    return ''
  }
}

function dateStringISOToBr(val: string) {
  const result = z.string().nonempty().regex(ISO_DATE_REGEX).safeParse(val)
  if (result.success) {
    const parsed = result.data
    const [year, month, day] = parsed.split('-')
    return `${day}/${month}/${year}`
  } else {
    return ''
  }
}

export { notFutureDateSchema, dateStringBrToISO, dateStringISOToBr }
