import React from 'react'
import StepIcon from '@material-ui/core/StepIcon'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import { alpha, withStyles } from '@material-ui/core/styles'
import { useIsMobile } from 'shared/utils/hooks'
import { colors } from 'theme'

const DarkPrimaryStepIcon = withStyles((theme) => ({
  root: {
    color: alpha(colors.blue, 0.08),
  },
  text: {
    fill: colors.textDisabled,
  },
  active: {
    color: `${theme.palette.primary.dark} !important`,
  },
}))(StepIcon)

type Props = {
  steps: string[]
  activeStep: number
  handleNavigate: (index: number) => React.MouseEventHandler<HTMLDivElement>
}

export default function HorizontalLabelPositionBelowStepper({
  steps,
  activeStep,
  handleNavigate,
}: Props) {
  const isMobile = useIsMobile()

  return (
    <div className="w-full">
      <Stepper
        className="p-2"
        nonLinear
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((label: any, index: number) => {
          const clickableProps =
            index < activeStep
              ? {
                onClick: handleNavigate(index),
                style: { cursor: 'pointer' },
              }
              : {}

          return (
            <Step key={label} {...clickableProps}>
              <StepLabel StepIconComponent={DarkPrimaryStepIcon}>
                {isMobile ? (
                  ''
                ) : (
                  <span className="stepper-label">
                    {label}
                  </span>
                )}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
}
