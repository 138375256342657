type Props = {
  title: string
  percentage: number | undefined
  className?: string
}

export default function PercentageCircle({
  title,
  percentage,
  className = '',
}: Props) {
  return (
    <div
      title={title}
      className={`${className} subtitle2 h-10 w-[53px] rounded-r-3xl rounded-l py-[10px] px-3 leading-5 text-white`}
    >
      {percentage && percentage}%
    </div>
  )
}
