import { Evaluator } from '@seasonedsoftware/ui'
import { useCroods } from 'croods'
import StarRounded from '@material-ui/icons/StarRounded'
import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded'
import type { Skill } from '../../shared/careerSkills/types'
import parseISO from 'date-fns/parseISO'
import addDays from 'date-fns/addDays'
import isAfter from 'date-fns/isAfter'

type Props = {
  teamMemberId: string
}

export default function SelfEvaluationCommitment({ teamMemberId }: Props) {
  const [{ list }] = useCroods<Skill>({
    name: 'carrerSkills',
    path: `/team_members/${teamMemberId}/career_skills`,
    fetchOnMount: true,
    parseResponse: ({ data }) =>
      data.map((skill: any) => ({
        ...skill,
        createdAt: skill.createdAt && parseISO(skill.createdAt),
        archivedAt: skill.archivedAt && parseISO(skill.archivedAt),
        pausedAt: skill.pausedAt && parseISO(skill.pausedAt),
        completedAt: skill.completedAt && parseISO(skill.completedAt),
      })),
  })

  const skills = list?.filter(
    (skill) =>
      !skill.archivedAt &&
      skill.developmentCommitment &&
      (!skill.completedAt ||
        isAfter(addDays(skill.completedAt!, 16), new Date())) &&
      !skill.pausedAt,
  )

  return skills?.length > 0 ? (
    <>
      <p className="mb-2 text-base font-bold">Avaliações por competência</p>
      <div className="flex gap-4">
        {skills.map((skill) => (
          <div key={skill.id} className="rounded-lg bg-gray-lightest p-3">
            <p className="text-base font-bold text-gray-dark">{skill.title}</p>
            <div className="small-stars pointer-events-none">
              <Evaluator
                subjects={[`developmentCommitment-${skill.id}`]}
                value={skill.developmentCommitment}
                emptyIcon={<StarOutlineRoundedIcon />}
                icon={<StarRounded />}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  ) : null
}
