import React from 'react'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import { cx } from 'shared/helpers'

import Actions from './actions'

type Props = {
  submitting?: boolean
  disableButton?: boolean
  error?: string | null
  nextButtonLabel?: string
  backButtonLabel?: string
  visible?: boolean
  handleSubmit: React.FormEventHandler
  handleNavigate: () => Promise<void>
}
const FormStep: React.FC<Props> = ({
  submitting = false,
  disableButton,
  error,
  children,
  handleSubmit,
  nextButtonLabel,
  backButtonLabel,
  visible = false,
  handleNavigate,
}) => (
  <section className={cx('card pt-4 pr-0 pb-0 pl-0', visible || 'hidden')}>
    <form onSubmit={handleSubmit}>
      <section className="flex grow flex-col justify-between gap-6 px-2 md:px-8 py-4">
        {children}
        {error && (
          <section className="flex grow">
            <FeedbackMessage
              message="Houve um erro ao processar a solicitação"
              serverMessage={error}
            />
          </section>
        )}
      </section>
      <Actions
        loading={submitting}
        disabled={disableButton}
        nextButtonLabel={nextButtonLabel}
        backButtonLabel={backButtonLabel}
        onBack={handleNavigate}
      />
    </form>
  </section>
)

export default FormStep
