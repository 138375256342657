import React from 'react'
import useEvaluationColors from 'shared/utils/useEvaluationColors'

type Props = {
  title: string
  description?: string
  score?: number
  children?: React.ReactNode
  type?: 'ambitions' | 'results' | 'maturity' | 'performance'
}

const TYPES = {
  ambitions: 'growthPath',
  results: 'teamResultsScore',
  maturity: 'maturityScore',
  performance: 'teamPercentage',
}

const EvaluationCard = ({
  title,
  description,
  score,
  children,
  type,
}: Props) => {
  const colorType = type ? TYPES[type] : undefined
  const colorTypeParams = colorType
    ? ([colorType, score] as [string, number])
    : null

  const titleColorParams =
    title === 'Resultados'
      ? (['teamResultsScore', score] as [string, number])
      : score

  const colorParams = colorTypeParams ? colorTypeParams : titleColorParams

  const { scoreColors, backgroundColors } = useEvaluationColors(colorParams)

  return (
    <div className="flex flex-col text-center">
      <h6 className="text-gray-dark">{title}</h6>
      {children || (
        <h5
          className="mt-2 mb-4 md:mb-0 md:mt-6 flex h-14 md:h-28 items-center justify-center rounded-2xl p-4 text-center"
          style={{ backgroundColor: backgroundColors, color: scoreColors }}
        >
          {description}
        </h5>
      )}
    </div>
  )
}

export default EvaluationCard
