import { typeToPath } from 'shared/utils/conversationLookup'
import { useNavigate } from 'react-router-dom'
import Body from './Body'
import Footer from './Footer'
import Header from './Header'

import type { UserAvatar } from 'types'

type User = UserAvatar & {
  role: string
}

type TeamMember = {
  id: number
  user: User
  teamName: string
}

type Props = {
  conversation: {
    conversationType: string
    date: string
    teamMember: TeamMember
  }
}

const NextConversationCard = ({ conversation }: Props) => {
  const navigate = useNavigate()
  const { conversationType, date, teamMember } = conversation

  return (
    <button
      type="button"
      className="md:min-w-[180px] inline-block md:basis-0 overflow-hidden cursor-pointer rounded-2xl border text-left transition hover:bg-gray/10"
      onClick={() =>
        navigate(
          `/conversas/nova-conversa/${typeToPath(conversationType)}/${
            teamMember.id
          }`,
        )
      }
    >
      <Header teamMember={teamMember} />
      <Body conversationType={conversationType} />
      <Footer date={date} />
    </button>
  )
}

export default NextConversationCard
export type { User, TeamMember }
