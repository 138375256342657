import AvatarList from 'shared/ui/AvatarList'
import useOrganization from 'shared/organization/useOrganization'
import Period from 'shared/ui/Period'
import Notice from './Notice'
import StatusChip from 'vibe/circle/status-chip'
import type { Team } from 'types'
import type { MemberEvaluation } from '../types'

const TeamNoticeCard = ({
  allTeams,
  isDraft = false,
}: {
  allTeams: (Team & { memberEvaluations: MemberEvaluation[] })[]
  isDraft?: boolean
}) => {
  const {
    evaluationWindowOpensAt,
    evaluationWindowClosesAt,
    evaluationWindowStatus,
  } = useOrganization()

  return (
    <div className="card rounded-2xl">
      <div className="header-card">
        <div className="flex items-center gap-4">
          <h4>Avaliar Talentos</h4>
          {isDraft && <StatusChip label="rascunho" />}
        </div>
      </div>

      <div className="p-6">
        <div className="flex flex-col gap-6">
          {evaluationWindowStatus !== 'closed' && (
            <Period
              title="Período para avaliar"
              startsAt={evaluationWindowOpensAt}
              finishesAt={evaluationWindowClosesAt}
              longFormat
            />
          )}
          <div className="flex flex-col gap-6">
            {allTeams.map((team, index) => (
              <AvatarList key={index} team={team} memberType="members" />
            ))}
          </div>
          <Notice />
        </div>
      </div>
    </div>
  )
}

export default TeamNoticeCard
