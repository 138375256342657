import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Icon from 'shared/ui/icon'

const VacationActions = ({
  fullName,
  vacationStartDate,
}: {
  fullName: string
  vacationStartDate: string
}) => {
  const navigate = useNavigate()

  return (
    <section className="flex flex-col justify-end gap-y-4 sm:flex-row">
      <button
        className="bt bt-outlined flex justify-center items-center gap-2 px-3"
        onClick={() =>
          navigate(`/portal/gestao-de-ferias/${fullName}/${vacationStartDate}`)
        }
      >
        <Icon icon="visibility" size="md" />
        <span>Ver em gestão de férias</span>
      </button>
    </section>
  )
}

export { VacationActions }
