import React from 'react'
import Radio from 'shared/forms/Radio'
import type { FormState } from '../types'
import useDescriptionTexts from './useDescriptionTexts'
import { colors } from 'theme'
import type { TeamEvaluations } from '../NewEvaluation'

type Props = {
  handleChange: (title: keyof FormState, value: number) => void
  teamEvaluation?: TeamEvaluations
}

export default function Radios({ handleChange, teamEvaluation }: Props) {
  const { goalsScore, resultsScore, maturityScore } = useDescriptionTexts()

  return (
    <div className="flex flex-col gap-y-8">
      <Radio
        title="Ambição das metas"
        afterChange={(newValue) => handleChange('goalsScore', Number(newValue))}
        previousValue={teamEvaluation?.goalsScore?.toString()}
        options={[
          {
            label: goalsScore.data[0].label,
            value: '1',
            color: colors.redInput,
            description: goalsScore.data[0].adminText,
          },
          {
            label: goalsScore.data[1].label,
            value: '2',
            color: colors.yellowInput,
            description: goalsScore.data[1].adminText,
          },
          {
            label: goalsScore.data[2].label,
            value: '3',
            color: colors.greenInput,
            description: goalsScore.data[2].adminText,
          },
          {
            label: goalsScore.data[3].label,
            value: '4',
            color: colors.greenDarkestInput,
            description: goalsScore.data[3].adminText,
          },
        ]}
      />

      <Radio
        title="Resultados"
        afterChange={(newValue) =>
          handleChange('resultsScore', Number(newValue))
        }
        previousValue={teamEvaluation?.resultsScore?.toString()}
        options={[
          {
            label: resultsScore.data[0].label,
            value: '1',
            color: colors.redInput,
            description: resultsScore.data[0].adminText,
          },
          {
            label: resultsScore.data[1].label,
            value: '2',
            color: colors.yellowInput,
            description: resultsScore.data[1].adminText,
          },
          {
            label: resultsScore.data[2].label,
            value: '3',
            color: colors.greenInput,
            description: resultsScore.data[2].adminText,
          },
        ]}
      />

      <Radio
        title="Maturidade"
        afterChange={(newValue) =>
          handleChange('maturityScore', Number(newValue))
        }
        previousValue={teamEvaluation?.maturityScore?.toString()}
        options={[
          {
            label: maturityScore.data[0].label,
            value: '1',
            color: colors.redInput,
            description: maturityScore.data[0].adminText,
          },
          {
            label: maturityScore.data[1].label,
            value: '2',
            color: colors.yellowInput,
            description: maturityScore.data[1].adminText,
          },
          {
            label: maturityScore.data[2].label,
            value: '3',
            color: colors.greenInput,
            description: maturityScore.data[2].adminText,
          },
          {
            label: maturityScore.data[3].label,
            value: '4',
            color: colors.greenDarkestInput,
            description: maturityScore.data[3].adminText,
          },
        ]}
      />
    </div>
  )
}
