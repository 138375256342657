import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

export const colors = {
  green: 'rgb(91, 202, 138)',
  greenInput: 'rgba(91, 202, 138, .3)',
  greenDarkestInput: 'rgba(36, 81, 55, .5)',
  greenLightInput: 'rgba(91, 202, 138, .1)',
  greenDarkestLightInput: 'rgba(36, 81, 55, .2)',
  greenDark: 'rgb(69,153,105,1)',
  greenDarkest: 'rgba(36, 81, 55, 1)',
  greenLight: 'rgb(115,229,163)',
  greenLightBg: 'rgba(91,202,138, .08)',
  greenLightest: 'rgba(239, 250, 243, 1)',
  greenDark30: 'rgba(69, 153, 105, .3)',

  // color gradation
  green40: 'rgb(91, 202, 138, .4)',
  green60: 'rgb(91, 202, 138, .6)',

  blue: '#5F91CC',
  blueDark: '#28649B',
  blueLight: 'rgb(139,175,218)',
  blueLightBg: 'rgba(106, 151, 206, .08)',
  blueInput: 'rgba(95,145,204,.5)',
  // color gradation
  blue20: 'rgba(106, 151, 206, .2)',
  blue80: 'rgba(106, 151, 206, .8)',

  yellow: 'rgb(240, 184, 88)',
  yellowInput: 'rgba(240, 184, 88,.3)',
  yellowLightInput: 'rgba(240, 184, 88,.1)',
  yellowLight: 'rgb(240,196,120,1)',
  yellowLightBg: 'rgba(240,184,88,.08)',
  yellowLightest: 'rgba(254, 248, 238, 1)',
  yellowDark: 'rgba(186, 136, 41, 1)',
  // color gradation
  yellow40: 'rgb(240, 184, 88, .4)',
  yellow60: 'rgb(240, 184, 88, .6)',

  red: 'rgb(214,57,76)',
  redInput: 'rgba(214,57,76,.3)',
  redLightInput: 'rgba(214,57,76,.1)',
  redDark: 'rgb(178,46,62)',
  redLight: 'rgb(229,92,108)',
  redLightBg: 'rgba(255, 79, 76,.08)',
  redLightest: 'rgba(251, 235, 237, 1)',
  // color gradation
  red40: 'rgba(214, 57, 76, .4)',
  red60: 'rgba(214, 57, 76, .6)',

  purpleLight: 'rgb(196,200,239)',
  purpleInput: 'rgba(148,153,212,.5)',

  crimson: 'rgba(134, 28, 28, 1)',
  tangerine: 'rgba(255, 79, 79, 1)',
  crimson10: 'rgba(134, 28, 28, .1)',
  tangerine10: 'rgba(255, 79, 79, .1)',

  // black & grey gradation
  grey: 'rgb(192, 192, 192)',
  greyDark: 'rgb(150, 150, 150);',
  grey10: 'rgba(192, 192, 192, 0.10)',
  grey23: 'rgba(192, 192, 192, 0.23)',
  grey40: 'rgba(192, 192, 192, 0.40)',
  grey54: 'rgba(192, 192, 192, 0.54)',
  grey75: 'rgba(192, 192, 192, 0.75)',
  black: 'rgb(0, 0, 0)',
  black02: 'rgba(0, 0, 0, .02)',
  black06: 'rgba(0, 0, 0, .06)',
  black12: 'rgba(0, 0, 0, .12)',
  black14: 'rgba(0, 0, 0, .14)',
  black23: 'rgba(0, 0, 0, .23)',
  black40: 'rgba(0, 0, 0, .40)',
  black54: 'rgba(0, 0, 0, .54)',
  black75: 'rgba(0, 0, 0, .75)',
  black87: 'rgba(0, 0, 0, .87)',

  // text:
  textPrimary: 'rgba(45,45,44,1)',
  textSecondary: 'rgba(0, 0, 0, .54)',
  textDisabled: 'rgba(193,193,193,1)',
  textHint: 'rgba(45,45,44, .38)',
  textContrast: '#FFFFFF',

  // others
  white: 'rgb(255, 255, 255)',
  stroke: 'rgba(0,0,0, .23)',
  divider: 'rgba(224,224,224,1)',

  // Vibe colors
  blueGray: '#6A97CE',
  purpleHaze: '#6C578F',
  buttonGradient: 'linear-gradient(225deg, #9B84BF 0%, #7493CB 100%)',
  buttonGradientHover: 'linear-gradient(225deg, #6C578F 0%, #43669A 100%)',
  buttonSecondary: '#646BA2',
  buttonSecondaryHover: 'rgba(198, 202, 255, .38)',

  // old colors to be removed, do not use anymore
  solidLightGrey: 'rgb(244, 244, 244)',
  solidHardGrey: 'rgb(136, 136, 145)',
  whitesmoke: 'rgb(244, 244, 244)',
  white90: 'rgba(255, 255, 255, .90)',
  lightgrey: 'rgba(0, 0, 0, 0.1)',
  hardgrey: 'rgba(0, 0, 0, 0.6)',
  lightblack: 'rgba(0, 0, 0, 0.54)',
  background: '#fbfbfb',
}

const placeholderVisible = {
  opacity: '0.5 !important',
}

const buttonStyles = {
  default: {
    width: 'fit-content',
    height: '36px',
    borderRadius: '4px',
    boxShadow: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    color: colors.blue,
    '&:hover': {
      backgroundColor: colors.blueLightBg,
    },
    '&:disabled': {
      border: `1px solid ${colors.textDisabled}`,
      backgroundColor: 'white',
      color: colors.textDisabled,
    },
  },
  secondary: {
    color: colors.buttonSecondary,
    borderColor: colors.buttonSecondary,
    '&:hover': {
      backgroundColor: colors.buttonSecondaryHover,
    },
  },
  outlined: {
    border: `1px solid ${colors.blue}`,
  },
  contained: {
    backgroundColor: colors.blue,
    color: colors.textContrast,
    '&:hover': {
      backgroundColor: colors.blueDark,
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: `${colors.blueDark} !important`, // MUI idiosyncrasy
      },
    },
    '&:disabled': {
      border: 'none',
      backgroundColor: colors.grey40,
      color: colors.textDisabled,
    },
  },
  cta: {
    border: 'none',
    backgroundColor: 'transparent',
    background: colors.buttonGradient,
    '&:hover': {
      background: colors.buttonGradientHover,
    },
    '&:disabled': {
      background: 'none',
      backgroundColor: colors.grey40,
    },
  },
  textVariant: {
    border: 'none',
  },
  text: {
    small: {
      fontSize: 14,
      lineHeight: '24px',
      padding: '4px 10px',
    },
    medium: {
      fontSize: 14,
      lineHeight: '24px',
      padding: '6px 16px',
    },
    large: {
      fontSize: 15,
      lineHeight: '26px',
      padding: '8px 22px',
    },
  },
}

buttonStyles.contained = {
  ...buttonStyles.contained,
  ...buttonStyles.text.medium,
}

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: { main: colors.blue },
      secondary: { main: colors.white },
      background: {
        // @ts-expect-error
        main: colors.background,
        badge: colors.black40,
      },
      text: {
        // @ts-expect-error
        main: colors.textPrimary,
        primary: colors.textPrimary,
        secondary: colors.textSecondary,
        error: colors.red,
      },
    },
    typography: {
      // @ts-expect-error
      useNextVariants: true,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      fontFamily: 'Nunito',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      h1: {
        fontSize: 54,
        fontWeight: 700,
        letterSpacing: -1.5,
      },
      h2: {
        fontSize: 48,
        fontWeight: 700,
        letterSpacing: -0.5,
      },
      h3: {
        fontSize: 22,
        fontWeight: 700,
        letterSpacing: 0.25,
      },
      h4: {
        fontSize: 18,
        fontWeight: 700,
        letterSpacing: 0.25,
      },
      h5: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 1.5,
      },
      h6: {
        fontSize: 12,
        lineHeight: 1.2,
        letterSpacing: 0.15,
      },
      body1: {
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 0,
      },
      body2: {
        fontSize: 10,
        lineHeight: 1.43,
        letterSpacing: 0,
      },
      caption: {
        fontSize: 10,
        fontWeight: 700,
        lineHeight: 1.2,
        letterSpacing: 0.33,
      },
      subtitle1: {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1,
        letterSpacing: 0.15,
      },
      subtitle2: {
        fontSize: 10,
        fontWeight: 700,
        lineHeight: 1.43,
        letterSpacing: 0.09,
      },
    },
    overrides: {
      MuiButton: {
        root: buttonStyles.default,
        contained: buttonStyles.contained,
        containedPrimary: buttonStyles.cta,
        containedSecondary: buttonStyles.contained,

        outlined: buttonStyles.outlined,
        outlinedPrimary: buttonStyles.outlined,
        outlinedSecondary: buttonStyles.secondary,

        label: {
          fontStretch: 'normal',
          fontStyle: 'normal',
          textAlign: 'center',
          letterSpacing: 0.46,
          textTransform: 'uppercase',
          fontWeight: 'bold',
        },

        text: buttonStyles.text.medium,
        textSecondary: {
          ...buttonStyles.text.medium,
          ...buttonStyles.secondary,
        },

        textSizeLarge: buttonStyles.text.large,
        containedSizeLarge: buttonStyles.text.large,
        textSizeSmall: buttonStyles.text.small,
        containedSizeSmall: buttonStyles.text.small,
      },
      MuiCardHeader: {
        root: {
          backgroundColor: colors.whitesmoke,
        },
        title: {
          textAlign: 'center',
          color: colors.hardgrey,
        },
      },
      MuiCard: {
        root: {
          borderRadius: '1rem',
          boxShadow: '0px 3px 14px 2px #60558C1F, 0px 5px 5px -3px #60558C33',
        },
      },
      MuiInputAdornment: {
        root: {
          color: colors.black54,
        },
      },
      MuiInputBase: {
        root: {
          color: colors.blue,
        },
        input: {
          'label[data-shrink=false] + $formControl &': {
            '&::-webkit-input-placeholder': placeholderVisible,
            '&::-moz-placeholder': placeholderVisible, // Firefox 19+
            '&:-ms-input-placeholder': placeholderVisible, // IE 11
            '&::-ms-input-placeholder': placeholderVisible, // Edge
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: colors.textPrimary,
        },
        shrink: {
          backgroundColor: 'white',
          padding: '0 5px',
        },
      },
      // @ts-expect-error
      MuiAlert: {
        root: {
          borderRadius: 16,
        },
        standardWarning: {
          color: colors.black54,
          background: colors.yellowLightBg,
        },
      },
      MuiAlertTitle: {
        root: {
          fontWeight: 600,
          color: colors.black75,
        },
      },
      MuiChip: {
        root: {
          backgroundColor: colors.grey40,
          color: colors.black54,
        },
        icon: {
          color: colors.black23,
        },
      },
      MuiSkeleton: {
        root: {
          width: '100%',
          borderRadius: 16,
          minHeight: 100,
        },
      },
      MuiOutlinedInput: {
        root: {
          '& svg': {
            color: colors.blue,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.blueDark,
          },
        },
        input: {
          padding: '12px',
        },
        notchedOutline: {
          borderColor: colors.blue,
          '&:hover': {
            borderColor: colors.blueDark,
          },
        },
      },
    },
  }),
)

export default theme
