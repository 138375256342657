const activeSection = ({ pathname, path, possiblePaths }: any) => {
  if (pathname && pathname !== '/login') {
    const rootPath = pathname.split('/')[1]
    if (possiblePaths.includes(rootPath)) {
      if (path.includes(rootPath)) {
        return path
      }
    } else if (path === '/') {
      return path
    }
    return false
  }
  return false
}

export default activeSection
