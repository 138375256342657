import Alert from 'shared/ui/alert'
import { useCroods } from 'croods'
import CycleDialog from './cycle-dialog'
import CycleCard from './CycleCard'

export default function Cycle() {
  const [{ info: organization }] = useCroods({ name: 'organization' })
  const { canEditCycleDuration, isFirstEvaluationCycle, currentCycle } =
    organization

  return (
    <>
      <div className="flex justify-between">
        <h3>Ciclo</h3>
        {currentCycle && canEditCycleDuration && (
          <CycleDialog
            disabled={!canEditCycleDuration}
            currentCycle={currentCycle}
            isFirstEvaluationCycle={isFirstEvaluationCycle}
          />
        )}
      </div>

      {!canEditCycleDuration && (
        <Alert severity="warning" className="my-4" message="Calma.">
          <p>
            Neste momento você não poderá editar o ciclo, espere o período de
            avaliação atual terminar.
          </p>
        </Alert>
      )}

      {!currentCycle && canEditCycleDuration && (
        <Alert severity="warning" className="my-4" message="Atenção.">
          <p>No momento não existe ciclo corrente</p>
        </Alert>
      )}

      {currentCycle && <CycleCard currentCycle={currentCycle} />}
    </>
  )
}
