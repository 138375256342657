import type { PageProps } from 'types'

import Timeline from './Timeline'
import { VocationManagementContextProvider } from './context'

const VacationManagement: PageProps = ({}) => {
  return (
    <VocationManagementContextProvider>
      <div className="pb-10">
        <Timeline />
      </div>
    </VocationManagementContextProvider>
  )
}

export default VacationManagement
