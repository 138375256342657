import React from 'react'
import { getSortedCircles, getDenomination, safeJoin } from 'shared/helpers'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'

import type { User, Team } from 'types'

const formattedCircle = ({ teamName, teamDenomination: denom }: Team): string =>
  safeJoin([teamName, denom && `[${getDenomination(denom)}]`])

type UserTeams = Pick<User, 'teamMembers' | 'teamLeaders'>
const CircleChips: React.FC<UserTeams> = ({ teamMembers, teamLeaders }) => {
  const sorted = getSortedCircles({ teamMembers, teamLeaders })
  const [first, ...rest] = sorted.map(formattedCircle)
  return (
    <div className="flex flex-row justify-start gap-1 md:flex-col md:items-center md:justify-center lg:flex-row">
      {first ? (
        <Tooltip title={first} placement="bottom">
          <Chip className="max-w-[120px] !text-xs" size="small" label={first} />
        </Tooltip>
      ) : (
        <Chip
          className="max-w-[120px] !text-xs"
          size="small"
          variant="outlined"
          label="Sem Círculo"
        />
      )}
      {Boolean(rest.length) && (
        <Tooltip title={safeJoin(rest, ', ')} placement="bottom">
          <Chip
            className="max-w-[80px] cursor-auto !text-xs"
            size="small"
            label={`+${rest.length}`}
          />
        </Tooltip>
      )}
    </div>
  )
}

export default CircleChips
