import { useState, useEffect, useCallback, useRef, useMemo } from 'react'

import type { QueryStringObj, UseCroodsOptions } from 'croods'
import { useCroods } from 'croods'

import type { Option, AutocompleteProps } from './Autocomplete'
import Autocomplete from './Autocomplete'

import debounce from 'lodash/debounce'
import identity from 'lodash/identity'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'

type Props<T> = {
  fixedOptions?: Option[]
  parseOptions?: (options: T[]) => any[]
  initialQuery?: string
  queryParamName?: string
  extraSearchParams?: QueryStringObj
  croodsConfig: UseCroodsOptions
  autocompleteProps: AutocompleteProps
  inputProps: Record<string, any>
  defaultValue?: any
  debounceTime?: number
}

const AutocompleteWithSearch = <T,>({
  fixedOptions = [],
  parseOptions = identity,
  initialQuery = '',
  queryParamName = 'query',
  extraSearchParams = {},
  croodsConfig,
  autocompleteProps,
  inputProps,
  defaultValue = [],
  debounceTime = 0,
}: Props<T>) => {
  const [query, setQuery] = useState(initialQuery)
  const [debounceLoading, setDebounceLoading] = useState(false)

  const [{ list, fetchingList: loading }, { fetch, setList: setOptions }] =
    useCroods<any>({
      fetchOnMount: false,
      ...croodsConfig,
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchWithQuery = useCallback(fetch(), []) // used on click and typing]

  const delayedRequest = useRef(
    debounce((value) => {
      fetchWithQuery(value)
      setDebounceLoading(false)
    }, debounceTime),
  ).current

  useEffect(() => {
    if (query.length >= 1) {
      const handler = setTimeout(() => {
        setDebounceLoading(true)
        return delayedRequest({ [queryParamName]: query, ...extraSearchParams })
      }, 0)

      return () => {
        clearTimeout(handler)
        setOptions([])
      }
    }
    return undefined
  }, [query]) //eslint-disable-line

  const currentValue = useMemo(
    () => autocompleteProps.value || [],
    [autocompleteProps.value],
  )

  useEffect(() => {
    if (!autocompleteProps.value) {
      setQuery('')
    }
  }, [autocompleteProps.value])

  let options = []

  options = sortBy(
    uniqBy(
      [
        ...fixedOptions,
        ...(Array.isArray(currentValue) ? currentValue : [currentValue]),
        ...parseOptions(list),
      ],
      'label',
    ),
    'label',
  )

  return (
    <Autocomplete
      {...autocompleteProps}
      inputProps={inputProps}
      loading={loading || debounceLoading}
      options={options}
      query={query}
      setQuery={setQuery}
      defaultValue={defaultValue}
      disablePortal
    />
  )
}

export default AutocompleteWithSearch
