import * as React from 'react'

const AiIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="23"
    viewBox="0 0 32 23"
    className="mr-2 inline fill-current"
    {...props}
  >
    <path d="M7.04888 15.088C6.79222 15.088 6.60888 15 6.49888 14.824C6.38888 14.648 6.39255 14.4354 6.50988 14.186L9.41388 7.75102C9.50922 7.53836 9.62288 7.38802 9.75488 7.30002C9.89422 7.20469 10.0519 7.15702 10.2279 7.15702C10.3966 7.15702 10.5469 7.20469 10.6789 7.30002C10.8182 7.38802 10.9356 7.53836 11.0309 7.75102L13.9459 14.186C14.0632 14.4427 14.0706 14.659 13.9679 14.835C13.8652 15.0037 13.6892 15.088 13.4399 15.088C13.2345 15.088 13.0732 15.0404 12.9559 14.945C12.8459 14.8424 12.7469 14.6884 12.6589 14.483L12.1089 13.207H8.32488L7.78588 14.483C7.69055 14.6957 7.59155 14.8497 7.48888 14.945C7.38622 15.0404 7.23955 15.088 7.04888 15.088ZM10.2059 8.77402L8.80888 12.085H11.6249L10.2279 8.77402H10.2059Z" />
    <path d="M16.3214 15.088C15.8521 15.088 15.6174 14.846 15.6174 14.362V7.88303C15.6174 7.39902 15.8521 7.15702 16.3214 7.15702C16.7908 7.15702 17.0254 7.39902 17.0254 7.88303V14.362C17.0254 14.846 16.7908 15.088 16.3214 15.088Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6328 1.00003C23.7519 1.00003 23.8601 1.06966 23.9094 1.17811L25.2151 4.05066L28.0876 5.35637C28.1961 5.40567 28.2657 5.5138 28.2657 5.63294C28.2657 5.75207 28.1961 5.86021 28.0876 5.9095L25.2151 7.21521L23.9094 10.0878C23.8601 10.1962 23.7519 10.2658 23.6328 10.2658C23.5137 10.2658 23.4055 10.1962 23.3562 10.0878L22.0505 7.21521L19.178 5.9095C19.0695 5.86021 18.9999 5.75207 18.9999 5.63294C18.9999 5.5138 19.0695 5.40567 19.178 5.35637L22.0505 4.05066L23.3562 1.17811C23.4055 1.06966 23.5137 1.00003 23.6328 1.00003ZM29.0303 7.56332C29.1493 7.56285 29.2575 7.63182 29.3074 7.73981L29.944 9.1192L31.3234 9.75584C31.4314 9.80568 31.5004 9.91395 31.4999 10.0329C31.4994 10.1518 31.4296 10.2595 31.3212 10.3085L29.944 10.9309L29.3078 12.3225C29.2582 12.4311 29.1497 12.5005 29.0303 12.5C28.911 12.4996 28.803 12.4293 28.7543 12.3203L28.1323 10.9309L26.7429 10.309C26.634 10.2602 26.5637 10.1522 26.5632 10.0329C26.5627 9.91354 26.6322 9.80499 26.7407 9.75538L28.1323 9.1192L28.7547 7.74201C28.8037 7.63363 28.9114 7.56379 29.0303 7.56332Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.802 3.0166C14.9543 1.91603 12.8025 1.43678 10.6625 1.64922C8.52238 1.86166 6.50679 2.7546 4.91158 4.19695C3.31637 5.63929 2.22559 7.55506 1.79938 9.663C1.37316 11.7709 1.63396 13.96 2.54349 15.9088C3.45302 17.8576 4.96336 19.4634 6.85278 20.4906C8.74221 21.5178 10.9112 21.9122 13.0413 21.6159C15.1713 21.3196 17.1503 20.3482 18.6877 18.8444C20.225 17.3405 21.2398 15.3834 21.5829 13.2604C21.6358 12.9332 21.9438 12.7109 22.2709 12.7638C22.5981 12.8167 22.8204 13.1247 22.7675 13.4518C22.3834 15.8282 21.2476 18.0189 19.5268 19.7022C17.806 21.3855 15.5909 22.4728 13.2066 22.8045C10.8223 23.1361 8.39452 22.6947 6.27962 21.5449C4.16473 20.3951 2.47415 18.5976 1.45609 16.4163C0.438022 14.2349 0.1461 11.7847 0.623179 9.42517C1.10026 7.06569 2.3212 4.92131 4.10677 3.30685C5.89234 1.69238 8.14846 0.692883 10.5439 0.455089C12.9394 0.217295 15.348 0.753733 17.4161 1.98565C17.7008 2.15523 17.7941 2.52349 17.6245 2.80818C17.4549 3.09287 17.0867 3.18619 16.802 3.0166Z"
    />
  </svg>
)

export default AiIcon
