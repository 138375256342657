import React from 'react'
import Button from '@material-ui/core/Button'

const Switcher = ({ options, active, setActive }: any) => (
  <div className="flex flex-1">
    {options.map(({ title }: any) => (
      <Button
        key={title}
        size="large"
        className="flex-1 !rounded-none !border-l-0 !text-xs leading-loose first:!rounded-l first:!border-l last:!rounded-r sm:flex-auto"
        variant={active === title ? 'contained' : 'outlined'}
        onClick={() => setActive(title)}
      >
        {title}
      </Button>
    ))}
  </div>
)

export default Switcher
