import TeamEvaluationNotice from 'performance/evaluations/teamEvaluation/teamEvaluationNotice'
import MembersEvaluationSwitch from 'performance/evaluations/membersEvaluation/MembersEvaluationSwitch'
import useOrganization from 'shared/organization/useOrganization'

export default function Performance() {
  const organization = useOrganization()

  return (
    <div className="flex flex-col gap-y-10">
      {organization.permitTeamsEvaluations && <TeamEvaluationNotice />}
      {organization.permitTalentsEvaluations && <MembersEvaluationSwitch />}
    </div>
  )
}
