import React from 'react'
import { cx, formatDateShort, formatDateShorter } from 'shared/helpers'
import Bars from './bars'
import Header from './header'
import BaseShape from './base-shape'
import ActiveShape from './active-shape'
import RedStackShape from './red-stack-shape'
import GreenStackShape from './green-stack-shape'
import YellowStackShape from './yello-stack-shape'
import DarkGreenStackShape from './dark-green-stack-shape'

type Props = { data: any[] } & JSX.IntrinsicElements['div']

function ChartRoot({ data: rawData, className, children, ...props }: Props) {
  const data = rawData.map(({ startsAt, finishesAt, ...values }) => {
    return {
      dateLabel: `${formatDateShorter(
        startsAt,
      ).toUpperCase()}-${formatDateShorter(finishesAt).toUpperCase()}`,
      dateText: `${formatDateShort(startsAt).toUpperCase()} a ${formatDateShort(
        finishesAt,
      ).toUpperCase()}`,
      ...values,
    }
  })

  return (
    <div className={cx('w-full rounded-xl shadow-card', className)} {...props}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return child

        if (child.type === Bars) {
          return React.cloneElement(child, { data, ...child.props })
        }

        return child
      })}
    </div>
  )
}

const Chart = Object.assign(ChartRoot, {
  Header,
  Bars,
  BaseShape,
  ActiveShape,
  DarkGreenStackShape,
  GreenStackShape,
  YellowStackShape,
  RedStackShape,
})

export default Chart
