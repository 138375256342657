import * as React from 'react'
import { MdOutlineBeachAccess } from 'react-icons/md'

import { formatNumberOfDays } from 'portal/vacationsHelpers'

import type { Vacation } from '../types'

export default function Body({ member }: { member: Vacation }) {
  return (
    <div className="flex h-10 items-center p-6">
      <MdOutlineBeachAccess className="text-gray-dark" size={24} />
      <p className="ml-2 text-sm font-bold text-gray-darkest">
        {formatNumberOfDays(member.currentBalance)}
      </p>
    </div>
  )
}
