import React from 'react'
import { Link } from 'react-router-dom'
import { cx } from 'shared/helpers'

type Props = React.HTMLAttributes<HTMLParagraphElement> & {
  to?: string
  newTab?: boolean
}
const TextLink = ({ to = '', children, newTab = false, ...props }: Props) => {
  const LinkComponent = newTab ? 'a' : Link

  return to && newTab ? (
    <a href={to} target="_blank" className="no-underline" rel="noreferrer">
      <Content {...props}>{children}</Content>
    </a>
  ) : to ? (
    <LinkComponent to={to}>
      <Content {...props}>{children}</Content>
    </LinkComponent>
  ) : (
    <Content onClick={goBack} {...props}>
      {children}
    </Content>
  )
}

const Content = ({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) => (
  <p
    className={cx(
      className,
      'cursor-pointer font-bold leading-7 text-blue no-underline',
    )}
    {...props}
  >
    {children}
  </p>
)

export const goBack = () => window.history.back()

export default TextLink
