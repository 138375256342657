import * as React from 'react'
import { useCroods } from 'croods'
import { useNavigate, useParams } from 'react-router-dom'
import curry from 'lodash/curry'

import RadioGroup from './RadioButtonsGroup'
import { presence, richTextPresence } from 'shared/forms/validations'
import ConversationPaper from 'talks/conversations/ConversationPaper'
import { useTemplate } from 'shared/utils/useTemplate'
import { useForm } from 'shared/utils/useForm'
import CustomTooltip from 'talks/conversations/CustomTooltip'
import RichText from 'shared/forms/RichText'
import FormError from 'shared/forms/FormError'
import SubmitButton from 'shared/forms/SubmitButton'

import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { typeToIcon } from 'shared/utils/conversationLookup'
import type { FormTemplate } from 'types'
import { useTrackAnalytics } from 'domain/analytics'

const New = () => {
  const teamLeaderId = useParams().teamLeaderId!
  const template = useTemplate('evaluation')

  return (
    <ConversationPaper title="Nova Avaliação Ascendente">
      <header className="flex flex-col text-gray-darkest text-opacity-80">
        <div className="flex items-center gap-4">
          {typeToIcon('upward_evaluation')}
          <h4 className="text-gray-darkest first-letter:uppercase">
            Avaliação Ascendente
          </h4>
        </div>
        <p className="mt-2 text-base italic text-gray-dark">
          Franqueza | Relacionamento | Desenvolvimento do líder
        </p>
        <p className="mt-4 text-base text-gray-dark">
          Me ajude a ser um líder melhor!
        </p>
      </header>
      <Form teamLeaderId={teamLeaderId} template={template} />
    </ConversationPaper>
  )
}

const Form = ({
  teamLeaderId,
  template,
}: {
  teamLeaderId: string
  template: FormTemplate
}) => {
  const [error, setError] = React.useState('')

  const { snackbar } = useSnackbar()
  const navigate = useNavigate()
  const track = useTrackAnalytics()

  const { formState, fields, hasErrors } = useForm(
    {},
    {
      requiredFields: ['comment', 'evaluation'],
    },
  )

  const [{ saving, saveError }, { save }] = useCroods({
    name: 'upwardEvaluations',
    customPath: `/team_leaders/${teamLeaderId}/upward_evaluations`,
    afterSuccess: () => {
      track('Upward evaluation')
      snackbar({ message: 'Avaliação criada com sucesso!', type: 'success' })
      navigate('/')
    },
    afterFailure: (err: any) => {
      if (err.code === 404 || err.message.includes('404'))
        return setError('Líder não encontrado.')
      return setError('Houve algum erro. Tente novamente mais tarde.')
    },
  })

  return (
    <form
      aria-label="form"
      onSubmit={handleSubmit({
        save,
        values: formState.values,
      })}
      className="mt-10 flex flex-col p-0"
    >
      <RadioGroup
        {...fields('text', 'evaluation', [presence()])}
        label="Analisando as últimas conversas que tivemos e o nosso relacionamento diário como um todo, como você o classificaria?"
        setValue={curry(formState.setField)('evaluation')}
        value={formState.values.evaluation}
        template={template}
      />

      <div className="relative mt-10">
        <h5>Deixe uma mensagem explicando sua avaliação.</h5>
        <CustomTooltip
          className="absolute right-0 -top-1 text-gray"
          text="Escreva um pouco sobre sua avaliação"
        />
        <RichText
          {...fields('raw', 'comment', [richTextPresence()])}
          rows={8}
          name="comment"
          className="!mb-0"
          helper="Me desafie! Por favor, acrescente tudo o que acredita que possa me
          ajudar a ser um líder melhor!"
        />
      </div>

      <FormError>{error || saveError}</FormError>

      <div className="mt-8 flex items-center justify-end gap-4">
        <SubmitButton
          className="order-last"
          disabled={hasErrors}
          loading={saving}
        >
          Salvar e enviar
        </SubmitButton>
      </div>
    </form>
  )
}

const handleSubmit =
  ({ save, values }: { save: any; values: any }) =>
  (event: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault && event.preventDefault()
    save()(values)
  }

export default New
