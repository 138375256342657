import * as React from 'react'
import type { Mode, SubmitHandler } from 'react-hook-form'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import type * as z from 'zod'

type Props = Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> & {
  onSubmit?: (
    d: SubmitHandler<any>['arguments'][0],
    ev?: React.FormEvent<HTMLFormElement>,
  ) => Promise<boolean | void>
  schema: z.ZodSchema<any>
  followAction?: boolean
  mode?: Mode
  defaultValues?: Record<string, any>
}
function Form(
  {
    onSubmit,
    followAction = true,
    schema,
    children,
    mode = 'onTouched',
    defaultValues,
    ...props
  }: Props,
  ref: React.Ref<HTMLFormElement>,
) {
  type Schema = z.infer<typeof schema>
  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
    mode,
    defaultValues,
  })
  const navigate = useNavigate()

  async function handleSubmit(
    data: SubmitHandler<Schema>['arguments'][0],
    ev?: React.FormEvent<HTMLFormElement>,
  ) {
    let valid = true
    if (onSubmit) {
      ev?.preventDefault()
      valid = (await onSubmit(data, ev)) ?? true
    }
    if (valid && followAction && props.action) navigate(props.action)
  }

  return (
    <FormProvider {...methods}>
      <form
        ref={ref}
        {...props}
        onSubmit={methods.handleSubmit(handleSubmit as SubmitHandler<Schema>)}
      >
        {children}
      </form>
    </FormProvider>
  )
}

const ForwardRef = React.forwardRef(Form)
export type { Props as FormProps }
export default ForwardRef
