import React, { useState } from 'react'
import { useCroods } from 'croods'

import { getFullName } from 'shared/helpers'
import UserModal from 'shared/ui/UserModal'

import type { Actions } from 'croods'
import type { User } from 'types'

import CheckInput from 'shared/forms/CheckInput'
import useHandleConfirm from 'shared/ui/Modal/useHandleConfirm'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'

type Props = {
  onClose(): void
  user: User | null
  fetchList: Actions<User>['fetch']
}

const Reactivate: React.FC<Props> = ({ onClose, user, fetchList }) => {
  const { snackbar } = useSnackbar()
  const [confirmed, setConfirmed] = useState(false)
  const [{ saving }, { save }] = useCroods({
    id: user?.id,
    name: 'users',
    stateId: 'supervisor',
    customPath: '/supervisor/users/:id/reactivate',
    afterSuccess: () => {
      snackbar({
        title: 'Sucesso',
        message: 'Usuário reativado com sucesso',
        type: 'success',
      })
      fetchList()()
    },
    afterFailure: (e) => {
      snackbar({
        title: 'Não foi possível reativar o usuário.',
        message: e?.response?.data?.message,
        type: 'error',
        timeout: 5,
      })
    },
  })
  const execute = useHandleConfirm<User>(save({ method: 'PUT' }))
  return (
    <UserModal
      user={user}
      enabled={confirmed}
      kind="success"
      onClose={onClose}
      title="Ativar Usuário"
      loading={saving}
      onConfirm={execute(
        'Não foi possível reativar esse usuário',
        'Usuário ativado com sucesso',
      )}
      cta="Ativar"
    >
      <CheckInput
        onChange={({ target }) => setConfirmed(target.checked)}
        checked={confirmed}
        label={
          user ? `Confirmar a ativação do usuário ${getFullName(user)}.` : ''
        }
      >
        Ativação de usuário
      </CheckInput>
    </UserModal>
  )
}

export default Reactivate
