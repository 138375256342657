import type { ReactElement } from 'react'

type TooltipTalentEvaluationItem = {
  title: string
  color: string
  text: ReactElement
}
const boldText = '!text-gray-darkest !font-extrabold'

type TooltipTalentEvaluation = {
  header: string
  description?: ReactElement | string
  items: TooltipTalentEvaluationItem[]
}

const altoImpacto: TooltipTalentEvaluationItem = {
  title: 'ALTO IMPACTO',
  color: 'text-red',
  text: (
    <>
      A performance do círculo seria{' '}
      <span className={boldText}>seriamente afetada</span> e haveria uma
      <span className={boldText}>
        {' '}
        dificuldade extrema para encontrar um substituto
      </span>
      .
    </>
  ),
}
const medioImpacto: TooltipTalentEvaluationItem = {
  title: 'MÉDIO IMPACTO',
  color: 'text-yellow',
  text: (
    <>
      A performance do círculo teria um{' '}
      <span className={boldText}>impacto relevante</span> e haveria
      <span className={boldText}>
        {' '}
        certa dificuldade para encontrar um substituto
      </span>
      .
    </>
  ),
}

const baixoImpacto: TooltipTalentEvaluationItem = {
  title: 'BAIXO IMPACTO',
  color: 'text-green',
  text: (
    <>
      A performance do círculo seria pouco impactada com a perda dessa pessoa e{' '}
      <span className={boldText}>
        não seria difícil encontrar um substituto
      </span>
    </>
  ),
}

const impactoText: TooltipTalentEvaluation = {
  header: 'IMPACTO DA PERDA',
  description:
    'Indica o quanto essa pessoa é relevante para o círculo e qual seria o impacto se ela nos deixasse:',
  items: [baixoImpacto, medioImpacto, altoImpacto],
}

const abaixoDoMercado: TooltipTalentEvaluationItem = {
  title: 'ABAIXO',
  color: 'text-red',
  text: (
    <>
      Recebe uma remuneração que está
      <span className={boldText}>
        {' '}
        abaixo da remuneração oferecida pelo mercado.
      </span>
      .
    </>
  ),
}
const dentroDoMercado: TooltipTalentEvaluationItem = {
  title: 'DENTRO',
  color: 'text-green',
  text: (
    <>
      Recebe uma remuneração que está
      <span className={boldText}>
        {' '}
        dentro da remuneração oferecida pelo mercado.
      </span>
      .
    </>
  ),
}

const acimaDoMercado: TooltipTalentEvaluationItem = {
  title: 'ACIMA',
  color: 'text-yellow',
  text: (
    <>
      Recebe uma remuneração que está
      <span className={boldText}>
        {' '}
        acima da remuneração oferecida pelo mercado.
      </span>
    </>
  ),
}

const remuneracaoText: TooltipTalentEvaluation = {
  header: 'REMUNERAÇÃO',
  description:
    'Indica se as pessoas estão sendo remuneradas de forma justa, de acordo com o mercado.',
  items: [acimaDoMercado, dentroDoMercado, abaixoDoMercado],
}

const valorPromotor: TooltipTalentEvaluationItem = {
  title: 'PROMOTOR',
  color: 'text-green-darkest',
  text: (
    <>
      Além de{' '}
      <span className={boldText}>praticar com intensidade os valores</span> da
      empresa, demonstra iniciativa para interagir com colegas ou superiores de
      forma <span className={boldText}>positiva</span>, com franqueza e
      educação, para
      <span className={boldText}> defender os valores</span> da empresa quando
      necessário.
    </>
  ),
}

const valorAlto: TooltipTalentEvaluationItem = {
  title: 'ALTO',
  color: 'text-green-dark',
  text: (
    <>
      <span className={boldText}>Pratica com intensidade os valores </span>
      da empresa todos os dias.
    </>
  ),
}

const valorAdequado: TooltipTalentEvaluationItem = {
  title: 'ADEQUADO',
  color: 'text-green',
  text: (
    <>
      Está alinhado e
      <span className={boldText}>
        {' '}
        age de acordo com os valores da empresa na maioria das vezes.
      </span>{' '}
      Quando necessário, recebe os feedbacks como{' '}
      <span className={boldText}>aprendizado</span> e não prejudica as relações
      de trabalho como um todo.
    </>
  ),
}

const valorBaixo: TooltipTalentEvaluationItem = {
  title: 'BAIXO',
  color: 'text-yellow-dark',
  text: (
    <>
      <span className={boldText}>
        Age de forma contrária a um ou mais valores{' '}
      </span>
      da empresa e não recebe os feedbacks como aprendizado,{' '}
      <span className={boldText}>prejudicando as relações de trabalho</span>.
    </>
  ),
}

const valorDetrator: TooltipTalentEvaluationItem = {
  title: 'DETRATOR',
  color: 'text-red',
  text: (
    <>
      <span className={boldText}>
        Age de forma contrária a um ou mais valores da empresa de forma
        deliberada{' '}
      </span>
      ou interage com os colegas de forma negativa,{' '}
      <span className={boldText}>contaminando as relações de trabalho</span>.
    </>
  ),
}

const fitCulturalTexto: TooltipTalentEvaluation = {
  header: 'FIT CULTURAL',
  description: (
    <>
      Indica o quanto a pessoa está{' '}
      <span className={boldText}>alinhada com a cultura e valores</span> da
      empresa e com qual intensidade essa pessoa luta por eles.
    </>
  ),
  items: [valorPromotor, valorAlto, valorAdequado, valorBaixo, valorDetrator],
}

const potencialAlto: TooltipTalentEvaluationItem = {
  title: 'ALTO',
  color: 'text-green-darkest',
  text: (
    <>
      A pessoa demonstra uma{' '}
      <span className={boldText}>evolução rápida no aprendizado</span> e tem
      potencial para se tornar uma{' '}
      <span className={boldText}>
        referência realizando contribuições diferenciadas
      </span>{' '}
      para o círculo.
    </>
  ),
}

const potencialMedio: TooltipTalentEvaluationItem = {
  title: 'MÉDIO',
  color: 'text-green',
  text: (
    <>
      A pessoa demonstra uma{' '}
      <span className={boldText}>evolução gradual no aprendizado</span> e tem
      potencial para{' '}
      <span className={boldText}>contribuir de forma relevante</span> para o
      círculo.
    </>
  ),
}

const potencialBaixo: TooltipTalentEvaluationItem = {
  title: 'BAIXO',
  color: 'text-yellow',
  text: (
    <>
      A pessoa demonstra{' '}
      <span className={boldText}>dificuldade de aprendizado</span> e permanecerá
      com uma <span className={boldText}>contribuição baixa</span> para o
      círculo.
    </>
  ),
}

const potencialText: TooltipTalentEvaluation = {
  header: 'POTENCIAL',
  description:
    'É a sua percepção como líder de onde você acredita que essa pessoa poderá chegar no futuro a partir das contribuições que ela entrega hoje:',
  items: [potencialBaixo, potencialMedio, potencialAlto],
}

const trajetoriaDesafio: TooltipTalentEvaluationItem = {
  title: 'DESAFIO',
  color: 'text-blue',
  text: (
    <>
      Não sucumbe ao status quo e deseja muito modificar as coisas ao seu redor,{' '}
      <span className={boldText}>aprender</span>, enfrentar
      <span className={boldText}> desafios</span> e realizar{' '}
      <span className={boldText}>inovações</span> de impacto.
    </>
  ),
}

const trajetoriaCuidado: TooltipTalentEvaluationItem = {
  title: 'CUIDADO',
  color: 'text-purple',
  text: (
    <>
      Performa melhor em ambientes com atividades que exigem
      <span className={boldText}> cuidado</span>,
      <span className={boldText}> disciplina</span>,
      <span className={boldText}> resiliência</span> e/ou{' '}
      <span className={boldText}> sensibilidade</span>.
    </>
  ),
}

const trajetoriaText: TooltipTalentEvaluation = {
  header: 'PERFIL DE TRAJETÓRIA',
  items: [trajetoriaDesafio, trajetoriaCuidado],
}

const entregaDependencia: TooltipTalentEvaluationItem = {
  title: 'DEPENDÊNCIA',
  color: 'text-yellow',
  text: (
    <>
      Realiza entregas mais <span className={boldText}>simples</span>,
      dependendo dos colegas para realizar entregas mais relevantes.
    </>
  ),
}

const entregaAutonomia: TooltipTalentEvaluationItem = {
  title: 'AUTONOMIA',
  color: 'text-green',
  text: (
    <>
      Realiza entregas <span className={boldText}>relevantes</span> para o time
      de forma autônoma.
    </>
  ),
}

const entregaDiferenciada: TooltipTalentEvaluationItem = {
  title: 'DIFERENCIADA',
  color: 'text-green-darkest',
  text: (
    <>
      Realiza entregas <span className={boldText}>diferenciadas</span>, acima da
      média de mercado e que poderiam ser apresentadas em cases.
    </>
  ),
}

const entregaText: TooltipTalentEvaluation = {
  header: 'ENTREGA',
  description:
    'É a sua percepção como líder sobre os resultados entregues pelo desempenho desse colaborador.',
  items: [entregaDependencia, entregaAutonomia, entregaDiferenciada],
}

const trabalhoEmEquipeIndividual: TooltipTalentEvaluationItem = {
  title: 'INDIVIDUAL',
  color: 'text-yellow',
  text: (
    <>
      Trabalha mais <span className={boldText}>individualmente</span> do que
      coletivamente.
    </>
  ),
}

const trabalhoEmEquipeMembro: TooltipTalentEvaluationItem = {
  title: 'MEMBRO',
  color: 'text-green',
  text: (
    <>
      Trabalha em equipe para que todos atinjam os objetivos{' '}
      <span className={boldText}>coletivamente</span>.
    </>
  ),
}

const trabalhoEmEquipeReferencia: TooltipTalentEvaluationItem = {
  title: 'REFERÊNCIA',
  color: 'text-green-darkest',
  text: (
    <>
      É uma <span className={boldText}>referência pessoal e profissional</span>{' '}
      para os demais membros do time.
    </>
  ),
}

const trabalhoEmEquipeText: TooltipTalentEvaluation = {
  header: 'TRABALHO EM EQUIPE',
  description:
    'É a sua percepção como líder em relação a forma como o colaborador se relaciona e trabalha dentro das equipes em que atua.',
  items: [
    trabalhoEmEquipeIndividual,
    trabalhoEmEquipeMembro,
    trabalhoEmEquipeReferencia,
  ],
}

export const Impacto = () => render(impactoText)
export const FitCultural = () => render(fitCulturalTexto)
export const Potencial = () => render(potencialText)
export const Trajetoria = () => render(trajetoriaText)
export const Entrega = () => render(entregaText)
export const TrabalhoEmEquipe = () => render(trabalhoEmEquipeText)
export const Remuneracao = () => render(remuneracaoText)

const render = (talentEvaluation: TooltipTalentEvaluation) => (
  <div className="p-2 text-left">
    <h6 className="mb-2 font-bold text-gray-darkest">
      {talentEvaluation.header}
    </h6>
    {talentEvaluation.description && (
      <div className="mb-2 text-sm font-light text-gray-darkest">
        {talentEvaluation.description}
      </div>
    )}
    {talentEvaluation.items.map((item, index) => (
      <div key={`${talentEvaluation.header}${index}`} className="mb-2">
        <div className={`font-bold ${item.color}`}>{item.title}</div>
        <span className="mb-2 text-sm font-light text-gray-darkest">
          {item.text}
        </span>
      </div>
    ))}
  </div>
)
