import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'theme'

const GreenRedSwitch = withStyles({
  switchBase: {
    color: colors.red,
    '&$checked': {
      color: colors.green,
    },
    '&$checked + $track': {
      backgroundColor: colors.green,
    },
  },
  checked: {},
  track: {
    backgroundColor: colors.red,
  },
})(Switch)

export default GreenRedSwitch
