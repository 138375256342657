import { useState, useCallback, useMemo } from 'react'

const useCroodsHeaders = () => {
  const [headers, setHeaders] = useState<Record<string, any>>({})

  const parseResponse = useCallback((resp) => {
    setHeaders(resp.headers)

    return resp.data
  }, [])

  const memoizedResponse = useMemo(
    () => ({
      headers,
      parseResponse,
    }),
    [headers, parseResponse],
  )

  return memoizedResponse
}

export default useCroodsHeaders
