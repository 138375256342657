import { Fetch } from 'croods'
import { useNavigate } from 'react-router-dom'

import Avatar from 'shared/ui/Avatar'
import Icon from 'shared/ui/icon'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import { Skeleton } from '@material-ui/lab'

const UpwardEvaluationReminder = () => {
  const navigate = useNavigate()

  return (
    <Fetch
      id="notification"
      name="upwardEvaluationReminder"
      customPath="/upward_evaluation/notification"
      render={(upwardEvaluationReminder) => {
        const {
          firstName,
          lastName,
          gaveEvaluation,
          teamLeaderId,
          avatar,
          email,
          hasCredential,
          recentlyAdded,
        } = upwardEvaluationReminder

        const user = {
          firstName,
          lastName,
          avatar,
          email,
          hasCredential,
          recentlyAdded,
        }

        const upwardEvaluationUrl = `/conversas/lider/${teamLeaderId}/avaliacao-ascendente/novo`

        if (gaveEvaluation) {
          return null
        }

        return (
          <div>
            <h3 className="mb-4">Avaliação Ascendente</h3>

            <div className="overflow-hidden rounded-2xl border ">
              <div className="flex justify-between px-4 py-3">
                <div className="flex items-center">
                  <Avatar user={user} size={36} />
                  <div className="ml-3">
                    <h6 className="font-bold">
                      {firstName} {lastName} aguarda sua avaliação.
                    </h6>
                    <p className="text-gray-dark">
                      Me ajude a ser um líder melhor!
                    </p>
                  </div>
                </div>

                <button
                  type="button"
                  className="flex max-h-8 items-center gap-2 whitespace-nowrap rounded-md border border-blue/50 px-2 py-1 text-base font-bold text-blue outline-none hover:bg-blue-lightest/10"
                  onClick={() => navigate(upwardEvaluationUrl)}
                >
                  <Icon icon="mail" size="sm" />
                  <span className="block">AVALIAR LÍDER</span>
                </button>
              </div>
            </div>
          </div>
        )
      }}
      renderLoading={() => <Skeleton height="20rem" className="col-span-2" />}
      renderError={(error) => (
        <FeedbackMessage
          serverMessage={error}
          message="Ocorreu um erro na Avaliação Ascendente"
        />
      )}
      renderEmpty={() => null}
    />
  )
}

export default UpwardEvaluationReminder
