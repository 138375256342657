import React, { useState } from 'react'
import { TextAreaPencil } from 'shared/ui/EditableField'

type Props = {
  description?: string
  handleSave: (string: string) => void
  autoFocus?: boolean
}

export default function TextArea({
  description,
  handleSave,
  autoFocus,
}: Props) {
  const [value, setValue] = useState(description || '')

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    handleSave(e.target.value)
  }

  const handleKeyPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      handleSave(e.currentTarget.value)
    }
  }

  return (
    <div className="flex grow items-center p-4">
      <TextAreaPencil
        name="goal"
        onBlur={handleBlur}
        onKeyPress={handleKeyPressEnter}
        value={value}
        setValue={setValue}
        placeholder="Escreva o objetivo"
        className="w-full text-base font-bold text-gray-dark"
        autoFocus={autoFocus}
      />
    </div>
  )
}
