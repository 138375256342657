import React from 'react'
import StarRoundedIcon from '@material-ui/icons/StarRounded'
import StarHalfRoundedIcon from '@material-ui/icons/StarHalfRounded'
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded'
import { cx } from 'shared/helpers'

type Props = {
  status: 'new' | 'ongoing' | 'completed'
  displayOnly?: boolean
}

export default function Star({ status, displayOnly }: Props) {
  const getStar = () => {
    if (status === 'completed') {
      return (
        <StarRoundedIcon
          titleAccess="Completo"
          className="fill-current"
          fontSize="large"
        />
      )
    }

    if (status === 'ongoing') {
      return (
        <StarHalfRoundedIcon
          titleAccess="Em andamento"
          className="fill-current"
          fontSize="large"
        />
      )
    }

    return (
      <StarBorderRoundedIcon
        titleAccess="Novo"
        className="fill-current"
        fontSize="large"
      />
    )
  }

  const bgColor = () => {
    if (displayOnly) {
      return 'radial-gradient'
    }
    return status === 'completed' ? 'bg-green-light' : 'bg-gray-lightest'
  }

  const textColor = () => {
    if (status === 'completed') return 'text-gray-darkest/50'
    if (displayOnly) return 'text-white'
    return null
  }

  return (
    <div
      data-testid="goal-star"
      className={cx(
        'flex grow-0 items-center justify-center p-4',
        bgColor(),
        textColor(),
      )}
    >
      {getStar()}
    </div>
  )
}
