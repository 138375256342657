import React from 'react'
import type { Dispatch, SetStateAction } from 'react'
import Avatar from 'shared/ui/Avatar'
import type { User } from 'types'
import type { MemberEvaluation } from '../../types'

type Props = {
  user: User
  name: string
  setShowDialog: Dispatch<SetStateAction<boolean>>
  loading: boolean
  selectedPathProfile?: MemberEvaluation['pathProfile']
  children: JSX.Element[]
}

const getSelectedPathProfile = (
  selectedPathProfile: Props['selectedPathProfile'],
) => {
  const pathProfile =
    selectedPathProfile === 'care' ? (
      <p className="subtitle2 h-fit rounded-2xl bg-[#F1F3FF] px-2 leading-4 text-purple-dark">
        Cuidado
      </p>
    ) : selectedPathProfile === 'challenge' ? (
      <p className="subtitle2 h-fit rounded-2xl bg-blue-lightest px-2 leading-4 text-blue">
        Desafio
      </p>
    ) : null

  return pathProfile
}

const MemberInfo = ({
  user,
  name,
  setShowDialog,
  loading,
  selectedPathProfile,
  children,
}: Props) => {
  return (
    <td className="mr-3 flex min-w-[180px] text-left">
      <div className="mr-1">
        <Avatar user={user} size={32} />
      </div>
      <div className="mr-1 w-[120px] grow text-ellipsis text-sm font-bold">
        <div className="flex items-center justify-between">
          <p className="subtitle1 truncate text-gray-darkest" title={name}>
            {name}
          </p>
          {getSelectedPathProfile(selectedPathProfile)}
        </div>
        {children}
      </div>
      <button
        disabled={loading}
        className="shrink-0 self-start hover:bg-blue-lightest disabled:animate-pulse disabled:cursor-wait"
        type="button"
        onClick={() => setShowDialog(true)}
        title={'Mais Opções'}
      >
        <img
          src={'/three_dots_button.svg'}
          alt="Mais Opções"
          className="min-w-5 h-5"
        />
      </button>
    </td>
  )
}

export default MemberInfo
