import { cx } from '@remaster-run/helpers'
import { makeForm } from '@remaster-run/react-router'
import { makeTheme } from '@remaster-run/themes/basic'
import type {
  EditProps,
  InfoLoaderOrData,
  InfoProps,
  ListItemProps,
  ListLoaderOrData,
  ListProps,
  ListWithCrudProps,
  NewProps,
  PaginationProps,
  SearchParamsValues,
} from '@remaster-run/cruds'
import type { FormProps, FormSchema } from 'remix-forms'

import {
  Form as FrameworkForm,
  Link,
  useActionData,
  useLoaderData,
  useSubmit,
  useLocation,
  useNavigation,
  useNavigate,
  useFetcher,
  useFetchers,
  useResolvedPath,
} from 'react-router-dom'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid'
import { Fragment, forwardRef, useEffect, useRef } from 'react'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { debounce } from 'lodash'
import Icon from 'shared/ui/icon'

const {
  Form,
  List: ThemeList,
  Info: ThemeInfo,
  Edit: ThemeEdit,
  New: ThemeNew,
  ListWithCrud: ThemeListWithCrud,
  GlobalLoading,
  SearchParamsForm,
  SearchParamsRemixForm,
  makeInfoDestroyAction,
} = makeTheme(
  {
    Form: FrameworkForm,
    RemixForm: makeForm(),
    Link,
    useLoaderData,
    useActionData,
    useNavigation,
    useLocation,
    useSubmit,
    useNavigate,
    useFetcher,
    useFetchers,
    useResolvedPath,
  },
  {
    globalLoading: {
      wrapper: 'fixed inset-x-0 top-0 left-0 z-[150] h-1.5',
    },
    list: {
      main: 'flex flex-col gap-4 md:gap-6',
      selectItem: {
        checkbox:
          'h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue disabled:bg-gray-lightest',
      },
      table: {
        table: 'block w-full table-fixed text-base text-gray-dark md:table',
      },
      tableHead:
        'hidden bg-gray-lightest text-base font-bold md:table-header-group',
      tableBody: 'block md:table-row-group',
      tableHeader: 'block p-3 md:table-cell text-left uppercase',
      tableRow: {
        row: 'block bg-white md:table-row py-2 md:py-0 relative',
        evenRow: 'bg-blue-lightest',
      },
      tableCell:
        'block px-3 py-2 md:py-5 before:block before:font-bold before:uppercase before:content-[attr(data-label)] before:mb-1 md:table-cell md:before:content-none data-[drag-handle=true]:absolute md:data-[drag-handle=true]:static data-[drag-handle=true]:right-0 md:data-[drag-handle=true]:right-auto',
      list: {
        wrapper: '',
        list: 'overflow-hidden bg-white shadow rounded-lg',
      },
      listItemLink: {
        link: '-m-4 flex flex-1 items-center p-4 overflow-hidden',
        text: 'flex min-w-0 flex-1 items-center gap-4',
      },
      listTitle: 'text-lg font-bold text-blue break-words',
      listSubtitle: 'flex items-center text-base text-gray-darker',
    },
    form: {
      label: 'label',
      input: 'input',
      multiline: 'input',
      select: 'input',
      checkbox:
        'h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue disabled:bg-gray-lightest',
      radio: 'h-4 w-4 rounded-full border-gray-300 text-blue focus:ring-blue',
      button: 'bt bt-contained',
      error: 'text-red-600',
    },
  },
)

function SnackbarErrors({ children }: JSX.IntrinsicElements['div']) {
  return <Fragment>{children}</Fragment>
}

function SnackbarError({ children }: JSX.IntrinsicElements['div']) {
  const { snackbar } = useSnackbar()
  const message = String(children)

  useEffect(() => {
    if (!message) return

    snackbar({ message, type: 'error' })
  }, [message, snackbar])

  return null
}

function SnackbarForm<Schema extends FormSchema>(props: FormProps<Schema>) {
  return (
    <Form
      globalErrorsComponent={SnackbarErrors}
      errorComponent={SnackbarError}
      {...props}
    />
  )
}

function Null() {
  return null
}

function New<Form extends FormSchema>(props: NewProps<Form>) {
  return <ThemeNew<Form> headerComponent={Null} {...props} />
}

function Edit<LoaderOrData extends InfoLoaderOrData, Form extends FormSchema>(
  props: EditProps<LoaderOrData, Form>,
) {
  return <ThemeEdit<LoaderOrData, Form> headerComponent={Null} {...props} />
}

function Info<LoaderOrData extends InfoLoaderOrData>(
  props: InfoProps<LoaderOrData>,
) {
  return <ThemeInfo backLinkComponent={Null} {...props} />
}

function Empty() {
  return (
    <FeedbackMessage
      type="warning"
      message="Nenhum item a ser listado aqui"
      serverMessage=""
    />
  )
}

const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ className, index, ...props }, ref) => (
    <li
      ref={ref}
      className={cx(
        'flex items-center gap-4 p-4 hover:bg-purple-light/30',
        index % 2 !== 0 && 'bg-blue-lightest',
        className,
      )}
      {...props}
    />
  ),
)

function pages(page: number, totalPages: number) {
  const delta = 2
  const left = page - delta
  const right = page + delta + 1
  let range: number[] = []
  let rangeWithDots: Array<number | '...'> = []
  let l

  for (let i = 1; i <= totalPages; i++) {
    if (i == 1 || i == totalPages || (i >= left && i < right)) {
      range.push(i)
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1)
      } else if (i - l !== 1) {
        rangeWithDots.push('...')
      }
    }
    rangeWithDots.push(i)
    l = i
  }

  return rangeWithDots
}

function Pagination({
  fetcher,
  action,
  paginationPattern,
  onAppendPage,
  page,
  total,
  totalPages,
  firstItem,
  lastItem,
  previousPage,
  nextPage,
  preserveSearchParams,
  customSearchParams,
  customSearchValues = {},
}: PaginationProps) {
  if (paginationPattern === 'append') {
    return page < totalPages ? (
      <div className="mt-2 flex justify-center">
        <button onClick={onAppendPage} className="bt bt-outlined">
          Load more
        </button>
      </div>
    ) : null
  }

  const preserveOptions = (values: SearchParamsValues) => ({
    preserve: preserveSearchParams,
    customParams: customSearchParams,
    customValues: { ...customSearchValues, ...values },
  })

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 justify-between md:hidden">
        <SearchParamsForm
          action={action}
          fetcher={fetcher}
          {...preserveOptions({ page: previousPage })}
        >
          <button className="bt bt-outlined" disabled={page === 1}>
            Anterior
          </button>
        </SearchParamsForm>
        <SearchParamsForm
          action={action}
          fetcher={fetcher}
          {...preserveOptions({ page: nextPage })}
        >
          <button className="bt bt-outlined" disabled={page >= totalPages}>
            Próxima
          </button>
        </SearchParamsForm>
      </div>
      <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
        <div>
          <p className="text-base text-gray-darker">
            Mostrando <span className="font-medium">{firstItem}</span> a{' '}
            <span className="font-medium">{lastItem}</span> de{' '}
            <span className="font-medium">{total}</span> resultados
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <SearchParamsForm
              action={action}
              fetcher={fetcher}
              {...preserveOptions({ page: null })}
            >
              <button className="h-10 relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-darker hover:bg-gray-50 focus:z-20">
                <span className="sr-only">Primeira</span>
                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </SearchParamsForm>
            <SearchParamsForm
              action={action}
              fetcher={fetcher}
              {...preserveOptions({ page: previousPage })}
            >
              <button className="h-10 relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-darker hover:bg-gray-50 focus:z-20">
                <span className="sr-only">Anterior</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </SearchParamsForm>
            {pages(page, totalPages).map((value, index) => {
              if (value === '...') {
                return (
                  <span
                    key={index}
                    className="h-10 relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-darker"
                  >
                    ...
                  </span>
                )
              }

              return (
                <SearchParamsForm
                  key={index}
                  action={action}
                  fetcher={fetcher}
                  {...preserveOptions({ page: value })}
                >
                  <button
                    aria-current={value === page ? 'page' : undefined}
                    className={cx(
                      'h-10 relative inline-flex items-center border px-4 py-2 text-base font-bold focus:z-20',
                      value === page
                        ? 'z-10 border-blue bg-blue-50 text-blue'
                        : 'border-gray-300 bg-white text-gray-darker hover:bg-gray-50',
                    )}
                  >
                    {value}
                  </button>
                </SearchParamsForm>
              )
            })}
            <SearchParamsForm
              action={action}
              fetcher={fetcher}
              {...preserveOptions({ page: nextPage })}
            >
              <button className="h-10 relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-darker hover:bg-gray-50 focus:z-20">
                <span className="sr-only">Próxima</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </SearchParamsForm>
            <SearchParamsForm
              action={action}
              fetcher={fetcher}
              {...preserveOptions({ page: totalPages })}
            >
              <button className="h-10 relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-darker hover:bg-gray-50 focus:z-20">
                <span className="sr-only">Última</span>
                <ChevronDoubleRightIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </button>
            </SearchParamsForm>
          </nav>
        </div>
      </div>
    </div>
  )
}

function List<LoaderOrData extends ListLoaderOrData>(
  props: ListProps<LoaderOrData>,
) {
  return (
    <ThemeList<LoaderOrData>
      emptyComponent={Empty}
      paginationComponent={Pagination}
      listItemComponent={ListItem}
      {...props}
    />
  )
}

function ListWithCrud<
  LoaderOrData extends ListLoaderOrData,
  Form extends FormSchema,
>(props: ListWithCrudProps<LoaderOrData, Form>) {
  return (
    <ThemeListWithCrud<LoaderOrData, Form>
      component={List}
      remixFormComponent={Form}
      {...props}
    />
  )
}

const SearchInput = forwardRef<
  HTMLInputElement,
  { submit: () => void } & JSX.IntrinsicElements['input']
>(({ submit, ...props }, ref) => {
  const delayedSubmit = useRef(
    debounce(() => {
      submit()
    }, 600),
  ).current

  return (
    <div className="flex flex-1 items-center justify-start gap-2 rounded border border-blue px-4 text-blue focus-within:ring-1">
      <Icon icon="search" size="md" className="" />
      <input
        {...props}
        ref={ref}
        onChange={() => delayedSubmit()}
        placeholder="Buscar"
        className="!focus:border-none h-9 w-full text-base text-blue placeholder-blue placeholder-opacity-50 focus:outline-none !border-none rounded-none"
      />
    </div>
  )
})

export {
  Form,
  SnackbarForm,
  List,
  Info,
  Edit,
  New,
  ListWithCrud,
  GlobalLoading,
  makeInfoDestroyAction,
  SearchParamsRemixForm,
  SearchInput,
}
