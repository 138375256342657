import React, { useState } from 'react'
import { TextAreaPencil } from 'shared/ui/EditableField'

type Props = {
  text?: string
  handleSave: (string: string) => void
  autoFocus?: boolean
  highlighted?: boolean
  maxLength?: number
}

export default function TitleInput({
  text = '',
  handleSave,
  autoFocus,
  highlighted,
  maxLength = 120,
}: Props) {
  const [value, setValue] = useState(text)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    handleSave(e.target.value)
  }

  const handleKeyPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    handleSave(e.currentTarget.value)
  }

  return (
    <TextAreaPencil
      maxLength={maxLength}
      name="skillTitle"
      label="Título da competência"
      setValue={setValue}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyPress={handleKeyPressEnter}
      autoFocus={autoFocus}
      highlighted={highlighted}
      placeholder="Escreva o título da competência"
      className="w-full resize-none bg-transparent p-1 text-base font-bold"
    />
  )
}
