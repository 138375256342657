import React from 'react'
import type { Actions } from 'croods'
import Star from './Star'
import Uncomplete from './actions/Uncomplete'
import type { Goal } from './types'

type Props = Goal & {
  save: Actions<Goal>['save']
}

export default function Completed({ id, description, save }: Props) {
  const handleUncomplete = () => {
    save({ id })({ completedAt: null })
  }

  return (
    <div
      className="mb-6 flex overflow-hidden rounded-lg border border-green-light bg-green-lightest"
      key={id}
    >
      <Star status="completed" />

      <div className="flex flex-1 flex-col">
        <div className="flex grow items-center p-4">
          <p className="w-full p-0 text-base font-bold text-gray-dark">
            {description}
          </p>
        </div>

        <p className="m-4 mt-0 text-xs font-bold text-green">
          Objetivo alcançado!
        </p>
      </div>

      <div className="flex items-center px-4 py-2 text-gray">
        <Uncomplete handleUncomplete={handleUncomplete} />
      </div>
    </div>
  )
}
