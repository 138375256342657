import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import { Link } from 'react-router-dom'

function MobileWarning({ buttonMessage = 'Voltar para Home' }) {
  return (
    <div className="card w-full">
      <CardContent className="flex flex-col">
        <div className="flex justify-center pt-8">
          <Icon />
        </div>
        <Divider className="my-8 -ml-6 w-[calc(100%_+_6rem)]" />
        <p className="my-5 text-center">
          Esta funcionalidade não é indicada para uso em celulares e tablets.
          Acesse com seu computador para utiliza-la.
        </p>
        <p className="my-5 text-center">
          Se você já está no computador, tente reduzir o zoom do seu navegador.
        </p>
        <Button component={Link} to="/" size="small" className="mt-5 self-end">
          {buttonMessage}
        </Button>
      </CardContent>
    </div>
  )
}

const Icon = () => (
  <svg
    width={216}
    height={72}
    viewBox="0 0 216 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77 36H88.17L83.29 40.88C82.9 41.27 82.9 41.91 83.29 42.3C83.68 42.69 84.31 42.69 84.7 42.3L91.29 35.71C91.68 35.32 91.68 34.69 91.29 34.3L84.71 27.7C84.32 27.31 83.69 27.31 83.3 27.7C82.91 28.09 82.91 28.72 83.3 29.11L88.17 34H77C76.45 34 76 34.45 76 35C76 35.55 76.45 36 77 36Z"
      fill="#C1C1C1"
    />
    <path
      d="M23.1072 6.23932H17.7504C17.1079 6.23932 16.5892 6.75797 16.5892 7.40048C16.5892 8.043 17.1079 8.56165 17.7504 8.56165H23.1072C23.7498 8.56165 24.2684 8.043 24.2684 7.40048C24.2684 6.75797 23.7498 6.23932 23.1072 6.23932Z"
      fill="#C1C1C1"
    />
    <path
      d="M35.7174 0H5.14009C2.30685 0 0 2.30685 0 5.14009V66.8599C0 69.6932 2.30685 72 5.14009 72H35.7097C38.5429 72 40.8498 69.6932 40.8498 66.8599V5.14009C40.8498 2.30685 38.543 0 35.7174 0ZM38.5275 66.8522C38.5275 68.4004 37.2657 69.6699 35.7097 69.6699H5.14009C3.59187 69.6699 2.32233 68.4081 2.32233 66.8522V5.14009C2.32233 3.59187 3.58413 2.32233 5.14009 2.32233H35.7097C37.2579 2.32233 38.5275 3.58413 38.5275 5.14009V66.8522Z"
      fill="#C1C1C1"
    />
    <path
      d="M20.4287 57.7177C18.3386 57.7177 16.6433 59.413 16.6433 61.5031C16.6433 63.5931 18.3386 65.2884 20.4287 65.2884C22.5188 65.2884 24.2141 63.5931 24.2141 61.5031C24.2141 59.413 22.5188 57.7177 20.4287 57.7177ZM20.4287 62.9661C19.6236 62.9661 18.9656 62.3081 18.9656 61.5031C18.9656 60.698 19.6236 60.04 20.4287 60.04C21.2338 60.04 21.8918 60.698 21.8918 61.5031C21.8918 62.3081 21.2338 62.9661 20.4287 62.9661Z"
      fill="#C1C1C1"
    />
    <path
      d="M166.78 11.7461H173.497C174.303 11.7461 174.954 11.0957 174.954 10.29C174.954 9.48431 174.303 8.83392 173.497 8.83392H166.78C165.974 8.83392 165.324 9.48431 165.324 10.29C165.324 11.0957 165.974 11.7461 166.78 11.7461Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M206.027 52.8661V9.29016C206.027 5.27135 202.755 2 198.736 2H141.551C137.532 2 134.261 5.27135 134.261 9.29016V52.8661H125V59.6223C125 63.1364 127.864 66 131.378 66H208.89C212.404 66 215.268 63.1364 215.268 59.6223V52.8661H206.027ZM137.163 9.29016C137.163 6.87305 139.124 4.91218 141.541 4.91218H198.727C201.144 4.91218 203.105 6.87305 203.105 9.29016V52.8661H137.163V9.29016ZM174.857 55.7783C174.303 56.9625 173.1 57.7877 171.711 57.7877H168.576C167.188 57.7877 165.984 56.9625 165.431 55.7783H174.857ZM212.365 59.6223C212.365 61.5347 210.812 63.0878 208.9 63.0878H131.378C129.465 63.0878 127.912 61.5347 127.912 59.6223V55.7783H162.363C163.023 58.5934 165.557 60.6998 168.566 60.6998H171.702C174.721 60.6998 177.245 58.5934 177.905 55.7783H212.365V59.6223Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M169.857 29.7433C169.683 29.7433 169.527 29.7822 169.362 29.8404C169.323 29.8501 169.285 29.8695 169.246 29.889C169.09 29.9569 168.945 30.0443 168.819 30.1705C168.692 30.2967 168.605 30.4423 168.537 30.5976C168.518 30.6364 168.498 30.6752 168.489 30.7141C168.43 30.8694 168.391 31.0344 168.391 31.1994C168.391 31.3256 168.411 31.4518 168.44 31.578L171.673 43.6539C171.847 44.3042 172.439 44.7314 173.08 44.7314C173.206 44.7314 173.332 44.712 173.459 44.6828C174.235 44.479 174.691 43.6733 174.488 42.8967L172.663 36.0628L181.409 44.809C181.69 45.0905 182.069 45.2361 182.438 45.2361C182.807 45.2361 183.185 45.0905 183.467 44.809C184.04 44.2363 184.04 43.3141 183.467 42.7511L174.721 34.0048L181.545 35.8395C182.321 36.0531 183.117 35.5871 183.331 34.8105C183.535 34.034 183.079 33.238 182.302 33.0244L170.226 29.7919C170.11 29.7628 169.983 29.7433 169.857 29.7433Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M169.857 24.7927C170.663 24.7927 171.313 24.1423 171.313 23.3366V19.1819C171.313 18.3762 170.663 17.7258 169.857 17.7258C169.052 17.7258 168.401 18.3762 168.401 19.1819V23.3366C168.401 24.1423 169.052 24.7927 169.857 24.7927Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M163.45 31.1994C163.45 30.3937 162.8 29.7433 161.994 29.7433H157.84C157.034 29.7433 156.384 30.3937 156.384 31.1994C156.384 32.0051 157.034 32.6555 157.84 32.6555H161.994C162.79 32.6555 163.45 32.0051 163.45 31.1994Z"
      fill="url(#paint4_linear)"
    />
    <path
      d="M165.324 37.7907C165.897 37.218 165.897 36.2958 165.324 35.7328C164.751 35.16 163.829 35.16 163.266 35.7328L160.325 38.6741C159.752 39.2468 159.752 40.169 160.325 40.732C160.606 41.0135 160.985 41.1591 161.354 41.1591C161.723 41.1591 162.101 41.0135 162.383 40.732L165.324 37.7907Z"
      fill="url(#paint5_linear)"
    />
    <path
      d="M174.381 26.6662C174.662 26.9477 175.041 27.0933 175.41 27.0933C175.779 27.0933 176.157 26.9477 176.439 26.6662L179.38 23.7249C179.953 23.1521 179.953 22.2299 179.38 21.6669C178.807 21.0942 177.885 21.0942 177.322 21.6669L174.381 24.6082C173.818 25.1809 173.818 26.1031 174.381 26.6662Z"
      fill="url(#paint6_linear)"
    />
    <path
      d="M163.266 26.6662C163.547 26.9477 163.926 27.0933 164.295 27.0933C164.664 27.0933 165.042 26.9477 165.324 26.6662C165.897 26.0934 165.897 25.1712 165.324 24.6082L162.383 21.6669C161.81 21.0942 160.888 21.0942 160.325 21.6669C159.752 22.2396 159.752 23.1618 160.325 23.7249L163.266 26.6662Z"
      fill="url(#paint7_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="174.954"
        y1="8.83392"
        x2="173.34"
        y2="14.1702"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9B84BF" />
        <stop offset={1} stopColor="#7493CB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="215.268"
        y1={2}
        x2="154.874"
        y2="87.181"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9B84BF" />
        <stop offset={1} stopColor="#7493CB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="183.896"
        y1="29.7433"
        x2="168.404"
        y2="45.2483"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9B84BF" />
        <stop offset={1} stopColor="#7493CB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="171.313"
        y1="17.7258"
        x2="166.334"
        y2="19.7775"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9B84BF" />
        <stop offset={1} stopColor="#7493CB" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="163.45"
        y1="29.7433"
        x2="161.399"
        y2="34.7222"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9B84BF" />
        <stop offset={1} stopColor="#7493CB" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="165.753"
        y1="35.3032"
        x2="159.898"
        y2="41.1616"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9B84BF" />
        <stop offset={1} stopColor="#7493CB" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="179.81"
        y1="21.2374"
        x2="173.954"
        y2="27.0884"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9B84BF" />
        <stop offset={1} stopColor="#7493CB" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="165.753"
        y1="21.2374"
        x2="159.898"
        y2="27.0957"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9B84BF" />
        <stop offset={1} stopColor="#7493CB" />
      </linearGradient>
    </defs>
  </svg>
)

export default MobileWarning
