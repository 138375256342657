import { Bar } from 'recharts'
import Chart from 'vibe/insights/chart'
import { getRetentionPercentage } from 'vibe/insights/chart-helpers'

type Data = {
  totalMembers: number
  retainedMembers: number
}

type Props = { data: Data[] }

export default function ConversationFrequencyChart({ data: rawData }: Props) {
  const data = rawData.map(({ totalMembers, retainedMembers, ...values }) => ({
    ...values,
    baseValue: totalMembers,
    activeValue: retainedMembers,
  }))

  return (
    <Chart data={data}>
      <Chart.Header tooltip="Retenção (Total)">Retenção (Total)</Chart.Header>

      <div className="mt-2 mb-6 ml-[16%] flex w-[80%] sm:ml-[12%] sm:w-[83%] md:ml-[10%] md:w-[85%] lg:ml-[8%] lg:w-[87%]">
        {data.map(({ activeValue, baseValue }, index) => {
          const retentionPercentage = getRetentionPercentage(
            activeValue,
            baseValue,
          )
          return (
            <div key={index} className="flex flex-1 justify-center">
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 font-bold">
                {retentionPercentage}%
              </div>
            </div>
          )
        })}
      </div>

      <Chart.Bars
        tooltip={({ activeValue, baseValue }) => {
          const retentionPercentage = getRetentionPercentage(
            activeValue,
            baseValue,
          )
          return (
            <>
              <div>Total de Colaboradores: {baseValue}</div>
              <div>Colaboradores Retidos: {activeValue}</div>
              <div>
                Porcentagem de Colaboradores Retidos: {retentionPercentage}%
              </div>
            </>
          )
        }}
      >
        <>
          <Bar dataKey="baseValue" shape={Chart.BaseShape} stackId="a" />
          <Bar dataKey="activeValue" shape={Chart.ActiveShape} stackId="a" />
        </>
      </Chart.Bars>
    </Chart>
  )
}
