import * as React from 'react'
import { useCroods } from 'croods'

import { usePendingSolicitation } from 'portal/pendingSolicitations/context'
import DynamicInputs, { buildSchema } from 'portal/dynamic-input'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { useForm } from 'shared/utils/useForm'
import Button from 'shared/forms/SubmitButton'
import Modal from 'shared/ui/Modal'
import Icon from 'shared/ui/icon'

import SolicitationItem from './SolicitationItem'
import { renderIf } from 'shared/helpers'

const getStatusData = ({
  type,
  status,
  name,
}: {
  type: 'name' | 'verb' | 'customPath'
  status: 'pending_approval' | 'pending_execution'
  name?: string
}) => {
  const statusType = {
    name: {
      pending_approval: 'Aprovação',
      pending_execution: 'Execução',
      canceled: 'Cancelada',
    },
    verb: {
      pending_approval: 'aprovada',
      pending_execution: 'executada',
    },
    customPath: {
      pending_approval: 'approve',
      pending_execution: 'execute',
    },
  }

  const data = {
    name: `${statusType[type][status]}: ${name}`,
    verb: statusType[type][status],
    customPath: statusType[type][status],
  }

  return data[type]
}

const parseRequiredFields = (inputs: any[]) => {
  if (!inputs?.length) return []
  return inputs
    .filter((item) => item.isRequired === true)
    .map((item) => item.id)
}

const parseValues = (values: {}) => {
  const answersAttributes = Object.entries(values).map((value) => ({
    inputId: value[0],
    value: value[1],
  }))
  return { answersAttributes }
}

const ViewSolicitationModal = ({
  open,
  onClose,
  id,
  solicitationItem,
  handleReject,
}: {
  open: boolean
  onClose: () => void
  id: number
  solicitationItem: any
  handleReject: () => void
}) => {
  const { status, createdAt, requester, questionsUnfilled, solicitation } =
    solicitationItem
  const { resetFilter, fetchPendingSolicitations } = usePendingSolicitation()
  const { snackbar } = useSnackbar()
  const [{ saving }, { save }] = useCroods({
    name: 'solicitations',
    stateId: `update-${id}`,
    customPath: `/workflow/solicitations/${id}?solicitation_action=${getStatusData(
      {
        type: 'customPath',
        status,
      },
    )}`,
    afterSuccess: () => {
      snackbar({
        message: `Solicitação ${getStatusData({
          type: 'verb',
          status,
        })} com sucesso!`,
        type: 'success',
      })
      onClose()
      fetchPendingSolicitations()
      resetFilter()
    },
    afterFailure: () =>
      snackbar({
        message: 'Houve um erro ao reprovar a solicitação',
        type: 'error',
      }),
  })

  const { hasErrors } = useForm(
    {},
    {
      // @ts-expect-error
      requiredFields: [...parseRequiredFields(questionsUnfilled)],
    },
  )

  const handleSubmit = async (data: any) => {
    save({ method: 'PUT' })(parseValues(data))
  }

  return (
    <Modal.FormWrapper
      open={open}
      loading={saving}
      onClose={onClose}
      schema={buildSchema(questionsUnfilled)}
      onConfirm={handleSubmit}
    >
      <Modal.Header>
        {getStatusData({ type: 'name', status, name: solicitation.name })}
      </Modal.Header>
      <Modal.Content>
        <SolicitationItem {...{ createdAt, requester, solicitation }} />
        <DynamicInputs inputs={questionsUnfilled} />
      </Modal.Content>
      <Modal.Actions className="gap-4" cancelLabel="voltar">
        {renderIf(
          status === 'pending_approval' || status === 'pending_execution',
          <>
            <Button
              title="reprovar"
              variant="outlined"
              kind="danger"
              type="button"
              onClick={handleReject}
            >
              <Icon icon="thumbs-down" className="fill-transparent text-blue" />
            </Button>
            <Modal.Confirm
              disabled={hasErrors}
              title={status === 'pending_approval' ? 'aprovar' : 'executar'}
              variant="contained"
              kind="success"
              type="submit"
            >
              <Icon icon="thumbs-up" className="fill-transparent text-white" />
            </Modal.Confirm>
          </>,
        )}
      </Modal.Actions>
    </Modal.FormWrapper>
  )
}

export default ViewSolicitationModal
