import Period from 'shared/ui/Period'

function CycleCard({ currentCycle }: any) {
  const {
    startsAt,
    finishesAt,
    evaluationWindowOpensAt,
    evaluationWindowClosesAt,
    durationInMonths,
  } = currentCycle

  return (
    <div className="flex flex-col gap-4 divide-y">
      <div className="flex flex-col gap-1 pt-4">
        <h5 className="text-gray-dark">Periodicidade de avaliações</h5>
        <Period durationInMonths={durationInMonths} show />
      </div>
      <div className="flex flex-col gap-1 pt-4">
        <h5 className="text-gray-dark">Ciclo vigente</h5>
        <Period startsAt={startsAt} finishesAt={finishesAt} show />
      </div>
      <div className="flex flex-col gap-1 pt-4">
        <h5 className="text-gray-dark">
          Período de avaliações do ciclo vigente
        </h5>
        <Period
          startsAt={evaluationWindowOpensAt}
          finishesAt={evaluationWindowClosesAt}
          longFormat
          show
        />
      </div>
    </div>
  )
}

export default CycleCard
