import { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { useForm } from 'shared/utils/useForm'
import Step from 'shared/ui/form-step'
import Icon from 'shared/ui/icon'
import ApproverFields from './ApproverFields'

const ApproversForm = ({
  handleSubmit,
  submitting = false,
  error,
  visible,
  handleNavigate,
  filledValues,
  activeStep,
}: any) => {
  const initialState = {
    approvalsAttributes: filledValues?.approvalsAttributes || [],
  }

  const { formState, hasErrors } = useForm(initialState, {
    requiredFields: [],
  })

  useEffect(() => {
    if (filledValues) {
      for (const [attribute, value] of Object.entries(filledValues)) {
        formState.setField(attribute as keyof typeof initialState, value)
      }
    }
  }, [formState, filledValues])

  const addApproval = () => {
    formState.setField('approvalsAttributes', [
      ...formState.values.approvalsAttributes,
      {},
    ])
  }

  const updateApproval = (index: number, newApprovalAttributes: any) => {
    const approvalsAttributes = [...formState.values.approvalsAttributes]
    approvalsAttributes.splice(index, 1, newApprovalAttributes)

    formState.setField('approvalsAttributes', approvalsAttributes)
  }

  const removeApproval = (index: number) => {
    const approvalsAttributes = [...formState.values.approvalsAttributes]
    approvalsAttributes.splice(index, 1)

    formState.setField('approvalsAttributes', approvalsAttributes)
  }

  const onSubmit: React.FormEventHandler = (e) => {
    e && e.preventDefault && e.preventDefault()
    handleSubmit && handleSubmit(formState.values)
  }

  return (
    <Step
      handleSubmit={onSubmit}
      error={error}
      disableButton={hasErrors}
      submitting={submitting}
      visible={visible}
      backButtonLabel="Voltar"
      handleNavigate={handleNavigate(activeStep - 1)}
    >
      {formState.values.approvalsAttributes.map(
        (approvalAttributes: any, index: number) => {
          const level = `Nível ${index + 1}`
          return (
            <div key={index} title={`Remover ${level}`}>
              <div className="relative">
                <div className="absolute flex w-full justify-end">
                  <button type="button" onClick={() => removeApproval(index)}>
                    <Icon
                      icon="delete-forever-outline"
                      size="lg"
                      className="p-1 text-gray-dark hover:text-red" />
                  </button>
                </div>
              </div>

              <ApproverFields
                key={index}
                title={level}
                filledValues={approvalAttributes}
                allApprovalAttributes={formState.values.approvalsAttributes}
                onChange={(newApprovalAttributes: any) => updateApproval(index, newApprovalAttributes)} />
            </div>
          )
        },
      )}

      <div className="flex justify-center">
        <Button
          title="Adicionar Nível de Aprovação"
          color="primary"
          variant="contained"
          size="small"
          startIcon={<Icon icon="add-circle-outline" className="h-5 w-5" />}
          style={{ marginTop: 24, marginBottom: 40, height: 'auto' }}
          onClick={addApproval}
        >
          Adicionar Nível de Aprovação
        </Button>
      </div>
    </Step>
  )
}

export default ApproversForm
