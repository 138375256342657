import React from 'react'
import HistoryIcon from '@material-ui/icons/History'

type Props = {
  handleUncomplete: () => void
}

export default function Complete({ handleUncomplete }: Props) {
  const handleClick = () => {
    handleUncomplete()
  }

  return (
    <button
      aria-label="uncomplete"
      type="button"
      onClick={handleClick}
      className="p-4 hover:text-green"
    >
      <HistoryIcon />
    </button>
  )
}
