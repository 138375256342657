import { renderIf } from 'shared/helpers'

type Props = {
  id: number
  date: string
  estimatedTime: string
  timePerformed: string
  addedTimes: string
  reason: string
  observation: string
}

const SolicitationDetails = ({
  id,
  date,
  estimatedTime,
  timePerformed,
  addedTimes,
  reason,
  observation,
}: Props) => (
  <article className="pl-2">
    <section aria-label="id">
      <h4 className="p-0 m-0 text-base font-bold leading-6 text-gray-darkest">
        Número da solicitação
      </h4>
      <span className="flex flex-row items-center gap-4 p-0 m-0 leading-4 tracking-wider uppercase text-gray-darker text-base">
        {id}
      </span>
    </section>

    <section className="pt-5" aria-label="date">
      <h4 className="p-0 m-0 text-base font-bold leading-6 text-gray-darkest">
        Data
      </h4>
      <span className="flex flex-row items-center gap-4 p-0 m-0 leading-4 tracking-wider uppercase text-gray-darker text-base">
        {date}
      </span>
    </section>

    {renderIf(
      Boolean(estimatedTime) || Boolean(timePerformed),
      <section
        aria-label="times"
        className="grid sm:grid-cols-3 grid-cols-1 gap-4 pt-5"
      >
        {renderIf(
          Boolean(estimatedTime),
          <div aria-label="estimated-times">
            <h4 className="p-0 m-0 text-base font-bold leading-6 text-gray-darkest">
              Horário Previsto
            </h4>
            <span className="flex flex-row items-center gap-4 p-0 m-0 leading-4 tracking-wider uppercase text-gray-darker text-base">
              {estimatedTime}
            </span>
          </div>,
        )}

        {renderIf(
          Boolean(timePerformed),
          <div aria-label="performed-times">
            <h4 className="p-0 m-0 text-base font-bold leading-6 text-gray-darkest">
              Horário Realizado
            </h4>
            <span className="flex flex-row items-center gap-4 p-0 m-0 leading-4 tracking-wider uppercase text-gray-darker text-base">
              {timePerformed}
            </span>
          </div>,
        )}
      </section>,
    )}

    {renderIf(
      Boolean(addedTimes),
      <section className="pt-5" aria-label="added-times">
        <h4 className="p-0 m-0 text-base font-bold leading-6 text-gray-darkest">
          Marcações Adicionadas
        </h4>
        <span className="m- flex flex-row items-center gap-4 p-0 leading-4 tracking-wider uppercase text-gray-darker text-base">
          {addedTimes}
        </span>
      </section>,
    )}

    {renderIf(
      Boolean(reason),
      <section className="pt-5" aria-label="reason">
        <h4 className="p-0 m-0 text-base font-bold leading-6 text-gray-darkest">
          Motivo
        </h4>
        <span className="m- flex flex-row items-center gap-4 p-0 leading-4 tracking-wider uppercase text-gray-darker text-base">
          {reason}
        </span>
      </section>,
    )}

    {renderIf(
      Boolean(observation),
      <section className="pt-5" aria-label="observation">
        <h4 className="p-0 m-0 text-base font-bold leading-6 text-gray-darkest">
          Observação
        </h4>
        <span className="m- flex flex-row items-center gap-4 p-0 leading-4 tracking-wider uppercase text-gray-darker text-base">
          {observation}
        </span>
      </section>,
    )}
  </article>
)

export default SolicitationDetails
