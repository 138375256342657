import React, { useContext } from 'react'

import SubmitButton from 'shared/forms/SubmitButton'
import ModalContext from './Context'

type Props = {
  kind?: 'danger' | 'success' | 'default'
  variant?: any
} & JSX.IntrinsicElements['button']

const Confirm = ({ children, kind = 'default', ...props }: Props) => {
  const { loading, disabled, handleConfirm, isForm } = useContext(ModalContext)
  return (
    <SubmitButton
      kind={kind}
      loading={loading}
      disabled={disabled}
      onClick={isForm ? undefined : handleConfirm}
      color="secondary"
      {...props}
    >
      {children}
    </SubmitButton>
  )
}

export default Confirm
