import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.bubble.css'
import { cx } from 'shared/helpers'

type Props = Partial<ReactQuill> & {
  helper?: string
  name: string
  value?: string
  label?: string
  error?: string
  rows?: number
  style?: React.CSSProperties
  onBlur?: (s: string) => void
  onChange?: (s: string) => void
  onKeyUp?: (s: string) => void
  className?: string
}
function RichText({
  helper = '',
  onChange = () => {},
  onKeyUp = () => {},
  onBlur,
  value,
  label,
  name,
  error = '',
  rows = 5,
  className,
  ...props
}: Props) {
  const [focused, setFocused] = useState(false)

  return (
    <div className={cx('!mb-6 flex w-full flex-col gap-2', className)}>
      <label
        htmlFor={name}
        className={cx(
          'text-base text-gray-darkest',
          focused && 'text-blue',
          error && '!text-red-dark',
        )}
      >
        {label}
      </label>
      <div data-testid="rich-text">
        <ReactQuill
          style={{
            height: `calc(${rows} * 1.5rem)`,
          }}
          className={cx(
            error && '!border-red-dark !text-red-dark',
            focused && 'border-blue text-blue',
            'w-full rounded border py-1 text-gray-dark',
          )}
          id={name}
          theme="bubble"
          defaultValue={value}
          onBlur={(_, __, editor) => {
            setFocused(false)
            onBlur?.(editor.getHTML())
          }}
          onFocus={() => setFocused(true)}
          onChange={(_, __, ___, editor) => {
            onChange?.(editor.getHTML())
          }}
          onKeyUp={(event) => {
            onKeyUp(event.target.innerHTML)
          }}
          modules={{
            toolbar: [
              ['bold', 'italic', { header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link'],
            ],
          }}
          {...props}
        />
      </div>
      <span
        className={cx(
          'text-sm',
          error && '!text-red-dark',
          focused && 'text-blue',
        )}
      >
        {error || helper}
      </span>
    </div>
  )
}

export default RichText
