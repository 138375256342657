import React from 'react'
import { useNavigate } from 'react-router-dom'

import { formatDateUnits } from 'shared/helpers'
import { typeToIcon, typeToName } from 'shared/utils/conversationLookup'
import type { TeamMemberType, UserConversations } from 'types'

type Props = {
  conversation: UserConversations['conversations'][0]
  id: number
  path: TeamMemberType
}

export const getTypeToName = (
  path: TeamMemberType,
  kind: UserConversations['conversations'][0]['kind'],
) => {
  if (kind === 'upward_evaluation') {
    return path === 'leaders' ? 'Avaliação enviada' : 'Avaliação recebida'
  }
  return typeToName(kind)
}

export default function Button({ conversation, id, path }: Props) {
  const { kind, date, id: conversationId } = conversation
  const navigate = useNavigate()
  const navigationPath = `/conversas/historico-de-conversas/${id}?type=${path}#${conversationId}`

  return (
    <button
      type="button"
      className="flex h-32 w-full max-w-[8rem] flex-col justify-between overflow-hidden rounded-xl border border-gray-lightest hover:bg-gray-50"
      onClick={() => navigate(navigationPath)}
    >
      <div className="flex h-20 w-full items-center justify-center">
        <div className="p-4 text-center">
          {typeToIcon(kind)}
          <h6 className="mt-2 font-bold">{getTypeToName(path, kind)}</h6>
        </div>
      </div>
      <div className="w-full bg-gray-lightest p-4 text-center">
        <p className="text-xs text-gray">{formatDateUnits(date)}</p>
      </div>
    </button>
  )
}
