import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import type { AutocompleteChangeDetails } from '@material-ui/lab/Autocomplete'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import type { Role } from 'performance/evaluations/membersEvaluation/types'

type Props = {
  allRoles: Role[]
  roles: Role[]
  handleAddNewRole: (
    value: string,
    type: 'primaryRole' | 'secondaryRole',
  ) => void
  renderOption: (option: Role) => React.ReactNode
  setRoles: (secondaryRoles: Role[]) => void
  getDisabledRoles: (role: Role) => boolean
}

const filter = createFilterOptions<Role>()

export default function SecondaryRole({
  allRoles,
  roles,
  setRoles,
  handleAddNewRole,
  renderOption,
  getDisabledRoles,
}: Props) {
  const handleChange = (
    _: React.ChangeEvent<{}>,
    value: Role[],
    __: any,
    details: AutocompleteChangeDetails<Role> | undefined,
  ) => {
    if (details?.option.inputValue) {
      handleAddNewRole(details?.option.inputValue, 'secondaryRole')
    } else {
      setRoles(value)
    }
  }

  const getOptionSelected = (option: Role, value: Role) =>
    option.id === value.id

  const handleFilter = (options: any, params: any) => {
    const filtered = filter(options, params)

    if (params.inputValue !== '') {
      filtered.push({
        id: 0,
        inputValue: params.inputValue,
        name: params.inputValue,
      })
    }

    return filtered
  }

  const noOptionsText =
    roles.length < 7 ? 'Papel não encontrado' : 'Limite de papéis atingido'

  return (
    <FormControl fullWidth={true} className="max-w-lg">
      <Autocomplete
        disablePortal
        multiple
        options={roles.length < 7 ? allRoles : []}
        noOptionsText={noOptionsText}
        disableClearable
        onChange={handleChange}
        value={roles}
        getOptionSelected={getOptionSelected}
        getOptionLabel={(option) => option.name}
        renderOption={renderOption}
        filterOptions={handleFilter}
        getOptionDisabled={getDisabledRoles}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                maxLength: 120,
              },
            }}
          />
        )}
        data-testid="secondaryRole"
        classes={{ popper: 'mt-10' }}
      />
    </FormControl>
  )
}
