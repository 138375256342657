import React from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'theme'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(1),
    backgroundColor: colors.greyDark,
    color: colors.white,
    borderRadius: '8px',
    margin: '-5px 0 0 5px',
    alignItems: 'stretch',
    width: 'full',
  },
  arrow: {
    color: colors.greyDark,
  },
}))(Tooltip)

type Props = {
  children: React.ReactElement
  text: string | undefined
}

const CustomizedToolTip = ({ children, text }: Props) => (
  <LightTooltip
    title={
      <Typography variant="body2" style={{ fontWeight: 600 }} component="div">
        {text}
      </Typography>
    }
    enterTouchDelay={100}
    placement="top"
    arrow={true}
  >
    {children}
  </LightTooltip>
)

export default CustomizedToolTip
