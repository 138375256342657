import useEvaluationColors from 'shared/utils/useEvaluationColors'
import Icon from './Icon'

type Props = {
  percentageScore: number
  previousPercentageScore?: number
}

export const getColor = (percentageScore: number) => {
  if (percentageScore <= 54) return 1
  if (percentageScore <= 69) return 2
  if (percentageScore <= 84) return 3
  return 4
}

const PercentageScoreCard = ({
  percentageScore,
  previousPercentageScore,
}: Props) => {
  const { scoreColors, performanceColors } = useEvaluationColors([
    'teamPercentage',
    getColor(percentageScore),
  ])

  return (
    <div
      className="flex items-center justify-center w-full mt-2 md:mt-6 font-bold grow rounded-2xl"
      data-testid="PercentageScoreCard"
      style={{
        color: scoreColors,
        backgroundColor: performanceColors,
      }}
    >
      <h2>{percentageScore}%</h2>
      <Icon
        percentageScore={percentageScore}
        previousPercentageScore={previousPercentageScore}
      />
    </div>
  )
}

export default PercentageScoreCard
