import { Fetch } from 'croods'
import type { PageProps } from 'types'

import FeedbackMessage from 'shared/ui/FeedbackMessage'
import Skeleton from 'shared/ui/skeleton'

import type { FolderType, FileType } from './types'
import FolderSection from './FolderSection'
import FileItem from './FileItem'

const showDirectory = (directory: FolderType) => {
  return (
    <FolderSection key={directory.name} folderName={directory.name}>
      {directory.content.map((contentItem) => {
        if (contentItem.kind === 'directory')
          return showDirectory(contentItem as FolderType)
        else
          return (
            <FileItem
              key={(contentItem as FileType).name}
              file={contentItem as FileType}
            />
          )
      })}
    </FolderSection>
  )
}

const MyDocuments: PageProps = ({ pageTitle }) => (
  <>
    <h3 className="mb-4">{pageTitle}</h3>
    <Fetch
      name="myDocumentsList"
      path="/workflow/files"
      render={(docs: FolderType[] | FileType[]) => (
        <div className="card flex w-full flex-col overflow-visible p-4 pt-0">
          {docs.map((doc) => {
            if (doc.kind === 'directory')
              return showDirectory(doc as FolderType)

            return (
              <FileItem key={(doc as FileType).name} file={doc as FileType} />
            )
          })}
        </div>
      )}
      renderError={(error) =>
        error === 'not allowed to index? this Symbol' ? (
          <FeedbackMessage
            message=""
            serverMessage="Documentos não habilitados para esta organização"
          />
        ) : (
          <FeedbackMessage
            message="Erro ao carregar a lista de pastas e arquivos"
            serverMessage={error}
          />
        )
      }
      renderEmpty={() => (
        <FeedbackMessage
          message="Você ainda não possui nenhum documento"
          type="warning"
          serverMessage=""
        />
      )}
      renderLoading={() => <Skeleton className="!h-96 w-full p-4" />}
    />
  </>
)

export default MyDocuments
