import { cx } from 'shared/helpers'

export default function Cell({
  children,
  className,
  ...props
}: JSX.IntrinsicElements['td']) {
  return (
    <td className={cx('p-3', className)} {...props}>
      {children}
    </td>
  )
}
