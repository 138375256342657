import * as React from 'react'
import { useCroods } from 'croods'
import Modal from 'shared/ui/Modal'
import Input from 'shared/formsv2/input'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import * as z from 'zod'

const textInformation = {
  create: {
    title: 'Criar papel',
    button: 'Criar',
    label: 'Nome do papel',
  },
  edit: {
    title: 'Editar papel',
    button: 'Editar',
    label: 'Novo nome',
  },
  delete: {
    title: 'Remover papel',
    button: 'Remover',
  },
}

type Props = {
  dialogType: 'create' | 'edit' | 'delete' | null
  setDialogType: React.Dispatch<
    React.SetStateAction<'create' | 'edit' | 'delete' | null>
  >
  roleId?: string | number
  roleName?: any
}

const Dialog = ({ dialogType, setDialogType, roleId, roleName }: Props) => {
  const { snackbar } = useSnackbar()

  const [{ saving }, { save, destroy }] = useCroods({
    name: 'organizationRoles',
    afterFailure: () => {
      snackbar({
        message: 'Não foi possível completar sua solicitação, tente novamente',
        type: 'error',
      })
    },
  })

  if (!dialogType) return null

  const handleSubmit = (data: any) => {
    switch (dialogType) {
      case 'create':
        return save({
          path: '/organization_roles',
          afterSuccess: () => {
            snackbar({ message: 'Papel criado com sucesso', type: 'success' })
          },
        })(data)
      case 'edit':
        return save({
          method: 'put',
          path: '/organization_roles',
          id: roleId,
          afterSuccess: () => {
            snackbar({ message: 'Papel editado com sucesso', type: 'success' })
          },
        })(data)
      case 'delete':
        return destroy({
          method: 'delete',
          path: '/organization_roles',
          id: roleId,
          afterSuccess: () => {
            snackbar({ message: 'Papel removido com sucesso', type: 'success' })
          },
        })()
    }
  }

  return (
    <Modal.FormWrapper
      open={Boolean(dialogType)}
      schema={
        dialogType === 'delete'
          ? z.any()
          : z.object({ name: z.string().nonempty('Campo obrigatório') })
      }
      onClose={() => {
        setDialogType(null)
      }}
      loading={saving}
      onConfirm={handleSubmit}
    >
      <Modal.Header>
        <Modal.Title>
          {textInformation[dialogType].title}
          {dialogType === 'edit' && ` ${roleName}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Content>
        {dialogType === 'delete' ? (
          <p>Remover {roleName}</p>
        ) : (
          <Input
            maxLength={120}
            required
            defaultValue={roleName}
            label={textInformation[dialogType].label}
            name="name"
          />
        )}
      </Modal.Content>

      <Modal.Actions>
        <Modal.Confirm>{textInformation[dialogType].button}</Modal.Confirm>
      </Modal.Actions>
    </Modal.FormWrapper>
  )
}

export default Dialog
