import React from 'react'
import { Fetch } from 'croods'
import Skeleton from 'shared/ui/skeleton'
import FeedbackMessage from 'shared/ui/FeedbackMessage'

import Card from './Card'

const MembersEvaluationNotice = ({ isDraft = false }) => (
  <div>
    <h3 className="mb-4">Talentos</h3>

    <Fetch
      name="membersToEvaluate"
      path="team/members_to_evaluate"
      render={(allTeams) => <Card allTeams={allTeams} isDraft={isDraft} />}
      renderLoading={() => <Skeleton />}
      renderError={() => <FeedbackMessage />}
      renderEmpty={() => (
        <FeedbackMessage
          type="warning"
          message="Nenhum membro para avaliar"
          serverMessage=""
        />
      )}
    />
  </div>
)

export default MembersEvaluationNotice
