import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { cx } from 'shared/helpers'
import InputHint from './input-hint'
import Label from './label'
import { masks } from './masks'

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name'> & {
  name: string
  label?: string
  hint?: string
  mask?: keyof typeof masks
}
function Input({
  name,
  type = 'text',
  id = name,
  hint,
  mask,
  label,
  required,
  className,
  autoComplete = 'off',
  ...props
}: Props) {
  let { register, formState } = useFormContext()
  let { onChange, ...formProps } = register(name)
  let hasErrors = Boolean(formState.errors?.[name]?.message)

  function handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
    if (mask) {
      ev.target.value = masks[mask](ev.target.value)
    }
    onChange(ev)
    props.onChange?.(ev)
  }

  let hintId = `input-${id}-hint`
  return (
    <>
      <Label aria-required={required} htmlFor={id}>
        {label}
      </Label>
      <input
        className={cx(
          'input',
          hasErrors && '!border-red-dark !text-red-dark',
          className,
        )}
        type={type}
        {...props}
        {...formProps}
        onChange={handleChange}
        id={id}
        aria-invalid={hasErrors ? 'true' : 'false'}
        aria-describedby={hasErrors ? hintId : undefined}
        autoComplete={autoComplete}
      />
      <InputHint id={hintId} isError={hasErrors}>
        {formState.errors?.[name]?.message ?? hint}
      </InputHint>
    </>
  )
}

export type { Props as InputProps }
export default Input
