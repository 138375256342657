const DATE_REGEX =
  /^(([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)(19|20)\d{2})?$/
const ISO_DATE_REGEX =
  /^((19|20)\d{2})(\-)(((0)[0-9])|((1)[0-2]))(\-)([0-2][0-9]|(3)[0-1])?$/
const NUMBER_INTEGER_REGEX = /^\d*$/
const NUMBER_COMMA_REGEX = /^(\d{1,3}(,\d{3})*(\.\d+)?)?$/
const NUMBER_PERIOD_REGEX = /^(\d{1,3}(\.\d{3})*(,\d+)?)?$/

export {
  DATE_REGEX,
  ISO_DATE_REGEX,
  NUMBER_COMMA_REGEX,
  NUMBER_PERIOD_REGEX,
  NUMBER_INTEGER_REGEX,
}
