import React from 'react'
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core'
import type { LocalizationMap } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import pt_PT from '@react-pdf-viewer/locales/lib/pt_PT.json'

import Icon from 'shared/ui/icon'
import Modal from 'shared/ui/Modal'

import type { FileType } from './types'

type Props = {
  file: FileType
}

type PdfViewerProps = {
  isOpen: boolean
  onClose: () => void
  file: FileType
}

const PDFPreview = ({ isOpen, onClose, file }: PdfViewerProps) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
      <Modal.Wrapper
        className="!min-w-[80vw] lg:!min-w-[50vw]"
        open={isOpen}
        onClose={onClose}
      >
        <Modal.Header className="!bg-gray-200 py-3 px-3 md:px-6 md:py-6">
          {file.name}
        </Modal.Header>
        <Modal.Content className="h-[75vh] sm:h-[65vh] md:h-[75vh] w-full overflow-x-hidden overflow-y-scroll !bg-gray-100 px-0 py-0">
          <Viewer
            localization={pt_PT as unknown as LocalizationMap}
            plugins={[defaultLayoutPluginInstance]}
            fileUrl={file.url}
            defaultScale={SpecialZoomLevel.PageWidth}
          />
        </Modal.Content>

        <Modal.Actions
          className="!bg-gray-200 py-3 px-3 md:px-6 md:py-6"
          cancelLabel="Fechar"
        />
      </Modal.Wrapper>
    </Worker>
  )
}

export default function FileItem({ file }: Props) {
  const [openModal, setOpenModal] = React.useState(false)

  return (
    <>
      <div className={'mt-3 ml-7 flex justify-start justify-items-center '}>
        <a
          href={file.url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex mr-2 text-blue hover:cursor-pointer hover:underline"
        >
          <Icon icon="file-download-outline" />
          <div className="flex flex-col justify-center">
            <h5 className="ml-1">
              {file.name.replace(`.${file.extension}`, '')}
            </h5>
          </div>
        </a>
        {file.extension.toLowerCase() === 'pdf' && (
          <Icon
            title="Visualizar arquivo"
            onClick={() => setOpenModal(true)}
            className="transition-colors duration-300 text-blue opacity-70 hover:cursor-pointer hover:opacity-100"
            icon="visibility"
          />
        )}
      </div>
      <PDFPreview
        file={file}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  )
}
