import React from 'react'

// @ts-ignore
import { useCurrentUser } from 'croods-auth'
import { getFullName } from 'shared/helpers'
import Typography from '@material-ui/core/Typography'
import Avatar from 'shared/ui/Avatar'
import type { User } from 'types/index'
import Modal from './Modal'

type Props = {
  cta?: string
  enabled?: boolean
  kind?: 'danger' | 'success' | 'default'
  loading?: boolean
  onClose: () => void
  onConfirm: () => Promise<Record<string, unknown>>
  title: string
  user?: User | null
}
type CurrentUser = User | undefined

const UserModal: React.FC<Props> = ({
  onClose,
  user,
  kind = 'default',
  title,
  children,
  loading,
  enabled,
  onConfirm,
  cta = 'Salvar',
}) => {
  const [{ currentUser }, setCurrentUser] = useCurrentUser()

  const handleConfirm = async () => {
    const result = await onConfirm()
    if ((currentUser as CurrentUser)?.id === result.id) {
      setCurrentUser(result)
    }
  }

  return (
    <Modal.Wrapper
      enabled={enabled}
      loading={loading}
      open={Boolean(user)}
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        {user ? (
          <div className="flex items-center gap-2">
            <Avatar user={user} />
            <div>
              <Typography variant="subtitle2">{getFullName(user)}</Typography>
              <Typography variant="body2">{user?.role}</Typography>
            </div>
          </div>
        ) : null}
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        <Modal.Confirm kind={kind}>{cta}</Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default UserModal
