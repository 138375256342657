import * as React from 'react'
import { Menu } from '@headlessui/react'

const DropdownMenu = ({
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <Menu.Item>
    <button
      type="button"
      className="block w-full px-4 py-2 text-left text-sm font-semibold text-gray-400 hover:bg-gray-100 disabled:text-gray-300"
      {...props}
    >
      {children}
    </button>
  </Menu.Item>
)

export default DropdownMenu
