import * as React from 'react'
import * as z from 'zod'
import { useFormContext } from 'react-hook-form'

import Modal from 'shared/ui/Modal'

import Input from 'shared/formsv2/input'

type Props = {
  isOpen: boolean
  handleDelete: (formData: unknown) => () => void
  loading: boolean
  onClose: () => void
}

const JustificationModal = ({
  handleDelete,
  isOpen,
  loading,
  onClose,
}: Props) => {
  const Action = () => {
    const { watch } = useFormContext()
    const formData = watch()

    return (
      <Modal.Actions className="gap-4" cancelLabel="voltar">
        <button
          className="bt bt-contained w-[120px]"
          onClick={handleDelete(formData)}
          type="submit"
        >
          Reprovar
        </button>
      </Modal.Actions>
    )
  }

  return (
    <Modal.FormWrapper
      className="!min-w-[90vw] md:!min-w-[40vw]"
      loading={loading}
      onClose={onClose}
      open={isOpen}
      schema={z.object({ justification: z.string() })}
    >
      <Modal.Header>Atenção</Modal.Header>

      <Modal.Content>
        <Input
          label="Em caso de reprovação, é recomendado adicionar uma justificativa."
          name="justification"
          placeholder="Ex.: Falta de dados para comprovação do pedido."
        />
      </Modal.Content>

      <Action />
    </Modal.FormWrapper>
  )
}

export { JustificationModal }
