import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useResetPassword } from 'croods-auth'

import FormError from 'shared/forms/FormError'
import Input from './CustomInput'

import AuthForm from './AuthForm'

export default () => {
  const navigate = useNavigate()
  const [{ reseting, error, ...props }] = useResetPassword({
    afterSuccess: () => navigate('/'),
  })
  const { formProps, passwordProps, passwordConfirmationProps } = props

  return (
    <div className="my-4 mx-1 sm:mx-10 sm:my-10 md:my-14">
      <section className="mx-auto w-[95%] max-w-[512px] rounded-xl bg-white py-6 px-2 shadow sm:rounded-2xl sm:py-6 sm:px-6">
        <div className="flex flex-col">
          <img
            src="/vibe_logo_novo_colorido.svg"
            alt="Vibe logo"
            className="w-[200px] self-center"
          />
          <div className="py-10">
            <AuthForm
              title="Nova senha"
              form={
                <form {...formProps}>
                  <ResetPasswordForm
                    passwordProps={passwordProps}
                    passwordConfirmationProps={passwordConfirmationProps}
                    reseting={reseting}
                    error={error}
                  />
                </form>
              }
            />
          </div>
          <img
            src="/logo_metadados_novo_colorido.svg"
            alt="Metadados RH em Sintonia"
            className="w-[135px] self-center"
          />
        </div>
      </section>
    </div>
  )
}

const ResetPasswordForm = ({
  passwordProps,
  passwordConfirmationProps,
  reseting,
  error,
}: {
  passwordProps: any
  passwordConfirmationProps: any
  reseting: boolean
  error?: string
}) => (
  <div className="mt-7 flex w-full flex-col items-center gap-5">
    <Input {...passwordProps} label="Senha" />

    <Input {...passwordConfirmationProps} label="Confirmar Senha" />

    <FormError>{error}</FormError>

    <button disabled={reseting} className="bt bt-contained mt-2 self-end">
      Atualizar senha
    </button>
  </div>
)
