import React from 'react'

import { useCroods } from 'croods'
import Modal from 'shared/ui/Modal'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import InputHint from 'shared/formsv2/input-hint'
import type { User } from 'types'
import CreateRoundedIcon from '@material-ui/icons/CreateRounded'
import { email as emailValidation } from 'shared/forms/validations'
import { cx } from 'shared/helpers'
import Icon from 'shared/ui/icon'
import type { Actions } from 'croods'
import Alert from 'shared/ui/alert'

export type ResendInviteModalType = {
  onClose: () => void
  user: User | null
  fetchList: Actions<User>['fetch']
}

const CopyContent = ({ inviteLink }: { inviteLink: string }) => {
  const { snackbar } = useSnackbar()
  const handleCopyLink = () => {
    navigator.clipboard.writeText(inviteLink)
    snackbar({ message: 'Link copiada com sucesso', type: 'success' })
  }
  return (
    <>
      <button
        className="bt flex h-20 p-3 items-center border-blue border rounded "
        title="Copiar link"
        onClick={handleCopyLink}
      >
        <textarea
          className="no-scrollbar border-none select-none grow hover:cursor-pointer text-base text-blue resize-none outline-none"
          readOnly
          placeholder="O link será mostrado aqui."
          value={inviteLink}
        />
        <Icon
          title="file_copy-icon"
          icon="file-copy-outline"
          className="text-blue"
        />
      </button>
    </>
  )
}

const ResendInviteModal = ({
  onClose,
  user,
  fetchList,
}: ResendInviteModalType) => {
  const { snackbar } = useSnackbar()
  const [{ saving, info }, { save }] = useCroods({
    name: 'invitation',
    path: '/auth/invitation/resend',
    afterSuccess: () => {
      snackbar({
        title: 'Sucesso',
        message: 'E-mail reenviado com sucesso!',
        type: 'success',
      })
      setEmailSent(true)
      fetchList()()
    },
    afterFailure: (e) => {
      snackbar({
        title: 'Não foi possível enviar o e-mail.',
        message: e?.response?.data?.message,
        type: 'error',
        timeout: 5,
      })
    },
  })

  const [email, setEmail] = React.useState(user?.email || '')
  const [editing, setEditing] = React.useState(false)
  const [errors, setErrors] = React.useState(false)

  const [emailSent, setEmailSent] = React.useState(false)

  const isValidEmail = (email: string) =>
    Boolean(email && !emailValidation()(email))

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidEmail(event.target.value)) {
      setErrors(false)
    } else {
      setErrors(true)
    }
    setEmail(event.target.value)
  }

  const handleConfirm = async () => save({})({ userId: user?.id, email })

  return (
    <Modal.Wrapper
      loading={saving}
      onClose={onClose}
      onConfirm={handleConfirm}
      preventClose={true}
      enabled={!errors}
      className="!max-w-lg"
    >
      <Modal.Header>Reenviar convite</Modal.Header>
      {emailSent ? (
        <>
          <Modal.Content>
            <div className="flex flex-col max-w-md gap-4">
              <div className="text-lg">
                <p>
                  Convite reenviado com sucesso para <b>{info?.email}</b>.
                </p>
                <br />
                <p>
                  Você pode copiar e colar o link abaixo para enviar o convite
                  diretamente ao convidado.
                </p>
              </div>
              <CopyContent inviteLink={info?.invitationLink} />
            </div>
          </Modal.Content>
          <Modal.Actions cancelLabel="Fechar"></Modal.Actions>
        </>
      ) : (
        <>
          <Modal.Content>
            {editing ? (
              <input
                className={cx(
                  'input',
                  errors && '!border-red-dark !text-red-dark',
                )}
                onBlur={() => setEditing(false)}
                value={email}
                onChange={handleChange}
                placeholder={user?.email}
                autoFocus={true}
                type="email"
              />
            ) : (
              <button
                type="button"
                className="enabled:cursor-pointer py-[10px]"
                onClick={() => setEditing(true)}
                disabled={user?.imported}
              >
                <h4
                  className={cx(
                    'no-scrollbar text-neutral-400 flex items-center overflow-scroll',
                    errors && '!border-red-dark !text-red-dark',
                  )}
                >
                  {email}
                  {!user?.imported && (
                    <CreateRoundedIcon
                      className={cx(
                        'ml-2 !text-lg text-blue-500',
                        errors && '!border-red-dark !text-red-dark',
                      )}
                    />
                  )}
                </h4>
              </button>
            )}
            <InputHint isError={errors}>
              {errors && 'Não é um e-mail válido'}
            </InputHint>
            <p className="mt-[6px] max-w-md">
              Ao reenviar, o convite anterior é inativado. Você poderá copiar o
              link de convite após confirmar.
            </p>
            {user?.imported && (
              <Alert
                className="max-w-md mt-4"
                message="Colaboradores importados por integração não podem ter o email editado. É necessário editar na integração."
                severity="warning"
              />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Modal.Confirm>CONFIRMAR</Modal.Confirm>
          </Modal.Actions>
        </>
      )}
    </Modal.Wrapper>
  )
}

export default ResendInviteModal
