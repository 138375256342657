import { cx, getDenomination } from 'shared/helpers'
import Avatar from './Avatar'

const AvatarList = ({
  team,
  memberType,
  membersMax = 10,
  hasTitle = true,
  auxClass = '',
}: any) => {
  const isMainTeam = (member: any) =>
    team.id ? member.user.mainTeam?.id === team.id : true

  const filterList = (list: any[]) => list.filter(isMainTeam)
  const baseList = team[memberType]
  const list = memberType === 'members' ? filterList(baseList) : baseList

  return list.length > 0 ? (
    <div>
      {hasTitle && (
        <h4>
          {team.teamName || team.name} [
          {getDenomination(team.denomination || team.teamDenomination)}]
        </h4>
      )}
      <div className={cx('mt-4 flex flex-wrap gap-2', auxClass)}>
        {list.slice(0, membersMax).map((member: any, index: number) => (
          <Avatar key={index} user={member.user} />
        ))}
      </div>
    </div>
  ) : null
}

export default AvatarList
