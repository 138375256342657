import * as React from 'react'
import { DropPicture } from 'uploods'
import { useFormContext } from 'react-hook-form'
import type { InputProps } from './input'
import InputHint from './input-hint'
import Label from './label'

function parseFile(filesString: string) {
  if (!filesString) return null
  try {
    return JSON.parse(filesString) as File
  } catch (err) {
    return null
  }
}

type File = {
  id: string
  name: string
  parsed: string
  percent: number
  size: number
  state: string
  type: string
}
type Props = InputProps & { initialSrc?: string }
function ImageUploadInput({
  hint,
  name,
  id = name,
  label,
  required,
  className,
  initialSrc,
  ...props
}: Props) {
  let { register, formState, setValue } = useFormContext()
  let hasErrors = Boolean(formState.errors?.[name]?.message)
  const [mounted, setMounted] = React.useState(false)

  function handleChange(file?: File) {
    setValue(name, file ? JSON.stringify(file) : '', {
      shouldValidate: true,
    })
  }

  React.useEffect(() => {
    // This is needed bc DropPicture is calling onChange on mount
    // thus the validation was called prematurely
    setMounted(true)
  }, [])

  return (
    <>
      <Label aria-required={required} htmlFor={id}>
        {label}
      </Label>
      <input type="hidden" {...props} {...register(name)} id={id} />
      <div className={className}>
        <DropPicture
          initialSrc={initialSrc}
          maxDimension={144}
          quality={1}
          onChange={mounted ? (handleChange as (f: any) => void) : () => {}}
          config={{ autoUpload: false }}
        />
      </div>
      <InputHint isError={hasErrors}>
        {formState.errors?.[name]?.message ?? hint}
      </InputHint>
    </>
  )
}

export { parseFile }
export default ImageUploadInput
