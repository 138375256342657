import * as React from 'react'
import Hidden from '@material-ui/core/Hidden'
import { useNavigate, useParams } from 'react-router-dom'
import { useCroods } from 'croods'

import parseISO from 'date-fns/parseISO'
import subDays from 'date-fns/subDays'

import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'
import localPTBR from '@fullcalendar/core/locales/pt-br'

import { getFullName, renderIf } from 'shared/helpers'
import MobileWarning from 'shared/ui/MobileWarning'
import { useVacationManagement } from '../context'
import { useSearch } from 'shared/utils/hooks'
import Skeleton from 'shared/ui/skeleton'
import Search from 'shared/ui/Search'

import LeaderVacationReminder from '../LeaderVacationReminder'

import type { EventDropArg, CalendarApi } from '@fullcalendar/react'
import type { EventResizeDoneArg } from '@fullcalendar/interaction'
import type { Vacation } from '../types'
import {
  calendarConstProps,
  customButtons,
  eventContent,
  eventDrop,
  eventResize,
  resourceLabelContent,
  viewDidMount,
} from './calendarHelper'

const Timeline = () => {
  const { fullName, startDate } = useParams()
  const navigate = useNavigate()
  const { dispatch, events } = useVacationManagement()

  const [{ list, fetchingList }, { fetch }] = useCroods<Vacation>({
    fetchOnMount: true,
    name: 'vacations_list',
    path: '/workflow/vacation_management',
    afterSuccess: ({ data }) => {
      dispatch({
        type: 'INITIAL_STATE',
        payload: { initialState: data },
      })
    },
  })

  const [, { save }] = useCroods<Vacation>({
    name: 'vacations_save',
    path: '/workflow/vacation_management',
  })

  const [selectedEventIdToConfirm, setSelectedEventIdToConfirm] =
    React.useState('')

  const [calendarApi, setCalendarApi] = React.useState<undefined | CalendarApi>(
    undefined,
  )

  const yesterday = startDate
    ? subDays(parseISO(startDate), 1)
    : subDays(new Date(), 1)

  const { setSearch, select, searchTerm } = useSearch(fullName ?? '')

  const rows = select(list, ({ firstName, lastName, email }) => {
    return [getFullName({ firstName, lastName, email }), email]
  }) as Vacation[]

  if (fetchingList && list.length === 0) return <Skeleton height="32rem" />

  return (
    <div id="timeline" aria-label="timeline">
      <Hidden smDown>
        <div className="mb-5 flex-row items-center justify-between">
          <LeaderVacationReminder list={list} setSearchValue={setSearch} />
        </div>

        <div className="relative w-full">
          <div className="absolute mt-2 flex w-4/6 gap-3">
            <div className="w-80">
              <Search
                placeholder="Buscar colaborador"
                setSearch={setSearch}
                searchTerm={searchTerm}
              />
            </div>
            {renderIf(
              Boolean(searchTerm),
              <button
                className="bt bt-outlined px-3"
                onClick={() => navigate('/portal/gestao-de-ferias')}
              >
                Limpar busca
              </button>,
            )}
          </div>
        </div>

        <FullCalendar
          schedulerLicenseKey={process.env.REACT_APP_SCHEDULER_LICENSE}
          viewDidMount={({ view }) => viewDidMount(view, setCalendarApi)}
          locale={localPTBR}
          plugins={[interactionPlugin, resourceTimelinePlugin]}
          scrollTime={{
            month: yesterday.getUTCMonth(),
            day: yesterday.getDate(),
          }}
          customButtons={customButtons(calendarApi)}
          resources={rows.map((e) => ({ ...e, id: String(e.id) }))}
          events={events}
          eventDrop={async (arg: EventDropArg) =>
            eventDrop(arg, save, dispatch)
          }
          eventResize={async (arg: EventResizeDoneArg) =>
            eventResize(arg, save, dispatch)
          }
          resourceLabelContent={({ resource }) =>
            resourceLabelContent(resource, fetch, calendarApi)
          }
          eventContent={({ event }) =>
            eventContent(
              event,
              calendarApi,
              selectedEventIdToConfirm,
              setSelectedEventIdToConfirm,
              fetch,
            )
          }
          {...calendarConstProps}
        />
      </Hidden>
      <Hidden mdUp>
        <div className="mx-auto my-9 flex justify-center">
          <MobileWarning />
        </div>
      </Hidden>
    </div>
  )
}

export default Timeline
