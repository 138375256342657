import React from 'react'
import RichText from 'shared/forms/RichText'
import type { TeamEvaluations } from '../NewEvaluation'
import type { FormState } from '../types'
import optionalFieldsLabels from './optionalFieldsLabels'

type Props = {
  handleChange: (title: keyof FormState, value: string) => void
  teamEvaluation: TeamEvaluations
}

export default function Texts({ handleChange, teamEvaluation }: Props) {
  return (
    <div>
      <RichText
        onKeyUp={(value: string) => handleChange('keepDoing', value)}
        value={teamEvaluation?.keepDoing ?? undefined}
        label={optionalFieldsLabels.keepDoing}
        name="keepDoing"
      />
      <RichText
        onKeyUp={(value: string) => handleChange('needsToImprove', value)}
        value={teamEvaluation?.needsToImprove ?? undefined}
        label={optionalFieldsLabels.needsToImprove}
        name="needsToImprove"
      />
      <RichText
        onKeyUp={(value: string) => handleChange('notes', value)}
        value={teamEvaluation?.notes ?? undefined}
        label={optionalFieldsLabels.notes}
        name="notes"
      />
    </div>
  )
}
