import { cx } from 'shared/helpers'

export default function Head({
  children,
  className,
  ...props
}: JSX.IntrinsicElements['thead']) {
  return (
    <thead {...props}>
      <tr className={cx('divide-x divide-gray/50', className)}>{children}</tr>
    </thead>
  )
}
