import * as React from 'react'
import NumberFormat from 'react-number-format'
import { useFormContext } from 'react-hook-form'
import { cx } from 'shared/helpers'
import InputHint from './input-hint'
import Label from './label'

import type {
  NumberFormatProps,
  NumberFormatValues,
  SourceInfo,
  SyntheticInputEvent,
} from 'react-number-format'

type Props = Omit<
  NumberFormatProps<React.InputHTMLAttributes<HTMLInputElement>>,
  'name' | 'type'
> & {
  onChange?: (ev: SyntheticInputEvent) => void
  name: string
  label?: string
  hint?: string
}
function NumberInput({
  name,
  thousandSeparator = ',',
  decimalSeparator = '.',
  id = name,
  hint,
  label,
  required,
  className,
  ...props
}: Props) {
  let { register, formState, setValue } = useFormContext()
  let hasErrors = Boolean(formState.errors?.[name]?.message)

  function handleChange(values: NumberFormatValues, { event }: SourceInfo) {
    const ev = {
      ...event,
      target: { ...event.target, value: values.formattedValue },
    }
    setValue(name, values.formattedValue)
    props.onChange?.(ev)
  }

  React.useEffect(() => {
    setValue(name, props.defaultValue)
  }, [name, props.defaultValue, setValue])

  let hintId = `input-${id}-hint`
  return (
    <>
      <Label aria-required={required} htmlFor={id}>
        {label}
      </Label>
      <NumberFormat
        className={cx(
          'input',
          hasErrors && '!border-red-dark !text-red-dark',
          className,
        )}
        {...props}
        {...register(name)}
        id={id}
        onValueChange={handleChange}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={2}
        aria-invalid={hasErrors ? 'true' : 'false'}
        aria-describedby={hasErrors ? hintId : undefined}
      />
      <InputHint id={hintId} isError={hasErrors}>
        {formState.errors?.[name]?.message ?? hint}
      </InputHint>
    </>
  )
}

export type { Props as InputProps }
export default NumberInput
