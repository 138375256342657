import IconButton from '@material-ui/core/IconButton'
import ListItemMUI from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'theme'
import Icon from 'shared/ui/icon'
import { cx } from '@remaster-run/helpers'
import { renderIf } from 'shared/helpers'

const useStyles = makeStyles(() => ({
  listItem: {
    background: colors.grey23,
    borderRadius: '1rem',
    overflow: 'hidden',
    minHeight: '5rem',
    '& span, & p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& hr+div:last-child': {
      minWidth: '55%',
    },
  },
  image: {
    position: 'relative',
    marginRight: '1rem',
    '&::before': {
      top: '-200%',
      left: '-1rem',
      width: '4.5rem',
      bottom: '-200%',
      content: ' ""',
      display: ' block',
      position: ' absolute',
      backgroundColor: colors.grey40,
    },
  },
}))

type Props = {
  customIcon: React.ReactNode
  item: any
  primaryLabel?: string
  primaryText?: string
  secondaryLabel?: any
  secondaryItem?: any
  hasActions: boolean
  firstAction?: () => void
  secondAction?: () => void
  firstStatusIcon?: React.ReactNode
}

const ListItem = ({
  customIcon,
  item,
  primaryLabel,
  primaryText,
  secondaryLabel,
  secondaryItem,
  hasActions,
  firstAction,
  secondAction,
  firstStatusIcon,
}: Props) => {
  const classes = useStyles()

  return (
    <ListItemMUI key={item?.value} className={cx(classes.listItem, 'gap-3')}>
      <ListItemIcon className={classes.image} data-testid="icon">
        {customIcon}
      </ListItemIcon>

      <ListItemText
        primary={primaryLabel}
        secondary={primaryText}
        primaryTypographyProps={{ variant: 'h5', component: 'span' }}
      />
      {renderIf(
        Boolean(secondaryLabel),
        <>
          <Divider className="!mx-4" orientation="vertical" flexItem />
          <ListItemText
            primary={secondaryLabel}
            secondary={secondaryItem}
            primaryTypographyProps={{ variant: 'subtitle2', component: 'span' }}
            secondaryTypographyProps={{ component: 'div' }}
          />
        </>,
      )}

      {renderIf(
        Boolean(hasActions),
        <ListItemSecondaryAction style={{ display: 'flex' }}>
          {renderIf(
            Boolean(firstAction),
            <>
              <IconButton
                size="small"
                aria-label="update"
                onClick={firstAction}
              >
                <Icon icon="edit" data-testid="action" />
              </IconButton>
              <Divider className="mx-4" orientation="vertical" flexItem />
            </>,
          )}
          {renderIf(
            Boolean(secondAction),
            <IconButton
              size="small"
              edge="end"
              aria-label="delete"
              onClick={secondAction}
            >
              <Icon icon="clear" data-testid="action" />
            </IconButton>,
          )}
          {renderIf(Boolean(firstStatusIcon), <div>{firstStatusIcon}</div>)}
        </ListItemSecondaryAction>,
      )}
    </ListItemMUI>
  )
}

export { ListItem }
