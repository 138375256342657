import React from 'react'
import { useWindowDimensions } from '@seasonedsoftware/utils'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import CloudOffIcon from '@material-ui/icons/CloudOff'
import CachedIcon from '@material-ui/icons/Cached'

import FeedbackMessage from 'shared/ui/FeedbackMessage'
import { cx } from 'shared/helpers'
import { colors } from 'theme'
import { useCroods } from 'croods'
import { useTableContext } from './Context'

type Props = {
  handleDialog: () => void
}

const PublishCard = ({ handleDialog }: Props) => {
  const { height, width } = useWindowDimensions()
  const [{ saving, saveError }] = useCroods({
    name: 'memberEvaluations',
  })

  const { errors } = useTableContext()
  const disabled = Boolean(errors?.length)

  return (
    <div
      className={cx(
        'card fixed h-[71px] w-[545px] translate-x-[-50%] p-2 pt-4 shadow-lg transition',
        width === 0 && 'opacity-0',
      )}
      style={{
        top: `${height - 100}px`,
        left: `${width / 2}px`,
      }}
    >
      <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <>
              <Grid item>
                {!saving && !saveError && (
                  <CloudDoneIcon
                    style={{
                      color: colors.black54,
                    }}
                  />
                )}
                {saving && !saveError && (
                  <CachedIcon
                    style={{
                      color: colors.black54,
                    }}
                  />
                )}
                {saveError && (
                  <CloudOffIcon
                    style={{
                      color: colors.tangerine,
                    }}
                  />
                )}
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  style={{
                    lineHeight: '1.7',
                    fontWeight: 'bold',
                    color: saveError ? colors.tangerine : colors.black54,
                    width: '55px',
                  }}
                >
                  {!saving && !saveError && <span>Rascunho salvo</span>}
                  {saving && !saveError && <span>Salvando rascunho...</span>}
                  {saveError && <span>Erro ao salvar!</span>}
                </Typography>
              </Grid>
            </>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            className="w-[400px]"
            size="small"
            startIcon={<CheckIcon />}
            onClick={handleDialog}
            disabled={disabled}
            variant={disabled ? 'outlined' : 'contained'}
          >
            Finalizar
          </Button>
        </Grid>
      </Grid>
      {saveError && <FeedbackMessage message="Erro ao finalizar avaliação" />}
    </div>
  )
}

export default PublishCard
