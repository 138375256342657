import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import { cx } from 'shared/helpers'
import type { FormTemplate } from 'types'

type Props = {
  label: string
  value: string
  template: FormTemplate
  classNameFooter: string
  onClick: () => void
}

const Card = ({ label, value, template, classNameFooter, onClick }: Props) => (
  <div
    className="h-[363px] w-full max-w-[240px] cursor-pointer overflow-hidden rounded-2xl border text-left transition hover:bg-gray/10"
    onClick={onClick}
  >
    <h6 className="flex gap-2 bg-gray/10 p-4 uppercase">{label}</h6>
    <div
      className="h-[278px] p-4 text-base font-normal leading-[170%] text-gray-darkest"
      dangerouslySetInnerHTML={{
        __html: get(template, camelCase(`info_${value}`)),
      }}
    ></div>
    <div
      className={cx('flex h-10 items-center justify-center', classNameFooter)}
    >
      <FormControlLabel
        className="!m-0 !h-10"
        label=""
        value={value}
        control={
          <Radio
            className="!text-gray-darkest"
            inputProps={{
              'aria-label': value,
            }}
          />
        }
      />
    </div>
  </div>
)

const handleChange =
  (set: (arg: any) => void) =>
  ({ target: { value } }: { target: { value: any } }) =>
    set(value)

const options = [
  {
    label: 'Franqueza com Empatia',
    value: 'radical_candor',
    classNameFooter: 'bg-green bg-opacity-50',
  },
  {
    label: 'Franqueza com Indiferença',
    value: 'obnoxious_aggression',
    classNameFooter: 'bg-yellow bg-opacity-85',
  },
  {
    label: 'Medo de Falar a Verdade',
    value: 'ruinous_empathy',
    classNameFooter: 'bg-yellow bg-opacity-60',
  },
  {
    label: 'Mentira com Indiferença',
    value: 'manipulative_insincerity',
    classNameFooter: 'bg-yellow bg-opacity-30',
  },
] as const

type RadioButtonsGroupProps = {
  label: string
  value: any
  error: any
  template: FormTemplate
  setValue: () => () => void
  defaultValue?: any
  helper?: string
}

const RadioButtonsGroup = ({
  label,
  setValue,
  defaultValue,
  value,
  error,
  helper = ' ',
  template,
}: RadioButtonsGroupProps) => (
  <FormControl component="fieldset" error={!!error}>
    <h5 className="">{label}</h5>
    <RadioGroup
      aria-label={label}
      name={label}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange(setValue)}
      className="mt-2"
    >
      <div className="flex flex-wrap gap-10">
        {options.map((option) => (
          <Card
            key={option.value}
            label={option.label}
            value={option.value}
            template={template}
            classNameFooter={option.classNameFooter}
            onClick={() =>
              handleChange(setValue)({ target: { value: option.value } })
            }
          />
        ))}
      </div>
    </RadioGroup>
    <FormHelperText>{error || helper}</FormHelperText>
  </FormControl>
)

export default RadioButtonsGroup
