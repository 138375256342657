import { cx } from 'shared/helpers'

const Tr = ({ children, className, ...props }: JSX.IntrinsicElements['tr']) => {
  return (
    <tr
      {...props}
      className={cx('block even:bg-blue-lightest md:table-row', className)}
    >
      {children}
    </tr>
  )
}

export default Tr
