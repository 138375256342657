import * as React from 'react'
import { useCroods } from 'croods'
import CareerGoals from 'talks/conversations/career/shared/careerGoals'
import { CareerSkillsPlanning } from 'talks/conversations/career/shared/careerSkills'
import Stepper from 'shared/ui/stepper'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import Buttons from '../shared/Buttons'
import TextInput from '../shared/TextInput'
import Step from '../shared/Step'
import type { Info } from 'croods'
import type { CareerConversation } from 'talks/types'
import { useNavigate } from 'react-router-dom'
import SelfEvaluation from './SelfEvaluation'
import { useTrackAnalytics } from 'domain/analytics'

type Props = {
  conversation: Info<CareerConversation>
  teamMemberId: string
  setConversation: (value: CareerConversation) => void
}

export default function FirstConversation({
  conversation,
  teamMemberId,
  setConversation,
}: Props) {
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()
  const track = useTrackAnalytics()

  const [isValidated, setIsValidated] = React.useState(false)
  const [, { save: publishConversation }] = useCroods({
    name: 'carrerConversation',
    path: `/career_conversations/${conversation.id}/publish`,
    afterSuccess: () => {
      track('Conversation', { Type: 'career' })
      snackbar({
        message: 'Conversa publicada com sucesso',
        type: 'success',
      })
      navigate(`/`)
    },
    afterFailure: () => {
      snackbar({
        message: 'Não foi possível publicar a conversa',
        type: 'error',
      })
    },
  })

  const [, { save }] = useCroods({
    name: 'careerConversations',
    customPath: `career_conversations/${conversation.id}`,
    fetchOnMount: false,
    afterSuccess: ({ data }) => {
      setConversation(data)
      snackbar({
        message: 'Rascunho salvo com sucesso',
        timeout: 2,
      })
    },
  })

  const handleSave = (value: { [index: string]: string | number | null }) => {
    save({ method: 'put' })(value)
  }

  return (
    <Stepper.Wrapper>
      <Stepper.Steps
        className="flex items-start justify-center"
        divider={
          <div className="mx-4 flex flex-col justify-center">
            <div className="mt-5 w-4 md:w-12 border-b-2" />
          </div>
        }
      >
        <Step marker="1">
          Avaliação <br />
          Geral
        </Step>
        <Step marker="2">Objetivos</Step>
        <Step marker="3">
          Planejamento <br className="hidden md:block" />
          de Competências
        </Step>
      </Stepper.Steps>
      <div className="card mt-6 border border-gray-lightest shadow-lg">
        <div className="p-4">
          <Stepper.Content>
            {/* Step 1 */}
            <SelfEvaluation
              conversation={conversation}
              handleSave={handleSave}
              setConversation={setConversation}
              setIsValidated={setIsValidated}
            />
            {/* Step 2 */}
            <CareerGoals
              teamMemberId={teamMemberId}
              setIsValidated={setIsValidated}
            />
            {/* Step 3 */}
            <div>
              <CareerSkillsPlanning
                developmentPlanLabel="Planos e ideias para o desenvolvimento da competência no próximo ciclo"
                teamMemberId={teamMemberId}
                setIsValidated={setIsValidated}
                conversationId={conversation.id}
              />
              <TextInput
                text={conversation.supportPlan}
                name="supportPlan"
                handleSave={handleSave}
                label="Que apoio você precisa para seu desenvolvimento?"
              />
            </div>
          </Stepper.Content>
        </div>
        <Buttons publish={publishConversation()} isValidated={isValidated} />
      </div>
    </Stepper.Wrapper>
  )
}
