import React from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { colors } from 'theme'
import MembersList from 'shared/ui/AvatarList'
import EvaluationCycle from '../../../teamEvaluation/EvaluationCycle'

const Summary = ({ values }: { values: { teamId: number }[] }) => (
  <Grid container direction="column">
    <Grid item>
      <EvaluationCycle />
    </Grid>
    <Grid item>
      <Typography
        variant="body2"
        style={{
          fontWeight: 'bold',
          color: colors.black54,
          paddingBottom: '16px',
        }}
      >
        Talentos avaliados
      </Typography>
      {values.map((team) => (
        <MembersList
          key={team.teamId}
          team={team}
          memberType="memberEvaluations"
          membersMax={10}
        />
      ))}
    </Grid>
  </Grid>
)

export default Summary
