import * as React from 'react'

const Diamond = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_14015_15080)">
      <path
        d="M7.36359 6.36373L11.4545 0.90918L15.0909 6.36373"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.63635 0.90918L11 18.1819L17.3636 0.90918"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.36372C1 6.36372 12.9125 6.36372 20.5455 6.36372M1 6.36372L4.63636 0.90918H17.3636C17.3636 0.90918 19.3029 4.23359 20.5455 6.36372M1 6.36372L11 18.6365C11 18.6365 16.8177 11.1565 20.5455 6.36372"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14015_15080">
        <rect
          width="21.8182"
          height="20"
          fill="white"
          transform="translate(0.0908813)"
        />
      </clipPath>
    </defs>
  </svg>
)

export { Diamond }
