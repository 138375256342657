import type { AccessPolicyUser } from 'shared/accessPolicies'
import MenuItemList from './MenuItemList'

type Props = {
  currentUser: AccessPolicyUser | null
}

const ModuleItemsMenu = ({ currentUser }: Props) => {
  if (!currentUser) return null

  return (
    <div
      id="menu"
      className="flex grow flex-col rounded-tl-2xl bg-gradient-to-b from-[rgba(106,151,206,0.2)] to-[rgba(196,196,196,0)] sm:w-[230px] sm:rounded-tr-2xl md:grow-0"
    >
      <div className="flex-1 px-2">
        <div className="flex flex-col py-5">
          <img src="/vibe_logo.svg" alt="Vibe logo" className="mb-2 h-[45px]" />
        </div>

        <nav>
          <MenuItemList currentUser={currentUser} />
        </nav>
      </div>
    </div>
  )
}

export default ModuleItemsMenu
