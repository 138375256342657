import * as React from 'react'
import type { FormState, Inputs, StateErrors } from 'react-use-form-state'
import Select from './Select'
import type { MemberEvaluation, SelectType } from '../../types'
import PercentageCircle from 'shared/ui/PercentageCircle'
import { meritPercentageStyle } from 'performance/evaluations/membersEvaluation/labelAndColorByValue'
import { calculateContribution } from './calculate-contribution'
import {
  mappingCulturalFit,
  mappingPotential,
  calculateMeritPercentage,
} from 'performance/evaluations/membersEvaluation/TeamTable/Table/mapping-member-evaluation-fields'
type Props = {
  save: (arg: Record<string, number | string | null>) => void
  inputs: Inputs<MemberEvaluation>
  formState: FormState<MemberEvaluation, StateErrors<MemberEvaluation, string>>
  initalPercentage: MemberEvaluation['meritPercentage']
}

export default function MeritGroup({
  inputs,
  formState,
  save,
  initalPercentage,
}: Props) {
  const {
    individualOutput,
    teamwork,
    potential,
    culturalFit,
    meritPercentage,
  } = formState.values

  const handlePercentage = () => {
    const contributionScore = calculateContribution(individualOutput, teamwork)
    const culturalFitScore = mappingCulturalFit(culturalFit)
    const potentialScore = mappingPotential(potential)

    const meritPercentageResult = calculateMeritPercentage(
      contributionScore,
      potentialScore,
      culturalFitScore,
    )

    if (initalPercentage != meritPercentageResult) {
      save({ meritPercentage: meritPercentageResult })
    }
    formState.setField('meritPercentage', meritPercentageResult)
    formState.setFieldError('meritPercentage', undefined)
  }

  const handleSave = (type: SelectType, result: number | string | null) => {
    if (initalPercentage != result) {
      save({ [type]: result })
    }
  }

  React.useEffect(() => {
    if (individualOutput || teamwork || potential || culturalFit) {
      handlePercentage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualOutput, teamwork, potential, culturalFit])

  return (
    <>
      <Select
        title="Entrega"
        type="individualOutput"
        selected={individualOutput}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          handleSave('individualOutput', event.target.value)
        }}
        inputs={inputs}
        formState={formState}
      />
      <Select
        title="Trabalho em Equipe"
        type="teamwork"
        selected={teamwork}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          handleSave('teamwork', event.target.value)
        }}
        inputs={inputs}
        formState={formState}
      />
      <Select
        title="Potencial"
        type="potential"
        selected={potential}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          handleSave('potential', event.target.value)
        }}
        inputs={inputs}
        formState={formState}
      />
      <Select
        title="Fit Cultural"
        type="culturalFit"
        selected={culturalFit}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          handleSave('culturalFit', event.target.value)
        }}
        inputs={inputs}
        formState={formState}
      />
      <td className="ml-1 mr-3">
        <PercentageCircle
          title="Mérito"
          percentage={meritPercentage}
          className={meritPercentageStyle(meritPercentage).bgColor}
        />
      </td>
    </>
  )
}
