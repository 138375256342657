import { Fetch } from 'croods'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import Chart from './chart'
import type { InsightComponentProps } from 'vibe/insights/chart-helpers'
import { chartPath } from 'vibe/insights/chart-helpers'

const loadingCard = (
  <div className="animate-pulse text-center">Carregando...</div>
)

const Card = ({ query, stateId }: InsightComponentProps) => (
  <Fetch
    name="charts"
    // @ts-ignore
    operation="info"
    customPath={chartPath('talent_retention', query)}
    stateId={stateId}
    query={query}
    render={(_, [{ info, fetchingInfo, infoError }]) => {
      if (fetchingInfo) return loadingCard

      if (infoError)
        return <FeedbackMessage message={infoError} type="warning" />

      return <Chart data={info?.data ?? []} />
    }}
  />
)

export default Card
