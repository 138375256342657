import { useCurrentUser } from 'croods-auth'
import NextConversations from 'talks/conversations/next'
import SkillsAndGoals from 'talks/conversations/career/skills-and-goals'
import useOrganization from 'shared/organization/useOrganization'
import UpwardEvaluationReminder from 'talks/upwardEvaluation/UpwardEvaluationReminder'

export default function Talks() {
  const [{ currentUser }] = useCurrentUser()
  const organization = useOrganization()
  const { hierarchyPosition, mainTeam } = currentUser

  const hasNextConversations =
    organization?.permitOneOnOneConversation ||
    organization?.permitCareerConversation ||
    organization?.permitBehaviorConversation

  switch (hierarchyPosition) {
    case 'leader':
      return (
        <div className="flex flex-col gap-y-10">
          {hasNextConversations && <NextConversations maxConversations={4} />}
        </div>
      )
    case 'member':
      return (
        <div className="flex flex-col gap-y-6">
          {mainTeam?.abilities?.upwardEvaluation && (
            <UpwardEvaluationReminder />
          )}
          {mainTeam?.abilities?.careerConversation && <SkillsAndGoals />}
        </div>
      )
    case 'member_and_leader':
      return (
        <div className="flex flex-col gap-y-10">
          {hasNextConversations && <NextConversations maxConversations={4} />}
          {mainTeam?.abilities?.upwardEvaluation && (
            <UpwardEvaluationReminder />
          )}
          {mainTeam?.abilities?.careerConversation && <SkillsAndGoals />}
        </div>
      )
    default:
      return null
  }
}
