import type { RouteCategory } from 'domain/routes'
import { getTypeOf } from 'shared/helpers'
import type { LegacyRoute } from 'types'

const PROTECTED_CATEGORIES = ['settings']
const PROTECTED_PATHS = ['/configuracoes']

const onlyAdmin = (authRedirect = '/'): Partial<LegacyRoute> => ({
  authRedirect,
  unauthorize: (user) => !user?.admin,
})

export default ({
  unauthorize,
  authRedirect,
  path,
  category,
}: LegacyRoute & {
  category?: RouteCategory
}): Partial<LegacyRoute> => {
  if (getTypeOf(unauthorize) === 'function') {
    return { unauthorize, authRedirect }
  }
  const isAdminPath = PROTECTED_PATHS.some((p) => path.startsWith(p))
  const isAdminCategory = category && PROTECTED_CATEGORIES.includes(category)
  return isAdminPath || isAdminCategory ? onlyAdmin(authRedirect) : {}
}
