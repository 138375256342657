import type { Dispatch, SetStateAction} from 'react';
import React, { useContext } from 'react'

type ContextType = {
  stepLength: number
  currentStep: number
  nextStep: () => void
  previousStep: () => void
  setStepLength: Dispatch<SetStateAction<number>>
}

const initialValues = {
  stepLength: 0,
  currentStep: 0,
}

const StepperContext = React.createContext<ContextType>(
  initialValues as ContextType,
)

StepperContext.displayName = 'StepperContext'

export default StepperContext

export const useStepperContext = () => useContext(StepperContext)
