import { Fetch } from 'croods'

import Skeleton from 'shared/ui/skeleton'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import MembersEvaluationNotice from 'performance/evaluations/membersEvaluation/membersEvaluationNotice'
import NineBox from 'performance/evaluations/membersEvaluation/nineBox'

const nineBox = () => (
  <div>
    <Fetch
      name="memberEvaluationsNineBox"
      path="/member_evaluations/nine_box"
      render={({
        boxes,
        organizationCycleStartsAt,
        organizationCycleFinishesAt,
        canEdit,
        isDraft,
      }) =>
        boxes && boxes.length > 0 ? (
          <NineBox
            boxes={boxes}
            cycle={{ organizationCycleStartsAt, organizationCycleFinishesAt }}
            canEdit={canEdit}
          />
        ) : (
          <MembersEvaluationNotice isDraft={isDraft} />
        )
      }
      renderLoading={() => <Skeleton height="264.66px" />}
      renderError={() => <FeedbackMessage />}
    />
  </div>
)
export default nineBox
