import React, { useState } from 'react'
import type { Moment } from 'moment'
import moment from 'moment'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'

import Radios from './form/Radios'
import Texts from './form/Texts'
import Date from './form/Date'
import type { FormState } from './types'
import debounce from 'lodash/debounce'
import { useCroods } from 'croods'
import Summary from './summary'
import { useNavigate } from 'react-router-dom'
import PublishCard from './PublishCard'
import { useTrackAnalytics } from 'domain/analytics'

type Props = {
  teamId: number
}

export type TeamEvaluations = {
  id?: number
  teamId: number
  goalsScore: number | null
  resultsScore: number | null
  maturityScore: number | null
  notes: string | null
  keepDoing: string | null
  needsToImprove: string | null
  evaluatedAt: string
}

const NewEvaluation = ({ teamId }: Props) => {
  const teamEvaluationId = React.useRef<undefined | number>(undefined)
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null)
  const [openDialog, setOpenDialog] = useState(false)
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const [
    { info: teamEvaluation, saving, saveError, fetchingInfo, infoError },
    { save, fetch },
  ] = useCroods<TeamEvaluations>({
    name: 'teamEvaluation',
    afterSuccess: ({ data }) => {
      teamEvaluationId.current = data?.id
    },
  })

  const handleChange = (
    key: keyof FormState,
    value: number | string | Moment,
  ) => {
    save({
      method: 'PUT',
      customPath: `/team_evaluations/${teamEvaluationId.current}`,
    })({ [key]: value })
  }

  const debouncingHandleChange = React.useRef(
    debounce(handleChange, 300),
  ).current

  React.useEffect(() => {
    fetch({
      id: teamId,
      customPath: `/team_evaluations/:id/latest_unpublished`,
      afterFailure: (e) => {
        if (e.response?.status === 404) {
          snackbar({
            message: e.response.data.message,
            type: 'error',
          })

          navigate('/performance/avaliar-performance')
        }
      },
    })()
  }, []) //eslint-disable-line

  const track = useTrackAnalytics()

  if (fetchingInfo) return <div>carregando...</div>

  return (
    <div className="mt-12">
      <Radios teamEvaluation={teamEvaluation} handleChange={handleChange} />

      <div className="card mt-8 p-4">
        <Texts
          teamEvaluation={teamEvaluation}
          handleChange={debouncingHandleChange}
        />

        <div className="my-10">
          <Date
            evaluationDate={moment(teamEvaluation?.evaluatedAt)}
            handleChange={handleChange}
          />
        </div>

        {infoError && (
          <div className="my-4">
            <FeedbackMessage message={infoError} />
          </div>
        )}

        <PublishCard
          handleDialog={() => {
            setOpenDialog(true)
          }}
          disabled={
            !Boolean(
              teamEvaluation?.goalsScore &&
                teamEvaluation?.maturityScore &&
                teamEvaluation?.resultsScore,
            )
          }
          saving={saving}
          saveError={saveError}
        />

        <Summary
          openDialog={openDialog}
          handleClose={() => {
            setOpenDialog(false)
          }}
          handleSubmit={() => {
            save({
              method: 'PUT',
              customPath: `/team_evaluations/${teamEvaluationId.current}/publish`,
              afterSuccess: () => {
                setIsSuccess(true)
                track('Performance evaluation')
                setOpenDialog(true)
              },
              afterFailure: () => {
                setIsSuccess(false)
                setOpenDialog(false)
              },
            })({ publish: true })
          }}
          saving={saving}
          isSuccess={isSuccess}
          formState={{
            ...teamEvaluation,
            evaluatedAt: moment(teamEvaluation?.evaluatedAt),
          }}
        />
      </div>
    </div>
  )
}

export default NewEvaluation
