import type { HTMLAttributes } from 'react';
import React from 'react'
import { cx } from 'shared/helpers'

type TrackProps = {
  props: HTMLAttributes<HTMLDivElement> & {
    isPaused: boolean
    displayOnly: boolean
    isCompleted: boolean
    secondConversationPhase: 'evaluation' | 'planning'
  }
  state: {
    index: number
    value: number[]
  }
}

export default function Track(
  props: TrackProps['props'],
  state: TrackProps['state'],
) {
  const {
    isPaused,
    displayOnly,
    isCompleted,
    secondConversationPhase,
    ...trackProps
  } = props

  const getBg = () => {
    if (state.index === 0) {
      if (isCompleted) {
        return 'bg-green'
      }
      if (secondConversationPhase === 'planning') {
        return 'bg-blue-lightest'
      }
      if (isPaused || displayOnly) {
        return 'bg-gray'
      }
      return 'bg-blue'
    }

    if (isCompleted) {
      return 'bg-green/20'
    }
    if (isPaused || displayOnly) {
      return 'bg-gray-lightest'
    }
    return 'bg-blue-lightest'
  }

  return (
    <div
      data-index={state.index}
      {...trackProps}
      className={cx(
        props.className,
        getBg(),
        'relative top-[15px] h-[2px] rounded-full',
      )}
    >
      {state.index === 0 && (
        <div className="absolute left-0 -top-1 h-2 w-4 bg-white" />
      )}
      {state.index === state.value.length && (
        <div className="absolute right-0 -top-1 h-2 w-4 bg-white" />
      )}
    </div>
  )
}
