import React from 'react'
import { useForgotPassword } from 'croods-auth'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { buildRedirectUrl } from 'shared/auth/ForgotPassword'

import Modal from 'shared/ui/Modal'

type Props = {
  user: { email: string }
  onClose: () => void
}
const ChangePasswordModal: React.FC<Props> = ({ user, onClose }) => {
  const { snackbar } = useSnackbar()

  const [{ sending }, sendMail] = useForgotPassword({
    updateRootInfo: false,
    redirectUrl: buildRedirectUrl(),
    afterSuccess: () => {
      snackbar({
        message: 'Você recebeu um e-mail para redefinir sua senha',
        type: 'success',
      })
    },
  })

  const handleConfirm = async () => {
    sendMail({ email: user.email })
  }

  return (
    <Modal.Wrapper
      onClose={onClose}
      loading={sending}
      onConfirm={handleConfirm}
    >
      <Modal.Header>Alteração de senha</Modal.Header>
      <Modal.Content>
        <p>
          Você receberá um e-mail em {user?.email} <br />
          com instruções para alterar a senha.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Modal.Confirm aria-label="Alterar senha">Alterar senha</Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default ChangePasswordModal
