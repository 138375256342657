import * as React from 'react'
import { cx } from 'shared/helpers'

type Props = React.HTMLAttributes<HTMLDivElement>
const Content = ({ children, className, ...props }: Props) => (
  <div className={cx('flex flex-col p-6', className)} {...props}>
    {children}
  </div>
)

export default Content
