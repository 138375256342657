import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'

import Icon from 'shared/ui/icon'
import Text from './Text'
import Chips from './Chips'
import Files from './Files'
import Questions from './Questions'

const QuestionAnswer = ({ label, value, variant = 'text' }: any) => {
  const Component = get(variantComponents, variant, Text)

  return (
    <Grid container>
      <Grid item style={{ height: '100%' }}>
        <Icon icon="check-circle" className="mr-2 h-5 w-5 text-blue" />
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Typography variant="h5" component="h5" className="mb-2">
          {label}
        </Typography>
        <div className="flex items-center gap-2">
          <Component value={value} />
        </div>
      </Grid>
    </Grid>
  )
}

const variantComponents = {
  text: Text,
  chips: Chips,
  files: Files,
  questions: Questions,
}

export default QuestionAnswer
