import * as React from 'react'

const AddToCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
  >
    <g clipPath="url(#clip0_15645_1765)" fill="currentColor">
      <path d="M14.0088 11.5547C14.0088 12.6652 14.899 13.5554 16.0096 13.5554C17.1201 13.5554 18.0103 12.6652 18.0103 11.5547C18.0103 10.4441 17.1201 9.55396 16.0096 9.55396C14.899 9.55396 14.0004 10.4441 14.0088 11.5547Z" />
      <path d="M9.5118 7.55786C7.86291 7.55786 6.51074 8.90579 6.51074 10.5589C6.51074 12.212 7.86291 13.56 9.5118 13.56C11.1607 13.56 12.5129 12.212 12.5129 10.5589C12.5129 8.90579 11.1607 7.55786 9.5118 7.55786ZM9.5118 11.5593C8.96076 11.5593 8.51145 11.11 8.51145 10.5589C8.51145 10.0079 8.96076 9.55857 9.5118 9.55857C10.0628 9.55857 10.5122 10.0079 10.5122 10.5589C10.5122 11.11 10.0628 11.5593 9.5118 11.5593Z" />
      <path d="M11.9866 2.55151C6.4677 2.55151 1.9873 7.03191 1.9873 12.5508C1.9873 18.0697 6.4677 22.5501 11.9866 22.5501C17.5055 22.5501 21.9859 18.0697 21.9859 12.5508C21.9859 11.1944 21.7146 9.90157 21.2229 8.71895C20.5913 8.90545 20.0149 9.03685 19.3367 9.19369C19.307 9.20217 19.2815 9.20641 19.2519 9.20641C19.7266 10.228 19.9894 11.3428 19.9894 12.5381C19.9894 13.7334 19.7181 14.8779 19.2392 15.9079C18.2388 15.3187 16.8782 15.039 16.0007 15.039C14.479 15.039 11.4991 15.8486 11.4991 17.7391V20.5197C9.29497 20.3926 7.24764 19.3583 5.84036 17.6585C6.51857 17.1202 8.11235 16.548 9.49843 16.548C9.57049 16.548 9.64679 16.5565 9.72733 16.5565C9.96894 15.9164 10.3971 15.2679 11.0286 14.6956C10.5242 14.5981 10.0113 14.5473 9.49843 14.5346C8.19712 14.5346 6.1074 14.9839 4.76795 15.963C4.26777 14.9245 3.98801 13.7631 3.98801 12.5339C3.98801 8.12552 7.57826 4.53526 11.9866 4.53526" />
      <path d="M17.4881 7.72323H16.5089V5.28169H14.0674V4.30677H16.5089V1.86523H17.4881V4.30677H19.9296V5.28169H17.4881V7.72323Z" />
      <path d="M17.9169 8.15533H16.0773V5.71379H13.6357V3.87416H16.0773V1.43262H17.9169V3.87416H20.3585V5.71379H17.9169V8.15533ZM16.942 7.29486H17.0564V4.85332H19.498V4.73887H17.0564V2.29733H16.942V4.73887H14.5005V4.85332H16.942V7.29486Z" />
    </g>
  </svg>
)

export { AddToCircle }
