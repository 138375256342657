import * as React from 'react'
import { useCroods } from 'croods'
import { getFullName } from 'shared/helpers'
import type { User } from 'types'
import type { AutocompleteInputProps } from 'shared/formsv2/autocomplete-input'
import AutocompleteInput from 'shared/formsv2/autocomplete-input'
import { matchSearch } from 'shared/helpers'

type Props = Omit<AutocompleteInputProps, 'options'>
function LeaderSearch(props: Props) {
  const [{ list, fetchingList }, { fetch }] = useCroods<User>({
    name: 'users',
    path: 'users/browse',
    cache: true,
  })

  return (
    <AutocompleteInput<User>
      {...props}
      onChange={() => fetch()()}
      options={list as User[]}
      loading={fetchingList}
      loadingText="Buscando usuários..."
      emptyText="Nenhum usuário encontrado"
      displayValue={(user) => getFullName(user ?? ({} as User))}
      filter={(user, val) => matchSearch(val, [getFullName(user), user?.email])}
      multiple={false}
    />
  )
}

export default LeaderSearch
