import React from 'react'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import CloudOffIcon from '@material-ui/icons/CloudOff'
import CachedIcon from '@material-ui/icons/Cached'

import { colors } from 'theme'

type Props = {
  handleDialog: () => void
  disabled: boolean
  saving: boolean
  saveError: string | null
}

const PublishCard = ({ handleDialog, disabled, saving, saveError }: Props) => {
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <>
              <Grid item>
                {!saving && !saveError && (
                  <CloudDoneIcon
                    style={{
                      color: colors.black54,
                    }}
                  />
                )}
                {saving && !saveError && (
                  <CachedIcon
                    style={{
                      color: colors.black54,
                    }}
                  />
                )}
                {saveError && (
                  <CloudOffIcon
                    style={{
                      color: colors.tangerine,
                    }}
                  />
                )}
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  style={{
                    lineHeight: '1.7',
                    fontWeight: 'bold',
                    color: saveError ? colors.tangerine : colors.black54,
                    width: '55px',
                  }}
                >
                  {!saving && !saveError && <span>Rascunho salvo</span>}
                  {saving && !saveError && <span>Salvando rascunho...</span>}
                  {saveError && <span>Erro ao salvar!</span>}
                </Typography>
              </Grid>
            </>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            className="w-[400px]"
            size="medium"
            startIcon={<CheckIcon />}
            onClick={handleDialog}
            disabled={disabled}
            variant={disabled ? 'outlined' : 'contained'}
          >
            Finalizar
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default PublishCard
