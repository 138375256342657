import * as React from 'react'
import { Fetch } from 'croods'
import HeaderControls from 'shared/ui/HeaderControls'
import Skeleton from 'shared/ui/skeleton'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import { useSearch } from 'shared/utils/hooks'
import Item from './Item'

const List = ({ pageTitle }: { pageTitle: string }) => {
  const { setSearch, select } = useSearch<{ id: number; name: string }>()
  return (
    <div>
      <h3>{pageTitle}</h3>
      <HeaderControls
        searchLabel="Busca rápida por solicitações"
        setSearch={setSearch}
      />
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4">
        <Fetch<{ id: number; name: string }[]>
          name="openSolicitation"
          path="workflow/solicitation_templates?status=published"
          render={(solicitations) =>
            select(solicitations, ({ name }) => name).map((item) => (
              <Item
                key={`${item.id}${item.name}`}
                name={item.name}
                link={`/portal/nova-solicitacao/${item.id}`}
              />
            ))
          }
          renderLoading={() => (
            <Skeleton height="20rem" className="col-span-2" />
          )}
          renderError={(error) =>
            error === 'not allowed to index? this Symbol' ? (
              <FeedbackMessage
                className="col-span-2"
                message=""
                serverMessage="Solicitações não habilitadas para esta organização"
              />
            ) : (
              <FeedbackMessage
                className="col-span-2"
                message="Solicitações não encontradas"
                serverMessage={error}
              />
            )
          }
          renderEmpty={() => (
            <FeedbackMessage
              className="col-span-2"
              serverMessage="Crie um template"
              type="warning"
              message="Não existem solicitações"
            />
          )}
        />
      </div>
    </div>
  )
}

export default List
