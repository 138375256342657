import { useAdminText } from 'shared/utils/hooks'

const useDescriptionTexts = () => {
  const tags = useAdminText()

  const goalsScore = {
    title: 'Ambição das metas',
    data: [
      { label: 'Sem referência', adminText: tags.teamEvalWithoutReference },
      { label: 'Conservadoras', adminText: tags.teamEvalConservative },
      { label: 'Realistas', adminText: tags.teamEvalRealistic },
      { label: 'Superiores', adminText: tags.teamEvalChallenging },
    ],
  }

  const resultsScore = {
    title: 'Resultados',
    data: [
      {
        label: 'Não atingimos os objetivos',
        adminText: tags.teamEvalWeDidNotAchieve,
      },
      {
        label: 'Alguns objetivos',
        adminText: tags.teamEvalWeAchievedSome,
      },
      { label: 'Atingimos os objetivos', adminText: tags.teamEvalWeAchieved },
    ],
  }

  const maturityScore = {
    title: 'Maturidade',
    data: [
      { label: 'Estrutura', adminText: tags.teamEvalStructure },
      { label: 'Confiança', adminText: tags.teamEvalTrust },
      { label: 'Acordo', adminText: tags.teamEvalAgreement },
      { label: 'Performance', adminText: tags.teamEvalPerformance },
    ],
  }

  const performanceScore = {
    title: 'Performance',
    data: [
      {
        label: tags.teamEvalWeDidNotAchieve,
        adminText: tags.teamEvalWeDidNotAchieve,
      },
      {
        label: tags.teamEvalWeAchievedSome,
        adminText: tags.teamEvalWeAchievedSome,
      },
      {
        label: tags.teamEvalWeAchievedMost,
        adminText: tags.teamEvalWeAchievedMost,
      },
      { label: tags.teamEvalWeAchieved, adminText: tags.teamEvalWeAchieved },
    ],
  }

  return { goalsScore, resultsScore, maturityScore, performanceScore }
}

export default useDescriptionTexts
