import { colors } from 'theme'

const buildLabelObject = (
  merit: string,
  risk: string,
  meritColor: string,
  riskColor: string,
  bgColor: string,
) => {
  return {
    merit,
    risk,
    meritColor,
    riskColor,
    bgColor,
  }
}

const labelColors = {
  'warning-high': buildLabelObject(
    'Mérito Alto',
    'Risco Baixo',
    colors.green,
    colors.yellow,
    'bg-yellow/10',
  ),
  'warning-medium': buildLabelObject(
    'Mérito Médio',
    'Risco Baixo',
    colors.yellow,
    colors.yellow,
    'bg-yellow/10',
  ),
  'warning-low': buildLabelObject(
    'Mérito Baixo',
    'Risco Baixo',
    colors.red,
    colors.yellow,
    'bg-yellow/10',
  ),
  'ideal-high': buildLabelObject(
    'Mérito Alto',
    'Risco Médio',
    colors.green,
    colors.green,
    'bg-green/10',
  ),
  'ideal-medium': buildLabelObject(
    'Mérito Médio',
    'Risco Médio',
    colors.yellow,
    colors.green,
    'bg-green/10',
  ),
  'ideal-low': buildLabelObject(
    'Mérito Baixo',
    'Risco Médio',
    colors.red,
    colors.green,
    'bg-green/10',
  ),
  'critical-high': buildLabelObject(
    'Mérito Alto',
    'Risco Alto',
    colors.green,
    colors.red,
    'bg-red/10',
  ),
  'critical-medium': buildLabelObject(
    'Mérito Médio',
    'Risco Alto',
    colors.yellow,
    colors.red,
    'bg-red/10',
  ),
  'critical-low': buildLabelObject(
    'Mérito Baixo',
    'Risco Alto',
    colors.red,
    colors.red,
    'bg-red/10',
  ),
}

export default labelColors
