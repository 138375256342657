import React, { useState } from 'react'
import TableContext from './Context'

type Props = {
  children: React.ReactNode
}

export type Error = {
  id: number
  error: { [index: string]: string }
}

export default function Wrapper({ children }: Props) {
  const [errors, setErrors] = useState<Error[]>([])

  const addErrors = (id: number, error: { [index: string]: string }) => {
    setErrors((state) => [
      ...state,
      {
        id,
        error,
      },
    ])
  }

  const removeErrors = (id: number) => {
    setErrors((state) => state.filter((err) => err.id !== id))
  }

  return (
    <TableContext.Provider value={{ addErrors, removeErrors, errors }}>
      {children}
    </TableContext.Provider>
  )
}
