import { cx } from 'shared/helpers'
import InfoTooltip from 'shared/ui/Tooltip/InfoTooltip'

type Props = {
  tooltip?: string
} & JSX.IntrinsicElements['th']

export default function Header({
  children,
  className,
  tooltip,
  ...props
}: Props) {
  return (
    <th className={cx('px-3 py-1', className)} {...props}>
      <div className="flex items-center justify-start space-x-2 text-left text-xs">
        <span className="flex-1">{children}</span>
        {tooltip && (
          <InfoTooltip>
            <p className="p-2 text-base font-light text-gray-darker">
              {tooltip}
            </p>
          </InfoTooltip>
        )}
      </div>
    </th>
  )
}
