import { Skeleton } from '@material-ui/lab'
import { useNavigate } from 'react-router-dom'
import { Fetch } from 'croods'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { getDenomination } from 'shared/helpers'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import Circle from '../circle'
import CounterChip from '../counter-chip'
import Table from '../table'
import { chartPath } from 'vibe/insights/chart-helpers'
import Avatar from 'shared/ui/Avatar'
import type { UserAvatar } from 'types'

type TeamPerformanceProps = {
  query:
    | {
        overview: boolean
      }
    | {
        team_id: string
        inner_circle: boolean
      }
  stateId: string | number
  showUpwardEvaluation?: boolean
  showConversationFrequency?: boolean
}

type CirclePerformanceTableRow = {
  circleId: number
  circleName: string
  circleType: string
  collaboratorsAboveOrEqualIdeal: number
  collaboratorsTotal: number
  positiveEvaluation: number
  negativeEvaluation: number
  missingEvaluation: number
}

function CirclePerformanceTable({
  list,
  showUpwardEvaluation,
  showConversationFrequency,
}: {
  list: CirclePerformanceTableRow[]
  showUpwardEvaluation: boolean
  showConversationFrequency: boolean
}) {
  const navigate = useNavigate()

  return (
    <Table title="Ciclo atual em detalhes">
      <Table.Head>
        <Table.Header
          tooltip="São contabilizados apenas os círculos que permaneceram ativos desde o início do ciclo. Círculos que foram criados durante o ciclo ou estão inativos não serão levados em consideração."
          className="w-[180px]"
        >
          Círculo
        </Table.Header>
        {showConversationFrequency && (
          <Table.Header
            className="w-[150px]"
            tooltip="Colaboradores com frequência de conversa desejada"
          >
            Frequência das conversas
          </Table.Header>
        )}
        {showUpwardEvaluation && (
          <Table.Header
            className="w-[400px]"
            tooltip="Avaliações ascendentes dos liderados"
          >
            Avaliações ascendentes
          </Table.Header>
        )}
      </Table.Head>
      <Table.Body>
        {list.map(
          (
            {
              circleId,
              circleName,
              circleType,
              collaboratorsTotal,
              collaboratorsAboveOrEqualIdeal,
              positiveEvaluation,
              negativeEvaluation,
              missingEvaluation,
            }: CirclePerformanceTableRow,
            index: number,
          ) => (
            <Table.Row key={index} index={index}>
              <Table.Cell className="overflow-hidden text-ellipsis whitespace-nowrap">
                <button
                  type="button"
                  onClick={() => navigate(`/insights/${circleId}`)}
                  className="cursor-pointer text-blue-400 hover:underline"
                >
                  {circleName} [{getDenomination(circleType)}]
                </button>
              </Table.Cell>
              {showConversationFrequency && (
                <Table.Cell>
                  {collaboratorsTotal ? (
                    <Circle
                      color={
                        collaboratorsAboveOrEqualIdeal >= collaboratorsTotal
                          ? 'bg-green'
                          : 'bg-red'
                      }
                      size={4}
                    >
                      {`${collaboratorsAboveOrEqualIdeal}/${collaboratorsTotal} colaboradores`}
                    </Circle>
                  ) : (
                    'Nenhum colaborador neste ciclo'
                  )}
                </Table.Cell>
              )}
              {showUpwardEvaluation && (
                <Table.Cell className="flex flex-wrap gap-1">
                  <CounterChip
                    color="bg-green"
                    count={positiveEvaluation}
                    text="Avaliações positivas"
                  />
                  <CounterChip
                    color="bg-yellow"
                    count={negativeEvaluation}
                    text="Oportunidades de melhoria"
                  />
                  <CounterChip
                    color="bg-red"
                    count={missingEvaluation}
                    text="Sem avaliação"
                  />
                </Table.Cell>
              )}
            </Table.Row>
          ),
        )}
      </Table.Body>
    </Table>
  )
}

const EVALUATION_MAP = {
  radical_candor: 'Franqueza com Empatia',
  obnoxious_aggression: 'Franqueza com Indiferença',
  ruinous_empathy: 'Medo de Falar a Verdade',
  manipulative_insincerity: 'Mentira com Indiferença',
}

type UserPerformanceTableRow = {
  avatar: UserAvatar
  userId: number
  userName: string
  userRole: string
  totalConversations: number
  aboveOrEqualIdealConversation: number
  lastUpwardEvaluation: keyof typeof EVALUATION_MAP | null
}

function UserPerformanceTable({
  list,
  showUpwardEvaluation,
  showConversationFrequency,
}: {
  list: UserPerformanceTableRow[]
  showUpwardEvaluation: boolean
  showConversationFrequency: boolean
}) {
  const getChipColor = (
    lastUpwardEvaluation: UserPerformanceTableRow['lastUpwardEvaluation'],
  ) => {
    switch (lastUpwardEvaluation) {
      case 'radical_candor':
        return 'bg-green'
      case 'obnoxious_aggression':
        return 'bg-yellow'
      case 'ruinous_empathy':
        return 'bg-yellow'
      default:
        return 'bg-red'
    }
  }

  return (
    <Table title="Ciclo atual em detalhes">
      <Table.Head>
        <Table.Header
          tooltip="São contabilizados apenas os colaboradores que respondem a este círculo desde o início do ciclo. Colaboradores que entraram ou saíram durante o ciclo, ou estão inativos, não serão levados em consideração."
          className="w-[180px]"
        >
          Colaboradores
        </Table.Header>
        {showConversationFrequency && (
          <Table.Header
            className="w-[150px]"
            tooltip="Frequência de conversa desejada do colaborador"
          >
            Frequência das conversas
          </Table.Header>
        )}
        {showUpwardEvaluation && (
          <Table.Header
            className="w-[400px]"
            tooltip="Avaliação ascendente do liderado"
          >
            Última avaliação ascendente
          </Table.Header>
        )}
      </Table.Head>
      <Table.Body>
        {list.map((item: UserPerformanceTableRow, index: number) => (
          <Table.Row key={index} index={index}>
            <Table.Cell className="overflow-hidden text-ellipsis whitespace-nowrap">
              <div className="flex w-max items-center break-words">
                <div className="mr-4">
                  <Avatar user={item.avatar} />
                </div>
                <div className="flex max-w-[200px] flex-col justify-center">
                  <h5 className="subtitle2 whitespace-nowrap">
                    {item.userName}
                  </h5>
                  <p className="body2 whitespace-nowrap text-black/50">
                    {item.userRole}
                  </p>
                </div>
              </div>
            </Table.Cell>
            {showConversationFrequency && (
              <Table.Cell>
                <Circle
                  color={
                    item.totalConversations >=
                    item.aboveOrEqualIdealConversation
                      ? 'bg-green'
                      : 'bg-red'
                  }
                  size={4}
                >
                  {`${item.totalConversations}/${item.aboveOrEqualIdealConversation} conversas realizadas`}
                </Circle>
              </Table.Cell>
            )}
            {showUpwardEvaluation && (
              <Table.Cell>
                <div className="flex min-w-max">
                  <CounterChip
                    color={getChipColor(item.lastUpwardEvaluation)}
                    text={
                      item.lastUpwardEvaluation
                        ? get(EVALUATION_MAP, item.lastUpwardEvaluation)
                        : 'Não fez avaliação'
                    }
                  />
                </div>
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

export default function TeamPerformance({
  query,
  stateId,
  showUpwardEvaluation = true,
  showConversationFrequency = true,
}: TeamPerformanceProps) {
  return (
    <Fetch
      name="charts"
      customPath={chartPath('team_performance', query)}
      stateId={stateId}
      query={query}
      renderLoading={() => <Skeleton />}
      render={(_, [{ list, listError }]) => {
        if (listError)
          return <FeedbackMessage message={listError} type="warning" />

        if (isEmpty(list)) return null

        if (!('overview' in query) && !query?.inner_circle)
          return (
            <UserPerformanceTable
              list={list}
              showUpwardEvaluation={showUpwardEvaluation}
              showConversationFrequency={showConversationFrequency}
            />
          )

        return (
          <CirclePerformanceTable
            list={list}
            showUpwardEvaluation={showUpwardEvaluation}
            showConversationFrequency={showConversationFrequency}
          />
        )
      }}
    />
  )
}
