import React from 'react'

const Circle = ({
  color = 'bg-green',
  size = 4,
  circleText = '',
  children = '',
}) => (
  <div className="flex items-center">
    <div
      style={{ width: `${size / 4}rem`, height: `${size / 4}rem` }}
      className={`flex rounded-full ${color} mr-1 items-center justify-center font-bold text-white`}
    >
      {circleText}
    </div>
    <div className="mr-2">{children}</div>
  </div>
)

export default Circle
