import get from 'lodash/get'
import find from 'lodash/find'
import { useCroods } from 'croods'
import type { FormTemplate } from 'types'

type Names = 'one_on_one' | 'permanent' | 'upward_evaluation'

const idToNames: Record<string, Names> = {
  '1:1': 'one_on_one',
  oneOnOne: 'one_on_one',
  permanent: 'permanent',
  evaluation: 'upward_evaluation',
  upwardevaluation: 'upward_evaluation',
}

export const useTemplate = (templateName: Names | string): FormTemplate => {
  const id = templateName.toLowerCase()

  const [{ list }] = useCroods<FormTemplate>({
    name: 'conversationTemplates',
    fetchOnMount: true,
  })

  const name = get(idToNames, id, id)

  return (
    find(list, { name }) ?? {
      id: 0,
      name: '',
      howToConduct: '',
      icon: '',
      topics: '',
    }
  )
}
