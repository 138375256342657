import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { useCroods } from 'croods'
import moment from 'moment'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import Star from 'talks/conversations/career/shared/careerGoals/Star'
import type { Goal } from 'talks/conversations/career/shared/careerGoals/types'
import { cx } from 'shared/helpers'
import Tooltip from './tooltip'

type Props = {
  id: number
  snapshot?: boolean
}

export default function Goals({ id, snapshot = false }: Props) {
  const path = `/career_conversations/${id}/career_goals_snapshot`

  const [{ list: rawList, fetchingList, listError }] = useCroods<Goal>({
    name: 'careerGoals',
    path,
    fetchOnMount: true,
  })

  if (fetchingList) {
    return <Skeleton />
  }

  if (listError) {
    return (
      <FeedbackMessage
        message="Objetivos não encontrados"
        serverMessage=""
        type="error"
      />
    )
  }

  const list = snapshot
    ? rawList.filter(({ completedAt }) => !completedAt)
    : rawList

  if (list.length === 0) {
    return (
      <FeedbackMessage message="Nenhum objetivo encontrado" serverMessage="" />
    )
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 sm:gap-x-8">
      {list.map(({ id: goalId, description, createdAt, completedAt }) => (
        <Tooltip key={goalId} text={description}>
          <div
            className={cx(
              'flex overflow-hidden rounded-3xl shadow-md',
              'mb-4',
              completedAt && snapshot && 'bg-green-50',
            )}
            key={goalId}
          >
            <Star
              status={!completedAt ? 'ongoing' : 'completed'}
              displayOnly={Boolean(!(completedAt && snapshot))}
            />
            <div className={cx('overflow-hidden')}>
              <div className="flex h-full flex-col justify-center px-4">
                <p
                  className={cx(
                    'text-base font-bold text-gray-darkest',
                    'overflow-hidden text-ellipsis whitespace-nowrap',
                  )}
                >
                  {description}
                </p>
                {completedAt && snapshot ? (
                  <p className="text-xs text-green">Objetivo alcançado</p>
                ) : (
                  <p className="text-xs text-gray">
                    {moment(createdAt).fromNow()}
                  </p>
                )}
              </div>
            </div>
          </div>
        </Tooltip>
      ))}
    </div>
  )
}
