import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import get from 'lodash/get'
import Input from 'shared/forms/Input'
import { colors } from 'theme'

interface Props {
  [index: string]: any
}

export default function AutocompleteInput({
  disabled,
  label,
  error,
  name,
  helper,
  placeholder,
  params,
  loading,
  ...props
}: Props) {
  return (
    <Input
      label={label}
      error={error}
      name={name}
      title={name}
      variant="outlined"
      helper={helper}
      placeholder={placeholder}
      {...params}
      InputProps={{
        autoComplete: 'off',
        disabled,
        readOnly: disabled,
        ...get(props, 'InputProps'), // retorna objeto de props
        ...get(params, 'InputProps'),
        endAdornment: loading ? (
          <InputAdornment position="end">
            <CircularProgress size={20} style={{ color: colors.blue }} />
          </InputAdornment>
        ) : null,
      }}
    />
  )
}
