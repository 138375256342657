import * as React from 'react'
import { Fetch, useCroods } from 'croods'
import Select from 'shared/forms/Select'
import Skeleton from 'shared/ui/skeleton'
import CloudSavingFeedback from 'shared/ui/CloudSavingFeedback'

const initializeOption = ({
  createdTeamId,
  initialMainTeam,
  isCircleLeader,
}: any) => {
  const fixedOption = {
    value: createdTeamId ?? 0,
    label: isCircleLeader ? '------' : 'A este círculo',
  }
  const initialOption = initialMainTeam.value ? initialMainTeam : fixedOption
  return { fixedOption, initialOption }
}

const parseOptions = ({
  options,
  initialOption,
  fixedOption,
  isCircleLeader,
}: any) => {
  const defaultItems = {
    hasNoTeam: false,
    uniqueOptions: [initialOption],
    disableSelectable: initialOption.value === 0 && isCircleLeader,
  }

  if (options.length === 0) return defaultItems

  const uniqueOptions = options.filter(
    ({ value }: any) => value !== initialOption.value,
  )

  if (initialOption.value !== fixedOption.value && !isCircleLeader) {
    const currentCircleIndex = uniqueOptions.findIndex(
      (opt: any) => opt.value === fixedOption.value,
    )

    if (currentCircleIndex != -1) {
      uniqueOptions.splice(currentCircleIndex, 1)
    }

    uniqueOptions.push(fixedOption)
  }

  return {
    ...defaultItems,
    hasNoTeam: uniqueOptions.length === 0,
    uniqueOptions: [initialOption, ...uniqueOptions],
  }
}

const handleChange =
  ({ setOption, save }: any) =>
  (e: any) => {
    setOption(e.target.value)

    return save()({
      mainTeamId: e.target.value,
    })
  }

const RespondTo = ({
  createdTeamId,
  userId,
  memberId,
  isCircleLeader,
  initialMainTeam,
}: any) => {
  const { initialOption, fixedOption } = initializeOption({
    createdTeamId,
    initialMainTeam,
    isCircleLeader,
  })

  const [option, setOption] = React.useState(initialOption.value)
  const [response, setResponse] = React.useState(false)

  const [{ saving, saveError }, { save }] = useCroods({
    name: 'users',
    customPath: `/users/:id/set_main_team`,
    stateId: `${userId}`,
    id: userId,
    afterSuccess: () => setResponse(true),
  })

  return (
    <Fetch
      name="teams"
      customPath={`/users/${userId}/responds_to_circles`} //
      stateId={`${userId}`}
      render={(options) => {
        const { hasNoTeam, uniqueOptions, disableSelectable } = parseOptions({
          options,
          initialOption,
          fixedOption,
          isCircleLeader,
        })

        return (
          <div className="flex">
            <Select
              name="selectTeam"
              label=""
              disabled={!memberId || hasNoTeam || disableSelectable}
              onChange={handleChange({ setOption, save })}
              value={option}
              options={uniqueOptions}
            />
            <div className="ml-4 self-center">
              <CloudSavingFeedback
                saved={response}
                saving={saving}
                saveError={saveError}
              />
            </div>
          </div>
        )
      }}
      renderLoading={() => <Skeleton className="!h-8 !w-40 rounded" />}
      renderError={() => (
        <p className="body2 m-0 text-red-light">Erro ao carregar</p>
      )}
    />
  )
}

export default RespondTo
