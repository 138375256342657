import ReactQuill from 'react-quill'
import { cx } from 'shared/helpers'

type DevelopmentPlanInputProps = {
  name?: string
  value?: string
  defaultValue?: string
  className?: string
  disabled?: boolean
  onChange?: (s: string) => void
  placeholder?: string
}
const DevelopmentPlanInput = ({
  name = 'developmentPlan',
  value,
  defaultValue,
  className,
  disabled = false,
  onChange,
  placeholder,
}: DevelopmentPlanInputProps) => (
  <ReactQuill
    id={name}
    value={value}
    defaultValue={defaultValue}
    placeholder={placeholder || ''}
    readOnly={disabled}
    theme="bubble"
    className={cx(
      'fix-ql-editor-area w-full rounded border border-gray-500 text-base focus-within:border-2 focus-within:border-blue-light',
      disabled ? 'bg-gray-light text-gray-dark' : 'text-gray-darkest',
      className,
    )}
    onChange={(_value, _, __, editor) => {
      onChange?.(editor.getHTML())
    }}
    modules={{
      toolbar: [
        ['bold', 'italic', { header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
      ],
    }}
  />
)

export default DevelopmentPlanInput
