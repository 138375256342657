import { Link } from 'react-router-dom'
import { getDenomination } from 'shared/helpers'

const ParentTeams = ({
  name,
  parentTeams = [],
  denomination,
  variant,
}: any) => {
  if (!parentTeams.length) return null

  const link = variant?.includes('insights') ? 'insights' : 'circulos'

  return (
    <div className="flex gap-2 w-max items-center break-words">
      <div className="mr-2 shrink-0">
        <img
          src="/contido.svg"
          height="38"
          width="38"
          alt="Contido no círculo"
        />
      </div>
      <div className="flex max-w-[900px] flex-col justify-center">
        <h5 className="subtitle1 text-lg break-words">
          {name} [{denomination}] está contido no círculo
        </h5>
        <div className="flex flex-wrap items-center mt-2 gap-2">
          {parentTeams.map(({ id, name, denomination }: any) => (
            <div key={id}>
              <Link to={`/${link}/${id}`} className="block">
                <div className="rounded-full text-base border-[1px] border-gray-dark/60 px-2 py-[3px] font-semibold text-gray-dark hover:bg-gray/10">
                  {`${name} [${getDenomination(denomination)}]`}
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ParentTeams
