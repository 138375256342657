import * as z from 'zod'
import { parseContent } from 'shared/formsv2/richtext-input'
import { notFutureDateSchema } from 'shared/schema-helpers'

const schema = z.object({
  date: notFutureDateSchema,
  admissionDate: notFutureDateSchema,
  comment: z
    .string()
    .transform(parseContent)
    .refine((val) => (val?.text?.length ?? 0) > 1, 'Não pode ficar em branco')
    .refine((val) => (val?.text?.length ?? 0) >= 10, 'Texto deve ter no mínimo 10 caracteres')
    .transform((val) => val!.html),
})

export { schema }
