import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'

import type { Role } from 'performance/evaluations/membersEvaluation/types'

type Props = {
  allRoles: Role[]
  role: Role | null
  handleChange: (value: any, action?: 'add' | undefined) => void
  getDisabledRoles: (role: Role) => boolean
}

const filter = createFilterOptions<Role>()

const PrimaryRole = ({
  allRoles,
  role,
  handleChange,
  getDisabledRoles,
}: Props) => {
  const [error, setError] = React.useState('')

  const handleFilter = (options: any, params: any) => {
    const filtered = filter(options, params)

    if (params.inputValue !== '') {
      filtered.push({
        id: 0,
        inputValue: params.inputValue,
        name: params.inputValue,
      })
    }

    return filtered
  }

  const getOptionSelected = (option: Role, value: Role) =>
    option.id === value.id

  const renderOption = (option: Role) => {
    if (option.inputValue) {
      return (
        <span className="font-bold uppercase text-blue">
          Adicionar &quot;{option.name}&quot;
        </span>
      )
    }
    return <span>{option.name}</span>
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        disablePortal
        style={{ width: '100%' }}
        size="small"
        options={allRoles}
        noOptionsText="Papel não encontrado"
        disableClearable
        value={role!}
        onChange={(_: React.ChangeEvent<{}>, value: Role) => {
          setError('')
          if (value && value.inputValue) {
            if (value.inputValue.length <= 120) {
              handleChange(value.inputValue, 'add')
            } else {
              setError('O papel principal só pode conter 120 caracteres.')
            }
          } else {
            handleChange(value)
          }
        }}
        renderOption={renderOption}
        getOptionSelected={getOptionSelected}
        getOptionDisabled={getDisabledRoles}
        getOptionLabel={(option) => option.name}
        filterOptions={handleFilter}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        data-testid="primaryRole"
      />
      {error && <span className="my-1 text-red">{error}</span>}
    </FormControl>
  )
}

export default PrimaryRole
