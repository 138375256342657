import * as React from 'react'
import { useCroods } from 'croods'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import isEmpty from 'lodash/isEmpty'

function useDataRefreshOnInterval(
  teamMemberId: number,
  careerSkillId: number,
  callbackSuccess: (data: any) => void,
  timeout = 3000,
) {
  const interval = React.useRef<ReturnType<typeof setInterval> | null>(null)
  const [gptSessionId, setGptSessionId] = React.useState<number | null>(null)
  const [running, setRunning] = React.useState(false)
  const { snackbar } = useSnackbar()

  const resetStates = () => {
    setRunning(false)
    if (interval.current) clearInterval(interval.current)
  }

  const [, { fetch }] = useCroods({
    name: 'gptSession',
    path: `/team_members/${teamMemberId}/career_skills/${careerSkillId}/gpt_sessions`,
    afterSuccess: ({ data }) => {
      if (data?.completed) {
        if (isEmpty(data?.apiErrors)) {
          const content = JSON.parse(data.content)
          callbackSuccess(content ?? [])
          resetStates()
        } else {
          resetStates()
          snackbar({
            message:
              'Ocorreu um erro na comunicação com a AI. Tente novamente mais tarde',
            type: 'error',
          })
        }
      }
    },
    afterFailure: () => {
      resetStates()
      snackbar({
        message:
          'Ocorreu um erro na comunicação com a AI. Tente novamente mais tarde',
        type: 'error',
      })
    },
  })

  React.useEffect(() => {
    if (running && gptSessionId) {
      interval.current = setInterval(() => {
        fetch({ id: gptSessionId })()
      }, timeout)
    } else {
      resetStates()
    }
    return () => {
      if (interval.current) return clearInterval(interval.current)
    }
  }, [running, gptSessionId]) // eslint-disable-line

  return { running, setRunning, setGptSessionId }
}

export { useDataRefreshOnInterval }
