import { match, P } from 'ts-pattern'

type InsightComponentProps = {
  query:
    | {
        overview: boolean
      }
    | {
        team_id: string
        inner_circle: boolean
      }
  stateId: string | number
}

function chartPath(
  kind:
    | 'upward_evaluation'
    | 'total_retention'
    | 'conversation_frequency'
    | 'talent_retention'
    | 'contribution_level'
    | 'twelve_box'
    | 'team_performance'
    | 'recursive_nine_box'
    | 'team_development_support'
    | 'team_evaluations'
    | 'self_evaluation'
    | 'current_cycle_conversations',
  query: InsightComponentProps['query'],
) {
  const scope = match(query)
    .with({ team_id: P.string }, ({ team_id }) => 'teams/' + String(team_id))
    .with({ overview: true }, () => 'all_teams')
    .otherwise(() => 'led_teams')

  return ['insights', scope, kind].join('/')
}

function getRetentionPercentage(activeValue: number, baseValue: number) {
  return baseValue > 0 ? Math.trunc((activeValue / baseValue) * 100) : 100
}

export { chartPath, getRetentionPercentage }
export type { InsightComponentProps }
