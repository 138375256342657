import ForgotSent from 'shared/auth/ForgotSent'
import ForgotPassword from 'shared/auth/ForgotPassword'
import ResetPassword from 'shared/auth/ResetPassword'
import Invite from 'shared/auth/invite'

import type { LegacyRoute } from 'types'

const forgotSend: LegacyRoute = {
  component: ForgotSent,
  path: '/forgot-sent',
  pageTitle: 'Email com instruções',
}
const forgotPassword: LegacyRoute = {
  component: ForgotPassword,
  path: '/forgot-password',
  pageTitle: 'Esqueceu sua senha?',
}
const resetPassword: LegacyRoute = {
  component: ResetPassword,
  path: '/reset-password',
  pageTitle: 'Reset senha',
}
const AcceptInvite: LegacyRoute = {
  component: Invite,
  path: '/aceitar-convite/:token/criar-senha',
  pageTitle: 'Convite',
}

export default [forgotSend, forgotPassword, resetPassword, AcceptInvite]
