import React from 'react'

import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { colors } from 'theme'

import useOrganization from 'shared/organization/useOrganization'
import type { EvaluationWindowStatus, EvaluationWindowStatusMap } from 'types'

const noticeIcons: Partial<EvaluationWindowStatusMap> = {
  closed: '/empty.png',
  'almost-late': '/alert.png',
  late: '/late.png',
}

const noticeColors: EvaluationWindowStatusMap = {
  closed: colors.grey,
  open: colors.blue,
  'almost-late': colors.yellow,
  late: colors.tangerine,
}

const buttonColors: EvaluationWindowStatusMap = {
  closed: colors.grey,
  open: colors.blue,
  'almost-late': colors.blue,
  late: colors.tangerine,
}

const noticePhrases: EvaluationWindowStatusMap = {
  closed: 'Membros sem avaliação',
  open: '',
  'almost-late': 'Menos de uma semana para avaliar',
  late: 'Avaliação atrasada',
}

const Notice = () => {
  const { evaluationWindowStatus: status } = useOrganization() as {
    evaluationWindowStatus: EvaluationWindowStatus
  }
  return (
    <div className="mt-8 flex flex-col items-center text-center">
      <h4 className="flex gap-2" style={{ color: noticeColors[status] }}>
        {noticeIcons[status] && (
          <img
            src={noticeIcons[status]}
            alt={status}
            className="aspect-square w-6"
          />
        )}
        {noticePhrases[status]}
      </h4>

      {status !== 'closed' ? (
        <Button
          endIcon={<ArrowForwardIcon />}
          type="button"
          size="small"
          component={Link}
          to="/performance/avaliar-talentos"
          className="!md:mt-8"
          variant="outlined"
          style={{
            borderColor: buttonColors[status],
            color: buttonColors[status],
            marginLeft: 'auto',
            marginRight: '0',
          }}
        >
          Avaliar talentos
        </Button>
      ) : null}
    </div>
  )
}

export default Notice
