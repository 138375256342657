import { makeStyles } from '@material-ui/core/styles'
import { getUserIdentifier } from 'shared/helpers'
import Tooltip from '@material-ui/core/Tooltip'
import identity from 'lodash/identity'
import Avatar from 'shared/ui/Avatar'
import Icon from 'shared/ui/icon'

import type { User, TeamMember } from './index'

const useClasses = makeStyles(() => ({
  avatarWrapper: {
    background:
      'radial-gradient(2714.48% 445.24% at 194.53% 192.86%, #BC9EC7 0%, rgba(118, 146, 202, 0.433774) 48.56%, rgba(118, 146, 202, 0.433774) 100%);',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    borderRadius: '1rem',
  },

  avatar: {
    width: 32,
    height: 32,
    position: 'relative',
    '&::after': {
      borderRadius: '50%',
      content: '""',
      background:
        'linear-gradient(225deg, rgba(106, 151, 206, 0.5) 0%, rgba(239, 97, 164, 0.5) 100%)',
      backgroundBlendMode: 'lighten',
      opacity: 0.8,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
}))

const TooltipAlert = ({
  user,
  children,
}: {
  user: User
  children: JSX.Element
}) => {
  const tooltipContent = (
    <div>
      <p>Cadastro não completo</p>
      <p>{getUserIdentifier({ user })}</p>
    </div>
  )

  const hasTooltip = ![user?.firstName, user?.lastName].every(identity)

  if (hasTooltip)
    return (
      <Tooltip title={tooltipContent} interactive>
        <div className="flex items-center justify-between">
          {children}{' '}
          <Icon icon="info" className="h-auto max-w-[14px] text-yellow" />
        </div>
      </Tooltip>
    )

  return children
}

export default function Header({ teamMember }: { teamMember: TeamMember }) {
  const { user, teamName } = teamMember
  const { role } = user
  const classes = useClasses()

  const username = getUserIdentifier({ user })

  return (
    <div className="flex gap-2 bg-gray/10 p-2">
      <div className={classes.avatarWrapper}>
        <Avatar user={user} size={32} />
      </div>
      <div className="flex w-[calc(100%_-_40px)] flex-col justify-center">
        <TooltipAlert user={user}>
          <h5 className="subtitle2 overflow-hidden text-ellipsis whitespace-nowrap">
            {username}
          </h5>
        </TooltipAlert>
        <p className="body2 overflow-hidden text-ellipsis whitespace-nowrap text-gray-dark">
          {role} [{teamName}]
        </p>
      </div>
    </div>
  )
}
