import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useCurrentUser } from 'croods-auth'
import { useSearch } from 'shared/utils/hooks'
import HeaderControls from 'shared/ui/HeaderControls'
import CirclesList from './CirclesList'
import debounce from 'lodash/debounce'
import Icon from 'shared/ui/icon'

const TEAMS = {
  USER: 'Meus círculos',
  ALL: 'Todos os círculos',
}

const TeamList = ({ pageTitle }: any) => {
  const [{ currentUser }] = useCurrentUser()

  const { setSearch, searchTerm } = useSearch()
  const delayedRequest = useRef(
    debounce((value) => {
      setSearch(value)
    }, 600),
  ).current

  const [active, setActive] = useState(TEAMS.USER)
  const isPersonalList = active === TEAMS.USER
  const userStateId = isPersonalList ? currentUser.id : undefined

  return (
    <>
      <h3>{pageTitle}</h3>
      <HeaderControls
        filters={TEAMS}
        filter={active}
        setFilter={setActive}
        searchLabel="Buscar círculo"
        setSearch={delayedRequest}
      >
        <Link className="bt bt-contained gap-2" to="criar-novo-circulo">
          <Icon icon="add-circle-outline" size="sm" />
          <span>Criar círculo</span>
        </Link>
      </HeaderControls>

      <CirclesList searchTerm={searchTerm} userStateId={userStateId} />
    </>
  )
}

export default TeamList
