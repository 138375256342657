import Menu from '@material-ui/core/Menu'
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import EditIcon from '@material-ui/icons/Edit'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SignOut from 'shared/ui/SignOut'
import { Link } from 'react-router-dom'
import Avatar from 'shared/ui/Avatar'

export default function AvatarMenu({ currentUser }: any) {
  const [element, setElement] = React.useState<any>(null)

  const handleClick: React.MouseEventHandler = (event) => {
    setElement(event.currentTarget)
  }

  const handleClose = () => {
    setElement(null)
  }

  return (
    <>
      <button type="button" onClick={handleClick} className="cursor-pointer">
        <Avatar user={currentUser} size={32} />
      </button>
      <Menu
        elevation={1}
        marginThreshold={50}
        anchorEl={element}
        open={Boolean(element)}
        onClose={handleClose}
        keepMounted
      >
        <div className="min-w-[260px]">
          <MenuItem
            className="flex gap-2 text-xs transition hover:!bg-blue-50"
            role="button"
            component={Link}
            to="/editar-perfil"
          >
            <EditIcon fontSize="small" />
            <span>Editar Perfil</span>
          </MenuItem>
          <SignOut>
            <PowerSettingsNewIcon fontSize="small" />
            Sair
          </SignOut>
        </div>
      </Menu>
    </>
  )
}
