import * as React from 'react'
import InfoTooltip from 'shared/ui/Tooltip/InfoTooltip'
import { useCroods } from 'croods'
import { getDenomination } from 'shared/helpers'
import { Link } from 'react-router-dom'

import TeamCard from 'shared/ui/teamMembersCard/Card'
import type { FilterState } from './team-insights'
import { useRedirectWhenUnauthenticated } from 'shared/utils/hooks'
import useOrganization from 'shared/organization/useOrganization'

type HeaderProps = {
  query: { overview: boolean }
}

const Header = ({ query }: HeaderProps) => {
  const [{ list, fetchingList }] = useCroods({
    name: 'insightCircles',
    path: '/insights/header',
    stateId: query?.overview ? 'insightCircles-overview' : 'insightCircles',
    query,
    fetchOnMount: true,
  })

  const TooltipContent = () => (
    <div className="p-2 text-left">
      {!query.overview ? (
        <h6 className="text-base font-light text-gray-darker">
          Os dados exibidos são referente aos círculos que você é líder e
          contemplam, além dos colaboradores que respondem aos próprios
          círculos, os colaboradores que respondem aos círculos contidos neles.
        </h6>
      ) : (
        <h6 className="text-base font-light text-gray-darker">
          Os dados exibidos são referente aos círculos que estão no topo da
          organização (ou seja, não estão contidos em nenhum outro círculo) e
          contemplam, além dos colaboradores que respondem aos próprios
          círculos, os colaboradores que respondem aos círculos contidos neles.
        </h6>
      )}
    </div>
  )

  return (
    <div className="mt-9 rounded-2xl shadow-card">
      <div className="header-card flex items-center gap-4 rounded-t-2xl">
        <h4>{!query.overview ? 'Círculos em que sou líder' : 'Visão Total'}</h4>
        <InfoTooltip htmlColor="#FFFFFF" fontSize="medium">
          <TooltipContent />
        </InfoTooltip>
      </div>
      {fetchingList ? (
        <div className="px-4 py-6 text-center animate-pulse">Carregando...</div>
      ) : (
        <div className="flex flex-wrap gap-2 px-4 py-6">
          {list.map((circle) => (
            <div key={`${circle.label} - ${circle.teamId}`}>
              <Link to={`/insights/${circle.teamId}`} className="block">
                <div className="rounded-full border-[1px] border-gray-dark/60 px-2 py-[3px] font-semibold text-gray-dark hover:bg-gray/10">
                  {circle.label}
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

type TeamInsightsHeaderProps = {
  query: {
    inner_circle: boolean
    team_id: string
  }
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>
  setDefaultActive: React.Dispatch<React.SetStateAction<FilterState>>
  setShowSwitcher: React.Dispatch<React.SetStateAction<boolean>>
  setShowConversationFrequency: React.Dispatch<React.SetStateAction<boolean>>
  setShowPerformanceSection: React.Dispatch<React.SetStateAction<boolean>>
  setShowTalentSection: React.Dispatch<React.SetStateAction<boolean>>
  setShowUpwardEvaluation: React.Dispatch<React.SetStateAction<boolean>>
  setShowTeamDevelopmentSupport: React.Dispatch<React.SetStateAction<boolean>>
  setShowConversationPanel: React.Dispatch<React.SetStateAction<boolean>>
}

const TeamInsightsHeader = ({
  query,
  setErrorMessage,
  setShowSwitcher,
  setDefaultActive,
  setShowConversationFrequency,
  setShowPerformanceSection,
  setShowTalentSection,
  setShowUpwardEvaluation,
  setShowTeamDevelopmentSupport,
  setShowConversationPanel,
}: TeamInsightsHeaderProps) => {
  const redirectWhenUnauthenticated = useRedirectWhenUnauthenticated()
  const organization = useOrganization()
  const [{ info: team, fetchingInfo, infoError }] = useCroods({
    name: 'teams',
    id: query.team_id,
    stateId: query.team_id,
    path: `/insights/header`,
    fetchOnMount: true,
    after4xx: redirectWhenUnauthenticated(() => {
      setErrorMessage &&
        setErrorMessage('Você não possui acesso aos Insights deste círculo')
    }),
    after5xx: () => {
      setErrorMessage && setErrorMessage('Algum erro ocorreu')
    },
    afterSuccess: ({ data }) => {
      setErrorMessage?.(
        data?.childTeams?.length === 0 && data?.members?.length === 0
          ? 'Não existem dados para Insights pois este círculo não possui membros que respondem a ele e nem contém outros círculos'
          : '',
      )
    },
  })

  const TooltipContent = () => (
    <div className="p-2 text-left">
      {query.inner_circle ? (
        <h6 className="text-base font-light text-gray-darker">
          Os dados exibidos são referentes aos colaboradores que respondem aos
          círculos que estão contidos neste círculo.{' '}
          <span className="font-bold">
            Colaboradores que respondem diretamente
          </span>{' '}
          ao círculo selecionado{' '}
          <span className="font-bold">não estão sendo considerados.</span>
        </h6>
      ) : (
        <h6 className="text-base font-light text-gray-darker">
          Os dados exibidos são referente aos{' '}
          <span className="font-bold">
            colaboradores que respondem diretamente
          </span>{' '}
          ao círculo selecionado. Colaboradores que respondem aos{' '}
          <span className="font-bold">
            círculos contidos não estão sendo considerados.
          </span>
        </h6>
      )}
    </div>
  )

  React.useEffect(() => {
    if (!fetchingInfo) {
      if (team.childTeams.length && team.members.length) {
        setDefaultActive('CÍRCULOS CONTIDOS')
        setShowSwitcher(true)
      } else if (team.childTeams.length === 0) {
        setDefaultActive('SOMENTE ESTE CÍRCULO')
        setShowSwitcher(false)
      } else if (team.members.length === 0) {
        setDefaultActive('CÍRCULOS CONTIDOS')
        setShowSwitcher(false)
      }

      if (query.inner_circle) {
        setShowUpwardEvaluation(organization?.permitUpwardEvaluation)
        setShowConversationFrequency(
          organization?.permitOneOnOneConversation ||
            organization?.permitCareerConversation,
        )
        setShowConversationPanel(
          organization.permitOneOnOneConversation ||
            organization.permitCareerConversation,
        )
        setShowTeamDevelopmentSupport(organization.permitCareerConversation)

        setShowPerformanceSection(organization.permitTeamsEvaluations)
        setShowTalentSection(organization.permitTalentsEvaluations)
      } else {
        setShowUpwardEvaluation(team.abilities.upwardEvaluation)
        setShowConversationFrequency(
          team.abilities.careerConversation ||
            team.abilities.oneOnOneConversation,
        )
        setShowConversationPanel(
          team.abilities.careerConversation ||
            team.abilities.oneOnOneConversation,
        )
        setShowTeamDevelopmentSupport(team.abilities.careerConversation)

        setShowPerformanceSection(team.abilities.teamsEvaluations)
        setShowTalentSection(team.abilities.talentsEvaluations)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team, query, organization])

  if (infoError) return null

  if (fetchingInfo)
    return (
      <div role="header-loading" className="text-center animate-pulse">
        Carregando...
      </div>
    )

  return (
    <div className="w-full mt-9">
      {team && (
        <TeamCard
          name={team.name}
          leaders={team.leaders}
          members={team.members}
          teamId={query.team_id}
          team={team}
          showParentsAndChildren={true}
          denomination={getDenomination(team.denomination)}
          hasEvaluateButton={false}
          hasEditButton={false}
          variant="primary insights"
          TooltipContent={TooltipContent}
          status={team.status}
          showMembers={!query.inner_circle}
          filterChildren={(childTeams: any) =>
            childTeams.filter(
              ({ status }: { status: 'active' | 'draft' | 'inactive' }) =>
                status === 'active',
            )
          }
        />
      )}
    </div>
  )
}

export { Header, TeamInsightsHeader }
