import { Bar } from 'recharts'
import { colors } from 'theme'
import Chart from 'vibe/insights/chart'

type Data = {
  baseValue: number
  activeValue: number
}

type Props = { data: Data[] }

export default function ConversationFrequencyChart({ data }: Props) {
  return (
    <Chart data={data}>
      <Chart.Header tooltip="Autoavaliações dos liderados">
        Autoavaliações dos liderados
      </Chart.Header>
      <Chart.Bars
        tooltip={({ activeValue }) => (
          <>
            <div style={{ color: colors.blue }}>
              Autoavaliações: {activeValue}
            </div>
          </>
        )}
      >
        <Bar dataKey="activeValue" shape={Chart.ActiveShape} stackId="a" />
      </Chart.Bars>
    </Chart>
  )
}
