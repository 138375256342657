import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    padding: '15px',
  },
}))(Tooltip)

const CustomizedToolTip = ({
  text,
  blackText,
  content = <InfoIcon className="text-black/10" />,
  ...props
}: any) => (
  <LightTooltip
    title={
      <Typography
        variant="body1"
        color={blackText ? 'textPrimary' : 'textSecondary'}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    }
    {...props}
    enterTouchDelay={100}
  >
    {content}
  </LightTooltip>
)

export default CustomizedToolTip
