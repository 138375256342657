import React from 'react'
import type { Actions } from 'croods'
import moment from 'moment'

import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import Star from '../Star'
import TextArea from './TextArea'
import Archive from '../actions/Archive'
import Complete from '../actions/Complete'
import type { Goal } from '../types'

type Props = Goal & {
  save: Actions<Goal>['save']
  hideNewGoal?: () => void
}

export default function Row({
  id,
  description,
  createdAt,
  save,
  hideNewGoal,
}: Props) {
  const { snackbar } = useSnackbar()
  const status = createdAt ? 'ongoing' : 'new'

  const onSubmit = createdAt ? save({ id }) : save()

  const handleSave = (value: string) => {
    if (value === '') return false

    return onSubmit({ description: value })
  }

  const handleArchive = () => {
    if (hideNewGoal) {
      hideNewGoal()
    } else {
      onSubmit({ archivedAt: new Date().toISOString() })
      snackbar({
        title: 'Objetivo excluído',
        message: 'Você ainda pode desfazer essa ação',
        type: 'success',
        buttons: [
          {
            title: 'Desfazer',
            onClick: () => onSubmit({ archivedAt: null }),
          },
        ],
      })
    }
  }

  const handleComplete = () => {
    onSubmit({ completedAt: new Date().toISOString() })
    snackbar({
      title: 'Parabéns, objetivo alcançado!',
      message: 'Você ainda pode desfazer essa ação',
      type: 'success',
      buttons: [
        {
          title: 'Desfazer',
          onClick: () => onSubmit({ completedAt: null }),
        },
      ],
    })
  }

  return (
    <div
      className="mb-6 flex flex-col md:flex-row overflow-hidden rounded-lg border text-gray focus-within:border-blue-light focus-within:text-blue"
      key={id}
    >
      <Star status={status} />

      <div className="flex flex-1 flex-col">
        <TextArea
          description={description}
          handleSave={handleSave}
          autoFocus={hideNewGoal && true}
        />
        {createdAt && (
          <p className="m-4 mt-0 text-xs text-gray">
            {moment(createdAt).fromNow()}
          </p>
        )}
      </div>

      <div className="flex self-end items-center px-4 py-2 text-gray">
        {createdAt && <Complete handleComplete={handleComplete} />}
        <Archive handleArchive={handleArchive} />
      </div>
    </div>
  )
}
