import * as React from 'react'
import { useCroods } from 'croods'
import CareerGoals from 'talks/conversations/career/shared/careerGoals'
import { CareerSkillsPlanning } from 'talks/conversations/career/shared/careerSkills'
import Stepper from 'shared/ui/stepper'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import Buttons from '../shared/Buttons'
import TextInput from '../shared/TextInput'
import SkillEvaluation from './SkillEvaluation'
import SelfEvaluation from './SelfEvaluation'
import Step from '../shared/Step'
import MobileSteps from './MobileSteps'
import type { Info } from 'croods'
import type { CareerConversation } from 'talks/types'
import { useNavigate } from 'react-router-dom'
import { useTrackAnalytics } from 'domain/analytics'

type Props = {
  careerConversation: Info<CareerConversation>
  teamMemberId: string
  setConversation: (value: CareerConversation) => void
}

const steps = [
  <Step key="marker-1" marker="1">
    Avaliação de
    <br />
    Competências
  </Step>,
  <Step key="marker-2" marker="2">
    Avaliação
    <br />
    Geral
  </Step>,
  <Step key="marker-3" marker="3">
    Objetivos
  </Step>,
  <Step key="marker-4" marker="4">
    Planejamento
    <br />
    de Competências
  </Step>,
]

export default function SecondConversation({
  careerConversation,
  teamMemberId,
  setConversation,
}: Props) {
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()
  const track = useTrackAnalytics()

  const [isValidated, setIsValidated] = React.useState(false)

  const [, { save: publishConversation }] = useCroods({
    name: 'carrerConversation',
    path: `/career_conversations/${careerConversation.id}/publish`,
    afterSuccess: () => {
      track('Conversation', { Type: 'career' })
      snackbar({
        message: 'Conversa publicada com sucesso',
        type: 'success',
      })
      navigate(`/`)
    },
    afterFailure: () => {
      snackbar({
        message: 'Não foi possível publicar a conversa',
        type: 'error',
      })
    },
  })

  const [, { save }] = useCroods<CareerConversation>({
    name: 'updateCareerConversations',
    customPath: `career_conversations/${careerConversation.id}`,
    id: careerConversation.id,
    cache: false,
    afterSuccess: ({ data }) => {
      setConversation(data)

      snackbar({
        message: 'Rascunho salvo com sucesso',
        timeout: 2,
      })
    },
    afterFailure: () => {
      snackbar({
        message: 'Houve um erro ao salvar sua conversa, tente novamente',
        timeout: 7,
        type: 'error',
      })
    },
  })

  const handleSave = (value: { [index: string]: string | number | null }) =>
    save({ method: 'put' })(value)

  return (
    careerConversation && (
      <Stepper.Wrapper>
        <Stepper.Steps
          className="hidden justify-center md:flex"
          divider={
            <div className="mx-4 flex flex-col justify-center">
              <div className="mt-5 w-12 border-b-2" />
            </div>
          }
        >
          {steps}
        </Stepper.Steps>

        <MobileSteps>{steps}</MobileSteps>

        <div className="card mt-6 border border-gray-lightest shadow-lg">
          <div className="p-4">
            <Stepper.Content>
              {/* Step 1 */}
              <SkillEvaluation
                teamMemberId={teamMemberId}
                conversation={careerConversation}
                handleSave={handleSave}
                setIsValidated={setIsValidated}
              />
              {/* Step 2 */}
              <SelfEvaluation
                teamMemberId={teamMemberId}
                conversation={careerConversation}
                handleSave={handleSave}
                setIsValidated={setIsValidated}
                setConversation={setConversation}
              />
              {/* Step 3 */}
              <CareerGoals
                teamMemberId={teamMemberId}
                setIsValidated={setIsValidated}
              />
              {/* Step 4 */}
              <div>
                <CareerSkillsPlanning
                  teamMemberId={teamMemberId}
                  setIsValidated={setIsValidated}
                  conversationId={careerConversation.id}
                />
                <TextInput
                  text={careerConversation.supportPlan}
                  name="supportPlan"
                  handleSave={handleSave}
                  label="Que apoio você precisa para seu desenvolvimento?"
                />
              </div>
            </Stepper.Content>
          </div>
          <Buttons publish={publishConversation()} isValidated={isValidated} />
        </div>
      </Stepper.Wrapper>
    )
  )
}
