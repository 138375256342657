import ClockingPanelSolicitation from 'portal/clocking-panel/solicitation'
import MyDocuments from 'portal/myDocuments'
import MyVacations from 'portal/myVacations'
import List from 'portal/openSolicitation/list'
import New from 'portal/openSolicitation/new'
import PendingSolicitations from 'portal/pendingSolicitations'
import VacationManagement from 'portal/vacationManagement'
import type { LegacyRoute } from 'types'

const openSolicitation: LegacyRoute = {
  component: List,
  pageTitle: 'Nova Solicitação',
  path: '/portal',
  breadcrumbs: ['home', 'openSolicitation'],
}
const newSolicitation: LegacyRoute = {
  component: New,
  pageTitle: 'Abrir Solicitação',
  path: '/portal/nova-solicitacao/:solicitationId',
  breadcrumbs: ['home', 'openSolicitation', ':solicitationId'],
}
const pendingSolicitations: LegacyRoute = {
  component: PendingSolicitations,
  pageTitle: 'Solicitações Pendentes',
  path: '/portal/solicitacoes-pendentes',
  breadcrumbs: ['home', 'pendingSolicitations'],
}
const clockingPanelSolicitation: LegacyRoute = {
  component: ClockingPanelSolicitation,
  pageTitle: 'Acerto do Ponto',
  path: '/portal/acerto-do-ponto/nova-solicitacao/:masterId',
  breadcrumbs: ['home', 'clockingPanel', ':masterId'],
}

const myDocuments: LegacyRoute = {
  component: MyDocuments,
  pageTitle: 'Meus Documentos',
  path: '/portal/meus-documentos',
  breadcrumbs: ['home', 'myDocuments'],
}

const selectedVacationManagement: LegacyRoute = {
  component: VacationManagement,
  pageTitle: 'Gestão de Férias',
  path: '/portal/gestao-de-ferias/:fullName/:startDate',
  breadcrumbs: ['home', 'vacationManagement'],
}

const vacationManagement: LegacyRoute = {
  component: VacationManagement,
  pageTitle: 'Gestão de Férias',
  path: '/portal/gestao-de-ferias',
  breadcrumbs: ['home', 'vacationManagement'],
}

const myVacations: LegacyRoute = {
  component: MyVacations,
  pageTitle: 'Minhas Férias',
  path: '/portal/minhas-ferias',
  breadcrumbs: ['home', 'myVacations'],
}

const home: LegacyRoute = {
  component: List,
  pageTitle: 'Portal',
  path: '/portal',
  breadcrumbs: ['home'],
}

export default [
  openSolicitation,
  newSolicitation,
  pendingSolicitations,
  clockingPanelSolicitation,
  myDocuments,
  vacationManagement,
  selectedVacationManagement,
  myVacations,
  home,
]
