import { useSnackbar } from '@seasonedsoftware/ui'

function useHandleConfirm<T>(
  save: (o?: Partial<T>) => Promise<T>,
  body?: Partial<T>,
) {
  const { success, error } = useSnackbar()
  return (errorMsg?: string, successMsg?: string) => async () => {
    const result = await save(body)
    if (result && successMsg) {
      success(successMsg, 4)
    }
    if (!result && errorMsg) {
      error(errorMsg, 4)
    }
    return result
  }
}

export default useHandleConfirm
