import * as React from 'react'
import Lottie from 'lottie-react'
import aiLoadingAnimation from 'shared/lotties/ai-loading.json'
import { cx } from '@remaster-run/helpers'

const TEXT_MESSAGES = [
  'Preparando sugestões personalizadas para você.',
  'Só mais um pouquinho…',
  'Analisando dados para oferecer sugestões precisas.',
  'Processando informações para ajudar nas suas decisões.',
  'Processando informações para ajudar nas suas ações.',
  'Aprimorando suas escolhas com sugestões inteligentes.',
  'Estamos quase lá.',
]

const AILoadingAnimation = () => {
  const [message, setMessage] = React.useState(TEXT_MESSAGES[0])

  const [isShowing, setIsShowing] = React.useState(true)

  React.useEffect(() => {
    let seconds = 1
    let index = 1
    const interval = setInterval(() => {
      if (seconds === 6) {
        setIsShowing(false)
        seconds = 0
      } else {
        if (seconds === 0) {
          setMessage(TEXT_MESSAGES[index])
          setIsShowing(true)
          index = TEXT_MESSAGES.length - 1 === index ? 0 : index + 1
        }
        seconds++
      }
    }, 1000)

    return () => clearInterval(interval)
  }, []) // eslint-disable-line

  return (
    <>
      <Lottie
        animationData={aiLoadingAnimation}
        className="w-[240px] h-[240px]"
      />
      <p
        className={cx(
          'text-base text-gray-darker transition-opacity duration-1000',
          isShowing ? 'opacity-100' : 'opacity-0',
        )}
      >
        {message}
      </p>
    </>
  )
}

export { AILoadingAnimation }
