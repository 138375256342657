import React, { useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import each from 'lodash/each'
import omit from 'lodash/omit'

import useArray from 'shared/utils/useArray'
import Input from 'shared/forms/Input'
import Icon from 'shared/ui/icon'

const TextArrayInput = ({
  value,
  onChange,
  error,
  label,
  itemLabel,
  removeLabel,
  addLabel,
  ...otherProps
}: any) => {
  const { array, push, splice } = useArray(value)
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      onChange(array)
    } else {
      didMount.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [array])

  const renderedItems: any[] = []

  each(array, (option, index) => {
    renderedItems.push(
      <div className="flex items-end gap-4" key={index.toString()}>
        <div className="grow">
          <Input
            onChange={(event: any) => splice(index, event.target.value)}
            value={option}
            label={(index as unknown as number) === 0 ? label : null}
            title={`${itemLabel} ${index + 1}`}
            placeholder={`${itemLabel} ${index + 1}`}
            autoComplete="off"
            variant="standard"
            error={error && error[index]}
            {...omit(otherProps, 'error')}
          />
        </div>

        <div className="mb-4 flex-none">
          <Button
            color="primary"
            onClick={() =>
              array.length < 3 ? splice(index, '') : splice(index)
            }
            size="small"
            title={`${removeLabel} ${index + 1}`}
          >
            <Icon icon="clear" className="text-blue" />
          </Button>
        </div>
      </div>,
    )
  })

  return (
    <>
      {renderedItems}

      <div className="mb-4">
        <Button
          onClick={() => push('')}
          startIcon={
            <Icon icon="add-circle-outline" className="h-5 w-5 text-blue" />
          }
          title={addLabel}
          type="button"
        >
          {addLabel}
        </Button>
      </div>
    </>
  )
}

export default TextArrayInput
