import * as React from 'react'
import Period from 'shared/ui/Period'
import { scrollTo } from 'shared/helpers'
import Box from './Box'
import SelectedBox from './selectedBox'
import type { BoxContent } from '../types'
import ConfirmationDialog from './ConfirmationDialog'
import useOrganization from 'shared/organization/useOrganization'

type NineBox = {
  boxes: BoxContent[]
  cycle: {
    organizationCycleStartsAt: string
    organizationCycleFinishesAt: string
  }
  canEdit?: boolean
  fetch?: () => () => Promise<void>
}

const NineBox = ({ boxes, cycle, canEdit = false, fetch }: NineBox) => {
  const { evaluationWindowClosesAt } = useOrganization()
  const [openDialog, setOpenDialog] = React.useState(false)
  const [activeBox, setActiveBox] = React.useState<BoxContent['name'] | null>(
    null,
  )
  const { organizationCycleStartsAt, organizationCycleFinishesAt } = cycle

  const chosenBox = boxes.find((box) => box.name === activeBox) || null

  React.useEffect(() => {
    if (activeBox) {
      scrollTo('selected-scroll-target')
    }
  }, [activeBox])

  return (
    <div className="card w-full rounded-2xl">
      <div className="header-card">
        <div className="flex items-center justify-between">
          <h4 id="unselected-scroll-target">Avaliação dos Talentos</h4>
          {canEdit && (
            <>
              <button
                type="button"
                className="cursor-pointer self-center rounded-md border border-white p-2 px-5 text-lg !font-bold uppercase text-white hover:underline"
                onClick={() => setOpenDialog(true)}
              >
                EDITAR
              </button>
              <ConfirmationDialog
                openDialog={openDialog}
                handleClose={() => setOpenDialog(false)}
                finishesAt={evaluationWindowClosesAt}
                fetch={fetch}
              />
            </>
          )}
        </div>
      </div>
      <div className="p-2 md:p-6">
        <Period
          title="Ciclo"
          startsAt={organizationCycleStartsAt}
          finishesAt={organizationCycleFinishesAt}
        />
        <div className="mt-8 mb-4 grid grid-cols-3 justify-items-center gap-4">
          <h6 className="text-center text-lg text-yellow-500">
            Cenário de Atenção
          </h6>
          <h6 className="text-center text-lg text-green-500">Cenário Ideal</h6>
          <h6 className="text-center text-lg text-red-500">Cenário Crítico</h6>
        </div>
        <div className="grid grid-cols-3 grid-rows-3 justify-items-center gap-4">
          {boxes.map((box, i) => (
            <Box
              key={`box-${i}`}
              boxContent={box}
              activeBox={activeBox}
              setActiveBox={setActiveBox}
            />
          ))}
        </div>
        <div className="flex py-8">
          {<SelectedBox boxContent={chosenBox} />}
        </div>
      </div>
    </div>
  )
}

export default NineBox
