import { MdPersonOutline } from 'react-icons/md'
import { cx, getUserIdentifier, renderIf } from 'shared/helpers'

import type { UserAvatar } from 'types'

type Props = {
  user: UserAvatar
  size?: number
  showNewIndicator?: boolean
}

const Avatar = ({ user, size = 40, showNewIndicator = true }: Props) => {
  const bgClass = !Boolean(user?.avatar)
    ? user.hasCredential
      ? 'avatar-gradient-filter'
      : 'avatar-gray-filter'
    : ''

  const fullName = getUserIdentifier({ user: user })

  const avatarTooltip = user.hasCredential
    ? fullName
    : fullName
    ? `${fullName} - sem acesso ao Vibe`
    : 'Sem acesso ao Vibe'

  return (
    <div className="grid justify-items-center">
      <div
        title={avatarTooltip}
        role="img"
        className="relative flex items-center justify-center overflow-hidden rounded-full bg-gray/75"
        style={{ width: size, height: size, minWidth: size }}
      >
        {Boolean(user?.avatar) ? (
          <img
            src={user.avatar}
            alt={fullName}
            className="absolute h-full w-full rounded-full object-cover"
          />
        ) : (
          <MdPersonOutline
            role="avatar-icon"
            style={{ fontSize: size }}
            className="absolute rounded-full object-cover text-white"
          />
        )}

        <div
          aria-hidden="true"
          className={cx(`absolute inset-0 rounded-full ${bgClass}`)}
        />
      </div>

      {renderIf(
        showNewIndicator && Boolean(user.recentlyAdded),
        <p
          className="relative -mt-3 grid h-4 w-fit items-center rounded-[1.25rem] border border-white bg-green px-1 text-center text-xs font-extrabold text-white"
          title="Colaborador adicionado recentemente"
        >
          novo
        </p>,
      )}
    </div>
  )
}

export default Avatar
