import * as React from 'react'
import { Fetch } from 'croods'

import FeedbackMessage from 'shared/ui/FeedbackMessage'

import AcquisitivePeriodsHistory from './AcquisitivePeriodsHistory'
import Vacations from './Vacations'

import type { CollaboratorVacations } from './types'
import type { PageProps } from 'types'

const Wrapper = ({
  collaboratorVacations,
}: {
  collaboratorVacations: CollaboratorVacations
}) => {
  const [collaboratorVacationsState, setCollaboratorVacationsState] =
    React.useState<CollaboratorVacations>(collaboratorVacations)

  return (
    <>
      {collaboratorVacationsState.acquisitivePeriods.length > 0 ? (
        <AcquisitivePeriodsHistory
          collaboratorVacations={collaboratorVacationsState}
          setCollaboratorVacationsState={setCollaboratorVacationsState}
        />
      ) : (
        <p className="mt-5 max-h-[6rem] w-full overflow-hidden text-ellipsis rounded bg-yellow-lightest p-4 text-center text-sm font-semibold text-yellow-darkest shadow">
          Você ainda não possui períodos aquisitivos.
        </p>
      )}

      {collaboratorVacationsState.vacations.length > 0 ? (
        <Vacations vacations={collaboratorVacationsState.vacations} />
      ) : (
        <p className="mt-5 max-h-[6rem] w-full overflow-hidden text-ellipsis rounded bg-yellow-lightest p-4 text-center text-sm font-semibold text-yellow-darkest shadow">
          Você ainda não possui férias solicitadas ou confirmadas.
        </p>
      )}
    </>
  )
}

const Loading = () => (
  <div
    data-testid="loading"
    className="h-96 w-full animate-pulse rounded bg-gray-200"
  />
)

const MyVacations: PageProps = ({ pageTitle }) => (
  <>
    <h3 className="mb-4">{pageTitle}</h3>

    <Fetch
      name="collaboratorVacations"
      path="/workflow/vacations"
      render={(collaboratorVacations: CollaboratorVacations[]) => (
        <Wrapper collaboratorVacations={collaboratorVacations[0]} />
      )}
      renderError={(error) => (
        <FeedbackMessage
          message="Erro ao carregar informações de férias do colaborador"
          serverMessage={error}
        />
      )}
      renderEmpty={() => (
        <FeedbackMessage
          message="Você ainda não possui informações de férias"
          type="warning"
          serverMessage=""
        />
      )}
      renderLoading={() => <Loading />}
    />
  </>
)

export default MyVacations
