import * as React from 'react'
import { useCroods } from 'croods'
import { match, P } from 'ts-pattern'

import useOrganization from 'shared/organization/useOrganization'
import { renderIf } from 'shared/helpers'
import Table from 'shared/ui/table'
import Modal from 'shared/ui/Modal'
import Icon from 'shared/ui/icon'

import type { Organization, PageProps } from 'types'

type PropsConfirmationModal = {
  show: boolean
  onClose: () => void
  handleSave: () => void
  type: PropsIconButton['type']
  checked: boolean
  organization: Organization
}

const ConfirmationModal = ({
  show,
  onClose,
  handleSave,
  type,
  checked,
  organization,
}: PropsConfirmationModal) => {
  const typeMap: Record<PropsIconButton['type'], string> = {
    clocking: 'Acerto de Ponto',
    documents: 'Documentos',
    solicitations: 'Solicitações',
    vacations: 'Férias',
  }

  const warningMessage = match([
    type,
    checked,
    organization.permitSolicitations,
    organization.permitClocking || organization.permitVacations,
  ])
    .with(
      ['clocking', false, false, P._],
      () => 'Solicitações também serão permitidas',
    )
    .with(
      ['vacations', false, false, P._],
      () => 'Solicitações também serão permitidas',
    )
    .with(
      ['solicitations', true, P._, true],
      () => 'Acerto de Ponto e Férias não serão permitidos',
    )
    .otherwise(() => null)

  return (
    <Modal.Wrapper open={show} onClose={onClose}>
      <Modal.Header>
        <div className="flex items-start">
          <h5 className="mx-2 w-[38rem]">Confirmar alteração</h5>
        </div>
      </Modal.Header>
      <Modal.Content className="mx-2 gap-4">
        <h5 className="font-normal">
          Você deseja{' '}
          <span className="font-bold">
            {checked && 'não'} permitir {typeMap[type]}
          </span>{' '}
          na Organização?
        </h5>

        {renderIf(
          Boolean(warningMessage),
          <div className="flex items-center gap-2 rounded-2xl bg-orange-50 px-[10px] py-1">
            <Icon icon="error-outline" className="text-orange-300" />
            <span className="text-base font-bold text-yellow-800">
              {warningMessage}
            </span>
          </div>,
        )}
      </Modal.Content>
      <Modal.Actions cancelLabel="FECHAR">
        <Modal.Confirm color="primary" onClick={() => handleSave()}>
          SALVAR E CONFIRMAR
        </Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

type PropsIconButton = {
  type: 'clocking' | 'documents' | 'solicitations' | 'vacations'
  checked: boolean
}
const IconButton = ({ type, checked }: PropsIconButton) => {
  const [hover, setHover] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)

  const [{ info: organization }, { save, setInfo: setOrganization }] =
    useCroods({
      name: 'organization',
      id: type,
    })

  return (
    <>
      <div
        className="flex justify-center p-5"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {!hover &&
          (checked ? (
            <Icon
              icon="check-circle"
              className="text-green-300"
              data-testid={`${type}-icon`}
            />
          ) : (
            <Icon icon="block" data-testid={`${type}-icon`} />
          ))}
        {hover &&
          (checked ? (
            <button
              className="cursor-pointer text-base font-semibold text-blue"
              data-testid={`${type}-button`}
              onClick={() => {
                setShowModal(true)
              }}
            >
              NÃO PERMITIR
            </button>
          ) : (
            <button
              className="cursor-pointer text-base font-semibold text-blue"
              data-testid={`${type}-button`}
              onClick={() => {
                setShowModal(true)
              }}
            >
              PERMITIR
            </button>
          ))}
      </div>

      <ConfirmationModal
        show={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        handleSave={() => {
          save({
            customPath: `organization/${checked ? 'revoke' : 'permit'}/${type}`,
            afterSuccess: ({ data }) => {
              setOrganization(data)
              setShowModal(false)
            },
          })()
        }}
        type={type}
        checked={checked}
        organization={organization}
      />
    </>
  )
}

const Portal: PageProps = () => {
  const organization = useOrganization()

  return (
    <>
      <h3>Portal</h3>
      <div className="card mt-6">
        <div className="max-w-full">
          <Table id="portal-settings">
            <Table.Thead>
              <Table.Th>Nome</Table.Th>
              <Table.Th className="text-center">Solicitações</Table.Th>
              <Table.Th className="text-center">Acerto de Ponto</Table.Th>
              <Table.Th className="text-center">Documentos</Table.Th>
              <Table.Th className="text-center">Férias</Table.Th>
            </Table.Thead>

            <Table.Tbody>
              <Table.Tr className="!py-6">
                <Table.Td className="p-5" data-title="Nome">
                  {organization.name}
                </Table.Td>

                <Table.Td data-title="Solicitações">
                  <IconButton
                    type="solicitations"
                    checked={organization.permitSolicitations}
                  />
                </Table.Td>

                <Table.Td data-title="Acerto de Ponto">
                  <IconButton
                    type="clocking"
                    checked={organization.permitClocking}
                  />
                </Table.Td>

                <Table.Td data-title="Documentos">
                  <IconButton
                    type="documents"
                    checked={organization.permitDocuments}
                  />
                </Table.Td>

                <Table.Td data-title="Férias">
                  <IconButton
                    type="vacations"
                    checked={organization.permitVacations}
                  />
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </div>
      </div>
    </>
  )
}

export { Portal }
