import { useNavigate } from 'react-router-dom'

import Modal from 'shared/ui/Modal'

import Success from './Success'
import Confirm from './Confirm'
import type { FormState } from '../types'

type Props = {
  openDialog: boolean
  handleClose: () => void
  handleSubmit: () => void
  saving: boolean
  isSuccess: boolean | null
  formState: FormState
}

const Summary = ({
  openDialog,
  handleClose,
  handleSubmit,
  saving,
  isSuccess,
  formState,
}: Props) => {
  const navigate = useNavigate()
  return (
    <Modal.Wrapper
      open={openDialog}
      loading={saving}
      onClose={handleClose}
      onConfirm={async () => (isSuccess ? navigate('/') : handleSubmit())}
    >
      {isSuccess ? <Success /> : <Confirm formState={formState} />}
    </Modal.Wrapper>
  )
}

export default Summary
