import React, { useState, useEffect } from 'react'
import { useCroods } from 'croods'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'

import UserModal from 'shared/ui/UserModal'

import type { User } from 'types'

import CheckInput from 'shared/forms/CheckInput'
import useHandleConfirm from 'shared/ui/Modal/useHandleConfirm'

type Props = { onClose(): void; user: User | null }

const EditModal: React.FC<Props> = ({ onClose, user }) => {
  const id = user?.id

  const { snackbar } = useSnackbar()
  const [admin, setAdmin] = useState(Boolean(user?.admin))
  const [dataAnalyst, setDataAnalyst] = useState(Boolean(user?.dataAnalyst))
  const [{ saving }, { save }] = useCroods({
    id,
    name: 'users',
    stateId: 'supervisor',
    customPath: '/supervisor/users/:id',
    afterSuccess: () => {
      snackbar({
        title: 'Sucesso',
        message: 'Usuário atualizado com sucesso',
        type: 'success',
      })
    },
    afterFailure: (e) => {
      snackbar({
        title: 'Não foi possível atualizar o usuário.',
        message: e?.response?.data?.message,
        type: 'error',
        timeout: 5,
      })
    },
  })
  const update = save({ method: 'PUT' })
  const execute = useHandleConfirm<User>(update, { admin, dataAnalyst })

  useEffect(() => {
    if (user) {
      setAdmin(user.admin)
      setDataAnalyst(user.dataAnalyst)
    }
  }, [id]) // eslint-disable-line
  const enabled = !(user?.admin === admin && user?.dataAnalyst === dataAnalyst)

  return (
    <UserModal
      user={user}
      enabled={enabled}
      kind="default"
      onClose={onClose}
      title="Editar"
      loading={saving}
      onConfirm={execute(
        'Não foi possível salvar as alterações',
        'Usuário atualizado com sucesso',
      )}
    >
      <CheckInput
        onChange={({ target }) => setAdmin(target.checked)}
        checked={admin}
        label="Este colaborador é um Administrador (Admin)."
      >
        Poder de Admin
      </CheckInput>
      <CheckInput
        onChange={({ target }) => setDataAnalyst(target.checked)}
        checked={dataAnalyst}
        label="Permitir que usuário tenha Visão Total."
      >
        Permisão de Visão
      </CheckInput>
    </UserModal>
  )
}

export default EditModal
