import last from 'lodash/last'
import { useMatches } from 'react-router-dom'
import { z } from 'zod'

const routeCategorySchema = z.enum([
  'vibe',
  'talks',
  'performance',
  'portal',
  'settings',
])

const routeHandleSchema = z.object({
  breadcrumbs: z.array(z.string()).optional(),
  category: routeCategorySchema.optional(),
  title: z.string().optional(),
  layout: z.enum(['minimal', 'full']).default('full'),
})

type RouteCategory = z.infer<typeof routeCategorySchema>
type RouteHandle = Partial<z.infer<typeof routeHandleSchema>>

function useRouteHandle() {
  const matches = useMatches()
  const lastMatch = last(matches)
  const handle = lastMatch?.handle ?? {}
  return routeHandleSchema.parse(handle)
}

export type { RouteCategory, RouteHandle }
export { useRouteHandle }
