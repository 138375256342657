import React from 'react'
import moment from 'moment'
import TitleInput from './TitleInput'
import Slider from './Slider'
import DevelopmentCommitment from './DevelopmentCommitment'
import TextInput from '../../TextInput'
import { HeaderButtons } from './Buttons'
import DevelopmentPlanInput from './development-plan-input'
import { cx } from 'shared/helpers'
import type { Skill } from '../types'
import { useDataRefreshOnInterval } from './polling'
import Modal from 'shared/ui/Modal'
import type { ReactNode } from 'react'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import useOrganization from 'shared/organization/useOrganization'
import { useParams } from 'react-router-dom'
import { useCroods } from 'croods'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import CircleGoalDialog from './CircleGoalDialog'
import AiIcon from 'shared/ui/icon/AiIcon'
import { withStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import { LoadingDialog } from '../loading-dialog'
import { useTrackAnalytics } from 'domain/analytics'

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: `0px 2px 6px 2px #00000005,0px 1px 1px 0px #00000024,0px 1px 3px 0px #0000001f`,
    margin: '5px',
  },
}))(Tooltip)

type Props = {
  skill: Skill
  save: (options?: {
    id: number
  }) => (value: { [index: string]: string | number | null }) => void
  hideNewSkill?: () => void
  developmentPlanLabel?: string
}

type CheckboxOption = { action: string; justification: string }
type CheckboxDialogProps = {
  open: boolean
  onClose: () => void
  options: CheckboxOption[]
  cbSelectedOptions: (selectedOptions: CheckboxOption[]) => void
}
const CheckboxDialog = ({
  open,
  onClose,
  options,
  cbSelectedOptions,
}: CheckboxDialogProps) => {
  const [selectedIndexes, setSelectedIndexes] = React.useState<number[]>([])

  const handleCheckbox = (checked: boolean, index: number) => {
    if (checked) {
      setSelectedIndexes((prevState) => [...prevState, index])
    } else {
      setSelectedIndexes((prevState) => prevState.filter((p) => p !== index))
    }
  }

  return (
    <Modal.Wrapper
      open={open}
      onClose={() => {
        setSelectedIndexes([])
        onClose()
      }}
      preventClose={true}
      onConfirm={async () => {
        cbSelectedOptions(selectedIndexes.map((i) => options[i]))
        setSelectedIndexes([])
        onClose()
      }}
    >
      <Modal.Header>
        <h5 className="flex items-center">
          <AiIcon fill="#2D2D2C" className="inline pr-2" />
          Selecione as melhores ideias geradas pela AI
        </h5>
      </Modal.Header>
      <Modal.Content className="p-10">
        <div className="flex max-w-[600px] flex-col gap-6">
          {options.map(({ action, justification }, index) => {
            const checked = selectedIndexes.includes(index)
            return (
              <button
                key={index}
                type="button"
                onClick={() => handleCheckbox(!checked, index)}
              >
                <div className="flex gap-4 text-left">
                  <input
                    id="one"
                    className="input h-2 w-2 p-2 focus:ring-transparent cursor-pointer"
                    type="checkbox"
                    checked={checked}
                  />
                  <div
                    className={cx(checked ? 'text-blue' : 'text-gray-darker')}
                  >
                    <h5>{action}</h5>
                    <p className="text-base">{justification}</p>
                  </div>
                </div>
              </button>
            )
          })}
        </div>
      </Modal.Content>
      <Modal.Actions cancelLabel="CANCELAR">
        <Modal.Confirm
          disabled={selectedIndexes.length < 1}
          className="bt-contained"
        >
          CONFIRMAR<span className="hidden md:inline">&nbsp;SELECIONADAS</span>
        </Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

const selectedOptionsToText = (
  selectedOptions: CheckboxOption[],
  startWith: string,
) =>
  selectedOptions.reduce(
    (acc, option) => {
      return `${acc}<p><strong>${option.action}</strong></p><p>${option.justification}</p><br>`
    },
    isEmpty(startWith) ? startWith : `${startWith} <p>-</p>`,
  )

const AiBadgeContent = ({
  aiBadge,
}: {
  aiBadge: {
    name: string
    description?: string
    justification?: string
  }
}) => (
  <div className="p-2 text-left">
    <div className="text-base font-light text-gray-darker inline">
      <p className="font-bold">
        <AiIcon className="fill-gray-dark mr-1 inline" />
        {aiBadge.name}
      </p>
      {Boolean(aiBadge.description) && (
        <>
          <br />
          {aiBadge.description}
          <br />
        </>
      )}
      {Boolean(aiBadge.justification) && (
        <>
          <br />
          {aiBadge.justification}
        </>
      )}
    </div>
  </div>
)

const SkillPlanningSlider = ({
  skill,
  save,
  hideNewSkill,
  children,
}: {
  skill: Skill
  save: (options?: {
    id: number
  }) => (value: { [index: string]: string | number | null }) => void
  hideNewSkill?: () => void
  children?: ReactNode
}) => {
  const { snackbar } = useSnackbar()
  const onSubmit = skill.createdAt ? save({ id: skill.id }) : save()
  const isNew = skill.createdAt
    ? moment(skill.createdAt).isSame(moment(), 'day')
    : false
  const isCompleted = Boolean(skill.completedAt)
  const isPaused = Boolean(skill.pausedAt)

  const handleSave = (value: { [index: string]: string | number }) => {
    if (!skill.createdAt && 'title' in value && isEmpty(value.title)) {
      hideNewSkill?.()
      return snackbar({
        type: 'error',
        message:
          'A validação falhou: O Título da Competência não pode ficar em branco',
      })
    }

    return onSubmit(value)
  }

  return (
    <>
      <div
        key={skill.id}
        className={cx(
          'flex flex-col gap-y-2 overflow-hidden rounded-lg border',
          isPaused
            ? 'focus-within:border-gray-300'
            : 'focus-within:border-blue-400',
          isCompleted
            ? 'focus-within:border-green-light'
            : 'focus-within:border-gray-400',
        )}
      >
        <div
          className={cx(
            'flex py-2 px-4 grow flex-col md:flex-row',
            isPaused
              ? 'bg-gray-100 focus-within:bg-gray-50'
              : 'bg-gray-100 focus-within:bg-blue-50',
            isCompleted
              ? 'bg-green-lightest focus-within:bg-green-50'
              : 'bg-gray-100 focus-within:bg-gray-50',
          )}
        >
          <div className="flex grow items-center px-4 text-base text-gray-darkest">
            {!isEmpty(skill.aiGenerated) ? (
              <LightTooltip
                placement="bottom-start"
                title={<AiBadgeContent aiBadge={skill.aiGenerated!} />}
              >
                <div>
                  <AiIcon
                    className="fill-gray-dark mr-1"
                    data-testid="TitleAiIcon"
                  />
                </div>
              </LightTooltip>
            ) : null}
            {isCompleted ? (
              <p className="label text-base">{skill.title}</p>
            ) : (
              <TitleInput
                text={skill.title}
                handleSave={(value: string) => handleSave({ title: value })}
                autoFocus={hideNewSkill && true}
                highlighted={!skill.createdAt}
              />
            )}
          </div>

          <div className="flex flex-wrap md:flex-nowrap items-center py-2 text-gray-500 gap-4">
            <HeaderButtons
              secondConversationPhase="planning"
              createdAt={skill.createdAt}
              isCompleted={isCompleted}
              isNew={isNew}
              isPaused={isPaused}
              hideNewSkill={hideNewSkill}
              onSubmit={onSubmit}
              title={skill.title}
            />
          </div>
        </div>

        <Slider
          secondConversationPhase="planning"
          onSubmit={onSubmit}
          isPaused={isPaused}
          isCompleted={isCompleted}
          developmentPercentagePrevious={skill.developmentPercentagePrevious}
          developmentPercentageCurrent={skill.developmentPercentageCurrent}
          developmentPercentageLongTermGoal={
            skill.developmentPercentageLongTermGoal
          }
          developmentPercentageNextCycle={skill.developmentPercentageNextCycle}
          isNew={isNew}
        />
        {children}
      </div>
    </>
  )
}

const SkillPlanningWithAI = ({
  skill,
  developmentPlanLabel,
  save,
  hideNewSkill,
}: Props) => {
  const { teamMemberId } = useParams()
  const organization = useOrganization()
  const { snackbar } = useSnackbar()
  const onSubmit = skill.createdAt ? save({ id: skill.id }) : save()
  const isCompleted = Boolean(skill.completedAt)
  const isPaused = Boolean(skill.pausedAt)
  const showAIButton = Boolean(!isCompleted && !isPaused && skill?.createdAt)

  const handleSave = (value: { [index: string]: string | number }) => {
    return onSubmit(value)
  }

  const [{ saving: loadingGptSession }, { save: saveGptSession }] = useCroods({
    name: 'gptSession',
    path: `/team_members/${teamMemberId}/career_skills/${skill.id}/gpt_sessions`,
    afterSuccess: ({ data }) => {
      setGptSessionId(data.id)
      setRunning(true)
    },
    afterFailure: (e) => {
      snackbar({
        message: e?.response?.data?.message,
        type: 'error',
      })
    },
  })

  const [openTeamGoalDialog, setOpenTeamGoalDialog] = React.useState(false)

  const [
    { list: missingGoalDescriptions, listError },
    { save: saveMissingGoalDescriptions, fetch: fetchMissingGoalDescriptions },
  ] = useCroods({
    name: 'missingGoalDescriptions',
    stateId: teamMemberId,
    customPath: `/team_members/${teamMemberId}/team_goal`,
    fetchOnMount: true,
  })

  const submitGoalDescriptions = async (
    goalDescriptions: { id: number; goalDescription: string }[],
  ) => {
    saveMissingGoalDescriptions({
      method: 'PUT',
      afterSuccess: () => {
        fetchMissingGoalDescriptions()()
        snackbar({ message: 'Descrição salva com sucesso', timeout: 2 })
        saveGptSession()()
      },
      afterFailure: (e) => {
        snackbar({
          message: e?.response?.data?.message,
          type: 'error',
        })
      },
    })({ teams: goalDescriptions })
  }

  const [developmentPlan, setDevelopmentPlan] = React.useState(
    skill?.developmentPlan ?? '',
  )
  const [openOptionsModal, setOpenOptionsModal] = React.useState(false)
  const [options, setOptions] = React.useState<CheckboxOption[]>([])
  const track = useTrackAnalytics()
  const { running, setRunning, setGptSessionId } = useDataRefreshOnInterval(
    Number(teamMemberId),
    skill.id,
    (data) => {
      setOpenOptionsModal(true)
      track('Generate development plan', { AI: true })
      setOptions(data)
    },
  )

  const delayedRequest = React.useRef(
    debounce((text) => {
      handleSave({ developmentPlan: text === '<p><br></p>' ? '' : text })
    }, 600),
  ).current

  const aiEnabled =
    !isEmpty(organization.goalDescription) && !Boolean(listError)

  return (
    <>
      <SkillPlanningSlider
        skill={skill}
        save={save}
        hideNewSkill={hideNewSkill}
      >
        <div className="p-4">
          <div className="mt-4 mb-2 flex flex-col md:flex-row md:items-end justify-between gap-2">
            <p
              className={cx(
                'text-base leading-4',
                (isCompleted || isPaused) && 'text-gray-dark',
              )}
            >
              {developmentPlanLabel ??
                'Planos e ideias para o desenvolvimento da competência'}
            </p>
            {showAIButton && (
              <LightTooltip
                disableHoverListener={!isEmpty(organization.goalDescription)}
                disableTouchListener={!isEmpty(organization.goalDescription)}
                placement="top"
                title={
                  <p className="m-1 text-sm text-gray-darkest">
                    Função indisponível para sua organização
                  </p>
                }
              >
                <span className="flex flex-col gap-1">
                  <button
                    className="ai-button"
                    onClick={() => {
                      if (
                        typeof missingGoalDescriptions !== 'undefined' &&
                        missingGoalDescriptions.length > 0 &&
                        !missingGoalDescriptions.some(
                          (msg) =>
                            msg.id === undefined || msg.label === undefined,
                        )
                      ) {
                        setOpenTeamGoalDialog(true)
                      } else {
                        saveGptSession()()
                      }
                    }}
                    disabled={!aiEnabled}
                  >
                    <AiIcon />
                    SUGERIR IDEIAS
                  </button>
                  {Boolean(listError) && (
                    <small
                      className="form-helper-text text-red-dark"
                      role="alert"
                    >
                      {listError}
                    </small>
                  )}
                </span>
              </LightTooltip>
            )}
          </div>

          <CheckboxDialog
            open={openOptionsModal}
            onClose={() => setOpenOptionsModal(false)}
            options={options}
            cbSelectedOptions={(selectedOptions) => {
              setDevelopmentPlan(
                selectedOptionsToText(
                  selectedOptions,
                  skill?.developmentPlan ?? '',
                ),
              )
            }}
          />
          <DevelopmentPlanInput
            disabled={isCompleted || isPaused}
            value={developmentPlan}
            onChange={(text) => {
              setDevelopmentPlan(text)
              delayedRequest(text)
            }}
          />
        </div>
      </SkillPlanningSlider>
      <LoadingDialog
        open={Boolean(running || loadingGptSession)}
        onClose={() => setRunning(false)}
      />
      <CircleGoalDialog
        open={openTeamGoalDialog}
        onClose={() => setOpenTeamGoalDialog(false)}
        onConfirm={submitGoalDescriptions}
        missingGoalDescriptions={missingGoalDescriptions}
      />
    </>
  )
}

const SkillPlanningCard = ({
  skill,
  developmentPlanLabel,
  save,
  hideNewSkill,
}: Props) => {
  const onSubmit = skill.createdAt ? save({ id: skill.id }) : save()
  const isCompleted = Boolean(skill.completedAt)
  const isPaused = Boolean(skill.pausedAt)
  const showAIButton = Boolean(!isCompleted)

  const handleSave = (value: { [index: string]: string | number }) => {
    return onSubmit(value)
  }

  const [developmentPlan, setDevelopmentPlan] = React.useState(
    skill?.developmentPlan ?? '',
  )

  const delayedRequest = React.useRef(
    debounce((text) => {
      handleSave({ developmentPlan: text === '<p><br></p>' ? '' : text })
    }, 600),
  ).current

  return (
    <SkillPlanningSlider skill={skill} save={save} hideNewSkill={hideNewSkill}>
      <div className="p-4">
        <div className="mt-4 mb-2 flex items-end justify-between">
          <p
            className={cx(
              'text-base leading-4',
              (isCompleted || isPaused) && 'text-gray-dark',
            )}
          >
            {developmentPlanLabel ??
              'Planos e ideias para o desenvolvimento da competência'}
          </p>
          {showAIButton && (
            <button className="ai-button" disabled>
              <span>
                <AiIcon />
                SUGERIR IDEIAS
              </span>
            </button>
          )}
        </div>
        <DevelopmentPlanInput
          disabled={isCompleted || isPaused}
          value={developmentPlan}
          onChange={(text) => {
            setDevelopmentPlan(text)
            delayedRequest(text)
          }}
        />
      </div>
    </SkillPlanningSlider>
  )
}

const SkillEvaluationCard = ({
  skill,
  developmentPlanLabel,
  save,
  hideNewSkill,
}: Props) => {
  const onSubmit = skill.createdAt ? save({ id: skill.id }) : save()
  const isNew = skill.createdAt
    ? moment(skill.createdAt).isSame(moment(), 'day')
    : false
  const isCompleted = Boolean(skill.completedAt)
  const isPaused = Boolean(skill.pausedAt)

  const handleSave = (value: { [index: string]: string | number }) => {
    return onSubmit(value)
  }

  return (
    <div
      key={skill.id}
      className={cx(
        'flex flex-col gap-y-2 overflow-hidden rounded-lg border',
        isPaused
          ? 'focus-within:border-gray-300'
          : 'focus-within:border-blue-400',
        isCompleted
          ? 'focus-within:border-green-light'
          : 'focus-within:border-gray-400',
      )}
    >
      <div
        className={cx(
          'flex py-2 px-4 grow flex-col md:flex-row',
          isPaused
            ? 'bg-gray-100 focus-within:bg-gray-50'
            : 'bg-gray-100 focus-within:bg-blue-50',
          isCompleted
            ? 'bg-green-lightest focus-within:bg-green-50'
            : 'bg-gray-100 focus-within:bg-gray-50',
        )}
      >
        <div className="flex grow items-center text-base text-gray-darkest">
          {!isEmpty(skill.aiGenerated) ? (
            <LightTooltip
              placement="bottom-start"
              title={<AiBadgeContent aiBadge={skill.aiGenerated!} />}
            >
              <div>
                <AiIcon
                  className="fill-gray-dark mr-1"
                  data-testid="TitleAiIcon"
                />
              </div>
            </LightTooltip>
          ) : null}
          <p className="label text-base">{skill.title}</p>
        </div>

        <div className="flex flex-wrap md:flex-nowrap items-center py-2 text-gray-500 gap-4">
          <HeaderButtons
            secondConversationPhase="evaluation"
            createdAt={skill.createdAt}
            isCompleted={isCompleted}
            isNew={isNew}
            isPaused={isPaused}
            hideNewSkill={hideNewSkill}
            onSubmit={onSubmit}
            title={skill.title}
          />
        </div>
      </div>

      <div>
        <Slider
          secondConversationPhase="evaluation"
          onSubmit={onSubmit}
          isPaused={isPaused}
          isCompleted={isCompleted}
          developmentPercentagePrevious={skill.developmentPercentagePrevious}
          developmentPercentageCurrent={skill.developmentPercentageCurrent}
          developmentPercentageLongTermGoal={
            skill.developmentPercentageLongTermGoal
          }
          developmentPercentageNextCycle={skill.developmentPercentageNextCycle}
          isNew={isNew}
        />
      </div>
      <div className="p-4">
        <p className="mb-2 text-base leading-4 text-gray-dark">
          {developmentPlanLabel ??
            'Planos e ideias para o desenvolvimento da competência'}
        </p>
        <DevelopmentPlanInput disabled value={skill.developmentPlanPrevious} />
      </div>
      <div>
        <TextInput
          name="developmentEvidence"
          disabled={isCompleted || isPaused}
          text={skill.developmentEvidence}
          handleSave={handleSave}
          label="Evidências de desenvolvimento"
        />
      </div>
      <div>
        <DevelopmentCommitment
          id={skill.id}
          isCompleted={isCompleted}
          isPaused={isPaused}
          developmentCommitment={skill.developmentCommitment}
          handleSave={(value: number) => {
            if (skill.developmentCommitment !== value)
              handleSave({ developmentCommitment: value })
          }}
        />
      </div>
    </div>
  )
}

export { SkillPlanningCard, SkillEvaluationCard, SkillPlanningWithAI }
