import * as React from 'react'
import { useCroods } from 'croods'
import ConversationPaper from 'talks/conversations/ConversationPaper'
import { schema } from 'talks/conversations/onboarding/schema'
import ConversationForm from 'talks/conversations/onboarding/conversation-form'
import { useParams } from 'react-router-dom'
import { useTrackAnalytics } from 'domain/analytics'

const NewOnboardingConversation = () => {
  const { teamMemberId } = useParams()
  let [{ info }] = useCroods({
    name: 'teamMembers',
    id: teamMemberId,
    fetchOnMount: true,
  })
  let convoCroods = useCroods({ name: 'onboardingConversations' })
  let dateCroods = useCroods({ name: 'admissionDate' })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let dateSave = React.useCallback(
    dateCroods[1].save({
      path: `/users/${info?.user?.id}/admission_date`,
      method: 'PUT',
    }),
    [info],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let convoSave = React.useCallback(
    convoCroods[1].save({
      customPath: `/team_members/${teamMemberId}/onboarding_conversations`,
    }),
    [teamMemberId],
  )
  const track = useTrackAnalytics()

  let withAdmissionDate = !info?.hasOnboardingConversation
  return (
    <ConversationPaper className="mt-10 mb-4">
      <ConversationForm
        convoSave={convoSave}
        dateSave={withAdmissionDate ? dateSave : undefined}
        schema={schema}
        user={info?.user}
        error={Boolean(convoCroods[0].saveError || dateCroods[0].saveError)}
        afterSuccess={() => track('Conversation', { Type: 'onboarding' })}
      />
    </ConversationPaper>
  )
}

export default NewOnboardingConversation
