import React from 'react'

import Modal from 'shared/ui/Modal'
import Icon from 'shared/ui/icon'

const BulkActionFeedback = ({ open, info, onClose, feedbackVerb }: any) => {
  const { success, pending } = info
  return (
    <Modal.Wrapper open={open} onClose={onClose}>
      <Modal.Header>Execução coletiva</Modal.Header>
      <Modal.Content>
        <FeedbackInfo
          title={`feedback de ${feedbackVerb}s`}
          icon={<Icon icon="check-circle" className="text-green-light" />}
          amount={success}
          singleAmountText={`solicitação ${feedbackVerb} com sucesso!`}
          pluralAmountText={`solicitações ${feedbackVerb}s com sucesso!`}
          withOutAmountText={`não há solicitações para serem ${feedbackVerb}s.`}
        />
        <FeedbackInfo
          title="feedback de pendentes"
          icon={<Icon icon="help" className="text-yellow-light" />}
          amount={pending}
          singleAmountText={`solicitação com formulário obrigatório necessita ser ${feedbackVerb} individualmente.`}
          pluralAmountText={`solicitações com formulário obrigatório necessitam ser ${feedbackVerb}s individualmente.`}
          withOutAmountText={`Não há solicitações com formulário obrigatório que necessitam ser ${feedbackVerb}s individualmente.`}
        />
      </Modal.Content>
      <Modal.Actions cancelLabel="Fechar" />
    </Modal.Wrapper>
  )
}

const FeedbackInfo = ({
  title,
  icon,
  amount,
  singleAmountText,
  pluralAmountText,
  withOutAmountText,
}: any) => {
  const feedbackText = () => {
    const response = {
      feedback0: withOutAmountText,
      feedback1: `${amount} ${singleAmountText}`,
    }
    return (
      response[`feedback${amount}` as keyof typeof response] ??
      `${amount} ${pluralAmountText}`
    )
  }

  return (
    <div title={title} className="flex flex-row items-center space-x-2 py-2">
      {icon}
      <p className="text-xs leading-4  text-gray-darkest">{feedbackText()}</p>
    </div>
  )
}

export default BulkActionFeedback
