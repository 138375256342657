import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import PlayIcon from '@material-ui/icons/PlayCircleOutline'
import PauseIcon from '@material-ui/icons/PauseCircleOutline'
import Archive from '../actions/Archive'
import Uncomplete from '../actions/Uncomplete'
import Complete from '../actions/Complete'

type Props = {
  secondConversationPhase?: 'evaluation' | 'planning'
  isCompleted: boolean
  isNew: boolean
  isPaused: boolean
  title: string | undefined
  onSubmit: (value: { [index: string]: string | number | null }) => void
  createdAt: Date | undefined
  hideNewSkill: (() => void) | undefined
}

function HeaderButtons({
  secondConversationPhase,
  isCompleted,
  isNew,
  isPaused,
  title,
  onSubmit,
  createdAt,
  hideNewSkill,
}: Props) {
  const handlePause = () => onSubmit({ pausedAt: new Date().toISOString() })
  const handleUnPause = () => onSubmit({ pausedAt: null })
  const handleComplete = () =>
    onSubmit({ completedAt: new Date().toISOString() })
  const handleUncomplete = () => onSubmit({ completedAt: null })
  const handleArchive = () => {
    if (createdAt) {
      return onSubmit({ archivedAt: new Date().toISOString() })
    }
    return hideNewSkill && hideNewSkill()
  }

  if (secondConversationPhase === 'evaluation') {
    return (
      <>
        {!isCompleted && isPaused && (
          <span className="whitespace-nowrap mr-4 rounded-full bg-black/5 px-2 py-1 text-lg text-gray-500">
            Competência pausada
          </span>
        )}
        <Complete
          skillTitle={title}
          handleComplete={handleComplete}
          isCompleted={isCompleted}
        />
        {isCompleted && (
          <Uncomplete skillTitle={title} handleUncomplete={handleUncomplete} />
        )}
      </>
    )
  }

  return (
    <>
      <span>
        {!createdAt && (
          <Chip label="Pendente" size="small" variant="outlined" />
        )}
        {isNew && <Chip label="Nova competência" size="small" />}
      </span>
      {createdAt &&
        (isPaused ? (
          <span>
            <Button onClick={handleUnPause} title="Continuar">
              <PlayIcon />
            </Button>
          </span>
        ) : (
          <>
            <Complete
              skillTitle={title}
              handleComplete={handleComplete}
              isCompleted={isCompleted}
            />

            {!isCompleted && (
              <span>
                <Button onClick={handlePause} title="Pausar">
                  <PauseIcon />
                </Button>
              </span>
            )}

            {isCompleted && (
              <Uncomplete
                skillTitle={title}
                handleUncomplete={handleUncomplete}
              />
            )}
          </>
        ))}

      {!isCompleted && (
        <Archive skillTitle={title} handleArchive={handleArchive} />
      )}
    </>
  )
}

export { HeaderButtons }
