import * as React from 'react'
import { MdOutlineBeachAccess } from 'react-icons/md'
import { cx, renderIf } from 'shared/helpers'
import { match, P } from 'ts-pattern'
import { useCroods } from 'croods'
import Icon from 'shared/ui/icon'

import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import isBefore from 'date-fns/isBefore'
import isPast from 'date-fns/isPast'
import parseISO from 'date-fns/parseISO'
import subDays from 'date-fns/subDays'

import { formatNumberOfDays } from 'portal/vacationsHelpers'

import { VacationRequestModal } from './VacationRequestModal'

import type { CollaboratorVacations, CollaboratorType, User } from './types'

const AcquisitivePeriodsHistory = ({
  collaboratorVacations,
  setCollaboratorVacationsState,
}: {
  collaboratorVacations: CollaboratorVacations
  setCollaboratorVacationsState: (
    collaboratorVacations: CollaboratorVacations,
  ) => void
}) => {
  const [{ info: user }] = useCroods<User>({
    name: 'auth',
  })

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const myVacationBalance = `Meu saldo: ${formatNumberOfDays(
    collaboratorVacations.currentBalance,
  )}`

  const parsedDateLimit = collaboratorVacations.dateLimit
    ? parseISO(collaboratorVacations.dateLimit)
    : null

  const isDateLimitInFuture = parsedDateLimit ? isPast(parsedDateLimit) : false

  const isDateLimitUpTo30DaysInFuture = parsedDateLimit
    ? isBefore(parsedDateLimit, addDays(new Date(), 30))
    : false

  const startVacationBy = parsedDateLimit
    ? `${
        isDateLimitInFuture ? 'Em atraso desde' : 'Iniciar férias até'
      } ${format(parsedDateLimit, 'dd/MM/yyyy')}`
    : ''

  const validAcquisitivePeriods =
    collaboratorVacations.acquisitivePeriods.filter(
      (acquisitivePeriod) => acquisitivePeriod.vacationBalance != 0,
    )

  const getVacationInfo = ({
    collaboratorVacations,
    user,
  }: {
    collaboratorVacations: CollaboratorVacations
    user: User
  }) =>
    ({
      avatar: user.avatar,
      email: user.email,
      firstName: user.firstName,
      id: String(user.id),
      lastName: user.lastName,
      role: user.role,
      hasCredential: user.hasCredential,
      recentlyAdded: user.recentlyAdded,

      admissionDate: user.admissionDate,
      currentBalance: collaboratorVacations.currentBalance,
      dateLimit: collaboratorVacations.dateLimit,
      expiresAt: collaboratorVacations.expiresAt,
      openingBalance: collaboratorVacations.currentBalance,

      advanceThirteenth: false,
      confirmationError: '',
      vacationDays: '0',
      vacationDaysSold: '0',
      vacationId: 0,
    } as CollaboratorType)

  return (
    <div className="h-fit overflow-hidden rounded-lg shadow">
      <div className="flex h-fit min-h-[4rem] flex-col gap-2 bg-gray-lightest px-4 py-3 sm:flex-row sm:gap-0">
        <div
          aria-hidden="true"
          className="flex h-10 min-h-[2.5rem] w-10 min-w-[2.5rem] items-center justify-center self-center rounded-full bg-gradient-to-br from-[rgba(106,151,206,0.5)] to-[rgba(239,97,164,0.5)] opacity-70 bg-blend-lighten"
        >
          <MdOutlineBeachAccess className="text-2xl text-white/50" />
        </div>

        <div className="ml-2 flex min-w-fit items-center gap-4">
          <p className="text-lg font-bold text-gray-darkest">
            {myVacationBalance}
          </p>
          <span
            className={cx(
              'text-base',
              isDateLimitUpTo30DaysInFuture
                ? 'text-red-dark'
                : 'text-gray-dark',
            )}
          >
            {startVacationBy}
          </span>
        </div>

        {collaboratorVacations.hasActiveCircle ? (
          <div className="flex w-full justify-center sm:justify-end">
            <button
              className="flex h-8 w-full items-center justify-center gap-2 self-center rounded bg-gradient-to-r from-[#7493CB] to-[#9B84BF] text-lg font-bold uppercase text-white shadow-md transition hover:bg-gradient-to-r hover:from-[#43669A] hover:to-[#6C578F] hover:shadow-lg sm:w-44"
              onClick={() => setIsModalOpen(true)}
              type="button"
            >
              <Icon icon="add-circle-outline" size="sm" />
              <span className="">Solicitar férias</span>
            </button>
          </div>
        ) : null}
      </div>

      {renderIf(
        validAcquisitivePeriods.length > 0,
        <div className="py-3">
          {validAcquisitivePeriods.map((acquisitivePeriod, index) => {
            const acquisitivePeriodDays = `${formatNumberOfDays(
              Math.trunc(acquisitivePeriod.vacationBalance),
            )}`

            const vacationBalance = acquisitivePeriod.vacationBalance

            const parsedExpiresAt = parseISO(acquisitivePeriod.expiresAt)
            const dateLimit = subDays(parsedExpiresAt!, vacationBalance)

            const acquisitivePeriodExpiration = `${
              isPast(parsedExpiresAt) ? 'em atraso desde' : 'vencem em'
            } ${format(parsedExpiresAt, 'dd/MM/yyyy')}`

            const acquisitivePeriodColor = match([vacationBalance, dateLimit])
              .with(
                [
                  P.when(
                    (vacationBalance) =>
                      typeof vacationBalance == 'number' &&
                      vacationBalance <= 0,
                  ),
                  P._,
                ],
                () => 'text-gray-dark',
              )
              .with(
                [
                  P._,
                  P.when((dateLimit) =>
                    isBefore(dateLimit, addDays(new Date(), 30)),
                  ),
                ],
                () => 'text-red-dark',
              )
              .with(
                [
                  P._,
                  P.when((dateLimit) =>
                    isBefore(dateLimit, addDays(new Date(), 60)),
                  ),
                ],
                () => 'text-yellow-dark',
              )
              .otherwise(() => 'text-gray-dark')

            return (
              <p
                key={`acquisitive-period-${index}`}
                className={cx(
                  'ml-4 mt-1 text-sm font-bold',
                  acquisitivePeriodColor,
                )}
              >
                {acquisitivePeriodDays} {acquisitivePeriodExpiration}
              </p>
            )
          })}
        </div>,
      )}

      <VacationRequestModal
        data={getVacationInfo({ collaboratorVacations, user })}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        setCollaboratorVacationsState={setCollaboratorVacationsState}
        vacations={collaboratorVacations.vacations}
      />
    </div>
  )
}

export default AcquisitivePeriodsHistory
