import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { cx } from 'shared/helpers'
import getIcon from 'shared/ui/get-icon'

function MenuItem({ path, icon, title, parent, active }: any) {
  const to = `${parent === '/' ? '' : parent}${path}`
  const color = active ? 'text-blue' : 'text-black/50'

  return (
    <ListItem
      key={path}
      button
      component={Link}
      to={to}
      className={cx(
        active && '!bg-white/40',
        'my-2 rounded px-2 hover:bg-blue/20',
      )}
    >
      <ListItemIcon className="!min-w-[33px]">
        {getIcon(icon, {
          className: color,
        })}
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          className: cx(
            'text-inherit font-semibold text-sm text-ellipsis overflow-hidden whitespace-nowrap',
            color,
          ),
        }}
        className="m-0"
      >
        {title}
      </ListItemText>
    </ListItem>
  )
}

export default MenuItem
