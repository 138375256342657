export const getSelectedIcon = ({
  questionsTypes,
  inputType,
  optionType = 'itemDefault',
}: any) => {
  if (optionType === 'one') {
    return questionsTypes[inputType].radioBoxIcon({
      style: { width: 40, height: 40 },
    })
  }
  return questionsTypes[inputType].icon({
    style: { width: 40, height: 40 },
  })
}
