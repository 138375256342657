type ThumbProps = Record<string, any>

export function checkDisabled(
  thumbProps: ThumbProps,
  isPaused: boolean,
  displayOnly: boolean,
  isCompleted: boolean,
) {
  const props = thumbProps
  if (isPaused || displayOnly || isCompleted) {
    props.onFocus = () => {}
    props.onMouseDown = () => {}
    props.onTouchStart = () => {}
  }
  return props
}
