import React, { useState } from 'react'
import StepperContext from './Context'
import { useRestoreScrollOnChange } from 'shared/utils/hooks'

type Props = {
  children: React.ReactNode
}

export default function Wrapper({ children }: Props) {
  const [step, setStep] = useState(0)
  const [stepLength, setStepLength] = useState(0)
  useRestoreScrollOnChange(step)

  const nextStep = () => {
    setStep(Math.min(step + 1, stepLength))
  }

  const previousStep = () => {
    setStep(Math.max(step - 1, 0))
  }

  return (
    <StepperContext.Provider
      value={{
        stepLength,
        setStepLength,
        currentStep: step,
        nextStep,
        previousStep,
      }}
    >
      {children}
    </StepperContext.Provider>
  )
}
