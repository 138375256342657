import * as React from 'react'
import * as z from 'zod'

import { getFullName } from 'shared/helpers'
import Avatar from 'shared/ui/Avatar'
import Modal from 'shared/ui/Modal'

import format from 'date-fns/format'

import { formatNumberOfDays } from 'portal/vacationsHelpers'

import type { CollaboratorType } from '../types'

type Props = {
  data: CollaboratorType
  isOpen: boolean
  handleConfirm: () => void
  loading: boolean
  onClose: () => void
}

const ConfirmationModal = ({
  data,
  handleConfirm,
  isOpen,
  loading,
  onClose,
}: Props) => {
  return (
    <Modal.FormWrapper
      className="!min-w-[90vw] md:!min-w-[40vw]"
      loading={loading}
      onClose={onClose}
      open={isOpen}
      schema={z.object({})}
    >
      <Modal.Header className="!bg-gray-lightest">
        <p className="grid text-lg font-bold text-gray-darkest">
          Tem certeza que quer confirmar?
          <span className="font-normal">Essa ação não pode ser desfeita.</span>
        </p>
      </Modal.Header>

      <Modal.Content className="max-h-[60vh] !w-full overflow-y-auto !bg-white !bg-opacity-95 text-gray-darkest">
        <div className="mb-4 flex items-start gap-4">
          <Avatar user={data} size={48} />
          <div className="flex flex-1 flex-col gap-0.5 text-xs text-gray-darkest">
            <h3
              aria-label="user-name"
              className="text-lg font-bold text-gray-darkest"
            >
              {getFullName(data)}
            </h3>
            {data.role && (
              <span className="text-base uppercase">{data.role}</span>
            )}
          </div>
        </div>

        <div className="mt-3 mb-2 flex gap-5">
          <p className="text-lg font-bold text-gray-darkest">
            Dias de férias
            <span className="mt-5 flex h-6 w-fit items-center rounded-2xl bg-gray-lightest px-2 text-center text-xs font-normal text-gray-darkest">
              {formatNumberOfDays(Number(data.vacationDays))}
            </span>
          </p>

          <p className="text-lg font-bold text-gray-darkest">
            Primeiro dia de férias
            <span className="mt-5 flex h-6 w-fit items-center rounded-2xl bg-gray-lightest px-2 text-center text-xs font-normal text-gray-darkest">
              {format(data.startDate, 'dd/MM/yyyy')}
            </span>
          </p>

          <p className="text-lg font-bold text-gray-darkest">
            Último dia de férias
            <span className="mt-5 flex h-6 w-fit items-center rounded-2xl bg-gray-lightest px-2 text-center text-xs font-normal text-gray-darkest">
              {format(data.endDate, 'dd/MM/yyyy')}
            </span>
          </p>
        </div>
      </Modal.Content>

      <Modal.Actions className="gap-4" cancelLabel="voltar">
        <button
          className="bt bt-contained w-[120px]"
          onClick={handleConfirm}
          type="button"
        >
          Confirmar
        </button>
      </Modal.Actions>
    </Modal.FormWrapper>
  )
}

export { ConfirmationModal }
