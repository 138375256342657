import React from 'react'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import flow from 'lodash/flow'
import isNumber from 'lodash/isNumber'
import omit from 'lodash/omit'

import Modal from 'shared/ui/Modal'
import Input from 'shared/forms/Input'
import TextArrayInput from 'shared/forms/TextArrayInput'
import CheckInput from 'shared/forms/CheckInput'
import RadioButtonsGroup from 'shared/forms/RadioButtonsGroup'
import {
  presence,
  arrayPresence,
  arrayUniqueness,
} from 'shared/forms/validations'
import { useForm } from 'shared/utils/useForm'
import { colors } from 'theme'

const OptionsListInput = ({
  handleClose,
  list,
  handleSubmit,
  editingIndex,
  step,
  resetStatus,
  inputType,
}: any) => {
  const isEditing = isNumber(editingIndex)
  const inputBeingEdited = isEditing ? list[editingIndex] : {}

  const { formState, fields, hasErrors } = useForm<{
    inputName: string
    optionType: any
    optionList: string[]
    required: any
  }>(
    {
      inputType,
      optionList: ['', ''],
      ...inputBeingEdited,
    },
    {
      requiredFields: ['inputName', 'optionType', 'optionList'],
    },
  )

  const onChangeOptionType = (value: any) => {
    formState.setField('optionType', value)
  }

  const onClose = flow(formState.reset, handleClose)

  const onSubmit: React.FormEventHandler = (e) => {
    e.preventDefault()
    if (hasErrors) return

    const transform = isEditing ? transformToUpdate : transformToCreate
    handleSubmit(
      transform({
        newItem: formState.values,
        items: list,
        index: editingIndex,
        step,
      }),
    )
    onClose()
    resetStatus()
  }

  return (
    <>
      <Modal.Content>
        <Input
          {...fields('text', 'inputName', [presence()])}
          title="Título da pergunta"
          label="Título da pergunta"
          placeholder="Ex.: Escreva aqui a justificativa de aumento pedido."
          autoComplete="off"
        />

        <div className="mb-4">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                {...omit(fields('checkbox', 'required', []), ['error'])}
              />
            }
            label="Campo obrigatório"
            style={{
              color: colors.textSecondary,
            }}
          />
        </div>

        <TextArrayInput
          //@ts-expect-error
          {...fields('raw', 'optionList', [arrayPresence(), arrayUniqueness()])}
          label="Opções"
          itemLabel="Opção"
          removeLabel="Remover Opção"
          addLabel="Adicionar Opção"
        />

        <div className="grid grid-cols-2 gap-8">
          <div>
            <CheckInput
              onChange={() => onChangeOptionType('multiple')}
              checked={formState.values.optionType === 'multiple'}
              label={
                <>
                  <strong>Mais de uma</strong> opção pode ser selecionada
                </>
              }
            />
          </div>

          <div>
            <RadioButtonsGroup
              setValue={(value: any) => onChangeOptionType(value)}
              value={formState.values.optionType || null}
              options={[
                {
                  value: 'one',
                  label: (
                    <>
                      <strong>Somente uma opção</strong> pode ser selecionada
                    </>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          title={isEditing ? 'Atualizar' : 'Adicionar'}
          size="small"
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={hasErrors}
        >
          {isEditing ? 'Atualizar' : 'Adicionar'}
        </Button>
      </Modal.Actions>
    </>
  )
}

const transformToCreate = ({ newItem, items, step }: any) => [
  ...items,
  { step, ...newItem },
]

const transformToUpdate = ({ newItem, items, index }: any) => {
  const newItems = [...items]
  newItems[index] = newItem

  return newItems
}

export default OptionsListInput
