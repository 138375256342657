import Icon from 'shared/ui/icon'
import Stepper from 'shared/ui/stepper'
import { useStepperContext } from 'shared/ui/stepper/Context'
import Button from '@material-ui/core/Button'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

type Props = {
  publish: () => Promise<unknown>
  isValidated: boolean
}

export default function Buttons({ publish, isValidated }: Props) {
  const { currentStep, stepLength } = useStepperContext()
  return (
    <div className="flex justify-between bg-blue bg-opacity-10 p-6">
      <div className="self-start">
        <Stepper.PreviousStep>Voltar</Stepper.PreviousStep>
      </div>
      <div className="self-end">
        <Stepper.NextStep
          disabled={!isValidated}
          variant="outlined"
          endIcon={<Icon icon="arrow-forward" className="inherit" />}
        >
          Avançar
        </Stepper.NextStep>
      </div>
      {currentStep === stepLength - 1 && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => publish()}
          endIcon={<ArrowForwardIcon />}
          disabled={!isValidated}
        >
          Publicar
        </Button>
      )}
    </div>
  )
}
