import map from 'lodash/map'
import omit from 'lodash/omit'
import filter from 'lodash/filter'
import find from 'lodash/find'

const CLIENT_ONLY_ATTRIBUTES = [
  'parentId',
  'childId',
  'status',
  'createdAt',
  'updatedAt',
]

const addItemsToDestroy = (list: any[], oldList: any[]) => [
  ...list,
  ...map(
    filter(oldList, ({ id }) => id && !find(list, (item) => item.id === id)),
    (item) => ({ ...item, destroy: true }),
  ),
]

const parseRolesAndUsers = (list: any[], oldList = []) =>
  addItemsToDestroy(
    map(list, ({ id, kind, value, userId, role, teamId }) => ({
      id,
      kind,
      userId: userId || kind == 'user' ? value : null,
      role: role || kind == 'role' ? value : null,
      teamId: teamId || kind == 'team' ? value : null,
    })),
    oldList,
  )

const parseDocuments = (documentsAttributes: any[], oldAttributes = []) =>
  addItemsToDestroy(
    map(documentsAttributes, (documentAttributes) => ({
      id: documentAttributes.id,
      filename: documentAttributes.filename,
      blob: documentAttributes.blob,
    })),
    oldAttributes,
  )

const parseApprovals = (
  approvalsAttributes: any[],
  oldAttributes: any[] = [],
) =>
  addItemsToDestroy(
    map(
      approvalsAttributes,
      ({
        id,
        approversAttributes,
        inputsAttributes,
        ...otherApprovalsValues
      }) => {
        const oldApproval = find(
          oldAttributes,
          (approval) => approval.id === id,
        )

        return {
          ...otherApprovalsValues,
          id,
          approversAttributes: parseRolesAndUsers(
            approversAttributes,
            oldApproval?.approversAttributes,
          ),
          inputsAttributes: addItemsToDestroy(
            inputsAttributes,
            oldApproval?.inputsAttributes,
          ),
        }
      },
    ),
    oldAttributes,
  )

const parseAttributes = (values: any = {}, oldValues: any = null) => {
  const {
    applicantsAttributes = [],
    approvalsAttributes = [],
    documentsAttributes = [],
    inputsAttributes = [],
    performerInputsAttributes = [],
    performersAttributes = [],
    ...otherValues
  } = values

  return {
    ...omit(otherValues, CLIENT_ONLY_ATTRIBUTES),
    applicantsAttributes: parseRolesAndUsers(
      applicantsAttributes,
      oldValues?.applicantsAttributes,
    ),
    approvalsAttributes: parseApprovals(
      approvalsAttributes,
      oldValues?.approvalsAttributes,
    ),
    documentsAttributes: parseDocuments(
      documentsAttributes,
      oldValues?.documentsAttributes,
    ),
    inputsAttributes: addItemsToDestroy(
      inputsAttributes,
      oldValues?.inputsAttributes,
    ),
    performerInputsAttributes: addItemsToDestroy(
      performerInputsAttributes,
      oldValues?.performerInputsAttributes,
    ),
    performersAttributes: parseRolesAndUsers(
      performersAttributes,
      oldValues?.performersAttributes,
    ),
  }
}

const parseValues = ({ filledValues, formValues }: any) => {
  const oldValues = parseAttributes(filledValues)
  return parseAttributes(formValues, oldValues)
}

export default parseValues
