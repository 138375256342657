import React from 'react'
import ReactSlider from 'shared/ui/Slider'
import Thumb from './Thumb'
import Track from './Track'
import Bar from './Bar'
import Mark from './Mark'

type Props = {
  onSubmit?: (value: { [index: string]: string | number | null }) => void
  developmentPercentagePrevious: number
  developmentPercentageCurrent: number
  developmentPercentageLongTermGoal: number
  developmentPercentageNextCycle: number
  isPaused?: boolean
  displayOnly?: boolean
  isCompleted?: boolean
  secondConversationPhase?: 'evaluation' | 'planning'
  isNew?: boolean
}

export const THUMB_NAMES = {
  last: 'Último Ciclo',
  now: 'Situação Atual',
  next: 'Meta do Ciclo',
  longterm: 'Meta Final',
} as const

type ValueOf<a> = a[keyof a]
type SliderMarkers = Partial<Record<ValueOf<typeof THUMB_NAMES>, number>>

export default function Slider({
  onSubmit,
  developmentPercentagePrevious,
  developmentPercentageCurrent = 0,
  developmentPercentageNextCycle = 15,
  developmentPercentageLongTermGoal = 100,
  isPaused,
  displayOnly,
  isCompleted,
  secondConversationPhase,
  isNew = false,
}: Props) {
  const getValues: () => {
    thumbs: SliderMarkers
    marks: SliderMarkers
  } = () => {
    if (secondConversationPhase === 'evaluation') {
      return {
        thumbs: { 'Situação Atual': developmentPercentageCurrent },
        marks: {
          'Último Ciclo': developmentPercentagePrevious || 0,

          'Meta do Ciclo': developmentPercentageNextCycle,
          'Meta Final': developmentPercentageLongTermGoal,
        },
      }
    }

    if (secondConversationPhase === 'planning') {
      if (isNew) {
        return {
          thumbs: {
            'Situação Atual': developmentPercentageCurrent,
            'Meta do Ciclo': developmentPercentageNextCycle,
            'Meta Final': developmentPercentageLongTermGoal,
          },
          marks: {},
        }
      }

      return {
        thumbs: {
          'Meta do Ciclo': developmentPercentageNextCycle,
          'Meta Final': developmentPercentageLongTermGoal,
        },
        marks: { 'Situação Atual': developmentPercentageCurrent },
      }
    }

    return {
      thumbs: {
        'Situação Atual': developmentPercentageCurrent,
        'Meta do Ciclo': developmentPercentageNextCycle,
        'Meta Final': developmentPercentageLongTermGoal,
      },
      marks: {},
    }
  }

  const handleChange = (developmentPercentage: number | number[]) => {
    if (onSubmit) {
      if (Array.isArray(developmentPercentage)) {
        if (developmentPercentage.length === 3) {
          // First conversation or new skill
          onSubmit({
            developmentPercentageCurrent: developmentPercentage[0],
            developmentPercentageNextCycle: developmentPercentage[1],
            developmentPercentageLongTermGoal: developmentPercentage[2],
          })
        } else if (developmentPercentage.length === 2) {
          // Second conversation: planning
          onSubmit({
            developmentPercentageNextCycle: developmentPercentage[0],
            developmentPercentageLongTermGoal: developmentPercentage[1],
          })
        }
      } else {
        // Second conversation: evaluation
        onSubmit({ developmentPercentageCurrent: developmentPercentage })
      }
    }
  }

  const { thumbs: thumbsRecord, marks: marksRecord } = getValues()

  function extractOrderedMarkers(markers: SliderMarkers): {
    names: string[]
    values: number[]
  } {
    const orderedSet = Object.entries(markers).sort(
      ([, percentageA], [, percentageB]) => percentageA - percentageB,
    )
    const names = orderedSet.flatMap(([name]) => name)
    const values = orderedSet.flatMap(([, percentage]) => percentage)
    return { names, values }
  }

  const orderedThumbs = extractOrderedMarkers(thumbsRecord)
  const orderedMarks = extractOrderedMarkers(marksRecord)

  return (
    <div className="relative min-h-[160px] px-2 md:px-4" data-testid="slider">
      <div className="relative top-[85px]">
        <ReactSlider
          className="h-8 w-full"
          defaultValue={orderedThumbs.values}
          marks={orderedMarks.values}
          dataLabel={orderedThumbs.names}
          ariaLabel={orderedThumbs.names}
          markLabel={orderedMarks.names}
          pearling
          snapDragDisabled
          minDistance={3}
          renderMark={(props: any) =>
            Mark({ ...props, isPaused, displayOnly, isCompleted })
          }
          renderThumb={(props: any, state: any) =>
            Thumb({ ...props, isPaused, displayOnly, isCompleted }, state)
          }
          renderTrack={(props: any, state: any) =>
            Track(
              {
                ...props,
                isPaused,
                displayOnly,
                isCompleted,
                secondConversationPhase,
              },
              state,
            )
          }
          onAfterChange={handleChange}
        />
        <Bar
          isPaused={isPaused}
          displayOnly={displayOnly}
          isCompleted={isCompleted}
        />
      </div>
    </div>
  )
}
