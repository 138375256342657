import React from 'react'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { useToggle } from '@seasonedsoftware/utils'
import flow from 'lodash/flow'
import get from 'lodash/get'
import partial from 'lodash/partial'
import size from 'lodash/size'

import { colors } from 'theme'
import { ListItem } from 'settings/solicitationTemplate/list-item'
import Icon from 'shared/ui/icon'
import { getSelectedIcon } from 'settings/solicitationTemplate/new/parsers/utils'
import QuestionTypeSelector from './questionTypeSelector'
import questionsTypes from './questionTypes'
import { match } from 'ts-pattern'

type Props = {
  value: any
  onChange: (state: any) => void
  error?: string
  helper?: string
  step: string
  siblingsQuestionsList?: Array<any>
}

const AddQuestions = ({
  value = [],
  onChange,
  error,
  helper,
  step,
  siblingsQuestionsList,
}: Props) => {
  const [open, rawToggle] = useToggle(false)
  const toggle = rawToggle as () => void

  const formHelperText = match(step)
    .with(
      'approver',
      () => 'Adicione as perguntas que deverão ser respondidas pelo aprovador.',
    )
    .with(
      'performer',
      () => 'Adicione as perguntas que deverão ser respondidas pelo executor.',
    )
    .otherwise(
      () =>
        'Adicione as perguntas que deverão ser respondidas pelo solicitante.',
    )

  const [{ selectedInputIndex, questionType }, setSelectedIndexAndType] =
    React.useState<{ selectedInputIndex: number | null; questionType: string }>(
      {
        selectedInputIndex: null,
        questionType: 'selector',
      },
    )
  const handleDialogClose = flow(
    toggle,
    partial(setSelectedIndexAndType, {
      selectedInputIndex: null,
      questionType: 'selector',
    }),
  )
  const handleButtonClick = !open ? toggle : undefined
  const classes = useStyles()

  return (
    <>
      <Typography
        title="Perguntas"
        variant="h5"
        color={error ? 'error' : 'textPrimary'}
        component="h5"
      >
        Perguntas
      </Typography>
      <p className="form-helper-text">{formHelperText}</p>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        style={{
          border: `1px dashed ${error ? colors.red : alpha(colors.blue, 0.7)}`,
        }}
      >
        <List className={classes.list}>
          {value.map((input: any, index: any) => (
            <ListItem
              key={`${get(input, 'inputName')}-${index}`}
              customIcon={getSelectedIcon({
                questionsTypes,
                inputType: input.inputType,
                optionType: input?.optionType,
              })}
              item={input}
              primaryLabel={get(input, 'inputName')}
              primaryText={
                questionsTypes[
                  get(input, 'inputType') as keyof typeof questionsTypes
                ].title
              }
              hasActions
              firstAction={flow(
                toggle,
                partial(setSelectedIndexAndType, {
                  selectedInputIndex: index,
                  questionType: get(input, 'inputType'),
                }),
              )}
              secondAction={handleClickDeleteItem({
                onChange,
                list: value,
                index,
              })}
            />
          ))}
        </List>

        <Button
          title="Adicionar pergunta"
          size="small"
          color="primary"
          variant="contained"
          startIcon={<Icon icon="add-circle-outline" className="h-5 w-5" />}
          onClick={handleButtonClick}
          style={{ marginTop: size(value) > 0 ? 24 : 0, height: 'auto' }}
        >
          Adicionar pergunta
        </Button>

        <QuestionTypeSelector
          key={selectedInputIndex}
          open={open as boolean}
          handleClose={handleDialogClose}
          list={value}
          handleSubmit={onChange}
          editingIndex={selectedInputIndex}
          step={step}
          questionType={questionType}
          siblingsQuestionsList={siblingsQuestionsList}
        />
      </Grid>
      <FormHelperText error={!!error}>{helper || error}</FormHelperText>
    </>
  )
}

export const handleClickDeleteItem =
  ({
    index,
    list,
    onChange,
  }: {
    index: number
    list: Array<any>
    onChange: (values: any) => void
  }) =>
  () => {
    onChange(
      list.filter((_: any, questionIndex: any) => questionIndex !== index),
    )
  }

const useStyles = makeStyles((theme) => ({
  listItem: {
    background: `linear-gradient(0deg, ${alpha(
      colors.textDisabled,
      0.16,
    )}, ${alpha(colors.textDisabled, 0.16)}), ${colors.white}`,
    '&:hover': {
      background: `linear-gradient(0deg, ${colors.white90}, ${colors.white90}), ${colors.blue}`,
    },
    marginBottom: theme.spacing(1),
    borderRadius: 16,
  },
  container: {
    borderRadius: 4,
    padding: theme.spacing(4),
    marginTop: 4,
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 1),
    },
  },
  list: {
    width: '100%',
    padding: 0,
    '& li': { marginBottom: '1rem' },
    '& li:last-child': { marginBottom: 0 },
  },
}))

export default AddQuestions
