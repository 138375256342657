import * as React from 'react'
import type { IconType } from 'react-icons/lib'

import { cx } from 'shared/helpers'

type SvgIconProps = Omit<IconType, 'size'> & {
  Component: React.ComponentType<any>
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full'
  title?: string
  className?: string
  style?: JSX.IntrinsicElements['svg']['style']
  onClick?: React.MouseEventHandler
}

const BaseSvgIcon = ({
  size = 'md',
  className,
  Component,
  ...props
}: SvgIconProps) => {
  const iconClasses = cx(
    size === 'xs' && 'w-3 h-3',
    size === 'sm' && 'w-4 h-4',
    size === 'md' && 'w-6 h-6',
    size === 'lg' && 'w-8 h-8',
    size === 'xl' && 'w-16 h-16',
    size === 'full' && 'w-full h-full',
    className,
  )

  return <Component {...props} className={iconClasses} />
}

export default BaseSvgIcon
export type { SvgIconProps }
