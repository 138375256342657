import {
  compact,
  compose,
  concat,
  eq,
  findIndex,
  flatten,
  get,
  isBoolean,
  isNil,
  join,
  map,
  reject,
  split,
  startsWith,
  trim,
  capitalize,
} from 'lodash/fp'
import type { Team, User } from 'types'
import deburr from 'lodash/deburr'
import toLower from 'lodash/toLower'
import identity from 'lodash/identity'
import sortBy from 'lodash/sortBy'
import mapObject from 'lodash/map'
import camelCase from 'lodash/camelCase'
import pull from 'lodash/pull'

import moment from 'moment'
import 'moment/locale/pt-br'

import { colors } from 'theme'

moment.locale('pt-br')

type ObjToBool = (o: Record<string, unknown>) => boolean
export const compareValue = (path: string, val: unknown): ObjToBool =>
  compose(eq(val), get(path))

type TypeString =
  | 'null'
  | 'undefined'
  | 'boolean'
  | 'number'
  | 'string'
  | 'date'
  | 'array'
  | 'object'
  | 'function'
export const getTypeOf = (obj: unknown) =>
  Object.prototype.toString
    .call(obj)
    .replace(/\[\w+ (\w+)\]/g, '$1')
    .toLowerCase() as TypeString

export const safeJoin = (
  collection: Array<string | null | undefined | number | boolean>,
  delimiter = ' ',
): string => compose(join(delimiter), compact)(collection)

export const splitAndTrim = compose(map(trim), split(','), trim)

export const splitAndRemoveSpaces = compose(compact, splitAndTrim)

export const findWildcardPosition = compose(
  findIndex(startsWith(':')),
  split('/'),
)

export const cx = (...args: unknown[]) =>
  compose(join(' '), reject(isBoolean), reject(isNil), flatten)(args)

export const isAdmin = (currentUser: User) => Boolean(currentUser?.admin)

export const isLeader = (
  currentUser: { teamsLeadingIds?: number[] | null },
  teamId: number | string | undefined,
) => currentUser?.teamsLeadingIds?.includes(Number(teamId))

export const formatDate = (
  date: string,
  format = 'ddd, DD [de] MMMM [de] YYYY',
) => moment(date).format(format)

export const formatDateShort = (date: string, format = 'MMM/YYYY') =>
  moment(date).format(format)

export const formatDateShorter = (date: string, format = 'MMM/YY') =>
  moment(date).format(format)

export const formatDateUnits = (date: string | Date, format = 'DD/MM/YYYY') =>
  moment(date).format(format)

export const formatDateUnitsAndTime = (
  date: string,
  format = 'DD/MM/YYYY, HH:mm',
) => moment(date).format(format)

export const formatDateWeekdayUnits = (
  date: string,
  format = 'dddd, DD/MM/YY',
) => moment(date).format(format).replace('-feira', '')

export const formatStartDateCycle = (date: string, format = 'DD/MM/YYYY') =>
  moment(date).format(format)

export const formatEndDateCycle = (date: string, format = 'DD/MM/YYYY') =>
  moment(date).subtract(15, 'days').format(format)

export const formatMonthYear = (date: string) =>
  moment(date).format('MMM/YY').toLocaleLowerCase()

export const dateStatus = (date: string) => {
  const conversationDate = moment(date)
  const currentDate = moment()

  const diff = conversationDate.diff(currentDate, 'days')

  if (diff > 7) return { status: '', color: colors.grey10 }

  if (diff >= 0 && diff <= 7)
    return {
      status: 'Menos de 1 semana',
      color: colors.yellow,
    }

  return {
    status: 'Atrasada',
    color: colors.red,
  }
}

export const dateFromStringAtMidnight = (date: unknown) =>
  new Date(String(date) + 'T00:00:00.000')

export const scrollTo = (targetId: string) => {
  const element = document.getElementById(targetId)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

export const matchSearch = (searchTerm: string, terms: string[]) => {
  const normalize = compose([toLower, deburr])
  const search = normalize(searchTerm)
  return terms.map(normalize).some((term) => term.includes(search))
}

export const getFullName = (
  {
    firstName,
    lastName,
    email,
  }: { firstName?: string | null; lastName?: string | null; email?: string | null },
  fallback = email,
) => {
  const name = [firstName, lastName]
  return name.every(identity) ? name.join(' ').trim() : fallback ?? ''
}

export const getUserIdentifier = (
  {
    user: { email, ...user },
  }: {
    user: {
      firstName?: string | null
      lastName?: string | null
      email?: string | null
    }
  },
  fallback = email,
) => getFullName({ ...user, email, lastName: user.lastName ?? ' ' }, fallback)

export const getSortedCircles = ({
  teamMembers,
  teamLeaders,
}: {
  teamMembers: Team[] | undefined
  teamLeaders: Team[] | undefined
}) => {
  const joinArrays = compose([compact, concat])
  return sortBy(joinArrays(teamMembers, teamLeaders), 'teamName')
}

const denominations = {
  team: 'time',
  area: 'área',
  sector: 'setor',
  department: 'departamento',
  squad: 'squad',
  tribe: 'tribe',
  company: 'empresa',
  branch: 'filial',
  unity: 'unidade',
  chapter: 'chapter',
}
export const getDenomination = (type: string) =>
  denominations[type as keyof typeof denominations] ?? 'círculo sem denominação'

export const getDenominationOptions = () =>
  mapObject(denominations, (v: string, k: string) => ({
    label: capitalize(v),
    value: k,
  }))

export const addOrRemoveValue = (array: number[], value: number): number[] => {
  const arr = [...array]
  if (arr.length === pull(arr, value).length) {
    arr.push(value)
  }
  return arr
}

export const renderIf = (condition: boolean, elementIf: JSX.Element) =>
  condition ? elementIf : null

export function createHumps(obj: Record<string, unknown>) {
  const camelisedList: Array<[string, unknown]> = Object.entries(obj).map(
    ([key, value]) => [
      camelCase(key),
      value instanceof Object
        ? createHumps(value as Record<string, unknown>)
        : value,
    ],
  )

  return Object.fromEntries(camelisedList)
}

const mountQueryString = (params: Record<string, any>) => {
  const query = new URLSearchParams()
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      query.append(key, params[key])
    }
  })

  return query.toString() ? `?${query.toString()}` : ''
}

function fullPathFromLocation(location: {
  pathname: string
  search: string
  hash: string
}) {
  const { pathname, search, hash } = location
  return [pathname, search, hash].join('')
}

async function onErrorDefaultTo<T>(
  fn: () => T | Promise<T>,
  defaultValue: Awaited<T>,
): Promise<T> {
  try {
    return await fn()
  } catch {
    return defaultValue
  }
}

async function onErrorThrow<T>(
  fn: () => T | Promise<T>,
  error: unknown,
): Promise<T> {
  try {
    return await fn()
  } catch {
    throw error
  }
}

export {
  mountQueryString,
  fullPathFromLocation,
  onErrorDefaultTo,
  onErrorThrow,
}
