import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import type { AutocompleteChangeDetails } from '@material-ui/lab/Autocomplete'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import type { Role } from 'performance/evaluations/membersEvaluation/types'
import flatMap from 'lodash/flatMap'

type Props = {
  allRoles: Role[]
  roles: Role[]
  getDisabledRoles?: (role: Role) => boolean
  handleChange: (value: any, inputValue?: string) => void
  autocompleteProps?: {}
}

const filter = createFilterOptions<Role>()

export default function SecondaryRole({
  allRoles,
  roles,
  handleChange,
  getDisabledRoles,
  autocompleteProps,
}: Props) {
  const getOptionSelected = (option: Role, value: Role) =>
    option.id === value.id

  const handleFilter = (options: any, params: any) => {
    const filtered = filter(options, params)

    if (params.inputValue !== '') {
      filtered.push({
        id: 0,
        inputValue: params.inputValue,
        name: params.inputValue,
      })
    }

    return filtered
  }

  const renderOption = (option: Role) => {
    if (option.inputValue) {
      return (
        <span className="font-bold uppercase text-blue">
          Adicionar &quot;{option.name}&quot;
        </span>
      )
    }
    return <span>{option.name}</span>
  }

  const noOptionsText =
    allRoles.length < 7 ? 'Papel não encontrado' : 'Limite de papéis atingido'

  return (
    <FormControl>
      <Autocomplete
        style={{ width: '100%' }}
        size="small"
        multiple
        options={roles.length < 7 ? allRoles : []}
        noOptionsText={noOptionsText}
        disableClearable
        onChange={(
          _: React.ChangeEvent<{}>,
          value: Role[],
          __: any,
          details: AutocompleteChangeDetails<Role> | undefined,
        ) => {
          handleChange(flatMap(value), details?.option?.inputValue)
        }}
        value={roles}
        getOptionSelected={getOptionSelected}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={getDisabledRoles}
        renderOption={renderOption}
        filterOptions={handleFilter}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                maxLength: 120,
              },
            }}
          />
        )}
        data-testid="secondaryRole"
        {...autocompleteProps}
      />
    </FormControl>
  )
}
