import moment from 'moment'
import map from 'lodash/map'
import * as React from 'react'
import { Fetch } from 'croods'
import { useParams } from 'react-router-dom'
import compact from 'lodash/compact'

import EmptyMessageFeedback from 'portal/EmptyMessageFeedback'
import { joinInputsHour } from 'portal/clocking-panel/helpers'
import Alert from 'shared/ui/alert'
import Skeleton from 'shared/ui/skeleton'
import Form from './form'

type Props = {
  pageTitle: string
}

type HeaderInfoProps = {
  title: string
  children: React.ReactNode
}

type MasterProps = {
  id: number
  clockId: number
  masterDate: string
  status: string
  kind: string
  confirmed: null
  defaultMoments: string
  moments: Array<string>
  warnings: Array<string>
  errors: Array<string>
  confirmationError: string | null
  confirmationPending: boolean
}

const HeaderInfo = ({ title, children }: HeaderInfoProps) => {
  return (
    <section className="flex flex-col items-start gap-1">
      <h4 className="m-0 p-0 text-lg font-bold leading-6 text-gray-darkest">
        {title}
      </h4>
      <div className="m-0 flex flex-row items-center gap-4 p-0 text-base font-normal uppercase leading-4 tracking-wider text-gray-dark">
        {children}
      </div>
    </section>
  )
}

const ClockingPanelSolicitation = ({ pageTitle }: Props) => {
  const { masterId } = useParams()

  return (
    <>
      <h3
        title="page-title"
        className="flex items-baseline gap-2 first-letter:uppercase"
      >
        {pageTitle}
      </h3>
      <Fetch
        name={'clockingSolicitation'}
        path={`/workflow/masters/${masterId}`}
        stateId={masterId}
        render={(master: MasterProps) => (
          <article className="card mt-4 flex w-full flex-col overflow-visible p-4">
            <section
              aria-label="master-information"
              className="mx-4 mb-4 grid grid-cols-1 gap-8 sm:grid-cols-2"
            >
              <HeaderInfo title="Data">
                {moment(master.masterDate).format('DD/MM/YYYY ddd')}
              </HeaderInfo>
              <HeaderInfo title="Tipo de Dias">{master.kind}</HeaderInfo>

              {Boolean(master.defaultMoments) ? (
                <HeaderInfo title="Horário Previsto">
                  {master.defaultMoments}
                </HeaderInfo>
              ) : null}

              {compact(master.moments).length > 0 ? (
                <HeaderInfo title="Horário Realizado">
                  <span>{joinInputsHour(master.moments)}</span>
                </HeaderInfo>
              ) : null}

              <div
                aria-label="alerts"
                className="flex flex-row flex-wrap gap-3 sm:col-span-2"
              >
                {map(master.errors, (error) => (
                  <span
                    key={error}
                    className="text-red bg-red-lightest w-fit text-center rounded-lg px-2 font-bold uppercase whitespace-nowrap"
                  >
                    {error}
                  </span>
                ))}
                {map(master.warnings, (warning) => (
                  <span
                    key={warning}
                    className="text-yellow bg-yellow-lightest w-fit text-center rounded-lg px-2 font-bold uppercase whitespace-nowrap"
                  >
                    {warning}
                  </span>
                ))}
              </div>
            </section>
            <hr className="mb-6" />
            <section aria-label="solicitation-form-info">
              <h4 className="m-0 mb-2 p-0 text-xl font-bold leading-6 text-gray-darkest">
                Adicionar marcação de ponto
              </h4>
              <div className="mb-7 w-auto max-w-xl">
                <Alert
                  message="Somente permitida adição de horários que você não marcou anteriormente"
                  severity="warning"
                />
              </div>
              <Form
                id={masterId as string}
                clockId={master.clockId}
                masterDate={master.masterDate}
                defaultTimeInputsValue={master.moments}
              />
            </section>
          </article>
        )}
        renderLoading={() => <Skeleton className="!h-96 w-full p-4" />}
        renderError={() => (
          <EmptyMessageFeedback type="error">
            Não foi possível carregar os dados necessários para abrir uma
            solicitação.
          </EmptyMessageFeedback>
        )}
      />
    </>
  )
}

export default ClockingPanelSolicitation
