import Goals from 'talks/conversations/career/goals'
import Title from 'shared/ui/Title'
import Skills from 'talks/conversations/career/skills'
import { formatMonthYear, renderIf } from 'shared/helpers'
import PlaceRoundedIcon from '@material-ui/icons/PlaceRounded'
import FlagIcon from '@material-ui/icons/Flag'
import ArrowForward from '@material-ui/icons/ArrowForward'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import getIcon from 'shared/ui/get-icon'
import Icon from 'shared/ui/icon'
import type {
  IndividualOutputValues,
  PathProfileValues,
  TeamworkValues,
} from 'talks/types'
import React from 'react'

function Assessment({
  label,
  description,
  color,
  icon,
}: {
  label: string
  description: string
  color: string
  icon: React.ReactElement
}) {
  return (
    <div className="card h-full">
      <div
        className="flex h-10 items-center gap-2 pl-4"
        style={{ backgroundColor: color }}
      >
        {icon}
        <h5 className="text-lg font-bold uppercase">{label}</h5>
      </div>
      <div className="flex-1 px-4 py-4">
        <p
          className="text-base text-gray-darkest"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  )
}

function Assessments({
  individualOutput,
  teamwork,
  pathProfile,
}: {
  individualOutput: IndividualOutputValues
  teamwork: TeamworkValues
  pathProfile: PathProfileValues
}) {
  return (
    <div className="items-center flex flex-col md:items-stretch md:!flex-row gap-8 md:justify-start text-2xl mb-6 mt-2">
      <div className="flex flex-col max-w-[15rem]">
        <h4 className="mb-4 font-bold leading-6">Entrega</h4>
        {renderIf(
          individualOutput === 'dependency',
          <Assessment
            label="Dependência"
            description="Realiza entregas mais <b>simples, dependendo dos colegas</b> para realizar entregas mais relevantes."
            color="#f0b8584d"
            icon={<Icon icon="vertical-align-bottom" />}
          />,
        )}
        {renderIf(
          individualOutput === 'autonomy',
          <Assessment
            label="Autonomia"
            description="Realiza entregas relevantes para o time de forma <b>autônoma</b>."
            color="#5bca8a4d"
            icon={<Icon icon="drag-handle" />}
          />,
        )}
        {renderIf(
          individualOutput === 'differentiated',
          <Assessment
            label="Diferenciada"
            description="Realiza entregas <b>diferenciadas</b>, acima da média de mercado e que poderiam ser apresentadas em <b>cases</b>."
            color="#24513780"
            icon={<Icon icon="vertical-align-top" />}
          />,
        )}
      </div>
      <div className="flex flex-col max-w-[15rem]">
        <h4 className="mb-4 font-bold leading-6">Trabalho em Equipe</h4>
        {renderIf(
          teamwork === 'individual',
          <Assessment
            label="Individual"
            description="<b>Trabalha mais individualmente</b> do que coletivamente."
            color="#f0b8584d"
            icon={<Icon icon="vertical-align-bottom" />}
          />,
        )}
        {renderIf(
          teamwork === 'member',
          <Assessment
            label="Membro"
            description="<b>Trabalha em equipe</b> para que todos atinjam os objetivos coletivamente."
            color="#5bca8a4d"
            icon={<Icon icon="drag-handle" />}
          />,
        )}
        {renderIf(
          teamwork === 'reference',
          <Assessment
            label="Referência"
            description="É uma <b>referência pessoal</b> e profissional para os demais membros do time."
            color="#24513780"
            icon={<Icon icon="vertical-align-top" />}
          />,
        )}
      </div>
      <div className="flex flex-col max-w-[15rem]">
        <h4 className="mb-4 min-w-[275px] tracking-tighter font-bold leading-6">
          Perfil de Trajetória desejado
        </h4>
        {renderIf(
          pathProfile === 'care',
          <Assessment
            label="CUIDADO"
            description="Performa melhor em ambientes com atividades que exigem <b>cuidado</b>, <b>disciplina</b>, <b>resiliência </b>e/ou <b>sensibilidade</b>."
            color="#9499d480"
            icon={getIcon('care')}
          />,
        )}
        {renderIf(
          pathProfile === 'challenge',
          <Assessment
            label="DESAFIO"
            description="Não sucumbe ao status quo e tem muito desejo de modificar as coisas ao seu redor, além de <b>aprender</b>, enfrentar <b>desafios </b>e realizar <b>inovações </b>de impacto."
            color="#5f91cc80"
            icon={getIcon('auto-graph')}
          />,
        )}
      </div>
    </div>
  )
}

function DateChip({ date }: { date?: string }) {
  return (
    <div className="rounded-2xl bg-gray-200 px-2 py-1 uppercase">
      {date ? formatMonthYear(date) : 'MM/YY'}
    </div>
  )
}

const MyGoals = ({ id, snapshot }: { id: number; snapshot?: boolean }) => (
  <>
    <Title>Meus Objetivos</Title>
    <Goals id={id} snapshot={snapshot} />
  </>
)

const MySkills = ({
  conversationDate,
  nextConversation = new Date(),
}: {
  conversationDate?: string
  nextConversation?: any
}) => (
  <>
    <Title className="my-4">Minhas Competências em Desenvolvimento</Title>
    <div className="mb-4 flex items-center">
      <div className="mr-1 flex items-center sm:mr-2">
        <div className="mr-1 rounded-full bg-purple p-0 sm:mr-2 sm:p-1">
          <PlaceRoundedIcon className="p-1 text-white" fontSize="small" />
        </div>
        <div className="mr-1 flex flex-col sm:mr-2 ">
          <div className="text-md text-center text-gray-dark sm:text-left sm:text-lg">
            Situação em:
          </div>
        </div>
        <DateChip date={conversationDate} />
      </div>
      <ArrowForward className="mr-2 text-gray sm:mr-2" fontSize="small" />
      <div className="flex items-center justify-center">
        <div className="mr-1 -rotate-45 scale-90 rounded-md bg-purple p-0 sm:mr-2 sm:p-1">
          <FlagIcon className="rotate-45 p-1 text-white" fontSize="small" />
        </div>
        <div className="mr-0 flex flex-col sm:mr-2 ">
          <div className="text-md text-center text-gray-dark sm:text-left sm:text-lg">
            Meta do Ciclo:
          </div>
        </div>
        <DateChip date={nextConversation} />
      </div>
    </div>
  </>
)

const CareerInfo = ({
  id,
  conversationDate,
  nextConversation,
  supportPlan,
  snapshot = false,
  organizationRole,
  secondaryRoles,
  assessments,
}: {
  id: number
  conversationDate?: string
  nextConversation?: any
  supportPlan?: string
  snapshot?: boolean
  organizationRole?: any
  secondaryRoles?: any
  assessments?: {
    teamwork: TeamworkValues
    individualOutput: IndividualOutputValues
    pathProfile: PathProfileValues
  }
}) => {
  if (!id) return null

  return (
    <div>
      {organizationRole && (
        <>
          <Title>Papéis</Title>
          <div className="mb-8 flex flex-col items-start md:flex-row gap-4">
            <div className="flex flex-col">
              <h5 className="mb-2">Papel Principal</h5>
              <div className="mr-2 flex content-center justify-center rounded-2xl border border-gray-dark bg-white px-2 py-1 text-base text-gray-dark">
                {organizationRole?.name}
              </div>
            </div>
            {secondaryRoles && secondaryRoles.length > 0 && (
              <div className="flex flex-col">
                <h5 className="mb-2">Outros Papéis</h5>
                <div className="flex">
                  {secondaryRoles.map((role: any, index: number) => (
                    <div
                      key={index}
                      className="mr-2 flex content-center justify-center rounded-2xl border border-gray-dark bg-white px-2 py-1 text-base text-gray-dark"
                    >
                      {role?.name}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {assessments && Object.values(assessments).every(Boolean) && (
        <Assessments
          teamwork={assessments.teamwork}
          individualOutput={assessments.individualOutput}
          pathProfile={assessments.pathProfile}
        />
      )}

      <MyGoals id={id} snapshot={snapshot} />
      <MySkills
        conversationDate={conversationDate}
        nextConversation={nextConversation}
      />
      <h6 className="mb-2 text-gray-darkest">
        Que apoio você precisa para seu desenvolvimento?
      </h6>
      <TextareaAutosize
        data-testid="supportPlan"
        disabled={true}
        name="supportPlan"
        className="pointer-events-none mb-4 w-full resize-none rounded border bg-gray-lightest p-4 text-gray-dark focus-within:border-blue-light focus:outline-none"
        value={supportPlan}
        placeholder="Não há informação para apoio de desenvolvimento cadastrada"
      />
      <Skills id={id} snapshot={snapshot} />
    </div>
  )
}

export default CareerInfo
