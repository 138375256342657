import { getUserIdentifier, formatDateUnits } from 'shared/helpers'
import { useCurrentUser } from 'croods-auth'
import Avatar from 'shared/ui/Avatar'
import type { User } from 'types'
import Chip from 'shared/ui/chip'

export default function Identification({ user }: { user: User }) {
  const { role, email, active } = user

  const [{ currentUser }] = useCurrentUser()
  const showEmail = currentUser.admin

  const isInactive = !active

  return (
    <div className="flex sm:border-r sm:border-gray/40 grow-0 w-full sm:w-[448px]">
      <div className="radial-gradient flex justify-center items-center p-2 sm:p-8">
        <Avatar user={user} size={84} />
      </div>
      <div className="flex flex-col pl-4 px-8 pb-8 w-full">
        <div className="my-4">
          <div className="flex justify-between flex-wrap sm:items-center gap-2">
            <div>
              <h5 className="bold">
                {getUserIdentifier(
                  { user },
                  showEmail ? '[Sem nome]' : user.email,
                )}
              </h5>
              {role && <p className="text-gray-darker">{role}</p>}
            </div>
            {isInactive && (
              <div className="bg-gray-darkest text-white px-3 py-1 font-bold rounded-full h-fit">
                INATIVO
              </div>
            )}
          </div>
        </div>
        {showEmail && (
          <p className="mb-2 overflow-hidden overflow-ellipsis text-gray-darker">
            {email}
          </p>
        )}

        {user.admissionDate && (
          <div className="flex flex-col justify-center">
            <strong className="mb-0.5 tracking-wide text-gray-dark text-base">
              Data de admissão
            </strong>
            <Chip className="items-start self-start !bg-gray-200">
              {formatDateUnits(user.admissionDate)}
            </Chip>
          </div>
        )}
      </div>
    </div>
  )
}
