import React from 'react'
import { useStepperContext } from 'shared/ui/stepper/Context'

type Props = {
  children: React.ReactNode[]
}

export default function MobileSteps({ children }: Props) {
  const { currentStep, stepLength } = useStepperContext()

  const steps = React.Children.map(children, (step, index) => {
    if (index < currentStep - 1 || index > currentStep + 1) return null

    const el =
      React.isValidElement(step) && typeof step.type === 'function'
        ? React.cloneElement(step, {
            key: `mobile-step-${index}`,
            // @ts-expect-error
            active: currentStep === index,
            passed: currentStep > index,
          })
        : step

    return el
  })

  return (
    <div className="grid grid-cols-3 md:hidden">
      {currentStep === 0 && <div />}
      {steps}
      {currentStep === stepLength - 1 && <div />}
    </div>
  )
}
