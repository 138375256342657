import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

const FormContainer = ({ classes, form, title, styles }: any) => (
  <Grid container>
    {title && (
      <Grid item>
        <h3>{title}</h3>
      </Grid>
    )}
    <Grid item style={styles} className={classes.formContainer}>
      {form}
    </Grid>
  </Grid>
)

const styles = () => ({
  formContainer: {
    margin: 0,
    width: '100%',
  },
})

export default withStyles(styles)(FormContainer)
