import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import ReactQuill from 'react-quill'
import { cx } from 'shared/helpers'
import InputHint from './input-hint'
import Label from './label'

type RichContent = {
  text: string
  html: string
}
function parseContent(content: string) {
  if (!content) return null
  try {
    return JSON.parse(content) as RichContent
  } catch (err) {
    return null
  }
}

function isEditor(editor: any): editor is ReactQuill.UnprivilegedEditor {
  return (
    editor &&
    typeof editor.getHTML === 'function' &&
    typeof editor.getText === 'function'
  )
}
function isMountedEditor(editor: ReactQuill | null): editor is ReactQuill {
  return (
    editor !== null &&
    editor &&
    typeof editor.editor !== 'undefined' &&
    typeof editor.getEditor === 'function'
  )
}

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name'> & {
  name: string
  label?: string
  hint?: string
  rows?: number
}
function RichtextInput({
  name,
  id = name,
  hint,
  label,
  required,
  className,
  rows = 3,
  defaultValue,
  ...props
}: Props) {
  let { register, formState, setValue } = useFormContext()
  let hasErrors = Boolean(formState.errors?.[name]?.message)
  let { onBlur, ref, ...formProps } = register(name)
  let innerRef = React.useRef<HTMLInputElement>(null)
  let editorRef = React.useRef<ReactQuill>(null)

  function handleChange(editor: ReactQuill.UnprivilegedEditor): void {
    if (!isEditor(editor)) return void 0
    let content: RichContent = {
      text: editor.getText(),
      html: editor.getHTML(),
    }
    updateValue(content)
  }

  function updateValue(content: RichContent) {
    let value = JSON.stringify(content)
    setValue(name, value)
    props.onChange?.({
      target: { value },
    } as React.ChangeEvent<HTMLInputElement>)
  }

  React.useEffect(() => {
    if (defaultValue && isMountedEditor(editorRef.current)) {
      let editor = editorRef.current.getEditor()
      updateValue({
        text: editor.getText() ?? '',
        html: defaultValue as string,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  let hintId = `input-${id}-hint`
  let labelId = `richtextinput-label-${id}`
  return (
    <>
      <Label id={labelId} aria-required={required} htmlFor={id}>
        {label}
      </Label>
      <input
        type="hidden"
        aria-labelledby={labelId}
        id={id}
        aria-describedby={hasErrors ? hintId : undefined}
        aria-invalid={hasErrors ? 'true' : 'false'}
        {...props}
        {...formProps}
        ref={(obj) => {
          ref(obj)
          // @ts-ignore
          innerRef.current = obj
        }}
      />
      <ReactQuill
        ref={editorRef}
        className={cx(
          'input !p-0 !font-normal focus-within:ring-1',
          hasErrors && '!border-red-dark !text-red-dark',
          className,
        )}
        style={{
          height: `calc(${rows} * 1.5rem)`,
        }}
        theme="bubble"
        onBlur={() => {
          const ev = {
            target: innerRef.current,
          } as React.FocusEvent<HTMLInputElement>
          onBlur(ev)
          props.onBlur?.(ev)
        }}
        onChange={(_value, _, __, editor) => {
          handleChange(editor)
        }}
        defaultValue={defaultValue as string}
        modules={{
          toolbar: [
            ['bold', 'italic', { header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
          ],
          keyboard: {
            bindings: {
              tab: { key: 9, handler: () => true }, // This is to make TAB behave like a tab
            },
          },
        }}
      />
      <InputHint id={hintId} isError={hasErrors}>
        {formState.errors?.[name]?.message ?? hint}
      </InputHint>
    </>
  )
}

export type { RichContent }
export { parseContent }
export default RichtextInput
