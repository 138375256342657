import * as React from 'react'

const AutoGraphIconOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 96 960 960"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="m330 408-37-79-79-37 79-37 37-79 37 79 79 37-79 37-37 79Zm270 121-31-66-66-31 66-31 31-66 31 66 66 31-66 31-31 66Zm-463 80-31-66-66-31 66-31 31-66 31 66 66 31-66 31-31 66Zm29 307-46-46 300-300 160 161 298-334 42 41-340 383-160-159-254 254Z"
      fill="currentColor"
    />
  </svg>
)

export default AutoGraphIconOutline
