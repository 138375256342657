import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForgotPassword } from 'croods-auth'
import { isDev } from '@seasonedsoftware/utils'

import FormError from 'shared/forms/FormError'
import getOrganizationSlug from 'shared/organization/getOrganizationSlug'
import Input from './CustomInput'
import AuthForm from './AuthForm'

export const buildRedirectUrl = () => {
  const protocol = isDev() ? 'http' : 'https'

  return `${protocol}://${getOrganizationSlug()}.${
    process.env.REACT_APP_WEB_URL
  }/reset-password`
}

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [{ sending: sendingForgot, error, formProps, emailProps }] =
    useForgotPassword({
      afterSuccess: () => navigate('/forgot-sent'),
      redirectUrl: buildRedirectUrl(),
    })
  return (
    <div className="my-4 mx-1 sm:mx-10 sm:my-10 md:my-14">
      <section className="mx-auto w-[95%] max-w-[512px] rounded-xl bg-white py-6 px-2 shadow sm:rounded-2xl sm:py-6 sm:px-6">
        <div className="flex flex-col">
          <img
            src="/vibe_logo_novo_colorido.svg"
            alt="Vibe logo"
            className="w-[200px] self-center"
          />
          <div className="py-10">
            <AuthForm
              title="Esqueci minha senha"
              form={
                <form {...formProps}>
                  <ForgotPasswordForm
                    emailProps={emailProps}
                    error={error}
                    sendingForgot={sendingForgot}
                  />
                </form>
              }
            />
          </div>
          <img
            src="/logo_metadados_novo_colorido.svg"
            alt="Metadados RH em Sintonia"
            className="w-[135px] self-center"
          />
        </div>
      </section>
    </div>
  )
}

const ForgotPasswordForm = ({ emailProps, error, sendingForgot }: any) => (
  <div className="mt-7 flex w-full flex-col items-center gap-5">
    <Input {...emailProps} />

    <FormError>{error}</FormError>

    <button disabled={sendingForgot} className="bt bt-contained mt-2 self-end">
      Resgatar por email
    </button>
  </div>
)

export default ForgotPassword
