import * as React from 'react'
import { useForm } from 'shared/utils/useForm'
import OnOff from 'shared/ui/on-off'
import { setNameAndDenomination } from 'vibe/circle/new/parseTeamsAndUsers'
import { OrganizationContext } from 'shared/organization/OrganizationContext'
import { renderIf } from 'shared/helpers'

const ChildCirclePermissions = ({ values, onChange, state }: any) => {
  const organization = React.useContext(OrganizationContext)

  const { fields } = useForm(
    {
      enableOneOnOneConversation:
        state?.permissions?.enableOneOnOneConversation ?? false,
      enableCareerConversation:
        state?.permissions?.enableCareerConversation ?? false,
      enableBehaviorConversation:
        state?.permissions?.enableBehaviorConversation ?? false,
      enableUpwardEvaluation:
        state?.permissions?.enableUpwardEvaluation ?? false,
      permitTalentsEvaluations:
        state?.permissions?.permitTalentsEvaluations ?? false,
      permitTeamsEvaluations:
        state?.permissions?.permitTeamsEvaluations ?? false,
    },
    {},
  )
  const nameDenomination = setNameAndDenomination(values)
  const handleChange =
    ({ permissions }: any) =>
    (option: any) => {
      const newValues = { ...permissions, ...option }
      onChange(newValues)
    }

  const anyPermitedConversation =
    organization?.permitOneOnOneConversation ||
    organization?.permitCareerConversation ||
    organization?.permitBehaviorConversation ||
    organization?.permitUpwardEvaluation

  const anyPermitedPerformance =
    organization?.permitTeamsEvaluations ||
    organization?.permitTalentsEvaluations

  return (
    <div className="flex flex-col gap-4">
      {renderIf(
        anyPermitedConversation,
        <div>
          <h5>Configurações de Conversas</h5>
          <p className="form-helper-text mt-0 mb-1">
            Se aplica aos colaboradores que respondem ao círculo{' '}
            {nameDenomination}
          </p>
          <div className="flex flex-col items-center rounded border-blue/70 pt-2">
            {renderIf(
              organization?.permitOneOnOneConversation,
              <OnOff
                {...fields('raw', 'enableOneOnOneConversation', [], {
                  touchOnChange: true,
                })}
                label="Fazer Conversas de Onboarding, Efetivação e 1:1"
                type="enableOneOnOneConversation"
                handleChange={handleChange(state)}
              />,
            )}
            {renderIf(
              organization?.permitCareerConversation,
              <OnOff
                {...fields('raw', 'enableCareerConversation', [], {
                  touchOnChange: true,
                })}
                label="Fazer Conversa de Carreira"
                type="enableCareerConversation"
                handleChange={handleChange(state)}
              />,
            )}
            {renderIf(
              organization?.permitBehaviorConversation,
              <OnOff
                {...fields('raw', 'enableBehaviorConversation', [], {
                  touchOnChange: true,
                })}
                label="Fazer Conversa de Comportamento"
                type="enableBehaviorConversation"
                handleChange={handleChange(state)}
              />,
            )}
            {renderIf(
              organization?.permitUpwardEvaluation,
              <OnOff
                {...fields('raw', 'enableUpwardEvaluation', [], {
                  touchOnChange: true,
                })}
                label="Fazer Avaliação Ascendente"
                type="enableUpwardEvaluation"
                handleChange={handleChange(state)}
              />,
            )}
          </div>
        </div>,
      )}
      {renderIf(
        anyPermitedPerformance,
        <div>
          <h5>Configurações de Performance</h5>
          <p className="form-helper-text mt-0 mb-1">
            {renderIf(
              organization?.permitTalentsEvaluations,
              <span>
                Em Avaliação de Talentos se aplica aos colaboradores que
                respondem ao círculo {nameDenomination}.{' '}
              </span>,
            )}

            {renderIf(
              organization?.permitTeamsEvaluations,
              <span>
                Em Avaliação de Performance se aplica ao círculo todo.
              </span>,
            )}
          </p>
          <div className="flex flex-col items-center rounded border-blue/70 px-0 pt-2">
            {renderIf(
              organization?.permitTalentsEvaluations,
              <OnOff
                {...fields('raw', 'permitTalentsEvaluations', [], {
                  touchOnChange: true,
                })}
                label="Fazer Avaliação de Talentos"
                type="permitTalentsEvaluations"
                handleChange={handleChange(state)}
              />,
            )}
            {renderIf(
              organization?.permitTeamsEvaluations,
              <OnOff
                {...fields('raw', 'permitTeamsEvaluations', [], {
                  touchOnChange: true,
                })}
                label="Fazer Avaliação de Performance"
                type="permitTeamsEvaluations"
                handleChange={handleChange(state)}
              />,
            )}
          </div>
        </div>,
      )}
    </div>
  )
}

export default ChildCirclePermissions
