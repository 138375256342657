import Modal from 'shared/ui/Modal'

type PropsConfirmationModal = {
  show: boolean
  onClose: () => void
  handleSave: () => void
  children: React.ReactNode
}

function ConfirmationModal({
  show, onClose, handleSave, children,
}: PropsConfirmationModal) {
  return (
    <Modal.Wrapper open={show} onClose={onClose}>
      <Modal.Header>
        <div className="flex items-start">
          <h5 className="mx-2">Confirmar alteração</h5>
        </div>
      </Modal.Header>
      <Modal.Content className="mx-2 gap-6 md:min-w-[600px]">
        {children}
      </Modal.Content>
      <Modal.Actions cancelLabel="FECHAR">
        <Modal.Confirm color="primary" onClick={handleSave}>
          SALVAR E CONFIRMAR
        </Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export { ConfirmationModal }
