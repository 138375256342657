import { useLayoutEffect } from 'react'
import { useCurrentUser } from 'croods-auth'
import { authStorageKey } from 'domain/auth'

const LegacyAuth = ({
  Component,
  authorizing,
  unauthorized,
  unauthorize,
  ...props
}: any) => {
  const [{ currentUser, validating }, setCurrentUser] = useCurrentUser({
    afterFailure: () => unauthorized(currentUser),
    storageKey: authStorageKey(),
  })

  useLayoutEffect(() => {
    if (unauthorize && currentUser) {
      unauthorize(currentUser) && unauthorized(currentUser)
    }
  }, [currentUser, unauthorize, unauthorized])

  return validating || !currentUser ? (
    authorizing
  ) : (
    <Component
      {...props}
      currentUser={currentUser}
      setCurrentUser={setCurrentUser}
    />
  )
}

export { LegacyAuth }
