import { useCroods } from 'croods'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import Modal from 'shared/ui/Modal'
import Input from 'shared/formsv2/input'
import { usePendingSolicitation } from 'portal/pendingSolicitations/context'
import * as z from 'zod'

const RejectModal = ({
  open,
  onClose,
  id,
}: {
  open: boolean
  onClose: () => void
  id: number
}) => {
  const { fetchPendingSolicitations, resetFilter } = usePendingSolicitation()

  const { snackbar } = useSnackbar()
  const [{ saving }, { save }] = useCroods({
    id,
    name: 'solicitations',
    stateId: `reject-${id}`,
    customPath: '/workflow/solicitations/:id/reject',
    afterSuccess: () => {
      snackbar({
        message: 'Solicitação reprovada com sucesso!',
        type: 'success',
      })
      fetchPendingSolicitations()
      resetFilter()
    },
    afterFailure: () => {
      snackbar({
        message: 'Houve um erro ao reprovar a solicitação',
        type: 'error',
      })
    },
  })

  return (
    <Modal.FormWrapper
      open={open}
      schema={z.object({ justification: z.string() })}
      loading={saving}
      onClose={onClose}
      onConfirm={({ justification }) => save({})({ justification })}
    >
      <Modal.Header>Atenção</Modal.Header>
      <Modal.Content>
        <Input
          label="Em caso de reprovação, é recomendado adicionar uma justificativa."
          name="justification"
          placeholder="Ex.: Falta de dados para comprovação do pedido."
        />
      </Modal.Content>
      <Modal.Actions cancelLabel="voltar">
        <Modal.Confirm kind="danger" variant="contained" title="reprovar">
          Reprovar
        </Modal.Confirm>
      </Modal.Actions>
    </Modal.FormWrapper>
  )
}

export default RejectModal
