import Avatar from 'shared/ui/Avatar'
import { getUserIdentifier } from 'shared/helpers'

import type { Member, User } from 'types'

type Props = {
  id: Member['id']
  user: Pick<
    User,
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'avatar'
    | 'role'
    | 'leader'
    | 'hasCredential'
    | 'recentlyAdded'
  >
  selected: boolean
  setSelectedMemberId: (id: Member['id']) => void
}

const MemberEvaluationInfo = ({
  id,
  user,
  selected,
  setSelectedMemberId,
}: Props) => (
  <div
    key={id}
    className={`${
      selected ? ' border-blue-300 bg-blue-50' : 'border-transparent'
    } cursor-pointer rounded-xl border-2 p-2 hover:border-blue-200`}
    onClick={() => setSelectedMemberId(id)}
  >
    <div className="flex w-max cursor-pointer items-center break-words">
      <div className="mr-4">
        <Avatar user={user} />
      </div>

      <div className="flex max-w-[200px] flex-col justify-center">
        <h5 className="subtitle2 whitespace-nowrap">
          {getUserIdentifier({ user })}
        </h5>
        {user.role && (
          <p className="body2 whitespace-nowrap text-black/50">{user.role}</p>
        )}
      </div>
    </div>
  </div>
)

export default MemberEvaluationInfo
