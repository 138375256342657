import React from 'react'
import get from 'lodash/get'

import useOrganization from 'shared/organization/useOrganization'
import CustomTooltip from 'talks/conversations/CustomTooltip'
import DateInput from 'shared/forms/DateInput'
import RichText from 'shared/forms/RichText'
import SubmitButton from 'shared/forms/SubmitButton'
import FormError from 'shared/forms/FormError'
import { presence, richTextPresence, date } from 'shared/forms/validations'
import type { FormObject } from 'types'
import EditDialog from './ConversationEditDialog'

type Label = {
  date: string
  comment: string
}
const defaultLabels: Label = {
  date: 'Data',
  comment: 'Memórias da conversa',
}

type Helper = {
  comment: string
}
const defaultHelpers: Helper = {
  comment: 'Descreva como foi a conversa.',
}

type Props<T> = {
  fields: FormObject<T>['fields']
  disabled?: boolean
  saving: boolean
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  saveError: any
  afterFields?: React.ReactNode
  betweenFields?: React.ReactNode
  template: any
  withDate?: boolean
  labelOptions?: Label
  labels?: Label
  helperOptions?: Helper
  helpers?: Helper
  isEditMode?: boolean
  withAdmissionDate?: boolean
  children?: React.ReactNode
}

const Form = <T,>({
  fields,
  disabled,
  saving,
  onSubmit,
  saveError,
  afterFields,
  betweenFields,
  template,
  withDate = true,
  labelOptions = defaultLabels,
  labels = { ...defaultLabels, ...labelOptions },
  helperOptions = defaultHelpers,
  helpers = { ...defaultHelpers, ...helperOptions },
  isEditMode = false,
  withAdmissionDate = false,
  children = []
}: Props<T>) => {
  const organization = useOrganization()

  return (
    <form
      aria-label="form"
      onSubmit={onSubmit}
      className="mt-10 flex flex-col p-0"
    >
      {withDate && (
        <DateInput
          label={labels.date}
          labelClassName="text-base text-gray-darkest"
          minDate={organization.evaluationWindowOpensAt}
          maxDate={organization.evaluationWindowClosesAt}
          {...fields('raw', 'date', [presence(), date()])}
        />
      )}

      {betweenFields ?? null}

      <div className="relative">
        <CustomTooltip
          className="absolute right-0 -top-1"
          text={get(template, 'infoComment')}
        />
        <RichText
          {...fields('raw', 'comment', [richTextPresence()])}
          rows={8}
          label={labels.comment}
          name="comment"
          helper={helpers.comment}
        />
      </div>

      {afterFields ?? null}

      {withAdmissionDate && (
        <>
          {/* @ts-ignore */}
          <DateInput
            label="Data de admissão"
            labelClassName="text-base text-gray-darkest"
            {...fields('raw', 'admissionDate', [presence(), date()])}
          />
        </>
      )}

      {children}
      <FormError>{saveError}</FormError>

      <div className="mt-8 flex items-center justify-end gap-4">
        <SubmitButton
          className="order-last"
          disabled={disabled}
          loading={saving}
        >
          Salvar e enviar
        </SubmitButton>

        {isEditMode && <EditDialog />}
      </div>
    </form>
  )
}

export default Form
