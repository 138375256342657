import React, { useEffect, useState } from 'react'
import { useCurrentUser } from 'croods-auth'
import find from 'lodash/find'

import HeaderControls from 'shared/ui/HeaderControls'
import { useSearch } from 'shared/utils/hooks'
import type { optionProps, optionTitles } from './types'
import ConversationHistoryList from './ConversationsHistoryList'

const OPTIONS = {
  LIDERES: 'Meus líderes',
  LIDERADOS: 'Meus liderados',
}

const options: optionProps[] = [
  {
    title: 'Meus liderados',
    path: '/conversations/history_with_members',
  },
  {
    title: 'Meus líderes',
    path: '/conversations/history_with_leaders',
  },
]

const ConversationHistory = () => {
  const [currentUser] = useCurrentUser()
  const hierarchyPosition = currentUser?.currentUser?.hierarchyPosition
  const defaultOption = hierarchyPosition === 'leader' ? options[0] : options[1]
  const [fetchPath, setFetchPath] = useState(defaultOption.path)
  const [active, setActive] = useState<optionTitles>(defaultOption.title)

  const { setSearch, searchTerm } = useSearch()
  useEffect(() => {
    const activeOption = find(options, { title: active })
    if (activeOption) setFetchPath(activeOption.path)
  }, [active])

  return (
    <>
      <h3>Histórico de Conversas</h3>
      <HeaderControls
        filters={OPTIONS}
        filter={active}
        setFilter={setActive}
        searchLabel="Buscar"
        setSearch={setSearch}
      >
        <div></div>
      </HeaderControls>
      <ConversationHistoryList
        active={active}
        fetchPath={fetchPath}
        currentUser={currentUser}
        searchTerm={searchTerm}
      />
    </>
  )
}

export default ConversationHistory
