import { cx } from 'shared/helpers'
import Tr from './tr'

type Props = JSX.IntrinsicElements['thead'] & {
  propsTr?: JSX.IntrinsicElements['tr']
}

const Theader = ({ children, className, propsTr }: Props) => {
  return (
    <thead
      className={cx(
        'hidden bg-gray-lightest text-base font-bold md:table-header-group',
        className,
      )}
    >
      <Tr className="text-left" {...propsTr}>
        {children}
      </Tr>
    </thead>
  )
}

export default Theader
