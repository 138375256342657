import { RouterProvider, createBrowserRouter, json } from 'react-router-dom'
import type { LoaderFunctionArgs, RouteObject } from 'react-router-dom'

import { LegacyRoute } from 'legacy-route'
import protectRoutes from 'shared/auth/protectAdminRoutes'

import * as root from './root'

import auth from 'legacy-routes/auth'
import vibe from 'legacy-routes/vibe'
import talks from 'legacy-routes/talks'
import performance from 'legacy-routes/performance'
import portal from 'legacy-routes/portal'
import settings from 'legacy-routes/settings'
import EditProfile from 'shared/account/EditProfile'
import NotFound from 'shared/ui/not-found'
import { getUserEnv } from 'domain/auth'
import { createContext, useEffect, useState } from 'react'

async function legacyLoader({ request }: LoaderFunctionArgs) {
  const environment = await getUserEnv(request)
  return json(environment)
}

const routes: RouteObject[] = [
  {
    path: '/',
    ...root,
    children: [
      { path: '*', lazy: () => import('./not-found') },
      { path: '/', lazy: () => import('routes/_index') },
      { path: 'login', lazy: () => import('routes/login') },
      { path: 'logout', lazy: () => import('routes/logout') },
      {
        path: 'users/buscar-usuarios-com-credencial',
        lazy: () => import('routes/users.buscar-usuarios-com-credencial'),
      },
      {
        path: 'users/buscar-outros-usuarios-com-credencial',
        lazy: () =>
          import('routes/users.buscar-outros-usuarios-com-credencial'),
      },
      {
        path: 'login/google/:token',
        lazy: () => import('routes/login.google.$token'),
      },
      {
        path: 'aceitar-convite/:invitationToken',
        lazy: () => import('routes/aceitar-convite.$invitationToken'),
      },
      {
        path: 'aceitar-convite/:invitationToken/google/:googleToken',
        lazy: () =>
          import('routes/aceitar-convite.$invitationToken.google.$googleToken'),
      },
      { path: 'termos', lazy: () => import('routes/termos') },
      { path: 'privacidade', lazy: () => import('routes/privacidade') },
      {
        path: '/circulos/buscar',
        lazy: () => import('routes/circulos.buscar'),
      },
      {
        path: '/circulos/:circleId/buscar-colaboradores',
        lazy: () => import('routes/circulos.$circleId.buscar-colaboradores'),
      },
      {
        path: '/circulos/:circleId/editar-colaboradores',
        lazy: () => import('routes/circulos.$circleId.editar-colaboradores'),
        children: [
          {
            path: ':userId/adicionar',
            lazy: () =>
              import(
                'routes/circulos.$circleId.editar-colaboradores.$userId.adicionar'
              ),
          },
          {
            path: ':userId/remover',
            lazy: () =>
              import(
                'routes/circulos.$circleId.editar-colaboradores.$userId.remover'
              ),
          },
        ],
      },
      {
        path: '/circulos/:circleId',
        lazy: () => import('routes/circulos.$circleId'),
        children: [
          {
            path: 'convidar',
            lazy: () => import('routes/circulos.$circleId.convidar'),
          },
          {
            path: 'editar-lider',
            lazy: () => import('routes/circulos.$circleId.editar-lider'),
            children: [
              {
                path: ':userId/detalhe',
                lazy: () =>
                  import(
                    'routes/circulos.$circleId.editar-lider.$userId.detalhe'
                  ),
              },
            ],
          },
          {
            path: 'editar-circulo-acima',
            lazy: () =>
              import('routes/circulos.$circleId.editar-circulo-acima'),
          },
          {
            path: 'remover-circulo-acima',
            lazy: () =>
              import('routes/circulos.$circleId.remover-circulo-acima'),
          },
        ],
      },
      {
        path: '/circulos/:circleId/editar',
        lazy: () => import('routes/circulos.$circleId.editar'),
        children: [
          {
            path: 'inativar',
            lazy: () => import('routes/circulos.$circleId.editar.inativar'),
          },
          {
            path: 'reativar',
            lazy: () => import('routes/circulos.$circleId.editar.reativar'),
          },
        ],
      },
      {
        path: '/circulos/:circleId/editar-colaboradores',
        lazy: () => import('routes/circulos.$circleId.editar-colaboradores'),
        children: [
          {
            path: ':userId',
            lazy: () =>
              import('routes/circulos.$circleId.editar-colaboradores.$userId'),
          },
        ],
      },
      {
        path: '/conversas/meus-feedbacks',
        lazy: () => import('routes/conversas.meus-feedbacks'),
        children: [
          {
            index: true,
            lazy: () => import('routes/conversas.meus-feedbacks._index'),
          },
          {
            path: 'recebidos',
            lazy: () => import('routes/conversas.meus-feedbacks.recebidos'),
            children: [
              {
                path: 'melhoria/:improvementFeedbackId',
                lazy: () =>
                  import(
                    'routes/conversas.meus-feedbacks.melhoria.$improvementFeedbackId'
                  ),
              },
              {
                path: 'elogio/:complimentFeedbackId',
                lazy: () =>
                  import(
                    'routes/conversas.meus-feedbacks.elogio.$complimentFeedbackId'
                  ),
              },
            ],
          },
          {
            path: 'enviados',
            lazy: () => import('routes/conversas.meus-feedbacks.enviados'),
            children: [
              {
                path: 'melhoria/:improvementFeedbackId',
                lazy: () =>
                  import(
                    'routes/conversas.meus-feedbacks.melhoria.$improvementFeedbackId'
                  ),
              },
              {
                path: 'elogio/:complimentFeedbackId',
                lazy: () =>
                  import(
                    'routes/conversas.meus-feedbacks.elogio.$complimentFeedbackId'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'conversas/dar-feedback',
        lazy: () => import('routes/conversas.dar-feedback._index'),
      },
      {
        path: 'conversas/dar-feedback/:userId',
        lazy: () => import('routes/conversas.dar-feedback.$userId'),
      },
      {
        path: 'conversas/dar-feedback/:userId/elogio',
        lazy: () => import('routes/conversas.dar-feedback.$userId.elogio'),
      },
      {
        path: 'conversas/dar-feedback/:userId/melhoria',
        lazy: () => import('routes/conversas.dar-feedback.$userId.melhoria'),
      },
      {
        path: 'conversas/dar-feedback/melhoria/:intentId',
        lazy: () => import('routes/conversas.dar-feedback.melhoria.$intentId'),
        children: [
          {
            path: 'sugerir-competencias',
            lazy: () =>
              import(
                'routes/conversas.dar-feedback.melhoria.$intentId.sugerir-competencias'
              ),
          },
        ],
      },
      {
        path: 'conversas/dar-feedback/melhoria/:intentId/ai-trabalhando',
        lazy: () =>
          import(
            'routes/conversas.dar-feedback.melhoria.$intentId.ai-trabalhando'
          ),
      },
      {
        path: 'conversas/dar-feedback/melhoria/:intentId/edit',
        lazy: () =>
          import('routes/conversas.dar-feedback.melhoria.$intentId.edit'),
      },
      {
        path: 'conversas/dar-feedback/elogio/:intentId',
        lazy: () => import('routes/conversas.dar-feedback.elogio.$intentId'),
      },
      {
        path: 'conversas/dar-feedback/elogio/:intentId/ai-trabalhando',
        lazy: () =>
          import(
            'routes/conversas.dar-feedback.elogio.$intentId.ai-trabalhando'
          ),
      },
      {
        path: 'conversas/dar-feedback/elogio/:intentId/edit',
        lazy: () =>
          import('routes/conversas.dar-feedback.elogio.$intentId.edit'),
      },
      {
        path: 'portal/solicitacoes-recebidas',
        lazy: () => import('routes/portal.solicitacoes-recebidas'),
        children: [
          {
            index: true,
            lazy: () => import('routes/portal.solicitacoes-recebidas._index'),
          },
          {
            path: 'aprovacoes',
            lazy: () =>
              import('routes/portal.solicitacoes-recebidas.aprovacoes'),
            children: [
              {
                path: ':solicitationId',
                lazy: () =>
                  import(
                    'routes/portal.solicitacoes-recebidas.aprovacoes.$solicitationId'
                  ),
              },
            ],
          },
          {
            path: 'execucoes',
            lazy: () =>
              import('routes/portal.solicitacoes-recebidas.execucoes'),
            children: [
              {
                path: ':solicitationId',
                lazy: () =>
                  import(
                    'routes/portal.solicitacoes-recebidas.execucoes.$solicitationId'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'portal/solicitacoes-enviadas',
        lazy: () => import('routes/portal.solicitacoes-enviadas'),
        children: [
          {
            index: true,
            lazy: () => import('routes/portal.solicitacoes-enviadas._index'),
          },
          {
            path: 'em-meu-nome',
            lazy: () =>
              import('routes/portal.solicitacoes-enviadas.em-meu-nome'),
            children: [
              {
                path: ':solicitationId',
                lazy: () =>
                  import(
                    'routes/portal.solicitacoes-enviadas.em-meu-nome.$solicitationId'
                  ),
              },
            ],
          },
          {
            path: 'em-nome-de-outros',
            lazy: () =>
              import('routes/portal.solicitacoes-enviadas.em-nome-de-outros'),
            children: [
              {
                path: ':solicitationId',
                lazy: () =>
                  import(
                    'routes/portal.solicitacoes-enviadas.em-nome-de-outros.$solicitationId'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'portal/acerto-do-ponto',
        lazy: () => import('routes/portal.acerto-do-ponto'),
        children: [
          {
            index: true,
            lazy: () => import('routes/portal.acerto-do-ponto._index'),
          },
          {
            path: ':clockId',
            children: [
              {
                index: true,
                lazy: () =>
                  import('routes/portal.acerto-do-ponto.$clockId._index'),
              },
              {
                path: ':filter',
                lazy: () =>
                  import('routes/portal.acerto-do-ponto.$clockId.$filter'),
                children: [
                  {
                    path: 'data/:masterId',
                    lazy: () =>
                      import(
                        'routes/portal.acerto-do-ponto.$clockId.$filter.data.$masterId'
                      ),
                    children: [
                      {
                        path: ':solicitationId/cancelar',
                        lazy: () =>
                          import(
                            'routes/portal.acerto-do-ponto.$clockId.$filter.data.$masterId.$solicitationId.cancelar'
                          ),
                      },
                    ],
                  },
                  {
                    path: 'confirmar/:masterId',
                    lazy: () =>
                      import(
                        'routes/portal.acerto-do-ponto.$clockId.$filter.confirmar.$masterId'
                      ),
                  },
                  {
                    path: 'desfazer/:masterId',
                    lazy: () =>
                      import(
                        'routes/portal.acerto-do-ponto.$clockId.$filter.desfazer.$masterId'
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'portal/assinatura-eletronica',
        lazy: () => import('routes/portal.assinatura-eletronica'),
        children: [
          {
            index: true,
            lazy: () => import('routes/portal.assinatura-eletronica._index'),
          },
          {
            path: 'pendentes',
            lazy: () => import('routes/portal.assinatura-eletronica.pendentes'),
            children: [
              {
                path: ':signatureDocumentId',
                lazy: () =>
                  import(
                    'routes/portal.assinatura-eletronica.$signatureDocumentId'
                  ),
              },
            ],
          },
          {
            path: 'aguardando',
            lazy: () =>
              import('routes/portal.assinatura-eletronica.aguardando'),
            children: [
              {
                path: ':signatureDocumentId',
                lazy: () =>
                  import(
                    'routes/portal.assinatura-eletronica.$signatureDocumentId'
                  ),
              },
            ],
          },
          {
            path: 'concluidos',
            lazy: () =>
              import('routes/portal.assinatura-eletronica.concluidos'),
            children: [
              {
                path: ':signatureDocumentId',
                lazy: () =>
                  import(
                    'routes/portal.assinatura-eletronica.$signatureDocumentId'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'configuracoes/gestao-de-solicitacoes',
        lazy: () => import('routes/configuracoes.gestao-de-solicitacoes'),
        children: [
          {
            path: ':solicitationId',
            lazy: () =>
              import(
                'routes/configuracoes.gestao-de-solicitacoes.$solicitationId'
              ),
          },
          {
            path: 'cancelar-solicitacoes',
            lazy: () =>
              import(
                'routes/configuracoes.gestao-de-solicitacoes.cancelar-solicitacoes'
              ),
          },
        ],
      },
      {
        path: 'configuracoes/valores-organizacionais',
        lazy: () => import('routes/configuracoes.valores-organizacionais'),
        children: [
          {
            path: 'novo',
            lazy: () =>
              import('routes/configuracoes.valores-organizacionais.novo'),
          },
          {
            path: ':valueId/editar',
            lazy: () =>
              import(
                'routes/configuracoes.valores-organizacionais.$valueId.editar'
              ),
          },
          {
            path: ':valueId/remover',
            lazy: () =>
              import(
                'routes/configuracoes.valores-organizacionais.$valueId.remover'
              ),
          },
        ],
      },
      ...auth.map((route) => ({
        handle: { title: route.pageTitle, layout: 'minimal' },
        path: route.path,
        element: (
          <LegacyRoute
            key={route.path}
            {...protectRoutes(route)}
            component={route.component || NotFound}
            path={route.path}
            pageTitle={route.pageTitle}
            requireAuthorization={false}
          />
        ),
      })),
      ...vibe.map((route) => ({
        handle: {
          title: route.pageTitle,
          breadcrumbs: route.breadcrumbs,
          category: 'vibe',
        },
        path: route.path,
        loader: legacyLoader,
        element: (
          <LegacyRoute
            key={route.path}
            {...protectRoutes(route)}
            component={route.component || NotFound}
            path={route.path}
            pageTitle={route.pageTitle}
            requireAuthorization
          />
        ),
      })),
      ...talks.map((route) => ({
        handle: {
          title: route.pageTitle,
          breadcrumbs: route.breadcrumbs,
          category: 'talks',
        },
        path: route.path,
        loader: legacyLoader,
        element: (
          <LegacyRoute
            key={route.path}
            {...protectRoutes(route)}
            component={route.component || NotFound}
            path={route.path}
            pageTitle={route.pageTitle}
            requireAuthorization
          />
        ),
      })),
      ...performance.map((route) => ({
        handle: {
          title: route.pageTitle,
          breadcrumbs: route.breadcrumbs,
          category: 'performance',
        },
        path: route.path,
        loader: legacyLoader,
        element: (
          <LegacyRoute
            key={route.path}
            {...protectRoutes(route)}
            component={route.component || NotFound}
            path={route.path}
            pageTitle={route.pageTitle}
            requireAuthorization
          />
        ),
      })),
      ...portal.map((route) => ({
        handle: {
          title: route.pageTitle,
          breadcrumbs: route.breadcrumbs,
          category: 'portal',
        },
        path: route.path,
        loader: legacyLoader,
        element: (
          <LegacyRoute
            key={route.path}
            {...protectRoutes(route)}
            component={route.component || NotFound}
            path={route.path}
            pageTitle={route.pageTitle}
            requireAuthorization
          />
        ),
      })),
      ...settings.map((route) => ({
        handle: {
          title: route.pageTitle,
          breadcrumbs: route.breadcrumbs,
          category: 'settings',
        },
        path: route.path,
        loader: legacyLoader,
        element: (
          <LegacyRoute
            key={route.path}
            {...protectRoutes(route)}
            component={route.component || NotFound}
            path={route.path}
            pageTitle={route.pageTitle}
            requireAuthorization
          />
        ),
      })),
      {
        handle: {
          title: 'Editar Perfil',
          breadcrumbs: [],
          category: 'vibe',
        },
        path: '/editar-perfil',
        loader: legacyLoader,
        element: (
          <LegacyRoute
            key="/editar-perfil"
            component={EditProfile}
            path="/editar-perfil"
            pageTitle="Editar Perfil"
            requireAuthorization
          />
        ),
        children: [
          {
            path: 'alterar-login-para-google',
            lazy: () =>
              import('routes/editar-perfil.alterar-login-para-google'),
          },
          {
            path: 'alterar-login-para-senha',
            lazy: () => import('routes/editar-perfil.alterar-login-para-senha'),
          },
          {
            path: 'criar-senha/:migrationToken',
            lazy: () =>
              import('routes/editar-perfil.criar-senha.$migrationToken'),
          },
        ],
      },
    ],
  },
]

const router = createBrowserRouter(routes, {
  future: { v7_normalizeFormMethod: true },
})

type AppRouterState = {
  preventScrollReset: boolean
  navigationState: 'idle' | 'loading' | 'submitting'
}

const initialAppRouterState: AppRouterState = {
  preventScrollReset: false,
  navigationState: 'idle',
}

const AppRouterStateContext = createContext(initialAppRouterState)

const App = () => {
  const [routerState, setRouterState] = useState(initialAppRouterState)

  useEffect(() => {
    router.subscribe((state) => {
      setRouterState({
        preventScrollReset: state.preventScrollReset,
        navigationState: state.navigation.state,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppRouterStateContext.Provider value={routerState}>
      <RouterProvider router={router} />
    </AppRouterStateContext.Provider>
  )
}

export { App, routes, AppRouterStateContext }
