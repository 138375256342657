import indexOf from 'lodash/indexOf'

import Section from 'shared/ui/section'

import CircleForm from './forms/circle-form'
import RelationsForm from './forms/relations-form'
import CollaboratorsForm from './forms/employees-form'
import StatusChip from 'vibe/circle/status-chip'

import Stepper from './Stepper'
import useFormSteps, {
  STEPS,
  CIRCLE_STEP,
  RELATIONS_STEP,
  COLLABORATORS_STEP,
} from './useFormSteps'
import { useRestoreScrollOnChange } from 'shared/utils/hooks'

const New = ({ circle, toggleActive, setEditedMembers }: any) => {
  const { step, values, id, saving, saveError, handleNext, handleNavigate } =
    useFormSteps(circle?.id)
  useRestoreScrollOnChange(step)

  const title = circle ? 'Editar Círculo' : 'Criar Círculo'

  return (
    <Section
      title={
        <>
          {title}
          {circle?.status === 'inactive' && (
            <StatusChip label="círculo inativo" />
          )}
        </>
      }
      action={toggleActive}
    >
      <div className="md:max-w-4xl w-full px-1 mx-auto sm:px-6">
        <Stepper
          steps={['Círculo', 'Organograma', 'Colaboradores / Configurações']}
          activeStep={indexOf(STEPS, step)}
          handleNavigate={handleNavigate}
        />

        <CircleForm
          submitting={saving}
          error={saveError}
          handleSubmit={handleNext}
          visible={step === CIRCLE_STEP}
          filledValues={circle}
          handleNavigate={`/circulos/${circle?.id ? circle?.id : ''}`}
        />

        <RelationsForm
          submitting={saving}
          error={saveError}
          values={values}
          createdTeamId={id}
          handleSubmit={handleNext}
          visible={step === RELATIONS_STEP}
          filledValues={circle}
          handleNavigate={handleNavigate}
        />

        <CollaboratorsForm
          submitting={saving}
          error={saveError}
          values={values}
          createdTeamId={id}
          handleSubmit={handleNext}
          visible={step === COLLABORATORS_STEP}
          filledValues={circle}
          handleNavigate={handleNavigate}
          fetchedCollaborators={circle?.members}
          saving={saving}
          setEditedMembers={setEditedMembers}
        />
      </div>
    </Section>
  )
}

export default New
