import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { alpha } from '@material-ui/core/styles'
import { DropZone } from 'uploods'

import Icon from 'shared/ui/icon'
import { colors } from 'theme'

const DocumentsInput = ({ onChange, value }: any) => {
  const handleChange = (documents: { name: string; parsed?: any }[]) => {
    const existingBlobs = value.map((document: { blob: any }) => document.blob)
    const newDocuments = documents
      .map((document) => ({ filename: document.name, blob: document.parsed }))
      .filter((document) => existingBlobs.indexOf(document.blob) === -1)

    onChange([...value, ...newDocuments])
  }

  return (
    <div className="override-dropzone">
      <Typography
        title="Modelo de documento"
        variant="h5"
        color="textPrimary"
        component="h5"
      >
        Modelo de documento{' '}
        <span className="text-sm font-bold text-gray-darker">(Opcional)</span>
      </Typography>
      <DropZone
        unsupportedText="Arquivo não suportado"
        dragActiveText="Solte o arquivo aqui"
        text={
          (
            <Button
              title="Adicionar anexo"
              color="primary"
              variant="contained"
              size="small"
              startIcon={<Icon icon="attach-file" className="h-5 w-5" />}
            >
              Adicionar anexo
            </Button>
          ) as any
        }
        paperProps={{
          elevation: 0,
        }}
        containerStyle={{
          padding: 0,
          marginTop: 2,
        }}
        inputStyle={{
          height: 96,
          border: `1px dashed ${alpha(colors.blue, 0.7)}`,
        }}
        onChange={handleChange}
        config={{ autoUpload: false }}
        multiple
      />
      <p className="form-helper-text">
        Adicione arquivos que os solicitantes devem baixar.
      </p>
    </div>
  )
}

export default DocumentsInput
