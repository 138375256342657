import { Bar } from 'recharts'
import { colors } from 'theme'
import Chart from 'vibe/insights/chart'

type Data = {
  baseValue: number
  activeValue: number
  idealValue: number
}

type Props = { data: Data[] }

export default function ConversationFrequencyChart({ data: rawData }: Props) {
  const data = rawData.map((values) => {
    const { activeValue, baseValue } = values
    const color = activeValue === baseValue ? colors.green : colors.red
    return { ...values, color }
  })

  return (
    <Chart data={data}>
      <Chart.Header tooltip="São contabilizados colaboradores que, durante todo o ciclo, tenham participado de ao menos uma conversa mensal com seu líder, permitindo uma ausência. As conversas válidas são Onboarding, Efetivação, 1:1 e Carreira, desconsiderando as de Comportamento.">
        Frequência ideal de conversas
      </Chart.Header>
      <Chart.Bars
        tooltip={({ activeValue, baseValue, idealValue, color }) => (
          <>
            <div>Total de colaboradores no ciclo completo: {baseValue}</div>
            <div style={{ color }}>
              {`Com a frequência ideal (${idealValue} ${
                idealValue === 1 ? 'conversa' : 'conversas'
              }): ${activeValue}`}
            </div>
          </>
        )}
      >
        <>
          <Bar dataKey="baseValue" shape={Chart.BaseShape} stackId="a" />
          <Bar dataKey="activeValue" shape={Chart.ActiveShape} stackId="a" />
        </>
      </Chart.Bars>
    </Chart>
  )
}
