import Modal from 'shared/ui/Modal'

type PropsConfirmationModal = {
  show: boolean
  onClose: () => void
  handleSave: () => void
  children: React.ReactNode
}

function ConfirmationModal({
  show,
  onClose,
  handleSave,
  children,
}: PropsConfirmationModal) {
  return (
    <Modal.Wrapper open={show} onClose={onClose}>
      <Modal.Header>
        <div className="flex items-start">
          <h5 className="mx-2">
            Deseja prosseguir com a geração da chave de API?
          </h5>
        </div>
      </Modal.Header>
      <Modal.Content className="mx-2 gap-6 md:min-w-[600px]">
        {children}
      </Modal.Content>
      <Modal.Actions cancelLabel="CANCELAR">
        <Modal.Confirm color="primary" onClick={handleSave}>
          GERAR CHAVE DE API
        </Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export { ConfirmationModal }
