import Modal from 'shared/ui/Modal'

const RenderedQuestion = ({ title, icon, children }: any) => (
  <>
    <Modal.Header>
      <div className="flex items-center gap-x-4">
        <div className="border-r-2 border-gray-400 pr-4 text-gray-500">
          {icon}
        </div>
        <h5>{title}</h5>
      </div>
    </Modal.Header>
    {children}
  </>
)

export default RenderedQuestion
