import * as React from 'react'
import { useCroods } from 'croods'
import { useFormContext } from 'react-hook-form'

import Icon from 'shared/ui/icon'
import Modal from 'shared/ui/Modal'
import Avatar from 'shared/ui/Avatar'
import Input from 'shared/formsv2/input'
import { getFullName } from 'shared/helpers'
import Button from 'shared/forms/SubmitButton'
import DateInput from 'shared/formsv2/date-input'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'

import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import isFuture from 'date-fns/isFuture'
import isValid from 'date-fns/isValid'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'

import {
  formatNumberOfDays,
  FormContextWarnings,
  periodSchema,
  schema,
  vacationDaysSoldSchema,
} from 'portal/vacationsHelpers'

import type { EventInput } from '@fullcalendar/react'
import type {
  CollaboratorVacations,
  CollaboratorType,
  VacationEvent,
} from './types'

type Props = {
  data: CollaboratorType
  isOpen: boolean
  onClose: () => void
  setCollaboratorVacationsState: (
    collaboratorVacations: CollaboratorVacations,
  ) => void
  vacations: VacationEvent[]
}

const ModalContent = ({
  data,
  vacations,
}: {
  data: Props['data']
  vacations: Props['vacations']
}) => {
  const { watch } = useFormContext()
  const formData = watch()

  const events: EventInput[] = []

  vacations.map((vacation) => {
    events.push({
      resourceId: String(vacation.userId),
      id: String(vacation.id),
      extendedProps: {
        startDate: vacation.startDate,
        endDate: vacation.endDate,
      },
    })
  })

  const remainingBalance =
    data.openingBalance -
    Number(formData.vacationDays ?? 0) -
    Number(formData.vacationDaysSold ?? 0)

  const parsedStartDate = formData.startDate
    ? parse(formData.startDate, 'dd/MM/yyyy', new Date())
    : new Date()

  const startDate = isValid(parsedStartDate) ? parsedStartDate : new Date()

  const lastVacationDay = addDays(
    startDate,
    Number(formData.vacationDays) > 0 ? Number(formData.vacationDays) - 1 : 0,
  )

  const dateLimit = data.dateLimit ? parseISO(data.dateLimit) : ''

  return (
    <Modal.Content className="max-h-[60vh] !w-full overflow-y-auto !bg-white !bg-opacity-95 text-gray-darkest">
      <div className="mb-4 flex items-start gap-4">
        <Avatar user={data} size={48} />
        <div className="flex flex-1 flex-col gap-0.5">
          <h3 aria-label="user-name" className="text-xl">
            {getFullName(data)}
          </h3>
          {data.role && (
            <span className="text-base uppercase">{data.role}</span>
          )}
        </div>
      </div>

      <section aria-label="event-data" className="flex flex-col gap-7">
        <div>
          <label className="text-lg font-bold">Saldo inicial:</label>
          <span className="ml-3 mt-3 h-6 w-fit rounded-2xl bg-gray-lightest px-2 py-1 text-center text-base">
            {formatNumberOfDays(data.openingBalance)}
          </span>
          {dateLimit ? (
            <span className="ml-3 text-base">
              {isFuture(dateLimit) ? 'Vence em:' : 'Venceu em:'}{' '}
              {format(dateLimit, 'dd/MM/yyyy')}
            </span>
          ) : null}
        </div>
        <div className="flex flex-1 flex-col items-start gap-4 sm:flex-row">
          <DateInput
            name="startDate"
            label="Primeiro dia de férias"
            required
            wrapperClassname="!flex-1"
            defaultValue=""
          />
          <div className="flex w-20 flex-1 flex-col">
            <Input
              name="vacationDays"
              label="Dias de férias"
              required
              type="number"
              min="1"
              defaultValue={data.vacationDays}
            />
          </div>
          <div className="flex flex-1 flex-col">
            <label className="text-lg font-bold">Último dia de férias</label>
            <p className="mt-3 h-6 w-fit rounded-2xl bg-gray-lightest p-1 px-2 text-center text-base">
              {format(lastVacationDay, 'dd/MM/yyyy')}
            </p>
          </div>
        </div>

        <FormContextWarnings
          schema={periodSchema(remainingBalance, data, events)}
        />

        <div>
          <label className="text-lg font-bold">Saldo restante:</label>
          <span className="ml-3 mt-3 h-6 w-fit rounded-2xl bg-gray-lightest px-2 py-1 text-center text-base">
            {formatNumberOfDays(remainingBalance)}
          </span>
        </div>
        <div className="flex flex-col">
          <Input
            className="mt-1 w-20"
            label="Dias de abono de férias"
            name="vacationDaysSold"
            type="number"
            min="0"
            required
            defaultValue={data.vacationDaysSold}
          />
        </div>

        <FormContextWarnings schema={vacationDaysSoldSchema()} />

        <div className="flex items-center gap-3">
          <Input
            type="checkbox"
            name="advanceThirteenth"
            defaultChecked={data.advanceThirteenth}
          />
          <label htmlFor="advanceThirteenth" className="label">
            Antecipar 13º salário
          </label>
        </div>
        {data.confirmationError ? (
          <div className="flex max-w-xl items-center gap-3 rounded-2xl bg-red-lightest px-4 py-1 text-base font-bold text-red-darkest">
            <div className="flex h-9 w-9 items-center">
              <Icon icon="info" className="h-full w-full text-red" />
            </div>
            <span>{data.confirmationError}</span>
          </div>
        ) : null}
      </section>
    </Modal.Content>
  )
}

const VacationRequestModal = ({
  data,
  isOpen,
  onClose,
  setCollaboratorVacationsState,
  vacations,
}: Props) => {
  const { snackbar } = useSnackbar()

  const [{ saving }, { save }] = useCroods({
    id: data.vacationId,
    name: 'vacationSolicitation',
    path: '/workflow/vacations',
    afterSuccess: ({ data }: { data: CollaboratorVacations[] }) => {
      setCollaboratorVacationsState(data[0])
      snackbar({ message: 'Solicitação salva com sucesso', type: 'success' })
    },
    afterFailure: (error) => {
      snackbar({
        message:
          error.response?.data.id === 'taken_period'
            ? 'Não foi possível completar a solicitação pois já existe outra solicitação para esse período.'
            : 'Não foi possível salvar a solicitação, tente novamente.',
        type: 'error',
        timeout: 6,
      })
    },
  })

  const handleSave = React.useCallback(
    (dataToSave: any) => {
      const [startYear, startMonth, startDay] = dataToSave.startDate
        .split('-')
        .map(Number)
      const startDate = new Date(startYear, startMonth - 1, startDay)

      return save()({
        ...dataToSave,
        startDate: format(startDate, 'yyyy-MM-dd'),
      })
    },
    [save],
  )

  return (
    <Modal.FormWrapper
      open={isOpen}
      schema={schema}
      loading={saving}
      onClose={onClose}
      defaultValues={data}
      onConfirm={handleSave}
      className="!min-w-[90vw] md:!min-w-[46rem]"
    >
      <Modal.Header className="!bg-gray-lightest">
        Programar férias
      </Modal.Header>

      <ModalContent data={data} vacations={vacations} />

      <Modal.Actions className="gap-4" cancelLabel="voltar">
        <Button kind="primary" name="confirm" type="submit" loading={saving}>
          Criar solicitação
        </Button>
      </Modal.Actions>
    </Modal.FormWrapper>
  )
}

export { VacationRequestModal }
