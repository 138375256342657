import { cx } from 'shared/helpers'
import type { BoxContent } from '../types'

type Props = Pick<BoxContent, 'totalGroupMembers' | 'totalMembers'> & {
  className?: string
}

const Tag = ({ totalGroupMembers, totalMembers, className = '' }: Props) => (
  <div
    className={cx(
      className,
      'absolute left-[50%] bottom-0 z-10 w-full min-w-[2.5rem] translate-x-[-50%] rounded-t-lg py-0.5 px-3 text-center sm:w-auto sm:bg-gray-lightest',
    )}
  >
    <p className="font-bold tracking-tighter text-black/40">
      {totalGroupMembers} / {totalMembers}
    </p>
  </div>
)

export default Tag
