import * as z from 'zod'
import { match, P } from 'ts-pattern'
import { useFormContext } from 'react-hook-form'

import { DATE_REGEX } from 'shared/formsv2/regex'

import addDays from 'date-fns/addDays'
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping'
import endOfDay from 'date-fns/endOfDay'
import isBefore from 'date-fns/isBefore'
import isFuture from 'date-fns/isFuture'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import subDays from 'date-fns/subDays'

import type { EventInput } from '@fullcalendar/react'
import type { CollaboratorType } from './vacationManagement/types'
import Alert from 'shared/ui/alert'

const colors = {
  gray: {
    bg: 'bg-white/100',
    bgIcon: 'bg-gray-lightest',
    border: 'outline-gray',
    chipBorder: 'border-gray',
    text: 'text-gray',
    textBold: 'text-gray-dark',
    iconText: 'text-gray-dark',
  },
  green: {
    bg: 'bg-green-lightest',
    bgIcon: 'bg-green-light',
    border: 'outline-green outline-opacity-50',
    chipBorder: 'border-green-dark',
    text: 'text-green',
    textBold: 'text-green-dark',
    iconText: 'text-green-dark',
  },
  red: {
    bg: 'bg-red-lightest',
    bgIcon: 'bg-red bg-opacity-50',
    border: 'outline-red outline-opacity-50',
    chipBorder: 'border-red-dark',
    text: 'text-red',
    textBold: 'text-red-dark',
    iconText: 'text-red-darkest',
  },
  yellow: {
    bg: 'bg-yellow-lightest',
    bgIcon: 'bg-yellow-light',
    border: 'outline-yellow',
    chipBorder: 'border-yellow-dark',
    text: 'text-yellow-dark',
    textBold: 'text-yellow-dark',
    iconText: 'text-yellow-dark',
  },
}

const endDate = (start: Date, vacationDays: number) =>
  endOfDay(addDays(start, vacationDays - 1))

function formatNumberOfDays(days: number) {
  return `${days} ${Math.abs(days) === 1 ? 'dia' : 'dias'}`
}

const FormContextWarnings = ({ schema }: { schema: z.ZodTypeAny }) => (
  <div className="min-h-[1rem]">
    <FormWarnings schema={schema} formContext={useFormContext()} />
  </div>
)

const FormWarnings = ({
  schema,
  formContext,
}: {
  schema: z.ZodTypeAny
  formContext: ReturnType<typeof useFormContext>
}) => {
  const result = schema.safeParse(formContext.watch())
  const extractMessages = ({ errors }: z.ZodError) => {
    return errors.map((e) => e.message)
  }
  return result.success ? null : (
    <Warnings messages={extractMessages(result.error)} />
  )
}

function getColorByStatus(warnings: number, errors: number) {
  return match([warnings, errors])
    .with([P._, P.when((errors) => errors > 0)], () => 'red')
    .with([P.when((warnings) => warnings > 0), 0], () => 'yellow')
    .otherwise(() => 'gray') as keyof typeof colors
}

function getEventLabelMessage(
  status: string,
  warnings: number,
  errors: number,
) {
  return match([status, warnings, errors])
    .with(['confirmed', P._, P._], () => 'Férias confirmadas')
    .with(
      ['draft', P._, P.when((errors) => errors > 0)],
      () => `${errors} ${errors > 1 ? 'erros' : 'erro'}`,
    )
    .with(
      ['draft', P.when((warnings) => warnings > 0), 0],
      () => `${warnings} ${warnings > 1 ? 'avisos' : 'aviso'}`,
    )
    .otherwise(() => 'Rascunho')
}

function getScrollTime(date: string, today?: boolean) {
  const newDate = new Date()
  const isCurrentYear = date === newDate.getFullYear().toString()

  if (isCurrentYear || today) {
    const day = newDate.getDay()
    const month = newDate.getMonth()

    return { month, day }
  }
  return { month: 0, day: 0 }
}

const periodSchema = (
  remainingBalance: number,
  currentEvent: CollaboratorType,
  events: EventInput[],
) => {
  return z
    .object({
      startDate: z.preprocess(
        (arg) => {
          if (typeof arg === 'string' && arg.length === 10)
            return parse(arg, 'dd/MM/yyyy', new Date())

          if (String(arg).length >= 10) return arg
        },
        z
          .date()
          .optional()
          .refine(
            (date) =>
              date && currentEvent.admissionDate
                ? isBefore(parseISO(currentEvent.admissionDate), date)
                : true,
            {
              message:
                'A data de início das férias não pode ser menor que a data de admissão do colaborador.',
            },
          )
          .refine((date) => (date ? isFuture(endOfDay(date)) : true), {
            message: 'A data escolhida está no passado.',
          })
          .refine(
            (date) =>
              date
                ? isFuture(subDays(date, 30)) || !isFuture(endOfDay(date))
                : true,
            {
              message: 'A data escolhida está há menos de 30 dias.',
            },
          ),
      ),
      vacationDays: z.preprocess(
        Number,
        z.number().superRefine((value, ctx) => {
          const newValue = Number(value)

          if (value && newValue < 5) {
            return ctx.addIssue({
              message: 'Selecione um período de férias maior do que 04 dias.',
              code: 'custom',
            })
          }
        }),
      ),
    })
    .superRefine((_, ctx) => {
      if (remainingBalance < 0) {
        return ctx.addIssue({
          message:
            'O número de dias de férias é maior que o saldo disponível. O saldo ficará negativo.',
          code: 'custom',
        })
      }
    })
    .superRefine((value, ctx) => {
      if (value.startDate) {
        const isTaken = events
          .filter(({ resourceId }) => resourceId === currentEvent.id)
          .filter(
            ({ id }: EventInput) =>
              String(id) !== String(currentEvent.vacationId),
          )
          .find(({ extendedProps: { startDate, endDate: end } }: any) => {
            const vacationDays = value.vacationDays > 0 ? value.vacationDays : 1
            const currentEventStartDate = value.startDate!
            const currentEventEndDate = endDate(value.startDate!, vacationDays)

            return areIntervalsOverlapping(
              { start: parseISO(startDate), end: endOfDay(parseISO(end)) },
              {
                start: currentEventStartDate,
                end: currentEventEndDate,
              },
            )
          })

        if (isTaken) {
          ctx.addIssue({
            code: 'custom',
            message:
              'O período selecionado já possui férias marcadas. Selecione outro período.',
          })
        }
      }
    })
}

const schema = z
  .object({
    startDate: z.preprocess(
      (val) => (!val ? '' : val),
      z.string().nonempty('Campo obrigatório').regex(DATE_REGEX, {
        message: 'Formato de data é inválido',
      }),
    ),
    vacationDays: z.preprocess(
      (val) => (isNaN(Number(val as string)) ? 0 : Number(val as string)),
      z.number().gt(0, 'Dias de férias deve ser maior que zero'),
    ),
    vacationDaysSold: z.string().nonempty('Campo obrigatório'),
    advanceThirteenth: z.boolean().optional(),
  })
  .transform((val) => {
    const [day, month, year] = val.startDate.split('/').map(Number)
    const newVal = {
      advanceThirteenth: val.advanceThirteenth,
      startDate: `${year}-${month}-${day}`,
      vacationDays: Number(val.vacationDays),
      vacationDaysSold: Number(val.vacationDaysSold),
    }

    return newVal
  })

const vacationDaysSoldSchema = () => {
  return z
    .object({
      vacationDays: z.preprocess(Number, z.number()),
      vacationDaysSold: z.preprocess(Number, z.number()),
    })
    .superRefine((values, ctx) => {
      if (values.vacationDaysSold > values.vacationDays / 3) {
        return ctx.addIssue({
          message:
            'O número de dias de abono não deve ultrapassar 1/3 do número de dias de férias solicitado.',
          code: 'custom',
        })
      }
    })
}

const Warnings = ({ messages }: { messages: string[] }) => (
  <>
    {messages.map((warning, index) => (
      <Alert
        key={`warning-${index}`}
        className="mt-2 w-fit first:-mt-5"
        message={warning}
        severity="warning"
      />
    ))}
  </>
)

export {
  colors,
  endDate,
  formatNumberOfDays,
  FormContextWarnings,
  getColorByStatus,
  getEventLabelMessage,
  getScrollTime,
  periodSchema,
  schema,
  vacationDaysSoldSchema,
}
