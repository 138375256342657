import React from 'react'

import { getDenomination } from 'shared/helpers'
import useOrganization from 'shared/organization/useOrganization'
import Period from 'shared/ui/Period'
import Notice from './Notice'
import StatusChip from 'vibe/circle/status-chip'
import type { TeamToEvaluate } from 'types'

const TeamNoticeCard = ({ team }: { team: TeamToEvaluate }) => {
  const {
    evaluationWindowOpensAt,
    evaluationWindowClosesAt,
    evaluationWindowStatus,
  } = useOrganization()

  return (
    <div key={team.id} className="w-full card rounded-2xl">
      <div className="header-card">
        <div className="flex items-center gap-4">
          <h4>
            {team.teamName} [{getDenomination(team.teamDenomination!)}]
          </h4>
          {team?.isDraft && <StatusChip label="rascunho" />}
        </div>
      </div>

      <div className="p-6">
        <div className="flex flex-col">
          {evaluationWindowStatus !== 'closed' && (
            <Period
              title="Período para avaliar"
              startsAt={evaluationWindowOpensAt}
              finishesAt={evaluationWindowClosesAt}
              longFormat
            />
          )}
          <Notice id={team.id} />
        </div>
      </div>
    </div>
  )
}

export default TeamNoticeCard
