import { useEffect, useRef } from 'react'
import HeaderControls from 'shared/ui/HeaderControls'
import { usePagination, useSearch } from 'shared/utils/hooks'
import debounce from 'lodash/debounce'

import { useCroods } from 'croods'
import EmployeeTable from './table'
import Pagination from 'shared/ui/pagination/Pagination'
import useCroodsHeaders from 'shared/utils/useCroodsHeader'

import Skeleton from 'shared/ui/skeleton'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import { mountQueryString } from 'shared/helpers'

const Employees = ({ pageTitle }: { pageTitle: string }) => {
  const { setSearch, searchTerm } = useSearch()

  const delayedRequest = useRef(
    debounce((value) => {
      setSearch(value)
    }, 600),
  ).current

  const { headers, parseResponse } = useCroodsHeaders()

  const { currentPage, setCurrentPage, totalPages } = usePagination(
    Number(headers['per-page']),
    Number(headers['total']),
  )

  const [{ list, fetchingList }, { fetch }] = useCroods({
    name: 'users',
    path: `users/browse${mountQueryString({
      page: currentPage,
      query: searchTerm,
    })}`,
    cache: false,
    fetchOnMount: false,
    parseResponse,
  })

  useEffect(() => {
    fetch()({ page: currentPage, query: searchTerm })
  }, [currentPage, searchTerm]) // eslint-disable-line

  useEffect(() => {
    setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  return (
    <div>
      <h3>{pageTitle}</h3>
      <HeaderControls
        searchLabel="Buscar colaborador"
        setSearch={delayedRequest}
      />
      <div className="card">
        {!fetchingList ? (
          headers['per-page'] ? (
            totalPages > 0 ? (
              <>
                <EmployeeTable rows={list} />
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageClick={setCurrentPage}
                />
              </>
            ) : (
              <FeedbackMessage
                message="A consulta não retornou resultados"
                type="warning"
                serverMessage=""
              />
            )
          ) : (
            <FeedbackMessage message="Erro no servidor" serverMessage="" />
          )
        ) : (
          <Skeleton height="400px" />
        )}
      </div>
    </div>
  )
}

export default Employees
