import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { typeToGrayIcon, typeToName } from 'shared/utils/conversationLookup'

const useClasses = makeStyles(() => ({
  wrapper: {
    padding: '1.5rem 0.5rem',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    textTransform: 'uppercase',
    marginLeft: '0.25rem',
  },
}))

export default function Body({
  conversationType,
}: {
  conversationType: string
}) {
  const classes = useClasses()

  return (
    <div className={classes.wrapper}>
      {typeToGrayIcon(conversationType)}
      <Typography variant="subtitle2" className={classes.title}>
        {typeToName(conversationType)}
      </Typography>
    </div>
  )
}
