import * as React from 'react'

import { scrollTo } from 'shared/helpers'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'theme'
import { useIsMobile } from 'shared/utils/hooks'

import AvatarList from 'shared/ui/AvatarList'
import Label from './label'
import Tag from './Tag'

import boxColors from './boxColors'
import type { BoxContent } from '../types'

const useStyles = makeStyles((theme) => ({
  box: {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-16px',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 2,
      margin: 0,
      borderRadius: 'inherit',
      border: `3px solid ${colors.blue}`,
      opacity: 0,
      transition: 'opacity .15s ease',
    },
    '&:hover::before': { opacity: 1, cursor: 'pointer' },
  },
  selected: {
    '&::before': {
      opacity: 1,
    },
  },
  avatarFormatter: {
    flexWrap: 'wrap',
    padding: theme.spacing(3, 3, 2),
    height: '128px',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& > div': {
      marginLeft: '5px!important',
      marginRight: '5px',
    },
  },
}))

type BoxInterface = {
  boxContent: BoxContent
  setActiveBox: (boxName: BoxContent['name'] | null) => void
  activeBox: BoxContent['name'] | null
}

const Box = ({ boxContent, setActiveBox, activeBox }: BoxInterface) => {
  const isMobile = useIsMobile()

  const classes = useStyles()
  const color = boxColors[boxContent.name]

  const handleClick = React.useCallback(() => {
    if (activeBox === boxContent.name) {
      setActiveBox(null)
      scrollTo('unselected-scroll-target')
    } else {
      setActiveBox(boxContent.name)
    }
  }, [activeBox, boxContent.name, setActiveBox])

  return (
    <div
      data-testid={boxContent.name}
      className={`card relative h-[90px] w-full select-none !overflow-visible rounded-2xl border-t-[16px] border-white sm:h-[210px] sm:w-[300px] ${
        classes.box
      } ${activeBox === boxContent.name ? classes.selected : ''}`}
      style={{ borderTopColor: `${color}` }}
      onClick={handleClick}
    >
      <Label name={boxContent.name} withBg />
      {!isMobile ? (
        <AvatarList
          membersMax={8}
          team={boxContent}
          memberType="members"
          hasTitle={false}
          auxClass={`${classes.avatarFormatter} ${
            boxContent.members.length > 5 ? 'justify-start' : ''
          }`}
        />
      ) : null}
      <Tag
        totalGroupMembers={boxContent.totalGroupMembers}
        totalMembers={boxContent.totalMembers}
      />
    </div>
  )
}

export default Box
