import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import omit from 'lodash/omit'
import { cx } from 'shared/helpers'

import { THUMB_NAMES } from '..'
import type { SliderProps } from '../../../types'
import { styles } from './getStyle'
import Icon from 'shared/ui/icon'

export default function LastCycle({ props, isMark }: SliderProps) {
  const { className, ...thumbProps } = props
  const newProps = omit(thumbProps, ['isPaused', 'isCompleted', 'displayOnly'])

  return (
    <Tooltip title={THUMB_NAMES.last} placement="top" arrow>
      <div
        {...newProps} // has to come first
        className="relative -top-6"
        draggable={!isMark}
      >
        <div
          className={cx(
            className,
            styles.round,
            styles.disabled,
            '!h-6 !w-6 !text-sm',
          )}
        >
          <Icon icon="history" className="text-inherit" size="sm" />
        </div>
        <hr
          className={cx(
            isMark ? styles.dashedDisabled : styles.dashedDefault,
            'h-3 bg-transparent text-transparent',
          )}
        />
      </div>
    </Tooltip>
  )
}
