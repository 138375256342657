import React, { useEffect } from 'react'
import type { FormState, Inputs, StateErrors } from 'react-use-form-state'
import Select from './Select'
import type { MemberEvaluation, SelectType } from '../../types'
import PercentageCircle from 'shared/ui/PercentageCircle'
import { riskPercentageStyle } from 'performance/evaluations/membersEvaluation/labelAndColorByValue'

type Props = {
  save: (arg: Record<string, number | string | null>) => void
  inputs: Inputs<MemberEvaluation>
  formState: FormState<MemberEvaluation, StateErrors<MemberEvaluation, string>>
  initalPercentage: MemberEvaluation['riskPercentage']
}

export default function RiskGroup({
  save,
  inputs,
  formState,
  initalPercentage,
}: Props) {
  const { salaryScore, lossImpactScore, riskPercentage } = formState.values

  const handlePercentage = () => {
    const maxScore = 10
    const sum = Number(salaryScore) + Number(lossImpactScore)
    const result = Math.round(
      ((Math.sign(sum) === -1 ? 0 : sum) / maxScore) * 100,
    )
    if (initalPercentage != result) {
      save({ riskPercentage: Number(result) })
    }
    formState.setField('riskPercentage', result)
    formState.setFieldError('riskPercentage', undefined)
  }

  const handleSave = (type: SelectType, value: number | string | null) => {
    const result = value === '' ? null : Number(value)
    if (initalPercentage != result) {
      save({ [type]: result })
    }
  }

  useEffect(() => {
    if (salaryScore || lossImpactScore) {
      handlePercentage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salaryScore, lossImpactScore])

  return (
    <>
      <Select
        title="Remuneração"
        type="salaryScore"
        selected={salaryScore}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          handleSave('salaryScore', event.target.value)
        }}
        inputs={inputs}
        formState={formState}
      />
      <Select
        title="Impacto de Perda"
        type="lossImpactScore"
        selected={lossImpactScore}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          handleSave('lossImpactScore', event.target.value)
        }}
        inputs={inputs}
        formState={formState}
      />
      <td className="ml-1 mr-3">
        <PercentageCircle
          title="Risco de Perda"
          percentage={riskPercentage}
          className={riskPercentageStyle(riskPercentage).bgColor}
        />
      </td>
    </>
  )
}
