import React from 'react'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import flow from 'lodash/flow'
import isNumber from 'lodash/isNumber'
import omit from 'lodash/omit'

import Modal from 'shared/ui/Modal'
import Input from 'shared/forms/Input'
import Select from 'shared/forms/SelectNoMUI'
import { presence } from 'shared/forms/validations'
import { useForm } from 'shared/utils/useForm'
import { colors } from 'theme'

const NumberInput = ({
  handleClose,
  list,
  handleSubmit,
  editingIndex,
  step,
  resetStatus,
  inputType,
}: any) => {
  const isEditing = isNumber(editingIndex)
  const inputBeingEdited = isEditing ? list[editingIndex] : {}

  const { formState, fields, hasErrors } = useForm<{
    inputName: string
    maskType: any
    required: any
  }>(
    { inputType, ...inputBeingEdited },
    {
      requiredFields: ['inputName', 'maskType'],
    },
  )

  const onClose = flow(formState.reset, handleClose)

  const onSubmit: React.FormEventHandler = (e) => {
    e.preventDefault()
    if (hasErrors) return

    const transform = isEditing ? transformToUpdate : transformToCreate
    handleSubmit(
      transform({
        newItem: formState.values,
        items: list,
        index: editingIndex,
        step,
      }),
    )
    onClose()
    resetStatus()
  }

  return (
    <>
      <Modal.Content>
        <div className="flex flex-col gap-3">
          <Input
            {...fields('text', 'inputName', [presence()])}
            title="Título da pergunta"
            label="Título da pergunta"
            placeholder="Ex.: Escreva aqui a justificativa de aumento pedido."
            autoComplete="off"
          />

          <Select
            {...fields('select', 'maskType', [presence()])}
            title="Máscara"
            name="maskType"
            label="Máscara"
            helper="Selecione a máscara desejada"
            variant="outlined"
            options={[
              { label: '', value: null },
              { label: '9.999,99', value: 'period' },
              { label: '9,999.99', value: 'comma' },
              { label: '999999', value: 'integer' },
            ]}
          />
        </div>

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              {...omit(fields('checkbox', 'required', []), ['error'])}
            />
          }
          label="Campo obrigatório"
          style={{
            color: colors.textSecondary,
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          title={isEditing ? 'Atualizar' : 'Adicionar'}
          size="small"
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={hasErrors}
        >
          {isEditing ? 'Atualizar' : 'Adicionar'}
        </Button>
      </Modal.Actions>
    </>
  )
}

const transformToCreate = ({ newItem, items, step }: any) => [
  ...items,
  { step, ...newItem },
]

const transformToUpdate = ({ newItem, items, index }: any) => {
  const newItems = [...items]
  newItems[index] = newItem

  return newItems
}

export default NumberInput
