import * as React from 'react'
import { DropZone } from 'uploods'
import Button from '@material-ui/core/Button'
import { useFormContext } from 'react-hook-form'
import Icon from 'shared/ui/icon'
import type { InputProps } from './input'
import Input from './input'
import InputHint from './input-hint'

function parseFiles(filesString: string) {
  if (!filesString) return []
  try {
    return JSON.parse(filesString) as File[]
  } catch (err) {
    return []
  }
}

type File = {
  id: string
  name: string
  parsed: string
  percent: number
  size: number
  state: string
  type: string
}
type Props = InputProps & {
  accept?: string[]
  hint?: string
}
function UploadInput({
  accept,
  hint = 'Adicione arquivos que serão listados na observação da solicitação',
  ...props
}: Props) {
  const { setValue, formState, unregister } = useFormContext()
  const hasError = Boolean(formState?.errors[props.name]?.message)

  function handleChange(files: File[]) {
    (files.length === 0) ? unregister(props.name) : setValue(props.name, JSON.stringify(files), { shouldValidate: true })
  }

  return (
    <>
      <Input {...props} type="hidden" />
      <DropZone
        unsupportedText="Arquivo não suportado"
        dragActiveText="Solte o arquivo aqui"
        accept={accept}
        text={
          (
            <Button
              title="Adicionar anexo"
              color="primary"
              variant="contained"
              size="small"
              startIcon={<Icon icon="attach-file" size="sm" />}
            >
              Adicionar anexo
            </Button>
          ) as any
        }
        paperProps={{ elevation: 0 }}
        containerStyle={{ padding: 0, marginTop: 2 }}
        inputStyle={{
          height: 96,
          border: `1px dashed ${hasError ? '#D6394C' : '#5F91CC'}`,
        }}
        onChange={handleChange as (f: any[]) => void}
        config={{ autoUpload: false }}
        multiple
      />
      <InputHint>{hint}</InputHint>
    </>
  )
}

export { parseFiles }
export default UploadInput
