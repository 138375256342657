import Actions from './Actions'
import Confirm from './Confirm'
import Content from './Content'
import Header from './Header'
import Wrapper from './Wrapper'
import FormWrapper from './FormWrapper'
import Title from './Title'

export default {
  FormWrapper,
  Wrapper,
  Header,
  Title,
  Content,
  Actions,
  Confirm,
}
