import * as React from 'react'
import { useLocation } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import { compareValue, renderIf } from 'shared/helpers'
import AvatarMenu from './AvatarMenu'
import MenuItem from './MenuItem'
import type { AccessPolicyUser } from 'shared/accessPolicies'
import { getAvailablePaths, adminsOrLeaders } from 'shared/accessPolicies'
import activeSection from '../activeSection'
import useOrganization from 'shared/organization/useOrganization'

import type { MenuItemType, Organization } from 'types'

const moduleItems = getAvailablePaths()
const possiblePaths = moduleItems
  .filter((menu) => menu.path !== '/')
  .map((menu) => menu.path.replace('/', ''))

type RenderMenuButtonArgs = {
  pathname: string
  currentUser: AccessPolicyUser
  organization: Organization
}

export const renderMenuButton =
  ({ pathname, currentUser, organization }: RenderMenuButtonArgs) =>
  ({ path, icon, title, menuItems }: any) => {
    const someMenuItemIsVisibleAndPermitted = menuItems.some(
      (item: MenuItemType) =>
        item.visibleFor(currentUser) &&
        item.permittedForOrganization(organization),
    )

    if (!someMenuItemIsVisibleAndPermitted) return null

    const activePath =
      menuItems.find(
        (item: MenuItemType) =>
          item.visibleFor(currentUser) &&
          item.permittedForOrganization(organization),
      ).path || ''

    const newPath = activePath === '/' ? path : path + activePath

    return (
      <MenuItem
        key={`${title}-${path}`}
        path={newPath}
        icon={icon}
        title={title}
        active={activeSection({ pathname, path, possiblePaths })}
      />
    )
  }

type MenuProps = {
  currentUser: AccessPolicyUser | null
  toggleDrawer: () => void
  isVisible: boolean
}

const Menu = ({ currentUser, toggleDrawer, isVisible }: MenuProps) => {
  const { pathname } = useLocation()
  const organization = useOrganization()

  if (!isVisible) {
    return null
  }

  if (!currentUser) {
    return null
  }

  return (
    <aside className="flex flex-col items-center gap-4 py-4 px-2 md:gap-8 md:pt-[100px]">
      <IconButton
        className="md:!hidden"
        color="primary"
        size="small"
        onClick={toggleDrawer}
      >
        <MenuOpenIcon className="!h-8 !w-8 text-white" />
      </IconButton>

      {moduleItems
        .filter(compareValue('position', 'top'))
        .map(renderMenuButton({ pathname, currentUser, organization }))}

      <div className="grow" />

      {renderIf(
        adminsOrLeaders(currentUser),
        <MenuItem
          key={`Convidar colaboradores-/convidar`}
          path={'/convidar'}
          icon={'invitation'}
          title={'Convidar Colaboradores'}
        />,
      )}

      {moduleItems
        .filter(compareValue('position', 'bottom'))
        .map(renderMenuButton({ pathname, currentUser, organization }))}
      <AvatarMenu currentUser={currentUser} />
    </aside>
  )
}

export default Menu
