import { useCroods } from 'croods'
import type { Dispatch, SetStateAction } from 'react'

import Modal from 'shared/ui/Modal'
import Avatar from 'shared/ui/Avatar'

import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { getUserIdentifier } from 'shared/helpers'

import type { User } from 'types'

const InviteModal = ({
  onClose,
  employee,
  setShowInvite,
  afterSuccess,
}: {
  onClose: () => void
  employee: User
  setShowInvite: Dispatch<SetStateAction<boolean>>
  afterSuccess?: () => void
}) => {
  const { snackbar } = useSnackbar()

  const [{ saving }, { save }] = useCroods({
    name: 'invitation',
    path: '/auth/invitation',
    afterSuccess: () => {
      afterSuccess?.()
      snackbar({
        title: 'Sucesso',
        message: 'Colaborador convidado com sucesso',
        type: 'success',
      })
      setShowInvite(false)
    },
    afterFailure: (e) => {
      snackbar({
        title: 'Não foi possível convidar o colaborador.',
        message: e?.response?.data?.message,
        type: 'error',
        timeout: 5,
      })
    },
  })

  const handleConfirm = async () => {
    async function saveFunction() {
      const resultSave = await save({})({ emails: [employee.email] })

      return resultSave
    }

    return await saveFunction()
  }

  return (
    <Modal.Wrapper loading={saving} onClose={onClose} onConfirm={handleConfirm}>
      <Modal.Header>Convidar para o Vibe</Modal.Header>
      <Modal.Content className="md:w-[38rem]">
        <p className="text-base text-gray-darkest">
          Ao convidar um colaborador para o Vibe, ele poderá criar sua senha de
          acesso.
        </p>

        <div className="mt-6 flex">
          <div className="mr-4 flex-col">
            <Avatar user={employee} />
          </div>

          <div className="flex max-w-[200px] flex-col justify-center">
            <label className="text-sm font-bold">
              {getUserIdentifier({ user: employee })}
            </label>
            <p className="font-400 text-sm text-black/50">{employee.role}</p>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Modal.Confirm color="primary">ENVIAR CONVITE</Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default InviteModal
