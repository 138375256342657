import * as React from 'react'
import { useCroods } from 'croods'
import Button from '@material-ui/core/Button'
import { mountQueryString } from 'shared/helpers'
import useCroodsHeaders from 'shared/utils/useCroodsHeader'
import { usePagination, useRestoreScrollOnChange } from 'shared/utils/hooks'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'

import { useSearch } from 'shared/utils/hooks'
import CollaboratorTable from './table'
import HeaderControls from 'shared/ui/HeaderControls'
import Pagination from 'shared/ui/pagination/Pagination'

import type { PageProps, User } from 'types'

import DeactivateModal from './DeactivateModal'
import EditModal from './EditModal'
import InviteModal from './InviteModal'
import ReactivateModal from './ReactivateModal'
import ResendInviteModal from './ResendInviteModal'
import FeedbackMessage from 'shared/ui/FeedbackMessage'

const STATES = {
  ACTIVE: 'Ativos',
  INACTIVE: 'Inativos',
  WO_CIRCLE: 'Sem círculo',
}

export type Action =
  | 'DEACTIVATE'
  | 'REACTIVATE'
  | 'EDIT'
  | 'INVITE'
  | 'RESENDINVITE'
export type ActionCaller = (o: State) => void

const state = { user: null, action: null }
type State = { user: null | User; action: null | Action }

const Users: PageProps = ({ pageTitle }) => {
  const { headers, parseResponse } = useCroodsHeaders()

  const { currentPage, setCurrentPage, totalPages } = usePagination(
    Number(headers['per-page']),
    Number(headers['total']),
  )

  const { setSearch, searchTerm } = useSearch()
  const [filter, setFilter] = React.useState(STATES.ACTIVE)

  const selectedFilter = Object.entries(STATES).find(
    (state) => state[1] === filter,
  )

  const path = `/supervisor/users${mountQueryString({
    page: currentPage,
    search: searchTerm,
    filter: selectedFilter ? selectedFilter[0].toLowerCase() : 'active',
  })}`

  const [{ list: users, fetchingList }, { fetch, setList }] = useCroods({
    name: 'users',
    path,
    stateId: 'supervisor',
    cache: false,
    fetchOnMount: false,
    parseResponse,
  })

  const [modal, setModal] = React.useState<State>(state)
  const closeModal = () => setModal(state)

  const Loading = () => (
    <div className="flex flex-col shadow">
      <div className="h-9 w-full animate-pulse bg-gray-100" />
      <div className="h-16 animate-pulse " />
      <div className="h-16 animate-pulse bg-gray-100" />
      <div className="h-16 animate-pulse " />
      <div className="h-16 animate-pulse bg-gray-100" />
      <div className="h-16 animate-pulse" />
      <div className="h-16 animate-pulse bg-gray-100" />
      <div className="h-16 animate-pulse" />
      <div className="h-16 animate-pulse bg-gray-100" />
      <div className="h-16 animate-pulse" />
      <div className="h-16 animate-pulse bg-gray-100" />
    </div>
  )

  React.useEffect(() => {
    fetch()()
  }, [currentPage, filter, searchTerm]) // eslint-disable-line

  React.useEffect(() => {
    setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchTerm])

  useRestoreScrollOnChange(currentPage)

  return (
    <>
      <h3>{pageTitle}</h3>
      <HeaderControls
        filters={STATES}
        filter={filter}
        setFilter={setFilter}
        setSearch={setSearch}
        searchLabel="Buscar colaborador"
      >
        <Button
          variant="contained"
          className="uppercase no-underline"
          color="primary"
          size="small"
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => setModal({ action: 'INVITE', user: null })}
        >
          Convidar
        </Button>
      </HeaderControls>
      {!fetchingList ? (
        headers['per-page'] ? (
          totalPages > 0 ? (
            <div className="card">
              <CollaboratorTable
                actOnUser={setModal}
                rows={users}
                fetch={fetch}
                disableInvite={filter === 'Inativos'}
              />

              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageClick={setCurrentPage}
              />
            </div>
          ) : (
            <FeedbackMessage
              message="A consulta não retornou resultados"
              type="warning"
              serverMessage=""
            />
          )
        ) : (
          <FeedbackMessage message="Erro no servidor" serverMessage="" />
        )
      ) : (
        <Loading />
      )}
      {modal.action === 'INVITE' && (
        <InviteModal onClose={closeModal} setList={setList} rows={users} />
      )}
      {modal.action === 'DEACTIVATE' && (
        <DeactivateModal
          onClose={closeModal}
          user={modal.user}
          fetchList={fetch}
        />
      )}
      {modal.action === 'REACTIVATE' && (
        <ReactivateModal
          onClose={closeModal}
          user={modal.user}
          fetchList={fetch}
        />
      )}
      {modal.action === 'EDIT' && (
        <EditModal onClose={closeModal} user={modal.user} />
      )}
      {modal.action === 'RESENDINVITE' && (
        <ResendInviteModal
          onClose={closeModal}
          user={modal.user}
          fetchList={fetch}
        />
      )}
    </>
  )
}

export default Users
