import { useCallback, useEffect } from 'react'
import type {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormTrigger,
} from 'react-hook-form'
import { useFieldArray } from 'react-hook-form'
import type { FormProps } from './types'
import get from 'lodash/get'
import Icon from 'shared/ui/icon'
import TimeInput from './time-inputs'
import { getWarning } from './helpers'
import InputHint from 'shared/formsv2/input-hint'

type Props = {
  control: Control<FormProps, object>
  errors: FieldErrors<FormProps>
  masterDate: string
  trigger: UseFormTrigger<FormProps>
  register: UseFormRegister<FormProps>
}
const TimeInputSection = ({
  control,
  errors,
  masterDate,
  trigger,
  register,
}: Props) => {
  const { fields, remove, insert, update, replace } = useFieldArray({
    control,
    name: 'timeInputs',
  })

  const handleInsert = useCallback(
    (index: number) =>
      insert(index, {
        value: '',
        disabled: false,
        focus: true,
        parsedValue: undefined,
      }),
    [insert],
  )

  useEffect(() => {
    if (fields.length > 1) {
      const newFields = fields.map((item, index, array) => {
        const currentItem = item?.parsedValue
        const prevItem = array[index - 1]?.parsedValue

        if (
          currentItem &&
          prevItem &&
          currentItem?.getDate() - prevItem?.getDate() > 1
        ) {
          const parsedValue = new Date(prevItem)
          parsedValue.setDate(prevItem.getDate() + 1)
          return { ...item, parsedValue }
        }

        return item
      })

      if (JSON.stringify(newFields) !== JSON.stringify(fields))
        replace(newFields)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.length])

  return (
    <section className="mb-6 flex flex-col">
      <ul
        aria-label="timeInputs"
        className="flex w-full flex-wrap items-center gap-3"
      >
        {fields.map(({ value, focus, id, disabled, parsedValue }, index) => {
          const inputError = get(errors, `timeInputs[${index}].value.message`)

          return (
            <li
              key={id}
              aria-label={`timeInputs[${index}].value`}
              className="relative flex items-center gap-1"
            >
              {index === 0 && (
                <Icon
                  title="adicionar campo de horário"
                  icon="add-circle"
                  className="cursor-pointer text-blue"
                  onClick={() => handleInsert(0)}
                />
              )}

              <TimeInput
                id={id}
                autoFocus={focus}
                error={inputError}
                disabled={disabled}
                defaultValue={value}
                masterDate={masterDate}
                onRemove={() => {
                  remove(index)
                  trigger('timeInputs')
                }}
                aria-label={`timeInputs[${index}].value`}
                previousFieldParsedValue={fields[index - 1]?.parsedValue}
                warning={
                  parsedValue && !disabled
                    ? getWarning(parsedValue, masterDate)
                    : ''
                }
                onUpdate={(updadeProps) =>
                  update(index, { ...updadeProps, disabled: false })
                }
                //@ts-ignore
                {...register(`timeInputs[${index}].value`)}
              />
              <Icon
                title="adicionar campo de horário"
                icon="add-circle"
                className="cursor-pointer text-blue"
                onClick={() => handleInsert(index + 1)}
              />
            </li>
          )
        })}
      </ul>
      <InputHint isError className="!text-base">
        {get(errors, 'timeInputs.message')}
      </InputHint>
    </section>
  )
}

export default TimeInputSection
