import * as React from 'react'
import { useCroods } from 'croods'
import findKey from 'lodash/findKey'
import map from 'lodash/map'

import Icon from 'shared/ui/icon'
import { useSearch } from 'shared/utils/hooks'
import HeaderControls from 'shared/ui/HeaderControls'
import Table from 'shared/ui/table'
import Skeleton from 'shared/ui/skeleton'
import type { PageProps, Solicitation } from 'types'
import EmptyMessageFeedback from '../EmptyMessageFeedback'

import {
  filterByStatus,
  parseSolicitation,
  getPendingAmounts,
  setRequiredWarning,
} from 'portal/helpers'
import type { ParsedSolicitation } from 'portal/helpers'
import { PendingSolicitationProvider } from './context'
import SolicitationDescription from './SolicitationDescription'
import BulkActionButton from './Actions/BulkActionButton'
import ToggleAll from 'shared/ui/table/toggle-all'
import { cx } from 'shared/helpers'
import FeedbackMessage from 'shared/ui/FeedbackMessage'

const query = { query: 'pending' }

const handleRequiredWarning = setRequiredWarning(
  <Icon
    className="text-yellow"
    icon="error-outline"
    size="sm"
    title="Existem campos obrigatórios que precisam ser preenchidos"
  />
)

const tableHeaders = [
  'Nº',
  'PENDÊNCIA',
  'DATA, HORA',
  'MODELO DE SOLICITAÇÃO',
  'SOLICITANTE',
]

const classNameTd = 'py-1 md:py-5'

const PendingSolicitationPage: PageProps = ({ pageTitle }) => {
  const { setSearch, select } = useSearch()
  const [{ list, fetchingList, listError }, { fetch }] =
    useCroods<Solicitation>({
      name: 'solicitationsPending',
      path: 'workflow/solicitations',
      cache: false,
      query,
      fetchOnMount: true,
    })
  const {
    pendingApprovalAmount,
    pendingApprovalIds,
    pendingExecutionAmount,
    pendingExecutionIds,
  } = getPendingAmounts(list)
  const states = {
    all: 'Todas',
    pending_approval: 'Aprovações',
    pending_execution: 'Execuções',
  }
  const [filter, setFilter] = React.useState(states.all)
  const filterKey = findKey(states, (val) => val === filter) ?? 'all'
  const solicitations = filterByStatus(list, filterKey)
  const parsedSolicitations = solicitations.map(parseSolicitation)
  const rows = select(
    parsedSolicitations,
    //@ts-ignore
    ({
      id,
      fullName,
      status,
      templateName,
      automationIdentifier,
      fixedFieldsAnswers,
      prefixedValues,
      vacationStartDate,
    }: ParsedSolicitation) => [
        String(id),
        fullName,
        status,
        templateName,
        automationIdentifier,
        fixedFieldsAnswers,
        prefixedValues,
        vacationStartDate,
      ],
  ) as ParsedSolicitation[]

  let solicitationsComponent

  if (fetchingList) {
    solicitationsComponent = <Skeleton height="20rem" />
  } else if (listError === 'not allowed to index? this Symbol') {
    solicitationsComponent = (
      <FeedbackMessage
        message=""
        serverMessage="Solicitações não habilitadas para esta organização"
      />
    )
  } else if (solicitations.length) {
    solicitationsComponent = (
      <div className="card">
        <Table id="solicitations-table">
          <Table.Thead>
            {tableHeaders.map((header, index) => (
              <Table.Th key={index}>{header}</Table.Th>
            ))}
            <Table.Th className="w-screen text-right md:max-w-[64px]">
              <ToggleAll id="solicitations-table" allKeys={map(rows, 'id')} />
            </Table.Th>
          </Table.Thead>
          <Table.Tbody>
            {map(rows, (solicitation: ParsedSolicitation) => (
              <Table.TrExpandable
                key={solicitation.id}
                uniqueKey={solicitation.id}
                innerContent={
                  <SolicitationDescription
                    description={solicitation.description}
                    fixedFieldsAnswers={solicitation.fixedFieldsAnswers}
                    prefixedValues={solicitation.prefixedValues}
                    automationIdentifier={solicitation.automationIdentifier}
                    id={solicitation.id}
                    fullName={solicitation.fullName}
                    vacationStartDate={solicitation.vacationStartDate}
                    status={solicitation.status}
                  />
                }
              >
                <Table.Td data-title={tableHeaders[0]} className={classNameTd}>
                  <div className="flex gap-2">
                    {solicitation.id}

                    {handleRequiredWarning(
                      solicitation.description.hasUnfilledRequiredFields,
                    )}
                  </div>
                </Table.Td>
                <Table.Td data-title={tableHeaders[1]} className={classNameTd}>
                  {solicitation.status}
                </Table.Td>
                <Table.Td data-title={tableHeaders[2]} className={classNameTd}>
                  {solicitation.dateTime}
                </Table.Td>
                <Table.Td
                  data-title={tableHeaders[3]}
                  className={`${classNameTd} block md:table-cell`}
                >
                  {solicitation.templateName}
                </Table.Td>
                <Table.Td
                  data-title={tableHeaders[4]}
                  className={cx(classNameTd, 'overflow-hidden text-ellipsis')}
                >
                  {solicitation.fullName}
                </Table.Td>
              </Table.TrExpandable>
            ))}
          </Table.Tbody>
        </Table>
      </div>
    )
  } else {
    solicitationsComponent = (
      <div className="card">
        <EmptyMessageFeedback>
          Você finalizou todas as solicitações pendentes!
        </EmptyMessageFeedback>
      </div>
    )
  }

  return (
    <PendingSolicitationProvider
      value={{
        resetFilter: () => setFilter(filter),
        fetchPendingSolicitations: () => fetch()(query),
      }}
    >
      <h3>{pageTitle}</h3>
      <HeaderControls
        filters={states}
        filter={filter}
        setFilter={setFilter}
        setSearch={setSearch}
      >
        <BulkActionButton
          pendingAmount={pendingApprovalAmount}
          solicitationIds={pendingApprovalIds}
          actionType="pending_approval"
          filterKey={filterKey}
        />
        <BulkActionButton
          pendingAmount={pendingExecutionAmount}
          solicitationIds={pendingExecutionIds}
          actionType="pending_execution"
          filterKey={filterKey}
        />
      </HeaderControls>

      {solicitationsComponent}
    </PendingSolicitationProvider>
  )
}

export default PendingSolicitationPage
