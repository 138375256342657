import { Fetch } from 'croods'
import { useParams } from 'react-router-dom'
import Skeleton from 'shared/ui/skeleton'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import Section from 'shared/ui/section'
import Stepper from '../new/Stepper'
import PerformerForm from '../new/steps/PerformerForm'
import OpeningForm from '../new/steps/openingForm'
import ApproversForm from '../new/steps/approverForm'
import Summary from '../new/steps/summary'
import Review from './steps/review'
import indexOf from 'lodash/indexOf'

import useFormSteps, {
  EDITION_STEPS,
  PREVIEW_STEP,
  OPENING_STEP,
  APPROVER_STEP,
  PERFORMER_STEP,
  SUMMARY_STEP,
} from '../new/useFormSteps'

const approvalsHandleNext = (handleNext: any) => (formValues: any) => {
  const approvalsWithLevels = formValues.approvalsAttributes
    .filter(
      (approval: any) =>
        approval.approversAttributes && approval.approversAttributes.length,
    )
    .map((approval: any, index: any) => ({ ...approval, level: index + 1 }))

  return handleNext({ ...formValues, approvalsAttributes: approvalsWithLevels })
}

const EditForm = ({ template = {} }: any) => {
  const { step, values, saving, saveError, handleNext, handleNavigate } =
    useFormSteps({ initialValues: template, initialStep: PREVIEW_STEP })

  const activeStep = indexOf(EDITION_STEPS, step)
  return (
    <Section
      title={
        template.id
          ? 'Editar Modelo de Solicitação'
          : 'Novo Modelo de Solicitação'
      }
    >
      <div className="flex w-full flex-col">
        <Stepper
          steps={[
            'Visualização',
            'Abertura',
            'Aprovação',
            'Execução',
            'Publicação',
          ]}
          activeStep={activeStep}
          handleNavigate={handleNavigate}
        />
        <Review
          submitting={saving}
          error={saveError}
          values={values}
          handleSubmit={handleNext}
          visible={step === PREVIEW_STEP}
          handleNavigate="/configuracoes/modelos-de-solicitacao"
          nextButtonLabel="Avançar"
        />

        <OpeningForm
          submitting={saving}
          error={saveError}
          handleSubmit={handleNext}
          visible={step === OPENING_STEP}
          handleNavigate={handleNavigate}
          filledValues={values}
          activeStep={activeStep}
          backButtonLabel="Voltar"
        />

        <ApproversForm
          submitting={saving}
          error={saveError}
          handleSubmit={approvalsHandleNext(handleNext)}
          visible={step === APPROVER_STEP}
          handleNavigate={handleNavigate}
          filledValues={values}
          activeStep={activeStep}
        />

        <PerformerForm
          submitting={saving}
          error={saveError}
          handleSubmit={handleNext}
          visible={step === PERFORMER_STEP}
          handleNavigate={handleNavigate}
          filledValues={values}
          activeStep={activeStep}
        />

        <Summary
          submitting={saving}
          error={saveError}
          values={values}
          handleSubmit={handleNext}
          visible={step === SUMMARY_STEP}
          handleNavigate={handleNavigate}
          filledValues={values}
          activeStep={activeStep}
        />
      </div>
    </Section>
  )
}

const Edit = () => {
  const { id } = useParams()

  return (
    <Fetch
      name="editTemplate"
      customPath="/workflow/solicitation_templates/:id/edit"
      id={id}
      render={(template) => <EditForm template={template} />}
      renderLoading={() => <Skeleton height="50rem" />}
      renderError={(error) => <FeedbackMessage message={error} />}
      renderEmpty={() => (
        <FeedbackMessage
          message="Dados não encontrados"
          serverMessage=""
          type="warning"
        />
      )}
    />
  )
}

export default Edit
