import React from 'react'
import { useCroods } from 'croods'
import Icon from 'shared/ui/icon'
import Button from 'shared/forms/SubmitButton'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { usePendingSolicitation } from 'portal/pendingSolicitations/context'

import type { ParsedSolicitation } from 'portal/helpers'

const ApproveButton = ({
  id,
  status,
}: Pick<ParsedSolicitation, 'id' | 'status'>) => {
  const { resetFilter, fetchPendingSolicitations } = usePendingSolicitation()
  const { snackbar } = useSnackbar()
  const [, { save }] = useCroods({
    id,
    name: 'solicitations',
    stateId: `approve-${id}`,
    customPath: `/workflow/solicitations/${id}?solicitation_action=${
      status === 'Aprovação' ? 'approve' : 'execute'
    }`,
    afterSuccess: () => {
      snackbar({
        message: 'Solicitação aprovada com sucesso!',
        type: 'success',
      })
      fetchPendingSolicitations()
      resetFilter()
    },
    afterFailure: (e) =>
      snackbar({
        message: e?.response?.data.message,
        type: 'error',
      }),
  })

  return (
    <Button
      className="w-full flex-1 sm:w-auto"
      title={status === 'Aprovação' ? 'aprovar' : 'executar'}
      variant="contained"
      kind="success"
      type="button"
      onClick={() => save()()}
    >
      <Icon icon="thumbs-up" className="fill-transparent text-white" />
    </Button>
  )
}

export default ApproveButton
