import CloudDoneIcon from '@material-ui/icons/CloudDone'
import CloudOffIcon from '@material-ui/icons/CloudOff'
import CachedIcon from '@material-ui/icons/Cached'

const CloudSavingFeedback = ({ saved, saving, saveError }: any) => {
  const savingError = Boolean(saveError)

  if (savingError) {
    return <CloudOffIcon data-testid="save-error" className="text-red/40" />
  }

  if (saving) {
    return <CachedIcon data-testid="saving" className="text-black/40" />
  }

  if (saved) {
    return <CloudDoneIcon data-testid="saved" className="text-green/40" />
  }

  // default return
  return null
}

export default CloudSavingFeedback
