import * as React from 'react'
import moment from 'moment'

import Period from 'shared/ui/Period'
import useSummaryData from '../summary/useSummaryData'
import EvaluationCard from '../EvaluationCard'

import PercentageScoreCard from './PercentageScoreCard'
import map from 'lodash/map'

import { DetailsButton } from 'vibe/insights/twelve-box/teams-table'
import ConfirmationDialog from './ConfirmationDialog'
import useOrganization from 'shared/organization/useOrganization'
import type { FormState } from '../types'

const fixTeamType = (team: any) => ({
  id: team.teamId,
  name: team.teamName,
  denomination: team.teamDenomination,
  label: team.label || team.teamLabel || '',
  leaders: [],
  evaluation: {
    goalsScore: team.goalsScore,
    resultsScore: team.resultsScore,
    maturityScore: team.maturityScore,
    overallScorePercentage: team.overallScorePercentage,
    evaluatedAt: team.evaluatedAt,
    keepDoing: team.keepDoing,
    needsToImprove: team.needsToImprove,
    notes: team.notes,
  },
})

const EvaluatedTeam = ({ team, showEditButton = false }: any) => {
  const { fields } = useSummaryData({
    formState: {
      evaluatedAt: moment(team.evaluatedAt),
      goalsScore: team.goalsScore,
      resultsScore: team.resultsScore,
      maturityScore: team.maturityScore,
    } as unknown as FormState,
  })
  const { evaluationWindowClosesAt } = useOrganization()

  const [openDialog, setOpenDialog] = React.useState(false)

  const canEdit = showEditButton && team?.canEdit

  return (
    <>
      <div className="card rounded-2xl">
        <div className="header-card">
          <div className="flex items-center justify-end">
            <h4 className="grow justify-self-start ">{team.teamLabel}</h4>
            {canEdit && (
              <button
                type="button"
                className="cursor-pointer self-center border-blue px-5 text-lg !font-bold uppercase text-white  hover:underline"
                onClick={() => setOpenDialog(true)}
              >
                EDITAR
              </button>
            )}

            <DetailsButton team={fixTeamType(team)} whiteVariant={true} />
          </div>
        </div>

        <div className="p-6">
          {team.cycleStartedAt && team.cycleFinishedAt ? (
            <Period
              title="Ciclo"
              startsAt={team.cycleStartedAt}
              finishesAt={team.cycleFinishedAt}
            />
          ) : null}
          <div className="gap-4 mt-6 md:grid md:grid-cols-4">
            {map(fields, (item) => (
              <EvaluationCard
                key={item.title}
                title={item.title}
                description={item.description}
                type={item.type}
                score={item.score}
              />
            ))}
            <EvaluationCard title="Performance" type="performance">
              <PercentageScoreCard
                percentageScore={team.overallScorePercentage}
                previousPercentageScore={
                  team.previousCycleOverallScorePercentage
                }
              />
            </EvaluationCard>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        openDialog={openDialog}
        handleClose={() => setOpenDialog(false)}
        finishesAt={evaluationWindowClosesAt}
        teamEvaluationId={team.id}
        teamId={team.teamId}
      />
    </>
  )
}

export default EvaluatedTeam
