import compact from 'lodash/compact'
import get from 'lodash/get'
import head from 'lodash/head'
import identity from 'lodash/identity'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import objValues from 'lodash/values'
import pick from 'lodash/pick'
import some from 'lodash/some'

import moment from 'moment'

import type { FormState, Inputs, StateValues } from 'react-use-form-state'
import type { Moment } from 'moment'

// eslint-disable-next-line
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

type Validator = (...args: any) => string | undefined

export const validate =
  <T>(validators: Validator[]) =>
  (value: string | number, values: StateValues<T>) =>
    head(compact(validators.map((validator) => validator(value, values))))

export const presence =
  (message: string = 'É obrigatório') =>
  (value?: string | number) =>
    value ? undefined : message

export const maxLength =
  (
    length: number = 120,
    message: string = `Máximo caracteres permitido é ${length}.`,
  ) =>
  (value?: string) => {
    if (value && value.length > length) return message
    return undefined
  }

export const arrayPresence =
  (message: string = 'É obrigatório') =>
  (value?: Array<string>) => {
    if (!isArray(value) || value.length === 0) {
      return message
    }

    const messages = value.map((item) =>
      item && item.trim().length ? undefined : message,
    )

    if (messages.some(identity)) {
      return messages
    }

    return undefined
  }

export const arrayUniqueness =
  (message: string = 'Já está em uso') =>
  (value?: Array<string>) => {
    if (!isArray(value) || value.length === 0) {
      return undefined
    }

    const seen = {} as Record<string, boolean>
    const messages = value.map((item) => {
      if (seen[item]) {
        return message
      }

      seen[item] = true
      return undefined
    })

    if (messages.some(identity)) {
      return messages
    }

    return undefined
  }

export const richTextPresence =
  (message = 'É obrigatório') =>
  (value?: string) => {
    const striped = value ? value.replace(/<[^>]*>?/gm, '').trim() : ''
    return striped.length > 0 ? undefined : message
  }

export const email =
  (message = 'Email inválido') =>
  (value: string) =>
    value && !EMAIL_REGEX.test(value) ? message : undefined

export const date =
  (format = 'DD/MM/YYYY', message = 'Data inválida') =>
  (value?: string | Moment) => {
    if (!value) {
      return undefined
    }

    if (typeof value === 'string') {
      return !moment(value, format).isValid() ||
        value.replace(' ', '').length < format.length
        ? message
        : undefined
    }

    return value.isValid() ? undefined : message
  }

export const futureDate =
  (
    compareWith: string,
    { format, message } = {
      format: 'DD/MM/YYYY',
      message: 'Data passada',
    },
  ) =>
  (value: string, values: Record<string, string>) => {
    const compareWithValue = get(values, compareWith)

    if (!value || !compareWithValue) return undefined

    const valueDate = moment(value, format)
    const compareWithDate = moment(compareWithValue, format)

    if (!valueDate.isValid() || !compareWithDate.isValid()) return undefined

    return valueDate.diff(compareWithDate, 'days') < 0 ? message : undefined
  }

export const getFieldError =
  <T>(formState: FormState<T>) =>
  (name: keyof Omit<T, 'id'>) =>
    formState.touched?.[name] && formState.errors?.[name]

type Validators = Array<(value?: string | undefined) => string | undefined>

type BaseType<T> = keyof Omit<
  Inputs<T>,
  | 'selectMultiple'
  | 'select'
  | 'textarea'
  | 'radio'
  | 'checkbox'
  | 'raw'
  | 'label'
  | 'id'
>

export const getFieldProps =
  <T>(fields: Inputs<T>, formState: FormState<T>) =>
  (
    type: keyof Omit<Inputs<T>, 'label' | 'id'>,
    name: keyof Omit<T, 'id'>,
    validators: Validators = [],
    options = {},
  ) => {
    const fieldError = getFieldError(formState)

    const newType = type as BaseType<T>

    const initializer = fields?.[newType] || {}

    return {
      ...initializer({
        name,
        validate: validate(validators),
        ...options,
      }),
      error: fieldError(name),
    }
  }

export const isValidForm = <T>(formState: FormState<T>) =>
  !compact(objValues(formState.errors)).length

type RequiredFields<T> = { requiredFields?: Array<keyof T> }

export const isFulfilled = <T>(
  formState: FormState<T>,
  { requiredFields }: RequiredFields<T> = {},
) => {
  if (!requiredFields)
    return (
      objValues(formState.values).length ===
      compact(objValues(formState.values)).length
    )

  const values = objValues(pick(formState.values, requiredFields))

  return values.length === requiredFields.length && !some(values, isEmpty)
}

export const isPartiallyFilled = <T>(
  formState: FormState<T>,
  { requiredFields }: RequiredFields<T> = {},
) => {
  const picked = pick(formState.values, requiredFields || [])
  const values: any[] = objValues(picked)
  const compacted = compact(objValues(values))
  const filtered = compacted.filter((item) => item.length !== 0)
  return filtered.length > 0
}
