import React from 'react'
import Modal from 'shared/ui/Modal'

import Content from './Content'
import type { FormState } from '../types'

type Props = {
  formState: FormState
}
export default function Confirm({ formState }: Props) {
  return (
    <>
      <Modal.Header>Confirmar avaliação de performance?</Modal.Header>
      <Modal.Content>
        <Content formState={formState} />
      </Modal.Content>
      <Modal.Actions cancelLabel="Editar">
        <Modal.Confirm>Confirmar</Modal.Confirm>
      </Modal.Actions>
    </>
  )
}
