import React from 'react'
import { cx } from 'shared/helpers'

type Props = {
  title?: string
  children: React.ReactNode
  className?: string
  style?: any
}

const ConversationPaper = ({
  title = 'NOVA CONVERSA',
  children,
  className,
  ...props
}: Props) => {
  return (
    <section {...props} className={cx('card mx-auto w-full', className)}>
      <div className="card flex flex-col items-center !overflow-visible">
        <header className="flex h-12 w-full items-center justify-between bg-gray-lightest p-3">
          <h3 className="text-base font-bold uppercase text-gray-darkest">
            {title}
          </h3>
        </header>
        <main className="w-full px-8 pt-8 last:pb-8">{children}</main>
      </div>
    </section>
  )
}

export default ConversationPaper
