import { periodDictionary } from 'shared/ui/Period'
import Modal from 'shared/ui/Modal'
import Select from 'shared/formsv2/select'

type Props = {
  selectedDuration: string
}
const DialogStageOne = ({ selectedDuration }: Props) => {
  const options = [
    { value: '3', label: periodDictionary[3]! },
    { value: '4', label: periodDictionary[4]! },
    { value: '6', label: periodDictionary[6]! },
  ]
  return (
    <>
      <Modal.Header>Editar ciclos</Modal.Header>
      <Modal.Content>
        <h5>Periodicidade de avaliação</h5>
        <Select
          name="selectCycle"
          defaultValue={selectedDuration}
          options={options}
        />
      </Modal.Content>
    </>
  )
}

export default DialogStageOne
