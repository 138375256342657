import * as React from 'react'
import { useCroods } from 'croods'
import * as z from 'zod'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import { useCurrentUser } from 'croods-auth'
import type { SubmitHandler } from 'react-hook-form'
import Input from 'shared/formsv2/input'
import DateInput from 'shared/formsv2/date-input'
import SubmitButton from 'shared/formsv2/submit-button'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ControlledForm from 'shared/formsv2/form'
import ImageUploadInput, { parseFile } from 'shared/formsv2/image-upload-input'
import {
  Link,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { notFutureDateSchema } from 'shared/schema-helpers'
import { cx, formatDateUnits } from 'shared/helpers'
import ChangePasswordModal from './change-password-modal'
import { match } from 'ts-pattern'
import { inputFromSearch } from 'domain-functions'
import type { getUserEnv } from 'domain/auth'

const schema = z
  .object({
    firstName: z.string().nonempty({ message: 'Não pode ficar em branco' }),
    lastName: z.string().nonempty({ message: 'Não pode ficar em branco' }),
    role: z.string(),
    admissionDate: notFutureDateSchema,
    image: z.string().transform((val) => {
      const file = parseFile(val)
      return file?.parsed ?? ''
    }),
  })
  .transform((data) => {
    const newData = omitBy(data, isEmpty)
    return newData
  })

type FormValues = z.infer<typeof schema>

const updateImage = async (image: String, saveImage: Function) => {
  if (image && saveImage) await saveImage({ image })
}

const signInMethodSchema = z.enum(['google', 'password'])

export default function EditProfile() {
  const loaderData = useLoaderData() as Awaited<ReturnType<typeof getUserEnv>>
  const { currentUser } = loaderData
  const navigate = useNavigate()
  const location = useLocation()
  const { error, success } = inputFromSearch(
    new URLSearchParams(location.search),
  )
  const [, setCurrentUser] = useCurrentUser()
  const { snackbar } = useSnackbar()
  const [changePasswordModalOpen, setChangePasswordModalOpen] =
    React.useState(false)

  React.useEffect(() => {
    if (!error) return

    snackbar({ message: String(error), type: 'error' })
  }, [snackbar, error])

  React.useEffect(() => {
    if (!success) return

    snackbar({ message: String(success), type: 'success' })
  }, [snackbar, success])

  const [, { save }] = useCroods({
    name: 'myUser',
    customPath: '/auth',
    afterSuccess: async (response) => {
      setCurrentUser(response.data.data)
      snackbar({
        message: 'Perfil atualizado com sucesso',
        type: 'success',
      })
      navigate('/')
    },
  })

  const [, { save: saveImage }] = useCroods({
    name: 'myUser',
    customPath: '/users',
  })

  const handleSubmit: SubmitHandler<FormValues> = async (data) => {
    await updateImage(data.image, saveImage({ method: 'put' }))

    return save({ method: 'PUT' })(data)
  }

  const { firstName, lastName, role, email, admissionDate, avatar, imported } =
    currentUser

  const signInMethod = signInMethodSchema.parse(
    loaderData.currentUser.signInMethod,
  )

  return (
    <>
      <h3>Editar Perfil</h3>

      <ControlledForm
        onSubmit={handleSubmit}
        schema={schema}
        className="mt-4 flex flex-col gap-6"
      >
        <div className="my-4 flex flex-col items-center">
          <ImageUploadInput
            label="Avatar"
            initialSrc={avatar ? String(avatar) : undefined}
            id="avatar"
            name="image"
            hint="(opcional)"
            className="overflow-hidden rounded-full"
          />
        </div>

        <div className="grid gap-y-6 gap-x-4 md:grid-cols-2">
          <div className="flex flex-col">
            <Input
              required
              type="firstName"
              name="firstName"
              label="Nome"
              defaultValue={firstName}
              readOnly={imported}
              className={cx(imported && 'disabled')}
            />
          </div>
          <div className="flex flex-col">
            <Input
              required
              type="lastName"
              name="lastName"
              label="Sobrenome"
              defaultValue={lastName}
              readOnly={imported}
              className={cx(imported && 'disabled')}
            />
          </div>
          <div className="flex flex-col">
            <Input
              type="role"
              name="role"
              label="Cargo"
              defaultValue={role || undefined}
              readOnly={imported}
              className={cx(imported && 'disabled')}
            />
          </div>

          <div className="flex flex-col">
            <p className="label mb-1">E-mail</p>
            <div defaultValue={email} className="disabled input">
              {email}
            </div>
          </div>
          <div className="flex flex-col">
            <DateInput
              required
              name="admissionDate"
              label="Data de admissão"
              aria-label="Data de admissão"
              defaultValue={formatDateUnits(admissionDate || '')}
              readOnly={imported}
              className={cx(imported && 'disabled')}
            />
          </div>
        </div>
        <hr className="my-4" />
        <div className="flex flex-col items-center gap-6">
          <div className="flex flex-col sm:flex-row gap-2 items-center">
            {signInMethod === 'google' && (
              <img
                src="/google-login-logo.png"
                alt="Google logo"
                className="h-5"
              />
            )}
            <h4 className="text-center">
              Sua conta Vibe é acessada com{' '}
              {match(signInMethod)
                .with('google', () => 'sua conta Google')
                .with('password', () => 'e-mail e senha')
                .exhaustive()}
            </h4>
          </div>
          <div className="flex flex-col gap-2">
            {signInMethod === 'password' && (
              <button
                type="button"
                className="text-center text-base font-bold text-blue"
                onClick={() => setChangePasswordModalOpen(true)}
              >
                Alterar senha
              </button>
            )}
            <Link
              preventScrollReset
              to={
                signInMethod === 'google'
                  ? 'alterar-login-para-senha'
                  : 'alterar-login-para-google'
              }
              className="text-center text-base font-bold text-blue"
            >
              Alterar forma de login
            </Link>
          </div>
        </div>
        <section className="flex justify-end rounded-b-xl bg-blue-50 p-8 text-center">
          <SubmitButton
            className="self-center rounded border border-blue p-2 text-lg font-bold uppercase text-blue hover:bg-white"
            submitting="Atualizando..."
          >
            Atualizar
            <ArrowForwardIcon className="ml-2" fontSize="small" />
          </SubmitButton>
          {changePasswordModalOpen && (
            <ChangePasswordModal
              user={currentUser}
              onClose={() => setChangePasswordModalOpen(false)}
            />
          )}
        </section>
      </ControlledForm>
      <Outlet />
    </>
  )
}
