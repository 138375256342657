import * as React from 'react'
import Icon from 'shared/ui/icon'

type Props = {
  setSearch: Function
  searchTerm?: string
  placeholder: string
}

function Search({ setSearch, searchTerm = '', placeholder }: Props) {
  // We need to hold an internal state for the cases where the search is async and debounced
  const [value, setValue] = React.useState(searchTerm)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    setSearch(event.target.value)
  }

  return (
    <div className="flex flex-1 items-center justify-start gap-2 rounded border border-blue px-4 text-blue focus-within:ring-1">
      <Icon icon="search" size="md" className="" />
      <input
        className="h-9 w-full rounded text-base text-blue placeholder-blue placeholder-opacity-50 focus:outline-none"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  )
}

export default Search
