import moment from 'moment'
import type { SOLICITATION_STATUS } from 'portal/constants'

export const joinInputsHour = (inputs: string[] = []) => {
  const filterInput = inputs.filter((input) => Boolean(input))

  return filterInput.map((item) => moment(item).utc().format('LT')).join(' | ')
}

export const getLatestSolicitationStatus = (
  status: keyof typeof SOLICITATION_STATUS | undefined,
) => {
  if (!status) return ''

  switch (status) {
    case 'rejected':
      return 'REPROVADA'
    case 'completed':
      return 'FINALIZADA'

    default:
      return 'EM ANDAMENTO'
  }
}
