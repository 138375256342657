import { routeBreadcrumbs } from 'breadcrumbs'
import type { Breadcrumb as BreadcrumbType } from 'types'
import MobileMenuButton from 'shared/ui/layout/MobileMenuButton'
import BreadcrumbItem from './breadcrumb-item'
import type { RouteCategory } from 'domain/routes'

type Props = {
  breadcrumbs: string[]
  category?: RouteCategory
  isDrawerOpen: boolean
  toggleDrawer: () => void
}

export default function Breadcrumbs({
  breadcrumbs,
  category,
  isDrawerOpen,
  toggleDrawer,
}: Props) {
  const possibleRoutes = category
    ? routeBreadcrumbs[category]
    : ({} as {
        [index: string]: BreadcrumbType
      })

  const filteredBreadcrumbs = breadcrumbs.filter(
    (breadcrumb) => possibleRoutes[breadcrumb],
  )

  if (filteredBreadcrumbs.length === 0) {
    return (
      <div className="absolute right-4 top-4 rounded-full bg-white shadow">
        <MobileMenuButton isOpen={isDrawerOpen} toggle={toggleDrawer} />
      </div>
    )
  }

  return (
    <div className="sticky -top-2 z-50 -mx-2 mb-4 -mt-2 flex min-h-[4rem] items-center justify-between rounded-t-xl border-b border-gray border-opacity-50 bg-white px-2 sm:-mx-4 sm:mb-6 sm:-mt-4 sm:rounded-t-2xl sm:px-4 md:-top-4 md:-mx-6 md:-mt-6 md:px-6">
      <div className="flex items-center gap-2 divide-x">
        {filteredBreadcrumbs.map((breadcrumb, idx) => (
          <div className="pl-2 first:pl-0" key={idx}>
            <BreadcrumbItem
              routeInfo={possibleRoutes[breadcrumb]}
              last={idx + 1 === breadcrumbs.length}
            />
          </div>
        ))}
      </div>
      <div className="-mt-1 rounded-full bg-white shadow">
        <MobileMenuButton isOpen={isDrawerOpen} toggle={toggleDrawer} />
      </div>
    </div>
  )
}
