import type { ReactNode } from 'react'
import { cx } from 'shared/helpers'
import Icon from 'shared/ui/icon'

const Alert = ({
  severity,
  message,
  className,
  children,
}: {
  severity: 'warning' | 'error'
  message: string
  className?: string
  children?: ReactNode
}) => {
  return (
    <div
      role="alert"
      aria-label="alert"
      className={cx(
        'flex flex-1 items-center gap-3 rounded-2xl py-2 px-4',
        severity === 'warning' ? 'bg-yellow-lightest' : 'bg-red-lightest',
        className,
      )}
    >
      <div>
        <Icon
          title="info-icon"
          icon="error-outline"
          className={cx(
            '!h-[20px] !w-[20px]',
            severity === 'warning' ? 'text-yellow' : 'text-red',
          )}
        />{' '}
      </div>
      <div>
        <p
          aria-label="message"
          className={cx(
            'flex-1 text-lg font-bold',
            severity === 'warning' ? 'text-yellow-darkest' : 'text-red-darkest',
          )}
        >
          {message}
        </p>
        {children}
      </div>
    </div>
  )
}

export default Alert
