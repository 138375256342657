import React from 'react'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'

type Props = {
  handleArchive: () => void
}

export default function Archive({ handleArchive }: Props) {
  const handleClick = () => {
    handleArchive()
  }

  return (
    <button
      aria-label="archive"
      type="button"
      onClick={handleClick}
      className="p-4 hover:text-red"
    >
      <DeleteForeverOutlinedIcon />
    </button>
  )
}
