import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Fetch } from 'croods'
import Skeleton from '@material-ui/lab/Skeleton'
import FeedbackMessage from 'shared/ui/FeedbackMessage'

import EvaluatedTeam from './teamEvaluationNotice/EvaluatedTeam'

type Props = {
  previousEvaluationId: number
}

export default function SimpleAccordion({ previousEvaluationId }: Props) {
  return (
    <Fetch
      name="teamEvaluations"
      id={previousEvaluationId}
      render={(teamEvaluations) => (
        <div className="mt-6 rounded-2xl">
          <Accordion
            square
            className="rounded-2xl"
            TransitionProps={{
              onEntered: () =>
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: 'smooth',
                }),
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              id="teste"
              className="mt-10"
              classes={{ content: 'justify-center' }}
            >
              <p className="font-semibold body1 text-blue">
                Histórico de Avaliação de Performance
              </p>
            </AccordionSummary>
            <AccordionDetails className="flex flex-col">
              <EvaluatedTeam team={teamEvaluations} />
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      renderLoading={() => <Skeleton />}
      renderError={(error) => (
        <FeedbackMessage message={error} serverMessage="" />
      )}
    />
  )
}
