import * as React from 'react'
import Icon from 'shared/ui/icon'
import { match, P } from 'ts-pattern'
import { LightTooltip } from 'shared/ui/Tooltip/InfoTooltip'

import { useCroods } from 'croods'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { CreateEventModal } from './Collaborator'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

import { createHumps, cx, renderIf } from 'shared/helpers'

import { JustificationModal } from './JustificationModal'
import { ConfirmationModal } from './ConfirmationModal'

import {
  colors,
  formatNumberOfDays,
  getColorByStatus,
  getEventLabelMessage,
  periodSchema,
  vacationDaysSoldSchema,
} from 'portal/vacationsHelpers'
import { useVacationManagement } from '../context'
import { endDate } from 'portal/vacationsHelpers'
import { UndoModal } from './UndoModal'

import type { CalendarApi, EventApi, EventInput } from '@fullcalendar/core'
import type { QueryStringObj } from 'croods'

function DraftIcon({
  edited,
  warningsCount,
  errorsCount,
}: {
  edited: boolean
  warningsCount: number
  errorsCount: number
}) {
  const eventColor = getColorByStatus(warningsCount, errorsCount)

  return (
    <div
      data-testid="event-label-status-icon"
      className={cx(
        'flex h-14 w-14 grow-0 flex-col items-center justify-center rounded-l-2xl p-2',
        colors[eventColor].bgIcon,
      )}
    >
      {match([warningsCount, errorsCount])
        .with([P._, P.when((errors) => errors > 0)], () => (
          <Icon
            icon="error-outline"
            className="fill-current text-red-dark"
            title="Erros"
            size="lg"
          />
        ))
        .with([P.when((warnings) => warnings > 0), 0], () => (
          <Icon
            icon="error-outline"
            className="fill-current text-yellow-dark"
            title="Avisos"
            size="lg"
          />
        ))
        .otherwise(() => (
          <Icon
            icon="calendar-today-outline"
            className="fill-current text-gray-dark"
            title="Rascunho"
            size="lg"
          />
        ))}
      {renderIf(
        edited,
        <p className={cx('text-xs', colors[eventColor].iconText)}>Alterado</p>,
      )}
    </div>
  )
}

type Props = {
  event: EventApi
  selectedEventIdToConfirm: string
  onSelectedEventIdToConfirm: (eventId: string) => void
  reload: (afterReload?: () => void) => void
  calendarApi: CalendarApi | undefined
}

export default function ActionBar({
  event,
  reload,
  selectedEventIdToConfirm,
  onSelectedEventIdToConfirm,
  calendarApi,
}: Props) {
  const { snackbar } = useSnackbar()
  const { events, dispatch } = useVacationManagement()
  const [{ destroying, saving }, { destroy, save }] = useCroods({
    name: 'vacationsDestroy',
  })
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    React.useState(false)
  const [isJustificationModalOpen, setIsJustificationModalOpen] =
    React.useState(false)
  const [isUndoModalOpen, setIsUndoModalOpen] = React.useState(false)
  const [undoingEventId, setUndoingEventId] = React.useState(
    null as string | null,
  )

  const DeleteButton = ({
    onClick,
  }: {
    onClick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  }) => {
    return (
      <LightTooltip
        title={<TooltipShortMessage message="Excluir período" />}
        enterTouchDelay={100}
        placement="top-start"
        arrow={true}
      >
        <button
          className="flex border-none bg-transparent text-gray-dark"
          data-testid="delete-button"
          onClick={(e) => {
            e.stopPropagation()
            onSelectedEventIdToConfirm(event.id)
            onClick(e)
          }}
        >
          <Icon
            icon={
              destroying && event.id === selectedEventIdToConfirm
                ? 'spinner'
                : 'delete-forever-outline'
            }
            size="md"
            className={cx(
              destroying && event.id === selectedEventIdToConfirm
                ? 'animate-spin cursor-default hover:text-gray-dark'
                : 'cursor-pointer hover:text-red',
            )}
          />
        </button>
      </LightTooltip>
    )
  }

  const handleDelete = (formData?: unknown) => () => {
    setIsJustificationModalOpen(false)

    destroy({
      method: 'delete',
      id: event.id,
      path: 'workflow/vacation_management',
      afterSuccess: () => {
        dispatch({
          type: 'UPDATE_EVENT',
          payload: { event: event as EventInput, shouldDelete: true },
        })
        snackbar({
          message: 'Evento de férias removido com sucesso',
          type: 'success',
        })
      },
      afterFailure: () => {
        reload()
        snackbar({
          message: 'Não foi possível excluir o evento de férias',
          type: 'error',
        })
      },
    })(formData as QueryStringObj)
  }

  const handleUndoModal = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    ev.stopPropagation()

    setIsUndoModalOpen(true)
  }

  const handleUndo = async () => {
    setIsUndoModalOpen(false)

    await save({
      method: 'post',
      id: event.id,
      customPath: 'workflow/vacation_management/:id/undo',
      afterSuccess: ({ data }) => {
        dispatch({
          type: 'UPDATE_EVENT_FROM_API',
          payload: {
            event: data,
          },
        })
        reload(() => {
          calendarApi?.gotoDate(data.startDate)
        })
        snackbar({
          message: 'Alterações descartadas',
          type: 'success',
        })
      },
      afterFailure: () => {
        setUndoingEventId(null)
        snackbar({
          message:
            'Não foi possível descartar as alterações do evento de férias, tente novamente',
          type: 'error',
          timeout: 6,
        })
      },
    })()
  }

  const handleConfirmModal = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    ev.stopPropagation()

    setIsConfirmationModalOpen(true)
  }

  const handleConfirm = async () => {
    setIsConfirmationModalOpen(false)

    const dataToSave = {
      advanceThirteenth: event.extendedProps.advanceThirteenth,
      vacationDaysSold: event.extendedProps.vacationDaysSold,
      vacationDays: event.extendedProps.vacationDays,
      id: event.id,
      startDate: event.extendedProps.startDate,
      confirm: true,
    }

    await save({
      method: 'put',
      id: event.id,
      path: 'workflow/vacation_management',
      afterSuccess: () => {
        reload()
        snackbar({
          message: 'Evento de férias confirmado com sucesso',
          type: 'success',
        })
      },
      afterFailure: ({ response }) => {
        let failureMessage

        if (response?.data) {
          dispatch({
            type: 'UPDATE_EVENT',
            payload: { event: createHumps(response.data) },
          })
        }

        if (response?.data.confirmation_error) {
          failureMessage = `Não foi possível confirmar o evento de férias: ${response?.data.confirmation_error}`
        }

        reload()

        snackbar({
          message:
            failureMessage ?? 'Não foi possível confirmar o evento de férias',
          type: 'error',
          timeout: 6,
        })
      },
    })(dataToSave)
  }

  const getEventInfo = (event: EventApi) => {
    const selectedResource = event.getResources()[0]

    const [startYear, startMonth, startDay] = event.extendedProps.startDate
      .split('-')
      .map(Number)

    const [endYear, endMonth, endDay] = event.extendedProps.endDate
      .split('-')
      .map(Number)

    return {
      avatar: selectedResource.extendedProps.avatar,
      firstName: selectedResource.extendedProps.firstName,
      lastName: selectedResource.extendedProps.lastName,
      email: selectedResource.extendedProps.email,
      role: selectedResource.extendedProps.role,
      id: selectedResource.id,
      openingBalance: selectedResource.extendedProps.openingBalance,
      dateLimit: selectedResource.extendedProps.dateLimit,
      expiresAt: selectedResource.extendedProps.expiresAt,
      currentBalance: selectedResource.extendedProps.currentBalance,
      admissionDate: selectedResource.extendedProps.admissionDate,
      hasCredential: selectedResource.extendedProps.hasCredential,
      recentlyAdded: selectedResource.extendedProps.recentlyAdded,
      startDate: new Date(startYear, startMonth - 1, startDay),
      endDate: new Date(endYear, endMonth - 1, endDay),
      vacationDays: event.extendedProps.vacationDays.toString(),
      vacationDaysSold: event.extendedProps.vacationDaysSold.toString(),
      advanceThirteenth: event.extendedProps.advanceThirteenth,
      vacationId: Number(event.id),
      confirmationError: event.extendedProps.confirmationError,
    }
  }

  const getEventWarningsList = (event: EventApi) => {
    const eventInfo = getEventInfo(event)

    const openingBalance = Number(eventInfo.openingBalance ?? 0)

    const remainingBalance =
      openingBalance -
      Number(eventInfo.vacationDays ?? 0) -
      Number(eventInfo.vacationDaysSold ?? 0)

    const periodSchemaWarnings = periodSchema(
      remainingBalance,
      eventInfo,
      events,
    ).safeParse(eventInfo)

    const periodSchemaWarningsList = periodSchemaWarnings.success
      ? []
      : periodSchemaWarnings.error.errors

    const vacationDaysSoldSchemaWarnings =
      vacationDaysSoldSchema().safeParse(eventInfo)

    const vacationDaysSoldSchemaWarningsList =
      vacationDaysSoldSchemaWarnings.success
        ? []
        : vacationDaysSoldSchemaWarnings.error.errors

    return periodSchemaWarningsList.concat(vacationDaysSoldSchemaWarningsList)
  }

  const TooltipShortMessage = ({ message }: { message: string }) => (
    <div className="text-left">
      <h6 className="max-w-[10.5rem] text-sm font-light text-gray-darkest">
        {message}
      </h6>
    </div>
  )

  const buttonsPadding = match(event.extendedProps.vacationDays)
    .with(5, () => 'pl-5')
    .with(6, () => 'pl-12')
    .otherwise(() => '')

  const UndoButton = () => (
    <LightTooltip
      title={<TooltipShortMessage message="Desfazer alterações" />}
      enterTouchDelay={100}
      placement="top-start"
      arrow={true}
    >
      <button
        className="flex border-none bg-transparent text-gray-dark"
        data-testid="undo-button"
        onClick={(e) => {
          handleUndoModal(e)
          setUndoingEventId(event.id)
        }}
      >
        <Icon
          icon={saving && event.id === undoingEventId ? 'spinner' : 'undo'}
          size="md"
          className={cx(
            saving && event.id === undoingEventId
              ? 'animate-spin cursor-default hover:text-gray-dark'
              : 'cursor-pointer hover:text-yellow',
          )}
          // @ts-ignore
          id={event.id}
        />
      </button>
    </LightTooltip>
  )
  const ConfirmButton = () => (
    <LightTooltip
      title={<TooltipShortMessage message="Confirmar período" />}
      enterTouchDelay={100}
      placement="top-start"
      arrow={true}
    >
      <button
        className="flex border-none bg-transparent text-gray-dark"
        data-testid="confirm-button"
        disabled={event.extendedProps.confirmationError}
        onClick={(e) => {
          onSelectedEventIdToConfirm(event.id)
          handleConfirmModal(e)
        }}
      >
        <Icon
          icon={
            saving && event.id === selectedEventIdToConfirm
              ? 'spinner'
              : 'check-circle-outline'
          }
          size="md"
          className={cx(
            saving && event.id === selectedEventIdToConfirm
              ? 'animate-spin cursor-default hover:text-gray-dark'
              : '',
            event.extendedProps.confirmationError
              ? 'cursor-not-allowed'
              : 'cursor-pointer hover:text-green',
          )}
          // @ts-ignore
          id={event.id}
        />
      </button>
    </LightTooltip>
  )

  const EditButton = () => (
    <LightTooltip
      title={<TooltipShortMessage message="Editar período" />}
      enterTouchDelay={100}
      placement="right"
      arrow={true}
    >
      <button
        type="button"
        data-testid="edit-button"
        onClick={() => setIsModalOpen(true)}
      >
        <Icon icon="edit" size="sm" className="mr-1 hover:text-blue" />
      </button>
    </LightTooltip>
  )

  const SolicitationChip = ({
    borderColor,
    textColor,
  }: {
    borderColor: string
    textColor: string
  }) => {
    const solicitationVacationPeriod = event.extendedProps.solicitation
      ?.prefixedValues
      ? `${format(
          parseISO(event.extendedProps.solicitation.prefixedValues.startDate),
          'dd/MM/yyyy',
        )} a ${format(
          endDate(
            parseISO(event.extendedProps.solicitation.prefixedValues.startDate),
            event.extendedProps.solicitation.prefixedValues.vacationDays,
          ),
          'dd/MM/yyyy',
        )}`
      : ''

    return (
      <LightTooltip
        title={
          <TooltipShortMessage
            message={`Férias solicitadas pelo colaborador de ${solicitationVacationPeriod}`}
          />
        }
        enterTouchDelay={100}
        placement="bottom-start"
        arrow={true}
      >
        <div
          className={cx(
            'mb-1 flex h-6 w-16 items-center justify-center self-start overflow-visible rounded-2xl border text-xs font-normal',
            borderColor,
            textColor,
          )}
        >
          Solicitado
        </div>
      </LightTooltip>
    )
  }

  const vacationPeriod = `${format(
    parseISO(event.extendedProps.startDate),
    'dd/MM/yyyy',
  )} - ${format(parseISO(event.extendedProps.endDate), 'dd/MM/yyyy')}`

  const ConfirmedVacation = ({
    vacationInfo,
  }: {
    vacationInfo: {
      vacationDays: string
      status: 'confirmed'
      isSolicited: boolean
    }
  }) => (
    <LightTooltip
      title={<TooltipShortMessage message={vacationPeriod} />}
      enterTouchDelay={100}
      placement="top-start"
      arrow={true}
    >
      <section
        className={cx(
          'mt-1 flex w-full cursor-default items-center rounded-2xl shadow-md outline outline-1',
          colors['green'].bg,
          colors['green'].border,
        )}
      >
        <div
          data-testid="event-label-status-icon"
          className={cx(
            'flex grow-0 items-center justify-center rounded-l-2xl bg-opacity-100 p-3',
            colors['green'].bgIcon,
          )}
        >
          <Icon
            icon="check-circle-outline"
            className="fill-current text-green-dark"
            title="Férias confirmadas"
            size="lg"
          />
        </div>

        <div
          className={cx(
            'flex !h-full flex-1 items-center justify-between rounded-r-2xl px-2',
            colors['green'].bg,
          )}
        >
          <div className="flex h-full flex-col justify-center">
            <div className="flex flex-row items-center gap-2">
              <div
                className={cx(
                  'mb-1 flex overflow-hidden text-ellipsis whitespace-nowrap text-base font-bold',
                  colors['green'].textBold,
                )}
              >
                <span className={cx('text-lg', colors['green'].textBold)}>
                  {formatNumberOfDays(Number(vacationInfo.vacationDays))}
                </span>
              </div>

              {renderIf(
                vacationInfo.isSolicited,
                <SolicitationChip
                  borderColor={colors['green'].chipBorder}
                  textColor={colors['green'].textBold}
                />,
              )}
            </div>

            <span className={cx('text-xs', colors['green'].text)}>
              Férias confirmadas
            </span>
          </div>
        </div>
      </section>
    </LightTooltip>
  )

  const DraftVacation = ({
    vacationInfo,
  }: {
    vacationInfo: {
      vacationDays: string
      edited: boolean
      status: 'draft'
      isSolicited: boolean
    }
  }) => {
    const warningsCount = getEventWarningsList(event).length
    const errorsCount = event.extendedProps.confirmationError ? 1 : 0
    const eventColor = getColorByStatus(warningsCount, errorsCount)

    const TooltipMessage = () => (
      <div className="p-2 text-left">
        {errorsCount > 0 && (
          <h6 className="mb-2 text-sm font-light text-gray-darkest">
            {event.extendedProps.confirmationError}
          </h6>
        )}

        {warningsCount > 0 && errorsCount === 0 && (
          <div>
            {getEventWarningsList(event).map((warning: any, index) => (
              <h6
                key={`warning-${index}`}
                className="mb-2 text-sm font-light text-gray-darkest"
              >
                {warning.message}
              </h6>
            ))}
          </div>
        )}
      </div>
    )

    return (
      <LightTooltip
        title={<TooltipShortMessage message={vacationPeriod} />}
        enterTouchDelay={100}
        placement="top-start"
        arrow={true}
      >
        <section
          onClick={() => setIsModalOpen(true)}
          className={cx(
            'mt-1 flex w-full cursor-pointer items-center rounded-2xl shadow-md outline outline-1',
            colors[eventColor].bg,
            colors[eventColor].border,
          )}
        >
          <DraftIcon
            edited={vacationInfo.edited}
            warningsCount={warningsCount}
            errorsCount={errorsCount}
          />
          <div
            className={cx(
              'flex !h-full flex-1 items-center gap-2 rounded-r-2xl px-2',
              colors[eventColor].bg,
            )}
          >
            <div className="flex h-full flex-col justify-center">
              <div className="flex flex-row items-center justify-center gap-2">
                <div
                  className={cx(
                    'mb-1 flex overflow-hidden text-ellipsis whitespace-nowrap text-base font-bold',
                    colors[eventColor].textBold,
                  )}
                >
                  <EditButton />
                  <span className={cx('text-lg', colors[eventColor].textBold)}>
                    {formatNumberOfDays(Number(vacationInfo.vacationDays))}
                  </span>
                </div>

                {renderIf(
                  vacationInfo.isSolicited,
                  <SolicitationChip
                    borderColor={colors[eventColor].chipBorder}
                    textColor={colors[eventColor].text}
                  />,
                )}
              </div>

              {errorsCount > 0 || warningsCount > 0 ? (
                <LightTooltip
                  title={<TooltipMessage />}
                  enterTouchDelay={100}
                  placement="right-start"
                  arrow={true}
                >
                  <span className={cx('text-xs', colors[eventColor].text)}>
                    {getEventLabelMessage('draft', warningsCount, errorsCount)}
                  </span>
                </LightTooltip>
              ) : (
                <span className={cx('text-xs', colors[eventColor].text)}>
                  Rascunho
                </span>
              )}
            </div>
          </div>

          <div className={cx('flex items-center gap-4 pr-6', buttonsPadding)}>
            {renderIf(vacationInfo.edited, <UndoButton />)}
            <ConfirmButton />
            <DeleteButton
              onClick={
                vacationInfo.isSolicited
                  ? () => {
                      setIsJustificationModalOpen(true)
                    }
                  : handleDelete()
              }
            />
          </div>
        </section>
      </LightTooltip>
    )
  }

  const eventInfo = getEventInfo(event)

  return (
    <>
      {event.extendedProps.status === 'confirmed' ? (
        <ConfirmedVacation
          vacationInfo={{
            vacationDays: event.extendedProps.vacationDays,
            status: event.extendedProps.status,
            isSolicited: Boolean(event.extendedProps.solicitation),
          }}
        />
      ) : (
        <DraftVacation
          vacationInfo={{
            vacationDays: event.extendedProps.vacationDays,
            edited: event.extendedProps.edited,
            status: event.extendedProps.status,
            isSolicited: Boolean(event.extendedProps.solicitation),
          }}
        />
      )}

      <CreateEventModal
        data={eventInfo}
        isOpen={isModalOpen}
        reload={reload}
        onClose={() => setIsModalOpen(false)}
        calendarApi={calendarApi}
      />

      <ConfirmationModal
        data={eventInfo}
        handleConfirm={handleConfirm}
        isOpen={isConfirmationModalOpen}
        loading={saving}
        onClose={() => setIsConfirmationModalOpen(false)}
      />

      <JustificationModal
        handleDelete={handleDelete}
        isOpen={isJustificationModalOpen}
        loading={saving}
        onClose={() => setIsJustificationModalOpen(false)}
      />

      <UndoModal
        handleUndo={handleUndo}
        isOpen={isUndoModalOpen}
        loading={saving}
        onClose={() => setIsUndoModalOpen(false)}
      />
    </>
  )
}
