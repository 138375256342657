import * as React from 'react'
import Section from './section'
import ConversationFrequency from './charts/conversation-frequency'
import UpwardEvaluation from './charts/upward-evaluation'
import SelfEvaluation from './charts/self-evaluation'
import ContributionLevel from './charts/contribution-level'
import TeamPerformance from './tables/team-performance'
import TeamDevelopmentSupport from './tables/team-development-support'
import TeamEvaluations from './charts/team-evaluations'
import TotalRetention from './charts/total-retention'
import TalentRetention from './charts/talent-retention'
import NineBox from 'performance/evaluations/membersEvaluation/nineBox'
import { Fetch } from 'croods'
import TwelveBox from './twelve-box'
import { TeamInsightsHeader } from './header'
import Switcher from 'shared/ui/Switcher'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import { chartPath } from './chart-helpers'
import { ConversationPanel } from './conversation-panel'

type FilterState = 'CÍRCULOS CONTIDOS' | 'SOMENTE ESTE CÍRCULO'

const TeamInsights = ({ pageTitle }: { pageTitle: string }) => {
  const teamId = useParams().teamId!
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [showSwitcher, setShowSwitcher] = React.useState<boolean>(false)
  const [showConversationFrequency, setShowConversationFrequency] =
    React.useState<boolean>(false)
  const [showUpwardEvaluation, setShowUpwardEvaluation] =
    React.useState<boolean>(false)
  const [showTeamDevelopmentSupport, setShowTeamDevelopmentSupport] =
    React.useState<boolean>(false)
  const [showConversationPanel, setShowConversationPanel] =
    React.useState<boolean>(false)

  const [showPerformanceSection, setShowPerformanceSection] =
    React.useState<boolean>(false)
  const [showTalentSection, setShowTalentSection] =
    React.useState<boolean>(false)

  const [defaultActive, setDefaultActive] =
    React.useState<FilterState>('CÍRCULOS CONTIDOS')

  const availableFilters: FilterState[] = [
    'CÍRCULOS CONTIDOS',
    'SOMENTE ESTE CÍRCULO',
  ]

  const innerCircles = searchParams.get('inner_circle')

  const active: FilterState =
    innerCircles === 'true'
      ? 'CÍRCULOS CONTIDOS'
      : innerCircles === 'false'
      ? 'SOMENTE ESTE CÍRCULO'
      : defaultActive

  const query = React.useMemo(
    () =>
      active == 'CÍRCULOS CONTIDOS'
        ? {
            inner_circle: true,
            team_id: teamId,
          }
        : {
            inner_circle: false,
            team_id: teamId,
          },
    [active, teamId],
  )

  const handleSwitcherClick = (activeState: FilterState) => {
    navigate(
      `?inner_circle=${(activeState === 'CÍRCULOS CONTIDOS').toString()}`,
    )
  }

  const [errorMessage, setErrorMessage] = React.useState<string>('')

  return (
    <div>
      <div className="flex justify-between">
        <h3>{pageTitle}</h3>
        <div className="flex">
          {showSwitcher && (
            <Switcher
              active={active}
              setActive={handleSwitcherClick}
              options={availableFilters.map((title) => ({ title }))}
            />
          )}
        </div>
      </div>
      <TeamInsightsHeader
        query={query}
        setErrorMessage={setErrorMessage}
        setShowSwitcher={setShowSwitcher}
        setDefaultActive={setDefaultActive}
        setShowConversationFrequency={setShowConversationFrequency}
        setShowPerformanceSection={setShowPerformanceSection}
        setShowTalentSection={setShowTalentSection}
        setShowUpwardEvaluation={setShowUpwardEvaluation}
        setShowTeamDevelopmentSupport={setShowTeamDevelopmentSupport}
        setShowConversationPanel={setShowConversationPanel}
      />

      {errorMessage ? (
        <div className="mt-6">
          <FeedbackMessage
            message={errorMessage}
            type="warning"
            serverMessage=""
          />
        </div>
      ) : (
        <>
          {showConversationPanel && (
            <ConversationPanel
              query={query}
              stateId={`currentCycleConversations-${query.team_id}-${query.inner_circle}`}
            ></ConversationPanel>
          )}
          {(showConversationFrequency || showUpwardEvaluation) && (
            <Section
              section="Experiência de Desenvolvimento Pessoal e Profissional"
              title="Estamos proporcionando uma boa experiência de desenvolvimento pessoal e profissional?"
            >
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {showConversationFrequency && (
                  <ConversationFrequency
                    query={query}
                    stateId={`conversationFrequency-${query.team_id}-${query.inner_circle}`}
                  />
                )}
                {showUpwardEvaluation && (
                  <UpwardEvaluation
                    query={query}
                    stateId={`upwardEvaluation-${query.team_id}-${query.inner_circle}`}
                  />
                )}
              </div>
              <div className="mt-5">
                <TeamPerformance
                  query={query}
                  stateId={`teamPerformance-${query.team_id}-${query.inner_circle}`}
                  showUpwardEvaluation={showUpwardEvaluation}
                  showConversationFrequency={showConversationFrequency}
                />
              </div>
            </Section>
          )}
          {showTeamDevelopmentSupport && (
            <Section
              section="Desenvolvimento dos Liderados"
              title="Estou conseguindo apoiar os meus liderados em busca do autodesenvolvimento deles?"
            >
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <SelfEvaluation
                  query={query}
                  stateId={`selfEvaluation-${query.team_id}-${query.inner_circle}`}
                />
                <ContributionLevel
                  query={query}
                  stateId={`contributionLevel-${query.team_id}-${query.inner_circle}`}
                />
              </div>
              <div className="mt-5">
                <TeamDevelopmentSupport
                  query={query}
                  stateId={`teamDevelopmentSupport-${query.team_id}-${query.inner_circle}`}
                />
              </div>
            </Section>
          )}

          {showPerformanceSection && (
            <Section
              section="Performance"
              title="Como os meus círculos estão performando?"
            >
              {query.inner_circle ? (
                <>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <TeamEvaluations
                      query={query}
                      stateId={`teamEvaluations-${query.team_id}-${query.inner_circle}`}
                    />
                  </div>
                  <div className="mt-10">
                    <TwelveBox
                      query={query}
                      stateId={`twelveBox-${query.team_id}-${query.inner_circle}`}
                    />
                  </div>
                </>
              ) : (
                <TeamEvaluations
                  query={query}
                  stateId={`teamEvaluations-${query.team_id}-${query.inner_circle}`}
                />
              )}
            </Section>
          )}
          {showTalentSection && (
            <Section
              section="Reconhecimento e Retenção"
              title="Quem são os talentos dos meus círculos e eles estão sendo adequadamente reconhecidos e retidos?"
            >
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <TotalRetention
                  query={query}
                  stateId={`totalRetention-${query.team_id}-${query.inner_circle}`}
                />
                <TalentRetention
                  query={query}
                  stateId={`talentRetention-${query.team_id}-${query.inner_circle}`}
                />
              </div>
              <div className="mt-10">
                <Fetch
                  name="memberEvaluationsNineBox"
                  path={chartPath('recursive_nine_box', query)}
                  query={query}
                  stateId={`memberEvaluationsNineBox-${query.team_id}-${query.inner_circle}`}
                  render={({
                    boxes,
                    organizationCycleStartsAt,
                    organizationCycleFinishesAt,
                  }) => {
                    if (!boxes) return null

                    return (
                      <NineBox
                        boxes={boxes}
                        cycle={{
                          organizationCycleStartsAt,
                          organizationCycleFinishesAt,
                        }}
                      />
                    )
                  }}
                />
              </div>
            </Section>
          )}
        </>
      )}
    </div>
  )
}

export type { FilterState }

export default TeamInsights
