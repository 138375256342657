import React from 'react'
import HomeIcon from '@material-ui/icons/Home'
import Modal from 'shared/ui/Modal'

export default function Success() {
  return (
    <>
      <Modal.Header>Avaliação de Performance concluída!</Modal.Header>
      <Modal.Content>
        <div className="mt-10 flex flex-col items-center">
          <img alt="finish" src="/finish.png" className="h-32 w-32" />
          <h6>Uhuuul!</h6>
        </div>
      </Modal.Content>
      <Modal.Actions hideCancel>
        <Modal.Confirm>
          <HomeIcon /> IR PARA HOME
        </Modal.Confirm>
      </Modal.Actions>
    </>
  )
}
