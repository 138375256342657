import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import type { TextFieldProps } from '@material-ui/core/TextField'
import TextField from '@material-ui/core/TextField'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'

import type { Role } from 'performance/evaluations/membersEvaluation/types'

type Props = {
  allRoles: Role[]
  role: Role | null
  handleAddNewRole: (
    value: string,
    type: 'primaryRole' | 'secondaryRole',
  ) => void
  renderOption: (option: Role) => React.ReactNode
  setRole: (primaryRole: Role) => void
  textFieldProps?: TextFieldProps
  autocompleteProps?: {}
  getDisabledRoles: (role: Role) => boolean
}

const filter = createFilterOptions<Role>()

const PrimaryRole = ({
  allRoles,
  role,
  setRole,
  handleAddNewRole,
  renderOption,
  textFieldProps,
  autocompleteProps,
  getDisabledRoles,
}: Props) => {
  const [error, setError] = React.useState('')

  const handleChange = (_: React.ChangeEvent<{}>, value: Role | null) => {
    setError('')
    if (value?.inputValue) {
      if (value.inputValue.length <= 120) {
        handleAddNewRole(value.inputValue, 'primaryRole')
      } else {
        setError('O papel principal só pode conter 120 caracteres.')
      }
    } else if (value) {
      setRole(value)
    }
  }

  const handleFilter = (options: any, params: any) => {
    const filtered = filter(options, params)

    if (params.inputValue !== '') {
      filtered.push({
        id: 0,
        inputValue: params.inputValue,
        name: params.inputValue,
      })
    }

    return filtered
  }

  const getOptionSelected = (option: Role | null, value: Role | null) =>
    option?.id === value?.id

  return (
    <FormControl fullWidth={true}>
      <Autocomplete
        disablePortal
        options={allRoles}
        noOptionsText="Papel não encontrado"
        disableClearable
        onChange={handleChange}
        value={role!}
        renderOption={renderOption}
        getOptionSelected={getOptionSelected}
        getOptionLabel={(option) => option.name}
        filterOptions={handleFilter}
        getOptionDisabled={getDisabledRoles}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={'Selecione um Papel principal'}
            variant="standard"
            {...textFieldProps}
          />
        )}
        data-testid="primaryRole"
        {...autocompleteProps}
      />
      {error && <span className="my-1 text-red">{error}</span>}
    </FormControl>
  )
}

export default PrimaryRole
