import * as React from 'react'
import { useCroods } from 'croods'
import { useFormContext } from 'react-hook-form'

import Icon from 'shared/ui/icon'
import Chip from 'shared/ui/chip'
import Modal from 'shared/ui/Modal'
import Avatar from 'shared/ui/Avatar'
import Input from 'shared/formsv2/input'
import { getFullName } from 'shared/helpers'
import Button from 'shared/forms/SubmitButton'
import DateInput from 'shared/formsv2/date-input'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import camelCase from 'lodash/camelCase'

import addDays from 'date-fns/addDays'
import differenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'
import isFuture from 'date-fns/isFuture'
import parseISO from 'date-fns/parseISO'

import {
  formatNumberOfDays,
  FormContextWarnings,
  periodSchema,
  schema,
  vacationDaysSoldSchema,
} from 'portal/vacationsHelpers'
import InfoTooltip from 'shared/ui/Tooltip/InfoTooltip'
import { useVacationManagement } from '../context'

import type { CalendarApi } from '@fullcalendar/react'
import type { CollaboratorType } from '../types'

type Props = {
  data: CollaboratorType
  reload: () => void
  calendarApi: CalendarApi | undefined
}

type ModalProps = {
  data: Props['data']
  isOpen: boolean
  onClose: () => void
  reload: () => void
  calendarApi: CalendarApi | undefined
}

const Collaborator = ({ data, reload, calendarApi }: Props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const user = {
    avatar: data.avatar,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    hasCredential: data.hasCredential,
    recentlyAdded: data.recentlyAdded,
  }

  const expiresAt = parseISO(data.expiresAt)
  const dateLimit = parseISO(data.dateLimit)
  const diff = differenceInDays(dateLimit, new Date())
  const isVisible = !isNaN(diff)
  const sentenceColor = isVisible
    ? diff <= 30
      ? '!text-red-dark'
      : diff <= 60
      ? '!text-yellow-dark'
      : '!text-gray-dark'
    : 'hidden'

  const bgChipColor = isVisible
    ? diff <= 30
      ? '!bg-red-lightest'
      : diff <= 60
      ? '!bg-yellow-lightest'
      : '!bg-gray-lightest'
    : 'hidden'

  const textChipColor = isVisible
    ? diff <= 30
      ? '!text-red-darkest'
      : diff <= 60
      ? '!text-yellow-darkest'
      : '!text-gray-darkest'
    : 'hidden'

  return (
    <>
      <div className="-mt-3 flex !h-full !w-full flex-1 items-start gap-2">
        <div className="mt-2">
          <Avatar user={user} size={32} />
        </div>
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex items-center gap-3">
            <h3
              aria-label="user-name"
              className="w-24 overflow-clip text-ellipsis text-base"
            >
              {getFullName(user)}
            </h3>
            <Chip
              className={`lowercase ${bgChipColor} !px-3 !py-1 ${textChipColor}`}
            >
              {formatNumberOfDays(data.openingBalance)}
            </Chip>
            <button
              type="button"
              onClick={() => setIsModalOpen(true)}
              className="ml-auto flex items-center gap-2 rounded border border-blue px-2 py-1 text-lg font-bold uppercase text-blue transition-colors duration-300 hover:bg-slate-100 disabled:cursor-not-allowed"
            >
              <Icon icon="add-circle-outline" size="md" />
              FÉRIAS
            </button>
          </div>
          {data.role && (
            <span
              title={data.role}
              className="-mt-3 w-24 overflow-clip text-ellipsis text-sm font-bold uppercase text-gray-dark"
            >
              {data.role}
            </span>
          )}
          <div
            className={`-mt-1 flex flex-1 items-center gap-2 ${
              isVisible ? '' : 'hidden'
            }`}
          >
            {data.openingBalance > 0 ? (
              <>
                <p className={`text-sm font-bold ${sentenceColor}`}>
                  {isVisible && dateLimit
                    ? `${
                        isFuture(dateLimit)
                          ? 'Iniciar férias até'
                          : 'Em atraso desde'
                      } ${format(dateLimit, 'dd/MM/yyyy')}`
                    : ''}
                </p>

                <InfoTooltip fontSize="small" htmlColor="#969696">
                  <div className="text-md font-semibold">
                    <div>
                      <span className={'text-gray-darkest'}>
                        {isVisible && dateLimit
                          ? `Data limite: ${format(dateLimit, 'dd/MM/yyyy')}`
                          : ''}
                      </span>
                    </div>
                    <div>
                      <span className="text-gray-darkest">
                        {isVisible && expiresAt
                          ? `Vencimento: ${format(expiresAt, 'dd/MM/yyyy')}`
                          : ''}
                      </span>
                    </div>
                    <div>
                      <br />
                      <span className="text-gray-darkest">
                        {data.currentBalance}{' '}
                        {Math.abs(data.currentBalance) === 1
                          ? 'dia deve ser usufruído até'
                          : 'dias devem ser usufruídos até'}{' '}
                        {isVisible ? format(expiresAt, 'dd/MM/yyyy') : ''}
                      </span>
                    </div>
                  </div>
                </InfoTooltip>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <CreateEventModal
        data={data}
        reload={reload}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        calendarApi={calendarApi}
      />
    </>
  )
}

const ModalContent = ({ data }: { data: Props['data'] }) => {
  const { events } = useVacationManagement()
  const { watch } = useFormContext()
  const formData = watch()

  const initialStartDate =
    typeof formData.startDate == 'string' && formData.startDate.length === 10
      ? formData.startDate
      : format(data.startDate ?? new Date(), 'dd/MM/yyyy')

  const [startDay, startMonth, startYear] = initialStartDate
    .split('/')
    .map(Number)

  const startDate = new Date(startYear, startMonth - 1, startDay)

  const draftEventsVacationDaysAndSoldDays = events
    .filter(({ resourceId }) => String(resourceId) === String(data.id))
    .reduce(
      (total: number, current: any) =>
        current.extendedProps.status === 'draft' &&
        String(current.id) !== String(data.vacationId)
          ? (total +=
              current.extendedProps.vacationDays +
              current.extendedProps.vacationDaysSold)
          : total,
      0,
    ) as number

  const remainingBalance =
    data.openingBalance -
    draftEventsVacationDaysAndSoldDays -
    Number(formData.vacationDays ?? 0) -
    Number(formData.vacationDaysSold ?? 0)

  const lastVacationDay = addDays(
    startDate,
    Number(formData.vacationDays) > 0 ? Number(formData.vacationDays) - 1 : 0,
  )

  const dateLimit = data.dateLimit ? parseISO(data.dateLimit) : ''

  return (
    <Modal.Content className="max-h-[60vh] !w-full overflow-y-auto !bg-white !bg-opacity-95 text-gray-darkest">
      <div className="mb-4 flex items-start gap-4">
        <Avatar user={data} size={48} />
        <div className="flex flex-1 flex-col gap-0.5">
          <h3 aria-label="user-name" className="text-xl">
            {getFullName(data)}
          </h3>
          {data.role && (
            <span className="text-base uppercase">{data.role}</span>
          )}
        </div>
      </div>

      <section aria-label="event-data" className="flex flex-col gap-7">
        <div>
          <label className="text-lg font-bold">Saldo inicial:</label>
          <span className="ml-3 mt-3 h-6 w-fit rounded-2xl bg-gray-lightest px-2 py-1 text-center text-base">
            {formatNumberOfDays(data.openingBalance)}
          </span>
          {dateLimit ? (
            <span className="ml-3 text-base">
              {isFuture(dateLimit) ? 'Vence em:' : 'Venceu em:'}{' '}
              {format(dateLimit, 'dd/MM/yyyy')}
            </span>
          ) : null}
        </div>

        <div className="flex flex-1 flex-col items-start gap-4 sm:flex-row">
          <DateInput
            name="startDate"
            label="Primeiro dia de férias"
            required
            wrapperClassname="!flex-1"
            defaultValue={
              data.startDate && format(data.startDate, 'dd/MM/yyyy')
            }
          />
          <div className="flex w-20 flex-1 flex-col">
            <Input
              name="vacationDays"
              label="Dias de férias"
              required
              type="number"
              min="1"
              defaultValue={data.vacationDays}
            />
          </div>
          <div className="flex flex-1 flex-col">
            <label className="text-lg font-bold">Último dia de férias</label>
            <p className="mt-3 h-6 w-fit rounded-2xl bg-gray-lightest p-1 px-2 text-center text-base">
              {format(lastVacationDay, 'dd/MM/yyyy')}
            </p>
          </div>
        </div>

        <FormContextWarnings
          schema={periodSchema(remainingBalance, data, events)}
        />

        <div>
          <label className="text-lg font-bold">Saldo restante:</label>
          <span className="ml-3 mt-3 h-6 w-fit rounded-2xl bg-gray-lightest px-2 py-1 text-center text-base">
            {formatNumberOfDays(remainingBalance)}
          </span>
        </div>

        <div className="flex flex-col">
          <Input
            className="mt-1 w-20"
            label="Dias de abono de férias"
            name="vacationDaysSold"
            type="number"
            min="0"
            required
            defaultValue={data.vacationDaysSold}
          />
        </div>

        <FormContextWarnings schema={vacationDaysSoldSchema()} />

        <div className="flex items-center gap-3">
          <Input
            type="checkbox"
            name="advanceThirteenth"
            defaultChecked={data.advanceThirteenth}
          />
          <label htmlFor="advanceThirteenth" className="label">
            Antecipar 13º salário
          </label>
        </div>

        {data.confirmationError ? (
          <div className="mt-2 flex w-fit items-center gap-2 rounded-2xl bg-red-lightest px-4 py-1 text-base font-bold text-red-darkest">
            <Icon
              icon="error-outline"
              className="!h-[22px] !w-[22px] text-red"
            />
            {data.confirmationError}
          </div>
        ) : null}
      </section>
    </Modal.Content>
  )
}

export const CreateEventModal = ({
  isOpen,
  onClose,
  data,
  reload,
  calendarApi,
}: ModalProps) => {
  const { dispatch } = useVacationManagement()
  const { snackbar } = useSnackbar()
  const [{ saving }, { save }] = useCroods({
    id: data.vacationId,
    name: 'vacation',
    path: '/workflow/vacation_management',
    afterSuccess: (response) => {
      let successMessage = 'Evento salvo como rascunho.'

      if (response.data.status === 'confirmed') {
        successMessage = 'Evento confirmado com sucesso.'
        reload()
      } else
        dispatch({
          type: 'UPDATE_EVENT',
          payload: { event: { ...response.data, resourceId: data.id } },
        })

      const [, month, day] = response.data.startDate?.split('-').map(Number)
      calendarApi?.gotoDate(parseISO(response.data.startDate))
      calendarApi?.scrollToTime({ day: day - 2, month: month - 1 })

      snackbar({ message: successMessage, type: 'success' })
    },
    afterFailure: ({ response }) => {
      let errorMessage

      if (response?.data?.user_id) {
        let formattedData = {}

        Object.keys(response.data).map((key) => {
          formattedData = {
            ...formattedData,
            [camelCase(key)]: response.data[key],
          }
        })

        if (response?.data.confirmation_error) {
          errorMessage = `Evento salvo como rascunho, porém não foi possível confirmar: ${response?.data.confirmation_error}`
        } else {
          errorMessage =
            'Evento salvo como rascunho, porém não foi possível confirmar.'
        }

        dispatch({
          type: 'UPDATE_EVENT',
          payload: { event: { ...formattedData, resourceId: data.id } },
        })
      }

      snackbar({
        message:
          errorMessage ??
          'Não foi possível salvar o evento de férias, tente novamente.',
        type: 'error',
        timeout: 6,
      })
    },
  })

  const handleSave = React.useCallback(
    (dataToSave: any, event?: React.FormEvent<HTMLFormElement>) => {
      // @ts-ignore
      const submitterName = event?.nativeEvent?.submitter?.name
      const [startYear, startMonth, startDay] = dataToSave.startDate
        .split('-')
        .map(Number)
      const startDate = new Date(startYear, startMonth - 1, startDay)

      return save()({
        ...dataToSave,
        startDate: format(startDate, 'yyyy-MM-dd'),
        userId: Number(data.id),
        confirm: submitterName === 'confirm',
      })
    },
    [save, data.id],
  )

  return (
    <Modal.FormWrapper
      open={isOpen}
      schema={schema}
      loading={saving}
      onClose={onClose}
      defaultValues={data}
      onConfirm={handleSave}
      className="!min-w-[90vw] md:!min-w-[46rem]"
    >
      <Modal.Header className="!bg-gray-lightest">
        Programar férias
      </Modal.Header>

      <ModalContent data={data} />

      <Modal.Actions className="gap-4" cancelLabel="voltar">
        {!saving && (
          <Button variant="outlined" kind="danger" type="submit" name="draft">
            salvar rascunho
          </Button>
        )}
        <Button kind="primary" name="confirm" type="submit" loading={saving}>
          Salvar e confirmar
        </Button>
      </Modal.Actions>
    </Modal.FormWrapper>
  )
}

export default Collaborator
