import { cx } from 'shared/helpers'

const Th = ({ children, className, ...props }: JSX.IntrinsicElements['th']) => {
  return (
    <th {...props} className={cx('block p-3 md:table-cell', className)}>
      {children}
    </th>
  )
}

export default Th
