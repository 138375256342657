import { Rectangle } from 'recharts'
import type { RectangleProps } from 'recharts'
import { colors } from 'theme'

type Props = Omit<RectangleProps, 'ref'> & {
  baseValue: number
  activeValue: number
  background: {
    height: number
    y: number
  }
  height: number
}

export default function ActiveShape({
  color,
  baseValue,
  activeValue,
  ...props
}: Props) {
  const position =
    baseValue || activeValue
      ? props.background.height + props.background.y - props.height
      : props.y

  return (
    <Rectangle
      {...props}
      y={position}
      fill={color || colors.blue}
      radius={[16, 16, 0, 0]}
    />
  )
}
