import * as React from 'react'

const ArrowUpLeftIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="currentColor"
  >
    <path
      d="M3 15V13.5H10.225C11.3917 13.5 12.3958 13.1125 13.2375 12.3375C14.0792 11.5625 14.5 10.6 14.5 9.45C14.5 8.3 14.0792 7.3375 13.2375 6.5625C12.3958 5.7875 11.3917 5.4 10.225 5.4H2.85L5.7 8.25L4.65 9.3L0 4.65L4.65 0L5.7 1.05L2.85 3.9H10.2C11.7833 3.9 13.1458 4.43333 14.2875 5.5C15.4292 6.56667 16 7.88333 16 9.45C16 11.0167 15.4292 12.3333 14.2875 13.4C13.1458 14.4667 11.7833 15 10.2 15H3Z"
      stroke="currentColor"
      strokeWidth="0.8"
    />
  </svg>
)

export default ArrowUpLeftIcon
