type Props = {
  label: string
  bulletColor: string
}

const BulletPoint = ({ label, bulletColor }: Props) => (
  <p className="flex items-center">
    <p className={`mr-2 h-5 w-5 rounded-full ${bulletColor} border-none`}></p>
    {label}
  </p>
)

export default BulletPoint
