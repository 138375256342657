import Performance from 'performance'
import TeamEvaluation from 'performance/evaluations/teamEvaluation'
import type { LegacyRoute } from 'types'
import TalentRate from 'performance/evaluations/talentRate'
import TeamEvaluationNotice from 'performance/evaluations/teamEvaluation/teamEvaluationNotice'

const home: LegacyRoute = {
  component: Performance,
  pageTitle: 'Performance',
  path: '/performance',
  breadcrumbs: ['home'],
}
const rate: LegacyRoute = {
  component: TeamEvaluationNotice,
  pageTitle: 'Avaliar Performance',
  path: '/performance/avaliar-performance',
  breadcrumbs: ['home', 'rate'],
}

const talentRate: LegacyRoute = {
  component: TalentRate,
  pageTitle: 'Avaliar Talentos',
  path: '/performance/avaliar-talentos',
  breadcrumbs: ['home', 'talentRate'],
}

const teamEvaluation: LegacyRoute = {
  component: TeamEvaluation,
  path: '/performance/avaliacao-de-performance/:teamId',
  pageTitle: 'Avaliação do Círculo',
  breadcrumbs: ['home', 'teamEvaluationList', 'teamEvaluation'],
}

export default [home, rate, talentRate, teamEvaluation]
