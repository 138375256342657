import React, { useRef } from 'react'

import { Dialog, Transition } from '@headlessui/react'

export type Props = {
  onClose: () => void
  show?: boolean
  children: React.ReactNode
}
const OverlayWrapper = ({ onClose, children, show = true }: Props) => {
  const initialFocus = useRef(null)
  return (
    <Transition appear show={show} as={React.Fragment}>
      <Dialog
        onClose={onClose}
        className="fixed inset-0 z-[100] overflow-y-auto"
        initialFocus={initialFocus}
      >
        <div
          className="flex min-h-screen items-center justify-center px-2"
          ref={initialFocus}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/5 backdrop-blur-sm" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default OverlayWrapper
