import { Switch } from '@headlessui/react'
import { cx } from '@remaster-run/helpers'

type Props = {
  onChange: (value: boolean) => void
  label: string
  value: any
  type: string
  handleChange: (option: any) => void
}

const OnOff = ({ onChange, label, type, value, handleChange }: Props) => {
  const setChanges = () => {
    onChange(!value)
    handleChange({ [type]: !value })
  }

  return (
    <ul className="w-full overflow-hidden border border-b-0 border-gray/75 bg-white first:rounded-t-2xl last:mb-0 last:rounded-b-2xl last:border-b">
      <li className="relative">
        <div className="flex px-4 py-2 w-2/3 relative box-border items-center justify-start">
          <p className="font-bold text-gray-dark">{label}</p>
        </div>
        <Switch
          checked={value}
          onChange={() => {
            setChanges()
          }}
          className="group inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full outline-none focus:outline-none absolute top-1/2 right-4 -translate-y-1/2"
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className="pointer-events-none absolute h-full w-full rounded-md bg-white"
          />
          <span
            aria-hidden="true"
            className={cx(
              Boolean(value) ? 'bg-green-200' : 'bg-red-300',
              'pointer-events-none absolute mx-auto h-[14px] w-9 rounded-full transition-colors duration-200 ease-in-out',
            )}
          />
          <span
            aria-hidden="true"
            className={cx(
              Boolean(value)
                ? 'translate-x-5 bg-green'
                : 'translate-x-0 bg-red',
              'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full shadow ring-0 transition-transform duration-200 ease-in-out',
            )}
          />
        </Switch>
      </li>
    </ul>
  )
}

export default OnOff
