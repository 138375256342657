import React, { useState } from 'react'
import { useCroods } from 'croods'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import Button from 'shared/forms/SubmitButton'
import Icon from 'shared/ui/icon'
import ViewSolicitationModal from './ViewSolicitationModal'
import RejectModal from './RejectModal'
import { renderIf } from 'shared/helpers'

import type { Solicitation } from 'types'
import type { Actions } from 'croods'

const getButtonStyle = (asApproveButton: boolean) =>
  asApproveButton
    ? {
        title: 'solicitação',
        variant: 'contained',
        type: 'button',
        kind: 'success',
        startIcon: undefined,
        children: (
          <Icon icon="thumbs-up" className="fill-transparent text-white" />
        ),
      }
    : {
        title: 'solicitação',
        variant: 'outlined',
        type: 'button',
        startIcon: <Icon icon="visibility" />,
        children: 'solicitação',
      }

const handleFetch = (fetch: Actions<Solicitation>['fetch']) =>
  fetch({ cache: false })()

const ViewSolicitationButton = ({
  id,
  asApproveButton = false,
}: {
  id: number
  asApproveButton?: boolean
}) => {
  const [modal, setModal] = useState(false)
  const { snackbar } = useSnackbar()
  const [{ fetchingList: loading, info: solicitation }, { fetch }] = useCroods({
    id,
    name: 'solicitations',
    stateId: `view-${id}`,
    path: '/workflow/solicitations',
    afterSuccess: () => {
      setModal(true)
    },
    afterFailure: () =>
      snackbar({
        message: 'Houve um erro ao visualizar a solicitação',
        type: 'error',
      }),
  })

  const buttonStyleConfig = getButtonStyle(asApproveButton)
  const [showJustificationModal, setShowJustificationModal] = useState(false)

  const handleReject = () => {
    setModal(false)
    setShowJustificationModal(true)
  }

  return (
    <>
      <Button
        {...buttonStyleConfig}
        className="!w-full flex-1 sm:!w-auto sm:flex-none"
        onClick={() => handleFetch(fetch)}
        loading={loading}
      />

      {renderIf(
        Boolean(solicitation),
        <ViewSolicitationModal
          open={modal}
          id={id}
          onClose={() => setModal(false)}
          solicitationItem={solicitation}
          handleReject={handleReject}
        />,
      )}

      {renderIf(
        Boolean(solicitation) && showJustificationModal,
        <RejectModal
          id={id}
          open={showJustificationModal}
          onClose={() => setShowJustificationModal(false)}
        />,
      )}
    </>
  )
}

export default ViewSolicitationButton
