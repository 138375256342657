import React from 'react'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { cx } from 'shared/helpers'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'theme'

type Props<T> = {
  value: T
  label: string
  icon?: React.ReactNode
  description?: string
  color: string
  center?: boolean
  fullWidth?: boolean
}

export default function RadioCard<T>({
  value,
  label,
  icon,
  description,
  color,
  center,
  fullWidth,
}: Props<T>) {
  const colorType =
    color.includes('#') || color.includes('rgb') ? 'hash' : 'tailwind'
  const classes = useStyles()

  return (
    <FormControlLabel
      className={cx(
        '!m-0 h-auto w-full',
        fullWidth ? 'flex-1' : 'max-w-[15rem]',
      )}
      value={value}
      label={label}
      classes={{ label: '!hidden' }}
      control={
        <div className="card flex h-full w-full flex-col">
          <div
            className={cx(
              'flex h-10 items-center gap-2 pl-4',
              description && 'bg-gray-lightest',
              center ? 'justify-center' : 'justify-start',
            )}
          >
            {icon && icon}
            <h5 className={'text-lg font-bold uppercase '}>{label}</h5>
          </div>
          {description && (
            <div className={'min-h-[1rem] flex-1 px-4 py-4'}>
              <p
                className={' text-base text-gray-darkest'}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          )}
          <div
            className={cx(
              'flex h-10 items-center justify-center',
              colorType === 'tailwind' && color,
            )}
            style={colorType === 'hash' ? { backgroundColor: color } : {}}
          >
            <Radio
              inputProps={{ 'aria-label': label }}
              value={value}
              className={'text-black/50'}
              classes={{ root: classes.radio, checked: classes.checked }}
            />
          </div>
        </div>
      }
    />
  )
}

const useStyles = makeStyles(() => ({
  root: {
    marginRight: 0,
  },
  label: {
    width: '100%',
    marginRight: 0,
  },
  radio: {
    '&$checked': {
      color: colors.black,
    },
  },
  checked: {
    '&$checked': {
      color: colors.black,
    },
  },
}))
