import * as React from 'react'
import Button from '@material-ui/core/Button'
import { Skeleton } from '@material-ui/lab'
import { useCroods } from 'croods'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import Slider from 'talks/conversations/career/shared/careerSkills/Card/Slider'
import type { Skill } from 'talks/conversations/career/shared/careerSkills/types'
import { cx } from 'shared/helpers'
import DevelopmentPlanInput from './shared/careerSkills/Card/development-plan-input'

type Props = {
  id: number
  snapshot?: boolean
}

export default function Skills({ id, snapshot = false }: Props) {
  const endpoint = `/career_conversations/${id}/career_skills_snapshot`

  const [{ list: rawList, fetchingList, listError }] = useCroods<Skill>({
    name: 'carrerSkillsSnapshot',
    path: endpoint,
    fetchOnMount: true,
  })

  const [showCompleteSkills, setShowCompleteSkills] = React.useState(false)

  if (fetchingList) {
    return <Skeleton />
  }

  if (listError) {
    return (
      <FeedbackMessage
        message="Competências não encontradas"
        serverMessage=""
        type="error"
      />
    )
  }

  const list = snapshot
    ? rawList.filter(({ completedAt }) => !completedAt)
    : rawList

  const skills = !snapshot && !showCompleteSkills ? list.slice(0, 3) : list

  return (
    <div>
      {skills.map(
        ({
          id: skillId,
          title,
          developmentPlan,
          developmentPercentagePrevious,
          developmentPercentageCurrent,
          developmentPercentageLongTermGoal,
          developmentPercentageNextCycle,
          pausedAt,
        }) => (
          <div
            className="mb-6 flex flex-col overflow-hidden rounded-3xl shadow-md"
            key={skillId}
          >
            <div
              className={cx(
                'flex p-4 h-14 grow flex-col md:flex-row',
                Boolean(pausedAt)
                  ? 'bg-gray-100 focus-within:bg-gray-50'
                  : 'linear-gradient',
              )}
            >
              <div className="flex grow items-center text-base text-gray-darkest">
                <p className="label text-base">{title}</p>
              </div>
              <div className="flex flex-wrap md:flex-nowrap items-center py-2 text-gray-500 gap-4">
                {Boolean(pausedAt) && (
                  <span className="whitespace-nowrap mr-4 rounded-full bg-black/5 px-2 py-1 text-lg text-gray-500">
                    Competência pausada
                  </span>
                )}
              </div>
            </div>

            <div className="mb-4">
              <Slider
                displayOnly
                developmentPercentagePrevious={developmentPercentagePrevious}
                developmentPercentageCurrent={developmentPercentageCurrent}
                developmentPercentageLongTermGoal={
                  developmentPercentageLongTermGoal
                }
                developmentPercentageNextCycle={developmentPercentageNextCycle}
                isPaused={snapshot}
              />
            </div>
            <div className="px-2 pb-5 md:p-4">
              <p className="mb-1 text-base font-bold leading-4 text-gray-dark">
                Planos e ideias para o desenvolvimento da competência
              </p>
              <DevelopmentPlanInput
                disabled
                value={developmentPlan}
                placeholder="Não preenchido"
              />
            </div>
          </div>
        ),
      )}

      {list.length === 0 && (
        <FeedbackMessage
          message="Nenhuma competência encontrada"
          serverMessage=""
        />
      )}
      {list.length > 3 && !snapshot && (
        <Button
          variant="outlined"
          className="!mx-auto !block"
          onClick={() => setShowCompleteSkills(!showCompleteSkills)}
        >
          {!showCompleteSkills
            ? 'Ver competências completas'
            : 'Ver competências resumidas'}
        </Button>
      )}
    </div>
  )
}
