import get from 'lodash/get'
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined'
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined'
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import ThumbsUpDownRoundedIcon from '@material-ui/icons/ThumbsUpDownRounded'
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined'
import { colors } from 'theme'

export const conversationTypes = {
  one_on_one: {
    name: '1:1',
    path: '1-1',
    icon: <QuestionAnswerOutlinedIcon htmlColor={colors.blue} />,
    grayIcon: <QuestionAnswerOutlinedIcon htmlColor={colors.black40} />,
  },
  career: {
    name: 'Carreira',
    path: 'carreira',
    icon: <FlagOutlinedIcon htmlColor={colors.blue} />,
    grayIcon: <FlagOutlinedIcon htmlColor={colors.black40} />,
  },
  behavior: {
    name: 'Comportamento',
    path: 'comportamento',
    icon: <PersonOutlinedIcon htmlColor={colors.blue} />,
    grayIcon: <PersonOutlinedIcon htmlColor={colors.black40} />,
  },
  onboarding: {
    name: 'Onboarding',
    path: 'onboarding',
    icon: <ContactMailIcon htmlColor={colors.blue} />,
    grayIcon: <ContactMailIcon htmlColor={colors.black40} />,
  },
  permanent: {
    name: 'Efetivação',
    path: 'efetivacao',
    icon: <ThumbsUpDownRoundedIcon htmlColor={colors.blue} />,
    grayIcon: <ThumbsUpDownRoundedIcon htmlColor={colors.black40} />,
  },
  upward_evaluation: {
    name: 'Avaliação Ascendente',
    path: 'evaluation',
    icon: <MailOutlineOutlinedIcon htmlColor={colors.blue} />,
    grayIcon: <MailOutlineOutlinedIcon htmlColor={colors.black40} />,
  },
}

export const typeToName = (type: string) =>
  get(conversationTypes[type as keyof typeof conversationTypes], 'name')

export const typeToPath = (type: string) =>
  get(conversationTypes[type as keyof typeof conversationTypes], 'path')

export const typeToIcon = (type: string) =>
  get(conversationTypes[type as keyof typeof conversationTypes], 'icon')

export const typeToGrayIcon = (type: string) =>
  get(conversationTypes[type as keyof typeof conversationTypes], 'grayIcon')
