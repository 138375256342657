import { Bar } from 'recharts'
import { colors } from 'theme'
import Chart from 'vibe/insights/chart'

type Data = {
  highContribution: number
  mediumContribution: number
  lowContribution: number
}

type Props = { data: Data[] }

export default function ConversationFrequencyChart({ data }: Props) {
  return (
    <Chart data={data}>
      <Chart.Header tooltip='O resultado da Contribuição dos liderados é calculado com a combinação dos resultados de "Entrega" e "Trabalho em Equipe" informados na Conversa de Carreira.'>
        Contribuições dos liderados
      </Chart.Header>
      <Chart.Bars
        tooltip={({
          mediumContribution,
          highContribution,
          lowContribution,
        }) => (
          <>
            <div style={{ color: colors.greenDarkest }}>
              Contribuição superior: {highContribution}
            </div>
            <div style={{ color: colors.green }}>
              Contribuição adequada: {mediumContribution}
            </div>
            <div style={{ color: colors.yellow }}>
              Contribuição baixa: {lowContribution}
            </div>
          </>
        )}
      >
        <>
          <Bar
            dataKey="lowContribution"
            stackId="a"
            shape={Chart.YellowStackShape}
          />
          <Bar
            dataKey="mediumContribution"
            stackId="a"
            shape={Chart.GreenStackShape}
          />
          <Bar
            dataKey="highContribution"
            stackId="a"
            shape={Chart.DarkGreenStackShape}
          />
        </>
      </Chart.Bars>
    </Chart>
  )
}
