import { typeToIcon, typeToName } from 'shared/utils/conversationLookup'
import { formatDate } from 'shared/helpers'
import { colors } from 'theme'
import { useNavigate } from 'react-router-dom'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import CareerListItem from './career/career-list-item'
import UpwardEvaluationListItem from 'talks/conversations/upwardEvaluation/UpwardEvaluationListItem'
import Icon from 'shared/ui/icon'

type BaseConversationListItemProps = {
  conversation: any // Leaving any here since the runtime below expects a type that doesn't exist
  teamMemberId?: string
  isEditMode?: Boolean
}

const BaseConversationListItem = ({
  conversation,
  teamMemberId,
  isEditMode = false,
}: BaseConversationListItemProps) => {
  const navigate = useNavigate()

  const {
    conversationType,
    id: conversationId,
    date,
    comment,
    followUpDate,
    decision,
    attachments,
  } = conversation

  const getLink = () => {
    const urlBase = `/conversas/nova-conversa`
    switch (conversationType) {
      case 'onboarding':
        return `${urlBase}/onboarding/${teamMemberId}/${conversationId}/editar`
      case '1-1':
        return `${urlBase}/1-1/${teamMemberId}/${conversationId}/editar`
      case 'one_on_one':
        return `${urlBase}/1-1/${teamMemberId}/${conversationId}/editar`
      case 'behavior':
        return `${urlBase}/comportamento/${teamMemberId}/${conversationId}/editar`
      default:
        return `/`
    }
  }

  if (conversationType === 'career') {
    return <CareerListItem conversation={conversation} />
  }

  if (conversationType === 'upward_evaluation') {
    return <UpwardEvaluationListItem conversation={conversation} />
  }

  return (
    <div id={conversationId} className="relative scroll-pt-20">
      <div className="card">
        <header className="flex items-center justify-between bg-gray-lightest px-8 py-2">
          <div className="flex items-center gap-2">
            {typeToIcon(conversationType)}
            <h4 className="text-base text-gray-darkest text-opacity-80 first-letter:uppercase">
              {typeToName(conversationType)}
            </h4>
          </div>
          {isEditMode && (
            <button
              className="rounded px-3 py-2 text-lg font-bold uppercase tracking-wide text-blue transition hover:bg-gray-light"
              onClick={() => navigate(getLink())}
            >
              Editar
            </button>
          )}
        </header>
        <div className="my-6 flex flex-col gap-4 px-8 py-2">
          {date && (
            <div>
              <h5>Data</h5>
              <p className="text-gray-500">{formatDate(date)}</p>
            </div>
          )}
          {comment && (
            <div>
              <h5>Memórias da conversa</h5>
              <div
                className="text-gray-500"
                dangerouslySetInnerHTML={{ __html: comment }}
              />
            </div>
          )}
          {attachments && attachments.length > 0 && (
            <div className="flex flex-col gap-2">
              <h5>Anexos</h5>
              {attachments.map(
                (attachment: {
                  id: string
                  name: string
                  type: string
                  url: string
                }) => (
                  <a
                    key={attachment.id}
                    target="_blank"
                    href={attachment.url}
                    rel="noreferrer"
                    className="uppercase font-bold text-blue hover:text-blue-400"
                  >
                    <Icon icon="attach-file" className="inline" />
                    {attachment.name}
                  </a>
                ),
              )}
            </div>
          )}
          {followUpDate && (
            <div>
              <h5>Próxima conversa</h5>
              <p className="text-gray-500">{formatDate(followUpDate)}</p>
            </div>
          )}
          {decision && (
            <div>
              <h5>Decisão Efetivação / Desligamento</h5>
              {decision === 'permanent' && (
                <div className="mt-2">
                  <ThumbUpIcon htmlColor={colors.blue} />
                  <span className="mt-4 ml-2 text-lg font-bold text-blue ">
                    Efetivado/a
                  </span>
                </div>
              )}
              {decision === 'fired' && (
                <>
                  <ThumbDownIcon htmlColor={colors.blue} />
                  <span className="mt-4 ml-2 text-lg font-bold text-blue ">
                    Desligado/a
                  </span>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BaseConversationListItem
