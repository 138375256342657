import type { Actions } from 'croods'
import React from 'react'
import type { AddToCroodsQueue, EvaluationTeam, Role } from '../../types'

import EvaluationRow from './EvaluationRow'

type Props = {
  team: EvaluationTeam
  save: AddToCroodsQueue
  allRoles: Role[]
  rolesSave: Actions['save']
}

function Table({ team, save, allRoles, rolesSave }: Props) {
  return (
    <form className="mb-8 pl-1">
      <div key={team.teamId}>
        <h4 className="my-4 text-xl font-bold">{team.teamName}</h4>
        <table className="w-full text-sm text-gray-600">
          <tbody>
            {team.memberEvaluations.map((evaluation) => {
              const key = `evaluation-${evaluation.id}`
              return (
                <EvaluationRow
                  key={key}
                  evaluation={evaluation}
                  save={save}
                  allRoles={allRoles}
                  rolesSave={rolesSave}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    </form>
  )
}

export default Table
