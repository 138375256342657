import Grid from '@material-ui/core/Grid'

import { getUserIdentifier, cx } from 'shared/helpers'

import MemberInfo from './MemberInfo'
import Header from './Header'
import FaceGroup from './FaceGroup'

import ParentTeams from './ParentTeams'
import ChildTeams from './ChildTeams'

const Card = ({
  name,
  leaders = [],
  members = [],
  hasEvaluateButton,
  hasEditButton,
  teamId,
  team,
  variant,
  status,
  denomination,
  lines = 3,
  showParentsAndChildren = false,
  filterChildren,
  showMembers = true,
  TooltipContent,
}: any) => {
  const persons = [
    ...leaders.map((leader: any) => ({ ...leader, leader: true })),
    ...members,
  ].map(({ user, leader }, index) => ({
    key: `${user.id}-${index}`,
    id: user.id,
    badge: leader && 'Líder',
    image: user.avatar,
    name: getUserIdentifier({ user }),
    occupation: user.role,
    hasCredential: user.hasCredential,
    recentlyAdded: user.recentlyAdded,
  }))

  let limitedGroup: any[] = []
  let faceGroup: any[] = []
  let remaining = 0
  let maxItems = 0

  if (!variant?.includes('primary')) {
    maxItems = lines * 3 - 1
    limitedGroup = persons.splice(0, maxItems)
    faceGroup = persons.splice(0, 4)
    remaining = faceGroup.length
  }

  const rendered = limitedGroup.length ? limitedGroup : persons

  const { parentTeams = [], childTeams = [] } = team || {}

  const setChildTeamsOpaque = variant?.includes('insights') && showMembers

  return (
    <div className="card rounded-2xl">
      <Header
        status={status}
        name={name}
        hasEvaluateButton={hasEvaluateButton}
        hasEditButton={hasEditButton}
        teamId={teamId}
        team={team}
        variant={variant}
        denomination={denomination}
        TooltipContent={TooltipContent}
      />
      {showParentsAndChildren && (parentTeams.length || childTeams.length) ? (
        <Grid container spacing={1} alignItems="center">
          {parentTeams.length ? (
            <>
              <div
                className={cx(
                  'px-5 py-4',
                  variant?.includes('insights') && '!opacity-50',
                )}
              >
                <ParentTeams
                  teamId={teamId}
                  name={name}
                  denomination={denomination}
                  parentTeams={parentTeams}
                  variant={variant}
                />
              </div>
              <div className="gray/stroke w-screen border-b-2"></div>
            </>
          ) : null}
          {childTeams.length ? (
            <>
              <div
                className={cx(
                  'px-5 py-4',
                  setChildTeamsOpaque && '!opacity-50',
                )}
              >
                <ChildTeams
                  teamId={teamId}
                  name={name}
                  denomination={denomination}
                  childTeams={childTeams}
                  variant={variant}
                  filterChildren={filterChildren}
                />
              </div>
              <div className="gray/stroke w-screen border-b-2"></div>
            </>
          ) : null}
        </Grid>
      ) : null}
      {showMembers && (
        <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 md:grid-cols-3">
          {rendered.map(
            ({
              key,
              id,
              image,
              name: userName,
              badge,
              occupation,
              hasCredential,
              recentlyAdded,
            }) => (
              <MemberInfo
                key={key}
                badge={badge}
                id={id}
                image={image}
                name={userName}
                occupation={occupation}
                hasCredential={hasCredential}
                recentlyAdded={recentlyAdded}
              />
            ),
          )}
          {!variant?.includes('primary') && limitedGroup.length >= maxItems && (
            <div>
              <FaceGroup faces={faceGroup} remaining={remaining} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Card
