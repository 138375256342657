import { colors } from 'theme'

const boxColors = {
  'warning-high': colors.yellow,
  'warning-medium': colors.yellow60,
  'warning-low': colors.yellow40,
  'ideal-high': colors.green,
  'ideal-medium': colors.green60,
  'ideal-low': colors.green40,
  'critical-high': colors.red,
  'critical-medium': colors.red60,
  'critical-low': colors.red40,
} as const

export default boxColors
