import { useContext, useEffect, useRef, useState } from 'react'
import type { DependencyList } from 'react'
import {
  useFetcher,
  useLocation,
  useNavigate,
  useNavigation,
} from 'react-router-dom'
import { useCroods } from 'croods'
import { useQueryParam } from '@seasonedsoftware/utils'
import { fullPathFromLocation, matchSearch } from 'shared/helpers'

import flatten from 'lodash/flatten'
import snakeCase from 'lodash/snakeCase'

import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import type { ProviderOptions } from 'croods/dist/types'
import * as z from 'zod'
import { AppRouterStateContext } from 'app'

export const usePageTitle = (text: string): void => {
  useEffect(() => {
    document.title = `${text} | Vibe`
  }, [text])
}

export const useAdminText = (): Record<string, string> => {
  const [{ list }] = useCroods({
    name: 'adminTexts',
    urlParser: snakeCase,
    fetchOnMount: true,
    cache: true,
  })

  const fetchedTags = list.reduce(
    (total, item) => ({
      ...total,
      ...item,
    }),
    {},
  )

  return fetchedTags
}

// It will grab the redirect_to param from the URL and return a function that
// navigates to such path
// USAGE: const redirectBack = useRedirectBack()
// <button onClick={redirectBack} />
export const useRedirectBack = (
  param: string = 'redirect_to',
  defaultPath: string = '/',
) => {
  const redirect = useQueryParam(param)
  const navigate = useNavigate()
  return () => navigate((redirect as unknown as string) || defaultPath)
}

export const useIsMobile = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('sm'))
}

export const useSearch = <T = unknown>(initialSearch: string = '') => {
  const [searchTerm, setSearch] = useState<string>(initialSearch)
  const select = (collection: T[], fn: (param: any) => string | string[]) =>
    collection.filter((item) => matchSearch(searchTerm, flatten([fn(item)])))
  return { setSearch, select, searchTerm }
}

export const useRedirectWhenUnauthenticated = () => {
  const navigate = useNavigate()

  return (
      callback?: ProviderOptions['after4xx'],
    ): ProviderOptions['after4xx'] =>
    (code, message, data) => {
      const errorSchema = z.object({ errors: z.array(z.string()) })

      const errorList = errorSchema.safeParse(data)
      if (
        code === 401 &&
        errorList.success &&
        errorList.data.errors.includes(
          'Para continuar, faça login ou registre-se.',
        )
      ) {
        navigate('/login?log_out=true')
        return
      }
      callback?.(code, message, data)
    }
}

export const usePagination = (numPerPage: number, numTotal: number) => {
  const [page, setPage] = useState(1)

  const division = Math.floor(numTotal / numPerPage)
  const totalPages = numTotal % numPerPage === 0 ? division : division + 1

  const setCurrentPage = (currentPage: number) => {
    const newPage =
      currentPage <= 1
        ? 1
        : currentPage >= totalPages
        ? totalPages
        : currentPage

    setPage(newPage)
  }

  return { currentPage: page, setCurrentPage, totalPages }
}

export function useFetcherWithDebounce<T extends unknown = unknown>() {
  const timeout = 300
  const fetcher = useFetcher<T>()
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>()
  const [href, load] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (href) {
      if (timer.current) clearTimeout(timer.current)
      setLoading(true)
      timer.current = setTimeout(() => {
        fetcher.load(href)
        load(undefined)
        setLoading(false)
      }, timeout)
    }

    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [href, timeout, fetcher])

  return {
    data: fetcher.data,
    load,
    state: loading ? 'loading' : fetcher.state,
  }
}

export function useRestoreScrollOnChange(...dependencies: DependencyList) {
  useEffect(() => {
    const target = document.querySelector('#scroll-restoration-target')

    target?.scrollIntoView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

export function useRestoreScrollOnNavigation() {
  const location = useLocation()
  const navigation = useNavigation()
  const { state: navigationState } = navigation
  const [loading, setLoading] = useState(navigationState === 'loading')
  const locationPath = fullPathFromLocation(location)
  const routerState = useContext(AppRouterStateContext)

  const preventScrollReset =
    Boolean(location.state?.preventScrollReset) ||
    routerState.preventScrollReset

  const navigationPath =
    navigation.location && fullPathFromLocation(navigation.location)

  useEffect(() => {
    if (navigationState === 'loading' && locationPath !== navigationPath) {
      setLoading(true)
      return
    }

    if (loading && routerState.navigationState === 'idle') {
      setLoading(false)

      if (!preventScrollReset) {
        const target = document.querySelector('#scroll-restoration-target')
        target?.scrollIntoView()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationState, routerState.navigationState])
}
