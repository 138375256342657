import React from 'react'
import TextField from '@material-ui/core/TextField'
import startCase from 'lodash/startCase'
import InputMask from 'react-input-mask'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'theme'
import get from 'lodash/get'

const useStyles = makeStyles((theme) => ({
  body: {
    // Variant standard
    '& .MuiInput-underline:before': {
      borderBottomColor: colors.blue,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: colors.blue,
    },
    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },

    // Variant outlined
    '& .MuiOutlinedInput-multiline': {
      padding: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.blue,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.blueDark,
    },
    '& label': {
      fontFamily: 'Nunito',
      fontSize: theme.typography?.h5?.fontSize,
      fontWeight: theme.typography?.h5?.fontWeight,
      lineHeight: theme.typography?.h5?.lineHeight,
      position: 'relative',
      transition: 'none',
      transform: 'none',
      marginBottom: theme.spacing(0.5),
    },
    '& input': {
      padding: '.75rem',
      backgroundPosition: '99% center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto',
    },
    '& textarea': {
      padding: '.75rem',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}))

const Input = ({
  disabled,
  name,
  error,
  helper = '',
  label = startCase(name),
  placeholder = '',
  mask = '',
  maskChar = ' ',
  variant = 'outlined',
  rows,
  multiline,
  step = 300, // 5 minutes
  type = 'text',
  ...props
}: any) => {
  const maskProps = mask
    ? {
        InputProps: {
          inputComponent: InputMask,
          inputProps: { mask, maskChar },
        },
      }
    : {}

  const stepProps =
    type === 'time' && !mask
      ? {
          inputProps: {
            step,
          },
        }
      : {}

  const helperText = error || helper || undefined
  const classes = useStyles()

  return (
    <TextField
      {...props}
      variant={variant}
      name={name}
      type={type}
      id={name}
      label={label}
      placeholder={placeholder}
      fullWidth
      error={!!error}
      helperText={helperText}
      disabled={disabled}
      InputLabelProps={{
        shrink: false,
        ...get(props, 'InputProps')?.inputLabelProps,
      }}
      className="mb-4"
      multiline={multiline}
      minRows={rows}
      maxRows={rows}
      classes={{
        root: classes.body,
      }}
      {...stepProps}
      {...maskProps}
    />
  )
}

export default Input
