import type { PublishedMemberEvaluation } from 'performance/evaluations/membersEvaluation/types'
import type React from 'react'
import type { FormState, StateErrors } from 'react-use-form-state'
import type { AccessPolicyUser } from 'shared/accessPolicies'

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_API_URL: string
      REACT_APP_SCHEDULER_LICENSE: string
      REACT_APP_WEB_URL: string
    }
  }
}

export type DomainPath = `/${string}` | ''
type LegacyRouteProps = {
  path: DomainPath
  pageTitle: string
}

export type EvaluationWindowStatus = 'closed' | 'open' | 'almost-late' | 'late'
export type EvaluationWindowStatusMap = Record<EvaluationWindowStatus, string>

export type PageProps<T = Record<string, any>> = React.FC<
  T &
    LegacyRouteProps & {
      currentUser?: User
      uri: string
    }
>

export type Team = {
  id: number
  teamId: number
  teamName: string
  teamDenomination?: string
  teamStatus: 'draft' | 'active' | 'inactive'
  user?: User
}

// Not the same as Team since we map teams in the API side for the endpoint teams/to_evaluate
export type TeamToEvaluate = {
  id: number
  teamName: string
  teamDenomination: string
  isDraft: boolean
}

export type Member = Pick<Team, 'teamId' | 'teamName'> & {
  id: number
  user: User
  adminOnly: boolean
  latestEvaluation?: PublishedMemberEvaluation
  mainTeam?: { id: number; name: string }
}

export type Circle = {
  id: number
  childTeams?: Circle[]
  denomination: string
  name: string
  leaders: Team[]
  members: Member[]
  ownerId?: number
  parentTeams?: Circle[]
  status: 'active' | 'inactive'
}

export type UserAvatar = {
  avatar?: string
  email?: string
  firstName?: string
  lastName?: string
  hasCredential: boolean
  recentlyAdded: boolean
}

export const hierarchyPositions = [
  'member',
  'leader',
  'member_and_leader',
  'teamless',
] as const

export type HierarchyPositions = typeof hierarchyPositions[number]

export type User = UserAvatar & {
  id: number
  active: boolean
  admissionDate?: string | Date
  dataAnalyst: boolean
  admin: boolean
  leader?: boolean
  role: string
  mainTeamId?: number
  mainTeam?: Pick<Team, 'id'> & { name: Team['teamName'] }
  email: string
  teamMembers?: Team[]
  teamLeaders?: Team[]
  teamsLeadingIds?: number[]
  teamMemberId?: string
  teamId?: string
  primaryRoles: string[]
  secondaryRoles: string[]
  hierarchyPosition: HierarchyPositions
  invitationAcceptedAt?: Date
  hasAcquisitivePeriods: boolean
  abilities?: {
    invite?: boolean
  }
  imported?: boolean
}

export type Organization = {
  behaviorConversations: boolean
  careerConversations: boolean
  currentCycle?: {
    durationInMonths: number
    evaluationWindowClosesAt: string
    evaluationWindowOpensAt: string
    finishesAt: string
    id: number
    openForEvaluation: boolean
    startsAt: string
  }
  permitClocking: boolean
  permitDocuments: boolean
  permitSolicitations: boolean
  permitVacations: boolean
  evaluationCycleFinishesAt: string
  evaluationCycleStartsAt: string
  evaluationWindowClosesAt: string
  evaluationWindowOpensAt: string
  evaluationWindowStatus: string
  hasCycle: boolean
  id: number
  logoUrl: string
  name: string
  slug: string
  goalDescription?: string
  onboardingConversations: boolean
  permanentConversations: boolean
  primaryColor: string
  secondaryColor: string
  permitOneOnOneConversation: boolean
  permitCareerConversation: boolean
  permitBehaviorConversation: boolean
  permitUpwardEvaluation: boolean
  permitTeamsEvaluations: boolean
  permitTalentsEvaluations: boolean
  permitFeedback: boolean
}

export type SolicitationTemplate = {
  name: string
  inputs?: Array<{
    id: number
    inputName: string
    required: boolean
  }>
  automationIdentifier: string | null
}

export type FixedFieldsAnswers = {
  reasonId: number
  observation: string
  reasonText: string
  timeInputs: Date[]
}

export type PrefixedValues = {
  id: number
  masterDate: Date
  status: string | null
  confirmed: boolean
  defaultMoments: string
  moments: Date[]
  warnings?: string[]
  errors?: string[]
  confirmationError?: string | null
  latestSolicitation: Solicitation | null
}

export type Solicitation = {
  id: number
  status:
    | 'pending_approval'
    | 'pending_execution'
    | 'completed'
    | 'rejected'
    | 'canceled'
  createdAt: Date
  requester: User
  applicant: {
    id: number
    firstName: string
    lastName: string
    email: string
  }
  hasUnfilledRequiredFields: boolean
  answers: Array<{
    inputId: number
    inputType: string
    value?: string | Array<string> | Array<object> | object | null
  }>
  questions: Array<{
    id: number
    question: string
    answer?: string | Array<string> | Array<object> | object | null
    inputType: string
    required: true | false
  }>
  solicitationTemplate: SolicitationTemplate
  fixedFieldsAnswers: FixedFieldsAnswers
  prefixedValues: PrefixedValues
  referenceNumber: Date
  user?: User
  vacationStartDate: string | null
  solicitationsApprovals: SolicitationApproval[] | []
  abilities: {
    cancel: boolean
  }
  solicitationCancelation?: SolicitationCancelation
}

export type SolicitationApproval = {
  id: number
  approver: {
    id: number | null
    alias: string
  }
  justification: string
  createdAt: Date
  status: string
}

export type SolicitationCancelation = {
  user: {
    id: number
    alias: string
  }
  justification?: string
  createdAt: Date
}

export type UserConversations = {
  id: number
  teamName: string
  user: User
  conversations: Array<{
    id: string
    date: string
    kind:
      | 'one_on_one'
      | 'behavior'
      | 'career'
      | 'onboarding'
      | 'upward_evaluation'
  }>
}

export type LegacyRoute = LegacyRouteProps & {
  breadcrumbs?: string[]
  authRedirect?: string
  component: null | React.ComponentType<any>
  unauthorize?: (u?: User) => boolean
  showWhen?: (env: NodeJS.ProcessEnv) => boolean
}

export type MenuItemType = {
  title: string
  icon: string
  path: string
  showWhen?: (env: NodeJS.ProcessEnv) => boolean
  visibleFor: (user: AccessPolicyUser) => boolean
  permittedForOrganization: (organization: Organization) => boolean
}

export type ModuleItem = {
  title: string
  icon: string
  path: string
  position: string
  showWhen?: (env: NodeJS.ProcessEnv) => boolean
  menuItems: Array<MenuItemType>
}

export type Breadcrumb = {
  icon?: string
  module?: boolean
  path: DomainPath
  title: string
}

export type FormObject<T> = {
  formState: FormState<T, StateErrors<any, string>>
  fields: (
    type: any,
    name: any,
    validators?: any[],
    options?: {},
  ) => Record<string, any>
  hasErrors: boolean
}

export type FormTemplate = {
  id: number
  name: string
  howToConduct: string
  topics?: string | null
  icon?: string | null
  infoComment?: string | null
  infoSelfEvaluation?: string | null
  infoGrowthPath?: string | null
  infoContribution?: string | null
  infoDevelopmentPlan?: string | null
  infoLeaderAttitude?: string | null
  infoRadicalCandor?: string | null
  infoObnoxiousAggression?: string | null
  infoRuinousEmpathy?: string | null
  infoManipulativeInsincerity?: string | null
}

export type TeamMemberType = 'members' | 'leaders'

export type SaveObj = Record<
  string,
  number | string | null | string[] | number[]
>
