import * as React from 'react'

const CareIconOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 96 960 960"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="M646 598q-47-42-89.5-82t-74.435-77.946q-31.934-37.946-51-73.459Q412 329.081 412 296q0-51.758 35.22-86.879Q482.439 174 534.341 174 564 174 593.5 190.5T646 235q23-28 52.5-44.5t59.159-16.5q51.902 0 87.122 35.121Q880 244.242 880 296q0 33.081-19.065 68.595-19.066 35.513-51 73.459Q778 476 735.323 516.171 692.647 556.341 646 598Zm0-83q66-60 120-119t54-100q0-27.423-17.356-44.712Q785.288 234 757.758 234 741 234 724.5 242q-16.5 8-33.5 30l-45 55-45-55q-17-22-33.5-30t-33.258-8q-27.53 0-44.886 17.288Q472 268.577 472 296q0 41 54 100t120 119Zm-80 479-311-89v57H40V568h309l255 96q27 10 45.5 32.5T668 761h114q42 0 70 30t28 81v26l-314 96Zm-466-92h94V628h-94v274Zm462 30 256-78q-6-19-15-26t-21-7H575q-30 0-55.5-4T471 806l-81-25 22-58 73 24q25 8 47.5 11t71.5 3q0-12-4.5-23.5T584 721l-245-93h-84v214l307 90ZM194 765Zm410-4Zm-410 4Zm61 0Zm391-391Z"
      fill="currentColor"
    />
  </svg>
)

export default CareIconOutline
