import * as React from 'react'
import { cx } from 'shared/helpers'
import Icon from 'shared/ui/icon'

type Props = React.HTMLAttributes<HTMLSpanElement> & {
  onDelete?: () => void
}
function Chip({ children, onDelete, className, ...props }: Props) {
  return (
    <span
      className={cx(
        'flex items-center rounded-xl bg-purple-light py-1 px-1.5 text-purple-darkest shadow-sm',
        className,
      )}
      {...props}
    >
      {children}
      {onDelete && (
        <button
          className="translate-x-0.5 rounded-full p-1 transition hover:bg-black/5"
          type="button"
          onClick={() => onDelete()}
        >
          <Icon aria-hidden size="xs" icon="close" />
          <span className="sr-only">Excluir</span>
        </button>
      )}
    </span>
  )
}

export default Chip
