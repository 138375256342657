import { cx } from 'shared/helpers'
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/20/solid'

type Props = {
  currentPage: number
  totalPages: number
  handlePageClick: (currentPage: number, totalPages: number) => void
}

const PageElement = ({ currentPage, totalPages, handlePageClick }: Props) => {
  const delta = 1
  const range = []

  for (
    let i = Math.max(2, currentPage - delta);
    i <= Math.min(totalPages - 1, currentPage + delta);
    i += 1
  ) {
    range.push(i)
  }

  if (currentPage - delta > 2) {
    range.unshift('...')
  }

  if (currentPage + delta < totalPages - 1) {
    range.push('...')
  }

  range.unshift(1)
  if (totalPages !== 1) range.push(totalPages)

  return (
    <>
      {range.map((i, index) => {
        return !isNaN(Number(i)) ? (
          <button
            key={index}
            className={cx(
              `${
                currentPage === Number(i)
                  ? 'border-blue font-bold text-blue'
                  : 'border-transparent font-bold text-gray-dark'
              }`,
              `inline-flex items-center border-t-2 px-4 pt-4 text-lg hover:cursor-pointer hover:border-gray-darkest hover:text-gray-darkest active:border-blue active:text-blue`,
            )}
            onClick={() => handlePageClick(Number(i), totalPages)}
          >
            {i}
          </button>
        ) : (
          <span
            key={index}
            className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-lg font-bold"
          >
            {i}
          </span>
        )
      })}
    </>
  )
}

export default function Pagination({
  currentPage,
  totalPages,
  handlePageClick,
}: Props) {
  return (
    <nav className="m-4 flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          className={cx(
            'inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-lg font-bold text-gray-dark hover:cursor-pointer hover:border-gray-darkest hover:text-gray-darkest active:border-blue active:text-blue',
            currentPage <= 1 && 'hidden',
          )}
          onClick={() => handlePageClick(currentPage - 1, totalPages)}
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5" aria-hidden="true" />
          Anterior
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        <PageElement
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageClick={handlePageClick}
        />
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          className={cx(
            'inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-lg font-bold text-gray-dark hover:cursor-pointer hover:border-gray-darkest hover:text-gray-darkest active:border-blue active:text-blue',
            currentPage >= totalPages && 'hidden',
          )}
          onClick={() => handlePageClick(currentPage + 1, totalPages)}
        >
          Próxima
          <ArrowLongRightIcon className="ml-3 h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </nav>
  )
}
