type Options = Record<
  string,
  Record<string, { label: string; bgColor: string; selectClasses: string }>
>
const Options: Options = {
  pathProfile: {
    challenge: {
      label: 'Desafio',
      bgColor: 'bg-blue',
      selectClasses: 'text-blue bg-blue/10  !border-white',
    },
    care: {
      label: 'Cuidado',
      bgColor: 'bg-purple-dark',
      selectClasses: 'text-purple-dark bg-purple-light/40 !border-white',
    },
  },
  individualOutput: {
    dependency: {
      label: 'Dependência',
      bgColor: 'bg-yellow',
      selectClasses: 'text-yellow-dark bg-yellow/10 !border-white',
    },
    autonomy: {
      label: 'Autonomia',
      bgColor: 'bg-green-dark',
      selectClasses: 'text-green-dark bg-green/10 !border-white',
    },
    differentiated: {
      label: 'Diferenciada',
      bgColor: 'bg-green-darkest',
      selectClasses: 'text-green-darkest bg-green-dark/30 !border-white',
    },
  },
  teamwork: {
    individual: {
      label: 'Individual',
      bgColor: 'bg-yellow',
      selectClasses: 'text-yellow-dark bg-yellow/10 !border-white',
    },
    member: {
      label: 'Membro',
      bgColor: 'bg-green-dark',
      selectClasses: 'text-green-dark bg-green/10 !border-white',
    },
    reference: {
      label: 'Referência',
      bgColor: 'bg-green-darkest',
      selectClasses: 'text-green-darkest bg-green-dark/30 !border-white',
    },
  },
  potential: {
    low: {
      label: 'Baixo',
      bgColor: 'bg-yellow',
      selectClasses: 'text-yellow-dark bg-yellow/10 !border-white',
    },
    medium: {
      label: 'Médio',
      bgColor: 'bg-green-dark',
      selectClasses: 'text-green-dark bg-green/10 !border-white',
    },
    high: {
      label: 'Alto',
      bgColor: 'bg-green-darkest',
      selectClasses: 'text-green-darkest bg-green-dark/30 !border-white',
    },
  },
  culturalFit: {
    advocate: {
      label: 'Promotor',
      bgColor: 'bg-green-darkest',
      selectClasses: 'text-green-darkest bg-green-dark/30 !border-white',
    },
    high: {
      label: 'Alto',
      bgColor: 'bg-green-dark',
      selectClasses: 'text-green-dark bg-green/10 !border-white',
    },
    adequate: {
      label: 'Adequado',
      bgColor: 'bg-green',
      selectClasses: 'text-green bg-green-dark/5 !border-white',
    },
    low: {
      label: 'Baixo',
      bgColor: 'bg-yellow',
      selectClasses: 'text-yellow-dark bg-yellow-dark/10 !border-white',
    },
    detractor: {
      label: 'Detrator',
      bgColor: 'bg-red',
      selectClasses: 'text-red bg-red/10 !border-white',
    },
  },
  salaryScore: {
    '0': {
      label: 'Acima',
      bgColor: 'bg-yellow-dark',
      selectClasses: 'text-yellow-dark bg-yellow-dark/10 !border-white',
    },
    '4': {
      label: 'Dentro',
      bgColor: 'bg-green',
      selectClasses: 'text-green bg-green-dark/5 !border-white',
    },
    '8': {
      label: 'Abaixo',
      bgColor: 'bg-red',
      selectClasses: 'text-red bg-red/10 !border-white',
    },
  },
  lossImpactScore: {
    '-4': {
      label: 'Baixo',
      bgColor: 'bg-green',
      selectClasses: 'text-green bg-green/10 !border-white',
    },
    '1': {
      label: 'Médio',
      bgColor: 'bg-yellow-dark',
      selectClasses: 'text-yellow-dark bg-yellow-dark/10 !border-white',
    },
    '2': {
      label: 'Alto',
      bgColor: 'bg-red',
      selectClasses: 'text-red bg-red/10 !border-white',
    },
  },
  recognitionReadinessScore: {},
}

const meritPercentageStyle = (value: number | null | undefined) => {
  if (value === null || value === undefined)
    return { label: 'Não preenchido', bgColor: 'bg-gray' }

  const label = `${value}%`
  if (value < 50) {
    return { label, bgColor: 'bg-red' }
  } else if (value < 75) {
    return { label, bgColor: 'bg-yellow' }
  } else {
    return { label, bgColor: 'bg-green' }
  }
}

const riskPercentageStyle = (value: number | null | undefined) => {
  if (value === null || value === undefined)
    return { label: 'Não preenchido', bgColor: 'bg-gray' }

  const label = `${value}%`
  if (value < 34) {
    return { label, bgColor: 'bg-yellow' }
  } else if (value < 67) {
    return { label, bgColor: 'bg-green' }
  } else {
    return { label, bgColor: 'bg-red' }
  }
}

const labelAndColorByValue = (
  result_type: keyof typeof Options,
  value: string | number | null | undefined,
) => {
  if (value === null || value === undefined) {
    return { label: 'Não preenchido', bgColor: 'bg-gray' }
  }
  const option = Options[result_type]
  return option[value.toString()]
}

export {
  Options,
  labelAndColorByValue,
  meritPercentageStyle,
  riskPercentageStyle,
}
