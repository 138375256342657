import { cx } from 'shared/helpers'

export default function Indicator({
  className,
  ...props
}: JSX.IntrinsicElements['div']) {
  return (
    <div
      className={cx(
        'rounded-md px-4 py-2 text-center font-bold text-white',
        className,
      )}
      {...props}
    />
  )
}
