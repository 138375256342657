import * as React from 'react'
import { Fetch } from 'croods'
import { Skeleton } from '@material-ui/lab'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import HeaderControls from 'shared/ui/HeaderControls'
import { useSearch } from 'shared/utils/hooks'
import { getFullName } from 'shared/helpers'
import { useNavigate } from 'react-router-dom'
import NextConversationCard from './NextConversationCard'

const NextConversations = ({
  maxConversations,
}: {
  maxConversations?: number
}) => {
  const { setSearch, select } = useSearch()
  const navigate = useNavigate()

  return (
    <div>
      <h3 className="mb-4">Próximas Conversas</h3>
      {!maxConversations && (
        <HeaderControls searchLabel="Buscar" setSearch={setSearch} />
      )}
      <Fetch
        name="nextConversations"
        renderLoading={() => <Skeleton />}
        renderError={() => (
          <FeedbackMessage message="Erro ao buscar próximas conversas" />
        )}
        renderEmpty={() => (
          <FeedbackMessage
            message="Nenhuma próxima conversa"
            serverMessage=""
            type="warning"
          />
        )}
        render={(list) => {
          const conversations = select(list, ({ teamMember }) =>
            getFullName(teamMember.user),
          )

          const conversationsToShow = maxConversations
            ? conversations.slice(0, maxConversations)
            : conversations

          if (conversationsToShow.length === 0) return null
          const showMore = maxConversations
            ? conversations.length > maxConversations
            : false

          return (
            <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:flex-wrap">
              {conversationsToShow.map((conversation: any) => (
                <NextConversationCard
                  key={conversation.id}
                  conversation={conversation}
                />
              ))}
              {showMore && (
                <button
                  type="button"
                  className="md:basis-0 md:min-w-[180px] min-h-[160px] items-center  flex justify-center rounded-2xl border text-base font-semibold uppercase text-blue-dark/80 transition hover:bg-blue-lightest/10"
                  onClick={() => navigate('/conversas/proximas-conversas')}
                >
                  Ver todas
                </button>
              )}
            </div>
          )
        }}
      />
    </div>
  )
}

export default NextConversations
