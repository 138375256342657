import * as React from 'react'
import { addOrRemoveValue } from 'shared/helpers'

type Props = {
  openKeys: number[]
  toggleKey: (value: number) => void
  setOpenKeys: React.Dispatch<React.SetStateAction<number[]>>
}

const ExpandableContext = React.createContext<Props>({} as Props)

export const ExpandableProvider: React.FC = ({ children }) => {
  const [openKeys, setOpenKeys] = React.useState<number[]>([])

  const toggleKey = (value: number) => {
    setOpenKeys(addOrRemoveValue(openKeys, value))
  }

  return (
    <ExpandableContext.Provider
      value={{
        openKeys,
        toggleKey,
        setOpenKeys,
      }}
    >
      {children}
    </ExpandableContext.Provider>
  )
}

export const useExpandable = () => React.useContext(ExpandableContext)
