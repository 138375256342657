import React from 'react'
import StarRounded from '@material-ui/icons/StarRounded'
import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded'

import { Evaluator } from '@seasonedsoftware/ui'
import type { SelfEvaluationRatingValues } from 'talks/types'

type Props = {
  selfEvaluationRating?: SelfEvaluationRatingValues
  handleSave: (value: { [index: string]: string | number | null }) => void
  setIsValidated: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SelfEvaluationRating({
  selfEvaluationRating,
  handleSave,
  setIsValidated,
}: Props) {
  const handleChange = ({
    selfEvaluation,
  }: {
    selfEvaluation: SelfEvaluationRatingValues
  }) => {
    setIsValidated(!!selfEvaluation)
    if (selfEvaluation) {
      handleSave({ selfEvaluation })
    }
  }

  return (
    <div>
      <p className="mb-2 text-base font-bold">Qual sua autoavaliação geral?</p>
      <div className="blue-stars inline-block rounded-lg border p-2 focus-within:border-blue">
        <Evaluator
          subjects={[`selfEvaluation`]}
          onChange={handleChange}
          value={selfEvaluationRating || 0}
          emptyIcon={<StarOutlineRoundedIcon />}
          icon={<StarRounded />}
        />
      </div>
    </div>
  )
}
