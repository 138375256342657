import * as React from 'react'
import { useTemplate } from 'shared/utils/useTemplate'
import { typeToIcon } from 'shared/utils/conversationLookup'
import Form from 'shared/formsv2/form'
import type * as z from 'zod'
import isAfter from 'date-fns/isAfter'
import isEqual from 'date-fns/isEqual'
import DateInput from 'shared/formsv2/date-input'
import SubmitButton from 'shared/formsv2/submit-button'
import RichtextInput from 'shared/formsv2/richtext-input'
import { dateStringISOToBr } from 'shared/schema-helpers'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import EditDialog from 'talks/conversations/ConversationEditDialog'
import type { OnboardingConversation } from 'talks/types'
import CustomTooltip from '../CustomTooltip'
import isNil from 'lodash/isNil'

type Props = {
  schema: z.ZodObject<any>
  conversation?: OnboardingConversation
  user?: {
    admissionDate?: string
  }
  dateSave?: (data: Record<string, string>) => Promise<boolean>
  convoSave: (data: Record<string, string>) => Promise<boolean>
  error?: boolean
  afterSuccess?: () => void
}
function ConversationForm({
  schema,
  conversation,
  user,
  dateSave,
  convoSave,
  error,
  afterSuccess,
}: Props) {
  let { snackbar } = useSnackbar()
  let template = useTemplate('onboarding')
  let isEditing = Boolean(conversation)
  let actualSchema = (
    dateSave
      ? schema
      : schema.pick({
          date: true,
          comment: true,
        })
  ).refine(checkDateIsAfterAdmission(user?.admissionDate), {
    message: 'Não pode ser antes da data de admissão do colaborador.',
    path: ['date'],
  })

  async function handleSubmit(data: z.infer<typeof schema>) {
    let { date, comment, admissionDate } = data
    let results = [await convoSave({ date, comment })]
    if (dateSave) {
      results.push(await dateSave({ admissionDate }))
    }
    if (results.every(Boolean)) {
      snackbar({ message: 'Salvo com sucesso', type: 'success' })
      afterSuccess?.()
      return true
    }
    return false
  }

  React.useEffect(() => {
    if (error)
      snackbar({ message: 'Erro ao salvar conversação', type: 'error' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <div className="flex flex-col">
      <header className="flex flex-col text-gray-darkest text-opacity-80">
        <div className="flex items-center gap-4">
          {typeToIcon('onboarding')}
          <h4 className="text-gray-darkest first-letter:uppercase">
            Onboarding
          </h4>
        </div>
        <p className="mt-2 text-base italic text-gray-dark">
          Histórias | Sonhos | Entregas desejadas
        </p>
        <p className="mt-4 text-base text-gray-dark">
          Uma boa conversa de onboarding tem por objetivo compartilhar as
          histórias, conhecer os sonhos e acordar a primeira entrega.
        </p>
      </header>
      <Form
        action="/"
        className="mt-6 flex flex-col gap-6"
        onSubmit={handleSubmit}
        mode="onChange"
        schema={actualSchema}
      >
        <div className="flex flex-col">
          <DateInput
            defaultValue={getDefaultDateString(conversation?.date)}
            required
            name="date"
            label="Data"
          />
        </div>
        <div className="flex flex-col">
          <CustomTooltip className="-mb-4" text={template?.infoComment ?? ''} />

          <RichtextInput
            required
            defaultValue={conversation?.comment ?? ''}
            name="comment"
            label="Memórias da conversa"
            hint="Descreva como foi a conversa."
            rows={5}
          />
        </div>
        {dateSave && (
          <div className="flex flex-col">
            <DateInput
              defaultValue={getDefaultDateString(user?.admissionDate)}
              required
              name="admissionDate"
              label="Data de admissão"
            />
          </div>
        )}
        <div className="mt-8 flex items-center justify-end gap-4">
          <SubmitButton className="order-last rounded bg-purple px-3 py-2 text-lg font-bold uppercase text-white transition hover:bg-purple-dark">
            Salvar e enviar
          </SubmitButton>
          {isEditing && <EditDialog />}
        </div>
      </Form>
    </div>
  )
}

function getDefaultDateString(date?: string): string {
  if (!isNil(date)) {
    return dateStringISOToBr(date)
  }

  return new Date().toLocaleDateString('pt-br')
}

function checkDateIsAfterAdmission(persistedDate?: string) {
  return (data: Record<string, string>) => {
    let admissionDate = data.admissionDate || persistedDate
    if (!admissionDate) return true
    return (
      isAfter(new Date(data.date), new Date(admissionDate)) ||
      isEqual(new Date(data.date), new Date(admissionDate))
    )
  }
}

export { getDefaultDateString, checkDateIsAfterAdmission }
export default ConversationForm
