import React from 'react'
import { useNavigate } from 'react-router-dom'

import Modal from 'shared/ui/Modal'
import Icon from 'shared/ui/icon'

const ExitConfirmationModal = ({
  title = 'Deseja realmente sair?',
  exitWarning = 'O cadastro irá ser interrompido!',
  handleNavigate,
  setOpenConfirm,
  openConfirm,
}: any) => {
  const navigate = useNavigate()
  return (
    <Modal.Wrapper
      onConfirm={async () => {
        setOpenConfirm(false)
        navigate(handleNavigate)
      }}
      open={openConfirm}
      onClose={() => setOpenConfirm(false)}
    >
      <Modal.Header>
        <div className="flex items-start gap-x-4">
          <div className="border-r-2 border-gray-400 pr-4 text-gray-500">
            <Icon icon="warning-outline" />
          </div>
          <h5 className="mx-5">{title}</h5>
        </div>
      </Modal.Header>
      <Modal.Content>
        <h5 className="text-gray-500">{exitWarning}</h5>
      </Modal.Content>
      <Modal.Actions cancelLabel="não">
        <Modal.Confirm>Sim</Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default ExitConfirmationModal
