import React from 'react'
import { Link } from 'react-router-dom'

import { getTypeOf } from 'shared/helpers'
import SubmitButton from 'shared/forms/SubmitButton'
import Icon from 'shared/ui/icon'

type Props = {
  loading?: boolean
  disabled?: boolean
  backButtonLabel?: string
  nextButtonLabel?: string
  onBack?: string | (() => void) | React.FC<Props>
  onNext?: () => void
}
const StepperActions: React.FC<Props> = ({
  loading = false,
  disabled,
  onBack = () => window.history.back(),
  onNext = () => {},
  backButtonLabel = 'Voltar',
  nextButtonLabel = 'Avançar',
}) => (
  <section className="flex items-center justify-between bg-blue-50 p-6 text-center">
    {getTypeOf(onBack) === 'string' ? (
      <SubmitButton
        title={backButtonLabel}
        variant="text"
        color="primary"
        type="button"
        to={onBack}
        component={Link}
      >
        {backButtonLabel}
      </SubmitButton>
    ) : (
      <SubmitButton
        title={backButtonLabel}
        variant="text"
        color="primary"
        type="button"
        onClick={onBack}
      >
        {backButtonLabel}
      </SubmitButton>
    )}
    <SubmitButton
      title={nextButtonLabel}
      disabled={disabled}
      loading={loading}
      variant="outlined"
      endIcon={<Icon icon="arrow-forward" />}
      onClick={onNext}
    >
      {nextButtonLabel}
    </SubmitButton>
  </section>
)

export default StepperActions
