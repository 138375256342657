import { useCroods } from 'croods'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import TeamTable from '../membersEvaluation/TeamTable'
import { useCroodsSaveQueue } from '../membersEvaluation/useCroodsQueue'
import useOrganization from 'shared/organization/useOrganization'
import { Fetch } from 'croods'
import Skeleton from 'shared/ui/skeleton'
import NineBox from 'performance/evaluations/membersEvaluation/nineBox'
import Hidden from '@material-ui/core/Hidden'
import MobileWarning from 'shared/ui/MobileWarning'
import { isEmpty } from 'lodash'

const TalentRate = () => {
  const [
    { list: listMemberEvaluations, listError, fetchingList },
    { save, fetch },
  ] = useCroods({
    name: 'memberEvaluations',
    fetchOnMount: true,
  })

  const [
    { list: roles, listError: rolesError, fetchingList: fetchingRoles },
    { save: rolesSave },
  ] = useCroods({
    name: 'organizationRoles',
    fetchOnMount: true,
  })

  const { evaluationWindowStatus } = useOrganization()

  const handleSave = useCroodsSaveQueue(save)

  if (isEmpty(listMemberEvaluations) && (fetchingList || fetchingRoles)) return <Skeleton height="264.66px" />

  return (
    <div>
      <Fetch
        name="memberEvaluationsNineBox"
        path="/member_evaluations/nine_box"
        render={({
          boxes,
          organizationCycleStartsAt,
          organizationCycleFinishesAt,
          canEdit,
        }) => {
          if (boxes && boxes.length > 0)
            return (
              <NineBox
                boxes={boxes}
                cycle={{
                  organizationCycleStartsAt,
                  organizationCycleFinishesAt,
                }}
                canEdit={canEdit}
                fetch={fetch}
              />
            )

          return (
            <Hidden mdDown>
              <div className="flex flex-col space-y-4">
                {listError ? (
                  <FeedbackMessage
                    message="O período de avaliações do ciclo atual ainda não começou e não foram encontradas avaliações do ciclo passado"
                    type="warning"
                    serverMessage=""
                  />
                ) : (
                  listMemberEvaluations?.length === 0 &&
                  !fetchingList && (
                    <FeedbackMessage
                      message="Nenhum talento para avaliar"
                      serverMessage=""
                      type="warning"
                    />
                  )
                )}
                {rolesError && (
                  <FeedbackMessage message="Erro ao buscar por papéis" />
                )}
                {Boolean(
                  evaluationWindowStatus !== 'closed' &&
                    listMemberEvaluations?.length,
                ) && (
                  <TeamTable
                    save={handleSave}
                    allRoles={roles}
                    allTeams={listMemberEvaluations || []}
                    rolesSave={rolesSave}
                  />
                )}
              </div>
            </Hidden>
          )
        }}
        renderLoading={() => <Skeleton height="264.66px" />}
        renderError={(error) => <FeedbackMessage message={error} />}
      />

      <Hidden lgUp>
        <div className="mx-auto my-9 flex justify-center">
          <MobileWarning />
        </div>
      </Hidden>
    </div>
  )
}
export default TalentRate
