import * as React from 'react'

const ConversationIconOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
  >
    <path
      d="M16.6667 9.70021C16.6667 12.5262 13.6819 14.6125 10 14.6125L6.45834 16.2499L6.25 13.9315C4.48936 13.0105 3.33334 11.459 3.33334 9.70021C3.33334 6.87419 6.3181 4.58325 10 4.58325C13.6819 4.58325 16.6667 6.87419 16.6667 9.70021Z"
      stroke="currentColor"
      strokeWidth="2.3"
      strokeLinejoin="round"
    />
  </svg>
)

export default ConversationIconOutline
