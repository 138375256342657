import * as React from 'react'
import Modal from 'shared/ui/Modal'
import AiIcon from 'shared/ui/icon/AiIcon'
import { isEmpty, map, mapValues, some } from 'lodash'

type CircleGoalDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: (
    goalDescriptions: { id: number; goalDescription: string }[],
  ) => Promise<void>
  missingGoalDescriptions: {
    id: number
    label: string
  }[]
}

const CircleGoalDialog = ({
  open,
  onClose,
  onConfirm,
  missingGoalDescriptions,
}: CircleGoalDialogProps) => {
  const [goalDescriptions, setGoalDescriptions] = React.useState<{
    [n: number]: string
  }>({})
  React.useEffect(() => {
    if (isEmpty(goalDescriptions)) {
      setGoalDescriptions(
        Object.assign(
          {},
          ...map(missingGoalDescriptions, ({ id }) => ({ [id]: '' })),
        ),
      )
    }
  }, [missingGoalDescriptions]) // eslint-disable-line

  const disableButton = some(mapValues(goalDescriptions), isEmpty)

  const updateGoalDescriptions = (id: number) => (event: any) => {
    setGoalDescriptions((prevState) => ({
      ...prevState,
      [id]: event.target.value,
    }))
  }

  return (
    <Modal.Wrapper
      open={open}
      onClose={onClose}
      onConfirm={() =>
        onConfirm(
          map(goalDescriptions, (value, key) => ({
            id: Number(key),
            goalDescription: value,
          })),
        )
      }
      className="md:min-w-[600px]"
    >
      <Modal.Header>
        <h5 className="flex items-center">
          <AiIcon fill="#2D2D2C" className="inline pr-2" />
          AI quase pronta
        </h5>
      </Modal.Header>
      <Modal.Content className="!p-10 max-w-[600px] gap-6">
        <p className="label">Precisamos de mais informações.</p>
        {missingGoalDescriptions.map(
          (teamInfo: { id: number; label: string }) => (
            <div className="flex flex-col" key={teamInfo?.label + teamInfo?.id}>
              <label
                htmlFor={`goalDescriptionInput${teamInfo?.id}`}
                className="label"
              >
                {`O que o círculo ${teamInfo?.label} faz?`}
              </label>
              <input
                id={`goalDescriptionInput${teamInfo?.id}`}
                maxLength={120}
                required
                className="input mb-2"
                placeholder="Ex.: Desenvolvimento de software"
                onChange={updateGoalDescriptions(teamInfo.id)}
                value={goalDescriptions[teamInfo.id]}
              />
            </div>
          ),
        )}
        <p className="text-base text-gray-darker">
          Isso ajudará a AI (Inteligência Artificial) a gerar melhores
          sugestões. Poderá ser alterado depois, ao editar o círculo(s).
        </p>
      </Modal.Content>
      <Modal.Actions cancelLabel="CANCELAR">
        <Modal.Confirm disabled={disableButton} className="bt-contained">
          AVANÇAR
        </Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default CircleGoalDialog
