import React from 'react'
import indexOf from 'lodash/indexOf'

import Section from 'shared/ui/section'

import Stepper from './Stepper'
import PerformerForm from './steps/PerformerForm'
import OpeningForm from './steps/openingForm'
import ApproversForm from './steps/approverForm'
import Summary from './steps/summary'

import useFormSteps, {
  STEPS,
  OPENING_STEP,
  APPROVER_STEP,
  PERFORMER_STEP,
  SUMMARY_STEP,
} from './useFormSteps'

export const approvalsHandleNext = (handleNext: any) => (formValues: any) => {
  const approvalsWithLevels = formValues.approvalsAttributes
    .filter(
      (approval: any) =>
        approval.approversAttributes && approval.approversAttributes.length,
    )
    .map((approval: any, index: number) => ({ ...approval, level: index + 1 }))

  return handleNext({ ...formValues, approvalsAttributes: approvalsWithLevels })
}

const New = ({ template = {} }: any) => {
  const { step, values, saving, saveError, handleNext, handleNavigate } =
    useFormSteps({ initialValues: template, initialStep: OPENING_STEP })

  const activeStep = indexOf(STEPS, step)

  return (
    <Section
      title={
        template.id
          ? 'Editar Modelo de Solicitação'
          : 'Novo Modelo de Solicitação'
      }
    >
      <div className="flex w-full flex-col">
        <Stepper
          steps={['Abertura', 'Aprovação', 'Execução', 'Publicação']}
          activeStep={activeStep}
          handleNavigate={handleNavigate}
        />
        <OpeningForm
          submitting={saving}
          error={saveError}
          handleSubmit={handleNext}
          visible={step === OPENING_STEP}
          handleNavigate="/configuracoes/modelos-de-solicitacao"
          filledValues={values}
          activeStep={activeStep}
        />

        <ApproversForm
          submitting={saving}
          error={saveError}
          handleSubmit={approvalsHandleNext(handleNext)}
          visible={step === APPROVER_STEP}
          handleNavigate={handleNavigate}
          filledValues={values}
          activeStep={activeStep}
        />

        <PerformerForm
          submitting={saving}
          error={saveError}
          handleSubmit={handleNext}
          visible={step === PERFORMER_STEP}
          handleNavigate={handleNavigate}
          filledValues={values}
          activeStep={activeStep}
        />

        <Summary
          submitting={saving}
          error={saveError}
          values={values}
          handleSubmit={handleNext}
          visible={step === SUMMARY_STEP}
          handleNavigate={handleNavigate}
          filledValues={values}
          activeStep={activeStep}
        />
      </div>
    </Section>
  )
}

export default New
