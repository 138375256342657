import useOrganization from 'shared/organization/useOrganization'
import DateInput from 'shared/forms/DateInput'
import { date, futureDate } from 'shared/forms/validations'

export const FollowUpDate = ({ fields }: { fields: any }) => {
  const organization = useOrganization()
  return (
    <DateInput
      label="Próxima conversa"
      labelClassName="text-base text-gray-darkest"
      minDate={organization.evaluationWindowOpensAt}
      {...fields('raw', 'followUpDate', [date(), futureDate('date')])}
    />
  )
}
