import React, { useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import { useForm } from 'shared/utils/useForm'
import AutocompleteWithSearch from 'shared/forms/AutocompleteWithSearch'
import Step from 'shared/ui/form-step'
import AddQuestions from 'settings/solicitationTemplate/new/addQuestions'
import validateQuestions from 'settings/solicitationTemplate/new/parsers/validateQuestions'
import InfoTooltip from 'shared/ui/Tooltip/InfoTooltip'

const PerformerTooltip = () => (
  <div className="relative">
    <div className="absolute z-50 ml-20">
      <InfoTooltip fontSize="small">
        <div className="flex flex-col gap-3 p-2 text-left text-gray-darker">
          <div className="mb-2">
            <h6 className="font-bold text-gray-darkest">EXECUTORES</h6>
            <p>Escolha quem pode executar essa solicitação.</p>
          </div>

          <div>
            <p className="font-bold">Nome de Colaborador</p>
            <p className="font-light">Colaborador específico.</p>
          </div>

          <div>
            <p className="font-bold">Nome de Círculo</p>
            <p className="font-light">
              Membros do Círculo específico, exceto seu líder.
            </p>
          </div>

          <div>
            <p className="font-bold">&quot;Líder do solicitante&quot;</p>
            <p className="font-light">
              Líder do círculo ao qual o solicitante responde.
            </p>
          </div>
        </div>
      </InfoTooltip>
    </div>
  </div>
)

const PerformerForm = ({
  handleSubmit,
  submitting = false,
  error,
  visible,
  handleNavigate,
  filledValues,
  activeStep,
}: any) => {
  const initialState = {
    performersAttributes: filledValues?.performersAttributes || [],
    performerInputsAttributes: filledValues?.performerInputsAttributes || [],
  }

  const { formState, fields, hasErrors } = useForm(initialState, {
    requiredFields: ['performersAttributes'],
  })

  useEffect(() => {
    if (filledValues) {
      for (const [attribute, value] of Object.entries(filledValues)) {
        formState.setField(attribute as keyof typeof initialState, value)
      }
    }
  }, [formState, filledValues])

  const onSubmit: React.FormEventHandler = (e) => {
    e && e.preventDefault && e.preventDefault()
    handleSubmit && !hasErrors && handleSubmit(formState.values)
  }

  const autocompleteFields = fields(
    'raw',
    'performersAttributes',
    [(value) => (isEmpty(value) ? 'Campo obrigatório' : undefined)],
    {
      touchOnChange: true,
      compare: isEqual,
    },
  )

  return (
    <Step
      handleSubmit={onSubmit}
      error={error}
      disableButton={hasErrors}
      submitting={submitting}
      visible={visible}
      backButtonLabel="Voltar"
      handleNavigate={handleNavigate(activeStep - 1)}
    >
      <div>
        <PerformerTooltip />

        <AutocompleteWithSearch
          croodsConfig={{
            name: 'search_performer',
            path: '/search/performer',
          }}
          autocompleteProps={{
            ...autocompleteFields,
            disabledOptions: (option: any) =>
              (autocompleteFields.value as unknown as any[]).some(
                (anotherOption) =>
                  option.kind === anotherOption.kind &&
                  option.value === anotherOption.value,
              ),
            getOptionSelected: (option, anotherOption) =>
              option.kind === anotherOption.kind &&
              option.value === anotherOption.value,
          }}
          inputProps={{
            label: 'Executores',
            name: 'Executores',
            placeholder:
              'Ex. João da Silva, Financeiro, Líder do solicitante, etc.',
          }}
          defaultValue={initialState.performersAttributes}
        />
        <p className="form-helper-text">
          Selecione colaboradores ou círculos específicos utilizando seus nomes. Para executores genéricos, utilize o papel &quot;Líder do solicitante&quot;.
        </p>
      </div>

      <div>
        <AddQuestions
          {...fields(
            'raw',
            'performerInputsAttributes',
            // @ts-expect-error
            [validateQuestions()],
            {
              touchOnChange: true,
              compare: isEqual,
            },
          )}
          step="performer"
        />
      </div>
    </Step>
  )
}

export default PerformerForm
