import { Link } from 'react-router-dom'
import Icon from 'shared/ui/icon'

const Item = ({ name, link }: { name: string; link: string }) => (
  <div className="card transition-shadow hover:shadow-lg">
    <Link
      className="group flex items-center gap-2 text-gray-dark transition-all hover:bg-blue hover:bg-opacity-10 hover:text-blue-dark sm:gap-4"
      to={link}
      title={name}
    >
      <div className="border-r bg-gray-lightest p-10 text-current group-hover:border-blue-dark group-hover:bg-blue group-hover:bg-opacity-70">
        <Icon icon="play-list-add" />
      </div>
      <div className="grow overflow-hidden text-ellipsis whitespace-nowrap p-2 pr-6 text-base font-bold">
        {name}
      </div>
    </Link>
  </div>
)

export default Item
