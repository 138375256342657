import * as React from 'react'

import type {
  MemberEvaluation,
  SelectType,
} from 'performance/evaluations/membersEvaluation/types'
import { cx } from 'shared/helpers'
import type { FormState, Inputs, StateErrors } from 'react-use-form-state'
import { presence } from 'shared/forms/validations'
import { Options } from 'performance/evaluations/membersEvaluation/labelAndColorByValue'

type Props = {
  title: string
  type: SelectType
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  inputs: Inputs<MemberEvaluation>
  selected:
    | MemberEvaluation['pathProfile']
    | MemberEvaluation['individualOutput']
    | MemberEvaluation['teamwork']
    | MemberEvaluation['potential']
    | MemberEvaluation['culturalFit']
    | MemberEvaluation['salaryScore']
    | MemberEvaluation['lossImpactScore']
    | MemberEvaluation['recognitionReadinessScore']
    | ''
  formState: FormState<MemberEvaluation, StateErrors<MemberEvaluation, string>>
}

export default function ({
  title,
  type,
  onChange,
  inputs,
  selected,
  formState,
}: Props) {
  const options = Options[type]

  const selectedClasses = (selectedValue: typeof selected) => {
    if (selectedValue === undefined || selectedValue === '')
      return 'bg-white text-white'

    const { selectClasses } =
      options[`${selectedValue}` as keyof typeof options] || {}

    return selectClasses
  }

  React.useEffect(() => {
    if (formState && selected === null) {
      formState.setFieldError(type, 'Obrigatório')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <td className="mx-1 w-full">
      <select
        title={title}
        data-type={type}
        className={cx(
          'w-full min-w-[88px] rounded border border-gray-dark px-2 py-[10px] text-sm font-bold',
          selected !== undefined && selected !== ''
            ? selectedClasses(selected)
            : 'bg-white text-white',
        )}
        // This is illegal and should be changed.
        // Queueing an update for any other component from within the actual rendering body of a function component is illegal.
        {...inputs.select({
          name: type,
          onChange,
          validate: (value) => presence('Obrigatório')(value),
        })}
        value={selected === null ? '' : selected}
        required
      >
        <option value="" disabled hidden>
          Selecione
        </option>
        {Object.entries(options).map(([key, option]) => (
          <option key={key} value={key} className="bg-white text-gray-darkest">
            {option.label}
          </option>
        ))}
      </select>
      {formState.errors?.[type] && (
        <div className="mt-1">
          <p className="text-xs text-gray">{formState.errors[type]}</p>
        </div>
      )}
    </td>
  )
}
