import * as React from 'react'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import { Skeleton } from '@material-ui/lab'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { useCroods } from 'croods'
import filter from 'lodash/filter'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import {
  SkillPlanningCard,
  SkillEvaluationCard,
  SkillPlanningWithAI,
} from 'talks/conversations/career/shared/careerSkills/Card'
import type { Skill } from './types'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import useOrganization from 'shared/organization/useOrganization'
import AiIcon from 'shared/ui/icon/AiIcon'
import CircleGoalDialog from './Card/CircleGoalDialog'
import { useDataRefreshOnInterval } from './polling'
import { LoadingDialog } from './loading-dialog'
import Modal from 'shared/ui/Modal'
import { cx } from 'shared/helpers'
import { InfoIconOutline } from 'shared/ui/icon/InfoIconOutline'
import { AILoadingAnimation } from './ai-loading-animation'
import { z } from 'zod'
import { useTrackAnalytics } from 'domain/analytics'

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: `0px 2px 6px 2px #00000005,0px 1px 1px 0px #00000024,0px 1px 3px 0px #0000001f`,
    margin: '5px',
  },
}))(Tooltip)

function skillValid({
  developmentEvidence,
  developmentCommitment,
  pausedAt,
}: Skill): Boolean {
  return (
    (Boolean(developmentEvidence) && Boolean(developmentCommitment)) ||
    Boolean(pausedAt)
  )
}

type CheckboxOption = {
  name: string
  description: string
  justification: string
}
type CheckboxDialogProps = {
  open: boolean
  onClose: () => void
  options: CheckboxOption[]
  cbSelectedOptions: (selectedOptions: CheckboxOption[]) => void
  saving: boolean
  setOptions: React.Dispatch<React.SetStateAction<CheckboxOption[]>>
  aiEndpointPath: string
}
function CheckboxDialog({
  open,
  onClose,
  options,
  cbSelectedOptions,
  saving,
  setOptions,
  aiEndpointPath,
}: CheckboxDialogProps) {
  const { snackbar } = useSnackbar()
  const [selectedIndexes, setSelectedIndexes] = React.useState<number[]>([])
  const track = useTrackAnalytics()

  const handleCheckbox = (checked: boolean, index: number) => {
    if (checked) {
      setSelectedIndexes((prevState) => [...prevState, index])
    } else {
      setSelectedIndexes((prevState) => prevState.filter((p) => p !== index))
    }
  }

  const { gptSessionId, setGptSessionId } = useDataRefreshOnInterval({
    path: aiEndpointPath,
    callbackSuccess: (data: CheckboxOption[]) => {
      track('Generate skill', { AI: true })

      const filteredData = data.filter(
        ({ name }) => z.string().min(1).safeParse(name).success,
      )
      if (filteredData.length > 0) {
        setOptions((prev) => [...prev, ...filteredData])
      } else {
        snackbar({
          message:
            'Não foi possível sugerir mais competências, tente novamente mais tarde',
          type: 'error',
        })
      }
    },
  })

  const [{ saving: loadingGptSession }, { save: saveGptSession }] = useCroods({
    name: 'gptSessionMoreSkills',
    customPath: aiEndpointPath,
    afterSuccess: ({ data }) => {
      setGptSessionId(data.id)
    },
    afterFailure: (e) => {
      snackbar({
        message: e?.response?.data?.message,
        type: 'error',
      })
    },
  })

  return (
    <Modal.Wrapper
      open={open}
      onClose={() => {
        setSelectedIndexes([])
        setGptSessionId(null)
        onClose()
      }}
      preventClose={true}
      onConfirm={async () => {
        cbSelectedOptions(selectedIndexes.map((i) => options[i]))
        setSelectedIndexes([])
        setGptSessionId(null)
        onClose()
      }}
    >
      <Modal.Header>
        <h5 className="flex items-center">
          <AiIcon fill="#2D2D2C" className="inline pr-2" />
          Selecione as competências mais relevantes sugeridas pela AI
        </h5>
      </Modal.Header>
      <Modal.Content className="p-10">
        <div className="flex max-w-[600px] flex-col gap-6">
          {options.map(({ name, description, justification }, index) => {
            const checked = selectedIndexes.includes(index)
            return (
              <div className="flex gap-4 text-left" key={index}>
                <input
                  id={`checkbox-${index}`}
                  name={`checkbox-${index}`}
                  className="input h-2 w-2 p-2 focus:ring-transparent cursor-pointer"
                  type="checkbox"
                  checked={checked}
                  onChange={() => handleCheckbox(!checked, index)}
                />
                <div className={cx(checked ? 'text-blue' : 'text-gray-darker')}>
                  <div className="flex flex-row gap-2 items-center">
                    <label
                      htmlFor={`checkbox-${index}`}
                      className="cursor-pointer font-bold text-lg text-inherit"
                    >
                      {name}
                    </label>
                    {justification && (
                      <LightTooltip
                        title={
                          <p className="text-gray-darker p-2">
                            {justification}
                          </p>
                        }
                      >
                        <span>
                          <InfoIconOutline className="w-5 h-5 text-gray-dark" />
                        </span>
                      </LightTooltip>
                    )}
                  </div>
                  <p className="text-base">{description}</p>
                </div>
              </div>
            )
          })}
          <div className="flex flex-col items-center self-center">
            {loadingGptSession || gptSessionId ? (
              <AILoadingAnimation />
            ) : (
              <button
                className="ai-button max-w-sm mx-auto"
                onClick={() => {
                  saveGptSession({ method: 'POST' })({
                    skillsAlreadySuggested: options.map(
                      (option) => option.name,
                    ),
                  })
                }}
              >
                <span>
                  <AiIcon />
                  SUGERIR MAIS COMPETÊNCIAS
                </span>
              </button>
            )}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions cancelLabel="CANCELAR">
        <Modal.Confirm
          disabled={selectedIndexes.length < 1 || saving}
          className="bt-contained"
        >
          CONFIRMAR<span className="hidden md:inline">&nbsp;SELECIONADAS</span>
        </Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

function CareerSkillsPlanning({
  teamMemberId,
  developmentPlanLabel,
  setIsValidated,
  conversationId,
}: {
  teamMemberId: string
  secondConversationPhase?: 'evaluation' | 'planning'
  developmentPlanLabel?: string
  setIsValidated: React.Dispatch<React.SetStateAction<boolean>>
  conversationId: string
}) {
  const { snackbar } = useSnackbar()
  const organization = useOrganization()
  const track = useTrackAnalytics()

  const [newSkill, setNewSkill] = React.useState<Skill>()
  const [openTeamGoalDialog, setOpenTeamGoalDialog] = React.useState(false)

  type CheckboxOption = {
    name: string
    description: string
    justification: string
  }

  const aiEndpointPath = `/team_members/${teamMemberId}/career_conversations/${conversationId}/gpt_sessions`

  const [options, setOptions] = React.useState<CheckboxOption[]>([])
  const openOptionsModal = options.length > 0

  const { gptSessionId, setGptSessionId } = useDataRefreshOnInterval({
    path: aiEndpointPath,
    callbackSuccess: (data: CheckboxOption[]) => {
      const filteredData = data.filter(
        ({ name }) => z.string().min(1).safeParse(name).success,
      )
      if (filteredData.length > 0) {
        track('Generate skill', { AI: true })
        setOptions(filteredData)
      } else {
        snackbar({
          message:
            'Não foi possível sugerir competências, tente novamente mais tarde',
          type: 'error',
        })
      }
    },
  })

  const [{ saving: loadingGptSession }, { save: saveGptSession }] = useCroods({
    name: 'gptSession',
    customPath: aiEndpointPath,
    afterSuccess: ({ data }) => {
      setGptSessionId(data.id)
    },
    afterFailure: (e) => {
      snackbar({
        message: e?.response?.data?.message,
        type: 'error',
      })
    },
  })

  const [
    { list: missingGoalDescriptions, listError },
    { save: saveMissingGoalDescriptions, fetch: fetchMissingGoalDescriptions },
  ] = useCroods({
    name: 'missingGoalDescriptions',
    customPath: `/team_members/${teamMemberId}/team_goal`,
    stateId: teamMemberId,
    fetchOnMount: true,
    cache: false,
  })

  const submitGoalDescriptions = async (
    goalDescriptions: { id: number; goalDescription: string }[],
  ) => {
    saveMissingGoalDescriptions({
      method: 'PUT',
      afterSuccess: () => {
        fetchMissingGoalDescriptions()()
        snackbar({ message: 'Descrição salva com sucesso', timeout: 2 })
        saveGptSession({ method: 'POST' })()
      },
      afterFailure: (e) => {
        snackbar({
          message: e?.response?.data?.message,
          type: 'error',
        })
      },
    })({ teams: goalDescriptions })
  }

  const [{ fetchingList, list }, { fetch: fetchSkills, save }] =
    useCroods<Skill>({
      name: 'carrerSkills',
      path: `/team_members/${teamMemberId}/career_skills`,
      fetchOnMount: true,
      cache: false,
      afterSuccess: () => {
        snackbar({ message: 'Rascunho salvo com sucesso', timeout: 2 })
      },
      afterFailure: (e) => {
        snackbar({
          message: e?.response?.data?.message,
          type: 'error',
        })
      },
    })

  const handleSave =
    (options?: { id: number }) =>
    (value: { [index: string]: string | number | null }) => {
      save(options)(value)
    }

  const handleSaveForNewSkill =
    (options?: { id: number }) =>
    (value: { [index: string]: string | number | null }) => {
      save({
        ...options,
        afterSuccess: () => {
          setNewSkill(undefined)
          snackbar({ message: 'Rascunho salvo com sucesso', timeout: 2 })
        },
      })(value)
    }

  const ongoingSkills = filter(
    list,
    (item: Skill) => !item.archivedAt && !item.completedAt,
  )

  const completedSkills = filter(list, (item: Skill) =>
    Boolean(item.completedAt),
  )

  React.useEffect(() => {
    const ongoingSkillsWithoutPaused = ongoingSkills.filter(
      ({ pausedAt }) => !Boolean(pausedAt),
    )

    setIsValidated(
      !isEmpty(ongoingSkills) &&
        every(ongoingSkillsWithoutPaused, (skill) =>
          Boolean(skill.developmentPlan),
        ),
    )
  }, [ongoingSkills]) // eslint-disable-line

  const [{ saving: savingSuggestedSkills }, { save: saveSuggestedSkills }] =
    useCroods({
      id: teamMemberId,
      name: 'bulkCreateCareerSkills',
      customPath: `/team_members/${teamMemberId}/career_skills/bulk_create`,
      afterSuccess: () => {
        snackbar({ message: 'Competências criadas com sucesso', timeout: 2 })
        setOptions([])
        fetchSkills()()
      },
    })

  const aiEnabled =
    !isEmpty(organization.goalDescription) && !Boolean(listError)

  return (
    <>
      <div>
        <h4 className="mt-5 mb-9">Planejamento de competências</h4>
        <div className="mb-4 flex flex-col gap-10">
          {fetchingList && <Skeleton height={400} />}

          {ongoingSkills.map((skill) => {
            return (
              <SkillPlanningWithAI
                key={`ongoingSkill-${skill.id}`}
                skill={skill}
                save={handleSave}
                developmentPlanLabel={developmentPlanLabel}
              />
            )
          })}

          {newSkill && (
            <SkillPlanningCard
              skill={newSkill}
              save={handleSaveForNewSkill}
              hideNewSkill={() => setNewSkill(undefined)}
              developmentPlanLabel={developmentPlanLabel}
            />
          )}

          {!newSkill && !fetchingList && (
            <div className="mb-4 flex flex-col justify-center items-center pt-4 gap-6">
              <div className="min-w-[250px]">
                <LightTooltip
                  disableHoverListener={!isEmpty(organization.goalDescription)}
                  disableTouchListener={!isEmpty(organization.goalDescription)}
                  placement="top"
                  title={
                    <p className="m-1 text-sm text-gray-darkest">
                      Função indisponível para sua organização
                    </p>
                  }
                >
                  <span className="flex flex-col gap-1">
                    <button
                      className="ai-button w-full"
                      onClick={() => {
                        if (
                          typeof missingGoalDescriptions !== 'undefined' &&
                          missingGoalDescriptions.length > 0 &&
                          !missingGoalDescriptions.some(
                            (msg) =>
                              msg.id === undefined || msg.label === undefined,
                          )
                        ) {
                          setOpenTeamGoalDialog(true)
                        } else {
                          saveGptSession({ method: 'POST' })()
                        }
                      }}
                      disabled={!aiEnabled}
                    >
                      <AiIcon />
                      SUGERIR COMPETÊNCIAS
                    </button>
                    {Boolean(listError) && (
                      <small
                        className="form-helper-text text-red-dark"
                        role="alert"
                      >
                        {listError}
                      </small>
                    )}
                  </span>
                </LightTooltip>
              </div>
              <p className="text-lg text-gray-dark">ou</p>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setNewSkill({
                    id: Date.now(),
                    title: '',
                    developmentPercentagePrevious: 0,
                    developmentPercentageCurrent: 0,
                    developmentPercentageNextCycle: 15,
                    developmentPercentageLongTermGoal: 100,
                    aiGenerated: null,
                  })
                }}
                startIcon={<ControlPointIcon />}
              >
                Adicionar Competência
              </Button>
            </div>
          )}

          {completedSkills.length > 0 && (
            <div className="w-full mt-4">
              <h4>Desenvolvimentos concluídos</h4>
            </div>
          )}

          {completedSkills.map((skill) => (
            <SkillPlanningCard
              skill={skill}
              key={`ongoingSkill-${skill.id}`}
              save={handleSave}
              developmentPlanLabel={developmentPlanLabel}
            />
          ))}
        </div>
      </div>
      <LoadingDialog
        open={Boolean(!openOptionsModal && (loadingGptSession || gptSessionId))}
        onClose={() => setGptSessionId(null)}
      />
      <CircleGoalDialog
        open={openTeamGoalDialog}
        onClose={() => setOpenTeamGoalDialog(false)}
        onConfirm={submitGoalDescriptions}
        missingGoalDescriptions={missingGoalDescriptions}
      />
      <CheckboxDialog
        open={openOptionsModal}
        onClose={() => setOptions([])}
        aiEndpointPath={aiEndpointPath}
        options={options}
        setOptions={setOptions}
        cbSelectedOptions={(selectedOptions) => {
          saveSuggestedSkills({ method: 'POST' })({
            careerSkills: selectedOptions.map(
              ({ name, description, justification }) => ({
                generatedName: name,
                generatedDescription: description,
                generatedJustification: justification,
              }),
            ),
          })
        }}
        saving={savingSuggestedSkills}
      />
    </>
  )
}

function CareerSkillsEvaluation({
  teamMemberId,
  setFilledForms,
}: {
  teamMemberId: string
  secondConversationPhase?: 'evaluation' | 'planning'
  setFilledForms: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { snackbar } = useSnackbar()

  const [{ fetchingList, list }, { save }] = useCroods<Skill>({
    name: 'carrerSkills',
    path: `/team_members/${teamMemberId}/career_skills`,
    fetchOnMount: true,
    cache: false,
    afterSuccess: () => {
      snackbar({ message: 'Rascunho salvo com sucesso', timeout: 2 })
    },
    afterFailure: (e) => {
      snackbar({
        message: e?.response?.data?.message,
        type: 'error',
      })
    },
  })

  const handleSave =
    (options?: { id: number }) =>
    (value: { [index: string]: string | number | null }) => {
      checkValidation()
      save(options)(value)
    }

  const ongoingSkills = filter(
    list,
    (item: Skill) =>
      !item.archivedAt &&
      !item.completedAt &&
      !moment(item.createdAt).isSame(moment(), 'day'),
  )

  const completedSkills = filter(list, (item: Skill) =>
    Boolean(item.completedAt),
  )

  const checkValidation = () => {
    setFilledForms(ongoingSkills.every(skillValid))
  }

  const title = 'Avaliação de competências'

  React.useEffect(() => {
    checkValidation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ongoingSkills])

  return (
    <div>
      <h4 className="mt-5 mb-9">{title}</h4>
      <div className="mb-4 flex flex-col gap-10">
        {fetchingList && <Skeleton height={400} />}

        {ongoingSkills.map((skill) => {
          return (
            <SkillEvaluationCard
              key={`ongoingSkill-${skill.id}`}
              skill={skill}
              save={handleSave}
            />
          )
        })}

        {completedSkills.length > 0 && (
          <div className="w-full">
            <h4>Desenvolvimentos concluídos</h4>
          </div>
        )}

        {completedSkills.map((skill) => (
          <SkillEvaluationCard
            key={`ongoingSkill-${skill.id}`}
            skill={skill}
            save={handleSave}
          />
        ))}
      </div>
    </div>
  )
}

export { CareerSkillsPlanning, CareerSkillsEvaluation }
