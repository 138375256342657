import React, { useState, useEffect } from 'react'

import type { TextFieldProps } from '@material-ui/core/TextField'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Icon from 'shared/ui/icon'
import { splitAndTrim } from 'shared/helpers'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  fieldset: {
    '& fieldset': {
      border: 'transparent !important',
    },
  },
}))

type Props = Partial<TextFieldProps> & {
  label?: string
  collection: string[]
  setCollection: (c: string[]) => void
  validate?: (i: string) => boolean
  onTextChange?: (v: string) => void
  helperText?: string
}
const TagsInput: React.FC<Props> = ({
  label,
  name,
  collection,
  setCollection,
  validate = (i) => Boolean(i),
  onTextChange,
  helperText = '',
  ...props
}) => {
  const classes = useStyles()
  const [content, setContent] = useState('')

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Backspace' && content === '') {
      setCollection(collection.slice(0, -1))
    }
    if (event.key === 'Enter' || event.key === ';') {
      event.preventDefault()
      addItem(content ? `${content},` : '')
    }
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    addItem(ev.target.value || '')
  }

  const addItem = (value: string) => {
    const [possibleItem, text] = splitAndTrim(value)
    if (typeof text !== 'undefined' && validate(possibleItem)) {
      setCollection([...collection, possibleItem])
      setContent(text)
    } else {
      setContent(possibleItem)
    }
  }

  const handleDelete = (idx: number) => () => {
    setCollection([...collection.slice(0, idx), ...collection.slice(idx + 1)])
  }

  useEffect(() => {
    onTextChange?.(content)
  }, [content, onTextChange])

  return (
    <>
      {label && (
        <Typography variant="h5" component="div" className="mb-0">
          <label htmlFor={name} className="cursor-pointer">
            {label}
          </label>
        </Typography>
      )}
      <div className="flex flex-wrap items-center gap-[2px] rounded border border-blue px-2.5 pt-2 pb-1 focus-within:border-2 focus-within:border-blue-light">
        {collection.map((item, idx) => (
          <Chip
            key={`item-chip-${idx}`}
            role="listitem"
            className="mb-1.5"
            label={item}
            icon={
              <button type="button" onClick={handleDelete(idx)}>
                <Icon size="sm" icon="close" />
              </button>
            }
            onDelete={handleDelete(idx)}
          />
        ))}
        <TextField
          id={name}
          name={name}
          className="min-w-[15rem] grow pt-2.5 pr-3 pb-3 pl-1"
          variant="outlined"
          {...props}
          InputProps={{ className: classes.fieldset }}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          value={content}
        />
      </div>
      {helperText && <p className="form-helper-text">{helperText}</p>}
    </>
  )
}

export default TagsInput
