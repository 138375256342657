import React from 'react'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

type Props = {
  handleComplete: () => void
}

export default function Complete({ handleComplete }: Props) {
  const handleClick = () => {
    handleComplete()
  }

  return (
    <button
      aria-label="complete"
      type="button"
      onClick={handleClick}
      className="p-4 hover:text-green"
    >
      <CheckCircleOutlineIcon />
    </button>
  )
}
