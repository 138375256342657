import React, { useState } from 'react'
import get from 'lodash/get'

import { useCroods } from 'croods'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { usePendingSolicitation } from 'portal/pendingSolicitations/context'
import Button from 'shared/forms/SubmitButton'
import BulkActionFeedback from './BulkActionFeedback'

const endPointTypes = {
  pending_execution: '/workflow/solicitations/bulk_execute',
  pending_approval: '/workflow/solicitations/bulk_approve',
}

const configuration = {
  pending_execution: {
    successMessage: 'Solicitações finalizadas com sucesso!',
    failureMessage: 'Houve um erro ao finalizar as solicitações',
    buttonLabel: 'finalizar todas',
    feedbackVerb: 'finalizada',
  },
  pending_approval: {
    successMessage: 'Solicitações aprovadas com sucesso!',
    failureMessage: 'Houve um erro ao aprovar as solicitações',
    buttonLabel: 'aprovar todas',
    feedbackVerb: 'aprovada',
  },
}

const BulkActionButton = ({
  pendingAmount,
  solicitationIds,
  actionType,
  filterKey,
}: {
  pendingAmount: number
  solicitationIds: number[]
  actionType: 'pending_approval' | 'pending_execution'
  filterKey: string
}) => {
  const disabled = pendingAmount === 0
  const i18n: typeof configuration['pending_execution'] = get(
    configuration,
    actionType,
  )
  const { fetchPendingSolicitations, resetFilter } = usePendingSolicitation()
  const [modal, setModal] = useState(false)
  const [response, setResponse] = useState({})
  const { snackbar } = useSnackbar()
  const [, { save }] = useCroods({
    name: 'bulkSolicitations',
    customPath: get(endPointTypes, actionType),
    afterSuccess: () => {
      snackbar({
        message: i18n.successMessage,
        type: 'success',
      })
      setModal(true)
    },
    afterFailure: (e) =>
      snackbar({
        message: e?.response?.data.message,
        type: 'error',
      }),
  })

  const handleSave = () => {
    try {
      save({ method: 'POST' })({ solicitationIds }).then(setResponse)
    } catch (problem) {
      snackbar({
        message: i18n.failureMessage,
        type: 'error',
      })
    }
  }

  const handleClose = () => {
    fetchPendingSolicitations()
    resetFilter()
    setModal(false)
  }

  if (filterKey !== actionType) return null

  return (
    <>
      <Button
        className="flex-1 md:flex-none"
        disabled={disabled || modal}
        title={i18n.buttonLabel}
        variant="contained"
        type="button"
        onClick={handleSave}
      >
        {i18n.buttonLabel}
      </Button>
      <BulkActionFeedback
        open={modal}
        info={response}
        onClose={handleClose}
        feedbackVerb={i18n.feedbackVerb}
      />
    </>
  )
}

export default BulkActionButton
