import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { useCroods } from 'croods'
import isEmpty from 'lodash/isEmpty'

import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import GreenRedSwitch from './GreenRedSwitch'
import validatePublishable from './validatePublishable'

const CustomTooltip = ({ message, className, children }: any) => {
  if (!message) return children

  return (
    <Tooltip className={className} title={message} aria-label={message}>
      <span>{children}</span>
    </Tooltip>
  )
}

const Row = ({
  solicitationTemplate,
  parentSolicitationTemplate,
  childSolicitationTemplate,
  updateRows,
}: any) => {
  const { id, name, status, hidden } = solicitationTemplate

  const { snackbar } = useSnackbar()
  const lastStatus = React.useRef(status)
  const lastParentStatus = React.useRef(
    parentSolicitationTemplate && parentSolicitationTemplate.status,
  )

  const updateOptimistically = (newStatus: any) => {
    updateRows((row: any) => {
      if (row.id === id) {
        return {
          ...row,
          status: newStatus,
        }
      }

      if (
        parentSolicitationTemplate &&
        row.id === parentSolicitationTemplate.id
      ) {
        return {
          ...row,
          status: 'inactive',
          hidden: true,
        }
      }

      return row
    })
  }

  const undoOptimisticUpdate = () => {
    updateRows((row: any) => {
      if (row.id === id) {
        return {
          ...row,
          status: lastStatus.current,
        }
      }

      if (
        parentSolicitationTemplate &&
        row.id === parentSolicitationTemplate.id
      ) {
        row = {
          ...row,
          status: lastParentStatus.current,
        }

        delete row.hidden

        return row
      }

      return row
    })
  }

  const [, { save }] = useCroods<{ message: string }>({
    name: 'changeStatus',
    customPath: '/workflow/solicitation_templates/:id/change_status',
    afterFailure: ({ response }) => {
      const message = response?.data?.message || 'Error'
      snackbar({ message, type: 'error' })
      undoOptimisticUpdate()
    },
    afterSuccess: ({ data: { status: newStatus } }) => {
      lastStatus.current = newStatus
      lastParentStatus.current = parentSolicitationTemplate && 'inactive'
    },
  })

  const cannotBePublishMessage = validatePublishable(solicitationTemplate)
  const cannotBeEditedMessage = childSolicitationTemplate
    ? 'Esse modelo de solicitação não pode ser editado porque já possui um rascunho de edição em andamento.'
    : undefined

  const published = status === 'published'
  const disabled = !published && !isEmpty(cannotBePublishMessage)

  if (hidden) {
    return null
  }

  return (
    <tr
      key={id}
      className="border-t border-gray-lightest text-base transition first:border-t-0 hover:bg-blue-50 hover:text-blue-darkest"
    >
      <td className="p-3 md:p-6">
        {parentSolicitationTemplate &&
        !parentSolicitationTemplate.hidden &&
        status === 'draft' ? (
          <span className="ml-4">{name}</span>
        ) : (
          name
        )}
      </td>
      <td className="p-3 md:p-6">
        {status === 'draft' && <span>Rascunho</span>}
        {status === 'published' && <span>Publicado</span>}
        {status === 'unpublished' && <span>Não publicado</span>}
      </td>
      <td className="p-3 text-right md:p-6">
        <CustomTooltip message={cannotBePublishMessage}>
          <GreenRedSwitch
            disabled={disabled}
            data-testid="switch"
            checked={published}
            onChange={() => {
              const newStatus =
                status === 'published' ? 'unpublished' : 'published'

              updateOptimistically(newStatus)
              save({ id })({ status: newStatus })
            }}
          />
        </CustomTooltip>

        <CustomTooltip message={cannotBeEditedMessage}>
          <Button
            disabled={!!childSolicitationTemplate}
            title="editar"
            size="small"
            color="primary"
            variant="outlined"
            component={Link}
            to={`/configuracoes/modelos-de-solicitacao/${id}/editar`}
          >
            editar
          </Button>
        </CustomTooltip>
      </td>
    </tr>
  )
}

export default Row
