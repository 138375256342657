import { useCurrentUser } from 'croods-auth'

import FeedbackMessage from 'shared/ui/FeedbackMessage'
import { Skeleton } from '@material-ui/lab'
import { useCroods } from 'croods'
import CareerInfo from 'talks/conversations/career/info'

const SkillsAndGoals = () => {
  const [{ currentUser }] = useCurrentUser()

  const [{ list: conversation, fetchingList, listError }] = useCroods({
    name: 'lastCareerConversation',
    path: '/career_conversations/last_conversation',
    fetchOnMount: true,
  })

  if (fetchingList) {
    return <Skeleton />
  }

  if (listError) {
    return (
      <FeedbackMessage
        message="Não há conversa de carreira cadastrada"
        serverMessage=""
        type="error"
      />
    )
  }

  //@ts-expect-error
  const { id, publishedAt, supportPlan, nextConversation } = conversation

  return (
    currentUser && (
      <CareerInfo
        id={id}
        conversationDate={publishedAt}
        nextConversation={nextConversation}
        supportPlan={supportPlan}
        snapshot={true}
      />
    )
  )
}

export default SkillsAndGoals
