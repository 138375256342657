import { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { useNavigate } from 'react-router-dom'
import Input from 'shared/forms/Input'
import AutocompleteWithSearch from 'shared/forms/AutocompleteWithSearch'
import InfoTooltip from 'shared/ui/Tooltip/InfoTooltip'
import { presence } from 'shared/forms/validations'
import { useForm } from 'shared/utils/useForm'
import Step from 'shared/ui/form-step'
import AddQuestions from 'settings/solicitationTemplate/new/addQuestions'
import validateQuestions from 'settings/solicitationTemplate/new/parsers/validateQuestions'
import ExitConfirmationModal from 'shared/ui/ExitConfirmationModal'
import DocumentsInput from './DocumentsInput'
import Documents from './Documents'

const ApplicantTooltip = () => (
  <div className="relative">
    <div className="absolute z-50 ml-20">
      <InfoTooltip fontSize="small">
        <div className="flex flex-col gap-3 p-2 text-left text-gray-darker">
          <div className="mb-2">
            <h6 className="font-bold text-gray-darkest mb-1">SOLICITANTES</h6>
            <p>Escolha quem pode abrir essa solicitação.</p>
          </div>

          <div>
            <p className="font-bold">Nome de Colaborador</p>
            <p className="font-light">Colaborador específico.</p>
          </div>

          <div>
            <p className="font-bold">Nome de Círculo</p>
            <p className="font-light">
              Membros do Círculo específico, exceto seu líder.
            </p>
          </div>

          <div>
            <p className="font-bold">&quot;Líderes&quot;</p>
            <p className="font-light">
              Qualquer líder ativo da organização.
            </p>
          </div>

          <div>
            <p className="font-bold">&quot;Colaboradores&quot;</p>
            <p className="font-light">
              Qualquer colaborador ativo da organização.
            </p>
          </div>
        </div>
      </InfoTooltip>
    </div>
  </div>
)

const verifyBeforeExit =
  ({ handleNavigate, formState, setOpenConfirm, navigate }: any) =>
    () => {
      if (!Object.values(formState.values).some((item: any) => item.length))
        return navigate(handleNavigate)
      return setOpenConfirm(true)
    }

type FormProps = {
  handleSubmit: Function
  submitting: boolean
  error: string | null
  visible: boolean
  handleNavigate: Function | string
  filledValues: any
  activeStep: number
  backButtonLabel?: string
}

const Form = ({
  handleSubmit,
  submitting = false,
  error,
  visible,
  handleNavigate,
  filledValues,
  activeStep,
  backButtonLabel = 'Sair',
}: FormProps) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const navigate = useNavigate()
  const initialState = {
    name: filledValues?.name || '',
    description: filledValues?.description || '',
    documentsAttributes: filledValues?.documentsAttributes || [],
    applicantsAttributes: filledValues?.applicantsAttributes || [],
    inputsAttributes: filledValues?.inputsAttributes || [],
    performersAttributes: filledValues?.performersAttributes || [],
    performerInputsAttributes: filledValues?.performerInputsAttributes || [],
  }

  const { formState, fields, hasErrors } = useForm(initialState, {
    requiredFields: ['name', 'applicantsAttributes'],
  })

  useEffect(() => {
    if (filledValues) {
      for (const [attribute, value] of Object.entries(filledValues)) {
        formState.setField(attribute as keyof typeof initialState, value)
      }
    }
  }, [formState, filledValues])

  const onSubmit: React.FormEventHandler = (e) => {
    e && e.preventDefault && e.preventDefault()
    handleSubmit && handleSubmit(formState.values)
  }

  const documentFields = fields('raw', 'documentsAttributes', [], {
    touchOnChange: true,
    compare: isEqual,
  })

  const autocompleteFields = fields(
    'raw',
    'applicantsAttributes',
    [(value) => (isEmpty(value) ? 'Campo obrigatório' : undefined)],
    {
      touchOnChange: true,
      compare: isEqual,
    },
  )

  return (
    <Step
      handleSubmit={onSubmit}
      error={error}
      disableButton={hasErrors}
      submitting={submitting}
      visible={visible}
      backButtonLabel={backButtonLabel}
      handleNavigate={
        activeStep > 0 && typeof handleNavigate === 'function'
          ? handleNavigate(activeStep - 1)
          : verifyBeforeExit({
            handleNavigate,
            formState,
            openConfirm,
            setOpenConfirm,
            navigate,
          })
      }
    >
      <Input
        title="Nome"
        {...fields('text', 'name', [presence()])}
        label="Nome"
        helper={<p className="form-helper-text !mt-0">Defina o nome deste modelo de solicitação.</p>}
        placeholder="Ex. Aumento de Salário"
        autoComplete="off"
      />

      <Input
        title="Texto de abertura"
        {...fields('text', 'description', [])}
        label={
          <>
            Texto de abertura{' '}
            <span className="text-sm font-bold text-gray-darker">(Opcional)</span>
          </>
        }
        helper={<p className="form-helper-text !mt-0">Acrescente informações sobre a solicitação, se necessário.</p>}
        placeholder="Descrição da solicitação"
        multiline
        rows={4}
      />

      <DocumentsInput {...documentFields} />

      {filledValues?.id && (
        <Documents {...documentFields} value={documentFields.value as any} />
      )}

      <div>
        <ApplicantTooltip />

        <AutocompleteWithSearch
          croodsConfig={{
            name: 'search_applicant',
            path: '/search/applicant',
          }}
          autocompleteProps={{
            ...autocompleteFields,
            disabledOptions: (option: any) =>
              (autocompleteFields.value as unknown as any[]).some(
                (anotherOption) =>
                  option.kind === anotherOption.kind &&
                  option.value === anotherOption.value,
              ),
            getOptionSelected: (option, anotherOption) =>
              option.kind === anotherOption.kind &&
              option.value === anotherOption.value,
          }}
          inputProps={{
            label: 'Solicitantes',
            name: 'Solicitantes',
            placeholder:
              'Ex. João da Silva, Financeiro, Colaboradores, Líderes, etc.',
          }}
          defaultValue={initialState.applicantsAttributes}
        />
        <p className="form-helper-text">
          Selecione colaboradores ou círculos específicos utilizando seus nomes. Para solicitantes genéricos, utilize os papéis &quot;Colaboradores&quot; ou &quot;Líderes&quot;.
        </p>
      </div>

      <div>
        <AddQuestions
          // @ts-expect-error
          {...fields('raw', 'inputsAttributes', [validateQuestions()], {
            touchOnChange: true,
          })}
          step="applicant"
        />
      </div>

      <ExitConfirmationModal
        title="Deseja realmente sair?"
        exitWarning={
          filledValues?.id
            ? 'A edição será interrompida!'
            : 'O cadastro será interrompido!'
        }
        {...{ handleNavigate, setOpenConfirm, openConfirm }}
      />
    </Step>
  )
}

export default Form
