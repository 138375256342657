import * as React from 'react'
import { cx } from 'shared/helpers'

type Props = React.HTMLAttributes<HTMLElement> & {
  isError?: boolean
}
function InputHint({ id, isError, children, className, ...props }: Props) {
  return children ? (
    <small
      className={cx(
        'form-helper-text',
        isError ? 'text-red-dark' : 'text-gray-dark',
        className,
      )}
      role={isError ? 'alert' : undefined}
      {...props}
      id={id}
    >
      {children}
    </small>
  ) : null
}

export default InputHint
