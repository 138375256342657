import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import Info from '@material-ui/icons/Info'
import { colors } from 'theme'

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(1),
    backgroundColor: colors.white,
    borderRadius: '8px',
    boxShadow: `0px 2px 6px 2px ${colors.black02},0px 1px 1px 0px ${colors.black14},0px 1px 3px 0px ${colors.black12}`,
    margin: '-5px 0 0 5px',
  },
  arrow: {
    '&:before': {
      boxShadow: `0px 2px 6px 2px ${colors.black02},0px 1px 1px 0px ${colors.black14},0px 1px 3px 0px ${colors.black12}`,
    },
    color: theme.palette.common.white,
  },
}))(Tooltip)

const CustomizedToolTip = ({
  children,
  fontSize = 'small',
  'data-testid': testId,
  htmlColor = '#C1C1C1',
}: any) => (
  <LightTooltip
    title={children}
    enterTouchDelay={100}
    placement="right-start"
    arrow={true}
    data-testid={testId}
  >
    <Info fontSize={fontSize} htmlColor={htmlColor} />
  </LightTooltip>
)

export default CustomizedToolTip
