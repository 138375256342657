import React from 'react'
import type { RolesStateHandler } from './Roles'
import Roles from './Roles'
import Modal from 'shared/ui/Modal'
import type { SaveObj, User } from 'types'
import type { MemberEvaluation, Role } from '../../types'
import PathProfile from 'talks/conversations/career/shared/path-profile'
import Avatar from 'shared/ui/Avatar'

type PropsGrothPathAdapter = {
  pathProfile: MemberEvaluation['pathProfile']
  save: (value: { [index: string]: string | null }) => void
}

const PathProfileAdapter = ({ pathProfile, save }: PropsGrothPathAdapter) => {
  const handleSave: React.ComponentProps<typeof PathProfile>['handleSave'] = ({
    pathProfile,
  }) => save({ pathProfile })

  return <PathProfile pathProfile={pathProfile} handleSave={handleSave} />
}

type AddRoleHandler = (
  newRoleName: string,
  type: 'primaryRole' | 'secondaryRole',
) => void

type DialogProps = {
  name: string
  open: boolean
  onClose: () => void
  selectedPathProfile: MemberEvaluation['pathProfile']
  save: (arg: SaveObj) => void
  allRoles: Role[]
  user: User
  primaryRole: Role | null
  secondaryRoles: Role[]
  handleRoles: RolesStateHandler
  handleAddNewRole: AddRoleHandler
}

export function MemberInfoDialog({
  name,
  open,
  onClose,
  selectedPathProfile,
  save,
  allRoles,
  user,
  primaryRole,
  secondaryRoles,
  handleRoles,
  handleAddNewRole,
}: DialogProps) {
  return (
    <Modal.Wrapper open={open} onClose={onClose} className={'!min-w-[544px]'}>
      <Modal.Header className={'!justify-start'}>
        <div className="mr-3">
          <Avatar size={64} user={user} />
        </div>
        <div className="body1 text-base">
          <h5>{name}</h5>
          <span>{user.role}</span>
          <br />
          <span className="over-line uppercase">{user.email}</span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Roles
          allRoles={allRoles}
          primaryRole={primaryRole}
          secondaryRoles={secondaryRoles}
          handleRoles={handleRoles}
          handleAddNewRole={handleAddNewRole}
        />
        <p className="mt-6 mb-3 text-lg font-bold">
          Marque o Perfil de Trajetória desejado{' '}
          <span className="text-sm font-normal">- Opcional</span>
        </p>

        <PathProfileAdapter pathProfile={selectedPathProfile} save={save} />
      </Modal.Content>
      <Modal.Actions hideCancel className={'!justify-end'}>
        <Modal.Confirm aria-label="Concluir">Concluir</Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export type { RolesStateHandler, AddRoleHandler }
