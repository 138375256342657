import * as React from 'react'
import { cx } from 'shared/helpers'
import { useFormContext } from 'react-hook-form'
import Icon from 'shared/ui/icon'
import Input from './input'
import { dateStringISOToBr } from 'shared/schema-helpers'

import type { InputProps } from './input'

type Props = InputProps & { wrapperClassname?: string }
const DateInput = ({ wrapperClassname, ...props }: Props) => {
  const { setValue } = useFormContext()
  const [initialized, setInitialized] = React.useState(false)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(props.name, dateStringISOToBr(event.target.value), {
      shouldValidate: true,
    })
  }

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true)
    }
    setValue(props.name, props.defaultValue, {
      shouldValidate: initialized,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue])

  return (
    <div className={cx('relative flex flex-col text-blue', wrapperClassname)}>
      <Input
        type="text"
        placeholder="DD/MM/AAAA"
        mask="date"
        inputMode="numeric"
        {...props}
      />
      <div
        className={cx(
          'absolute right-2 inline-block h-6 w-6',
          props.label ? 'top-8' : 'top-3',
        )}
      >
        <Icon
          icon="event"
          size="md"
          className="absolute cursor-pointer text-current"
        />
        <input
          type="date"
          onChange={handleChange}
          tabIndex={-1}
          aria-hidden="true"
          className="datepicker-input absolute inset-0 -ml-3 -mt-2 w-11 cursor-pointer opacity-0 outline-none"
        />
      </div>
    </div>
  )
}

export default DateInput
