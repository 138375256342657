import { cx } from 'shared/helpers'
import InfoTooltip from 'shared/ui/Tooltip/InfoTooltip'

type Props = { tooltip: string } & JSX.IntrinsicElements['div']

export default function Header({
  tooltip,
  children,
  className,
  ...props
}: Props) {
  return (
    <div className="flex items-center space-x-4 p-4" {...props}>
      <div className={cx('flex-1 text-xl font-bold', className)}>
        {children}
      </div>
      <InfoTooltip>
        <p className="p-2 text-base font-light text-gray-darker">{tooltip}</p>
      </InfoTooltip>
    </div>
  )
}
