import * as React from 'react'
import Modal from 'shared/ui/Modal'
import ArrowUpLeftIcon from 'shared/ui/icon/ArrowUpLeftIcon'

type Props = {
  handleUncomplete: () => void
  skillTitle?: string
}

export default function Uncomplete({ handleUncomplete, skillTitle }: Props) {
  const [modalOpen, setModalOpen] = React.useState(false)

  const handleSubmit = async () => {
    handleUncomplete()
  }

  return (
    <>
      <Modal.Wrapper
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
        onConfirm={handleSubmit}
      >
        <Modal.Header>Competência não desenvolvida?</Modal.Header>
        <Modal.Content className="max-w-[600px]">
          <p>
            Deseja remover a competência <b>{skillTitle}</b> da lista de
            competências já desenvolvidas? Ao confirmar, ela retornará para a
            lista de competências que ainda precisam ser desenvolvidas pelo
            colaborador.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Modal.Confirm>Confirmar</Modal.Confirm>
        </Modal.Actions>
      </Modal.Wrapper>
      <button
        title="Desmarcar como desenvolvida"
        aria-label="Desmarcar como desenvolvida"
        type="button"
        onClick={() => {
          setModalOpen(true)
        }}
        className="hover:text-green text-gray-dark"
      >
        <ArrowUpLeftIcon />
      </button>
    </>
  )
}
