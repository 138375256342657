import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import ModalContext from './Context'
import { cx } from 'shared/helpers'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  hideCancel?: boolean
  cancelLabel?: string
}
const Actions = ({
  children,
  hideCancel,
  cancelLabel = 'Cancelar',
  className,
  ...props
}: Props) => {
  const { loading, onClose } = useContext(ModalContext)
  return (
    <div
      {...props}
      className={cx(
        className,
        'flex items-center justify-between rounded-b-lg bg-gray-lightest p-6',
      )}
    >
      {children}
      {hideCancel ? null : (
        <div className="order-first ml-0 grow">
          <Button
            title={cancelLabel}
            size="small"
            variant="text"
            type="button"
            disabled={loading}
            onClick={onClose}
          >
            {cancelLabel}
          </Button>
        </div>
      )}
    </div>
  )
}
export default Actions
