import type { Box } from '.'

export default function useRating(
  goalType: Box['goalType'],
  reachedGoals: Box['reachedGoals'],
): 'low' | 'medium' | 'high' | 'veryHigh' {
  if (reachedGoals === 'none') return 'low'
  if (goalType === 'unknown') return 'low'

  if (goalType === 'stretch' && reachedGoals === 'all') {
    return 'veryHigh'
  }

  if (goalType === 'realistic' && reachedGoals === 'all') {
    return 'high'
  }

  return 'medium'
}
