import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { cx } from 'shared/helpers'
import InputHint from './input-hint'
import Label from './label'

type Props = Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'name'> & {
  name: string
  label?: string
  hint?: string
  options: { value: string; label: string }[]
}
function Select({
  name,
  id = name,
  hint,
  label,
  required,
  className,
  options,
  ...props
}: Props) {
  let { register, formState } = useFormContext()
  let hasErrors = Boolean(formState.errors?.[name]?.message)

  let hintId = `input-${id}-hint`
  return (
    <>
      <Label aria-required={required} htmlFor={id}>
        {label}
      </Label>
      <select
        className={cx(
          'input',
          hasErrors && '!border-red-dark !text-red-dark',
          className,
        )}
        {...props}
        {...register(name)}
        id={id}
        aria-invalid={hasErrors ? 'true' : 'false'}
        aria-describedby={hasErrors ? hintId : undefined}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <InputHint id={hintId} isError={hasErrors}>
        {formState.errors?.[name]?.message ?? hint}
      </InputHint>
    </>
  )
}

export type { Props as SelectProps }
export default Select
