import React, { useEffect, useState } from 'react'
import { getUserIdentifier } from 'shared/helpers'
import { useCroods } from 'croods'
import type { Actions } from 'croods'
import { useFormState } from 'react-use-form-state'
import values from 'lodash/values'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import type { AddToCroodsQueue, MemberEvaluation, Role } from '../../types'
import { useTableContext } from '../Context'
import MemberInfo from './MemberInfo'

import MeritGroup from './MeritGroup'
import RiskGroup from './RiskGroup'
import type { AddRoleHandler } from './MemberInfoDialog'
import { MemberInfoDialog } from './MemberInfoDialog'

import PrimaryRole from './Roles/PrimaryRole'

type Props = {
  evaluation: MemberEvaluation
  save: AddToCroodsQueue
  allRoles: Role[]
  rolesSave: Actions['save']
}

function EvaluationRow({ evaluation, save, allRoles, rolesSave }: Props) {
  const [formState, inputs] = useFormState<MemberEvaluation>(evaluation)
  const [showDialog, setShowDialog] = useState(false)

  const [{ fetchingList }, { fetch }] = useCroods({
    name: 'memberEvaluations',
  })

  function refreshList() {
    fetch()()
  }

  function handleClose() {
    setShowDialog(false)
  }

  const { addErrors, removeErrors } = useTableContext()

  const { pathProfile } = evaluation

  const userIdentifier = getUserIdentifier(evaluation)

  const handleSave = (
    arg: Record<string, number | string | null | string[] | number[]>,
  ) => {
    return save({
      id: evaluation.id,
      afterSuccess: () => {
        if (arg['pathProfile']) {
          refreshList()
        }
      },
    })(arg)
  }

  useEffect(() => {
    const relevantErrors = { ...formState.errors }
    delete relevantErrors.meritPercentage
    delete relevantErrors.riskPercentage

    relevantErrors.organizationRoleId = formState.values.organizationRoleId
      ? undefined
      : 'Obrigatório'

    if (values(relevantErrors).some((el) => el !== undefined)) {
      addErrors(evaluation.id, relevantErrors)
    } else {
      removeErrors(evaluation.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors, formState.values.organizationRoleId])

  useEffect(() => {
    formState.setField('secondaryRoles', evaluation.secondaryRoles)
  }, [evaluation.secondaryRoles]) // eslint-disable-line

  const setPrimaryRole = (role: Role) => {
    formState.setField('organizationRoleId', role.id)
    formState.setField('organizationRoleName', role.name)
    handleSave({ organizationRoleId: role.id })
  }

  const getPrimaryRole: () => Role | null = () =>
    formState.values.organizationRoleId
      ? {
          id: formState.values.organizationRoleId,
          name: formState.values.organizationRoleName ?? '',
        }
      : null

  const setSecondaryRoles = (roles: Role[]) => {
    formState.setField(
      'secondaryRoles',
      roles.map((r) => ({ id: r.id, organizationRoleId: r.id, name: r.name })),
    )
    handleSave({
      organizationRoleId: formState.values.organizationRoleId,
      secondaryRoleIds: roles.map((r) => r.id),
    })
  }

  const getSecondaryRoles: () => Role[] = () =>
    formState.values.secondaryRoles.map((role) => ({
      id: role.organizationRoleId!,
      name: role.name,
    }))

  const getDisabledRoles = (role: Role) =>
    [...getSecondaryRoles(), getPrimaryRole()].some(
      (selected) => selected && selected.id === role.id,
    )

  const handleRoles = { setPrimaryRole, setSecondaryRoles, getDisabledRoles }

  const handleAddNewRole: AddRoleHandler = (newRoleName, type) => {
    rolesSave()({ name: newRoleName }).then((newRoleObj) => {
      if (type === 'primaryRole') {
        setPrimaryRole(newRoleObj)
      } else {
        setSecondaryRoles([...getSecondaryRoles(), newRoleObj])
      }
    })
  }

  const renderOption = (option: Role) => {
    if (option.inputValue) {
      return (
        <span className="font-bold uppercase text-blue">
          Adicionar &quot;{option.name}&quot;
        </span>
      )
    }
    return <span>{option.name}</span>
  }

  return (
    <tr className="mb-1 flex border-t-2 pt-2 text-center first:border-0">
      <MemberInfoDialog
        name={userIdentifier}
        open={showDialog}
        onClose={handleClose}
        selectedPathProfile={pathProfile}
        save={handleSave}
        allRoles={allRoles}
        user={evaluation.user}
        primaryRole={getPrimaryRole()}
        secondaryRoles={getSecondaryRoles()}
        handleRoles={handleRoles}
        handleAddNewRole={handleAddNewRole}
      />

      <MemberInfo
        user={evaluation.user}
        name={userIdentifier}
        setShowDialog={setShowDialog}
        loading={fetchingList}
        selectedPathProfile={pathProfile}
      >
        <PrimaryRole
          allRoles={allRoles}
          role={getPrimaryRole()}
          setRole={handleRoles.setPrimaryRole}
          getDisabledRoles={handleRoles.getDisabledRoles}
          handleAddNewRole={handleAddNewRole}
          renderOption={renderOption}
          autocompleteProps={{
            popupIcon: (
              <KeyboardArrowDownIcon
                fontSize="small"
                className={getPrimaryRole() ? '' : '!fill-red'}
              />
            ),
            classes: {
              root: 'custom-autocomplete custom-autocomplete-warning',
              popper: 'mt-2',
            },
          }}
        />
        <p className="subtitle2 font-normal text-gray-dark">
          {getSecondaryRoles()
            .map((role) => role.name)
            .join(', ')}
        </p>
      </MemberInfo>

      <MeritGroup
        save={handleSave}
        inputs={inputs}
        formState={formState}
        initalPercentage={evaluation.meritPercentage}
      />
      <RiskGroup
        save={handleSave}
        inputs={inputs}
        formState={formState}
        initalPercentage={evaluation.riskPercentage}
      />
    </tr>
  )
}

export default React.memo(EvaluationRow)
