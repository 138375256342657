import * as React from 'react'

type Props = Omit<React.HtmlHTMLAttributes<HTMLLabelElement>, 'htmlFor'> & {
  htmlFor: string
}
function Label({ children, htmlFor, ...props }: Props) {
  return children ? (
    <label className="label mb-1" {...props} htmlFor={htmlFor}>
      {children}
      {props['aria-required'] && props['aria-required'] !== 'false' ? ' *' : ''}
    </label>
  ) : null
}

export default Label
