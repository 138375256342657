export default () => (
  <div className="my-4 mx-1 sm:mx-10 sm:my-10 md:my-14">
    <section className="mx-auto w-[95%] max-w-[512px] rounded-xl bg-white py-6 px-2 shadow sm:rounded-2xl sm:py-6 sm:px-6">
      <h5 className="text-center">
        Caso exista uma conta ativa, um email com instruções vai ter chegado
        para você.
      </h5>
    </section>
  </div>
)
