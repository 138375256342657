import { api } from 'api'
import { makeDomainFunction, mapError } from 'domain-functions'
import first from 'lodash/first'
import { redirect } from 'react-router'
import { z } from 'zod'
import { hierarchyPositions } from 'types'
import { onErrorDefaultTo } from 'shared/helpers'
import { parseFile } from 'shared/formsv2/image-upload-input'
import { flashMessage } from 'flash-message'
import { ISO_DATE_REGEX } from 'shared/formsv2/regex'

const passwordSchema = z.object({
  password: z
    .string()
    .min(1, { message: 'Digite uma senha' })
    .min(8, { message: 'Sua senha precisa ter no mínimo 8 caracteres.' }),
  passwordConfirmation: z
    .string()
    .min(1, { message: 'Digite uma senha' })
    .min(8, { message: 'Sua senha precisa ter no mínimo 8 caracteres.' }),
})

const loginSchema = z.object({
  email: z.string().email('Digite um e-mail válido'),
  password: z
    .string()
    .min(1, { message: 'Digite uma senha' })
    .min(8, { message: 'Sua senha precisa ter no mínimo 8 caracteres.' }),
})

const logoutSchema = z.object({})

const migrateCredentialToPasswordSchema = passwordSchema.refine(
  (data) => data.password === data.passwordConfirmation,
  {
    message: 'Confirmação precisa ser igual à senha',
    path: ['passwordConfirmation'],
  },
)

const avatarImageSchema = z.string().transform((val) => {
  const file = parseFile(val)
  return file?.parsed ?? ''
})

const acceptImportedInvitationSchema = z.object({
  image: avatarImageSchema,
})

const inviteEmployeesSchema = z.object({
  emails: z.array(z.string().email()),
})

const acceptInvitationSchema = z.object({
  firstName: z.string().min(1, 'Não pode ficar em branco'),
  lastName: z.string().min(1, 'Não pode ficar em branco'),
  role: z.string(),
  admissionDate: z
    .string()
    .min(1, 'Campo obrigatório')
    .regex(ISO_DATE_REGEX, {
      message: 'Data inválida. Verifique se sua data está correta.',
    })
    .refine((admissionDate) => {
      const today = new Date()
      return new Date(admissionDate) <= today
    }, 'Não pode ser uma data no futuro.'),
  image: avatarImageSchema,
})

const authOrganizationSchema = z
  .object({
    id: z.number(),
    slug: z.string(),
    permitOneOnOneConversation: z.boolean(),
    permitCareerConversation: z.boolean(),
    permitBehaviorConversation: z.boolean(),
    permitTeamsEvaluations: z.boolean(),
    permitTalentsEvaluations: z.boolean(),
    permitFeedback: z.boolean(),
    evaluationWindowStatus: z.enum(['closed', 'open', 'almost-late', 'late']),
  })
  .passthrough()
type AuthOrganizationSchema = z.infer<typeof authOrganizationSchema>

const authUserSchema = z
  .object({
    id: z.number(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    avatar: z.string().nullable(),
    dataAnalyst: z.boolean(),
    admin: z.boolean(),
    hasAcquisitivePeriods: z.boolean(),
    hierarchyPosition: z.enum(hierarchyPositions),
    shouldAcceptTerms: z.boolean(),
    shouldAcceptPrivacy: z.boolean(),
    signInMethod: z.enum(['google', 'password']),
    createdAt: z.string(),
    role: z.string().nullable(),
    admissionDate: z.string().nullable(),
    imported: z.boolean(),
    teamsLeadingIds: z.array(z.number()).nullable(),
    mainTeam: z
      .object({
        abilities: z.object({
          behaviorConversation: z.boolean(),
          careerConversation: z.boolean(),
          oneOnOneConversation: z.boolean(),
          talentsEvaluations: z.boolean(),
          teamsEvaluations: z.boolean(),
          upwardEvaluation: z.boolean(),
        }),
      })
      .nullable(),
  })
  .passthrough()

type AuthUserSchema = z.infer<typeof authUserSchema>

const authEnvSchema = z.object({
  currentOrganization: authOrganizationSchema.nullable(),
  currentUser: authUserSchema.nullable(),
})

const userEnvSchema = z.object({
  currentOrganization: authOrganizationSchema,
  currentUser: authUserSchema,
})
type UserEnvSchema = z.infer<typeof userEnvSchema>

const startCredentialMigrationToPasswordSchema = z.object({})

function authStorageKey() {
  return `${getCurrentOrganizationSlug(window.location.href)}-auth-credentials`
}

function throwRedirect(request: Request, path: string) {
  const url = new URL(request.url)
  const pathUrl = new URL(path, url)

  if (url.pathname === pathUrl.pathname) return

  throw redirect(path)
}

function removeCredentials() {
  const storageKey = authStorageKey()
  localStorage.removeItem(storageKey)
  localStorage.removeItem('authCredentials')
}

function getCurrentOrganizationSlug(url: string) {
  const { hostname } = new URL(url)

  const slug = first(
    hostname
      .replace(/https?:\/\//, '')
      .replace('www.', '')
      .split('.'),
  )

  if (!slug) throw 'No organization slug'

  return slug
}

async function getPublicCurrentOrganization(request: Request) {
  const slug = getCurrentOrganizationSlug(request.url)

  const response = await api.get('/organization/:slug/slug', {
    params: { slug },
  })

  return response.json(z.object({ slug: z.string() }))
}

async function getCurrentOrganization(request: Request) {
  const slug = getCurrentOrganizationSlug(request.url)

  return onErrorDefaultTo(async () => {
    const response = await api.get('/organization/:slug', {
      params: { slug },
    })

    return response.json(authOrganizationSchema)
  }, null)
}

async function getCurrentUser() {
  return onErrorDefaultTo(async () => {
    const response = await api.get('/auth/validate_token')

    const result = await response.json(
      z.object({
        success: z.literal(true),
        data: authUserSchema,
      }),
    )

    return result.data
  }, null)
}

async function requireNoCurrentUser() {
  const currentUser = await getCurrentUser()

  if (currentUser) {
    flashMessage('Você já esta logado em uma conta.', 'warning')

    throw redirect('/')
  }
}

async function requireNoCurrentUserForAcceptingInvite() {
  const currentUser = await getCurrentUser()

  if (currentUser) {
    flashMessage(
      'Você já esta logado em uma conta. Por favor, deslogue da conta atual para acessar seu novo convite.',
      'error',
    )

    throw redirect('/')
  }
}

function redirectToLoginAndRemoveCredentials(request: Request) {
  const { pathname, search, hash } = new URL(request.url)
  const redirectTo = [pathname, search, hash].join('') ?? '/'

  removeCredentials()

  return redirect(`/login?redirect_to=${redirectTo}`)
}

async function getAuthEnv(request: Request) {
  const currentOrganization = await getCurrentOrganization(request)
  const currentUser = await getCurrentUser()

  if (currentUser?.shouldAcceptTerms) {
    throwRedirect(request, '/termos')
  } else if (currentUser?.shouldAcceptPrivacy) {
    throwRedirect(request, '/privacidade')
  }

  return { currentOrganization, currentUser }
}

async function getUserEnv(request: Request) {
  const { currentOrganization, currentUser, ...environment } = await getAuthEnv(
    request,
  )

  if (!currentOrganization) throw redirectToLoginAndRemoveCredentials(request)
  if (!currentUser) throw redirectToLoginAndRemoveCredentials(request)

  return { currentOrganization, currentUser, ...environment }
}

function isDeviseError(
  error: unknown,
): error is { success: false; errors: string[] } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'success' in error &&
    error.success === false &&
    'errors' in error &&
    Array.isArray(error.errors) &&
    error.errors.length > 0
  )
}

const login = mapError(
  makeDomainFunction(loginSchema)(async ({ email, password }) => {
    const response = await api.post('/auth/sign_in', {
      body: { email, password },
    })

    return response.json(z.object({}))
  }),
  (error) => {
    if (isDeviseError(error)) {
      if (
        'exception' in error.errors[0] &&
        typeof error.errors[0].exception === 'object' &&
        error.errors[0].exception !== null &&
        'data' in error.errors[0].exception &&
        typeof error.errors[0].exception.data === 'object' &&
        error.errors[0].exception.data !== null &&
        'errors' in error.errors[0].exception.data &&
        Array.isArray(error.errors[0].exception.data.errors) &&
        error.errors[0].exception.data.errors.length > 0
      ) {
        return {
          ...error,
          errors: [{ message: error.errors[0].exception.data.errors[0] }],
        }
      }
    }
    return error
  },
)

const logout = makeDomainFunction(logoutSchema)(async () => {
  const response = await api.delete('/auth/sign_out')

  removeCredentials()

  return response.json(z.object({}))
})

const validateGoogleToken = makeDomainFunction(
  z.object({ token: z.string() }),
  authEnvSchema,
)(async ({ token }, { currentUser }) => {
  if (currentUser) throw new Error('Você já está logado')

  const response = await api.post('/auth/google_oauth2/validate', {
    body: { token },
  })

  return response.json(z.object({}))
})

const fetchInvitationUser = makeDomainFunction(
  z.object({ invitationToken: z.string() }),
)(async ({ invitationToken }) => {
  const response = await api.get('/users/:invitationToken/invitation', {
    params: { invitationToken },
  })

  const item = await response.json(
    z.object({
      imported: z.boolean(),
      firstName: z.string().nullable(),
      lastName: z.string().nullable(),
      role: z.string().nullable(),
    }),
  )

  return { item }
})

const acceptGoogleInvitation = makeDomainFunction(
  z.union([
    acceptInvitationSchema.extend({
      invitationToken: z.string(),
      googleToken: z.string(),
    }),
    acceptImportedInvitationSchema.extend({
      invitationToken: z.string(),
      googleToken: z.string(),
    }),
  ]),
)(async (body) => {
  const response = await api.put('/auth/invitation', { body })
  return response.json(z.object({}))
})

const startCredentialMigrationToGoogle = makeDomainFunction(
  z.object({}),
  userEnvSchema,
)(async () => {
  const response = await api.post('/user_credentials/start_migration_to_google')
  const item = await response.json(z.object({ token: z.string() }))
  return { item }
})

const startCredentialMigrationToPassword = makeDomainFunction(
  startCredentialMigrationToPasswordSchema,
  userEnvSchema,
)(async () => api.post('/user_credentials/start_migration_to_password'))

const migrateCredentialToPassword = makeDomainFunction(
  passwordSchema.extend({ migrationToken: z.string() }),
  userEnvSchema,
)(async (body) => {
  const response = await api.post('/user_credentials/migrate_to_password', {
    body,
  })

  return response.json(z.object({}))
})

const inviteEmployeesToVibe = makeDomainFunction(
  inviteEmployeesSchema,
  userEnvSchema,
)(async (body) => {
  const response = await api.post('/auth/invitation', { body })

  return response.json(z.object({}))
})

const mergeAuthEnv = makeDomainFunction(
  z.object({}),
  authEnvSchema,
)(async (_, authEnv) => authEnv)

const mergeUserEnv = makeDomainFunction(
  z.object({}),
  userEnvSchema,
)(async (_, authEnv) => authEnv)

export {
  authStorageKey,
  getCurrentOrganizationSlug,
  getPublicCurrentOrganization,
  getCurrentUser,
  getAuthEnv,
  getUserEnv,
  authEnvSchema,
  mergeAuthEnv,
  mergeUserEnv,
  authUserSchema,
  authOrganizationSchema,
  userEnvSchema,
  loginSchema,
  logoutSchema,
  inviteEmployeesSchema,
  login,
  logout,
  validateGoogleToken,
  acceptImportedInvitationSchema,
  acceptInvitationSchema,
  requireNoCurrentUser,
  requireNoCurrentUserForAcceptingInvite,
  acceptGoogleInvitation,
  fetchInvitationUser,
  startCredentialMigrationToGoogle,
  startCredentialMigrationToPasswordSchema,
  startCredentialMigrationToPassword,
  migrateCredentialToPasswordSchema,
  migrateCredentialToPassword,
  inviteEmployeesToVibe,
}
export type { AuthUserSchema, AuthOrganizationSchema, UserEnvSchema }
