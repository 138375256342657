import startsWith from 'lodash/startsWith'

import type { ModuleItem, Organization } from 'types'

type AccessPolicyUser = {
  dataAnalyst: boolean
  admin: boolean
  hasAcquisitivePeriods: boolean
  hierarchyPosition: 'member' | 'leader' | 'member_and_leader' | 'teamless'
}

const leadersOnly = (user: AccessPolicyUser) =>
  ['leader', 'member_and_leader'].includes(user.hierarchyPosition)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const everyone = (_user: AccessPolicyUser) => true

const membersAndLeaders = (user: AccessPolicyUser) =>
  ['member', 'leader', 'member_and_leader'].includes(user.hierarchyPosition)

const admins = (user: AccessPolicyUser) => user.admin

const adminsOrLeaders = (user: AccessPolicyUser) =>
  user.admin || ['leader', 'member_and_leader'].includes(user.hierarchyPosition)

const leadersAndDataAnalysts = (user: AccessPolicyUser) =>
  leadersOnly(user) || user.dataAnalyst

const getAvailablePaths: () => ModuleItem[] = () => {
  const membersWithAcquisitivePeriods = (user: {
    hasAcquisitivePeriods: boolean
  }) => user.hasAcquisitivePeriods

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const permittedForAllOrganizations = (_organization: Organization) => true

  const organizationHasPermittedClocking = (organization: Organization) =>
    organization.permitClocking

  const organizationHasPermittedDocuments = (organization: Organization) =>
    organization.permitDocuments

  const organizationHasPermittedSolicitations = (organization: Organization) =>
    organization.permitSolicitations

  const organizationHasPermittedVacations = (organization: Organization) =>
    organization.permitVacations

  const organizationHasNextConversations = (organization: Organization) =>
    organization.permitOneOnOneConversation ||
    organization.permitCareerConversation ||
    organization.permitBehaviorConversation

  const organizationHasPermittedConversations = (organization: Organization) =>
    organization.permitOneOnOneConversation ||
    organization.permitCareerConversation ||
    organization.permitBehaviorConversation ||
    organization.permitUpwardEvaluation

  const organizationHasPermittedPerformance = (organization: Organization) =>
    organization.permitTeamsEvaluations || organization.permitTalentsEvaluations

  const organizationHasPermittedTeamsEvaluations = (
    organization: Organization,
  ) => organization.permitTeamsEvaluations

  const organizationHasPermittedTalentsEvaluations = (
    organization: Organization,
  ) => organization.permitTalentsEvaluations

  const organizationHasPermittedFeedback = (organization: Organization) =>
    organization.permitFeedback

  const organizationHasPermittedConversationsEvaluations = (
    organization: Organization,
  ) =>
    organizationHasPermittedConversations(organization) ||
    organizationHasPermittedPerformance(organization)

  return [
    {
      title: 'Vibe',
      icon: 'vibe',
      path: '/',
      position: 'top',
      menuItems: [
        {
          title: 'Home',
          icon: 'home',
          path: '/',
          visibleFor: everyone,
          permittedForOrganization: permittedForAllOrganizations,
        },
        {
          title: 'Insights',
          icon: 'lamp',
          path: '/insights',
          visibleFor: leadersAndDataAnalysts,
          permittedForOrganization:
            organizationHasPermittedConversationsEvaluations,
        },
        {
          title: 'Círculos',
          icon: 'people',
          path: '/circulos',
          visibleFor: everyone,
          permittedForOrganization: permittedForAllOrganizations,
        },
        {
          title: 'Colaboradores',
          icon: 'person',
          path: '/colaboradores',
          visibleFor: everyone,
          permittedForOrganization: permittedForAllOrganizations,
        },
        {
          title: 'Convidar Colaboradores',
          icon: 'invitation',
          path: '/convidar',
          visibleFor: adminsOrLeaders,
          permittedForOrganization: permittedForAllOrganizations,
        },
      ],
    },
    {
      title: 'Conversas',
      icon: 'conversas',
      path: '/conversas',
      position: 'top',
      menuItems: [
        {
          title: 'Visão Geral',
          icon: 'home',
          path: '/',
          visibleFor: membersAndLeaders,
          permittedForOrganization: organizationHasPermittedConversations,
        },
        {
          title: 'Dar Feedback',
          icon: 'rate-review',
          path: '/dar-feedback',
          visibleFor: everyone,
          permittedForOrganization: organizationHasPermittedFeedback,
        },
        {
          title: 'Meus Feedbacks',
          icon: 'my-feedback',
          path: '/meus-feedbacks',
          visibleFor: everyone,
          permittedForOrganization: organizationHasPermittedFeedback,
        },
        {
          title: 'Próximas Conversas',
          icon: 'next',
          path: '/proximas-conversas',
          visibleFor: leadersOnly,
          permittedForOrganization: organizationHasNextConversations,
        },
        {
          title: 'Iniciar Conversa',
          icon: 'baloon-plus',
          path: '/nova-conversa',
          visibleFor: leadersOnly,
          permittedForOrganization: organizationHasNextConversations,
        },
        {
          title: 'Histórico de Conversas',
          icon: 'history',
          path: '/historico-de-conversas',
          visibleFor: membersAndLeaders,
          permittedForOrganization: organizationHasPermittedConversations,
        },
      ],
    },
    {
      title: 'Performance',
      icon: 'performance',
      path: '/performance',
      position: 'top',
      menuItems: [
        {
          title: 'Visão Geral',
          icon: 'chart',
          path: '/',
          visibleFor: leadersOnly,
          permittedForOrganization: organizationHasPermittedPerformance,
        },
        {
          title: 'Avaliar Performance',
          icon: 'dashboard',
          path: '/avaliar-performance',
          visibleFor: leadersOnly,
          permittedForOrganization: organizationHasPermittedTeamsEvaluations,
        },
        {
          title: 'Avaliar Talentos',
          icon: 'trophy',
          path: '/avaliar-talentos',
          visibleFor: leadersOnly,
          permittedForOrganization: organizationHasPermittedTalentsEvaluations,
        },
      ],
    },
    {
      title: 'Portal',
      icon: 'portal',
      path: '/portal',
      position: 'top',
      menuItems: [
        {
          title: 'Nova Solicitação',
          icon: 'plus',
          path: '/',
          visibleFor: everyone,
          permittedForOrganization: organizationHasPermittedSolicitations,
        },
        {
          title: 'Solicitações Pendentes',
          icon: 'bell',
          path: '/solicitacoes-pendentes',
          visibleFor: everyone,
          permittedForOrganization: organizationHasPermittedSolicitations,
        },
        {
          title: 'Solicitações Enviadas',
          icon: 'folder',
          path: '/solicitacoes-enviadas',
          visibleFor: everyone,
          permittedForOrganization: organizationHasPermittedSolicitations,
        },
        {
          title: 'Solicitações Recebidas',
          icon: 'inbox',
          path: '/solicitacoes-recebidas',
          visibleFor: everyone,
          permittedForOrganization: organizationHasPermittedSolicitations,
        },
        {
          title: 'Acerto do Ponto',
          icon: 'alarm-add',
          path: '/acerto-do-ponto',
          visibleFor: everyone,
          permittedForOrganization: organizationHasPermittedClocking,
        },
        {
          title: 'Meus Documentos',
          icon: 'file-text',
          path: '/meus-documentos',
          visibleFor: everyone,
          permittedForOrganization: organizationHasPermittedDocuments,
        },
        {
          title: 'Assinatura Eletrônica',
          icon: 'fingerprint',
          path: '/assinatura-eletronica',
          visibleFor: everyone,
          permittedForOrganization: organizationHasPermittedDocuments,
        },
        {
          title: 'Gestão de Férias',
          icon: 'vacations',
          path: '/gestao-de-ferias',
          visibleFor: leadersOnly,
          permittedForOrganization: organizationHasPermittedVacations,
        },
        {
          title: 'Minhas Férias',
          icon: 'travel',
          path: '/minhas-ferias',
          visibleFor: membersWithAcquisitivePeriods,
          permittedForOrganization: organizationHasPermittedVacations,
        },
      ],
    },
    {
      title: 'Configurações',
      icon: 'settings',
      path: '/configuracoes',
      admin: true,
      position: 'bottom',
      menuItems: [
        {
          title: 'Gestão de Colaboradores',
          icon: 'people',
          path: '/',
          visibleFor: admins,
          permittedForOrganization: permittedForAllOrganizations,
        },
        {
          title: 'Papéis',
          icon: 'assignmentindicon',
          path: '/papeis',
          visibleFor: admins,
          permittedForOrganization: permittedForAllOrganizations,
        },
        {
          title: 'Valores Organizacionais',
          icon: 'diamond',
          path: '/valores-organizacionais',
          visibleFor: admins,
          permittedForOrganization: permittedForAllOrganizations,
        },
        {
          title: 'Ciclo',
          icon: 'calendar',
          path: '/ciclo',
          visibleFor: admins,
          permittedForOrganization: permittedForAllOrganizations,
        },
        {
          title: 'Modelos de Solicitação',
          icon: 'folder',
          path: '/modelos-de-solicitacao',
          visibleFor: admins,
          permittedForOrganization: permittedForAllOrganizations,
        },
        {
          title: 'Gestão de Solicitações',
          icon: 'assignment',
          path: '/gestao-de-solicitacoes',
          visibleFor: admins,
          permittedForOrganization: permittedForAllOrganizations,
        },
        {
          title: 'API',
          icon: 'all-inclusive',
          path: '/api',
          visibleFor: admins,
          permittedForOrganization: permittedForAllOrganizations,
        },
        {
          title: 'Conversas',
          icon: 'conversation',
          path: '/conversas',
          visibleFor: admins,
          permittedForOrganization: permittedForAllOrganizations,
        },
        {
          title: 'Performance',
          icon: 'performance',
          path: '/performance',
          visibleFor: admins,
          permittedForOrganization: permittedForAllOrganizations,
        },
        {
          title: 'Portal',
          icon: 'portal',
          path: '/portal',
          visibleFor: admins,
          permittedForOrganization: permittedForAllOrganizations,
        },
      ],
    },
  ]
}

function isMenuItemActive({
  currentPath,
  menuItem,
  moduleItem,
}: {
  currentPath: string
  menuItem: ModuleItem['menuItems'][number]
  moduleItem: ModuleItem
}) {
  const matchingMenuItems = moduleItem.menuItems.filter((menuItem) => {
    const basePath = [moduleItem.path, menuItem.path]
      .join('') // join module and item paths
      .replace(/\/$/, '') // remove trailling slashed
      .replace('//', '/') // remove double slashes

    return startsWith(currentPath, basePath)
  })

  function byPathSize(itemA: typeof menuItem, itemB: typeof menuItem) {
    return itemB.path.length - itemA.path.length
  }

  const mostSpecificMatch = matchingMenuItems.sort(byPathSize)[0]

  return mostSpecificMatch === menuItem
}

export type { AccessPolicyUser }

export { getAvailablePaths, leadersOnly, isMenuItemActive, adminsOrLeaders }
