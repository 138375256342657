import React from 'react'
import slice from 'lodash/slice'
import { useNavigate } from 'react-router-dom'
import type { UserConversations } from 'types'
import Header from './Header'
import Button from './Button'

type Props = {
  active: 'Meus líderes' | 'Meus liderados'
  membersOrLeaders: UserConversations
  hasUpwardEvaluation: boolean
  activeLeaderId: number
}

export default function Panel({
  active,
  membersOrLeaders,
  hasUpwardEvaluation,
  activeLeaderId,
}: Props) {
  const navigate = useNavigate()
  const path = active === 'Meus liderados' ? 'members' : 'leaders'

  const { user, id, conversations } = membersOrLeaders

  return (
    <div className="h-56 overflow-hidden rounded-lg shadow">
      <div className="bg-gray-lightest">
        <Header
          user={user}
          hasUpwardEvaluation={hasUpwardEvaluation}
          leaderId={activeLeaderId}
        />
      </div>
      <div className="flex justify-between gap-4 p-4">
        <div className="flex h-full flex-1 flex-wrap gap-4">
          {slice(conversations, 0, 6).map((conversation, index) => (
            <Button
              key={index}
              conversation={conversation}
              id={id}
              path={path}
            />
          ))}
        </div>
        <div>
          <button
            className="h-32 w-32 rounded-2xl border border-blue text-lg font-bold uppercase tracking-wide text-blue transition hover:bg-gray-100"
            onClick={() =>
              navigate(`/conversas/historico-de-conversas/${id}?type=${path}`)
            }
          >
            Ver todas
          </button>
        </div>
      </div>
    </div>
  )
}
