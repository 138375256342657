import CardHeader from '@material-ui/core/CardHeader'
import TouchAppIcon from '@material-ui/icons/TouchApp'
import type { Box } from '.'
import TeamsTable from './teams-table'

type Props = {
  box?: Box
}

export default function Selected({ box }: Props) {
  if (!box)
    return (
      <div className="card relative flex overflow-hidden rounded-2xl">
        <CardHeader
          className="p-2"
          subheader={
            <div className="relative h-12 w-16 rounded-2xl bg-white">
              <div className="h-full w-full overflow-hidden rounded-2xl">
                <div className="h-4 w-full bg-black/10" />
              </div>
              <div className="absolute inset-0 bottom-[-5px] flex items-end justify-center">
                <TouchAppIcon className="h-9 w-9 text-gray" />
              </div>
            </div>
          }
        />
        <div className="m-6 flex flex-col items-start justify-start sm:flex-row sm:items-center">
          <p className="body2 mb-4 sm:mr-8 sm:mb-0">
            Clique em um <b>quadrante</b> para ver mais detalhes.
          </p>
        </div>
      </div>
    )

  return <TeamsTable teams={box.teams} />
}
