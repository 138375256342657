import React, { useEffect } from 'react'
import { useStepperContext } from './Context'

type Props = {
  children: React.ReactNode[]
  className?: string
}

export default function Content({ children, className }: Props) {
  const { currentStep, setStepLength } = useStepperContext()

  useEffect(() => {
    setStepLength(children.length)
  }, [children.length, setStepLength])

  return <div className={className}>{children[currentStep]}</div>
}
