import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'
import { cx } from 'shared/helpers'
import EmojiEventsRoundedIcon from '@material-ui/icons/EmojiEventsRounded'
import { THUMB_NAMES } from '..'
import getStyle, { getDashed, styles } from './getStyle'
import type { SliderProps } from '../../../types'
import { checkDisabled } from './checkDisabled'

export default function LongTerm({ props, isMark }: SliderProps) {
  const { className, isPaused, displayOnly, isCompleted, ...thumbProps } = props

  const newProps = checkDisabled(thumbProps, isPaused, displayOnly, isCompleted)

  return (
    <Tooltip title={THUMB_NAMES.longterm} placement="top" arrow>
      <div
        {...newProps} // has to come first
        draggable={!isMark}
        className="relative -top-14"
      >
        <div
          className={cx(
            className,
            getStyle({ isPaused, isMark, displayOnly, isCompleted }),
            styles.square,
            'relative bottom-1',
          )}
        >
          <div className="-rotate-45">
            <EmojiEventsRoundedIcon fontSize="inherit" />
          </div>
        </div>
        <hr
          className={cx(
            getDashed({ isPaused, isMark, displayOnly, isCompleted }),
            'h-11',
          )}
        />
      </div>
    </Tooltip>
  )
}
