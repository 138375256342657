import React, { useState } from 'react'
import type { Actions } from 'croods'
import { useCroods } from 'croods'
import moment from 'moment'

import BaseForm from 'talks/conversations/Form'
import ConversationPaper from 'talks/conversations/ConversationPaper'
import { useTemplate } from 'shared/utils/useTemplate'

import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { typeToIcon } from 'shared/utils/conversationLookup'
import type { StateValues } from 'react-use-form-state'
import type { FormTemplate } from 'types'
import { useForm } from 'shared/utils/useForm'
import Modal from 'shared/ui/Modal'
import Decision from './Decision'
import { useNavigate, useParams } from 'react-router-dom'
import { useTrackAnalytics } from 'domain/analytics'

const New = () => {
  const { teamMemberId } = useParams()
  const [modalOpen, setModalOpen] = useState(false)
  const template = useTemplate('permanent')
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  return (
    <>
      <ConversationPaper className="mt-10 mb-4">
        <div className="flex flex-col">
          <header className="flex flex-col text-gray-darkest text-opacity-80">
            <div className="flex items-center gap-4">
              {typeToIcon('permanent')}
              <h4 className="text-gray-darkest first-letter:uppercase">
                Efetivação
              </h4>
            </div>
          </header>
          <Form
            teamMemberId={teamMemberId!}
            template={template}
            setModalOpen={setModalOpen}
          />
        </div>
      </ConversationPaper>
      <Modal.Wrapper
        open={modalOpen}
        onClose={() => {
          snackbar({ message: 'Conversa criada com sucesso!', type: 'success' })
          navigate(`/`)
        }}
      >
        <Modal.Header>
          <Modal.Title>Conversa de efetivação</Modal.Title>
        </Modal.Header>
        <Modal.Content>
          <p className="text-base">
            Encaminhe o desligamento do funcionário junto ao RH
          </p>
        </Modal.Content>
        <Modal.Actions hideCancel>
          <Modal.Confirm>Voltar para a home</Modal.Confirm>
        </Modal.Actions>
      </Modal.Wrapper>
    </>
  )
}

type FormProps = {
  teamMemberId: string
  template: FormTemplate
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export interface FormFields {
  date: moment.Moment
  comment: string
  decision: 'permanent' | 'fired'
}

const Form = ({ teamMemberId, template, setModalOpen }: FormProps) => {
  const { formState, fields, hasErrors } = useForm<FormFields>(
    {
      date: moment(),
    },
    {
      requiredFields: ['date', 'comment', 'decision'],
    },
  )

  const { snackbar } = useSnackbar()
  const navigate = useNavigate()
  const track = useTrackAnalytics()

  const [{ saving, saveError }, { save }] = useCroods({
    name: 'permanentConversations',
    customPath: `/team_members/${teamMemberId}/permanent_conversations`,
    afterSuccess: ({ data }) => {
      track('Conversation', { Type: 'hiring' })
      if (data.decision === 'fired') {
        setModalOpen(true)
      } else {
        snackbar({ message: 'Conversa criada com sucesso!', type: 'success' })
        navigate(`/`)
      }
    },
  })

  return (
    <BaseForm
      onSubmit={handleSubmit({ save, values: formState.values })}
      template={template}
      fields={fields}
      saving={saving}
      saveError={saveError}
      disabled={hasErrors}
      afterFields={<Decision fields={fields} />}
    />
  )
}

type HandlerProps = {
  save: Actions['save']
  values: StateValues<FormFields>
}

const handleSubmit =
  ({ save, values }: HandlerProps) =>
  (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const convertedValues: {
      date: string
      decision: string
      comment: string
    } = {
      ...values,
      date: moment(values.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }

    save()(convertedValues)
  }

export default New
