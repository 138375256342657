import { Skeleton } from '@material-ui/lab'
import { Fetch } from 'croods'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import type { UserConversations } from 'types'
import Panel from './Panel'
import type { optionTitles } from './types'
import { isEmpty } from 'lodash'

type Props = {
  active: optionTitles
  fetchPath: string
  currentUser: any
  searchTerm?: string
}

const ConversationHistoryList = ({
  active,
  fetchPath,
  currentUser,
  searchTerm = '',
}: Props) => {
  return (
    <Fetch<UserConversations[]>
      name={active}
      path={fetchPath}
      render={(list) => {
        const filteredList = searchTerm
          ? list.filter(({ user }) => {
              const match =
                isEmpty(user.firstName) && isEmpty(user.lastName)
                  ? user.email
                  : `${user.firstName} ${user.lastName}`

              return match
                .trim()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            })
          : list

        return (
          <div className="flex flex-col gap-4">
            {filteredList.map((membersOrLeaders, index) => {
              const { mainTeam } = currentUser.currentUser

              const hasUpwardEvaluation =
                active === 'Meus líderes' &&
                mainTeam?.activeLeaderUserId === membersOrLeaders.user.id &&
                mainTeam?.abilities?.upwardEvaluation

              return (
                <Panel
                  key={index}
                  activeLeaderId={mainTeam?.activeLeaderId}
                  membersOrLeaders={membersOrLeaders}
                  hasUpwardEvaluation={hasUpwardEvaluation}
                  active={active}
                />
              )
            })}
          </div>
        )
      }}
      renderLoading={() => <Skeleton />}
      renderEmpty={() => (
        <FeedbackMessage
          message="Nenhuma conversa encontrada"
          serverMessage=""
          type="warning"
        />
      )}
      renderError={() => <FeedbackMessage />}
    />
  )
}

export default ConversationHistoryList
