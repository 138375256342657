import type { ReactNode } from 'react'
import { getFullName } from 'shared/helpers'
import moment from 'moment'

import type { Solicitation } from 'types'

const mapStatus = (
  status: Solicitation['status'],
): ParsedSolicitation['status'] => {
  switch (status) {
    case 'completed':
      return 'Completa'
    case 'pending_approval':
      return 'Aprovação'
    case 'pending_execution':
      return 'Execução'
    case 'rejected':
      return 'Reprovada'
    case 'canceled':
      return 'Cancelada'
    default:
      throw new Error(`A valid status is required: ${status} was passed`)
  }
}

const parseDateTime = (dateTime: Solicitation['createdAt']): string =>
  moment(dateTime).format('DD/MM/YYYY, HH:mm')

const parseDateTimeToDate = (
  dateTime: Solicitation['createdAt'] | string,
): string => moment(dateTime).format('DD/MM/YYYY')

const isDate = (value: string) =>
  Boolean(value.match(/^(\d){4}-(\d){2}-(\d){2}/))

const parseQuestions = (questions: Solicitation['questions']) =>
  questions.map(({ id, question, answer, inputType, required }) => {
    const defaultObject = {
      id,
      question,
      answer,
      inputType,
      required,
    }
    let isValueAnArray = true

    if (answer !== null && answer !== '') {
      switch (inputType) {
        case 'short_text':
        case 'long_text':
        case 'number_input':
        case 'hour_input':
        case 'led_search':
          return defaultObject
        case 'date_input':
          return {
            ...defaultObject,
            answer: isDate(answer as string)
              ? parseDateTimeToDate(answer as string)
              : answer,
          }
        case 'option_list':
          try {
            Array.isArray(JSON.parse(answer as string))
          } catch (error) {
            isValueAnArray = false
          }

          return {
            ...defaultObject,
            answer: isValueAnArray
              ? JSON.parse(answer as string).join('\r\n')
              : answer,
          }
        case 'upload':
          return {
            id,
            question,
            answer,
            inputType,
            required,
          }
        default:
          return defaultObject
      }
    }

    return {
      ...defaultObject,
      answer: 'A resposta não foi preenchida',
    }
  })

const filterByStatus = (
  collection: Solicitation[],
  key: string = 'all',
): Solicitation[] =>
  key === 'all' ? collection : collection.filter(({ status }) => status === key)

type ParsedSolicitation = Pick<Solicitation, 'id'> & {
  id: number
  fullName: string
  fullNameColaborator: string
  dateTime: string
  status: 'Aprovação' | 'Execução' | 'Completa' | 'Reprovada' | 'Cancelada'
  templateName: string
  description: {
    hasUnfilledRequiredFields: boolean
    questions: Array<{
      id: number
      question: string
      answer?:
        | string
        | Array<string>
        | Array<{ fileName: string; fileUrl: string }>
        | object
        | null
      inputType: string
      required: true | false
    }>
  }
  automationIdentifier: Solicitation['solicitationTemplate']['automationIdentifier']
  fixedFieldsAnswers: Solicitation['fixedFieldsAnswers']
  prefixedValues: Solicitation['prefixedValues']
  vacationStartDate: string | null
}
const parseSolicitation = (solicitation: Solicitation): ParsedSolicitation => ({
  id: solicitation.id,
  fullName: solicitation.applicant ? getFullName(solicitation.applicant) : '',
  fullNameColaborator: solicitation.user ? getFullName(solicitation.user) : '',
  dateTime: parseDateTime(solicitation.createdAt),
  status: mapStatus(solicitation.status),
  templateName: solicitation.solicitationTemplate.name,
  description: {
    hasUnfilledRequiredFields: solicitation.hasUnfilledRequiredFields,
    questions: parseQuestions(solicitation.questions),
  },
  automationIdentifier: solicitation.solicitationTemplate.automationIdentifier,
  fixedFieldsAnswers: solicitation.fixedFieldsAnswers,
  prefixedValues: solicitation.prefixedValues,
  vacationStartDate: solicitation.vacationStartDate,
})

const getPendingAmounts = (list: Solicitation[]) => {
  const pendingApproval = list.filter(
    ({ status }) => status === 'pending_approval',
  )
  const pendingExecution = list.filter(
    ({ status }) => status === 'pending_execution',
  )
  return {
    pendingApprovalAmount: pendingApproval.length,
    pendingApprovalIds: pendingApproval.map(({ id }) => id),
    pendingExecutionAmount: pendingExecution.length,
    pendingExecutionIds: pendingExecution.map(({ id }) => id),
  }
}

const setRequiredWarning =
  (icon: ReactNode) => (hasUnfilledRequiredFields: Boolean) => {
    if (hasUnfilledRequiredFields) return icon
    return null
  }

export {
  filterByStatus,
  parseDateTime,
  isDate,
  parseSolicitation,
  mapStatus,
  parseQuestions,
  getPendingAmounts,
  setRequiredWarning,
}
export type { ParsedSolicitation }
