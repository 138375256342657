import React from 'react'
import { cx } from 'shared/helpers'
import { safeJoin } from '../helpers'

type Props = {
  message?: string
  serverMessage?: string
  type?: 'error' | 'warning'
  className?: string
}
const FeedbackMessage = ({
  message = 'Erro inesperado ao buscar por dados',
  serverMessage = 'Não encontrado',
  type = 'error',
  className,
}: Props) => (
  <p
    role="alert"
    className={cx(
      'max-h-[6rem] w-full overflow-hidden text-ellipsis rounded p-4 text-center text-base font-semibold shadow',
      type === 'error' && 'bg-red-50 text-red-darkest',
      type === 'warning' && 'bg-yellow-lightest text-yellow-darkest',
      className,
    )}
  >
    {safeJoin([message, serverMessage], ': ')}.
  </p>
)

export default FeedbackMessage
