import React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { alpha, makeStyles } from '@material-ui/core/styles'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import map from 'lodash/map'

import Icon from 'shared/ui/icon'
import { colors } from 'theme'
import FieldValue from './fieldValue'

const defaultRenderDetails = (questions: any) => (
  <>
    {map(keys(questions), (question) =>
      isEmpty(get(questions[question], 'value')) ? null : (
        <Grid item key={question} style={{ marginBottom: 40 }}>
          <FieldValue {...questions[question]} />
        </Grid>
      ),
    )}
  </>
)

const StepSummary = ({
  children,
  questions,
  startOpen = false,
  render = defaultRenderDetails,
}: any) => {
  const classes = useStyles()

  return (
    <Accordion defaultExpanded={startOpen}>
      <AccordionSummary
        classes={classes}
        expandIcon={<Icon icon="expand-more" />}
      >
        <Typography variant="h4" style={{ margin: 0 }}>
          {children}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{ display: 'flex', flexDirection: 'column', paddingTop: 20 }}
      >
        {render(questions)}
      </AccordionDetails>
    </Accordion>
  )
}

const useStyles = makeStyles(() => ({
  expanded: {
    backgroundColor: alpha(colors.blue, 0.08),
  },
  content: {
    backgroundColor: 'transparent',
  },
  expandIcon: {
    backgroundColor: 'transparent',
  },
}))

export default StepSummary
