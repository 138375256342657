import * as React from 'react'
import { CareerSkillsEvaluation } from '../shared/careerSkills'
import TextInput from '../shared/TextInput'
import type { Info } from 'croods'
import type { CareerConversation } from 'talks/types'

type Props = {
  conversation: Info<CareerConversation>
  teamMemberId: string
  handleSave: (value: { [index: string]: string }) => void
  setIsValidated: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SkillEvaluation({
  conversation,
  teamMemberId,
  handleSave: save,
  setIsValidated,
}: Props) {
  const { supportEvidence, previousSupportPlan } = conversation
  const [filledForms, setFilledForms] = React.useState(false)

  React.useEffect(() => {
    setIsValidated(Boolean(filledForms))
  }, [filledForms]) //eslint-disable-line

  const handleSave = (value: { [index: string]: string }) => {
    save(value)
  }

  return (
    <div>
      <CareerSkillsEvaluation
        teamMemberId={teamMemberId}
        setFilledForms={setFilledForms}
      />
      <TextInput
        text={previousSupportPlan}
        name="previousSupportPlan"
        label="Plano de apoio da conversa passada"
        disabled
      />
      <TextInput
        text={supportEvidence}
        name="supportEvidence"
        handleSave={handleSave}
        label="Evidências de apoio"
      />
    </div>
  )
}
