import type { ButtonProps } from '@material-ui/core/Button'
import Button from '@material-ui/core/Button'
import React from 'react'
import { useStepperContext } from '../Context'

type Props = {
  children: React.ReactNode
  onClick?: () => void | boolean
} & ButtonProps

export default function PreviousStep({ children, onClick, ...props }: Props) {
  const { previousStep, currentStep } = useStepperContext()

  if (currentStep === 0) return null

  const handleClick = () => {
    const result = onClick?.()
    if (result !== false) previousStep()
  }

  return (
    <Button {...props} onClick={handleClick}>
      {children}
    </Button>
  )
}
