import React from 'react'
import { cx } from 'shared/helpers'
import Tooltip from './Tooltip'

type Props = {
  text: string
  style?: Record<string, any>
  className?: string
  blackText?: boolean
}

const CustomTooltip = ({ text, style, className, blackText }: Props) =>
  text ? (
    <Tooltip
      text={text}
      style={style}
      className={cx('z-10 mr-2 self-end', className)}
      blackText={blackText}
    />
  ) : null

export default CustomTooltip
