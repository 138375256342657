import * as React from 'react'
import Body from './Body'
import Footer from './Footer'
import Header from './Header'
import { getFullName } from 'shared/helpers'

import type { Vacation } from '../types'

type Props = {
  list: Vacation[]
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
}

const LeaderVacationReminder = ({ list, setSearchValue }: Props) => {
  return (
    <div className="max-h-36 w-full overflow-hidden">
      {list.map((member, index) => {
        if (!member.dateLimit) return

        return (
          <button
            key={index}
            className="mr-4 mb-4 inline-block max-h-[180px] w-full max-w-[180px] overflow-hidden rounded-2xl border text-left transition hover:bg-gray/10"
            onClick={() => {
              setSearchValue(
                getFullName({
                  firstName: member.firstName,
                  lastName: member.lastName,
                  email: member.email,
                }),
              )
            }}
          >
            <Header member={member} />
            <Body member={member} />
            <Footer date={member.dateLimit} />
          </button>
        )
      })}
    </div>
  )
}

export default LeaderVacationReminder
