import { makeStyles } from '@material-ui/core/styles'
import { cx } from 'shared/helpers'
import Label from '../label'
import boxColors from '../boxColors'
import type { BoxContent } from '../../types'

const useStyles = makeStyles((theme) => ({
  typesHolder: {
    height: `calc(100% - ${theme.spacing(2)}px)`,
    '& > p': {
      flexDirection: 'row',
    },
  },
}))

const SelectedLabel = ({ name }: { name: BoxContent['name'] }) => {
  const color = boxColors[name]

  const classes = useStyles()
  return (
    <div className="relative flex h-[50px] flex-col overflow-hidden rounded-lg bg-white shadow-lg">
      <div
        style={{ backgroundColor: `${color}` }}
        className="h-4 rounded-t-lg"
      />
      <div
        className={cx(
          classes.typesHolder,
          'flex items-center justify-center border-none py-1 sm:py-0',
        )}
        style={{ width: 200 }}
      >
        <Label name={name} />
      </div>
    </div>
  )
}

export default SelectedLabel
