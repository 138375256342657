import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { Form } from 'themes/main'
import { z } from 'zod'

type Props = {
  children: React.ReactNode
}

const SignOut = ({ children }: Props) => {
  return (
    <Form schema={z.object({})} action="/logout">
      {({ submit }) => (
        <MenuItem
          className="flex gap-2 text-xs transition hover:!bg-blue-50"
          role="button"
          onClick={submit}
        >
          {children}
        </MenuItem>
      )}
    </Form>
  )
}

export default SignOut
