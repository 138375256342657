import Icon from 'shared/ui/icon'

import { useExpandable } from './expandable-context'

const ToggleAll = ({ id, allKeys }: { allKeys: number[]; id: string }) => {
  const { openKeys, setOpenKeys } = useExpandable()

  const areAllOpen = openKeys.length === allKeys.length

  const toggleAllKeys = () => {
    const keys = areAllOpen ? [] : allKeys
    setOpenKeys(keys)
  }

  return (
    <button
      className="rounded-full p-1 !transition-all hover:!bg-gray-200"
      type="button"
      onClick={toggleAllKeys}
      aria-label={areAllOpen ? 'Fechar todas linhas' : 'Expandir todas linhas'}
      aria-controls={id}
      aria-expanded={areAllOpen}
    >
      <Icon icon="format-line-spacing" />
    </button>
  )
}

export default ToggleAll
