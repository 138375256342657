import { useEffect, useState } from 'react'
import { scrollTo } from 'shared/helpers'
import Card from './card'
import type { Box } from '.'
import Selected from './selected'
import Period from 'shared/ui/Period'

type Props = {
  boxes: Box[]
  totalTeams: number
  startsAt: string
  finishesAt: string
}

export default function Boxes({
  boxes,
  totalTeams,
  startsAt,
  finishesAt,
}: Props) {
  const [selectedBoxName, setSelectedBoxName] = useState<string | null>(null)
  const selectedBox = boxes.find(({ name }) => name === selectedBoxName)
  const isActive = (box: Box) => box.name === selectedBoxName

  const selectBox = (box: Box) => () => setSelectedBoxName(box.name)

  const unselectBox = () => {
    setSelectedBoxName(null)
    scrollTo('twelve-box-unselected-scroll-target')
  }

  useEffect(() => {
    selectedBox && scrollTo('twelve-box-selected-scroll-target')
  }, [selectedBox])

  return (
    <div className="card rounded-2xl">
      <div className="relative flex flex-col space-y-4">
        <div className="header-card">
          <div
            id="twelve-box-unselected-scroll-target"
            className="absolute -top-20"
          />
          <div className="flex items-center">
            <h4 className="font-bold">Performance dos Círculos</h4>
          </div>
        </div>

        <div className="px-6 pb-4">
          <Period title="Ciclo" startsAt={startsAt} finishesAt={finishesAt} />
        </div>

        <div className="grid grid-cols-3 grid-rows-3 gap-4 px-6 justify-items-center">
          {boxes.map((box) => (
            <Card
              key={box.name}
              totalTeams={totalTeams}
              active={isActive(box)}
              onSelect={selectBox(box)}
              onUnselect={unselectBox}
              {...box}
            />
          ))}
        </div>

        <div className="relative self-center px-6 pb-6">
          <div
            id="twelve-box-selected-scroll-target"
            className="absolute -top-20"
          />
          <Selected box={selectedBox} />
        </div>
      </div>
    </div>
  )
}
