import { Fetch } from 'croods'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import Chart from './chart'
import EvaluatedTeam from 'performance/evaluations/teamEvaluation/teamEvaluationNotice/EvaluatedTeam'
import type { InsightComponentProps } from 'vibe/insights/chart-helpers'
import { chartPath } from 'vibe/insights/chart-helpers'

const loadingCard = (
  <div className="text-center animate-pulse">Carregando...</div>
)

const Card = ({ query, stateId }: InsightComponentProps) => (
  <Fetch
    name="charts"
    // @ts-ignore
    operation="info"
    customPath={chartPath('team_evaluations', query)}
    stateId={stateId}
    query={query}
    render={(_, [{ info, fetchingInfo, infoError }]) => {
      if (fetchingInfo) return loadingCard

      if (infoError)
        return <FeedbackMessage message={infoError} type="warning" />

      if (!('overview' in query) && !query?.inner_circle) {
        if (!info)
          return (
            <FeedbackMessage
              message="Não existe avaliação para este time"
              type="warning"
            />
          )

        return (
          <div className="flex flex-col gap-4">
            <EvaluatedTeam
              key={`team-evaluated-${info?.teamLabel}`}
              team={info}
            />
          </div>
        )
      }

      return <Chart data={info?.data ?? []} />
    }}
    renderLoading={() => loadingCard}
    renderError={(error) => <FeedbackMessage message={error} type="warning" />}
  />
)

export default Card
