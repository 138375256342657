import { Link } from 'react-router-dom'

import Container from 'shared/ui/Container'

export default () => (
  <Container>
    <h3>404 - Page not found</h3>
    <p>
      <Link to="/">Go to Home page</Link>
    </p>
  </Container>
)
