import { makeStyles } from '@material-ui/core/styles'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import StopIcon from '@material-ui/icons/Stop'

import { colors } from 'theme'

const useStyles = makeStyles((theme) => ({
  performanceIcon: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}))

const Icon = ({
  percentageScore,
  previousPercentageScore,
}: {
  percentageScore: number
  previousPercentageScore?: number
}) => {
  const classes = useStyles()

  if (!previousPercentageScore)
    return <span className={classes.performanceIcon} style={{ width: 28 }} />

  if (percentageScore > previousPercentageScore)
    return (
      <div className="ml-1 bg-white rounded-full">
        <ArrowUpwardIcon
          className={classes.performanceIcon}
          style={{ color: colors.green }}
        />
      </div>
    )

  if (percentageScore < previousPercentageScore)
    return (
      <div className="ml-1 bg-white rounded-full">
        <ArrowDownwardIcon
          className={classes.performanceIcon}
          style={{ color: colors.red }}
        />
      </div>
    )

  return (
    <div className="ml-1 bg-white rounded-full">
      <StopIcon
        className={classes.performanceIcon}
        style={{ color: '#969696' }}
      />
    </div>
  )
}

export default Icon
