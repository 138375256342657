import * as z from 'zod'
import moment from 'moment'
import type { Cycle } from './types'

const EVALUATION_PREVIOUS_DAYS = 15
const EVALUATION_PERIOD = 30

function getSchema(cycle: Cycle) {
  const nextDayForChangingCycle = moment(cycle.finishesAt)
    .add(1, 'days')
    .format('DD/MM/YYYY')

  return z.object({
    selectCycle: z
      .string()
      .nonempty()
      .refine((val) => {
        return val !== String(cycle.durationInMonths)
      }, 'Este é o ciclo atual')
      .refine((val) => {
        const { evaluationWindowOpensAt } = updateEvaluationWindow(cycle, val)
        return moment().isBefore(moment(evaluationWindowOpensAt))
      }, `Opção não aplicável por incompatibilidade na janela de avaliações.\nVocê poderá utilizar essa opção a partir de ${nextDayForChangingCycle}`),
  })
}

function updateEvaluationWindow(
  cycle: Cycle,
  durationInMonths: string,
  isFirstEvaluationCycle?: boolean,
) {
  const format = 'YYYY-MM-DD'
  const newFinishesAt = moment(cycle.startsAt)
    .add(durationInMonths, 'M')
    .subtract(1, 'days')

  const evaluationWindowOpensAt = isFirstEvaluationCycle
    ? moment(cycle.startsAt).format(format)
    : moment(newFinishesAt)
        .subtract(EVALUATION_PREVIOUS_DAYS, 'days')
        .format(format)

  return {
    startsAt: cycle.startsAt,
    finishesAt: newFinishesAt.format(format),
    evaluationWindowOpensAt: evaluationWindowOpensAt,
    evaluationWindowClosesAt: moment(newFinishesAt)
      .add(EVALUATION_PERIOD, 'days')
      .format(format),
    durationInMonths,
  }
}

export type { Cycle }
export { updateEvaluationWindow, getSchema }
