import * as React from 'react'
import type { Organization } from 'types'

import Table from 'shared/ui/table'
import type { ServerError } from 'croods'
import { useCroods } from 'croods'
import Search from 'shared/ui/Search'
import Pagination from 'shared/ui/pagination/Pagination'
import debounce from 'lodash/debounce'
import partition from 'lodash/partition'
import Icon from 'shared/ui/icon'
import { ConfirmationModal } from './confirmation-modal'
import CheckInput from 'shared/formsv2/checkbox'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import Skeleton from 'shared/ui/skeleton'

import Tooltip from '@material-ui/core/Tooltip'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import { renderIf } from 'shared/helpers'
import { useRestoreScrollOnChange } from 'shared/utils/hooks'

type PropsIconButton = {
  type:
    | 'one_on_one_conversation'
    | 'career_conversation'
    | 'behavior_conversation'
    | 'upward_evaluation'
  checked: boolean
  permited: boolean
  label: string
  id: number
  teams: Array<GetTeamSettingsApi>
}
function IconButton({
  type,
  checked,
  permited,
  label,
  id,
  teams,
}: PropsIconButton) {
  const [showModal, setShowModal] = React.useState(false)
  const [checkedAllCircles, setCheckedAllCircles] = React.useState(false)
  const { snackbar } = useSnackbar()

  const [, { save, setList: setTeams }] = useCroods({
    name: 'teamsSettings',
  })

  const afterFailure = (e: ServerError) => {
    if (e.response?.data.message) {
      snackbar({
        message: e.response.data.message,
        type: 'error',
      })
    } else {
      snackbar({
        message: 'houve um erro inesperado, tente novamente',
        type: 'error',
      })
    }
    setShowModal(false)
    setCheckedAllCircles(false)
  }

  const [
    { list: childrenSettings, fetchingList: fetchingChildrenSettings },
    { fetch: fetchChildrenSettings },
  ] = useCroods({
    name: 'innerCircles',
    customPath: `team/${id}/settings/children`,
    afterFailure: afterFailure,
  })

  if (!permited) {
    return (
      <>
        <div className="flex justify-center p-5">
          <Tooltip title="Não permitido pela Organização" placement="bottom">
            <div>
              <Icon icon="block" />
            </div>
          </Tooltip>
        </div>
      </>
    )
  }

  const PERMIT_CONTENT = {
    one_on_one_conversation: {
      description: 'habilitar Onboarding, Efetivação e 1:1',
      firstAlertMessage:
        'Todos os Círculos abaixo terão a configuração de Onboarding, Efetivação e 1:1 habilitada',
      secondAlertMessage:
        'Os Círculos abaixo já possuem a configuração de Onboarding, Efetivação e 1:1 habilitada',
    },
    career_conversation: {
      description: 'habilitar Conversa de Carreira',
      firstAlertMessage:
        'Todos os Círculos abaixo terão a configuração de Conversa de Carreira habilitada',
      secondAlertMessage:
        'Os Círculos abaixo já possuem a configuração de Conversa de Carreira habilitada',
    },
    behavior_conversation: {
      description: 'habilitar Conversa de Comportamento',
      firstAlertMessage:
        'Todos os Círculos abaixo terão a configuração de Conversa de Comportamento habilitada',
      secondAlertMessage:
        'Os Círculos abaixo já possuem a configuração de Conversa de Comportamento habilitada',
    },
    upward_evaluation: {
      description: 'habilitar Avaliação Ascendente',
      firstAlertMessage:
        'Todos os Círculos abaixo terão a configuração de Avaliação Ascendente habilitada',
      secondAlertMessage:
        'Os Círculos abaixo já possuem a configuração de Avaliação Ascendente habilitada',
    },
  }

  const REVOKE_CONTENT = {
    one_on_one_conversation: {
      description: 'desabilitar Onboarding, Efetivação e 1:1',
      firstAlertMessage:
        'Todos os Círculos abaixo terão a configuração de Onboarding, Efetivação e 1:1 desabilitada',
      secondAlertMessage:
        'Os Círculos abaixo já possuem a configuração de Onboarding, Efetivação e 1:1 desabilitada',
    },
    career_conversation: {
      description: 'desabilitar Conversa de Carreira',
      firstAlertMessage:
        'Todos os Círculos abaixo terão a configuração de Conversa de Carreira desabilitada',
      secondAlertMessage:
        'Os Círculos abaixo já possuem a configuração de Conversa de Carreira desabilitada',
    },
    behavior_conversation: {
      description: 'desabilitar Conversa de Comportamento',
      firstAlertMessage:
        'Todos os Círculos abaixo terão a configuração de Conversa de Comportamento desabilitada',
      secondAlertMessage:
        'Os Círculos abaixo já possuem a configuração de Conversa de Comportamento desabilitada',
    },
    upward_evaluation: {
      description: 'desabilitar Avaliação Ascendente',
      firstAlertMessage:
        'Todos os Círculos abaixo terão a configuração de Avaliação Ascendente desabilitada',
      secondAlertMessage:
        'Os Círculos abaixo já possuem a configuração de Avaliação Ascendente desabilitada',
    },
  }

  const MAP_TYPE = {
    one_on_one_conversation: 'enableOneOnOneConversation',
    career_conversation: 'enableCareerConversation',
    behavior_conversation: 'enableBehaviorConversation',
    upward_evaluation: 'enableUpwardEvaluation',
  }

  const dataContent = checked ? REVOKE_CONTENT[type] : PERMIT_CONTENT[type]

  const checkInnerCircles = () => {
    setCheckedAllCircles(!checkedAllCircles)
  }

  const actionPath = checked
    ? checkedAllCircles
      ? 'disable_with_children'
      : 'disable'
    : checkedAllCircles
    ? 'enable_with_children'
    : 'enable'

  const apiType =
    (MAP_TYPE[type] as 'enableOneOnOneConversation') ||
    'enableCareerConversation' ||
    'enableBehaviorConversation' ||
    'enableUpwardEvaluation'

  const [childrenSettingsEnabled, childrenSettingsDisabled] = partition(
    childrenSettings,
    (childSettings: GetTeamSettingsApi) =>
      childSettings[apiType] === checked ? true : false,
  )

  const enableOrDisableOnClick = () => {
    fetchChildrenSettings()()
    setShowModal(true)
  }

  return (
    <>
      <div className="group flex justify-center p-5">
        <div className="block cursor-pointer group-hover:hidden">
          {checked ? (
            <Icon icon="check-circle-outline" className="text-green-300" />
          ) : (
            <Icon icon="close" className="text-red-light" />
          )}
        </div>
        <div className="hidden cursor-pointer group-hover:block">
          {checked ? (
            <button
              className="text-base font-semibold text-blue"
              onClick={enableOrDisableOnClick}
            >
              DESABILITAR
            </button>
          ) : (
            <button
              className="text-base font-semibold text-blue"
              onClick={enableOrDisableOnClick}
            >
              HABILITAR
            </button>
          )}
        </div>
      </div>
      <ConfirmationModal
        show={showModal}
        onClose={() => {
          setCheckedAllCircles(false)
          setShowModal(false)
        }}
        handleSave={() => {
          save({
            id,
            method: 'PUT',
            customPath: `team/${id}/settings/${actionPath}/${type}`,
            afterSuccess: ({ data: teamList }) => {
              setShowModal(false)
              setCheckedAllCircles(false)
              setTeams(
                teams.map(
                  (team) =>
                    teamList.find(
                      (t: GetTeamSettingsApi) => t.id === team.id,
                    ) || team,
                ),
              )
            },
            afterFailure: afterFailure,
          })()
        }}
      >
        <h5 className="font-normal">
          Você deseja{' '}
          <span className="font-bold">{dataContent.description}</span> em{' '}
          {label}?
        </h5>

        {fetchingChildrenSettings && <Skeleton className="!h-4 w-96 p-4" />}

        {!fetchingChildrenSettings &&
          (childrenSettingsEnabled.length > 0 ||
            childrenSettingsDisabled.length > 0) && (
            <div className="flex flex-col gap-6">
              <CheckInput
                onChange={checkInnerCircles}
                checked={checkedAllCircles}
                label={`${
                  checked ? 'Desabilitar' : 'Habilitar'
                } também em todos os Círculos contidos`}
              />
              {checkedAllCircles && (
                <div className="flex flex-col gap-6">
                  {childrenSettingsEnabled.length > 0 && (
                    <div className="flex flex-col gap-3 px-2">
                      <div className="flex items-center gap-2 rounded-2xl bg-orange-50 px-[10px] py-1">
                        <Icon icon="error-outline" className="text-[#F0B858]" />
                        <span className="text-base font-bold text-[#604A23]">
                          {dataContent.firstAlertMessage}
                        </span>
                      </div>
                      <div className="flex flex-wrap items-center gap-2">
                        {childrenSettingsEnabled.map(({ id, label }: any) => (
                          <div key={id}>
                            <div className="rounded-full border-[1px] border-gray-dark/60 px-2 py-[3px] font-semibold text-gray-dark hover:bg-gray/10">
                              {label}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {childrenSettingsDisabled.length > 0 && (
                    <div className="flex flex-col gap-3 px-2">
                      <div className="flex items-center gap-2 rounded-2xl bg-orange-50 px-[10px] py-1">
                        <Icon icon="error-outline" className="text-[#F0B858]" />
                        <span className="text-base font-bold text-[#604A23]">
                          {dataContent.secondAlertMessage}
                        </span>
                      </div>
                      <div className="flex flex-wrap items-center gap-2">
                        {childrenSettingsDisabled.map(({ id, label }: any) => (
                          <div key={id}>
                            <div className="rounded-full border-[1px] border-gray-dark/60 px-2 py-[3px] font-semibold text-gray-dark hover:bg-gray/10">
                              {label}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
      </ConfirmationModal>
    </>
  )
}

type GetTeamSettingsApi = {
  id: number
  label: string
  enableBehaviorConversation: boolean
  enableCareerConversation: boolean
  enableOneOnOneConversation: boolean
  enableTalentsEvaluations: boolean
  enableTeamsEvaluations: boolean
  enableUpwardEvaluation: boolean
}

function TeamsSettings({
  organization,
  className,
}: {
  organization: Organization
  className?: string
}) {
  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [search, setSearch] = React.useState('')
  useRestoreScrollOnChange(currentPage)

  const [{ list: teams }, { fetch }] = useCroods<GetTeamSettingsApi>({
    name: 'teamsSettings',
    customPath: 'team/settings',
    parseResponse: ({ data, headers }) => {
      const numPerPage = Number(headers['per-page'])
      const numTotal = Number(headers['total'])
      const numerOrPages = Math.floor(numTotal / numPerPage)
      const totalPages =
        numTotal % numPerPage === 0 ? numerOrPages : numerOrPages + 1
      setTotalPages(totalPages)
      return data
    },
    fetchOnMount: true,
  })

  const updateList = (search: string, page: number) => {
    fetch({
      afterSuccess: () => {
        setCurrentPage(page)
      },
    })({ search, page })
  }

  const handlePageClick = (currentPage: number) => {
    if (currentPage > 0 && currentPage <= totalPages)
      updateList(search, currentPage)
  }

  const delayedRequest = React.useRef(
    debounce((value) => {
      setSearch(value)
      updateList(value, 1)
    }, 600),
  ).current

  const classNameTd =
    'flex items-center justify-between text-left md:table-cell before:block before:font-bold before:content-[attr(data-title)] md:before:content-none'

  return (
    <div className={className}>
      <h3 className="mb-6">Conversas dos Círculos</h3>
      <div className="flex flex-col md:flex-row">
        <div className="flex-1">
          <Search
            placeholder="Busca rápida por Círculos"
            searchTerm={search}
            setSearch={delayedRequest}
          />
        </div>
        <div className="flex-1"></div>
        <div className="flex-1"></div>
      </div>
      <div className="card mt-6">
        <div className="max-w-full">
          {teams.length > 0 ? (
            <Table id="collaborator-table">
              <Table.Thead>
                <Table.Th>Círculo</Table.Th>
                <Table.Th className="text-center">
                  Onboarding, Efetivação e 1:1
                </Table.Th>
                <Table.Th className="text-center">Carreira</Table.Th>
                <Table.Th className="text-center">Comportamento</Table.Th>
                <Table.Th className="text-center">
                  Avaliação Ascendente
                </Table.Th>
              </Table.Thead>
              <Table.Tbody>
                {teams.map(
                  (
                    {
                      id,
                      label,
                      enableOneOnOneConversation,
                      enableCareerConversation,
                      enableBehaviorConversation,
                      enableUpwardEvaluation,
                    },
                    i,
                  ) => (
                    <Table.Tr className="!py-6" key={i}>
                      <Table.Td data-title="Nome" className={classNameTd}>
                        {label}
                      </Table.Td>
                      <Table.Td
                        data-title="Onboarding, Efetivação e 1:1"
                        className={classNameTd}
                      >
                        <IconButton
                          type="one_on_one_conversation"
                          checked={enableOneOnOneConversation}
                          permited={organization.permitOneOnOneConversation}
                          label={label}
                          teams={teams}
                          id={id}
                        />
                      </Table.Td>
                      <Table.Td data-title="Carreira" className={classNameTd}>
                        <IconButton
                          type="career_conversation"
                          checked={enableCareerConversation}
                          permited={organization.permitCareerConversation}
                          label={label}
                          teams={teams}
                          id={id}
                        />
                      </Table.Td>
                      <Table.Td
                        data-title="Comportamento"
                        className={classNameTd}
                      >
                        <IconButton
                          type="behavior_conversation"
                          checked={enableBehaviorConversation}
                          permited={organization.permitBehaviorConversation}
                          label={label}
                          teams={teams}
                          id={id}
                        />
                      </Table.Td>
                      <Table.Td
                        data-title="Avaliação Ascendente"
                        className={classNameTd}
                      >
                        <IconButton
                          type="upward_evaluation"
                          checked={enableUpwardEvaluation}
                          permited={organization.permitUpwardEvaluation}
                          label={label}
                          teams={teams}
                          id={id}
                        />
                      </Table.Td>
                    </Table.Tr>
                  ),
                )}
              </Table.Tbody>
            </Table>
          ) : (
            <FeedbackMessage
              message="A consulta não retornou resultados"
              type="warning"
              serverMessage=""
            />
          )}
        </div>
      </div>
      {renderIf(
        teams.length > 0,
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageClick={handlePageClick}
        />,
      )}
    </div>
  )
}

export { TeamsSettings }
export type { GetTeamSettingsApi }
