import { ExpandableProvider } from './expandable-context'
import Tbody from './tbody'
import Thead from './thead'
import TrExpandable from './tr-expandable'
import Td from './td'
import Tr from './tr'
import Th from './th'
import { cx } from 'shared/helpers'

const Table = ({ id, className, children }: JSX.IntrinsicElements['table']) => (
  <ExpandableProvider>
    <table
      className={cx(
        'block w-full table-fixed text-base text-gray-dark md:table',
        className,
      )}
      id={id}
    >
      {children}
    </table>
  </ExpandableProvider>
)

Table.Tbody = Tbody
Table.Thead = Thead
Table.TrExpandable = TrExpandable
Table.Tr = Tr
Table.Td = Td
Table.Th = Th

export default Table
