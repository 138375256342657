import * as React from 'react'
import { useCroods } from 'croods'
import { useNavigate, useParams } from 'react-router-dom'
import curry from 'lodash/curry'

import RadioGroup from './RadioButtonsGroup'
import { presence, richTextPresence } from 'shared/forms/validations'
import ConversationPaper from 'talks/conversations/ConversationPaper'
import { useTemplate } from 'shared/utils/useTemplate'
import { useForm } from 'shared/utils/useForm'
import CustomTooltip from 'talks/conversations/CustomTooltip'
import RichText from 'shared/forms/RichText'
import FormError from 'shared/forms/FormError'
import SubmitButton from 'shared/forms/SubmitButton'

import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { typeToIcon } from 'shared/utils/conversationLookup'
import { Skeleton } from '@material-ui/lab'
import type { FormTemplate } from 'types'

const Edit = () => {
  const { teamLeaderId, upwardEvaluationId } = useParams()
  const template = useTemplate('evaluation')

  return (
    <ConversationPaper title="Editar Avaliação Ascendente">
      <header className="flex flex-col text-gray-darkest text-opacity-80">
        <div className="flex items-center gap-4">
          {typeToIcon('upward_evaluation')}
          <h4 className="text-gray-darkest first-letter:uppercase">
            Avaliação Ascendente
          </h4>
        </div>
        <p className="mt-2 text-base italic text-gray-dark">
          Franqueza | Relacionamento | Desenvolvimento do líder
        </p>
        <p className="mt-4 text-base text-gray-dark">
          Me ajude a ser um líder melhor!
        </p>
      </header>
      <Form
        teamLeaderId={teamLeaderId!}
        template={template}
        upwardEvaluationId={upwardEvaluationId!}
      />
    </ConversationPaper>
  )
}

const Form = ({
  teamLeaderId,
  template,
  upwardEvaluationId,
}: {
  teamLeaderId: string
  template: FormTemplate
  upwardEvaluationId: string
}) => {
  const [error, setError] = React.useState('')

  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const { formState, fields, hasErrors } = useForm(
    {},
    {
      requiredFields: ['comment', 'evaluation'],
    },
  )

  const [
    { info: upwardEvaluation, saving, saveError, fetchingInfo },
    { save },
  ] = useCroods({
    id: upwardEvaluationId,
    name: 'upwardEvaluations',
    path: `/team_leaders/${teamLeaderId}/upward_evaluations`,
    fetchOnMount: true,
    afterFailure: (err: any) => {
      if (err.code === 404 || err.message.includes('404'))
        return setError('Líder não encontrado.')
      return setError('Houve algum erro. Tente novamente mais tarde.')
    },
    afterSuccess: ({ data }) => {
      formState.setField('comment', data.comment)
      formState.setField('evaluation', data.evaluation)
    },
  })

  const handleSubmit =
    ({ save, values }: { save: any; values: any }) =>
    (event: React.FormEvent<HTMLFormElement>) => {
      event && event.preventDefault && event.preventDefault()
      save({
        afterSuccess: () => {
          snackbar({ message: 'Avaliação salva com sucesso!', type: 'success' })
          navigate('/')
        },
      })(values)
    }

  if (fetchingInfo) return <Skeleton />

  return (
    <form
      aria-label="form"
      onSubmit={handleSubmit({
        save,
        values: formState.values,
      })}
      className="mt-10 flex flex-col p-0"
    >
      <RadioGroup
        {...fields('text', 'evaluation', [presence()])}
        label="Analisando as últimas conversas que tivemos e o nosso relacionamento diário como um todo, como você o classificaria?"
        setValue={curry(formState.setField)('evaluation')}
        value={formState.values.evaluation || upwardEvaluation.evaluation}
        template={template}
      />

      <div className="relative mt-10">
        <h5>Deixe uma mensagem explicando sua avaliação.</h5>
        <CustomTooltip
          className="absolute right-0 -top-1"
          text="Escreva um pouco sobre sua avaliação"
        />
        <RichText
          {...fields('raw', 'comment', [richTextPresence()])}
          rows={8}
          name="comment"
          className="!mb-0"
          value={upwardEvaluation.comment}
          helper="Me desafie! Por favor, acrescente tudo o que acredita que possa me
          ajudar a ser um líder melhor!"
        />
      </div>

      <FormError>{error || saveError}</FormError>

      <div className="mt-8 flex items-center justify-end gap-4">
        <SubmitButton
          className="order-last"
          disabled={hasErrors}
          loading={saving}
        >
          Salvar e enviar
        </SubmitButton>
      </div>
    </form>
  )
}

export default Edit
