import useOrganization from 'shared/organization/useOrganization'

import Period from 'shared/ui/Period'

const EvaluationCycle = () => {
  const { evaluationCycleStartsAt, evaluationCycleFinishesAt } =
    useOrganization()

  return evaluationCycleStartsAt && evaluationCycleFinishesAt ? (
    <div className="mb-8">
      <Period
        {...({
          title: 'Ciclo em avaliação',
          startsAt: evaluationCycleStartsAt,
          finishesAt: evaluationCycleFinishesAt,
        } as any)}
      />
    </div>
  ) : null
}

export default EvaluationCycle
