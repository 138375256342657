import React, { memo } from 'react'
import { useStepperContext } from './Context'

type Props = {
  children: React.ReactNode[]
  className?: string
  divider?: React.ReactNode
}

export type StepProps = {
  active?: boolean
  passed?: boolean
}

function Steps({ children, className, divider }: Props) {
  const { currentStep } = useStepperContext()

  const steps = children.map((child, index) => {
    const stepProps = {
      active: currentStep === index,
      passed: currentStep > index,
    }
    const el =
      React.isValidElement(child) && typeof child.type === 'function'
        ? React.cloneElement(child, stepProps)
        : child

    return (
      <React.Fragment key={`step-${index}`}>
        {index > 0 && divider}
        {el}
      </React.Fragment>
    )
  })

  return (
    <div key="step" className={className}>
      {steps}
    </div>
  )
}

export default memo(Steps)
