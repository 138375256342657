import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { useAdminText } from 'shared/utils/hooks'
import { cx } from 'shared/helpers'

interface IPaused {
  isPaused?: boolean
  displayOnly?: boolean
  isCompleted?: boolean
}

export default function Bar({ isCompleted }: IPaused) {
  const {
    skillDevelopmentHabit = '',
    skillDevelopmentApplication = '',
    skillDevelopmentKnowledge = '',
  } = useAdminText()

  const getColor = () => {
    if (isCompleted) return 'bg-green text-green-dark/80'
    return 'bg-blue text-blue'
  }

  return (
    <div className="mt-4 flex overflow-hidden rounded text-center font-bold">
      <Tooltip enterTouchDelay={100} arrow title={skillDevelopmentKnowledge}>
        <div
          className={cx('w-[42.5%] md:w-[45%] bg-opacity-10 py-1', getColor())}
        >
          <p>conhecimento</p>
        </div>
      </Tooltip>
      <Tooltip enterTouchDelay={100} arrow title={skillDevelopmentApplication}>
        <div
          className={cx('w-[42.5%] md:w-[45%] bg-opacity-20 py-1', getColor())}
        >
          <p>aplicação</p>
        </div>
      </Tooltip>
      <Tooltip enterTouchDelay={100} arrow title={skillDevelopmentHabit}>
        <div
          className={cx('w-[15%] md:w-[10%] bg-opacity-30 py-1', getColor())}
        >
          <p>hábito</p>
        </div>
      </Tooltip>
    </div>
  )
}
