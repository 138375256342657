import type { LegacyRoute } from 'types'

import Insights from 'vibe/insights'
import TeamInsights from 'vibe/insights/team-insights'
import Employees from 'vibe/employees'
import Employee from 'vibe/employee'
import Circles from 'vibe/circle/list'
import NewCircle from 'vibe/circle/new'
import Invitation from 'vibe/invitation'

const insights: LegacyRoute = {
  component: Insights,
  path: '/insights',
  pageTitle: 'Insights',
  breadcrumbs: ['home', 'insights'],
}

const teamInsights: LegacyRoute = {
  component: TeamInsights,
  path: '/insights/:teamId',
  pageTitle: 'Insights',
  breadcrumbs: ['home', 'insights', 'teamInsights'],
}

const circles: LegacyRoute = {
  component: Circles,
  path: '/circulos',
  pageTitle: 'Círculos',
  breadcrumbs: ['home', 'circles'],
}
const collaborators: LegacyRoute = {
  component: Employees,
  path: '/colaboradores',
  pageTitle: 'Colaboradores',
  breadcrumbs: ['home', 'collaborators'],
}
const collaborator: LegacyRoute = {
  component: Employee,
  path: '/colaboradores/:userId',
  pageTitle: 'Colaborador',
  breadcrumbs: ['home', 'collaborators', 'collaborator'],
}

const inviteCollaborator: LegacyRoute = {
  component: Invitation,
  path: '/convidar',
  pageTitle: 'Convidar Novos Colaboradores',
  breadcrumbs: ['home', 'inviteCollaborator'],
}

const teamCreate: LegacyRoute = {
  component: NewCircle,
  path: '/circulos/criar-novo-circulo',
  pageTitle: 'Criar Novo Círculo',
  breadcrumbs: ['home', 'circles', 'teamCreate'],
}

export default [
  insights,
  teamInsights,
  circles,
  collaborators,
  collaborator,
  inviteCollaborator,
  teamCreate,
]
