import { colors } from 'theme'

const scoreColors = ['#ff4f4f', '#f0b858', '#5bca8a', '#5F91CC']

const performanceColors = [
  colors.crimson10,
  colors.tangerine10,
  colors.yellowLightBg,
  colors.greenLightBg,
]

const backgroundColors = [
  colors.crimson10,
  colors.tangerine10,
  colors.yellowLightBg,
  colors.greenLightBg,
]

const growth = {
  colors: [
    colors.red,
    colors.yellowDark,
    colors.greenDark,
    colors.greenDarkest,
  ],
  performance: [
    colors.redLightest,
    colors.yellowLightest,
    colors.greenLightest,
    colors.greenDark30,
  ],
  background: [
    colors.redLightest,
    colors.yellowLightest,
    colors.greenLightest,
    colors.greenDark30,
  ],
}

const teamResult = {
  colors: [
    colors.red,
    colors.yellowDark,
    colors.greenDark,
    colors.greenDarkest,
  ],
  performance: [
    colors.redLightest,
    colors.yellowLightest,
    colors.greenLightest,
    colors.greenDark30,
  ],
  background: [
    colors.redLightest,
    colors.yellowLightest,
    colors.greenLightest,
    colors.greenDark30,
  ],
}

const maturity = {
  colors: [
    colors.red,
    colors.yellowDark,
    colors.greenDark,
    colors.greenDarkest,
  ],
  performance: [
    colors.redLightest,
    colors.yellowLightest,
    colors.greenLightest,
    colors.greenDark30,
  ],
  background: [
    colors.redLightest,
    colors.yellowLightest,
    colors.greenLightest,
    colors.greenDark30,
  ],
}

const teamPercentage = {
  colors: [colors.white, colors.white, colors.white, colors.white],
  performance: [colors.red, colors.yellow, colors.green, colors.greenDarkest],
  background: [colors.red, colors.yellow, colors.green, colors.greenDarkest],
}

type EvaluationTypes = 'star' | 'careerFrequency' | 'feedbackQuality'
type UseEvaluationColors = (
  indexColor?: number | EvaluationTypes | [string, number],
) => {
  scoreColors: string
  performanceColors?: string
  backgroundColors: string
}

const useEvaluationColors: UseEvaluationColors = (indexColor) => {
  if (Array.isArray(indexColor)) {
    if (typeof indexColor[1] === 'number') {
      const type = indexColor[0]

      if (type === 'growthPath') {
        const index = indexColor[1]
        return {
          scoreColors: growth.colors[index - 1],
          performanceColors: growth.performance[index - 1],
          backgroundColors: growth.background[index - 1],
        }
      }

      if (type === 'teamResultsScore') {
        const index = indexColor[1]
        return {
          scoreColors: teamResult.colors[index - 1],
          performanceColors: teamResult.performance[index - 1],
          backgroundColors: teamResult.background[index - 1],
        }
      }

      if (type === 'maturityScore') {
        const index = indexColor[1]
        return {
          scoreColors: maturity.colors[index - 1],
          performanceColors: maturity.performance[index - 1],
          backgroundColors: maturity.background[index - 1],
        }
      }

      if (type === 'teamPercentage') {
        const index = indexColor[1]
        return {
          scoreColors: teamPercentage.colors[index - 1],
          performanceColors: teamPercentage.performance[index - 1],
          backgroundColors: teamPercentage.background[index - 1],
        }
      }
    }
  }

  if (typeof indexColor === 'number') {
    if (indexColor === 0) {
      return {
        scoreColors: colors.textDisabled,
        performanceColors: colors.black06,
        backgroundColors: colors.black06,
      }
    }

    return {
      scoreColors: scoreColors[indexColor - 1],
      performanceColors: performanceColors[indexColor - 1],
      backgroundColors: backgroundColors[indexColor - 1],
    }
  }

  if (indexColor === 'star') {
    return {
      scoreColors: colors.blue,
      backgroundColors: colors.blueLightBg,
    }
  }

  if (indexColor === 'careerFrequency') {
    return {
      scoreColors: colors.green,
      backgroundColors: colors.greenLightBg,
    }
  }

  if (indexColor === 'feedbackQuality') {
    return {
      scoreColors: colors.black40,
      backgroundColors: colors.black06,
    }
  }

  return {
    scoreColors: scoreColors[0],
    performanceColors: performanceColors[0],
    backgroundColors: backgroundColors[0],
  }
}

export default useEvaluationColors
