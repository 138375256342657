import * as React from 'react'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import Modal from 'shared/ui/Modal'

type Props = {
  handleArchive: () => void
  skillTitle?: string
}

export default function Archive({ handleArchive, skillTitle }: Props) {
  const [modalOpen, setModalOpen] = React.useState(false)

  const handleSubmit = async () => {
    handleArchive()
  }

  return (
    <>
      <Modal.Wrapper
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
        onConfirm={handleSubmit}
      >
        <Modal.Header>Excluir Competência?</Modal.Header>
        <Modal.Content className="max-w-[600px]">
          <p>
            Deseja excluir a competência {skillTitle}? Essa ação não poderá ser
            desfeita e todo o progresso será perdido.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Modal.Confirm>
            <DeleteForeverOutlinedIcon /> Excluir
          </Modal.Confirm>
        </Modal.Actions>
      </Modal.Wrapper>
      <button
        title="Excluir"
        aria-label="Excluir competência"
        type="button"
        onClick={() => {
          setModalOpen(true)
        }}
        className="hover:text-red text-gray-dark"
      >
        <DeleteForeverOutlinedIcon />
      </button>
    </>
  )
}
