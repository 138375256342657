import * as React from 'react'

import ApproveButton from './ApproveButton'
import RejectButton from './RejectButton'
import ViewSolicitationButton from './ViewSolicitationButton'

import type { ParsedSolicitation } from 'portal/helpers'

type Props = Pick<ParsedSolicitation, 'id' | 'status'> & {
  hasUnfilledRequiredFields: boolean
}

const Actions = ({ id, hasUnfilledRequiredFields, status }: Props) => (
  <section className="flex flex-col justify-between gap-y-4 sm:flex-row">
    <section className="grow">
      <ViewSolicitationButton id={id} />
    </section>

    <section className="mb-4 flex flex-none flex-row justify-between gap-4">
      <RejectButton id={id} />
      <BehaviorButton
        id={id}
        hasUnfilledRequiredFields={hasUnfilledRequiredFields}
        status={status}
      />
    </section>
  </section>
)

const BehaviorButton = ({ id, hasUnfilledRequiredFields, status }: Props) => {
  if (hasUnfilledRequiredFields)
    return <ViewSolicitationButton id={id} asApproveButton />
  return <ApproveButton id={id} status={status} />
}

export { Actions }
