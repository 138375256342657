import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import DropdownMenu from 'shared/ui/dropdown/dropdown-menu'
import Icon from 'shared/ui/icon'

type Props = {
  children: React.ReactNode
}

const Dropdown = ({ children }: Props) => {
  return (
    <div
      className="relative inline-block"
      onClick={(event) => {
        event.stopPropagation()
      }}
      data-testid="dropdown"
    >
      <Menu>
        <Menu.Button
          className="flex items-center justify-center w-10 h-10 text-center text-gray-400 bg-transparent rounded-full hover:bg-gray-200 hover:text-gray-500 "
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          aria-label="menu more"
        >
          <Icon icon="more-horizontal" />
        </Menu.Button>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 top-0 z-20 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {children}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

Dropdown.Menu = DropdownMenu

export default Dropdown
