import * as React from 'react'
import Button from '@material-ui/core/Button'
import DialogStageOne from './CycleDialog/stage-one'
import DialogStageTwo from './CycleDialog/stage-two'
import Modal from 'shared/ui/Modal'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import { useCroods } from 'croods'
import type * as z from 'zod'
import type { Cycle } from 'settings/cycle/types'
import { getSchema } from 'settings/cycle/helpers'

type ModalState = 'closed' | 'stageOne' | 'stageTwo'
type Props = {
  disabled?: boolean
  currentCycle: Cycle
  isFirstEvaluationCycle?: boolean
}
function CycleDialog({
  currentCycle,
  disabled,
  isFirstEvaluationCycle,
}: Props) {
  const [modalState, setModalState] = React.useState<ModalState>('closed')
  const { snackbar } = useSnackbar()
  const [, { save }] = useCroods({
    name: 'organization',
    id: 'cycle_duration',
    afterFailure: () =>
      snackbar({
        message: 'houve um erro inesperado, tente novamente',
        type: 'error',
      }),
    afterSuccess: () =>
      snackbar({ message: 'Ciclo atualizado com sucesso', type: 'success' }),
  })

  const schema = getSchema(currentCycle)
  async function handleSubmit(data: z.infer<typeof schema>) {
    if (modalState === 'stageOne') {
      setModalState('stageTwo')
      return true
    }
    await save()({ cycle_duration_in_months: Number(data.selectCycle) })
    setModalState('closed')
    return true
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => setModalState('stageOne')}
        disabled={disabled}
      >
        Editar
      </Button>
      <Modal.FormWrapper
        schema={schema}
        onClose={() => {
          setModalState('closed')
        }}
        onConfirm={handleSubmit}
        preventClose
        open={modalState !== 'closed'}
      >
        {modalState === 'stageOne' && (
          <DialogStageOne selectedDuration={currentCycle.durationInMonths} />
        )}
        {modalState === 'stageTwo' && (
          <DialogStageTwo
            currentCycle={currentCycle}
            isFirstEvaluationCycle={isFirstEvaluationCycle}
          />
        )}
        <Modal.Actions>
          <Modal.Confirm>
            {modalState === 'stageOne' ? 'Alterar' : 'Confirmar'}
          </Modal.Confirm>
        </Modal.Actions>
      </Modal.FormWrapper>
    </>
  )
}

export default CycleDialog
