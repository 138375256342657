import Button from '@material-ui/core/Button'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import { useState } from 'react'
import { formatDateUnits } from 'shared/helpers'
import Modal from 'shared/ui/Modal'
import type { Team } from '.'

function orientation(goals: number, results: number, maturity: number): string {
  if (maturity === 1) {
    return 'Um time sem estrutura é um time que não possui condições mínimas para o trabalho em equipe. Portanto, ele deve implementar uma rotina com métodos, ferramentas e rituais de time, criando uma estrutura mínima para o trabalho coletivo antes de buscar o estabelecimento de metas mais ambiciosas ou cobrança por resultados.'
  }

  if (goals === 1 && (maturity === 2 || maturity === 3)) {
    return 'Sem referência sobre a ambição das metas, qualquer resultado é questionável. Por essa razão, o time deve buscar alguns cases de mercado para medir a ambição de forma minimamente satisfatória e para ter referências externas sobre os desafios que está se propondo a realizar. O jogo apenas começa de verdade quando a “régua do mercado” entra em ação.'
  }

  if (goals === 1 && maturity === 4) {
    return 'Isso é um erro. Volte e revise sua avaliação de performance, porque times com nível de maturidade “Performance” possuem conhecimento para aplicar as métricas e processos de mercado no seu dia a dia, tornando essa combinação impossível.'
  }

  if (goals > 1 && maturity === 2) {
    return 'Independente do time estar obtendo resultados para metas conservadoras, realistas e superiores, não é recomendável aumentar a ambição antes que o time resolva os seus conflitos e diferenças pessoais. Afinal, é preciso trabalhar como um time de verdade e integrado, compartilhando valores pessoais. Portanto, é importante direcionar os seus esforços na melhoria do relacionamento entre as pessoas e trabalhar as suas questões emocionais e de vínculo de confiança.'
  }

  if (goals === 2 && (results === 1 || results === 2) && maturity === 3) {
    return 'Antes de aumentar a ambição das metas, o time precisa ter a sensação de que está progredindo e contribuindo. Recomenda-se, então, que mantenha uma ambição conservadora e foque em atingir os seus objetivos, entregando resultados. A equipe também pode realizar uma busca mais ativa pela implementação de métricas e processos de mercado, com o intuito de aumentar a sua maturidade paralelamente à busca por resultados.'
  }

  if (goals === 2 && results === 3 && maturity === 3) {
    return 'Se o time entrega os resultados de metas conservadoras, está na hora de assumir mais desafios e riscos, aumentando a ambição para níveis realistas. A equipe também pode realizar uma busca mais ativa pela implementação de métricas e processos de mercado, com o intuito de aumentar a sua maturidade paralelamente à busca por novos resultados.'
  }

  if ((goals === 2 || goals === 3) && maturity === 4) {
    return 'Um time de alta performance não deveria se contentar com ambições realistas ou conservadoras, a não ser que esteja enfrentando uma mudança drástica de contexto. A equipe deve manter sua “régua” sempre acima da média de mercado para continuar evoluindo e se desafiando a cada dia.'
  }

  if (goals === 3 && (results === 1 || results === 2) && maturity === 3) {
    return 'Aqui existem duas possibilidades: 1) O time estava preparado para atingir metas realistas e teve um revés. Se este for o caso, deve buscar soluções para o próximo ciclo, mantendo a ambição. 2) o time não estava preparado para assumir uma ambição realista no momento. Nesse caso, pode dar um passo atrás e estabelecer metas mais conservadoras no próximo ciclo, para que volte a ter a sensação de entrega e progresso, buscando uma ambição mais realista no futuro, depois que estiver entregando resultados conservadores com consistência.'
  }

  if (goals === 3 && results === 3 && maturity === 3) {
    return 'Muito bom! O time está pronto para buscar a alta performance e pode manter a ambição das metas como realista ou mesmo apostar em metas mais superiores, entre os melhores do mercado, o que colocaria a equipe em direção a busca por uma maturidade de “Performance”. Bom momento para desafios maiores!'
  }

  if (goals === 4 && (results === 1 || results === 2) && maturity > 2) {
    return 'Aqui existem duas possibilidades: 1) O time estava preparado para atingir metas superiores e teve um revés. Assim, deve buscar soluções para o próximo ciclo, mantendo a ambição desafiadora das metas. 2) O time não estava preparado para assumir uma ambição desafiadora no momento. Nesse caso, pode dar um passo atrás e estabelecer metas mais realistas no próximo ciclo, para que volte a ter a sensação de entrega e progresso, buscando uma ambição mais realista no futuro, depois que estiver entregando resultados conservadores com consistência.'
  }

  if (goals === 4 && results === 3 && maturity > 2) {
    return 'Parabéns! Vocês são um time de alta performance e entregam resultados incríveis, entre os melhores do mercado! Continuem assim, sempre atualizados em relação às métricas e processos de mercado e buscando ser 1% melhor a cada dia!'
  }

  return ''
}

type Props = {
  team: Team & { label?: string }
  indicators: React.ReactNode
  whiteVariant?: boolean
}

export default function TeamButton({ team, indicators, whiteVariant }: Props) {
  const [open, setOpen] = useState(false)
  const { evaluation } = team

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<VisibilityOutlinedIcon />}
        size="small"
        onClick={() => setOpen(true)}
        className={
          whiteVariant ? '!border-white !text-white hover:!bg-white/20' : ''
        }
      >
        Detalhes
      </Button>
      <Modal.Wrapper open={open} onClose={() => setOpen(false)}>
        <Modal.Header>
          <h2 className="text-xl">{team.label} - Detalhes da performance</h2>
        </Modal.Header>
        <Modal.Content className="max-w-2xl">
          <div className="flex flex-col space-y-4">
            <h3 className="text-base">Orientação para o Círculo</h3>
            <p>
              {orientation(
                evaluation.goalsScore,
                evaluation.resultsScore,
                evaluation.maturityScore,
              )}
            </p>
            {indicators}
            <h3 className="text-base text-gray-400">
              O que precisamos continuar fazendo?
            </h3>
            <div
              className="rounded-md bg-gray-200 p-2 text-gray-400"
              dangerouslySetInnerHTML={{
                __html: evaluation.keepDoing || 'Não preenchido.',
              }}
            />
            <h3 className="text-base text-gray-400">
              O que precisamos melhorar?
            </h3>
            <div
              className="rounded-md bg-gray-200 p-2 text-gray-400"
              dangerouslySetInnerHTML={{
                __html: evaluation.needsToImprove || 'Não preenchido.',
              }}
            />
            <h3 className="text-base text-gray-400">Outras anotações</h3>
            <div
              className="rounded-md bg-gray-200 p-2 text-gray-400"
              dangerouslySetInnerHTML={{
                __html: evaluation.notes || 'Não preenchido.',
              }}
            />
            <div className="flex space-x-2">
              <h3 className="text-base text-gray-400">Data</h3>
              <div className="rounded-xl bg-gray-200 px-2 py-1 text-gray-400">
                {formatDateUnits(evaluation.evaluatedAt)}
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions cancelLabel="Voltar" />
      </Modal.Wrapper>
    </>
  )
}
