import { cx } from 'shared/helpers'

type Props = {
  index: number
} & JSX.IntrinsicElements['tr']

export default function Row({ children, className, index, ...props }: Props) {
  return (
    <tr
      className={cx(
        'divide-x divide-gray/50',
        index % 2 === 0 && 'bg-white',
        className,
      )}
      {...props}
    >
      {children}
    </tr>
  )
}
