import { createContext, useContext } from 'react'
import { useCroods } from 'croods'

type PendingContextProps = {
  resetFilter: () => void
  fetchPendingSolicitations: () => Promise<void>
}

const query = { query: 'pending' }

const PendingSolicitationContext = createContext<PendingContextProps>({
  resetFilter: () => {},
  fetchPendingSolicitations: async () => {}
})

export const usePendingSolicitation = () => {
  const context = useContext(PendingSolicitationContext)

  if (!context)
    throw new Error(
      'usePendingSolicitation hook, must be used within PendingSolicitationProvider',
    )

  return context
}

export const PendingSolicitationProvider = ({
  value: valueProp,
  children,
}: any) => {
  const [, { fetch }] = useCroods({
    name: 'solicitations',
    path: 'workflow/solicitations',
    cache: false,
    query,
  })

  const value = {
    reFetchList: () => fetch({ cache: false })(query),
    ...valueProp,
  }

  return (
    <PendingSolicitationContext.Provider value={value}>
      {children}
    </PendingSolicitationContext.Provider>
  )
}
