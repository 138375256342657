import React from 'react'
import { useToggle } from '@seasonedsoftware/utils'
import { useCroods } from 'croods'
import { cx } from 'shared/helpers'
import InputHint from 'shared/formsv2/input-hint'
import filter from 'lodash/filter'
import flow from 'lodash/flow'
import partial from 'lodash/partial'
import { parseItemValues } from '../parseTeamsAndUsers'
import Button from '@material-ui/core/Button'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import isNumber from 'lodash/isNumber'
import { presence } from 'shared/forms/validations'
import { splitAndRemoveSpaces } from 'shared/helpers'
import AutocompleteWithSearch from 'shared/forms/AutocompleteWithSearch'
import Modal from 'shared/ui/Modal'
import { useForm } from 'shared/utils/useForm'
import { autoCompleteParser } from '../parseTeamsAndUsers'
import InviteInput from './invite-input'

import upperFirst from 'lodash/upperFirst'
import { getTypeOf } from 'shared/helpers'
import RespondTo from './respond-to'

import IconButton from '@material-ui/core/IconButton'
import MuiListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'theme'
import Avatar from 'shared/ui/Avatar'
import Icon from 'shared/ui/icon'

const useStyles = makeStyles(() => ({
  listItem: {
    background: colors.grey23,
    borderRadius: '1rem',
    overflow: 'hidden',
    minHeight: '5rem',
    '& span, & p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& hr+div:last-child': {
      minWidth: '55%',
    },
  },
  image: {
    position: 'relative',
    marginRight: '1rem',
    '&::before': {
      top: '-200%',
      left: '-1rem',
      width: '4.5rem',
      bottom: '-200%',
      content: ' ""',
      display: ' block',
      position: ' absolute',
      backgroundColor: colors.grey40,
    },
  },
}))

type ListItemProps = {
  isAvatar: boolean | undefined
  showRole?: boolean | undefined
  showLeaderTag?: boolean
  isGroup?: boolean
  isSolo?: boolean
  item: any
  index: number
  primaryLabel: string
  primaryText: string
  secondaryLabel?: string | null
  secondaryItem?: any
  hasActions: boolean
  secondAction: (() => void) | undefined
}

const ListItem = ({
  isAvatar,
  showRole,
  showLeaderTag,
  isGroup,
  isSolo,
  item,
  index,
  primaryLabel,
  primaryText,
  secondaryLabel,
  secondaryItem,
  hasActions,
  secondAction,
}: ListItemProps) => {
  const classes = useStyles()
  const avatar = {
    avatar: item.avatar,
    email: item.email,
    hasCredential: item.hasCredential,
    recentlyAdded: item.recentlyAdded,
  }

  return (
    <MuiListItem key={`${item?.value}-${index}`} className={classes.listItem}>
      {isAvatar ? (
        <ListItemAvatar className={classes.image}>
          <Avatar user={avatar} />
        </ListItemAvatar>
      ) : null}

      {isGroup ? (
        <ListItemIcon className={classes.image} data-testid="icon">
          <Icon icon="group-work" style={{ width: 40, height: 40 }} />
        </ListItemIcon>
      ) : null}

      {isSolo ? (
        <ListItemIcon className={classes.image} data-testid="icon">
          <Icon icon="adjust" style={{ width: 40, height: 40 }} />
        </ListItemIcon>
      ) : null}

      {/* mobile */}
      <div className="overflow-x-hidden md:hidden">
        <ListItemText
          primary={primaryLabel}
          secondary={
            <Title
              name={primaryText}
              showRole={showRole}
              showLeaderTag={showLeaderTag}
            />
          }
          primaryTypographyProps={{ variant: 'h5', component: 'span' }}
        />

        {secondaryLabel ? (
          <ListItemText
            primary={secondaryLabel}
            secondary={secondaryItem}
            primaryTypographyProps={{
              variant: 'subtitle2',
              component: 'span',
            }}
            secondaryTypographyProps={{ component: 'div' }}
          />
        ) : null}
      </div>

      {/* desktop */}
      <ListItemText
        className="hidden md:block"
        primary={primaryLabel}
        secondary={
          <Title
            name={primaryText}
            showRole={showRole}
            showLeaderTag={showLeaderTag}
          />
        }
        primaryTypographyProps={{ variant: 'h5', component: 'span' }}
      />

      {secondaryLabel ? (
        <>
          <Divider
            className="!mx-4 hidden md:block"
            orientation="vertical"
            flexItem
          />
          <ListItemText
            className="hidden md:block"
            primary={secondaryLabel}
            secondary={secondaryItem}
            primaryTypographyProps={{
              variant: 'subtitle2',
              component: 'span',
            }}
            secondaryTypographyProps={{ component: 'div' }}
          />
        </>
      ) : null}

      {hasActions ? (
        <ListItemSecondaryAction style={{ display: 'flex' }}>
          {secondAction ? (
            <IconButton
              size="small"
              edge="end"
              aria-label="delete"
              onClick={secondAction}
            >
              <Icon icon="clear" data-testid="action" />
            </IconButton>
          ) : null}
        </ListItemSecondaryAction>
      ) : null}
    </MuiListItem>
  )
}

const Title = ({ name, showRole, showLeaderTag }: any) => {
  if (name === null) return null
  if (showRole)
    return (
      <>
        <strong>Cargo/</strong> {name}
      </>
    )
  if (showLeaderTag)
    return (
      <>
        <strong>Líder/</strong> {name}
      </>
    )
  return name
}

const mailMessage = 'Convidado por email'
const setEntitlement = ({
  isAvatar,
  role,
  leaderName,
  invitedByEmail,
}: {
  isAvatar: boolean | undefined
  role: string
  leaderName: string
  invitedByEmail: boolean
}) => {
  if (invitedByEmail) return mailMessage
  return isAvatar ? role : leaderName
}

type ListableItemProps = {
  item: any
  index: number
  isAvatar: boolean | undefined
  isGroup: boolean | undefined
  isSolo: boolean | undefined
  showRole: boolean | undefined
  showLeaderTag: boolean | undefined
  createdTeamId: string | undefined
  handleClickDeleteItem: () => void
  isCircleLeader: boolean
}

const ListableItem = ({
  item,
  index,
  isAvatar,
  isGroup,
  isSolo,
  showRole,
  showLeaderTag,
  createdTeamId,
  handleClickDeleteItem,
  isCircleLeader,
}: ListableItemProps) => {
  if (getTypeOf(item) === 'object') {
    return (
      <ListItem
        isAvatar={isAvatar}
        isGroup={isGroup}
        isSolo={isSolo}
        showRole={item?.invitedByEmail ? undefined : showRole}
        showLeaderTag={showLeaderTag}
        item={item}
        index={index}
        primaryLabel={upperFirst(item.label)}
        primaryText={setEntitlement({
          isAvatar,
          role: item?.role,
          leaderName: item?.leaderName,
          invitedByEmail: item?.invitedByEmail,
        })}
        secondaryLabel={isAvatar ? 'Responde a' : null}
        secondaryItem={
          isAvatar ? (
            <RespondTo
              createdTeamId={createdTeamId}
              userId={item.value}
              memberId={item.memberId}
              isCircleLeader={isCircleLeader}
              initialMainTeam={{
                value: item?.mainTeam?.id,
                label: item?.mainTeam?.name,
              }}
            />
          ) : null
        }
        hasActions
        secondAction={isCircleLeader ? undefined : handleClickDeleteItem}
      />
    )
  }

  return (
    <ListItem
      isAvatar
      item={{ name: item, avatar: null }}
      index={index}
      primaryLabel={item}
      primaryText={mailMessage}
      hasActions
      secondAction={handleClickDeleteItem}
    />
  )
}

const AddItemDialog = ({
  open,
  handleClose,
  list,
  handleSubmit,
  editingIndex,
  step,
  title,
  placeholder,
  multiple,
  parserType = 'user',
  path = '/users/browse',
  selectedOptions = [],
  hasInvite,
  label,
  extraSearchParams,
  debounceTime = 0,
}: any) => {
  const isEditing = isNumber(editingIndex)
  const inputBeingEdited = isEditing ? list[editingIndex] : {}

  const initialValues = hasInvite
    ? { inputName: [], invite: [], ...inputBeingEdited }
    : { inputName: [], ...inputBeingEdited }

  const { formState, fields, hasPartialValues } = useForm(initialValues, {
    requiredFields: hasInvite ? ['inputName', 'invite'] : ['inputName'],
  })
  const onClose = flow(formState.reset, handleClose)

  const onSubmit = async () => {
    if (hasPartialValues) return

    const transform = isEditing ? transformToUpdate : transformToCreate
    const parseList = multiple ? list : []

    handleSubmit(
      transform({
        newItem: formState.values,
        items: parseList,
        index: editingIndex,
        step,
      }),
    )
    onClose()
  }

  const autocompleteFields = fields('raw', 'inputName', [presence()], {
    touchOnChange: true,
  })

  return (
    <Modal.Wrapper open={open} onClose={onClose} onConfirm={onSubmit}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <AutocompleteWithSearch
          parseOptions={autoCompleteParser(parserType)}
          croodsConfig={{
            name: 'users',
            path,
          }}
          autocompleteProps={{
            ...autocompleteFields,
            noOptionsText: 'Nenhum dado encontrado',
            disabledOptions: disabledOptions({
              selectedOptions,
              parserType,
              usedOptions: formState.values || [],
              list: list || [],
            }),
            multiple,
          }}
          inputProps={{
            ...autocompleteFields,
            label,
            placeholder,
            name: title,
          }}
          queryParamName={parserType === 'user' ? 'query' : 'search'}
          extraSearchParams={extraSearchParams}
          debounceTime={debounceTime}
        />
        {hasInvite ? (
          <div className="mt-5">
            <InviteInput
              setValue={formState.setField.bind(null, 'invite')}
              setError={formState.setFieldError.bind(null, 'invite')}
              hasCollaborator={formState.values.inputName.length}
            />
          </div>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Modal.Confirm
          title={isEditing ? 'Atualizar' : 'Adicionar'}
          disabled={hasPartialValues}
        >
          {isEditing ? 'Atualizar' : 'Adicionar'}
        </Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

const transformToCreate = ({ newItem, items, step }: any) => {
  const { inputName, ...props } = newItem
  const innerValue = Array.isArray(inputName) ? inputName : [inputName]
  let { invite: invites } = props
  invites = invites?.length ? splitAndRemoveSpaces(invites) : []
  return [
    {
      step,
      inputName: [...innerValue, ...items, ...invites],
    },
  ]
}

const transformToUpdate = ({ newItem, items, index }: any) => {
  const newItems = [...items]
  newItems[index] = newItem

  return newItems
}

const disabledOptions =
  ({ selectedOptions = [], parserType, usedOptions, list }: any) =>
  (option: any) => {
    const parsedOptions = usedOptions?.inputName?.length
      ? usedOptions?.inputName
      : []
    const allOptions = [...selectedOptions, ...parsedOptions, ...list]
    if (parserType === 'user')
      return allOptions.some(
        (item) => item.value === option.value || item === option.value,
      )
    return allOptions.some(
      (item) => item.value === option.value || item === option.value,
    )
  }

const AddButton = ({ parsedItemValues, multiple, handleButtonClick }: any) => (
  <Button
    title={titleAppearance(parsedItemValues.length, multiple)}
    color="primary"
    variant="contained"
    size="small"
    startIcon={
      <ButtonApearance
        type="icon"
        length={parsedItemValues.length}
        multiple={multiple}
      />
    }
    onClick={handleButtonClick}
    className={cx('!h-auto', parsedItemValues.length > 0 && '!mt-4 sm:!mt-8')}
  >
    {parsedItemValues.length ? (
      <ButtonApearance type="text" multiple={multiple} />
    ) : (
      'adicionar'
    )}
  </Button>
)

export const ButtonApearance = ({ type, length, multiple }: any) => {
  if (type === 'icon')
    return length && !multiple ? <AutorenewIcon /> : <AddCircleOutlineIcon />
  return <>{multiple ? 'adicionar' : 'trocar'}</>
}

const titleAppearance = (value: any, multiple: any) => {
  if (value) {
    return multiple ? 'adicionar' : 'trocar'
  }
  return 'adicionar'
}

export const handleClickDeleteItem =
  ({ index, list, onChange, original, removeMember, save }: any) =>
  () => {
    const filtered = filter(list, (_, itemIndex) => itemIndex !== index)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [{ inputName, ...props }] = [...original]
    onChange([{ inputName: filtered, props }])
    if (removeMember) {
      save({ stateId: list[index].value.toString() })({
        membersIdsToRemove: [list[index].value],
      })
    }
  }

type Props = {
  value: any
  onChange: any
  error?: string | undefined
  step: string
  title: string
  optionalText?: string
  subtitle?: string
  placeholder: string
  multiple?: boolean
  path: string
  parserType?: string
  isAvatar?: boolean
  showRole?: boolean
  showLeaderTag?: boolean
  isGroup?: boolean
  isSolo?: boolean
  selectedOptions?: any
  hasInvite?: boolean
  label?: string
  createdTeamId?: string
  isCircleLeader?: boolean
  extraSearchParams?: any
  removeMember?: boolean
  debounceTime?: number
  formState?: any
  childCircleLeaders?: any
}

const AddItem = ({
  value = [],
  onChange,
  error,
  step,
  title,
  optionalText,
  subtitle,
  placeholder,
  multiple = false,
  path,
  parserType,
  isAvatar,
  showRole,
  showLeaderTag,
  isGroup,
  isSolo,
  selectedOptions,
  hasInvite,
  label,
  createdTeamId,
  isCircleLeader = false,
  extraSearchParams,
  removeMember,
  debounceTime = 0,
}: Props) => {
  const [open, toggle] = useToggle(false)
  const [selectedInputIndex, setSelectedInputIndex] = React.useState()
  // @ts-expect-error
  const handleDialogClose = flow(toggle, partial(setSelectedInputIndex, null))
  const handleButtonClick = !open ? toggle : undefined

  const [, { save }] = useCroods({
    name: 'membersRemove',
    path: 'team',
    id: createdTeamId,
  })

  const parsedItemValues = parseItemValues(value)

  return (
    <>
      <label className={cx('h5', error && 'text-red')}>
        {title}
        {Boolean(optionalText) && (
          <span className="subtitle1 text-gray-dark">{` (${optionalText})`}</span>
        )}
      </label>
      {Boolean(subtitle?.length) && (
        <p className="subtitle1 text-gray-dark">{subtitle}</p>
      )}
      <div
        className={cx(
          'mt-2 flex flex-col items-center rounded border border-dashed py-4 px-2 sm:py-8 sm:px-8',
          error ? 'border-red' : 'border-blue/70',
        )}
      >
        <ul className="flex w-full list-none flex-col gap-4">
          {parsedItemValues.map((item, index) => (
            <ListableItem
              key={`${item}-${index}`}
              handleClickDeleteItem={handleClickDeleteItem({
                onChange,
                list: parsedItemValues,
                index,
                original: value,
                removeMember,
                save,
              })}
              {...{
                item,
                index,
                isAvatar,
                isGroup,
                isSolo,
                showRole,
                showLeaderTag,
                createdTeamId,
                isCircleLeader,
              }}
            />
          ))}
        </ul>
        <AddButton {...{ parsedItemValues, multiple, handleButtonClick }} />
        <AddItemDialog
          handleClose={handleDialogClose}
          list={parsedItemValues}
          handleSubmit={onChange}
          editingIndex={selectedInputIndex}
          {...{
            open,
            step,
            title,
            placeholder,
            multiple,
            path,
            parserType,
            selectedOptions,
            hasInvite,
            label,
            extraSearchParams,
          }}
          debounceTime={debounceTime}
        />
      </div>
      <InputHint isError={Boolean(error)}>{error}</InputHint>
    </>
  )
}

export default AddItem
