import { Link } from 'react-router-dom'
import { cx } from 'shared/helpers'
import getIcon from 'shared/ui/get-icon'
import { match } from 'ts-pattern'

type Props = {
  title: string
  last?: boolean
  to: string
  isModule?: boolean
  icon?: string
}

export default function Item({ title, last, to, isModule, icon }: Props) {
  const iconClasses = 'w-[20px] h-[20px] shrink-0'

  const itemIcon = match([Boolean(icon), Boolean(isModule)])
    .with([true, true], () => (
      <img src={icon} alt={title} width={20} className={iconClasses} />
    ))
    .with([true, false], () => getIcon(icon!, { className: iconClasses }))
    .otherwise(() => null)

  return (
    <Link
      to={to}
      className={cx('no-underline', last ? 'text-blue' : 'text-blue/80')}
    >
      <p className="flex gap-0.5 items-center font-bold tracking-normal">
        {itemIcon}
        <span className="w-full max-w-[80px] md:max-w-[180px] truncate">
          {title}
        </span>
      </p>
    </Link>
  )
}
