import compact from 'lodash/compact'
import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'
import { z } from 'zod'
import type { authUserSchema } from './auth'
import { getCurrentOrganizationSlug } from './auth'
import type { RouteCategory } from './routes'
import { useRouteHandle } from './routes'

const TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN

function useIndentifyAnalytics(
  currentUser: z.infer<typeof authUserSchema> | null,
) {
  const id = currentUser?.id
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!TOKEN) return

    if (!initialized) {
      mixpanel.init(TOKEN)
      setInitialized(true)
    }

    if (!id) {
      mixpanel.reset()
      return
    }

    const { email, hierarchyPosition, dataAnalyst, admin } = currentUser
    const { firstName, lastName, createdAt } = currentUser
    const slug = getCurrentOrganizationSlug(window.location.href)

    mixpanel.identify(String(id))

    mixpanel.set_group('Organization', slug)

    mixpanel.people.set({
      $name: compact([firstName, lastName]).join(' '),
      $email: email,
      Organization: slug,
      'Hierarchy Position': hierarchyPosition,
      'Data Analyst': dataAnalyst,
      Admin: admin,
      'Created at': createdAt,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
}

const eventNamesSchema = z.enum([
  'Conversation',
  'Upward evaluation',
  'Feedback',
  'Performance evaluation',
  'Talent evaluation',
  'Generate feedback',
  'Generate skill',
  'Generate development plan',
])

type EventNames = z.infer<typeof eventNamesSchema>
type TrackParamsWithEventNames = [
  EventNames,
  Parameters<typeof mixpanel.track>[1]?,
  Parameters<typeof mixpanel.track>[2]?,
  Parameters<typeof mixpanel.track>[3]?,
]
type MixpanelTrackWithEventNames = (...args: TrackParamsWithEventNames) => void

function makeTrackAnalytics(category: RouteCategory | undefined) {
  const slug = getCurrentOrganizationSlug(window.location.href)

  const track: MixpanelTrackWithEventNames = (
    eventName,
    properties = {},
    optionsOrCallback,
    callback,
  ) => {
    if (!TOKEN) return

    const parsedEventName = eventNamesSchema.safeParse(eventName)
    if (!parsedEventName.success) return

    mixpanel.track(
      parsedEventName.data,
      { Organization: slug, Category: category, ...properties },
      optionsOrCallback,
      callback,
    )
  }

  return track
}

function trackTalksAnalytics(...args: TrackParamsWithEventNames) {
  makeTrackAnalytics('talks')(...args)
}

function useTrackAnalytics() {
  const { category } = useRouteHandle()

  return makeTrackAnalytics(category)
}

export { useIndentifyAnalytics, useTrackAnalytics, trackTalksAnalytics }
