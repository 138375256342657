import React from 'react'

import Modal from 'shared/ui/Modal'
import { useCroods } from 'croods'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import moment from 'moment'

type Props = {
  openDialog: boolean
  handleClose: () => void
  finishesAt: string | undefined
  teamEvaluationId: number
  teamId: number
}

const ConfirmationDialog = ({
  openDialog,
  handleClose,
  finishesAt,
  teamEvaluationId,
  teamId,
}: Props) => {
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const [{}, { save }] = useCroods({
    name: 'setToDraft',
    customPath: `/team_evaluations/${teamEvaluationId}/unpublish`,
  })

  const handleSubmit = async () => {
    save({
      method: 'PUT',
      afterSuccess: () => {
        navigate(`/performance/avaliacao-de-performance/${teamId}`)
      },
      afterFailure: (e) => {
        if (e.response?.data.message) {
          snackbar({
            message: e.response.data.message,
            type: 'error',
          })
        } else {
          snackbar({
            message: 'houve um erro inesperado, tente novamente',
            type: 'error',
          })
        }
        handleClose()
      },
    })()
  }

  return (
    <Modal.Wrapper
      open={openDialog}
      onConfirm={handleSubmit}
      onClose={handleClose}
      preventClose={true}
    >
      <Modal.Header>Editar avaliação finalizada</Modal.Header>
      <Modal.Content>
        <h4 className="mb-2">Esta avaliação voltará para rascunho.</h4>
        {finishesAt && (
          <p>
            Você tem até {moment(finishesAt).format('DD/MM/YYYY')} para
            finalizá-la.
          </p>
        )}
      </Modal.Content>
      <Modal.Actions cancelLabel="VOLTAR">
        <Modal.Confirm>EDITAR AVALIAÇÃO</Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default ConfirmationDialog
