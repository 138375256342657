import React from 'react'

import Modal from 'shared/ui/Modal'
import { useCroods } from 'croods'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import moment from 'moment'

type Props = {
  openDialog: boolean
  handleClose: () => void
  finishesAt: string | undefined
  fetch?: () => () => Promise<void>
}

const ConfirmationDialog = ({
  openDialog,
  handleClose,
  finishesAt,
  fetch,
}: Props) => {
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()

  const [{}, { save }] = useCroods({
    name: 'setMemberEvaluationToDraft',
    customPath: '/member_evaluations/unpublish',
  })

  const handleSubmit = async () => {
    save({
      method: 'PUT',
      afterSuccess: async () => {
        if (location.pathname.includes('avaliar-talentos')) {
          // We need this manual refresh while we are not using loaders and actions
          // fetch `memberEvaluations` from talentRate
          fetch && (await fetch()())
        } else {
          navigate('/performance/avaliar-talentos')
        }
        handleClose()
      },
      afterFailure: (e) => {
        if (e.response?.data.message) {
          snackbar({
            message: e.response.data.message,
            type: 'error',
          })
        } else {
          snackbar({
            message: 'houve um erro inesperado, tente novamente',
            type: 'error',
          })
        }
        handleClose()
      },
    })()
  }

  return (
    <Modal.Wrapper
      open={openDialog}
      onConfirm={handleSubmit}
      onClose={handleClose}
      preventClose={true}
    >
      <Modal.Header>Editar avaliação finalizada</Modal.Header>
      <Modal.Content>
        <h4 className="mb-2">Esta avaliação voltará para rascunho.</h4>
        {finishesAt && (
          <p>
            Você tem até {moment(finishesAt).format('DD/MM/YYYY')} para
            finalizá-la.
          </p>
        )}
      </Modal.Content>
      <Modal.Actions cancelLabel="VOLTAR">
        <Modal.Confirm>EDITAR AVALIAÇÃO</Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default ConfirmationDialog
