import * as React from 'react'
import Button from '@material-ui/core/Button'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import HeaderControls from 'shared/ui/HeaderControls'
import { useSearch } from 'shared/utils/hooks'
import List from './list'
import Dialog from './Dialog'
import type { Role } from 'performance/evaluations/membersEvaluation/types'

const OrganizationRoles = ({ pageTitle }: { pageTitle: string }) => {
  const { setSearch, select } = useSearch<Role>()
  const [dialogType, setDialogType] = React.useState<
    'create' | 'edit' | 'delete' | null
  >(null)
  const [roleId, setRoleId] = React.useState(0)
  const [roleName, setRoleName] = React.useState('')

  return (
    <div>
      <h3>{pageTitle}</h3>
      <HeaderControls searchLabel="Buscar papel" setSearch={setSearch}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setDialogType('create')}
        >
          Criar papel
        </Button>
      </HeaderControls>
      <Dialog
        dialogType={dialogType}
        setDialogType={setDialogType}
        roleId={roleId}
        roleName={roleName}
      />

      <div className="card overflow-visible">
        <List
          select={select}
          setDialogType={setDialogType}
          setRoleId={setRoleId}
          setRoleName={setRoleName}
        />
      </div>
    </div>
  )
}

export default OrganizationRoles
