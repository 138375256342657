import React from 'react'

import BaseConversationListItem from 'talks/conversations/BaseConversationListItem'

type Props = {
  conversation: any
  teamMemberId: string
  isEditMode?: boolean
}

// This component is marked for deletion and should not be used. Rencer BaseConversationListItem instead.
const ListItem = ({ conversation, teamMemberId, isEditMode }: Props) => (
  <BaseConversationListItem
    conversation={conversation}
    teamMemberId={teamMemberId}
    isEditMode={isEditMode}
  />
)

export default ListItem
