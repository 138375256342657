import * as React from 'react'
import { getFullName } from 'shared/helpers'
import { useNavigate } from 'react-router-dom'
import Avatar from 'shared/ui/Avatar'

import Table from 'shared/ui/table'
import type { User } from 'types'

const classNameTd =
  'flex items-center justify-between md:table-cell py-2 md:py-3 before:block before:font-bold before:content-[attr(data-title)] md:before:content-none'

type Props = {
  rows: User[]
}
const CollaboratorTable: React.FC<Props> = ({ rows }) => {
  const navigate = useNavigate()

  return (
    <div className="max-w-full">
      <Table id="collaborator-table">
        <Table.Thead>
          <Table.Th>Nome</Table.Th>
          <Table.Th>Cargo</Table.Th>
        </Table.Thead>
        <Table.Tbody>
          {rows.map((user) => (
            <Table.Tr
              key={user.id}
              className="cursor-pointer !py-6 transition even:bg-gray-50 hover:bg-gray-lightest"
              onClick={() => navigate(`/colaboradores/${user.id}`)}
            >
              <Table.Td data-title="Nome" className={classNameTd}>
                <div className="flex flex-row-reverse items-center gap-3 overflow-hidden md:flex-row md:items-start md:justify-start">
                  <Avatar user={user} size={30} />
                  <p className="ml-4 flex-1 self-center overflow-hidden text-ellipsis whitespace-nowrap text-left text-base font-semibold">
                    {getFullName(user)}
                  </p>
                </div>
              </Table.Td>
              <Table.Td data-title="Cargo" className={classNameTd}>
                <p
                  className="overflow-hidden text-ellipsis text-base font-semibold"
                  title={user.role}
                >
                  {user.role}
                </p>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </div>
  )
}

export default CollaboratorTable
