import { cx } from 'shared/helpers'

type Props = JSX.IntrinsicElements['td'] & {
  'data-title'?: string
}

const Td = ({ children, className, ...props }: Props) => {
  return (
    <td
      {...props}
      className={cx(
        'block px-3 before:block before:font-bold before:content-[attr(data-title)] before:mb-1 md:table-cell md:before:content-none',
        className,
      )}
    >
      {children}
    </td>
  )
}

export default Td
