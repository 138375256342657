import { cx } from 'shared/helpers'
import ModalContext from './Context'
import OverlayWrapper from './OverlayWrapper'

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  enabled?: boolean
  loading?: boolean
  onClose: () => void
  onConfirm?: () => Promise<void>
  preventClose?: boolean
  open?: boolean
}
const Wrapper: React.FC<Props> = ({
  onClose,
  children,
  loading = false,
  enabled = true,
  open = true,
  onConfirm,
  preventClose = false,
  className,
  ...props
}) => {
  const disabled = loading || !enabled
  const handleConfirm = async () => {
    if (enabled) {
      if (onConfirm) {
        await onConfirm()
      }
      preventClose || onClose()
    }
  }

  const value = { loading, onClose, disabled, handleConfirm, isForm: false }
  return (
    <ModalContext.Provider value={value}>
      <OverlayWrapper show={open} onClose={onClose}>
        <div
          {...props}
          className={cx(
            className,
            'relative mx-auto w-full md:min-w-[400px] rounded-lg bg-white shadow-lg md:w-max md:max-w-5xl',
          )}
        >
          {children}
        </div>
      </OverlayWrapper>
    </ModalContext.Provider>
  )
}

export default Wrapper
