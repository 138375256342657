import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import CardHeader from '@material-ui/core/CardHeader'
import MemberEvaluationInfo from './MemberEvaluationInfo'
import TouchAppIcon from '@material-ui/icons/TouchApp'
import SelectedLabel from './SelectedLabel'
import Tag from '../Tag'
import type { BoxContent } from 'performance/evaluations/membersEvaluation/types'
import EvaluationDetails from './EvaluationDetails'

export default function SelectedBox({
  boxContent,
}: {
  boxContent: BoxContent | null
}) {
  const [selectedMemberId, setSelectedMemberId] = React.useState<number | null>(
    null,
  )

  const { members } = boxContent || { members: [] }

  const selectedMember = members.find(
    (member) => member.id === selectedMemberId,
  )

  React.useEffect(() => {
    if (members.length) {
      setSelectedMemberId(members[0]?.id)
    }
  }, [boxContent, members])

  const boxCx = 'rounded-2xl relative overflow-hidden flex'

  if (!boxContent) {
    return (
      <div
        data-testid="selected-box"
        className={`card ${boxCx} m-auto`}
        id="selected-scroll-target"
      >
        <CardHeader
          className="p-2"
          subheader={
            <div className="relative h-12 w-16 rounded-2xl bg-white">
              <div className="h-full w-full overflow-hidden rounded-2xl">
                <div className="h-4 w-full bg-black/10" />
              </div>
              <div className="absolute inset-0 bottom-[-5px] flex items-end justify-center">
                <TouchAppIcon className="h-9 w-9 text-gray" />
              </div>
            </div>
          }
        />
        <div className="m-6 flex flex-col items-start justify-start sm:flex-row sm:items-center">
          <p className="body2 mb-4 sm:mr-8 sm:mb-0">
            Clique em um <b>quadrante</b> para ver mais detalhes.
          </p>
        </div>
      </div>
    )
  }

  const teams = members
    ? boxContent.members
        .map((member) => member.teamName)
        .filter(function (x, i, a) {
          return a.indexOf(x) === i
        })
    : null

  return (
    <div className="grid grid-cols-1 gap-6 lg:grid-cols-5">
      <div
        data-testid="selected-box"
        className={`card ${boxCx} col-span-3 min-h-[300px] w-full flex-col border-[3px] border-blue sm:min-h-[500px]`}
        id="selected-scroll-target"
      >
        <CardHeader
          style={{ background: '#F2F6FB' }}
          className="min-h-[130px] items-start p-4 sm:items-center sm:p-8"
          subheader={
            <div className="flex flex-col items-start justify-start sm:flex-row sm:items-center">
              <div className="mb-4 sm:mr-8 sm:mb-0">
                <SelectedLabel name={boxContent.name} />
              </div>

              <p className="body2">{boxContent.description}</p>
            </div>
          }
        />
        {teams && (
          <Grid container spacing={2} direction="column" className="p-5 md:p-8">
            {teams.map((team, index) => {
              return (
                <div key={index} className="pb-6">
                  <div>
                    <p className="font-bold">{team}</p>
                  </div>
                  <div className="grid md:grid-cols-2 gap-1">
                    {boxContent.members
                      .filter((member) => team === member.teamName)
                      .map((member) => {
                        if (!member.user) return null

                        return (
                          <MemberEvaluationInfo
                            id={member.id}
                            key={member.user?.id}
                            user={member.user}
                            setSelectedMemberId={setSelectedMemberId}
                            selected={member.id === selectedMemberId}
                          />
                        )
                      })}
                  </div>
                </div>
              )
            })}
          </Grid>
        )}
        <Tag
          totalGroupMembers={boxContent.totalGroupMembers}
          totalMembers={boxContent.totalMembers}
        />
      </div>
      <div className="col-span-3 lg:col-span-2">
        {selectedMember && selectedMember.latestEvaluation && (
          <EvaluationDetails member={selectedMember} />
        )}
      </div>
    </div>
  )
}
