import Users from 'settings/users'
import OrganizationRoles from 'settings/organizationRoles'
import Cycle from 'settings/cycle'
import List from 'settings/solicitationTemplate/list'
import New from 'settings/solicitationTemplate/new'
import Edit from 'settings/solicitationTemplate/edit'
import Api from 'settings/api'
import Conversations from 'settings/conversations'
import Performance from 'settings/performance'
import { Portal } from 'settings/portal'
import type { LegacyRoute } from 'types'

const users: LegacyRoute = {
  component: Users,
  pageTitle: 'Gestão de Colaboradores',
  path: '/configuracoes',
  breadcrumbs: ['home', 'users'],
}
const roles: LegacyRoute = {
  component: OrganizationRoles,
  pageTitle: 'Papéis',
  path: '/configuracoes/papeis',
  breadcrumbs: ['home', 'roles'],
}
const cicles: LegacyRoute = {
  component: Cycle,
  pageTitle: 'Ciclo',
  path: '/configuracoes/ciclo',
  breadcrumbs: ['home', 'cicles'],
}
const solicitations: LegacyRoute = {
  component: List,
  pageTitle: 'Modelos de Solicitação',
  path: '/configuracoes/modelos-de-solicitacao',
  breadcrumbs: ['home', 'solicitations'],
}
const solicitationsList: LegacyRoute = {
  component: New,
  pageTitle: 'Novo Modelo de Solicitação',
  path: '/configuracoes/modelos-de-solicitacao/novo',
  breadcrumbs: ['home', 'solicitations', 'solicitationsList'],
}

const solicitationsEdit: LegacyRoute = {
  component: Edit,
  pageTitle: 'Editar Modelo de Solicitação',
  path: '/configuracoes/modelos-de-solicitacao/:id/editar',
  breadcrumbs: ['home', 'solicitations', 'solicitationsEdit'],
}

const api: LegacyRoute = {
  component: Api,
  pageTitle: 'API',
  path: '/configuracoes/api',
  breadcrumbs: ['home', 'api'],
}

const conversations: LegacyRoute = {
  component: Conversations,
  pageTitle: 'Conversas',
  path: '/configuracoes/conversas',
  breadcrumbs: ['home', 'conversations'],
}

const performance: LegacyRoute = {
  component: Performance,
  pageTitle: 'Performance',
  path: '/configuracoes/performance',
  breadcrumbs: ['home', 'performance'],
}

const portal: LegacyRoute = {
  component: Portal,
  pageTitle: 'Portal',
  path: '/configuracoes/portal',
  breadcrumbs: ['home', 'portal'],
}

export default [
  users,
  roles,
  cicles,
  solicitations,
  solicitationsList,
  solicitationsEdit,
  api,
  conversations,
  performance,
  portal,
]
