import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'
import { cx } from 'shared/helpers'
import PlaceRoundedIcon from '@material-ui/icons/PlaceRounded'
import { THUMB_NAMES } from '..'
import getStyle, { styles } from './getStyle'
import type { SliderProps } from '../../../types'
import { checkDisabled } from './checkDisabled'

export default function CurrentCycle({ props, isMark }: SliderProps) {
  const { className, isPaused, displayOnly, isCompleted, ...thumbProps } = props

  const newProps = checkDisabled(thumbProps, isPaused, displayOnly, isCompleted)

  return (
    <Tooltip title={THUMB_NAMES.now} placement="top" arrow>
      <div
        {...newProps} // has to come first
        draggable={!isMark}
      >
        <div
          className={cx(
            className,
            getStyle({ isPaused, isMark, displayOnly, isCompleted }),
            styles.round,
          )}
        >
          <PlaceRoundedIcon fontSize="inherit" />
        </div>
      </div>
    </Tooltip>
  )
}
