import useDescriptionTexts from '../form/useDescriptionTexts'
import optionalFieldsLabels from '../form/optionalFieldsLabels'
import type { FormState } from '../types'

type Props = {
  formState: FormState
}

type SummaryData = {
  evaluatedAt: string
  fields: {
    title: string
    description: string
    type: 'ambitions' | 'results' | 'maturity' | 'performance'
    score: number
  }[]
  optionalFields: {
    title: string
    description: string | undefined
  }[]
}

const useSummaryData = ({ formState }: Props): SummaryData => {
  const { goalsScore, resultsScore, maturityScore } = useDescriptionTexts()

  return {
    evaluatedAt: formState.evaluatedAt.format('DD/MM/YYYY'),
    fields: [
      {
        title: goalsScore.title,
        description: formState?.goalsScore
          ? goalsScore.data[formState.goalsScore - 1].label
          : '',
        type: 'ambitions',
        score: Number(formState?.goalsScore ?? 0),
      },
      {
        title: resultsScore.title,
        description: formState?.resultsScore
          ? resultsScore.data[formState.resultsScore - 1].label
          : '',
        type: 'results',
        score: Number(formState?.resultsScore ?? 0),
      },
      {
        title: maturityScore.title,
        description: formState?.maturityScore
          ? maturityScore.data[formState.maturityScore - 1].label
          : '',
        type: 'maturity',
        score: Number(formState?.maturityScore ?? 0),
      },
    ],
    optionalFields: [
      {
        title: optionalFieldsLabels.keepDoing,
        description: formState?.keepDoing ?? undefined,
      },
      {
        title: optionalFieldsLabels.needsToImprove,
        description: formState?.needsToImprove ?? undefined,
      },
      {
        title: optionalFieldsLabels.notes,
        description: formState?.notes ?? undefined,
      },
    ],
  }
}

export default useSummaryData
