import type { Role } from '../../../types'
import PrimaryRole from './PrimaryRole'
import SecondaryRole from './SecondaryRole'

export type RolesStateHandler = {
  setPrimaryRole: (role: Role) => void
  setSecondaryRoles: (roles: Role[]) => void
  getDisabledRoles: (role: Role) => boolean
}

type Props = {
  allRoles: Role[]
  primaryRole: Role | null
  secondaryRoles: Role[]
  handleRoles: RolesStateHandler
  handleAddNewRole: any
}

export default function Roles({
  allRoles,
  primaryRole,
  secondaryRoles,
  handleRoles,
  handleAddNewRole,
}: Props) {
  const renderOption = (option: Role) => {
    if (option.inputValue) {
      return (
        <span className="font-bold uppercase text-blue">
          Adicionar &quot;{option.name}&quot;
        </span>
      )
    }
    return <span>{option.name}</span>
  }

  return (
    <>
      <p className="text-lg font-bold">Papel principal</p>
      <PrimaryRole
        allRoles={allRoles}
        role={primaryRole}
        setRole={handleRoles.setPrimaryRole}
        handleAddNewRole={handleAddNewRole}
        renderOption={renderOption}
        getDisabledRoles={handleRoles.getDisabledRoles}
        autocompleteProps={{
          classes: {
            root: 'custom-autocomplete-warning',
            popper: 'mt-6',
          },
        }}
      />
      <p className="mt-6 mb-1 text-lg font-bold">
        Outros papéis <span className="text-sm font-normal"> - Opcional</span>
      </p>
      <SecondaryRole
        allRoles={allRoles}
        roles={secondaryRoles}
        setRoles={handleRoles.setSecondaryRoles}
        handleAddNewRole={handleAddNewRole}
        getDisabledRoles={handleRoles.getDisabledRoles}
        renderOption={renderOption}
      />
    </>
  )
}
