import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

import type { SkeletonProps } from '@material-ui/lab/Skeleton'

type Props = SkeletonProps
const Loading = ({
  height = 'auto',
  width = '100%',
  variant = 'rect',
  animation = 'wave',
  ...props
}: Props) => (
  <Skeleton
    role="progressbar"
    variant={variant}
    animation={animation}
    height={height}
    width={width}
    style={{ minHeight: height }}
    {...props}
  />
)

export default Loading
