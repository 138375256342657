import React from 'react'
import { useCroods } from 'croods'
import type { NavigateFunction } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router-dom'

import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import type { Dispatcher } from 'shared/ui/Snackbar/types'
import NewEvaluation from './NewEvaluation'
import EvaluationCycle from './EvaluationCycle'
import AccordionSummary from './AccordionSummary'
import { useRedirectWhenUnauthenticated } from 'shared/utils/hooks'

import { getDenomination } from 'shared/helpers'
import TeamCard from 'shared/ui/teamMembersCard/Card'

import Skeleton from 'shared/ui/skeleton'
import FeedbackMessage from 'shared/ui/FeedbackMessage'

const afterFailure = (
  snackbar: Dispatcher,
  code: number,
  navigate: NavigateFunction,
) => {
  if (code === 403) {
    snackbar({
      message: 'Você não tem permissão para acessar essa página',
      type: 'error',
    })
    navigate('/')
  } else {
    snackbar({
      message: 'houve um erro inesperado, tente novamente',
      type: 'error',
    })
  }
}

const TeamEvaluation = () => {
  const { teamId } = useParams()
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()
  const redirectWhenUnauthenticated = useRedirectWhenUnauthenticated()

  const [{ info: team, fetchingInfo, infoError }] = useCroods({
    name: 'teams',
    id: teamId,
    path: `/team`,
    fetchOnMount: true,
    cache: true,
    after4xx: redirectWhenUnauthenticated((code) =>
      afterFailure(snackbar, code, navigate),
    ),
  })

  const { leaders = [], members = [] } = team || {}

  return (
    <div>
      <div className="flex flex-col items-baseline justify-between md:flex-row">
        <h3>Avaliação de Performance</h3>
      </div>

      <EvaluationCycle />

      {!fetchingInfo && !infoError ? (
        <TeamCard
          name={team?.name}
          leaders={leaders}
          members={members}
          teamId={teamId}
          team={team}
          showParentsAndChildren={false}
          denomination={getDenomination(team?.denomination)}
          hasEvaluateButton={false}
          hasEditButton={false}
          variant="primary"
          status={team?.status}
        />
      ) : infoError ? (
        <FeedbackMessage message={infoError} />
      ) : (
        <Skeleton height="400px" />
      )}

      <NewEvaluation teamId={teamId as unknown as number} />

      {team?.previousEvaluationId && (
        <AccordionSummary previousEvaluationId={team.previousEvaluationId} />
      )}
    </div>
  )
}

export default TeamEvaluation
