import { useRef } from 'react'
import { useInterval } from '@seasonedsoftware/utils'
import type { Actions } from 'croods'
import type { AddToCroodsQueue } from './types'

type SaveConfig = Parameters<Actions['save']>[0]
type SaveData = Parameters<ReturnType<Actions['save']>>[0]
type Queue = Array<[SaveConfig, SaveData]>

function useCroodsSaveQueue(
  save: Actions['save'],
  timeout = 300,
): AddToCroodsQueue {
  const queue = useRef<Queue>([])

  const addToQueue: AddToCroodsQueue = (opts) => (data) => {
    queue.current.push([opts, data])
  }

  const executeQueue = () => {
    Promise.allSettled(
      queue.current.map(([saveOpts, saveData]) => save(saveOpts)(saveData)),
    )
    queue.current = []
  }

  useInterval(executeQueue, timeout)

  return addToQueue
}

export { useCroodsSaveQueue }
