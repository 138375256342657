import React from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import { cx } from 'shared/helpers'

type Props = {
  label: string | JSX.Element
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  noMarginBottom?: boolean
}

const CheckInput: React.FC<Props> = ({
  onChange,
  checked,
  label,
  noMarginBottom,
  children,
}) => (
  <>
    {children && <Typography variant="subtitle1">{children}</Typography>}
    <FormControlLabel
      control={
        <Checkbox checked={checked} onChange={onChange} color="primary" />
      }
      label={label}
      className={cx(
        'text-gray-darkest last-of-type:mb-0',
        noMarginBottom || 'mb-8',
      )}
    />
  </>
)

export default CheckInput
