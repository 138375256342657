import type { Breadcrumb } from 'types'

const vibeBreadcrumbs: Record<string, Breadcrumb> = {
  home: {
    icon: 'vibe-active',
    module: true,
    path: '/',
    title: 'Vibe',
  },
  insights: {
    icon: 'lamp',
    path: '/insights',
    title: 'Insights',
  },
  teamInsights: {
    icon: 'lamp',
    path: '/insights/:teamId',
    title: 'Insights',
  },
  circles: {
    icon: 'people',
    path: '/circulos',
    title: 'Círculos',
  },
  collaborators: {
    icon: 'person',
    path: '/colaboradores',
    title: 'Colaboradores',
  },
  collaborator: {
    path: '/colaboradores/:userId',
    title: 'Colaborador',
  },
  inviteCollaborator: {
    icon: 'invitation',
    path: '/convidar',
    title: 'Convidar Novos Colaboradores',
  },
  teamProfile: {
    path: '/circulos/:teamId',
    title: 'Perfil do Círculo',
  },
  teamCreate: {
    icon: 'people',
    path: '/circulos/criar-novo-circulo',
    title: 'Criar Novo Círculo',
  },
  teamEdit: {
    icon: 'people',
    path: '/circulos/:teamId/editar-circulo',
    title: 'Edição de Círculo',
  },
}

const talksBreadcrumbs: Record<string, Breadcrumb> = {
  home: {
    icon: 'conversas-active',
    module: true,
    path: '/conversas',
    title: 'Conversas',
  },
  newConversation: {
    icon: 'baloon-plus',
    path: '/conversas/nova-conversa',
    title: 'Iniciar Conversa',
  },
  next: {
    icon: 'next',
    path: '/conversas/proximas-conversas',
    title: 'Próximas Conversas',
  },
  conversationHistory: {
    icon: 'history',
    path: '/conversas/historico-de-conversas',
    title: 'Histórico de Conversas',
  },
  myFeedbackList: {
    icon: 'my-feedback',
    path: '/conversas/meus-feedbacks',
    title: 'Meus Feedbacks',
  },
  conversationList: {
    path: '/conversas/historico-de-conversas/:teamMemberId',
    title: 'Histórico de Conversas',
  },
  member: {
    path: '/membro-do-time/:teamMemberId',
    title: 'Membro do Time',
  },
  NewOneOnOne: {
    path: '/conversas/nova-conversa/1-1/:teamMemberId',
    title: 'Nova Conversa 1:1',
  },
  editOneOnOne: {
    path: '/conversas/nova-conversa/1-1/:teamMemberId/:conversationId/editar',
    title: 'Editar Conversa 1:1',
  },
  newCareer: {
    path: '/conversas/nova-conversa/carreira/:teamMemberId',
    title: 'Nova Conversa de Carreira',
  },
  newBehavior: {
    path: '/conversas/nova-conversa/comportamento/:teamMemberId',
    title: 'Nova Conversa de Comportamento',
  },
  editBehavior: {
    path: '/conversas/nova-conversa/comportamento/:teamMemberId/:conversationId/editar',
    title: 'Editar Conversa de Comportamento',
  },
  onboard: {
    path: '/conversas/nova-conversa/onboarding/:teamMemberId',
    title: 'Onboarding',
  },
  newOnboard: {
    path: '/conversas/nova-conversa/onboarding/:teamMemberId',
    title: 'Nova Conversa de Onboarding',
  },
  editOnboard: {
    path: '/conversas/nova-conversa/onboarding/:teamMemberId/:conversationId/editar',
    title: 'Editar Conversa de Onboarding',
  },
  permanent: {
    path: '/conversas/nova-conversa/efetivacao/:teamMemberId',
    title: 'Efetivação',
  },
  newPermanent: {
    path: '/conversas/nova-conversa/efetivacao/:teamMemberId',
    title: 'Nova Conversa de Efetivação',
  },
  newUpwardEvaluation: {
    path: '/conversas/lider/:teamLeaderId/avaliacao-ascendente/novo',
    title: 'Nova Avaliação Ascendente',
  },
  editUpwardEvaluation: {
    path: '/conversas/lider/:teamLeaderId/avaliacao-ascendente/:upwardEvaluationId/editar',
    title: 'Editar Avaliação Ascendente',
  },
  newFeedback: {
    icon: 'rate-review',
    path: '/conversas/dar-feedback',
    title: 'Dar Feedback',
  },
}

const performanceBreadcrumbs: Record<string, Breadcrumb> = {
  home: {
    icon: 'performance-active',
    module: true,
    path: '/performance',
    title: 'Performance',
  },
  rate: {
    icon: 'dashboard',
    path: '/performance/avaliar-performance',
    title: 'Avaliar Performance',
  },
  talentRate: {
    icon: 'trophy',
    path: '/performance/avaliar-talentos',
    title: 'Avaliar Talentos',
  },
  teamEvaluation: {
    title: 'Avaliação do Círculo',
    path: '/performance/avaliacao-de-performance/:teamId',
  },
}

const portalBreadcrumbs: Record<string, Breadcrumb> = {
  home: {
    icon: 'portal-active',
    module: true,
    path: '/portal',
    title: 'Portal',
  },
  openSolicitation: {
    icon: 'plus',
    path: '/portal',
    title: 'Nova Solicitação',
  },
  newSolicitation: {
    icon: 'plus',
    path: '/portal/nova-solicitacao/:solicitationId',
    title: 'Abrir Solicitação',
  },
  pendingSolicitations: {
    icon: 'bell',
    path: '/portal/solicitacoes-pendentes',
    title: 'Solicitações Pendentes',
  },
  sentSolicitations: {
    icon: 'folder',
    path: '/portal/solicitacoes-enviadas',
    title: 'Solicitações Enviadas',
  },
  receivedSolicitations: {
    icon: 'inbox',
    path: '/portal/solicitacoes-recebidas',
    title: 'Solicitações Recebidas',
  },
  clockingPanel: {
    icon: 'alarm-add',
    path: '/portal/acerto-do-ponto',
    title: 'Acerto do Ponto',
  },
  clockingPanelSolicitation: {
    icon: 'alarm-add',
    path: '/portal/acerto-do-ponto/nova-solicitacao/:masterId',
    title: 'Acerto do Ponto',
  },
  myDocuments: {
    icon: 'file-text',
    path: '/portal/meus-documentos',
    title: 'Meus Documentos',
  },
  signatureDocuments: {
    icon: 'fingerprint',
    path: '/portal/assinatura-eletronica',
    title: 'Assinatura Eletrônica',
  },
  vacationManagement: {
    icon: 'vacations',
    path: '/portal/gestao-de-ferias',
    title: 'Gestão de Férias',
  },
  selectedVacationManagement: {
    icon: 'vacations',
    path: '/portal/gestao-de-ferias/:fullName/:startDate',
    title: 'Gestão de Férias',
  },
  myVacations: {
    icon: 'travel',
    path: '/portal/minhas-ferias',
    title: 'Minhas Férias',
  },
}

const settingsBreadcrumbs: Record<string, Breadcrumb> = {
  home: {
    icon: 'settings-active',
    module: true,
    path: '/configuracoes',
    title: 'Configurações',
  },
  users: {
    icon: 'people',
    path: '/configuracoes',
    title: 'Gestão de Colaboradores',
  },
  roles: {
    icon: 'assignmentindicon',
    path: '/configuracoes/papeis',
    title: 'Papéis',
  },
  cicles: {
    icon: 'calendar',
    path: '/configuracoes/ciclo',
    title: 'Ciclo',
  },
  solicitations: {
    icon: 'folder',
    path: '/configuracoes/modelos-de-solicitacao',
    title: 'Modelos de Solicitação',
  },
  solicitationsList: {
    icon: 'folder',
    path: '/configuracoes/modelos-de-solicitacao/novo',
    title: 'Novo Modelo de Solicitação',
  },
  solicitationsEdit: {
    icon: 'folder',
    path: '/configuracoes/modelos-de-solicitacao/:id/editar',
    title: 'Editar Modelo de Solicitação',
  },
  solicitationsManagement: {
    icon: 'assignment',
    path: '/configuracoes/gestao-de-solicitacoes',
    title: 'Gestão de Solicitações',
  },
  api: {
    icon: 'all-inclusive',
    path: '/configuracoes/api',
    title: 'API',
  },
  conversations: {
    icon: 'conversation',
    path: '/configuracoes/conversas',
    title: 'Conversas',
  },
  performance: {
    icon: 'performance',
    path: '/configuracoes/performance',
    title: 'Performance',
  },
  portal: {
    icon: 'portal',
    path: '/configuracoes/portal',
    title: 'Portal',
  },
  values: {
    icon: 'diamond',
    path: '/configuracoes/valores-organizacionais',
    title: 'Valores organizacionais',
  },
}

const routeBreadcrumbs = {
  vibe: vibeBreadcrumbs,
  talks: talksBreadcrumbs,
  performance: performanceBreadcrumbs,
  portal: portalBreadcrumbs,
  settings: settingsBreadcrumbs,
}

export { routeBreadcrumbs }
