import React, { useState } from 'react'

import { useCroods } from 'croods'
import Modal from 'shared/ui/Modal'
import { email as emailValidation } from 'shared/forms/validations'
import TagsInput from 'shared/forms/TagsInput'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import type { User } from 'types'

const isValidEmail = (email: string) =>
  Boolean(email && !emailValidation()(email))

export type InviteModalType = {
  onClose: () => void
  setList: (users: User[], isToUpdate: boolean) => void
  rows: User[]
}

const InviteModal = ({ onClose, setList, rows }: InviteModalType) => {
  const [emailChips, setEmailChips] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>('')

  const { snackbar } = useSnackbar()
  const [{ saving }, { save }] = useCroods({
    name: 'invitation',
    path: '/auth/invitation',
    afterSuccess: () => {
      snackbar({
        title: 'Sucesso',
        message: 'Usuários convidados com sucesso',
        type: 'success',
      })
    },
    afterFailure: (e) => {
      snackbar({
        title: 'Não foi possível convidar os usuários.',
        message: e?.response?.data?.message,
        type: 'error',
        timeout: 5,
      })
    },
  })

  const emails = isValidEmail(inputValue)
    ? [...emailChips, inputValue]
    : emailChips

  const handleConfirm = async () => {
    async function saveFunction() {
      const oldRows = rows
      const resultSave = await save({})({ emails })
      const updateObj = resultSave
        ? { rows: newUsersByEmails(emails), isToMergeList: true }
        : { rows: oldRows, isToMergeList: false }

      setList(updateObj.rows, updateObj.isToMergeList)

      return resultSave
    }

    return await saveFunction()
  }

  const newUsersByEmails = (emails: string[]): User[] => {
    const ret: User[] = []
    emails.forEach((email) =>
      ret.push({ ...newUserByEmail(email), id: ret.length }),
    )

    return ret
  }
  const newUserByEmail = (email: string): User => {
    return {
      id: 0,
      email,
      role: '',
      active: true,
      admin: false,
      dataAnalyst: false,
      primaryRoles: [],
      secondaryRoles: [],
      hierarchyPosition: 'teamless',
      hasAcquisitivePeriods: false,
      hasCredential: false,
      recentlyAdded: true,
    }
  }

  return (
    <Modal.Wrapper
      enabled={Boolean(emails.length)}
      loading={saving}
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      <Modal.Header>Convidar</Modal.Header>
      <Modal.Content>
        <TagsInput
          onTextChange={setInputValue}
          collection={emailChips}
          setCollection={setEmailChips}
          validate={isValidEmail}
          label="Convidar novos colaboradores por e-mail"
          name="emails"
          placeholder="nome@empresa.com"
          helperText="Adicione vários separando por vírgula"
        />
      </Modal.Content>
      <Modal.Actions>
        <Modal.Confirm>Salvar</Modal.Confirm>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default InviteModal
