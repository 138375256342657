type Props = {
  count: number
  total: number
}

export default function Tag({ count, total }: Props) {
  return (
    <div className="absolute left-[50%] -bottom-3 z-10 w-auto min-w-[2.5rem] translate-x-[-50%] rounded-b-lg rounded-t-lg bg-gray-lightest py-0.5 px-3 text-center sm:bottom-0 sm:rounded-b-none">
      <p className="font-bold tracking-tighter text-black/40">
        {count} / {total}
      </p>
    </div>
  )
}
