import * as React from 'react'
import * as z from 'zod'

import Modal from 'shared/ui/Modal'

const UndoModal = ({
  handleUndo,
  isOpen,
  loading,
  onClose,
}: {
  isOpen: boolean
  handleUndo: () => void
  loading: boolean
  onClose: () => void
}) => {
  return (
    <Modal.FormWrapper
      className="!min-w-[90vw] md:!min-w-[30vw] !max-w-min"
      loading={loading}
      onClose={onClose}
      open={isOpen}
      schema={z.object({})}
    >
      <Modal.Header className="!bg-gray-lightest">
        <p className="grid text-lg font-bold text-gray-darkest">
          Tem certeza que quer desfazer?
        </p>
      </Modal.Header>

      <Modal.Content className="text-base max-h-[60vh] !w-full overflow-y-auto !bg-white !bg-opacity-95 text-gray-darkest">
        Suas alterações nesse período serão descartadas e ele voltará ao estado
        original, conforme solicitado pelo colaborador. Deseja continuar?
      </Modal.Content>

      <Modal.Actions className="gap-4" cancelLabel="voltar">
        <button
          className="bt bt-contained w-[120px]"
          onClick={handleUndo}
          type="button"
        >
          Confirmar
        </button>
      </Modal.Actions>
    </Modal.FormWrapper>
  )
}

export { UndoModal }
