import React from 'react'

import compact from 'lodash/compact'

import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import Chip from 'shared/ui/chip'
import { parseQuestions } from 'portal/helpers'
import DocumentsList from 'portal/DocumentsList'
import SolicitationDetails from 'portal/clocking-panel/solicitation/SolicitationDetails'
import {
  cx,
  getUserIdentifier,
  formatDateUnitsAndTime,
  formatDateUnits,
  renderIf,
} from 'shared/helpers'

import { joinInputsHour } from 'portal/clocking-panel/helpers'

const SolicitationItem = ({ createdAt, requester, solicitation }: any) => (
  <section
    role="presentation"
    className="rounded-2xl bg-gray-lightest px-6 py-4 mb-4 text-base"
  >
    <Header
      title={<Title title="Solicitante" />}
      chipTitle={
        <Chip
          title="nome do solicitante"
          className="bg-gray-300 py-1 !text-gray-darkest"
        >
          {getUserIdentifier(requester)}
        </Chip>
      }
      dateTitle={<Title title="Abertura" />}
      chipDateTitle={
        <Chip
          title="data da solicitação"
          className="bg-gray-300 py-1 !text-gray-darkest"
        >
          {formatDateUnitsAndTime(createdAt)}
        </Chip>
      }
    />
    {solicitation.solicitationTemplate?.automationIdentifier === 'clocking' ? (
      <>
        <hr className="m-2" />
        <ClockingDetails solicitation={solicitation} />
      </>
    ) : (
      <DefautlDetails solicitation={solicitation} requester={requester} />
    )}
  </section>
)

const DefautlDetails = ({ solicitation, requester }: any) => (
  <>
    {renderIf(
      solicitation.documentsAttributes.length,
      <>
        <hr className="m-2" />
        <Title title="Arquivo(s) de Leitura" />
        <DocumentsList
          documents={solicitation.documentsAttributes}
          className="ml-2"
          isModelFile
        />
      </>,
    )}
    {renderIf(
      requester.questions.length,
      <>
        <hr className="m-2" />
        <ul>
          {requester.questions.map(
            ({
              id: questionId,
              question,
              answer,
              inputType,
              required,
            }: any) => (
              <QuestionAndAnswer
                key={questionId}
                ariaQuestion="pergunta"
                ariaAnswer="resposta"
                question={question}
                answer={answer}
                type={inputType}
                id={questionId}
                required={required}
              />
            ),
          )}
        </ul>
      </>,
    )}
    {renderIf(
      solicitation.approvers.length,
      <>
        <hr className="mx-2 my-6" />
        {solicitation.approvers.map(
          (
            {
              user,
              approverLevel,
              createdAt: solCreatedAt,
              questionsFilled,
            }: any,
            index: number,
          ) => (
            <React.Fragment key={`${index}-${solCreatedAt}`}>
              <Title title={`Aprovação #${approverLevel}`} />
              <Header
                chipTitle={
                  <Chip
                    title="nome do aprovador"
                    className="bg-gray-300 py-1 !text-gray-darkest"
                  >
                    {getUserIdentifier({ user })}
                  </Chip>
                }
                chipDateTitle={
                  <Chip
                    title="data de aprovação"
                    className="bg-gray-300 py-1 !text-gray-darkest"
                  >
                    {formatDateUnitsAndTime(solCreatedAt)}
                  </Chip>
                }
              />
              <ul className="mb-8 ml-4 last:mb-0">
                {questionsFilled.map(
                  ({
                    id: questionId,
                    question,
                    answer,
                    inputType,
                    required,
                  }: any) => (
                    <QuestionAndAnswer
                      key={questionId}
                      ariaQuestion="pergunta do aprovador"
                      ariaAnswer="resposta do aprovador"
                      question={question}
                      answer={answer}
                      type={inputType}
                      id={questionId}
                      required={required}
                    />
                  ),
                )}
              </ul>
            </React.Fragment>
          ),
        )}
      </>,
    )}
  </>
)

const ClockingDetails = ({ solicitation }: any) => {
  const date = formatDateUnits(solicitation?.prefixedValues?.masterDate)
  const timePerformed = compact(solicitation?.prefixedValues?.moments)
    .map((moment) => format(parseISO(String(moment).replace('Z', '')), 'HH:mm'))
    .join(' | ')

  const addedTimes = joinInputsHour(solicitation.fixedFieldsAnswers.timeInputs)

  return (
    <SolicitationDetails
      date={date}
      id={solicitation.id}
      reason={solicitation.fixedFieldsAnswers?.reasonText}
      observation={solicitation.fixedFieldsAnswers?.observation}
      estimatedTime={solicitation.prefixedValues?.defaultMoments}
      addedTimes={addedTimes || 'Nenhum horário adicionado'}
      timePerformed={timePerformed}
    />
  )
}

const Header = ({ title, chipTitle, dateTitle, chipDateTitle }: any) => (
  <div className="flex flex-col gap-4 sm:flex-row mb-4">
    <ul className="">
      {title && <li>{title}</li>}
      <li>{chipTitle}</li>
    </ul>
    <ul className="">
      {dateTitle && <li>{dateTitle}</li>}
      <li>{chipDateTitle}</li>
    </ul>
  </div>
)

const Title = ({
  className,
  title,
  content,
}: {
  className?: string
  title: string
  content?: string
}) => (
  <h5
    title={title}
    className={cx(
      'mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-base font-bold text-current',
      className,
    )}
  >
    {content || title}
  </h5>
)

type QnAType = {
  id: number
  ariaQuestion: string
  ariaAnswer: string
  question: string
  answer: File[] | string
  type: string
  required: boolean
}

const QuestionAndAnswer = ({
  id,
  ariaQuestion,
  ariaAnswer,
  question,
  answer,
  type,
  required,
}: QnAType) => {
  const { answer: parsedAnswer, inputType } = parseQuestions([
    { id, question, answer, inputType: type, required },
  ])[0]

  const documents = typeof parsedAnswer !== 'string' ? parsedAnswer : []

  return (
    <li className="mb-4 list-none text-gray-darkest">
      <Title className="mb-2" title={ariaQuestion} content={question} />
      {inputType === 'upload' ? (
        <DocumentsList documents={documents} className="ml-1" />
      ) : (
        <p title={ariaAnswer} className="ml-4 whitespace-pre-wrap text-base">
          {parsedAnswer}
        </p>
      )}
    </li>
  )
}

export default SolicitationItem
