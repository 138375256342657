import React from 'react'
import Container from '@material-ui/core/Container'

const Content = ({ children }: { children: React.ReactNode }) => (
  <Container>
    <div className="mb-4">{children}</div>
  </Container>
)

export default Content
