import { useFormContext } from 'react-hook-form'
import { useCroods } from 'croods'
import { useNavigate } from 'react-router-dom'

import DynamicInputs, { buildSchema } from 'portal/dynamic-input'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import Actions from 'shared/ui/form-step/actions'
import DynamicForm from 'shared/formsv2/form'

import type { DynamicInput } from 'portal/openSolicitation/new/types'

type Props = {
  inputs?: Array<DynamicInput>
  solicitationTemplateId: number
}

const Form = ({ inputs = [], solicitationTemplateId }: Props) => {
  const applicantInputs = inputs.filter(({ step }) => step === 'applicant')
  const [, { fetch }] = useCroods({
    name: 'solicitations',
    path: 'workflow/solicitations',
  })
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const [{ saveError }, { save }] = useCroods({
    name: 'solicitation',
    path: '/workflow/solicitations',
    afterSuccess: () => {
      snackbar({ message: 'Solicitação feita com sucesso.', type: 'success' })
      navigate('/portal')
      fetch({ cache: false })({ query: 'pending' })
    },
  })

  async function handleSubmit(data: Record<string, unknown>) {
    const answersAttributes = Object.entries(data).map(([inputId, value]) => ({
      inputId,
      value,
    }))

    return save()({ answersAttributes, solicitationTemplateId })
  }

  return (
    <DynamicForm
      schema={buildSchema(inputs)}
      mode="onChange"
      onSubmit={handleSubmit}
      className="flex flex-col gap-4 p-4"
    >
      <DynamicInputs inputs={applicantInputs} />
      {saveError && (
        <div>
          <FeedbackMessage
            message="Houve um erro ao processar a solicitação"
            serverMessage={
              saveError === 'not allowed to create? this Solicitation'
                ? 'Você não tem permissão para abrir este modelo de solicitação.'
                : saveError
            }
          />
        </div>
      )}
      <FormActions />
    </DynamicForm>
  )
}

function FormActions() {
  const { formState } = useFormContext()
  return (
    <Actions
      disabled={!formState.isValid}
      backButtonLabel="Voltar"
      nextButtonLabel="Enviar"
      loading={formState.isSubmitting}
    />
  )
}

export default Form
