import { makeListInput } from '@remaster-run/cruds'
import { collect, makeDomainFunction, map } from 'domain-functions'
import { z } from 'zod'
import { userEnvSchema } from './auth'
import { api } from 'api'
import { getFullName } from 'shared/helpers'
import { parseDateTime } from 'portal/helpers'
import parseISO from 'date-fns/parseISO'
import isNull from 'lodash/isNull'
import omitBy from 'lodash/omitBy'
import type { Dictionary } from 'lodash'

const filteredSolicitationFiltersSchema = z.object({
  createdFrom: z.string().optional(),
  createdUntil: z.string().optional(),
  solicitationTemplateId: z.string().optional(),
  userId: z.string().optional(),
  status: z.array(z.string()).optional(),
  solicitationIdFilter: z.string().optional(),
  order: z.enum(['asc', 'desc']).default('desc'),
})

const solicitationFiltersFormSchema = filteredSolicitationFiltersSchema.refine(
  ({ createdFrom, createdUntil }) => {
    if (createdFrom && createdUntil) {
      return new Date(createdFrom) <= new Date(createdUntil)
    }
    return true
  },
  {
    message: 'Não pode ser antes da data inicial',
    path: ['createdUntil'],
  },
)

const solicitationStatusSchema = z.enum([
  'pending_approval',
  'pending_execution',
  'completed',
  'rejected',
  'canceled',
])

const STATUS_LABELS = {
  pending_approval: 'Aprovação pendente',
  pending_execution: 'Execução pendente',
  completed: 'Executada',
  rejected: 'Reprovada',
  canceled: 'Cancelada',
} as const

const fetchFilteredSolicitations = makeDomainFunction(
  makeListInput({ defaultPerPage: 50 }).merge(
    filteredSolicitationFiltersSchema,
  ),
  userEnvSchema,
)(
  async ({
    page,
    perPage,
    order,
    createdFrom,
    createdUntil,
    solicitationTemplateId,
    userId,
    solicitationIdFilter,
    status,
  }) => {
    const response = await api.get('/supervisor/solicitations', {
      query: omitBy(
        {
          page: String(page),
          perPage: String(perPage),
          order,
          createdFrom: createdFrom ? createdFrom : null,
          createdUntil: createdUntil ? createdUntil : null,
          solicitationTemplateId: solicitationTemplateId
            ? String(solicitationTemplateId)
            : null,
          userId: userId ? String(userId) : null,
          solicitationId: solicitationIdFilter
            ? String(solicitationIdFilter)
            : null,
          status: status ? status : null,
        },
        isNull,
      ) as Dictionary<string>,
    })

    const data = await response.json(
      z.object({
        items: z.array(
          z.object({
            id: z.number(),
            status: solicitationStatusSchema,
            createdAt: z.preprocess((val) => parseISO(String(val)), z.date()),
            solicitationTemplateName: z.string(),
            requester: z.object({
              id: z.number(),
              firstName: z.string().nullable(),
              lastName: z.string().nullable(),
              email: z.string(),
            }),
            abilities: z.object({
              cancel: z.boolean(),
            }),
          }),
        ),
        page: z.number(),
        perPage: z.number(),
        total: z.number(),
      }),
    )

    const templateResponse = await api.get(
      '/supervisor/solicitations/published_solicitation_templates',
      {},
    )

    const templateData = await templateResponse.json(
      z.array(
        z.object({
          id: z.number(),
          name: z.string(),
        }),
      ),
    )

    return {
      ...data,
      items: data.items.map((item) => ({
        id: item.id,
        status: STATUS_LABELS[item.status],
        createdAt: parseDateTime(item.createdAt),
        solicitationTemplateName: item.solicitationTemplateName,
        requesterFullName: item.requester ? getFullName(item.requester) : '',
        canCancel: item.abilities.cancel,
      })),
      templateOptions: templateData.map((template) => ({
        value: template.id,
        name: template.name,
      })),
    }
  },
)

const receivedSolicitationsSearchSchema = z.object({
  search: z.string().optional(),
})

const sentSolicitationsSearchSchema = z.object({
  search: z.string().optional(),
})

const makeFetchReceivedSolicitations = (path: string) =>
  makeDomainFunction(
    makeListInput({ defaultPerPage: 50 }).merge(
      receivedSolicitationsSearchSchema,
    ),
    userEnvSchema,
  )(async ({ page, perPage, search }) => {
    const query = { page: String(page), perPage: String(perPage) }

    const response = await (search
      ? api.get(path, { query: { ...query, search } })
      : api.get(path, { query }))

    const data = await response.json(
      z.object({
        items: z.array(
          z.object({
            id: z.number(),
            status: solicitationStatusSchema,
            createdAt: z.preprocess((val) => parseISO(String(val)), z.date()),
            solicitationTemplateName: z.string(),
            requesterFullName: z.string().nullable(),
            applicantFullName: z.string(),
          }),
        ),
        page: z.number(),
        perPage: z.number(),
        total: z.number(),
      }),
    )

    return {
      ...data,
      items: data.items.map((item) => ({
        ...item,
        status: STATUS_LABELS[item.status],
        createdAt: parseDateTime(item.createdAt),
      })),
    }
  })

const fetchReceivedApprovals = makeFetchReceivedSolicitations(
  '/workflow/solicitations/received_approvals',
)

const fetchReceivedExecutions = makeFetchReceivedSolicitations(
  '/workflow/solicitations/received_executions',
)

const fetchSentByMeForMyself = makeDomainFunction(
  makeListInput({ defaultPerPage: 50 }).merge(
    receivedSolicitationsSearchSchema,
  ),
  userEnvSchema,
)(async ({ page, perPage, search }) => {
  const query = { page: String(page), perPage: String(perPage) }

  const response = await (search
    ? api.get('/workflow/solicitations/sent_by_me_for_myself', {
        query: { ...query, search },
      })
    : api.get('/workflow/solicitations/sent_by_me_for_myself', { query }))

  const data = await response.json(
    z.object({
      items: z.array(
        z.object({
          id: z.number(),
          status: solicitationStatusSchema,
          createdAt: z.preprocess((val) => parseISO(String(val)), z.date()),
          solicitationTemplateName: z.string(),
        }),
      ),
      page: z.number(),
      perPage: z.number(),
      total: z.number(),
    }),
  )

  return {
    ...data,
    items: data.items.map((item) => ({
      ...item,
      status: STATUS_LABELS[item.status],
      createdAt: parseDateTime(item.createdAt),
    })),
  }
})

const fetchSentByMeForOthers = makeDomainFunction(
  makeListInput({ defaultPerPage: 50 }).merge(
    receivedSolicitationsSearchSchema,
  ),
  userEnvSchema,
)(async ({ page, perPage, search }) => {
  const query = { page: String(page), perPage: String(perPage) }

  const response = await (search
    ? api.get('/workflow/solicitations/sent_by_me_for_others', {
        query: { ...query, search },
      })
    : api.get('/workflow/solicitations/sent_by_me_for_others', { query }))

  const data = await response.json(
    z.object({
      items: z.array(
        z.object({
          id: z.number(),
          status: solicitationStatusSchema,
          createdAt: z.preprocess((val) => parseISO(String(val)), z.date()),
          solicitationTemplateName: z.string(),
          requesterFullName: z.string(),
        }),
      ),
      page: z.number(),
      perPage: z.number(),
      total: z.number(),
    }),
  )

  return {
    ...data,
    items: data.items.map((item) => ({
      ...item,
      status: STATUS_LABELS[item.status],
      createdAt: parseDateTime(item.createdAt),
    })),
  }
})

const fetchSolicitationHistory = makeDomainFunction(
  z.object({ solicitationId: z.preprocess((val) => String(val), z.string()) }),
  userEnvSchema,
)(async ({ solicitationId }) => {
  const response = await api.get(
    '/workflow/solicitations/:solicitationId/history',
    {
      params: { solicitationId },
    },
  )

  const items = await response.json(
    z.array(
      z.object({
        label: z.string(),
        details: z.object({
          user: z.string(),
          createdAt: z.preprocess((val) => parseISO(String(val)), z.date()),
          inputs: z
            .array(
              z.object({
                type: z.enum([
                  'short_text',
                  'long_text',
                  'date_input',
                  'hour_input',
                  'number_input',
                  'led_search',
                  'upload',
                  'option_list',
                ]),
                question: z.string(),
                answer: z.any().nullable(),
              }),
            )
            .optional()
            .default([]),
          justification: z.string().optional(),
        }),
      }),
    ),
  )

  return items.map((i) => ({
    ...i,
    details: {
      ...i.details,
      createdAt: parseDateTime(i.details.createdAt),
    },
  }))
})

const fetchSolicitationDetailsNoHistory = makeDomainFunction(
  z.object({ solicitationId: z.preprocess((val) => String(val), z.string()) }),
  userEnvSchema,
)(async ({ solicitationId }) => {
  const response = await api.get(
    '/workflow/solicitations/:solicitationId/details',
    {
      params: { solicitationId },
    },
  )

  const item = await response.json(
    z.object({
      id: z.number(),
      createdAt: z.preprocess((val) => parseISO(String(val)), z.date()),
      hasUnfilledRequiredFields: z.boolean(),
      status: solicitationStatusSchema,
      abilities: z.object({
        cancel: z.boolean(),
      }),
      applicant: z.object({
        firstName: z.string().nullable(),
        lastName: z.string().nullable(),
        email: z.string(),
        questions: z.array(
          z.object({
            id: z.number(),
            answer: z.unknown(),
            inputType: z.string(),
            question: z.string(),
            required: z.boolean(),
          }),
        ),
      }),
      executor: z
        .object({
          id: z.number().nullable(),
          alias: z.string(),
          executedAt: z.preprocess((val) => parseISO(String(val)), z.date()),
        })
        .nullable(),
      fixedFieldsAnswers: z.unknown(),
      pendingFor: z
        .object({
          approveLevel: z.number().nullable(),
          pendingSince: z.preprocess((val) => parseISO(String(val)), z.date()),
          approvers: z
            .array(
              z.object({
                kind: z.enum(['team', 'user']),
                id: z.number(),
                label: z.string(),
                warning: z.string().nullable(),
              }),
            )
            .optional(),
          executors: z
            .array(
              z.object({
                kind: z.enum(['integrator', 'team', 'user']),
                id: z.number(),
                label: z.string(),
                warning: z.string().nullable(),
              }),
            )
            .optional(),
        })
        .nullable(),
      prefixedValues: z.unknown(),
      requester: z.object({
        id: z.number(),
        firstName: z.string().nullable(),
        lastName: z.string().nullable(),
        email: z.string(),
      }),
      solicitationsApprovals: z.array(
        z.object({
          id: z.number(),
          approver: z.object({
            id: z.number().nullable(),
            alias: z.string(),
          }),
          justification: z.string().nullable(),
          createdAt: z.preprocess((val) => parseISO(String(val)), z.date()),
          status: z.string(),
        }),
      ),
      solicitationCancelation: z
        .object({
          user: z.object({
            id: z.number(),
            alias: z.string(),
          }),
          createdAt: z.preprocess((val) => parseISO(String(val)), z.date()),
          justification: z.string().nullable(),
        })
        .nullable(),
      solicitationTemplate: z.object({
        automationIdentifier: z.string().nullable(),
        name: z.string(),
        inputs: z
          .array(
            z.object({
              id: z.number(),
              inputName: z.string(),
              required: z.boolean(),
            }),
          )
          .optional(),
      }),
    }),
  )

  return {
    item: {
      ...item,
      requester: {
        ...item.requester,
        fullName: item.requester ? getFullName(item.requester) : '',
      },
    },
  }
})

const fetchSolicitationDetails = map(
  collect({
    details: fetchSolicitationDetailsNoHistory,
    history: fetchSolicitationHistory,
  }),
  ({ details, history }) => {
    return { ...details, item: { ...details.item, history: history } }
  },
)

const fetchSelectedSolicitations = makeDomainFunction(
  z.object({ selected: z.array(z.string()).optional() }),
  userEnvSchema,
)(async ({ selected }) => {
  return {
    item: {
      solicitationsIds: selected,
    },
  }
})

const cancelSolicitationSchema = z.object({
  justification: z.string().optional(),
})

const cancelSolicitation = makeDomainFunction(
  cancelSolicitationSchema.extend({ solicitationId: z.string() }),
  userEnvSchema,
)(async ({ solicitationId, ...params }) => {
  const response = await api.put(
    '/workflow/solicitations/:solicitationId/cancel',
    {
      params: { solicitationId },
      body: params,
    },
  )

  return response.json(z.object({ id: z.number() }))
})

const bulkCancelSolicitationsSchema = z.object({
  solicitationsIds: z.array(z.string()),
  justification: z.string().optional(),
})

const bulkCancelSolicitations = makeDomainFunction(
  bulkCancelSolicitationsSchema,
  userEnvSchema,
)(async ({ justification, solicitationsIds }) => {
  const response = await api.post('/supervisor/solicitations/bulk_cancel', {
    body: justification
      ? {
          solicitationsIds: solicitationsIds.map(Number),
          justification: justification,
        }
      : {
          solicitationsIds: solicitationsIds.map(Number),
        },
  })

  return response.json(z.array(z.object({})))
})

export {
  bulkCancelSolicitations,
  bulkCancelSolicitationsSchema,
  cancelSolicitation,
  cancelSolicitationSchema,
  fetchFilteredSolicitations,
  fetchReceivedApprovals,
  fetchReceivedExecutions,
  fetchSelectedSolicitations,
  fetchSolicitationDetails,
  fetchSolicitationHistory,
  filteredSolicitationFiltersSchema,
  solicitationFiltersFormSchema,
  receivedSolicitationsSearchSchema,
  solicitationStatusSchema,
  sentSolicitationsSearchSchema,
  fetchSentByMeForMyself,
  fetchSentByMeForOthers,
  STATUS_LABELS,
}
