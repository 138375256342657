import * as React from 'react'

const PerformanceIconOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="-1 -2 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.83333 0.166748C3.28104 0.166748 2.83333 0.614463 2.83333 1.16675V1.41675H1.75C1.19772 1.41675 0.75 1.86446 0.75 2.41675V4.08341C0.75 5.76956 1.94233 7.17722 3.52985 7.50925C4.21636 8.69042 5.3576 9.57459 6.71386 9.92206C6.41264 10.6829 5.95791 11.378 5.37392 11.962L5.20957 12.1264C4.92357 12.4124 4.83801 12.8425 4.99279 13.2162C5.14757 13.5899 5.51221 13.8335 5.91667 13.8335H10.0833C10.4878 13.8335 10.8524 13.5899 11.0072 13.2162C11.162 12.8425 11.0764 12.4124 10.7904 12.1264L10.6261 11.962C10.0421 11.378 9.58737 10.6829 9.28615 9.92206C10.6424 9.57459 11.7836 8.69041 12.4701 7.50925C14.0577 7.17722 15.25 5.76956 15.25 4.08341V2.41675C15.25 1.86446 14.8023 1.41675 14.25 1.41675H13.1667V1.16675C13.1667 0.614463 12.7189 0.166748 12.1667 0.166748H3.83333ZM13.25 4.08341C13.25 4.25659 13.2207 4.42291 13.1667 4.57768V3.41675H13.25V4.08341ZM2.83333 4.57765V3.41675H2.75V4.08341C2.75 4.25658 2.77934 4.42289 2.83333 4.57765ZM4.83333 4.91675V2.16675H11.1667V4.91675C11.1667 6.66565 9.7489 8.08341 8 8.08341C6.25109 8.08341 4.83333 6.66565 4.83333 4.91675ZM8.00708 11.8335H7.99294L8.00001 11.8217L8.00708 11.8335Z"
      fill="currentColor"
    />
  </svg>
)

export default PerformanceIconOutline
