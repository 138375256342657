import React from 'react'
import { useCroods } from 'croods'
import { useCurrentUser } from 'croods-auth'

import Skeleton from 'shared/ui/skeleton'
import FeedbackMessage from 'shared/ui/FeedbackMessage'

import Card from './Card'
import EvaluatedTeam from './EvaluatedTeam'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

const TeamEvaluationNotice = () => {
  const [{ currentUser }] = useCurrentUser()
  const [{ info, fetchingInfo }] = useCroods({
    name: 'users',
    id: currentUser.id,
    fetchOnMount: true,
  })

  const [
    {
      list: teamsToEvaluate,
      fetchingList: loadingTeamsToEvaluate,
      listError: errorTeamsToEvaluate,
    },
    { fetch: fetchTeamsToEvaluate },
  ] = useCroods({
    name: 'teamToEvaluate',
    path: 'team/to_evaluate',
  })

  const [
    {
      list: teamsEvaluated,
      fetchingList: loadingTeamsEvaluated,
      listError: errorTeamsEvaluated,
    },
  ] = useCroods({
    name: 'teamEvaluated',
    path: 'team/evaluated',
    fetchOnMount: true,
  })

  const isLeader = Boolean(
    info?.teamLeaders?.filter((leader: any) => leader.teamStatus === 'active')
      .length,
  )

  React.useEffect(() => {
    if (isLeader) fetchTeamsToEvaluate()()
  }, [isLeader]) // eslint-disable-line

  return (
    <div>
      <h3 className="mb-4">Performance</h3>

      {fetchingInfo ? (
        <Skeleton height="100" />
      ) : (
        <div className="flex flex-col gap-4">
          {isLeader && (
            <LayoutList
              error={errorTeamsToEvaluate}
              loading={loadingTeamsToEvaluate}
            >
              {map(teamsToEvaluate, (team) => (
                <Card key={`team-card-${team.id}`} team={team} />
              ))}
            </LayoutList>
          )}
          <LayoutList
            error={errorTeamsEvaluated}
            loading={loadingTeamsEvaluated}
          >
            <div className="flex flex-col gap-4">
              {map(teamsEvaluated, (team) => (
                <EvaluatedTeam
                  key={`team-evaluated-${team.teamName}-${team.teamDenomination}`}
                  team={team}
                  showEditButton={true}
                />
              ))}
            </div>
          </LayoutList>

          {!loadingTeamsToEvaluate &&
            !loadingTeamsEvaluated &&
            isEmpty(teamsToEvaluate) &&
            isEmpty(teamsEvaluated) && (
              <FeedbackMessage
                type="warning"
                message="Nenhuma avaliação de performance"
                serverMessage=""
              />
            )}
        </div>
      )}
    </div>
  )
}

const LayoutList = ({
  children,
  error,
  loading,
}: {
  children: React.ReactNode
  error: string | null
  loading: boolean
}) => {
  if (error) return <FeedbackMessage />

  if (loading) return <Skeleton height="100" />

  return children as JSX.Element
}

export default TeamEvaluationNotice
