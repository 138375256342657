import React, { useContext } from 'react'
import type { Error } from './Wrapper'

type ContextType = {
  addErrors: (id: number, error: { [index: string]: string }) => void
  removeErrors: (id: number) => void
  errors: Error[]
}

const initialValues = {} as ContextType

const TableContext = React.createContext<ContextType>(initialValues)
TableContext.displayName = 'TableContext'

export default TableContext

export const useTableContext = () => useContext(TableContext)
