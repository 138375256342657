import { useEffect } from 'react'
import { useFormState } from 'react-use-form-state'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { Fetch } from 'croods'
import { Skeleton } from '@material-ui/lab'
import type { TeamMemberType, User } from 'types'

import { useLocation } from 'react-router-dom'
import { scrollTo } from 'shared/helpers'
import ListItem from '../ListItem'

import { getUserIdentifier, formatDateUnits } from 'shared/helpers'
import { Link } from 'react-router-dom'
import Avatar from 'shared/ui/Avatar'
import Chip from 'shared/ui/chip'

import Select from 'shared/forms/Select'

const defaultOptions = [
  {
    label: '1:1',
    value: 'one_on_one',
  },
  {
    label: 'Comportamento',
    value: 'behavior',
  },
  {
    label: 'Carreira',
    value: 'career',
  },
  {
    label: 'Onboarding',
    value: 'onboarding',
  },
  {
    label: 'Efetivação',
    value: 'permanent',
  },
  {
    label: 'Avaliação Ascendente',
    value: 'upward_evaluation',
  },
]

type Props = {
  teamMemberId: string
  list: { id: string; conversationType: unknown }[]
  isEditMode: boolean
  type: TeamMemberType
}

type MemberOrLeaderInfo = {
  user: User
  teamId: number
  teamName: string
}

const ListAll = ({ teamMemberId, list, isEditMode, type }: Props) => {
  const [formState, fields] = useFormState({ filter: 'all' })

  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      scrollTo(hash.replace('#', ''))
    }
  }, [hash])

  const {
    values: { filter: filterValue },
  } = formState
  const conversations =
    filterValue === 'all'
      ? list
      : filter(list, { conversationType: filterValue })

  const filterOptions = map(list, 'conversationType')

  const options = [
    {
      label: 'Todas conversas',
      value: 'all',
    },
    ...filter(defaultOptions, (item) => filterOptions.includes(item.value)),
  ]

  return (
    <>
      <div className="mb-6 flex justify-between">
        <h3>Conversas Anteriores</h3>
        {Boolean(filter) && (
          <Select
            {...fields.text({ name: 'filter', validate: () => undefined })}
            value={filterValue as string}
            fullWidth={false}
            options={options}
            variant="outlined"
          />
        )}
      </div>

      <Fetch<MemberOrLeaderInfo>
        name="user-card"
        customPath={`team_${type}/${teamMemberId}`}
        render={(info) => {
          if (info.user) {
            const team = {
              id: info.teamId,
              name: info.teamName,
            }
            const { user } = info

            return (
              <div className="card flex">
                <div className="flex max-w-md border-r border-gray/40">
                  <div className="radial-gradient flex items-center justify-center p-4">
                    <Link to={`/colaboradores/${user.id}`}>
                      <Avatar user={user} size={40} />
                    </Link>
                  </div>
                  <div className="flex flex-col min-w-[150px] md:min-w-[200px] justify-center p-4">
                    <div>
                      <h6 className="bold text-base">
                        {getUserIdentifier({ user }, user.email)}
                      </h6>
                      {user.role && (
                        <p className="text-base text-gray-darker">
                          {user.role}
                        </p>
                      )}
                    </div>
                    {user.admissionDate && (
                      <div className="flex flex-col justify-center">
                        <strong className="mb-0.5 tracking-wide text-gray-dark mt-1 text-sm">
                          Data de admissão
                        </strong>
                        <Chip className="items-start self-start !bg-gray-200">
                          {formatDateUnits(user.admissionDate)}
                        </Chip>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex w-full flex-col justify-center">
                  <div className="p-2">
                    <p className="body2 text-gray-dark">
                      Seu {type === 'members' ? 'liderado' : 'líder'} em
                    </p>
                    <p className="">{team?.name}</p>
                  </div>
                </div>
              </div>
            )
          }
          return null
        }}
        renderLoading={() => (
          <Skeleton height={64} width="100%" style={{ borderRadius: 16 }} />
        )}
      />

      <div className="mt-6 flex flex-col gap-4">
        {conversations.map((conversation, index: number) => (
          <ListItem
            key={index + conversation.id}
            conversation={conversation}
            teamMemberId={teamMemberId!}
            isEditMode={
              isEditMode &&
              conversation.conversationType !== 'permanent' &&
              conversation.conversationType !== 'upward_evaluation'
            }
          />
        ))}
      </div>
    </>
  )
}

export default ListAll
