import { useState } from 'react'

const useArray = (initialArray: any[]) => {
  const [array, setArray] = useState(initialArray)

  const push = (newItem: any) => {
    setArray((oldArray) => [...oldArray, newItem])
  }

  const splice = (index: number, ...newItems: any[]) => {
    setArray((oldArray) => {
      const newArray = [...oldArray]
      newArray.splice(index, 1, ...newItems)
      return newArray
    })
  }

  return {
    array,
    push,
    splice,
  }
}

export default useArray
