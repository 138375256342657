import React from 'react'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import Typography from '@material-ui/core/Typography'
import ApprovalLevel from './ApprovalLevel'

const Approvals = ({ levels }: any) => {
  if (isEmpty(levels))
    return (
      <Typography variant="h4" align="center">
        Não há níveis de aprovação
      </Typography>
    )

  const visibleLevels = filter(levels, (level) => !isEmpty(level.approvers))

  return (
    <>
      {map(visibleLevels, (level, index) => (
        <ApprovalLevel key={index} index={index} {...level} />
      ))}
    </>
  )
}

export default Approvals
