import * as React from 'react'
import { cx } from 'shared/helpers'
import Icon from 'shared/ui/icon'
import { useExpandable } from './expandable-context'
import Td from './td'
import Tr from './tr'

type Props = JSX.IntrinsicElements['tr'] & {
  uniqueKey: number
  innerContent: React.ReactNode
}

const TrExpandable = ({
  uniqueKey,
  innerContent,
  children,
  className,
  onClick,
  ...props
}: Props) => {
  const { openKeys, toggleKey } = useExpandable()

  const isOpen = openKeys.includes(uniqueKey)

  return (
    <>
      <Tr {...props} className={className} onClick={onClick}>
        {children}
        <Td className="text-right">
          <button
            aria-label={isOpen ? 'Fechar linha' : 'Expandir linha'}
            aria-expanded={isOpen}
            type="button"
            onClick={(event) => {
              event.stopPropagation()
              toggleKey(uniqueKey)
            }}
            className={cx(
              'rounded-full !bg-blue-lightest !transition-all hover:!bg-blue',
              isOpen && '-rotate-180 !bg-white',
            )}
          >
            <Icon icon="arrow-down" className="text-blue hover:text-white" />
          </button>
        </Td>
      </Tr>

      {isOpen && <tr />}
      {isOpen && (
        <Tr {...props} className={className}>
          <Td colSpan={React.Children.count(children) + 1}>{innerContent}</Td>
        </Tr>
      )}
    </>
  )
}

export default TrExpandable
