import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'shared/utils/useForm'
import Step from 'shared/ui/form-step'
import AddParentTeam from './add-parent-team'
import {
  parseDisabledOptions,
  setNameAndDenomination,
} from '../parseTeamsAndUsers'

type Props = {
  handleSubmit: (formValues: object) => void
  submitting?: boolean
  error: string | null
  visible: boolean
  values: any
  createdTeamId?: any
  filledValues: any
  handleNavigate: Function
}

const Form = ({
  handleSubmit,
  submitting = false,
  error,
  visible,
  values,
  filledValues,
  handleNavigate,
  createdTeamId,
}: Props) => {
  const nameDenomination = setNameAndDenomination(values)
  const { formState, fields, hasErrors } = useForm(
    {
      parentTeamId: filledValues?.parentTeamId || [],
      childTeamsIds: filledValues?.childTeamsIds || [],
    },
    {},
  )
  const selectedOptions = {
    createdTeamId,
    parent: formState.values.parentTeamId,
    childs: formState.values.childTeamsIds,
  }

  const [disabledOptions, setDisabledOptions] = useState(() =>
    parseDisabledOptions(selectedOptions),
  )

  const onSubmit: React.FormEventHandler = (e) => {
    e && e.preventDefault && e.preventDefault()
    handleSubmit && handleSubmit(formState.values)
  }

  const { current: parentTeamId } = useRef(formState.values.parentTeamId)
  const { current: childTeamsIds } = useRef(formState.values.childTeamsIds)

  useEffect(() => {
    setDisabledOptions(parseDisabledOptions(selectedOptions))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentTeamId, childTeamsIds])

  return (
    <Step
      handleSubmit={onSubmit}
      error={error}
      disableButton={hasErrors}
      submitting={submitting}
      backButtonLabel="Voltar"
      visible={visible}
      handleNavigate={handleNavigate(0)}
    >
      <section className="mb-8 flex flex-col">
        <AddParentTeam
          {...fields('raw', 'parentTeamId', [], {
            touchOnChange: true,
          })}
          step="relations"
          title={`${nameDenomination} está dentro de um círculo?`}
          optionalText="Opcional"
          subtitle={`Ao adicionar um círculo acima de ${nameDenomination}, você estará autorizando acesso aos dados de Insights ao líder do novo círculo e todos os líderes acima dele.`}
          placeholder="Busque pelo nome do círculo"
          path="/team/quick-list"
          parserType="team"
          isSolo
          showLeaderTag
          label="Adicionar círculo"
          selectedOptions={disabledOptions}
          debounceTime={600}
        />
      </section>
    </Step>
  )
}

export default Form
