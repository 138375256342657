import * as React from 'react'
import { useCroods } from 'croods'
import Section from './section'
import InfoTooltip from 'shared/ui/Tooltip/InfoTooltip'
import { chartPath } from 'vibe/insights/chart-helpers'
import type { InsightComponentProps } from 'vibe/insights/chart-helpers'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import Skeleton from 'shared/ui/skeleton'
import { match, P } from 'ts-pattern'
import Period from 'shared/ui/Period'
import useOrganization from 'shared/organization/useOrganization'

const TotalConversationsTooltipContent = ({
  isOnlyThisCirclePage,
}: {
  isOnlyThisCirclePage: Boolean
}) => (
  <div className="p-2 text-left">
    <p className="text-base font-light text-gray-darker">
      {`Total de conversas realizadas com colaboradores que respondem a ${
        isOnlyThisCirclePage ? 'este círculo' : 'estes círculos'
      } atualmente.`}
      <br />
      <br />
      {`São contabilizadas as conversas de Onboarding, Efetivação, 1:1 ou
      Carreira ${
        isOnlyThisCirclePage
          ? 'deste círculo.'
          : 'dos círculos que as habilitaram.'
      } Conversas de Comportamento não
      são contabilizadas.`}
    </p>
  </div>
)

const OneConversationTooltipContent = ({
  isOnlyThisCirclePage,
}: {
  isOnlyThisCirclePage: Boolean
}) => (
  <div className="p-2 text-left">
    <p className="text-base font-light text-gray-darker">
      {`Percentual de colaboradores que respondem a ${
        isOnlyThisCirclePage ? 'este círculo' : 'estes círculos'
      } atualmente e
      participaram de, pelo menos, uma conversa com ${
        isOnlyThisCirclePage ? 'seu líder' : 'seus líderes'
      } neste ciclo.`}
      <br />
      <br />
      {`São contabilizadas as conversas de Onboarding, Efetivação, 1:1 ou
      Carreira ${
        isOnlyThisCirclePage
          ? 'deste círculo.'
          : 'dos círculos que as habilitaram.'
      } Conversas de Comportamento não são contabilizadas.`}
    </p>
  </div>
)

const CurrentMembersTooltipContent = ({
  becameMemberThisCycle,
  noLongerATeamMember,
  mainTeamMemberSinceTheBeginning,
  isOnlyThisCirclePage,
}: {
  becameMemberThisCycle: number
  noLongerATeamMember: number
  mainTeamMemberSinceTheBeginning: number
  isOnlyThisCirclePage: Boolean
}) => (
  <div className="p-2 text-left">
    <p className="text-base font-light text-gray-darker">
      {`Respondem a ${
        isOnlyThisCirclePage ? 'este círculo' : 'estes círculos'
      } desde o início do ciclo:
      ${mainTeamMemberSinceTheBeginning} ${
        mainTeamMemberSinceTheBeginning === 1
          ? 'colaborador.'
          : 'colaboradores.'
      }`}
      <br />
      <br />
      {`Passaram a responder a ${
        isOnlyThisCirclePage ? 'este círculo' : 'estes círculos'
      } ao longo do ciclo:
      ${becameMemberThisCycle} ${
        becameMemberThisCycle === 1 ? 'colaborador.' : 'colaboradores.'
      }`}
      <br />
      <br />
      {`Deixaram de responder a ${
        isOnlyThisCirclePage ? 'este círculo' : 'estes círculos'
      } ao longo do ciclo e não estão sendo contabilizados: ${noLongerATeamMember} ${
        noLongerATeamMember === 1 ? 'colaborador.' : 'colaboradores.'
      }`}
      <br />
      <br />
      São contabilizados somente os colaboradores que respondem aos círculos que
      habilitam conversas de Onboarding, Efetivação, 1:1 ou Carreira atualmente.
    </p>
  </div>
)

const ConversationPanel = ({ query, stateId }: InsightComponentProps) => {
  const { snackbar } = useSnackbar()

  const queryString = match(query)
    .with(
      { inner_circle: P.boolean },
      ({ inner_circle }) => '?inner_circle=' + String(inner_circle),
    )
    .otherwise(() => '')

  const isOnlyThisCirclePage = match(query)
    .with({ inner_circle: false }, () => true)
    .otherwise(() => false)

  const [{ info, fetchingInfo, infoError }] = useCroods({
    name: 'currentCycleConversations',
    id: stateId,
    customPath: `${
      chartPath('current_cycle_conversations', query) + queryString
    }`,
    stateId: stateId,
    fetchOnMount: true,
    afterFailure: (e) => {
      if (e.response?.data.message) {
        snackbar({
          message: e.response.data.message,
          type: 'warning',
        })
      } else {
        snackbar({
          message:
            'Houve um erro inesperado. A seção Placar das Conversas não carregou.',
          type: 'warning',
        })
      }
    },
  })

  const { currentCycle } = useOrganization()

  if (infoError) return null

  const sectionChips = currentCycle ? (
    <Period
      startsAt={currentCycle.startsAt}
      finishesAt={currentCycle.finishesAt}
      show
    />
  ) : null

  return (
    <>
      <Section title="Resumo das conversas do ciclo atual" chips={sectionChips}>
        {info && !fetchingInfo && !infoError ? (
          <div className="mt-2 rounded-2xl shadow-card">
            <div className="grid items-center gap-10 py-6 text-center sm:flex sm:gap-0 sm:px-4">
              <div className="flex flex-col items-center basis-1/3">
                <div className="relative">
                  <div className="absolute top-0 -right-7">
                    <InfoTooltip
                      data-testid="total-conversations-tooltip"
                      htmlColor="#C1C1C1"
                    >
                      <TotalConversationsTooltipContent
                        isOnlyThisCirclePage={isOnlyThisCirclePage}
                      />
                    </InfoTooltip>
                  </div>
                  <h2 className="text-gray-darker">{info.conversations}</h2>
                </div>
                <h6 className="mt-2 max-w-[216px] text-gray-darker">
                  {info.conversations === 1
                    ? 'Conversa realizada'
                    : 'Conversas realizadas'}
                </h6>
              </div>
              <div className="relative flex flex-col items-center basis-1/3">
                <div className="relative">
                  <div className="absolute top-0 -right-7">
                    <InfoTooltip
                      data-testid="one-on-one-conversations-tooltip"
                      htmlColor={'#C1C1C1'}
                    >
                      <OneConversationTooltipContent
                        isOnlyThisCirclePage={isOnlyThisCirclePage}
                      />
                    </InfoTooltip>
                  </div>
                  <h2 className="text-gray-darker">
                    {info.memberPercentageWithConversations}%
                  </h2>
                </div>
                <h6 className="mt-2 max-w-[216px] text-gray-darker">
                  {info.memberWithConversations === 1
                    ? `${info.memberWithConversations} colaborador realizou pelo menos uma conversa neste ciclo`
                    : `${info.memberWithConversations} colaboradores realizaram pelo menos uma conversa neste ciclo`}
                </h6>
              </div>
              <div className="relative flex flex-col items-center basis-1/3">
                <div className="relative">
                  <div className="absolute top-0 -right-7">
                    <InfoTooltip htmlColor={'#C1C1C1'}>
                      <CurrentMembersTooltipContent
                        mainTeamMemberSinceTheBeginning={
                          info.mainTeamMemberSinceTheBeginning
                        }
                        becameMemberThisCycle={info.becameMemberThisCycle}
                        noLongerATeamMember={info.noLongerATeamMember}
                        isOnlyThisCirclePage={isOnlyThisCirclePage}
                      />
                    </InfoTooltip>
                  </div>
                  <h2 className="text-gray-darker">{info.mainTeamMembers}</h2>
                </div>
                <h6 className="mt-2 max-w-[216px] text-gray-darker">
                  {info.mainTeamMembers === 1
                    ? 'Colaborador responde'
                    : 'Colaboradores respondem'}
                  {isOnlyThisCirclePage
                    ? ' a este círculo atualmente'
                    : ' a estes círculos atualmente'}
                </h6>
              </div>
            </div>
          </div>
        ) : fetchingInfo ? (
          <div className="mt-3">
            <Skeleton height={100} variant="text" animation="pulse" />
          </div>
        ) : null}
      </Section>
    </>
  )
}

export { ConversationPanel }
