import * as React from 'react'

import type { Actions } from 'croods'
import PublishCard from './PublishCard'
import SummaryDialog from './summary'
import Table from './Table'
import Wrapper from './Wrapper'
import Modal from 'shared/ui/Modal'
import HomeIcon from '@material-ui/icons/Home'

import type { AddToCroodsQueue, EvaluationTeam, Role } from '../types'
import Period from 'shared/ui/Period'
import useOrganization from 'shared/organization/useOrganization'
import * as z from 'zod'
import TeamTableHeader from './TeamTableHeader'

type PSProps = {
  open: boolean
  handleClose: () => void
}

const PublishSuccess = ({ open, handleClose }: PSProps) => (
  <Modal.FormWrapper
    open={open}
    onClose={handleClose}
    schema={z.object({})}
    action="/"
  >
    <Modal.Header>Avaliação de Talentos concluída!</Modal.Header>
    <Modal.Content>
      <div className="flex flex-col items-center mt-20">
        <img alt="finish" src="/finish.png" className="w-32 h-32" />
        <h6 className="mt-5 leading-9 text-black/75">Uhuuul!</h6>
      </div>
    </Modal.Content>
    <Modal.Actions hideCancel>
      <Modal.Confirm aria-label="Ir para Home">
        <HomeIcon /> IR PARA HOME
      </Modal.Confirm>
    </Modal.Actions>
  </Modal.FormWrapper>
)

type Props = {
  allTeams: EvaluationTeam[]
  save: AddToCroodsQueue
  allRoles: Role[]
  rolesSave: Actions['save']
}

export default function TeamTable({
  save,
  allTeams,
  allRoles,
  rolesSave,
}: Props) {
  const [openDialog, setOpenDialog] = React.useState(false)
  const [successDialog, setSuccessDialog] = React.useState(false)
  const { evaluationWindowOpensAt, evaluationWindowClosesAt } =
    useOrganization()

  const handleDialog = () => {
    setOpenDialog(true)
  }

  const handleSuccessClose = () => {
    setSuccessDialog(false)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <div className="mb-16">
      <h3 className="mb-4 text-xl font-bold text-left">
        Nova Avaliação de Talentos
      </h3>
      <div className="mb-4">
        <Period
          title="Ciclo em avaliação"
          startsAt={evaluationWindowOpensAt}
          finishesAt={evaluationWindowClosesAt}
          evaluatedAt={null}
          durationInMonths={null}
          hollow={false}
          show={false}
          longFormat={false}
        />
      </div>
      <Wrapper>
        <div className="overflow-x-clip">
          <div className="sticky z-10 top-12 mt-7">
            <TeamTableHeader />
          </div>
          {allTeams.map((team: EvaluationTeam, idx) => (
            <Table
              key={team.teamId + idx}
              team={team}
              save={save}
              allRoles={allRoles}
              rolesSave={rolesSave}
            />
          ))}
        </div>
        <PublishCard handleDialog={handleDialog} />
        <SummaryDialog
          openDialog={openDialog}
          setSuccessDialog={setSuccessDialog}
          handleClose={handleClose}
          values={allTeams}
        />
        <PublishSuccess open={successDialog} handleClose={handleSuccessClose} />
      </Wrapper>
    </div>
  )
}
