import map from 'lodash/map'
import size from 'lodash/size'
import uniq from 'lodash/uniq'

const validateQuestions =
  ({ msg = 'Há perguntas repetidas. Use nomes únicos' } = {}) =>
  (questions: any[]) =>
    size(questions) === size(uniq(map(questions, 'inputName')))
      ? undefined
      : msg

export default validateQuestions
