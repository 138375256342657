import { cx } from 'shared/helpers'
import Body from './body'
import Cell from './cell'
import Head from './head'
import Header from './header'
import Row from './row'

function TableRoot({
  children,
  className,
  title,
  ...props
}: JSX.IntrinsicElements['table']) {
  return (
    <>
      {title && <div className="flex-1 text-xl font-bold mb-2">{title}</div>}
      <div className="w-[92vw] max-w-full overflow-x-auto rounded-xl shadow md:w-auto">
        <table
          className={cx('w-full table-fixed bg-gray-lightest', className)}
          {...props}
        >
          {children}
        </table>
      </div>
    </>
  )
}

const Table = Object.assign(TableRoot, { Head, Header, Body, Row, Cell })

export default Table
