import {
  parseName,
  parseLeader,
  parseMembers,
  parseInvitations,
  parseChilds,
  parseParent,
} from './parseTeamsAndUsers'

const parseValues = (rawValues: any, step: any) => {
  const {
    name = '',
    leadersToAdd = [],
    membersToAdd = [],
    parentTeamId = [],
    childTeamsIds = [],
    permissions = {},
    ...otherValues
  } = rawValues

  if (step === 'circle')
    return {
      ...otherValues,
      name: parseName(name),
      leadersToAdd: parseLeader(leadersToAdd),
    }

  return {
    ...otherValues,
    parentTeamId: parseParent(parentTeamId),
    childTeamsIds: parseChilds(childTeamsIds),
    membersToAdd: parseMembers(membersToAdd),
    membersToInvite: parseInvitations(membersToAdd),
    permitTalentsEvaluations: permissions?.permitTalentsEvaluations,
    permitTeamsEvaluations: permissions?.permitTeamsEvaluations,
    enableOneOnOneConversation: permissions?.enableOneOnOneConversation,
    enableCareerConversation: permissions?.enableCareerConversation,
    enableBehaviorConversation: permissions?.enableBehaviorConversation,
    enableUpwardEvaluation: permissions?.enableUpwardEvaluation,
  }
}

export default parseValues
