import { useCroods } from 'croods'
import { cx } from 'shared/helpers'
import PrimaryRole from 'shared/roles/PrimaryRole'
import SecondaryRole from 'shared/roles/SecondaryRole'
import type { RolesStateHandler } from 'performance/evaluations/membersEvaluation/TeamTable/Table/Roles'
import type { Role } from 'performance/evaluations/membersEvaluation/types'

type Props = {
  primaryRole: Role | null
  secondaryRoles: Role[]
  handleRoles: RolesStateHandler & { addSecondaryRole: (role: Role) => void }
}

const Loading = () => (
  <div
    data-testid="loading"
    className="w-full h-10 bg-gray-200 rounded animate-pulse"
  />
)

const Error = ({ message }: { message: string }) => (
  <label className="text-red">
    Algum erro ocorreu ao carregar os papéis {message}
  </label>
)

const Roles = ({ primaryRole, secondaryRoles = [], handleRoles }: Props) => {
  const [
    { list: allRoles, listError: rolesError, fetchingList: fetchingRoles },
    { save: rolesSave },
  ] = useCroods({
    name: 'organizationRoles',
    fetchOnMount: true,
  })

  return (
    <div className="flex flex-col gap-4">
      <h4 className="mt-5 mb-2">Papéis</h4>
      {rolesError ? (
        <Error message={rolesError} />
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1 mt-2">
            <label className="text-lg font-bold">Papel Principal</label>
            {fetchingRoles ? (
              <Loading />
            ) : (
              <PrimaryRole
                allRoles={allRoles}
                role={primaryRole}
                getDisabledRoles={handleRoles.getDisabledRoles}
                handleChange={(value: any, action?: 'add' | undefined) => {
                  if (action === 'add') {
                    rolesSave()({ name: value }).then((newValue) => {
                      handleRoles.setPrimaryRole(newValue)
                    })
                  } else {
                    handleRoles.setPrimaryRole(value)
                  }
                }}
              />
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label
              className={cx('text-lg font-bold', !primaryRole && 'text-gray')}
            >
              Outros Papéis
            </label>
            {fetchingRoles ? (
              <Loading />
            ) : (
              <SecondaryRole
                allRoles={allRoles}
                roles={secondaryRoles}
                getDisabledRoles={handleRoles.getDisabledRoles}
                handleChange={(value: any, inputValue?: string) => {
                  if (inputValue) {
                    rolesSave()({ name: inputValue }).then((newValue) => {
                      handleRoles.addSecondaryRole(newValue)
                    })
                  } else {
                    handleRoles.setSecondaryRoles(value)
                  }
                }}
                autocompleteProps={{
                  disabled: !primaryRole,
                  title: !primaryRole
                    ? 'Para habilitar este campo preencha o Papel Principal'
                    : 'Este campo é opcional',
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Roles
