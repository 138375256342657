import React from 'react'
import { useForm } from 'shared/utils/useForm'
import Step from 'shared/ui/form-step'
import AddMembers from '../add-members'
import {
  parseMembers,
  parseFetchedCollaborators,
  invalidCollaborators,
} from '../../parseTeamsAndUsers'
import ChildCirclePermissions from './ChildCirclePermissions'

type Props = {
  handleSubmit: (formValues: object) => void
  submitting?: boolean
  error: string | null
  visible: boolean
  values: any
  createdTeamId?: any
  filledValues: any
  handleNavigate: Function
  fetchedCollaborators: any
  saving: boolean
  setEditedMembers: Function
}

const Form = ({
  handleSubmit,
  submitting = false,
  error,
  visible,
  values,
  createdTeamId,
  filledValues,
  handleNavigate,
  fetchedCollaborators,
  saving,
  setEditedMembers,
}: Props) => {
  const { formState, fields, hasErrors } = useForm(
    {
      membersToAdd: parseFetchedCollaborators(
        fetchedCollaborators,
        filledValues,
      ),
      permissions: filledValues?.permissions || {},
    },
    {},
  )

  if (setEditedMembers)
    setEditedMembers(formState.values?.membersToAdd[0]?.inputName)

  const onSubmit: React.FormEventHandler = (e) => {
    e && e.preventDefault && e.preventDefault()
    handleSubmit && handleSubmit(formState.values)
  }

  React.useEffect(() => {
    if (visible && !saving) {
      const members = formState.values.membersToAdd
      const parsed = parseFetchedCollaborators(
        fetchedCollaborators,
        filledValues,
      )
      const step = members[0]?.step || 'collaborators'
      const inputName = [
        ...(members[0]?.inputName || []),
        ...(parsed[0]?.inputName || []),
      ].reduce(
        (items, item) =>
          items.every((option: any) => option.value !== item.value)
            ? [...items, item]
            : items,
        [],
      )
      formState.setField('membersToAdd', [{ step, inputName }])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, saving])

  return (
    <Step
      handleSubmit={onSubmit}
      error={error}
      disableButton={hasErrors}
      submitting={submitting}
      nextButtonLabel="Publicar"
      visible={visible}
      handleNavigate={handleNavigate(1)}
    >
      <section className="flex flex-col">
        <AddMembers
          {...fields('raw', 'membersToAdd', [], {
            touchOnChange: true,
          })}
          path="/users/browse"
          step="relations"
          title="Adicionar colaboradores ao círculo"
          placeholder="Busque pelo(s) nome(s) do(s) colaborador(es)"
          isAvatar
          showRole
          hasInvite
          multiple
          label="Buscar colaborador(es) existente(s)"
          selectedOptions={parseMembers(
            invalidCollaborators(
              values,
              filledValues,
              formState.values.membersToAdd,
            ),
          )}
          createdTeamId={createdTeamId}
          removeMember={!!filledValues?.id}
          formState={formState}
          childCircleLeaders={
            values?.childTeamsIds?.length
              ? values?.childTeamsIds[0].inputName
              : []
          }
          debounceTime={600}
        />
      </section>
      <section className="flex flex-col">
        <ChildCirclePermissions
          {...fields('raw', 'permissions', [], {})}
          values={values}
          state={formState.values}
        />
      </section>
    </Step>
  )
}

export default Form
