import React from 'react'
import { cx } from 'shared/helpers'
import Icon from 'shared/ui/icon'

type Document = {
  url: string
  name: string
}

type Props = {
  documents: Document[]
  title?: string
  emptyText?: string
  className?: string
}

const Documents = ({ documents, title, emptyText, className }: Props) => {
  return (
    <>
      {Boolean(documents?.length) && (
        <div className={cx('mx-4 mb-4 flex flex-col gap-1', className)}>
          {title && <label className="mb-1 text-sm font-bold">{title}</label>}
          {documents.map((document, index) => (
            <div
              key={`${document.url}${index}`}
              className="flex flex-row items-center gap-2"
            >
              <a
                title="arquivo"
                aria-label={document.name}
                href={document.url}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center break-all text-xs font-bold uppercase text-blue no-underline transition-all duration-500 ease-in hover:underline"
              >
                <Icon icon="attach-file" className="fill-current" />
                {document.name || `Documento em anexo - ${index + 1}`}
              </a>
            </div>
          ))}
        </div>
      )}

      {emptyText && Boolean(!documents.length) && (
        <div className={cx('mb-4 ml-4', className)}>
          <span className="text-base text-gray-darker">{emptyText}</span>
        </div>
      )}
    </>
  )
}
export default Documents
