import React from 'react'
import type { EventInput } from '@fullcalendar/react'

import { reducer } from './reducer'
import type { ActionProps } from './reducer'

type VocationManagementContextProps = {
  events: Array<EventInput>
  dispatch: React.Dispatch<ActionProps>
}

const VocationManagementContext = React.createContext(
  {} as VocationManagementContextProps,
)

export const useVacationManagement = () => {
  const context = React.useContext(VocationManagementContext)

  if (!context)
    throw new Error(
      'useTimeline hook, must be used within VocationManagementContextProvider',
    )

  return context
}

export const VocationManagementContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [state, dispatch] = React.useReducer(reducer, [])

  return (
    <VocationManagementContext.Provider value={{ events: state, dispatch }}>
      {children}
    </VocationManagementContext.Provider>
  )
}
