import Typography from '@material-ui/core/Typography'
import { darken, makeStyles } from '@material-ui/core/styles'
import { dateStatus, formatDateWeekdayUnits, cx } from 'shared/helpers'
import { colors } from 'theme'

type Props = {
  color: string
  status: string
}

const useClasses = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    height: 45,
    overflow: 'hidden',
  },
  background: {
    backgroundColor: (props: Props) => props.color,
    opacity: (props: Props) => (props.status ? 0.2 : 1),
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 45,
  },
  dateWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  date: {
    padding: '0.5rem 0 0.5rem 0.5rem',
    color: (props: Props) =>
      props.status ? darken(props.color, 0.4) : colors.black54,
    width: 70,
  },
  status: {
    width: 110,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.5rem',
    backgroundColor: (props) => props.color,
    color: colors.white,
    borderRadius: '22.5rem 0 0 22.5rem',
    height: 45,
  },
  type: {
    fontWeight: 600,
    fontSize: 10,
  },
}))

export default function Footer({ date }: { date: string }) {
  const { status, color } = dateStatus(date)
  const classes = useClasses({ status, color })
  const [weekDay, formattedDate] = formatDateWeekdayUnits(date).split(',')

  return (
    <div className={classes.wrapper}>
      <div className={classes.dateWrapper}>
        <Typography variant="body2" className={cx(classes.date, classes.type)}>
          {weekDay}
          <br />
          {formattedDate}
        </Typography>
        {status && (
          <div className={classes.status}>
            <Typography variant="body2" className={classes.type}>
              {status}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.background} />
    </div>
  )
}
