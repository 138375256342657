import React from 'react'
import { Fetch } from 'croods'
import Skeleton from 'shared/ui/skeleton'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import type { TeamMemberType } from 'types'

import { useLocation, useParams } from 'react-router-dom'
import ListAll from './list-all'

const ListAllConversation = () => {
  const { teamMemberId } = useParams()
  const { search } = useLocation()
  const type = (
    search ? search.split('?type=')[1] : 'members'
  ) as TeamMemberType

  return (
    <Fetch
      name="conversations"
      path={`/team_${type}/${teamMemberId}/conversations`}
      stateId={teamMemberId}
      render={(list) => {
        return (
          <ListAll
            type={type}
            teamMemberId={teamMemberId!}
            list={list}
            isEditMode={type === 'members'}
          />
        )
      }}
      renderLoading={() => <Skeleton height="264.66px" />}
      renderError={(error) => {
        if (error === 'not allowed to conversations? this Team') {
          return (
            <FeedbackMessage
              message="Ocorreu um erro"
              serverMessage="Você não está autorizado a ver as conversas desse usuário"
            />
          )
        }
        return <FeedbackMessage />
      }}
      renderEmpty={() => (
        <FeedbackMessage message="Nenhum dado foi encontrado" type="warning" />
      )}
    />
  )
}

export default ListAllConversation
