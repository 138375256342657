import type { Box } from '.'

type Props = {
  teams: Box['teams']
}

export default function Avatars({ teams }: Props) {
  return (
    <div className="flex h-[75%] w-full items-center justify-center px-8">
      <div className="flex flex-wrap items-center justify-center gap-4">
        {teams.map((team) => {
          const initials = team.name
            .split(' ')
            .slice(0, 3)
            .map((word) => word.charAt(0))
            .join('')

          return (
            <div
              key={team.id}
              className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-300 text-center text-lg font-bold uppercase text-white"
              title={team.name}
            >
              {initials}
            </div>
          )
        })}
      </div>
    </div>
  )
}
