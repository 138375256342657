import Avatar from 'shared/ui/Avatar'
import { getUserIdentifier } from 'shared/helpers'
import type { Member } from 'types'
import type { PublishedMemberEvaluation } from 'performance/evaluations/membersEvaluation/types'
import {
  labelAndColorByValue,
  meritPercentageStyle,
  riskPercentageStyle,
} from 'performance/evaluations/membersEvaluation/labelAndColorByValue'
import { useNavigate } from 'react-router-dom'
import BulletPoint from 'shared/ui/BulletPoint'

type Props = {
  member: Member & {
    latestEvaluation: PublishedMemberEvaluation
  }
}

const EvaluationDetails = ({ member }: Props) => {
  const navigate = useNavigate()

  {
    if (!(member && member.user && member.latestEvaluation)) return null
  }

  const badge = member.user.leader ? 'Líder' : ''

  const secondaryRoles = member.latestEvaluation.secondaryRoles
    .map((role) => role.name)
    .join(', ')

  const {
    pathProfile,
    individualOutput,
    teamwork,
    potential,
    culturalFit,
    salaryScore,
    lossImpactScore,
    meritPercentage,
    riskPercentage,
  } = member.latestEvaluation

  const pathProfileResult = labelAndColorByValue('pathProfile', pathProfile)
  const potentialResult = labelAndColorByValue('potential', potential)
  const individualOutputResult = labelAndColorByValue(
    'individualOutput',
    individualOutput,
  )
  const teamworkResult = labelAndColorByValue('teamwork', teamwork)
  const culturalFitResult = labelAndColorByValue('culturalFit', culturalFit)
  const meritPercentageResult = meritPercentageStyle(meritPercentage)
  const salaryScoreResult = labelAndColorByValue('salaryScore', salaryScore)
  const lossImpactScoreResult = labelAndColorByValue(
    'lossImpactScore',
    lossImpactScore,
  )
  const riskPercentageResult = riskPercentageStyle(riskPercentage)

  return (
    <div className="h-fit overflow-hidden rounded-xl border-2 border-blue-300">
      <div
        className="p-4 md:p-6 flex cursor-pointer items-center bg-blue-50"
        onClick={() => navigate(`/colaboradores/${member.user.id}`)}
      >
        <Avatar size={80} user={member.user} />
        <div className="ml-4 overflow-hidden">
          <h4 className="mb-2 overflow-hidden text-ellipsis">
            {getUserIdentifier({ user: member.user })}
          </h4>
          <b className="text-gray-500">{member.user.role}</b>
          {badge && <span className="ml-2 text-gray-500">[{badge}]</span>}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-y-4 p-4 md:p-6">
        <p className="font-bold">Papel principal</p>
        <p>{member.latestEvaluation.organizationRoleName}</p>

        <p className="font-bold">Outros papéis</p>
        <p>{secondaryRoles || 'Não preenchido'}</p>
      </div>

      <hr className="mx-6" />

      <div className="grid grid-cols-2 gap-y-4 p-4 md:p-6">
        <p className="font-bold">Perfil de Trajetória</p>
        <BulletPoint
          label={pathProfileResult?.label}
          bulletColor={pathProfileResult?.bgColor}
        />

        <p className="font-bold">Entrega</p>
        <BulletPoint
          label={individualOutputResult?.label}
          bulletColor={individualOutputResult?.bgColor}
        />

        <p className="font-bold">Trabalho em Equipe</p>
        <BulletPoint
          label={teamworkResult?.label}
          bulletColor={teamworkResult?.bgColor}
        />

        <p className="font-bold">Potencial</p>
        <BulletPoint
          label={potentialResult?.label}
          bulletColor={potentialResult?.bgColor}
        />
        <p className="font-bold">Fit Cultural</p>
        <BulletPoint
          label={culturalFitResult?.label}
          bulletColor={culturalFitResult?.bgColor}
        />
        <p className="font-bold">Mérito</p>
        <BulletPoint
          label={meritPercentageResult?.label}
          bulletColor={meritPercentageResult?.bgColor}
        />

        <p className="font-bold">Remuneração</p>
        <BulletPoint
          label={salaryScoreResult?.label}
          bulletColor={salaryScoreResult?.bgColor}
        />

        <p className="font-bold">Impacto da perda</p>
        <BulletPoint
          label={lossImpactScoreResult?.label}
          bulletColor={lossImpactScoreResult?.bgColor}
        />

        <p className="font-bold">Risco da perda</p>
        <BulletPoint
          label={riskPercentageResult?.label}
          bulletColor={riskPercentageResult?.bgColor}
        />
      </div>
    </div>
  )
}

export default EvaluationDetails
