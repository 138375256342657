import get from 'lodash/get'
import identity from 'lodash/identity'
import keys from 'lodash/keys'
import map from 'lodash/map'
import pick from 'lodash/pick'
import reduce from 'lodash/reduce'

const roleParser = (values: { label: string }[]) =>
  map(values, (value) => get(value, 'label'))

const questions = {
  name: {
    label: 'Nome',
    variant: 'text',
  },
  description: {
    label: 'Texto de Abertura',
    variant: 'text',
  },
  documentsAttributes: {
    label: 'Modelo de Documento',
    variant: 'files',
  },
  applicantsAttributes: {
    label: 'Solicitantes',
    variant: 'chips',
    parser: roleParser,
  },
  inputsAttributes: {
    label: 'Perguntas',
    variant: 'questions',
  },
  performersAttributes: {
    label: 'Executores',
    variant: 'chips',
    parser: roleParser,
  },
  performerInputsAttributes: {
    label: 'Perguntas',
    variant: 'questions',
  },
  approvalsAttributes: {
    variant: 'approval',
    parser: (value: any) =>
      map(
        value,
        ({
          approversAttributes,
          inputsAttributes: approverInputsAttributes,
        }) => ({
          inputsAttributes: approverInputsAttributes,
          approvers: roleParser(approversAttributes),
        }),
      ),
  },
}

const openingQuestions = (obj: any) =>
  pick(obj, [
    'name',
    'description',
    'documentsAttributes',
    'applicantsAttributes',
    'inputsAttributes',
  ])

const performerQuestions = (obj: any) =>
  pick(obj, ['performersAttributes', 'performerInputsAttributes'])

const approvalQuestions = (obj: any) => pick(obj, ['approvalsAttributes'])

const joinQuestionsAnswer = (values: any, q = questions) =>
  reduce(
    keys(q),
    (acc, current) => {
      const { parser = identity, ...question } = get(q, current)

      return {
        ...acc,
        [current]: {
          ...question,
          value: parser(get(values, current)),
        },
      }
    },
    {},
  )

const splitIntoSteps = (questionsValue: any) => ({
  opening: openingQuestions(questionsValue),
  performer: performerQuestions(questionsValue),
  approval: approvalQuestions(questionsValue),
})

const summarize = (values: any) => splitIntoSteps(joinQuestionsAnswer(values))

export default summarize
