import Talks from 'talks'
import Next from 'talks/conversations/next'
import newConversationPage from 'talks/newConversation'
import ConversationHistory from 'talks/conversationHistory'
import oneOnOneNew from 'talks/conversations/oneOnOne/New'
import oneOnOneEdit from 'talks/conversations/oneOnOne/Edit'
import Career from 'talks/conversations/career/new'
import BehaviorNew from 'talks/conversations/behavior/New'
import BehaviorEdit from 'talks/conversations/behavior/Edit'
import OnboardingNew from 'talks/conversations/onboarding/new'
import OnboardingEdit from 'talks/conversations/onboarding/edit'
import PermanentNew from 'talks/conversations/permanent/NewPermanentConversation'
import UpwardEvaluationNew from 'talks/conversations/upwardEvaluation/New'
import UpwardEvaluationEdit from 'talks/conversations/upwardEvaluation/Edit'
import type { LegacyRoute } from 'types'
import ListAllConversation from 'talks/conversations/all'

const home: LegacyRoute = {
  component: Talks,
  path: '/conversas',
  pageTitle: 'Conversas',
  breadcrumbs: ['home'],
}
const newConversation: LegacyRoute = {
  component: newConversationPage,
  path: '/conversas/nova-conversa',
  pageTitle: 'Iniciar Conversa',
  breadcrumbs: ['home', 'newConversation'],
}
const next: LegacyRoute = {
  component: Next,
  path: '/conversas/proximas-conversas',
  pageTitle: 'Próximas Conversas',
  breadcrumbs: ['home', 'next'],
}
const conversationHistory: LegacyRoute = {
  component: ConversationHistory,
  path: '/conversas/historico-de-conversas',
  pageTitle: 'Histórico de Conversas',
  breadcrumbs: ['home', 'conversationHistory'],
}
const conversationList: LegacyRoute = {
  component: ListAllConversation,
  path: '/conversas/historico-de-conversas/:teamMemberId',
  breadcrumbs: ['home', 'conversationHistory', 'conversationList'],
  pageTitle: 'Histórico de Conversas',
}
const NewOneOnOne: LegacyRoute = {
  component: oneOnOneNew,
  path: '/conversas/nova-conversa/1-1/:teamMemberId',
  pageTitle: 'Nova Conversa 1:1',
  breadcrumbs: ['home', 'oneOnOne', 'NewOneOnOne'],
}
const editOneOnOne: LegacyRoute = {
  component: oneOnOneEdit,
  path: '/conversas/nova-conversa/1-1/:teamMemberId/:conversationId/editar',
  pageTitle: 'Editar Conversa 1:1',
  breadcrumbs: ['home', 'oneOnOne', 'editOneOnOne'],
}
const newCareer: LegacyRoute = {
  component: Career,
  path: '/conversas/nova-conversa/carreira/:teamMemberId',
  pageTitle: 'Nova Conversa de Carreira',
  breadcrumbs: ['home', 'career', 'newCareer'],
}
const newBehavior: LegacyRoute = {
  component: BehaviorNew,
  path: '/conversas/nova-conversa/comportamento/:teamMemberId',
  pageTitle: 'Nova Conversa de Comportamento',
  breadcrumbs: ['home', 'behavior', 'newBehavior'],
}
const editBehavior: LegacyRoute = {
  component: BehaviorEdit,
  path: '/conversas/nova-conversa/comportamento/:teamMemberId/:conversationId/editar',
  pageTitle: 'Editar Conversa de Comportamento',
  breadcrumbs: ['home', 'behavior', 'editBehavior'],
}
const newOnboard: LegacyRoute = {
  component: OnboardingNew,
  path: '/conversas/nova-conversa/onboarding/:teamMemberId',
  pageTitle: 'Nova Conversa de Onboarding',
  breadcrumbs: ['home', 'onboard', 'newOnboard'],
}

const editOnboard: LegacyRoute = {
  component: OnboardingEdit,
  path: '/conversas/nova-conversa/onboarding/:teamMemberId/:conversationId/editar',
  pageTitle: 'Editar Conversa de Onboarding',
  breadcrumbs: ['home', 'onboard', 'editOnboard'],
}
const newPermanent: LegacyRoute = {
  component: PermanentNew,
  path: '/conversas/nova-conversa/efetivacao/:teamMemberId',
  pageTitle: 'Nova Conversa de Efetivação',
  breadcrumbs: ['home', 'permanent', 'newPermanent'],
}
const newUpwardEvaluation: LegacyRoute = {
  component: UpwardEvaluationNew,
  path: '/conversas/lider/:teamLeaderId/avaliacao-ascendente/novo',
  pageTitle: 'Nova Avaliação Ascendente',
  breadcrumbs: ['home', 'leader', 'newUpwardEvaluation'],
}
const editUpwardEvaluation: LegacyRoute = {
  component: UpwardEvaluationEdit,
  path: '/conversas/lider/:teamLeaderId/avaliacao-ascendente/:upwardEvaluationId/editar',
  pageTitle: 'Editar Avaliação Ascendente',
  breadcrumbs: ['home', 'leader', 'editUpwardEvaluation'],
}

export default [
  home,
  newConversation,
  next,
  conversationHistory,
  NewOneOnOne,
  editOneOnOne,
  newCareer,
  newBehavior,
  editBehavior,
  newOnboard,
  editOnboard,
  newPermanent,
  newUpwardEvaluation,
  editUpwardEvaluation,
  conversationList,
]
