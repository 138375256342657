import * as React from 'react'
import Button from '@material-ui/core/Button'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import Modal from 'shared/ui/Modal'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { cx } from 'shared/helpers'

type Props = {
  handleComplete: () => void
  skillTitle?: string
  isCompleted?: boolean
}

export default function Complete({
  handleComplete,
  skillTitle,
  isCompleted,
}: Props) {
  const [modalOpen, setModalOpen] = React.useState(false)

  const handleSubmit = async () => {
    handleComplete()
  }

  return (
    <>
      <Modal.Wrapper
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
        onConfirm={handleSubmit}
      >
        <Modal.Header>Competência desenvolvida?</Modal.Header>
        <Modal.Content className="max-w-[600px]">
          <p>
            Deseja marcar a competência <b>{skillTitle}</b> como suficientemente
            desenvolvida pelo colaborador?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Modal.Confirm aria-label="Competência desenvolvida" kind="success">
            Confirmar
          </Modal.Confirm>
        </Modal.Actions>
      </Modal.Wrapper>
      <span title={isCompleted ? 'Desenvolvida' : 'Marcar como desenvolvida'}>
        <Button
          onClick={() => {
            setModalOpen(true)
          }}
          disabled={isCompleted}
          className={cx(
            isCompleted &&
              'disabled:!bg-transparent !border-0 bg-transparent !text-green-dark',
          )}
          aria-label={isCompleted ? 'Desenvolvida' : 'Marcar como desenvolvida'}
        >
          {isCompleted ? <CheckCircle /> : <CheckCircleOutline />}
        </Button>
      </span>
    </>
  )
}
