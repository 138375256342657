import * as React from 'react'
import type { PageProps } from 'types'

import useOrganization from 'shared/organization/useOrganization'
import { OrganizationSettings } from './organization'
import { TeamsSettings } from './teams'

const Page: PageProps = () => {
  const organization = useOrganization()

  return (
    <>
      <OrganizationSettings organization={organization} />
      <TeamsSettings organization={organization} className="mt-12" />
    </>
  )
}

export default Page
