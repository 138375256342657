import React from 'react'
import { Dialog } from '@headlessui/react'
import { cx } from 'shared/helpers'

type Props = React.HTMLAttributes<HTMLDivElement>
const Header = ({ children, className, ...props }: Props) => (
  <Dialog.Title
    as="div"
    {...props}
    className={cx(
      className,
      'flex items-center justify-between rounded-t-lg bg-gray-lightest p-6 text-gray-darkest',
    )}
  >
    {typeof children === 'string' ? <h5>{children}</h5> : children}
  </Dialog.Title>
)

export default Header
