import type { ButtonProps } from '@material-ui/core/Button'
import Button from '@material-ui/core/Button'
import React from 'react'
import { useStepperContext } from '../Context'

type Props = {
  children: React.ReactNode
  onClick?: () => void | boolean
} & ButtonProps

export default function NextStep({ children, onClick, ...props }: Props) {
  const { nextStep, currentStep, stepLength } = useStepperContext()

  if (currentStep >= stepLength - 1) return null

  const handleClick = () => {
    const result = onClick?.()
    if (result !== false) nextStep()
  }

  return (
    <Button {...props} onClick={handleClick}>
      {children}
    </Button>
  )
}
