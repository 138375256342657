import { useState } from 'react'
import Button from '@material-ui/core/Button'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import { typeToIcon, typeToName } from 'shared/utils/conversationLookup'
import Period from 'shared/ui/Period'
import Modal from 'shared/ui/Modal'
import * as z from 'zod'
import CareerInfo from './info'

const CareerModal = ({
  open,
  handleClose,
  conversationDate,
  nextConversation,
  conversationId,
  supportPlan,
  organizationRole,
  secondaryRoles,
  assessments,
}: any) => {
  return (
    <Modal.FormWrapper
      open={open}
      onClose={handleClose}
      schema={z.object({})}
      action="/"
      className="max-h-screen overflow-y-auto md:min-w-[914px]"
    >
      <Modal.Header>
        <div className="flex items-center gap-1">
          {typeToIcon('career')}
          <h3 className="text-xl text-gray-darkest text-opacity-80 first-letter:uppercase">
            {typeToName('career')}
          </h3>
        </div>
      </Modal.Header>
      <Modal.Content>
        <CareerInfo
          id={conversationId}
          conversationDate={conversationDate}
          nextConversation={nextConversation}
          supportPlan={supportPlan}
          snapshot={true}
          organizationRole={organizationRole}
          secondaryRoles={secondaryRoles}
          assessments={assessments}
        />
      </Modal.Content>
      <Modal.Actions cancelLabel="Voltar" />
    </Modal.FormWrapper>
  )
}

const CareerListItem = ({ conversation }: any) => {
  const {
    id,
    publishedAt,
    supportPlan,
    nextConversation,
    organizationRole,
    secondaryRoles,
    teamwork,
    individualOutput,
    pathProfile,
  } = conversation

  const [conversationModal, setConversationModal] = useState(false)
  const handleModalClose = () => {
    setConversationModal(false)
  }

  const handleModalOpen = () => {
    setConversationModal(true)
  }

  return (
    <div id={id} className="relative scroll-pt-20">
      <div className="card">
        <header className="flex items-center justify-between bg-gray-lightest px-8 py-2">
          <div className="flex items-center gap-2">
            {typeToIcon('career')}
            <h4 className="text-base text-gray-darkest text-opacity-80 first-letter:uppercase">
              {typeToName('career')}
            </h4>
          </div>
        </header>
        <div className="flex justify-between gap-4 px-3 py-4 sm:px-8">
          <div className="flex items-center justify-center">
            <p className="mr-2 text-lg font-bold text-gray-darkest">
              Conversa realizada em:
            </p>
            {publishedAt && (
              <Period evaluatedAt={publishedAt} longFormat show />
            )}
          </div>
          <div>
            <Button
              variant="outlined"
              startIcon={<VisibilityOutlinedIcon />}
              size="small"
              onClick={handleModalOpen}
            >
              Conversa
            </Button>
            <CareerModal
              open={conversationModal}
              handleClose={handleModalClose}
              conversationDate={publishedAt}
              nextConversation={nextConversation}
              conversationId={id}
              supportPlan={supportPlan}
              organizationRole={organizationRole}
              secondaryRoles={secondaryRoles}
              assessments={{
                teamwork,
                individualOutput,
                pathProfile,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareerListItem
