import { match } from 'ts-pattern'
import type {
  PotentialValues,
  CulturalFitValues,
} from 'performance/evaluations/membersEvaluation/types'
import type { ContributionScore } from 'performance/evaluations/membersEvaluation/TeamTable/Table/calculate-contribution'

const MAX_CONTRIBUTION_SCORE = 8
const MAX_POTENTIAL_SCORE = 2
const MAX_FIT_SCORE = 5
const MAX_MERIT_SCORE =
  MAX_CONTRIBUTION_SCORE + MAX_POTENTIAL_SCORE + MAX_FIT_SCORE

const calculateMeritPercentage = (
  contributionScore: ContributionScore,
  potentialScore: PotentialScore,
  culturalFitScore: CulturalFitScore,
) => {
  const meritPercentage = Math.round(
    ((contributionScore + potentialScore + culturalFitScore) /
      MAX_MERIT_SCORE) *
      100,
  )
  return meritPercentage < 0 ? 0 : meritPercentage
}

type PotentialScore = 0 | 1 | 2
const mappingPotential = (potential: PotentialValues | undefined) =>
  match({
    potential,
  })
    .with({ potential: 'high' }, (): PotentialScore => 2)
    .with({ potential: 'medium' }, (): PotentialScore => 1)
    .with({ potential: 'low' }, (): PotentialScore => 0)
    .otherwise((): PotentialScore => 0)

type CulturalFitScore = -5 | 0 | 3 | 4 | 5
const mappingCulturalFit = (culturalFit: CulturalFitValues | undefined) =>
  match({
    culturalFit,
  })
    .with({ culturalFit: 'detractor' }, (): CulturalFitScore => -5)
    .with({ culturalFit: 'low' }, (): CulturalFitScore => 0)
    .with({ culturalFit: 'adequate' }, (): CulturalFitScore => 3)
    .with({ culturalFit: 'high' }, (): CulturalFitScore => 4)
    .with({ culturalFit: 'advocate' }, (): CulturalFitScore => 5)
    .otherwise((): CulturalFitScore => 0)

export type { CulturalFitScore, PotentialScore }
export { mappingCulturalFit, mappingPotential, calculateMeritPercentage }
