import { useEffect } from 'react'
import { useNavigation } from 'react-router-dom'
import type { SnackbarType } from 'shared/ui/Snackbar/types'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'

const FLASH_MESSAGE_KEY = '__vibeFlashMessage'

function flashMessage(message: string, type: SnackbarType) {
  localStorage.setItem(FLASH_MESSAGE_KEY, JSON.stringify({ message, type }))
}

function FlashMessage() {
  const { state } = useNavigation()
  const { snackbar } = useSnackbar()

  useEffect(() => {
    if (state !== 'idle') return

    const rawFlash = localStorage.getItem(FLASH_MESSAGE_KEY)

    if (!rawFlash) return

    const flash = JSON.parse(rawFlash) as {
      message: string
      type: SnackbarType
    }

    localStorage.removeItem(FLASH_MESSAGE_KEY)
    snackbar(flash)
  }, [snackbar, state])

  return null
}

export { flashMessage, FlashMessage }
