import type { ShouldRevalidateFunction } from 'react-router-dom'
import {
  Outlet,
  useLocation,
  useLoaderData,
  useRouteError,
  isRouteErrorResponse,
  json,
} from 'react-router-dom'
import Providers from 'providers'
import compact from 'lodash/compact'
import {
  useIsMobile,
  usePageTitle,
  useRestoreScrollOnNavigation,
} from 'shared/utils/hooks'
import { GlobalLoading } from 'themes/main'
import { cx } from '@remaster-run/helpers'
import { getCurrentUser } from 'domain/auth'
import { ResultError } from 'domain-functions'
import ModulesMenu from 'shared/ui/menu/modulesMenu'
import ModuleItemsMenu from 'shared/ui/menu/productMenu'
import Breadcrumbs from 'shared/ui/layout/Breadcrumbs'
import { useEffect, useState } from 'react'
import { useRouteHandle } from 'domain/routes'
import { useIndentifyAnalytics } from 'domain/analytics'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { FlashMessage } from 'flash-message'
import { shouldRevalidate as shouldRevalidateSolicitationManagement } from 'routes/configuracoes.gestao-de-solicitacoes'

export const shouldRevalidate: ShouldRevalidateFunction = (options) =>
  shouldRevalidateSolicitationManagement(options)

export async function loader() {
  const currentUser = await getCurrentUser()
  return json({ currentUser })
}

function Background(props: JSX.IntrinsicElements['div']) {
  return (
    <div
      className="relative flex h-screen w-screen overflow-hidden bg-gradient-to-t from-[#898bc5] via-[#7393cb] to-[#7393cb]"
      {...props}
    />
  )
}

function InstallInstructions() {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const apple = /iphone|ipad|ipod/.test(userAgent)

  const standalone =
    'standalone' in window.navigator && window.navigator.standalone

  const [visible, setVisible] = useState(apple && !standalone)

  if (!visible) return null

  return (
    <div className="fixed inset-x-0 bottom-0 z-[200] border-t border-blue-100">
      <div className="bg-blue-50 p-4">
        <div className="flex gap-2">
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-bold text-blue-800">
              Instale o app do Vibe
            </h3>
            <p className="text-sm text-blue-800">
              <span>No Safari, clique em </span>
              <svg
                className="w-5 h-5 inline align-middle"
                fill="currentColor"
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z" />
                <path d="M24 7h2v21h-2z" />
                <path d="M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z" />
              </svg>{' '}
              <span>
                e depois em <em>Adicionar à Tela de Início</em>.
              </span>
            </p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className="inline-flex rounded-md bg-blue-50 p-1.5 text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50"
                onClick={() => setVisible(false)}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function Component() {
  const location = useLocation()
  const handle = useRouteHandle()
  const title = handle.title ?? ''
  const pageId = compact(location.pathname.split('/')).join('-')
  const { category, breadcrumbs, layout } = handle
  useRestoreScrollOnNavigation()

  const [mobileMenuState, setMobileMenuState] = useState({
    category,
    open: false,
  })

  const toggleMobileMenu = () =>
    setMobileMenuState({ ...mobileMenuState, open: !mobileMenuState.open })

  const isMobile = useIsMobile()

  const { currentUser } = useLoaderData() as {
    currentUser: Awaited<ReturnType<typeof getCurrentUser>>
  }

  useEffect(() => {
    const nextOpen =
      mobileMenuState.category !== undefined &&
      category !== mobileMenuState.category &&
      !['/convidar', '/editar-perfil'].includes(location.pathname)

    setMobileMenuState({
      category,
      open: mobileMenuState.open ? nextOpen : false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const isMenuVisible = Boolean(
    layout === 'full' &&
      currentUser &&
      (!isMobile || (isMobile && mobileMenuState.open)),
  )

  usePageTitle(title)
  useIndentifyAnalytics(currentUser)

  return (
    <Providers renderOrganizationProvider={layout === 'full'}>
      <GlobalLoading />
      <FlashMessage />
      <Background>
        <ModulesMenu
          currentUser={currentUser}
          toggleDrawer={toggleMobileMenu}
          isVisible={isMenuVisible}
        />
        <div
          className={cx(
            'relative flex grow overflow-x-hidden bg-gradient-to-br from-vibe-light to-vibe-pink',
            'before:bg-vibe-background before:absolute before:left-0 before:bottom-0 before:hidden before:h-[220px] before:w-[220px] before:bg-contain before:bg-left-bottom before:bg-no-repeat lg:before:block',
            'after:bg-vibe-background after:absolute after:right-0 after:top-0 after:hidden after:h-[220px] after:w-[220px] after:rotate-180 after:bg-contain after:bg-left-bottom after:bg-no-repeat xl:after:block',
            isMenuVisible && 'rounded-l-2xl',
          )}
        >
          {isMenuVisible && <ModuleItemsMenu currentUser={currentUser} />}
          {layout === 'full' ? (
            <main
              className={cx(
                'z-[100] max-w-full grow overflow-auto p-2 md:p-4 relative',
                mobileMenuState.open && 'hidden',
              )}
            >
              <div id="scroll-restoration-target" className="absolute top-0" />
              <InstallInstructions />
              <section
                className="mx-auto max-w-screen-lg rounded-xl bg-white p-2 shadow sm:rounded-2xl sm:p-4 md:my-4 md:p-6"
                id={pageId}
              >
                {breadcrumbs && (
                  <Breadcrumbs
                    breadcrumbs={breadcrumbs}
                    category={category}
                    isDrawerOpen={mobileMenuState.open}
                    toggleDrawer={toggleMobileMenu}
                  />
                )}
                <Outlet />
              </section>
            </main>
          ) : (
            <main className="z-[100] flex-1 overflow-auto">
              <Outlet />
            </main>
          )}
        </div>
      </Background>
    </Providers>
  )
}

function ErrorPage({
  children = <div role="comment">Ocorreu um erro inesperado.</div>,
  ...props
}: JSX.IntrinsicElements['div']) {
  return (
    <Background>
      <main className="z-[100] flex-1 h-screen flex items-center justify-center">
        <div className="bg-white p-8 rounded-xl flex flex-col gap-4" {...props}>
          <h1 className="text-2xl">Ooops!</h1>
          {children}
        </div>
      </main>
    </Background>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()

  useEffect(() => {
    if (
      error instanceof TypeError &&
      error.message.toLowerCase() === 'failed to fetch'
    ) {
      window.location.href = '/offline.html'
    }
  }, [error])

  if (process.env.NODE_ENV === 'production') {
    console.error(error)
    return <ErrorPage />
  }

  if (process.env.NODE_ENV === 'development') {
    console.error(error)
  }

  if (isRouteErrorResponse(error)) {
    const getMessage = () => {
      if (!error.data) return 'Erro de rota inesperado.'
      if (error.data.message) return String(error.data.message)
      if (typeof error.data === 'string') return error.data

      return JSON.stringify(error.data, null, 2)
    }

    return (
      <ErrorPage>
        <div className="prose">
          {error.status && <h2>{error.status}</h2>}
          {error.statusText && <p>{error.statusText}</p>}
          <pre role="comment" className="max-h-[50vh] overflow-auto">
            {getMessage()}
          </pre>
        </div>
      </ErrorPage>
    )
  }

  if (error instanceof ResultError) {
    return (
      <ErrorPage>
        <div className="prose">
          <h2>ResultError</h2>
          <pre role="comment" className="max-h-[50vh] overflow-auto">
            {JSON.stringify(error.result, null, 2)}
          </pre>
        </div>
      </ErrorPage>
    )
  }

  if (error instanceof Error) {
    return (
      <ErrorPage>
        <div className="prose">
          <pre role="comment" className="max-h-[50vh] overflow-auto">
            {error.message}
          </pre>
        </div>
      </ErrorPage>
    )
  }

  if (typeof error === 'string') {
    return (
      <ErrorPage>
        <pre role="comment" className="max-h-[50vh] overflow-auto">
          {error}
        </pre>
      </ErrorPage>
    )
  }

  return <ErrorPage />
}
