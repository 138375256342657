import * as React from 'react'
import Roles from './FirstConversation/Roles'
import type { Dispatch, SetStateAction } from 'react'
import type { Info } from 'croods'
import type { Role } from 'performance/evaluations/membersEvaluation/types'
import type { CareerConversation } from 'talks/types'

type HandleSavePrimaryRole = { organizationRoleId: Role['id'] }
type HandleSaveSecondaryRoles = {
  has_role: boolean
  organizationRoleId: Role['id']
  secondaryRolesIds: Role['id'][]
}
type Props = {
  conversation: Info<CareerConversation>
  setConversation: (value: CareerConversation) => void
  handleSave: (value: HandleSavePrimaryRole | HandleSaveSecondaryRoles) => void
  setIsValidated: Dispatch<SetStateAction<boolean>>
}

const ConversationRoles = ({
  conversation,
  handleSave,
  setConversation,
  setIsValidated,
}: Props) => {
  const setPrimaryRole = (role: Role) => {
    setConversation({ ...conversation, organizationRole: role })
    handleSave({ organizationRoleId: role.id })
  }

  const getPrimaryRole: () => Role | null = () =>
    conversation.organizationRole ?? null

  const setSecondaryRoles = (roles: Role[]) => {
    if (conversation?.organizationRole?.id) {
      setConversation({
        ...conversation,
        secondaryRoles: roles.map((r) => ({
          id: r.id,
          organizationRoleId: r.id,
          name: r.name,
        })),
      })
      handleSave({
        has_role: true,
        organizationRoleId: conversation.organizationRole.id,
        secondaryRolesIds: roles.map((r) => r.id),
      })
    }
  }

  const addSecondaryRole = (role: Role) => {
    setSecondaryRoles([...getSecondaryRoles(), role])
  }

  const getSecondaryRoles: () => Role[] = () =>
    (conversation.secondaryRoles ?? []).map((role) => ({
      id: role.organizationRoleId!,
      name: role.name,
    }))

  const getDisabledRoles = (role: Role) =>
    [...getSecondaryRoles(), getPrimaryRole()].some(
      (selected) => selected && selected.id === role.id,
    )

  const handleRoles = {
    setPrimaryRole,
    setSecondaryRoles,
    getDisabledRoles,
    addSecondaryRole,
  }

  React.useEffect(() => {
    setIsValidated(!!conversation.organizationRole)
  }, [conversation.organizationRole]) // eslint-disable-line

  return (
    <Roles
      primaryRole={getPrimaryRole()}
      secondaryRoles={getSecondaryRoles()}
      handleRoles={handleRoles}
    />
  )
}

export default ConversationRoles
