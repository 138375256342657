import React from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import map from 'lodash/map'
import { getTypeOf } from 'shared/helpers'
import { ListItem } from 'settings/solicitationTemplate/list-item'
import questionsTypes from 'settings/solicitationTemplate/new/addQuestions/questionTypes'
import { getSelectedIcon } from 'settings/solicitationTemplate/new/parsers/utils'
import Icon from 'shared/ui/icon'

const Questions = ({ value: questions }: any) => {
  const classNames = useStyles()
  return (
    <Grid container direction="column">
      <List className={classNames.list}>
        {map(questions, (input, index) => (
          <ListItem
            key={`${input.inputName}-${index}`}
            customIcon={getSelectedIcon({
              questionsTypes,
              inputType: input.inputType,
              optionType: input?.optionType,
            })}
            item={input}
            primaryLabel={input.inputName}
            primaryText={
              questionsTypes[input.inputType as keyof typeof questionsTypes]
                .title
            }
            secondaryLabel={getInputOption({
              option: input.maskType || input.optionList,
              type: input.inputType,
              parser: 'label',
            })}
            secondaryItem={getInputOption({
              option: input.maskType || input.optionList,
              type: input.inputType,
              parser: 'content',
            })}
            hasActions={input.required}
            firstStatusIcon={
              input.required && (
                <Grid item className="text-gray-500">
                  <Icon icon="check-box" />
                  Obrigatório
                </Grid>
              )
            }
          />
        ))}
      </List>
    </Grid>
  )
}

const getInputOption = ({ option, type, parser }: any) => {
  if (type === 'number_input') {
    if (parser === 'label') {
      return 'Máscara selecionada'
    }
    if (parser === 'content') {
      const select = {
        period: '9.999,99',
        comma: '9,999.99',
        integer: '999999'
      }
      return select[option as keyof typeof select]
    }
  }

  if (type === 'option_list') {
    if (parser === 'label') {
      return 'Opções adicionada(s)'
    }
    if (parser === 'content') {
      // remove the below line when back-end start to send it
      if (getTypeOf(option) !== 'array') return null

      return (
        <ul className="mt-2">
          {option.map((item: any) => (
            <li key={item} className="!mb-0">
              {item}
            </li>
          ))}
        </ul>
      )
    }
  }
  return null
}

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    padding: theme.spacing(4),
    marginTop: 4,
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 1),
    },
  },
  list: {
    width: '100%',
    padding: 0,
    '& li': { marginBottom: '1rem' },
    '& li:last-child': { marginBottom: 0 },
  },
}))

export default Questions
