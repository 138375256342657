import React from 'react'

type FormFile = {
  name: string
  type: string
  content?: string
  url: string | null
  id: string | null
}

const useFileInput = (initialFiles: FormFile[] = []) => {
  const [files, setFiles] = React.useState<FormFile[]>([])

  React.useEffect(() => {
    setFiles(initialFiles || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files || event.currentTarget.files.length === 0)
      return

    Array.from(event.currentTarget.files).forEach((fileNode) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        const base64Encoded = event.target?.result
        setFiles((previousState) => [
          ...previousState,
          {
            name: fileNode.name,
            type: fileNode.type,
            content: base64Encoded as string,
            id: null,
            url: null,
          },
        ])
      }

      reader.readAsDataURL(fileNode)
    })
  }

  const removeFile = (indexToRemove: number) => {
    setFiles((previousState) =>
      previousState.filter((_, i) => i !== indexToRemove),
    )
  }

  return { files, addFile, removeFile }
}

export { useFileInput }
export type { FormFile }
