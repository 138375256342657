import * as React from 'react'

import differenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'
import isFuture from 'date-fns/isFuture'
import parseISO from 'date-fns/parseISO'

import { formatNumberOfDays } from 'portal/vacationsHelpers'

const dateStatus = (dateLimitString: string) => {
  const dateLimit = parseISO(dateLimitString)
  const diff = differenceInDays(dateLimit, new Date())
  const expirationTerm = isFuture(dateLimit) ? 'Iniciar em' : 'Atrasado há'

  return {
    status: `${expirationTerm} ${formatNumberOfDays(Math.abs(diff))}`,
    color: diff <= 30 ? 'bg-red' : diff <= 60 ? 'bg-yellow' : 'bg-gray-dark',
  }
}

export default function Footer({ date }: { date: string }) {
  const { status, color } = dateStatus(date)
  const formattedDate = format(parseISO(date), 'dd/MM/yyyy')

  return (
    <div className="relative h-10 overflow-hidden">
      <div className="flex justify-between">
        <p className={'w-[4.38rem] py-2 pr-0 pl-2 text-xs font-normal'}>
          Data limite {formattedDate}
        </p>
        {status && (
          <div
            role="status"
            className={`rounded-tr-0 rounded-br-0 flex h-10 w-28 items-center rounded-tl-[22.5rem] rounded-bl-[22.5rem] pl-2 text-white ${color}`}
          >
            <p className="text-xs font-semibold">{status}</p>
          </div>
        )}
      </div>
      <div
        className={`absolute left-0 top-0 h-10 w-full bg-opacity-20 ${color}`}
      />
    </div>
  )
}
