import * as React from 'react'

const DotsInterval = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="3"
    viewBox="0 0 20 3"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
  >
    <path
      d="M1.5 1.5H18.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeDasharray="2.5 2.5"
    />
  </svg>
)

export { DotsInterval }
