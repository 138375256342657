import * as React from 'react'
import type { Organization } from 'types'

import Table from 'shared/ui/table'
import Icon from 'shared/ui/icon'
import CheckInput from 'shared/forms/CheckInput'
import { useCroods } from 'croods'
import { ConfirmationModal } from './confirmation-modal'

const ToggleFeatureWithModal = ({
  checked,
  children,
}: {
  checked: boolean
  children: (
    modalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
  ) => React.ReactNode
}) => {
  const [showModal, setShowModal] = React.useState(false)

  return (
    <>
      <div className="group flex justify-center p-5">
        <div className="block cursor-pointer group-hover:hidden">
          {checked ? (
            <Icon icon="check-circle" className="text-green-300" />
          ) : (
            <Icon icon="block" />
          )}
        </div>
        <div className="hidden cursor-pointer group-hover:block">
          {checked ? (
            <button
              className="text-base font-semibold text-blue"
              onClick={() => {
                setShowModal(true)
              }}
            >
              NÃO PERMITIR
            </button>
          ) : (
            <button
              className="text-base font-semibold text-blue"
              onClick={() => {
                setShowModal(true)
              }}
            >
              PERMITIR
            </button>
          )}
        </div>
      </div>
      {children([showModal, setShowModal])}
    </>
  )
}

type CopyForFeature<T extends string> = Record<
  T,
  { description: string; alertMessage: string }
>

const ToggleFeatureOnlyOrganization = ({
  type,
  checked,
}: {
  type: 'feedback'
  checked: boolean
}) => {
  const [, { save, setInfo: setOrganization }] = useCroods({
    name: 'organization',
    id: type,
  })

  const PERMIT_CONTENT: CopyForFeature<typeof type> = {
    feedback: {
      description: 'permitir Feedbacks',
      alertMessage:
        'Todos os Círculos da organização terão Feedbacks habilitados',
    },
  }

  const REVOKE_CONTENT: CopyForFeature<typeof type> = {
    feedback: {
      description: 'não permitir Feedbacks',
      alertMessage:
        'Todos os Círculos da organização terão Feedbacks desabilitados',
    },
  }

  const dataContent = checked ? REVOKE_CONTENT[type] : PERMIT_CONTENT[type]

  return (
    <ToggleFeatureWithModal checked={checked}>
      {([showModal, setShowModal]) => {
        return (
          <ConfirmationModal
            show={showModal}
            onClose={() => {
              setShowModal(false)
            }}
            handleSave={() => {
              save({
                customPath: `organization/${
                  checked ? 'revoke' : 'permit'
                }/${type}`,
                afterSuccess: ({ data: organization }) => {
                  setOrganization(organization)
                  setShowModal(false)
                },
              })()
            }}
          >
            <h5 className="font-normal">
              Você deseja{' '}
              <span className="font-bold">{dataContent.description}</span> na
              Organização?
            </h5>
            {
              <div className="flex items-center gap-2 rounded-2xl bg-orange-50 px-[10px] py-1">
                <Icon icon="error-outline" className="text-[#F0B858]" />
                <span className="text-base font-bold text-[#604A23]">
                  {dataContent.alertMessage}
                </span>
              </div>
            }
          </ConfirmationModal>
        )
      }}
    </ToggleFeatureWithModal>
  )
}

const ToggleFeatureWithCircles = ({
  type,
  checked,
}: {
  type:
    | 'one_on_one_conversation'
    | 'career_conversation'
    | 'behavior_conversation'
    | 'upward_evaluation'
  checked: boolean
}) => {
  const [checkedAllCircles, setCheckedAllCircles] = React.useState(false)

  const [, { save, setInfo: setOrganization }] = useCroods({
    name: 'organization',
    id: type,
  })

  const PERMIT_CONTENT: CopyForFeature<typeof type> = {
    one_on_one_conversation: {
      description: 'permitir Onboarding, Efetivação e 1:1',
      alertMessage:
        'Todos os Círculos da organização terão Onboarding, Efetivação e 1:1 habilitadas',
    },
    career_conversation: {
      description: 'permitir Conversas de Carreira',
      alertMessage:
        'Todos os Círculos da organização terão Conversas de Carreira habilitadas',
    },
    behavior_conversation: {
      description: 'permitir Conversas de Comportamento',
      alertMessage:
        'Todos os Círculos da organização terão Conversas de Comportamento habilitadas',
    },
    upward_evaluation: {
      description: 'permitir Avaliação Ascendente',
      alertMessage:
        'Todos os Círculos da organização terão Avaliação Ascendente habilitadas',
    },
  }

  const REVOKE_CONTENT: CopyForFeature<typeof type> = {
    one_on_one_conversation: {
      description: 'não permitir Onboarding, Efetivação e 1:1',
      alertMessage:
        'Todos os Círculos da organização terão Onboarding, Efetivação e 1:1 desabilitadas',
    },
    career_conversation: {
      description: 'não permitir Conversas de Carreira',
      alertMessage:
        'Todos os Círculos da organização terão Conversas de Carreira desabilitadas',
    },
    behavior_conversation: {
      description: 'não permitir Conversas de Comportamento',
      alertMessage:
        'Todos os Círculos da organização terão Conversas de Comportamento desabilitadas',
    },
    upward_evaluation: {
      description: 'não permitir Avaliação Ascendente',
      alertMessage:
        'Todos os Círculos da organização terão Avaliação Ascendente desabilitadas',
    },
  }

  const dataContent = checked ? REVOKE_CONTENT[type] : PERMIT_CONTENT[type]

  return (
    <ToggleFeatureWithModal checked={checked}>
      {([showModal, setShowModal]) => {
        return (
          <ConfirmationModal
            show={showModal}
            onClose={() => {
              setCheckedAllCircles(false)
              setShowModal(false)
            }}
            handleSave={() => {
              save({
                customPath: `organization/${
                  checked
                    ? 'revoke'
                    : checkedAllCircles
                    ? 'permit_with_teams'
                    : 'permit'
                }/${type}`,
                afterSuccess: ({ data: organization }) => {
                  setOrganization(organization)
                  setShowModal(false)
                },
              })()
            }}
          >
            <h5 className="font-normal">
              Você deseja{' '}
              <span className="font-bold">{dataContent.description}</span> na
              Organização?
            </h5>
            {!checked && (
              <CheckInput
                onChange={() => setCheckedAllCircles(!checkedAllCircles)}
                checked={checkedAllCircles}
                label="Habilitar em todos os Círculos"
              />
            )}
            {(checked || checkedAllCircles) && (
              <div className="flex items-center gap-2 rounded-2xl bg-orange-50 px-[10px] py-1">
                <Icon icon="error-outline" className="text-[#F0B858]" />
                <span className="text-base font-bold text-[#604A23]">
                  {dataContent.alertMessage}
                </span>
              </div>
            )}
          </ConfirmationModal>
        )
      }}
    </ToggleFeatureWithModal>
  )
}

const OrganizationSettings = ({
  organization,
  className,
}: {
  organization: Organization
  className?: string
}) => {
  const classNameTd =
    'flex items-center justify-between text-left md:table-cell before:block before:font-bold before:content-[attr(data-title)] md:before:content-none'

  return (
    <div className={className}>
      <h3 className="mb-6">Conversas da Organização</h3>
      <div className="card">
        <div className="max-w-full">
          <Table id="collaborator-table">
            <Table.Thead>
              <Table.Th>Nome</Table.Th>
              <Table.Th className="text-center">
                Onboarding, Efetivação e 1:1
              </Table.Th>
              <Table.Th className="text-center">Carreira</Table.Th>
              <Table.Th className="text-center">Comportamento</Table.Th>
              <Table.Th className="text-center">Avaliação Ascendente</Table.Th>
              <Table.Th className="text-center">Feedbacks</Table.Th>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr className="!py-6">
                <Table.Td data-title="Nome" className={classNameTd}>
                  {organization.name}
                </Table.Td>
                <Table.Td
                  data-title="Onboarding, Efetivação e 1:1"
                  className={classNameTd}
                >
                  <ToggleFeatureWithCircles
                    type="one_on_one_conversation"
                    checked={organization.permitOneOnOneConversation}
                  />
                </Table.Td>
                <Table.Td data-title="Carreira" className={classNameTd}>
                  <ToggleFeatureWithCircles
                    type="career_conversation"
                    checked={organization.permitCareerConversation}
                  />
                </Table.Td>
                <Table.Td data-title="Comportamento" className={classNameTd}>
                  <ToggleFeatureWithCircles
                    type="behavior_conversation"
                    checked={organization.permitBehaviorConversation}
                  />
                </Table.Td>
                <Table.Td
                  data-title="Avaliação Ascendente"
                  className={classNameTd}
                >
                  <ToggleFeatureWithCircles
                    type="upward_evaluation"
                    checked={organization.permitUpwardEvaluation}
                  />
                </Table.Td>
                <Table.Td data-title="Feedbacks" className={classNameTd}>
                  <ToggleFeatureOnlyOrganization
                    type="feedback"
                    checked={organization.permitFeedback}
                  />
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export { OrganizationSettings }
