import { useReducer } from 'react'
import { useCroods } from 'croods'
import { useNavigate } from 'react-router-dom'

import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import parseValues from './parsers/parseValues'
import { useRestoreScrollOnChange } from 'shared/utils/hooks'

export const PREVIEW_STEP = 'preview'
export const OPENING_STEP = 'opening'
export const APPROVER_STEP = 'approver'
export const PERFORMER_STEP = 'performer'
export const SUMMARY_STEP = 'summary'

export const STEPS = [OPENING_STEP, APPROVER_STEP, PERFORMER_STEP, SUMMARY_STEP]

export const EDITION_STEPS = [PREVIEW_STEP, ...STEPS]

const reducer = (state: any, action: any) => {
  const currentStepList =
    state.initialStep === 'preview' ? EDITION_STEPS : STEPS

  if (action.type === 'next') {
    const step = currentStepList[currentStepList.indexOf(state.step) + 1]
    const isLastStep =
      currentStepList.indexOf(step) === currentStepList.length - 1

    return {
      ...state,
      step,
      isLastStep,
      values: action.values,
    }
  }

  if (action.type === 'navigate') {
    const nextStep = currentStepList[action.stepIndex]

    return {
      ...state,
      step: nextStep,
      isLastStep: false,
    }
  }

  return state
}

const useFormSteps = ({ initialValues, initialStep = OPENING_STEP }: any) => {
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const [{ saving, saveError }, { save }] = useCroods({
    id: initialValues?.id,
    name: 'solicitationTemplates',
    path: 'workflow/solicitation_templates',
  })

  const initialState = {
    step: initialStep,
    values: initialValues,
    initialStep: initialStep,
  }

  const [{ values, step, isLastStep }, dispatch] = useReducer(
    reducer,
    initialState,
  )

  useRestoreScrollOnChange(step)

  const handleNext = (formValues: any) => {
    if (PREVIEW_STEP === step) {
      dispatch({ type: 'next', values: values })
      return
    }

    if (isLastStep) {
      save({
        id: values?.id,
        customPath: '/workflow/solicitation_templates/:id/change_status',
        afterSuccess: async () => {
          snackbar({
            message: 'Modelo de solicitação publicado com sucesso!',
            type: 'success',
          })
          await navigate('/configuracoes/modelos-de-solicitacao')
        },
      })({ status: 'published' })
    } else if (step === 'preview') {
      dispatch({ type: 'next', values: values })
    } else {
      save({
        id: values?.id,
        afterSuccess: ({ data }) => {
          dispatch({ type: 'next', values: data })
        },
      })(
        parseValues({
          filledValues: values,
          formValues: step === 'preview' ? values : formValues,
        }),
      )
    }
  }

  const handleNavigate = (stepIndex: number) => () => {
    dispatch({ type: 'navigate', stepIndex })
  }

  return {
    values,
    step,
    saving,
    saveError,
    handleNext,
    handleNavigate,
  }
}

export default useFormSteps
