import * as React from 'react'
import Button from '@material-ui/core/Button'
import { Skeleton } from '@material-ui/lab'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { useCroods } from 'croods'
import { useAdminText } from 'shared/utils/hooks'
import EditableRow from './EditableRow'
import CompletedRow from './CompletedRow'
import type { Goal } from './types'
import filter from 'lodash/filter'

type Props = {
  teamMemberId: string
  setIsValidated: React.Dispatch<React.SetStateAction<boolean>>
}

function CareerGoals({ teamMemberId, setIsValidated }: Props) {
  const { careerGoalsTitle, careerGoalsCompletedTitle } = useAdminText()
  const [newGoal, setNewGoal] = React.useState<Goal>()

  const [{ fetchingList, list: careerGoals, saving }, { save }] =
    useCroods<Goal>({
      name: 'carrerGoals',
      path: `/team_members/${teamMemberId}/career_goals`,
      fetchOnMount: true,
      afterSuccess: () => {
        setNewGoal(undefined)
      },
    })

  const ongoingGoalsFiltered = filter(
    careerGoals,
    (item: Goal) => item.archivedAt == null && item.completedAt == null,
  )

  const completedGoalsFiltered = filter(
    careerGoals,
    (item: Goal) => item.archivedAt == null && item.completedAt != null,
  )

  React.useEffect(() => {
    setIsValidated(
      !fetchingList &&
        !saving &&
        (ongoingGoalsFiltered.length > 0 || completedGoalsFiltered.length > 0),
    )
  }, [ongoingGoalsFiltered, completedGoalsFiltered, fetchingList, saving]) // eslint-disable-line

  React.useEffect(() => {
    if (!newGoal) return
    if (saving) return

    setNewGoal(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving])

  return (
    <>
      <h4 className="mt-5 mb-9">{careerGoalsTitle}</h4>

      {fetchingList && <Skeleton height={90} />}

      {ongoingGoalsFiltered.map(({ id, description, createdAt }) => (
        <EditableRow
          key={`ongoingGoal-${id}`}
          description={description}
          createdAt={createdAt}
          id={id}
          save={save}
        />
      ))}

      {newGoal && (
        <EditableRow
          id={newGoal.id}
          save={save}
          createdAt={saving ? new Date() : undefined}
          hideNewGoal={() => setNewGoal(undefined)}
        />
      )}

      {!newGoal && !fetchingList && (
        <div className="flex justify-center pt-4">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setNewGoal({
                id: Date.now(),
                description: '',
              })
            }}
            startIcon={<ControlPointIcon />}
          >
            Adicionar
          </Button>
        </div>
      )}

      {completedGoalsFiltered.length > 0 && (
        <div className="my-8 w-full pt-8">
          <h4>{careerGoalsCompletedTitle}</h4>
        </div>
      )}

      {completedGoalsFiltered.map(({ id, description }) => (
        <CompletedRow
          key={`completedGoal-${id}`}
          description={description}
          id={id}
          save={save}
        />
      ))}
    </>
  )
}

export default CareerGoals
