import values from 'lodash/values'
import { renderIf } from 'shared/helpers'
import Search from 'shared/ui/Search'
import Switcher from 'shared/ui/Switcher'

type Props = {
  searchLabel?: string
  setSearch: Function
  setFilter?: Function
  filters?: Record<string, string>
  filter?: string
  children?: React.ReactNode
}

const HeaderControls = ({
  searchLabel = 'Buscar',
  setSearch,
  setFilter,
  filters,
  filter = values(filters)[0],
  children,
}: Props) => (
  <div className="my-6 flex flex-col justify-between gap-2 md:flex-row">
    <Search setSearch={setSearch} placeholder={searchLabel} />
    {renderIf(
      Boolean(filters),
      <Switcher
        options={values(filters).map((title) => ({ title }))}
        active={filter}
        setActive={setFilter}
      />,
    )}
    {renderIf(
      Boolean(children),
      <div className="flex flex-1 md:justify-end">{children}</div>,
    )}
  </div>
)

export default HeaderControls
