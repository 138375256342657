import type { AnyMaskedOptions } from 'imask'
import iMask from 'imask'

const createMask = (config: AnyMaskedOptions) => (val: string) =>
  iMask.createMask(config).resolve(val)

const masks = {
  date: createMask({ mask: '00/00/0000' }),
  integer: createMask({ mask: /^\d+$/ }),
  time: createMask({ mask: '00:00' }),
}

export { masks }
