import React from 'react'
import get from 'lodash/get'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { colors } from 'theme'

export default function SubmitButton({
  children,
  loading = false,
  kind = 'default',
  size = 'small',
  variant = 'contained',
  ...props
}: any) {
  const kinds = {
    'success-contained': {
      css: {
        background: colors.green,
        color: colors.white,
        borderColor: colors.green,
        '&:hover': {
          background: colors.greenDark,
          borderColor: colors.greenDark,
        },
      },
    },
    'danger-contained': {
      css: {
        background: colors.red,
        color: colors.white,
        borderColor: colors.red,
        '&:hover': { background: colors.redDark, borderColor: colors.redDark },
      },
    },
    'danger-outlined': {
      css: {
        color: colors.red,
        borderColor: colors.red,
        '&:hover': { background: colors.redLightBg, borderColor: colors.red },
      },
    },
  }
  const innerProps = get(kinds, `${kind}-${variant}`, {})
  return loading ? (
    <CircularProgress size="2rem" />
  ) : (
    <Button
      variant={variant}
      color="primary"
      type="submit"
      size={size}
      {...innerProps}
      {...props}
    >
      {children}
    </Button>
  )
}
