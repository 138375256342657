import React from 'react'

import Modal from 'shared/ui/Modal'
import FeedbackMessage from 'shared/ui/FeedbackMessage'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'

import { useCroods } from 'croods'
import Content from './Content'
import type { EvaluationTeam } from '../../types'
import valuesIn from 'lodash/valuesIn'
import { useTrackAnalytics } from 'domain/analytics'

type PublishConfirmProps = Pick<Props, 'values' | 'handleClose'> & {
  setSuccessDialog: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
}

const PublishConfirm = ({
  open,
  values,
  handleClose,
  setSuccessDialog,
}: PublishConfirmProps) => {
  const { snackbar } = useSnackbar()
  const track = useTrackAnalytics()
  const [{ saving, saveError }, { save }] = useCroods({
    name: '/member_evaluations',
    path: `/member_evaluations/publish`,
    afterSuccess: () => {
      track('Talent evaluation')
      setSuccessDialog(true)
    },
  })

  return (
    <Modal.Wrapper
      loading={saving}
      open={open}
      onConfirm={save({
        afterFailure: (e) => {
          const errorMessage =
            valuesIn(e?.response?.data)?.[0]?.[0] ??
            'Verifique se todos os dados estão preenchidos.'

          snackbar({
            message: errorMessage,
            type: 'error',
          })
        },
      })}
      onClose={handleClose}
    >
      <Modal.Header>Confirmar avaliação de talentos?</Modal.Header>
      <Modal.Content>
        <Content values={values} />
      </Modal.Content>
      <Modal.Actions cancelLabel="Editar">
        <Modal.Confirm>CONFIRMAR</Modal.Confirm>
      </Modal.Actions>
      {saveError && (
        <FeedbackMessage
          message="Houve um erro ao salvar"
          serverMessage="Verifique se todos os dados estão preenchidos"
        />
      )}
    </Modal.Wrapper>
  )
}

type Props = {
  openDialog: boolean
  handleClose: () => void
  setSuccessDialog: React.Dispatch<React.SetStateAction<boolean>>
  values: EvaluationTeam[]
}

const SummaryDialog = ({
  openDialog,
  setSuccessDialog,
  handleClose,
  values,
}: Props) => {
  return (
    <PublishConfirm
      open={openDialog}
      setSuccessDialog={setSuccessDialog}
      handleClose={handleClose}
      values={values}
    />
  )
}

export default SummaryDialog
