import Circle from './circle'

type Props = {
  color: string
  size?: number
  count?: number
  text: string
}

export default function CounterChip({
  color = 'bg-green',
  size = 4,
  count,
  text,
}: Props) {
  return (
    <div className="px-1 py-1 mr-2 rounded-full min-w-max bg-gray/50">
      <div className="flex items-center justify-center">
        <Circle
          color={color}
          size={size}
          circleText={typeof count === 'number' ? String(count) : undefined}
        >
          {text}
        </Circle>
      </div>
    </div>
  )
}
