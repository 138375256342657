import * as React from 'react'
import { useCroods } from 'croods'
import { useCurrentUser } from 'croods-auth'
import { Link, useParams } from 'react-router-dom'
import compact from 'lodash/compact'
import Identification from './card-identification'
import { LightTooltip } from 'shared/ui/Tooltip/InfoTooltip'
import CardChip from './card-chip'
import Icon from 'shared/ui/icon'
import type { Circle, User } from 'types'
import InviteModal from './invite-modal'
import { useSnackbar } from 'shared/ui/Snackbar/useSnackbar'
import type { Option } from 'shared/forms/AutocompleteWithSearch/Autocomplete'
import { getUserIdentifier, renderIf } from 'shared/helpers'
import Modal from 'shared/ui/Modal'
import AutocompleteWithSearch from 'shared/forms/AutocompleteWithSearch'
import Avatar from 'shared/ui/Avatar'
import CheckInput from 'shared/forms/CheckInput'

type SearchableCircle = Circle & {
  label: string
  teamId: number
}

const Loading = () => (
  <div
    data-testid="loading"
    className="h-80 w-full animate-pulse rounded bg-gray-200"
  />
)

export function TeamInvitationModal({
  open,
  onClose,
  employee,
  afterSuccess,
}: Pick<React.ComponentProps<typeof Modal.Wrapper>, 'open' | 'onClose'> & {
  employee: User
  afterSuccess?: () => void
}) {
  const [inviteToVibe, setInviteToVibe] = React.useState(false)
  const [teamId, setTeamId] = React.useState<number | string | null>(null)
  const canBeInvited = Boolean(!employee.hasCredential && employee.email)

  const parseOptions = (options: SearchableCircle[]) => {
    const result = options.map((option) => ({
      label: option.label,
      value: option.teamId,
    }))

    return result
  }
  const handleSelectTeamId = (
    props: string | Option | (string | Option)[] | null,
  ) => {
    if (typeof props === 'object' && !Array.isArray(props) && props !== null) {
      setTeamId(props.value)
    }
  }
  const { snackbar } = useSnackbar()
  const [{ saving }, { save }] = useCroods({
    name: 'addEmployee',
    customPath: `/team/${teamId}/add_employee`,
    fetchOnMount: false,
    afterSuccess: () => {
      afterSuccess ? afterSuccess() : window.location.reload()
      snackbar({
        title: 'Sucesso',
        message: 'Colaborador adicionado ao círculo com sucesso.',
        type: 'success',
      })
    },
    afterFailure: (e) => {
      snackbar({
        title: 'Não foi possível adicionar o colaborador ao círculo.',
        message: e?.response?.data?.message,
        type: 'error',
        timeout: 5,
      })
    },
  })

  const handleConfirm = async () => {
    async function saveFunction() {
      const resultSave = await save()({
        userId: employee.id,
        invite: inviteToVibe,
      })

      return resultSave
    }

    return await saveFunction()
  }

  return (
    <Modal.Wrapper
      loading={saving}
      open={open}
      onClose={onClose}
      enabled={Boolean(teamId)}
      onConfirm={handleConfirm}
    >
      <div className="md:w-[38rem]">
        <Modal.Header>
          <div className="flex items-start">
            <h5 className="mx-2 w-full">Adicionar a um círculo</h5>
          </div>
        </Modal.Header>
        <Modal.Content className="mx-2 gap-4">
          <div className="flex">
            <div className="mr-4 flex-col">
              <Avatar user={employee} />
            </div>

            <div className="flex max-w-[200px] flex-col justify-center">
              <label className="text-sm font-bold">
                {getUserIdentifier({ user: employee })}
              </label>
              <p className="font-400 text-sm text-black/50">{employee.role}</p>
            </div>
          </div>
          <div className="mt-2 flex flex-wrap">
            <span className="text-lg">
              Escolha um círculo em que deseja{' '}
              <span className="font-bold">adicionar</span>
            </span>
            <div className="mt-2 w-full">
              <AutocompleteWithSearch<SearchableCircle>
                croodsConfig={{
                  name: 'teamsToInvite',
                  path: '/team/to_invite',
                  fetchOnMount: true,
                }}
                parseOptions={parseOptions}
                inputProps={{
                  name: 'teams',
                  label: '',
                  placeholder: 'Busque pelo nome do círculo',
                }}
                autocompleteProps={{
                  multiple: false,
                  onChange: handleSelectTeamId,
                  onBlur: () => {},
                }}
              />

              <Link
                to="/circulos/criar-novo-circulo"
                className="float-right mt-1 text-base font-bold text-blue"
              >
                Criar novo círculo
              </Link>
            </div>
            {renderIf(
              canBeInvited,
              <div className="mt-5 w-full">
                <CheckInput
                  onChange={() => setInviteToVibe(!inviteToVibe)}
                  checked={inviteToVibe}
                  label={
                    <span className="text-lg font-bold">
                      Convidar para acessar o Vibe
                    </span>
                  }
                />
                <div className="h-9">
                  {inviteToVibe ? (
                    <div className="flex w-fit items-center gap-2 rounded-2xl bg-yellow-lightest px-4 py-1 text-base font-bold text-yellow-darkest">
                      <Icon
                        icon="error-outline"
                        className="!h-[22px] !w-[22px] text-yellow"
                      />
                      Os colaboradores poderão criar suas senhas e acessar o
                      Vibe.
                    </div>
                  ) : null}
                </div>
              </div>,
            )}
          </div>
        </Modal.Content>
        <Modal.Actions cancelLabel="CANCELAR">
          <Modal.Confirm color="primary">Adicionar</Modal.Confirm>
        </Modal.Actions>
      </div>
    </Modal.Wrapper>
  )
}

const Information = ({ employee }: { employee: User }) => {
  const { teamMembers, teamLeaders, primaryRoles, secondaryRoles, active } =
    employee

  // Add to circle
  const [{ currentUser }] = useCurrentUser()
  const [showTeamInvitation, setShowTeamInvitation] =
    React.useState<boolean>(false)
  const isAdminOrLeader = currentUser.admin || currentUser.leader
  const showAddToCircleButton =
    isAdminOrLeader && (!teamMembers || teamMembers?.length === 0) && active
  // end Add to circle

  const [invitingModal, setInvitingModal] = React.useState<boolean>(false)

  const closeModal = () => setInvitingModal(false)

  const [showInvite, setShowInvite] = React.useState<boolean>(
    !employee.hasCredential && Boolean(employee.abilities?.invite) && active,
  )

  const primaryRolesFiltered = compact([...(primaryRoles || [])])

  const secondaryRolesFiltered = compact([...(secondaryRoles || [])])

  const disableInvite = !Boolean(employee.email)

  return (
    <div className="flex flex-grow flex-col-reverse md:flex-col">
      <div className="m-2 mb-5 mx-auto md:mr-2 flex flex-col md:flex-row md:h-11 gap-2">
        {renderIf(
          showAddToCircleButton,
          <>
            <button
              onClick={() => setShowTeamInvitation(true)}
              type="button"
              className="bt bt-outlined overflow-hidden whitespace-nowrap w-full md:w-max"
            >
              Adicionar a um círculo
            </button>
            <TeamInvitationModal
              open={showTeamInvitation}
              employee={employee}
              onClose={() => setShowTeamInvitation(false)}
            />
          </>,
        )}

        {renderIf(
          showInvite,
          <LightTooltip
            title={
              disableInvite ? (
                <div className="text-center">
                  <h6 className="w-48 text-sm font-light text-gray-darkest">
                    Sem e-mail cadastrado
                  </h6>
                </div>
              ) : (
                ''
              )
            }
            enterTouchDelay={100}
            placement="bottom-end"
            arrow={true}
          >
            <button
              className="bt bt-contained flex items-center justify-center gap-2 w-full md:w-max"
              disabled={disableInvite}
              onClick={() => setInvitingModal(true)}
              type="button"
            >
              <Icon icon="add-circle-outline" size="md" />
              <span>Convidar para o Vibe</span>
            </button>
          </LightTooltip>,
        )}
      </div>

      <div className="flex flex-col gap-2 p-4">
        {teamLeaders && teamLeaders.length > 0 && (
          <CardChip
            type="teams"
            list={teamLeaders}
            url="/circulos/"
            label="Líder em"
            leader
          />
        )}
        {teamMembers && teamMembers.length > 0 && (
          <CardChip
            type="teams"
            list={teamMembers}
            url="/circulos/"
            label="Colaborador em"
          />
        )}
        {primaryRolesFiltered && primaryRolesFiltered.length > 0 && (
          <CardChip
            type="roles"
            list={primaryRolesFiltered}
            label="Papel Principal"
          />
        )}
        {secondaryRolesFiltered && secondaryRolesFiltered.length > 0 && (
          <CardChip
            type="roles"
            list={secondaryRolesFiltered}
            label="Outros Papéis"
          />
        )}
      </div>

      {invitingModal && (
        <InviteModal
          onClose={closeModal}
          employee={employee}
          setShowInvite={setShowInvite}
        />
      )}
    </div>
  )
}

export default function Employee() {
  const userId = useParams().userId!
  const [{ fetchingInfo, info: employee }] = useCroods({
    name: 'users',
    id: userId,
    fetchOnMount: true,
  })

  if (fetchingInfo) {
    return <Loading />
  }

  return employee ? (
    <div className="card flex flex-wrap">
      <Identification user={employee} />

      <Information employee={employee} />
    </div>
  ) : null
}
