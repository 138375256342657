import * as React from 'react'
import { useLocation } from 'react-router-dom'
import List from '@material-ui/core/List'
import { getAvailablePaths, isMenuItemActive } from 'shared/accessPolicies'
import type { AccessPolicyUser } from 'shared/accessPolicies'
import activeSection from '../activeSection'
import MenuItem from './MenuItem'

import { OrganizationContext } from 'shared/organization/OrganizationContext'

const menuItems = getAvailablePaths()

const getCurrentModuleItem = ({ pathname }: { pathname: string }) => {
  const possiblePaths = menuItems
    .filter((menu) => menu.path !== '/')
    .map((menu) => menu.path.replace('/', ''))

  const menu = menuItems.find(({ path }) =>
    pathname !== 'login'
      ? activeSection({ pathname, path, possiblePaths })
      : false,
  )

  if (!menu) throw new Error('could not find current menu')

  return menu
}

export default function MenuItemList({
  currentUser,
}: {
  currentUser: AccessPolicyUser
}) {
  const { pathname } = useLocation()
  const organization = React.useContext(OrganizationContext)

  const moduleItem = getCurrentModuleItem({ pathname })
  const menuItems = moduleItem.menuItems

  return (
    <List disablePadding>
      {menuItems.map((menuItem) => {
        if (!menuItem.visibleFor(currentUser)) return null

        if (!menuItem.permittedForOrganization(organization!)) return null

        const active = isMenuItemActive({
          currentPath: pathname,
          menuItem,
          moduleItem,
        })

        return (
          <MenuItem
            key={menuItem.path}
            {...menuItem}
            parent={moduleItem?.path}
            active={active}
          />
        )
      })}
    </List>
  )
}
